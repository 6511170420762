import StyleIcon from '@mui/icons-material/Style'
import RecentActorsIcon from '@mui/icons-material/RecentActors'
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import HubIcon from '@mui/icons-material/Hub'
import SettingsIcon from '@mui/icons-material/Settings'
import MediationIcon from '@mui/icons-material/Mediation'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'
import SaveAsIcon from '@mui/icons-material/SaveAs'
import DashboardIcon from '@mui/icons-material/Dashboard'
import AssignmentIcon from '@mui/icons-material/Assignment'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import ListAltIcon from '@mui/icons-material/ListAlt'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DescriptionIcon from '@mui/icons-material/Description';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
// import BarChartIcon from '@mui/icons-material/BarChart';

export const initialMenu = {
  firstLoad: true,
  procesos: [
    {
      index: 1,
      visible: 0,
      name: 'Árbol de procesos',
      to: '/',
      icon: () => <ClearAllIcon />,
      onClick: (event, handleListItemClick, setDisplayTreeView) => {
        event.preventDefault()
        handleListItemClick(event, 1)
        setDisplayTreeView(true)
      }
    },
    {
      index: 3,
      visible: 0,
      name: 'Value Stream Maps',
      to: 'valuemaplist',
      icon: () => <MediationIcon />,
      onClick: (event, handleListItemClick) => handleListItemClick(event, 3)
    },
    {
      index: 14,
      visible: 0,
      name: 'Tablero de indicadores',
      to: 'tableros',
      icon: () => <DashboardIcon />,
      onClick: (event, handleListItemClick) => handleListItemClick(event, 14)
    },
    {
      index: 6,
      visible: 0,
      name: 'Ingreso de resultados',
      to: 'resultados',
      icon: () => <SaveAsIcon />,
      onClick: (event, handleListItemClick) => handleListItemClick(event, 6)
    },
    {
      index: 13,
      visible: 0,
      name: 'Ingreso de resultados (Digitador)',
      to: 'digitador',
      icon: () => <AppRegistrationIcon />,
      onClick: (event, handleListItemClick) => handleListItemClick(event, 13)
    }
  ],
  okrs: [
    {
      index: 24,
      visible: 0,
      name: 'OKRs',
      to: 'okrs',
      icon: () => <TrackChangesIcon />,
      onClick: (event, handleListItemClick) => handleListItemClick(event, 24)
    },
    {
      index: 25,
      visible: 0,
      name: 'Ingreso de resultados',
      to: 'okrs/digitar-resultados',
      icon: () => <SaveAsIcon />,
      onClick: (event, handleListItemClick) => handleListItemClick(event, 25)
    }
  ],
  evaluaciones: [
    {
      index: 17,
      visible: 0,
      name: 'Mi evaluación de desempeño',
      to: 'indicadoresasignados',
      icon: () => <ListAltIcon />,
      onClick: (event, handleListItemClick) => handleListItemClick(event, 17)
    },
    {
      index: 12,
      visible: 0,
      name: 'Asignación a Bono Zafra',
      to: 'bonozafra',
      icon: () => <PersonAddIcon />,
      onClick: (event, handleListItemClick) => handleListItemClick(event, 12)
    },
    {
      index: 18,
      visible: 0,
      name: 'Evaluación de personal a cargo',
      to: 'evaluacionesdesempeno',
      icon: () => <AssignmentIcon />,
      onClick: (event, handleListItemClick) => handleListItemClick(event, 18)
    },
    {
      index: 16,
      visible: 0,
      name: 'Pesos de evaluación',
      to: 'desempeno',
      icon: () => <FitnessCenterIcon />,
      onClick: (event, handleListItemClick) => handleListItemClick(event, 16)
    },
    {
      index: 9,
      visible: 0,
      name: 'Niveles organizacionales',
      to: 'etiquetas',
      icon: () => <HubIcon />,
      onClick: (event, handleListItemClick) => handleListItemClick(event, 9)
    },
    {
      index: 21,
      visible: 0,
      name: 'Configuración Evaluaciones',
      to: 'configuracion/evaluacion',
      icon: () => <SettingsIcon />,
      onClick: (event, handleListItemClick) => handleListItemClick(event, 21)
    }
  ],
  administracion: [
    {
      index: 10,
      visible: 0,
      name: 'Gestión de roles',
      to: 'roles',
      icon: () => <RecentActorsIcon />,
      onClick: (event, handleListItemClick) => handleListItemClick(event, 10)
    },
    {
      index: 11,
      visible: 0,
      name: 'Asignación de roles',
      to: 'asignacionroles',
      icon: () => <ReduceCapacityIcon />,
      onClick: (event, handleListItemClick) => handleListItemClick(event, 11)
    },
    {
      index: 15,
      visible: 0,
      name: 'Gestión de etiquetas',
      to: 'familiaetiqueta',
      icon: () => <StyleIcon />,
      onClick: (event, handleListItemClick) => handleListItemClick(event, 15)
    },
    {
      index: 19,
      visible: 0,
      name: 'Pilares y Objetivos',
      to: 'pilares',
      icon: () => <AccountBalanceIcon />,
      onClick: (event, handleListItemClick) => handleListItemClick(event, 19)
    },
    {
      index: 5,
      visible: 0,
      name: 'Periodos de evaluación',
      to: 'periodos',
      icon: () => <EventRepeatIcon />,
      onClick: (event, handleListItemClick) => handleListItemClick(event, 5)
    },
    {
      index: 23,
      visible: 0,
      name: 'Bitácora',
      to: 'bitacora',
      icon: () => <DescriptionIcon />,
      onClick: (event, handleListItemClick) => handleListItemClick(event, 23)
    }
  ]
}
