import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Paper, Grid, IconButton } from '@mui/material'
import {
  Chart,
  Size,
  Series,
  Reduction,
  CommonSeriesSettings,
  Export,
  Legend,
  Margin,
  Tooltip,
  Label,
  ZoomAndPan,
  ScrollBar,
  ArgumentAxis,
  ValueAxis,
  Point,
  ConstantLine,
  Annotation,
  Title
} from 'devextreme-react/chart'
import { LayoutContext } from '../Context/layoutContext'
import { CHARTS_COLORS } from '../Utils/enums'
import { ChartTable } from './chartTable'
import { sendDataToAnalyze } from '../Api/analysis.api'
import { getGraphicData, getRefenceLines, getCoeficientesCP } from '../Api/graphics.api'
import { ChartControls } from './ChartControls'
import { TooltipTemplate } from './TooltipTemplate'
import { AnalisisRiesgosModal } from './AnalisisRiesgosModal'
import { ChartSkeleton } from './ChartSkeleton'
import { TooltipTemplateWithComments } from './TooltipTemplateWithComments'
import styles from './graph.module.css'
import { AgrupadoTable } from './agrupadoTable'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { PeriodoContext } from '../Context/periodoContext'
import CoeficientesCPTable from './coeficientesCPTable'
import {
  GRAFICAS_AVANZADAS,
  HORIZONTAL_GRAPH,
  formarPaleta,
  KEYS_INVALIDAS,
  TITULOS_GRAFICAS,
  TOTAL_LABELS,
  LIMITE_PUNTOS,
  CHART_MIN_WIDTH
} from '../Utils/graphConstants'
import { locale } from "devextreme/localization";

locale(navigator.language);

function CustomizedGraph({
  indexChart,
  resultados,
  titulos,
  periodosGuardados,
  titulo,
  nombrePersonalizado=null,
  etiquetaEjeX,
  graficarEjeXDiscreto,
  formatoFecha,
  operacionIntegracion,
  maxWidth = 100,
  minWidth = 100,
  metaInversa = false,
  height = 500,
  customKey = Math.random() * 100,
  onDoubleClick = () => { },
  indicadorId = null,
  indicadorPrevId = null,
  tipoGraficaProp,
  tipoVistaProp,
  agrupadorGraficaProp,
  seriesOcultasProp,
  actualizarVistaTipoGraficaFn = () => { },
  mostrarEtiquetasProp,
  showAnalysisProp,
  analysisDataProp,
  detalleIdProp,
  showToolButtons = true,
  allowComments = false,
  agregarListaComentarios,
  additionalButtons = [],
  agrupado = false,
  tablaResultados,
  listaColumnas,
  esParteRaci,
  updateResultados = () => { },
  fechaInicioConfirmada,
  fechaFinConfirmada,
  esAgrupadoSeries = false,
}) {
  const { isDrawerOpen } = React.useContext(LayoutContext)
  const [resultadosAMostrar, setResultadosAMostrar] = useState([])
  const [resultadosAMostrarAPI, setResultadosAMostrarAPI] = useState(null)
  const [lineasReferencia, setLineasReferencia] = useState([])
  const [titulosAMostrar, setTitulosAMostrar] = useState([])
  const [candlestickAvailableQ2, setCandleStickAvailableQ2] = useState([])
  const [chartLegends, setChartLegends] = useState([])
  const [showCumulativeChart, setShowCumulativeChart] = useState(false)
  const [chartType, setChartType] = useState(!!tipoGraficaProp ? tipoGraficaProp : 'line')
  const [agrupadorGrafica, setAgrupadorGrafica] = useState(agrupadorGraficaProp || null)
  const [tipoVista, setTipoVista] = useState(tipoVistaProp ? tipoVistaProp : 'chart')
  const [showValueTag, setShowValueTag] = useState(mostrarEtiquetasProp ? mostrarEtiquetasProp : false)
  const [processCapabilitiyData, setProcessCapabilitiyData] = useState(null)
  const [showAnalysis, setShowAnalysis] = useState(showAnalysisProp ? showAnalysisProp : false)
  const [analysisData, setAnalysisData] = useState(analysisDataProp ? analysisDataProp : null)
  const [fullScreen, setFullScreen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [meRenderizo, setMeRenderizo] = useState(false);
  const chartRef = React.useRef()

  const { getSelected, periodos } = React.useContext(PeriodoContext)

  const getPeriodosLeyendas = () => {
    if (getSelected().length && !allowComments)
      return getSelected()
    
    const _titulos = periodosGuardados.filter((titulo) => titulo.value.toLowerCase().includes('periodo') && !titulo.value.toLowerCase().includes('meta'))
    const _periodos = periodos.filter((periodo) => _titulos.map((titulo) => titulo.value).includes(periodo.nombre))
    return _periodos.map(item => item.id)
  }

  const palette = formarPaleta(getPeriodosLeyendas(), agrupado)

  let seriesOcultas = [];

  useEffect(() => {
    reRenderCharts()
  }, [maxWidth, minWidth, isDrawerOpen])

  useEffect(() => {
    if (showAnalysis) {
      performStatisticaAnalysis({ preventDefault: () => null, stopPropagation: () => null })
      performProcessCapability({ preventDefault: () => null, stopPropagation: () => null })
    }
  }, [showAnalysis, agrupadorGrafica, fechaInicioConfirmada, fechaFinConfirmada])

  useEffect(() => {
    setCandleStickAvailableQ2(titulosAMostrar.filter(item => !item?.value?.startsWith('Meta')))
    setChartLegends(titulosAMostrar.map(item => ({ ...item, visible: true })))
  }, [titulosAMostrar])

  // Controla el cambio de grafica
  useEffect(() => {
    getLineasReferencia();
    if (GRAFICAS_AVANZADAS.includes(chartType) || agrupadorGrafica) {
      getGraficasAvanzadas()
    } else {
      setResultadosAMostrarAPI(null)
    }
  }, [chartType, agrupadorGrafica, fechaInicioConfirmada, fechaFinConfirmada])

  useEffect(() => {
    if (!showCumulativeChart) {
      setResultadosAMostrar(formatearData(resultados))
      setTitulosAMostrar(titulos)
    } else {
      const resAcumulados = resultados.map((elem, index) => {
        const keys = Object.keys(elem)
        const numericKeys = keys.filter((key) => key !== 'fecha')
        const visitedResults = resultados.slice(0, index + 1)
        return {
          fecha: elem.fecha,
          [numericKeys[0]]: visitedResults.reduce(
            (prev, curr) => prev + curr[numericKeys[0]],
            0
          ),
          [numericKeys[1]]: visitedResults.reduce(
            (prev, curr) => prev + curr[numericKeys[1]],
            0
          )
        }
      })
      setResultadosAMostrar(formatearData(resAcumulados))
    }
    reRenderCharts()
  }, [showCumulativeChart, titulos, resultados])

  useEffect(() => {
    if (showAnalysis && analysisData) {
      let resultadosKeys = Object.keys(resultados[0])
      resultadosKeys = resultadosKeys.filter((key) => !key.startsWith('Meta') && key !== 'serie' && key !== 'valorNumerico')
      const resultadosCopy = resultados.map((res) => {
        const _res = {}
        for (let key of resultadosKeys) {
          _res[key] = res[key]
        }
        return _res
      })
      const nuevosTitulos = resultadosKeys
        .filter((key) => key !== 'fecha')
        .map((key) => {
          return {
            value: key,
            name: key
          }
        })
      setResultadosAMostrar(formatearData(resultadosCopy))
      setTitulosAMostrar(nuevosTitulos)
    } else {
      setTitulosAMostrar(titulos)
      setResultadosAMostrar(formatearData(resultados))
    }
  }, [showAnalysis])

  const formatearData = (data) => {
    // Ordenar por fecha
    data.sort((a, b) => new Date(a.fecha) - new Date(b.fecha))

    // Agrupar por columna que lleve la palabra Periodo
    const newData = [];
    const periodos = titulos.filter((titulo) => titulo.value.toLowerCase().includes('periodo') && !titulo.value.toLowerCase().includes('meta'))
    periodos.map((periodo) => {
      let dataPeriodo = data.filter((res) => res[periodo.value] !== undefined);
      dataPeriodo = dataPeriodo.map((res, index) => ({ ...res, serie: index + 1 }))
      newData.push(...dataPeriodo)
    })
    return newData.map((res, index) => ({ ...res, etiquetaDiscreta: index + 1 }))
  }

  const getGraficasAvanzadas = () => {
    setIsDataLoading(true)
    const periodos = getPeriodosLeyendas()
    let _chart = chartType;
    if (!GRAFICAS_AVANZADAS.includes(_chart)) _chart = agrupadorGrafica || chartType
    getGraphicData(_chart, {
      periodos,
      indicador: esAgrupadoSeries ? null : indicadorPrevId || indicadorId,
      agrupador_caja: agrupadorGrafica,
      indicadores_agrupados: esAgrupadoSeries ? indicadorPrevId || indicadorId : null,
      fecha_inicio: fechaInicioConfirmada?.toISOString().slice(0, 10),
      fecha_fin: fechaFinConfirmada?.toISOString().slice(0, 10)
    })
      .then(response => {
        const { resultados } = response || {}
        setResultadosAMostrarAPI(resultados)
      })
      .finally(() => {
        setIsDataLoading(false)
      })
  }

  const getLineasReferencia = () => {
    try {
      const periodos = getPeriodosLeyendas();
      const body = {
        periodos,
        indicador: esAgrupadoSeries ? null : indicadorPrevId || indicadorId,
        indicadores_agrupados: esAgrupadoSeries ? indicadorPrevId || indicadorId : null,
        fecha_inicio: fechaInicioConfirmada?.toISOString().slice(0, 10),
        fecha_fin: fechaFinConfirmada?.toISOString().slice(0, 10)
      }
      getRefenceLines(body)
        .then(response => {
          setLineasReferencia(response || [])
        })
    } catch (error) { }
  }

  function reRenderCharts() {
    setTimeout(() => {
      if (chartRef.current) chartRef.current.instance.render()
    }, 100)
  }

  function checkIfAnalysisIsCorrect() {
    if (!analysisData) return true
    const isAnalysisCorrect = Object.values(analysisData.rules).every(
      ({ outsideOfLimits }) => outsideOfLimits === false
    )
    return isAnalysisCorrect
  }

  function getTitulo() {
    let _titulo = titulo || ''
    if (nombrePersonalizado) {
      _titulo = nombrePersonalizado.replace('{Indicador}', _titulo);
    }
    return _titulo
  }

  function customizePoint(arg) {
    let color = null;
    const properties = Object.keys(arg.data)
    const valueProperty = properties.filter(
      (prop) => prop !== 'fecha' && !prop.startsWith('Meta') && prop !== 'serie'
    )[0]
    if (agrupado) {
      color = CHARTS_COLORS.BLUE
    } else if (arg.data[`Meta ${valueProperty}`] == null || arg.data[`Meta ${valueProperty}`] == undefined) {
      color = null;
    } else {
      color = metaInversa ?
        (arg.data[valueProperty] > arg.data[`Meta ${valueProperty}`] ?
          CHARTS_COLORS.RED : CHARTS_COLORS.GREEN
        )
        : (arg.data[valueProperty] >= arg.data[`Meta ${valueProperty}`] ?
          CHARTS_COLORS.GREEN : CHARTS_COLORS.RED
        );
    }

    const totalItems = (resultadosAMostrarAPI || resultadosAMostrar).length
    return {
      color: color,
      hoverStyle: { color },
      size: totalItems > LIMITE_PUNTOS ? 4 : 14,
      border: {
        visible: arg.data.comentarios?.length > 0 ? true : false,
        width: 1,
        color: 'black'
      }
    }
  }

  function customizeBorder(arg) {
    let color = null;
    const properties = Object.keys(arg.data)
    const valueProperty = properties.filter(
      (prop) => prop !== 'fecha' && !prop.startsWith('Meta') && prop !== 'serie'
    )[0]
    if (agrupado) {
      color = CHARTS_COLORS.BLUE
    } else if (arg.data[`Meta ${valueProperty}`] == null || arg.data[`Meta ${valueProperty}`] == undefined) {
      color = null;
    } else {
      color = metaInversa ?
        (arg.data[valueProperty] > arg.data[`Meta ${valueProperty}`] ?
          CHARTS_COLORS.RED : CHARTS_COLORS.GREEN
        )
        : (arg.data[valueProperty] >= arg.data[`Meta ${valueProperty}`] ?
          CHARTS_COLORS.GREEN : CHARTS_COLORS.RED
        );
    }
    return { border: { visible: true, color: color } }
  }

  function customizeLabel(arg) {
    let color
    const properties = Object.keys(arg.data)
    const valueProperty = properties.filter(
      (prop) => prop !== 'fecha' && !prop.startsWith('Meta') && prop !== 'serie'
    )[0]
    if (showAnalysis || agrupado) {
      color = CHARTS_COLORS.NONE
    } else {
      color = metaInversa ?
        (arg.data[valueProperty] > arg.data[`Meta ${valueProperty}`] ?
          CHARTS_COLORS.RED : CHARTS_COLORS.GREEN
        )
        : (arg.data[valueProperty] >= arg.data[`Meta ${valueProperty}`] ?
          CHARTS_COLORS.GREEN : CHARTS_COLORS.RED
        );
    }
    const totalItems = (resultadosAMostrarAPI || resultadosAMostrar).length
    const labels = fullScreen ? TOTAL_LABELS * 2 : TOTAL_LABELS
    const step = Math.ceil(totalItems / labels);
    if (totalItems > labels && arg.index % step !== 0) return { visible: false }
    return {
      visible: arg.seriesName.startsWith('Meta') || arg.seriesName.trim() == '' ? false : showValueTag,
      backgroundColor: color,
      color: 'black',
      customizeText(e) {
        switch (chartType) {
          case 'candlestick':
            return `Mímino: ${arg.data.minimo}
            Q1: ${arg.data.Q1}
            Q2: ${arg.data.Q2}
            Q3: ${arg.data.Q3}
            Máximo: ${arg.data.maximo}`
          default:
            return `${(parseFloat(e.valueText).toFixed(4)).replace(/\.?0+$/, '')}`
        }
      }
    }
  }

  function onPointClick({ target: point }) {
    point.showTooltip()
  }

  function hideTooltips() {
    chartRef.current.instance.getAllSeries().forEach((serie) => {
      serie.getAllPoints().forEach((point) => {
        point.hideTooltip()
      })
    })
  }

  function getCorrectWidth() {
    const width = isDrawerOpen ? minWidth : maxWidth
    if (width < CHART_MIN_WIDTH) return CHART_MIN_WIDTH
    return width
  }

  function handleCumulativeButtonClick(e) {
    e.stopPropagation() // Impide que se ejecuten los rerender de las graficas
    setShowCumulativeChart(!showCumulativeChart)
  }

  function performProcessCapability(e) {
    e.preventDefault()
    e.stopPropagation()

    setIsDataLoading(true)
    const periodos = getPeriodosLeyendas();
    const body = {
      periodos,
      indicador: esAgrupadoSeries ? null : indicadorPrevId || indicadorId,
      indicadores_agrupados: esAgrupadoSeries ? indicadorPrevId || indicadorId : null,
      fecha_inicio: fechaInicioConfirmada?.toISOString().slice(0, 10),
      fecha_fin: fechaFinConfirmada?.toISOString().slice(0, 10),
      agrupador: agrupadorGrafica
    }
    getCoeficientesCP(body)
      .then(response => {
        setProcessCapabilitiyData(response || null)
      })
      .finally(() => {
        setIsDataLoading(false)
      })

  }

  function performStatisticaAnalysis(e) {
    e.preventDefault()
    e.stopPropagation()
    const data = { indicadores: [] }
    const cleanedResults = resultados
      .map((res) => {
        const properties = Object.keys(res)
        const valueProperty = properties.filter(
          (prop) => prop !== 'fecha' && !prop.startsWith('Meta') && prop !== 'serie'
        )[0]
        return res[valueProperty]
      })
      .filter((res) => res !== null)
    if (cleanedResults.length === 0) {
      return toast.warning('No hay datos para analizar')
    }
    setIsDataLoading(true)
    const indicador = {
      id: indicadorPrevId || indicadorId,
      nombre: titulo,
      resultados: cleanedResults
    }
    data.indicadores.push(indicador)
    sendDataToAnalyze(data).then((response) => {
      setIsDataLoading(false)
      if (response.status !== 200) {
        return toast.error('Error al analizar los datos')
      }
      setAnalysisData(response.body[indicadorId])
    })
  }

  function changeLegends(name, hide) {
    if (hide) {
      setCandleStickAvailableQ2(candlestickAvailableQ2.filter(item => item.value != name))
    } else {
      if (!name.startsWith('Meta') && candlestickAvailableQ2.filter(item => item.value == name).length == 0)
        setCandleStickAvailableQ2([...candlestickAvailableQ2, { value: name, name }])
    }
  }

  function ocultarMostrarSerie(serie, ocultar = true) {
    try {
      const _seriesOcultasSet = new Set(seriesOcultas);
      _seriesOcultasSet.add(serie);
      if (!ocultar) _seriesOcultasSet.delete(serie);
      const _seriesOcultas = Array.from(_seriesOcultasSet);
      seriesOcultas = _seriesOcultas
      actualizarVistaTipoGraficaFn({
        id: detalleIdProp,
        seriesOcultas: _seriesOcultas.join(',')
      })
    } catch (error) { console.error(error) }
  }

  function onLegendClick({ target: series }) {
    if (['candlestick'].includes(chartType)) {
      if (series.name !== " ") {
        const name = series.name, hide = series.isVisible(),
          current = chartLegends.filter(item => item.name == name)[0],
          new_items = [...chartLegends.filter(item => item.name != name), { name, value: name, visible: !current.visible }]
        changeLegends(name, hide)
        setChartLegends(new_items)
        new_items.forEach(item => {
          const serie = chartRef.current.instance.getAllSeries().filter(item1 => item.name == item1.name)[0]
          if (item.visible) {
            ocultarMostrarSerie(serie.name, true)
            serie.hide()
          } else {
            ocultarMostrarSerie(serie.name + '', false)
            serie.show()
          }
        })
      }
    } else {
      if (series.isVisible()) {
        ocultarMostrarSerie(series.name, true)
        series.hide();
      } else {
        ocultarMostrarSerie(series.name + '', false)
        series.show();
      }
    }
  }

  function getFullScreenProps() {
    if (fullScreen) {
      return ({
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2000,
        margin: 0,
        width: window.innerWidth
      })
    }
    return {}
  }

  const encontrarMinimoMaximo = (_resultados, isHorizontalGraph = false) => {
    const keys = [...new Set(_resultados.flatMap(Object.keys))];
    let numericKeys = keys.filter((key) => (KEYS_INVALIDAS.includes(key) === false))
    if (isHorizontalGraph) {
      numericKeys = keys.filter((key) => (key === 'serie'))
    }
    const _minimo = Math.min.apply(
      Math,
      _resultados.map((res) =>
        Math.min.apply(
          Math,
          numericKeys
            .filter((key) => key in res && res[key] !== null) // Solo considera las claves que existen en res y cuyo valor no es null
            .map((key) => res[key])
        )
      )
    );
    const _maximo = Math.max.apply(
      Math,
      _resultados.map((res) =>
        Math.max.apply(
          Math,
          numericKeys
            .filter((key) => key in res && res[key] !== null) // Solo considera las claves que existen en res y cuyo valor no es null
            .map((key) => res[key])
        )
      )
    );
    return { _minimo, _maximo }
  }

  const encontrarLineaMinimaMaxima = () => {
    const _minimo = Math.min.apply(
      Math,
      lineasReferencia.map((item) => item.valor)
    )
    const _maximo = Math.max.apply(
      Math,
      lineasReferencia.map((item) => item.valor)
    )
    return { _minimo, _maximo }
  }

  const getValueRange = () => {
    // Encontrar el valor minimo y maximo de los resultados
    let minimo, maximo;
    if (GRAFICAS_AVANZADAS.includes(chartType)) {
      const { _minimo, _maximo } = encontrarMinimoMaximo(
        resultadosAMostrarAPI || [],
        HORIZONTAL_GRAPH.includes(chartType)
      )
      minimo = _minimo
      maximo = _maximo
    } else {
      const { _minimo, _maximo } = encontrarMinimoMaximo(resultadosAMostrar || [])
      minimo = _minimo
      maximo = _maximo
    }
    const { _minimo: minimoLinea, _maximo: maximoLinea } = encontrarLineaMinimaMaxima()
    if (minimoLinea < minimo && minimoLinea !== 0) minimo = minimoLinea
    if (maximoLinea > maximo) maximo = maximoLinea
    if (showAnalysis && analysisData) {
      if (analysisData['min'] < minimo) minimo = analysisData['min']
      if (analysisData['max'] > maximo) maximo = analysisData['max']
    }

    const margin = (maximo - minimo) * 0.1;
    
    return {
      startValue: chartType === 'bar' ? minimo - margin : minimo,
      endValue: maximo
    }

  }

  const getTipoGraficaBase = () => {
    if (chartType === 'distribucion') return 'line'
    if (chartType === 'histograma') return 'bar'
    return chartType
  }

  const getLineasSPC = () => {
    const array = []
    if (showAnalysis && analysisData) {
      array.push(
        { name: '+3s', value: analysisData['UCL'], color: '#D1111B', dashStyle: 'dot' },
        { name: '+2s', value: analysisData['+2s'], color: '#D1111B', dashStyle: 'dot' },
        { name: '+1s', value: analysisData['+1s'], color: '#D1111B', dashStyle: 'dot' },
        { name: 'Media', value: analysisData['mean'], color: '#5299D3', dashStyle: 'dash' },
        { name: '-1s', value: analysisData['-1s'], color: '#D1111B', dashStyle: 'dot' },
        { name: '-2s', value: analysisData['-2s'], color: '#D1111B', dashStyle: 'dot' },
        { name: '-3s', value: analysisData['LCL'], color: '#D1111B', dashStyle: 'dot' }
      )
    }
    return array
  }

  return (
    <React.Fragment>
      <Grid
        component={Paper}
        container
        elevation={5}
        style={{ flex: 1, display: 'flex' }}
        sx={{
          m: 1,
          border:
            showAnalysis && analysisData
              ? `3px solid ${checkIfAnalysisIsCorrect() ? 'green' : 'red'}`
              : '3px solid transparent',
          width: getCorrectWidth(),
          minHeight: fullScreen ? window.innerHeight : height - 100,
          height: 'auto',
          maxHeight: fullScreen ? window.innerHeight : height + 100,
          overflowY: 'scroll',
          ...getFullScreenProps()
        }}
        key={`${customKey} ${isDrawerOpen}`}
        onDoubleClick={onDoubleClick}
      >
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          {showToolButtons ? (
            <ChartControls
              operacionIntegracion={operacionIntegracion}
              showCumulativeChart={showCumulativeChart}
              additionalButtons={additionalButtons}
              showValueTag={showValueTag}
              showAnalysis={showAnalysis}
              fullScreen={fullScreen}
              setFullScreen={setFullScreen}
              detalleIdProp={detalleIdProp}
              setChartType={setChartType}
              tipoVista={tipoVista}
              setTipoVista={setTipoVista}
              actualizarVistaTipoGraficaFn={actualizarVistaTipoGraficaFn}
              setShowValueTag={setShowValueTag}
              handleCumulativeButtonClick={handleCumulativeButtonClick}
              chartType={chartType}
              checkIfAnalysisIsCorrect={checkIfAnalysisIsCorrect}
              setModalOpen={setModalOpen}
              performStatisticaAnalysis={setShowAnalysis}
              agrupado={agrupado}
              agrupadorGrafica={agrupadorGrafica}
              setAgrupadorGrafica={setAgrupadorGrafica}
            />
          ) : (
            <div className={styles['ajuste-mobile']}>
              <IconButton
                color="secondary"
                onClick={() => {
                  setFullScreen(!fullScreen)
                }}
                title='Pantalla completa'
              >
                <OpenInFullIcon />
              </IconButton>
            </div>
          )}
          {isDataLoading && <ChartSkeleton />}

          {(tipoVista === 'chart' || tipoVista === 'mixed' && !isDataLoading) && (
            <Grafica
              indexChart={indexChart}
              meRenderizo={meRenderizo}
              setMeRenderizo={setMeRenderizo}
              palette={palette}
              resultadosAMostrar={resultadosAMostrar}
              resultadosAMostrarAPI={resultadosAMostrarAPI}
              getTitulo={getTitulo}
              tipoVista={tipoVista}
              chartRef={chartRef}
              customKey={customKey}
              isDrawerOpen={isDrawerOpen}
              chartType={chartType}
              customizeBorder={customizeBorder}
              customizePoint={customizePoint}
              customizeLabel={customizeLabel}
              onPointClick={onPointClick}
              onLegendClick={onLegendClick}
              fullScreen={fullScreen}
              showAnalysis={showAnalysis}
              etiquetaEjeX={etiquetaEjeX}
              graficarEjeXDiscreto={graficarEjeXDiscreto}
              formatoFecha={formatoFecha}
              agrupadorGrafica={agrupadorGrafica}
              getTipoGraficaBase={getTipoGraficaBase}
              titulosAMostrar={titulosAMostrar}
              showValueTag={showValueTag}
              getValueRange={getValueRange}
              lineasReferencia={lineasReferencia}
              getLineasSPC={getLineasSPC}
              hideTooltips={hideTooltips}
              agregarListaComentarios={agregarListaComentarios}
              allowComments={allowComments}
              seriesOcultasProp={seriesOcultasProp}
            />
          )}

          {(showAnalysis && processCapabilitiyData && processCapabilitiyData.dentro_limites) ? (
            <p style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <p
                style={{ backgroundColor: '#FBF719', fontSize: '1.2rem' }}
              >El <strong>{`${processCapabilitiyData.dentro_limites.toFixed(2)}%`}</strong> estuvo dentro de los límites de especificación</p>
            </p>
          ) : null}

          {/* Mostrar Tabla de CP */}
          {showAnalysis && processCapabilitiyData && (
            <CoeficientesCPTable data={processCapabilitiyData} agrupador={agrupadorGrafica} />
          )}

          {(tipoVista === 'table' || tipoVista === 'mixed') && (
            <ChartTable
              dataArray={resultados}
              title={getTitulo()}
              periodos={titulos}
              etiquetaEjeX={etiquetaEjeX}
            />
          )}
        </div>
        {agrupado && (
          <div style={{ width: '100%' }}>
            <Button
              variant="contained"
              onClick={() => {
                updateResultados();
              }}
              sx={{ mt: 3 }}
            >
              Actualizar gráfica
            </Button>
            <AgrupadoTable
              tablaResultados={tablaResultados}
              listaColumnas={listaColumnas}
              esParteRaci={esParteRaci}
              updateResultados={updateResultados}
            />
          </div>
        )}
      </Grid>
      <AnalisisRiesgosModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        analysisData={analysisData}
      />
    </React.Fragment>
  )
}

const Grafica = ({ indexChart, meRenderizo, setMeRenderizo, ...props }) => {

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMeRenderizo(true);
    }, (indexChart + 1) * 200);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (props.seriesOcultasProp && props.seriesOcultasProp.length) {
      if (props.chartRef.current) {
        props.seriesOcultasProp.forEach((serieName) => {
          const serie = props.chartRef.current.instance.getSeriesByName(serieName);
          if (serie) {
            serie.hide();
          }
        });
      }
    }
  }, [props.seriesOcultasProp]);

  if (!meRenderizo) return null;

  return <GraficaMemo {...props} />
}

const GraficaMemo = React.memo(function GraficaMemo({
  palette, resultadosAMostrar, resultadosAMostrarAPI, getTitulo, tipoVista,
  chartRef, customKey, isDrawerOpen, chartType, customizeBorder, customizePoint,
  customizeLabel, onPointClick, onLegendClick, fullScreen, showAnalysis,
  etiquetaEjeX, agrupadorGrafica, getTipoGraficaBase, titulosAMostrar,
  showValueTag, getValueRange, lineasReferencia, getLineasSPC, hideTooltips,
  agregarListaComentarios, allowComments, graficarEjeXDiscreto, formatoFecha }) {

  const [scrollVisible, setScrollVisible] = useState(false);

  const handleZoomEnd = (e) => {
    setScrollVisible(e.shift !== 0);
  };

  const getArgumentField = () => {
    if (chartType === GRAFICAS_AVANZADAS[0]) return 'fecha'
    if (HORIZONTAL_GRAPH.includes(chartType)) return 'serie'
    if (agrupadorGrafica) return 'serie'
    if (graficarEjeXDiscreto) return 'etiquetaDiscreta'
    if (etiquetaEjeX) return 'serie'
    return 'fecha'
  }

  const getArgumentType = () => {
    const argumentField = getArgumentField()
    if (argumentField === 'fecha') return 'datetime'
    return undefined
  }

  const getArgumentLabel = () => {
    let format = null
    const argumentField = getArgumentField()
    if (argumentField === 'fecha') format = formatoFecha || 'dd/MM/yyyy HH:mm'
    return { visible: (!graficarEjeXDiscreto || GRAFICAS_AVANZADAS.includes(chartType) || agrupadorGrafica), format }
  }

  return (
    <Chart
      palette={palette}
      dataSource={resultadosAMostrarAPI || resultadosAMostrar}
      width={(tipoVista === 'chart' || tipoVista === 'mixed') ? '100%' : 0}
      ref={chartRef}
      key={`CH${customKey} ${isDrawerOpen}`}
      redrawOnResize={true}
      customizePoint={['bar'].includes(chartType) ? customizeBorder : !GRAFICAS_AVANZADAS.includes(chartType) ? customizePoint : null}
      customizeLabel={chartType != 'candlestick' && customizeLabel}
      onPointClick={onPointClick}
      onLegendClick={onLegendClick}
      animation={false}
      lazyRendering={true}
      onZoomEnd={handleZoomEnd}
      
    >
      <Title text={getTitulo()} subtitle={allowComments ? TITULOS_GRAFICAS[chartType] : null} />
      <Size
        height={fullScreen ? window.innerHeight - (showAnalysis ? 130 : 50) : undefined}
        width={(tipoVista === 'chart' || tipoVista === 'mixed') ? '100%' : 0}
      />
      <CommonSeriesSettings
        argumentField={getArgumentField()}
        type={getTipoGraficaBase()}
      />
      {titulosAMostrar.map((item, index) => (
        <Series
          key={index}
          valueField={item.value}
          name={item.name}
          dashStyle={item.name.startsWith('Meta') ? 'dash' : 'solid'}
          type={item.name.startsWith('Meta') ? 'line' : getTipoGraficaBase()}
          hoverMode={
            item.name.startsWith('Meta')
              ? 'excludePoints'
              : 'includePoints'
          }
          ignoreEmptyPoints={['candlestick'].includes(chartType)}
          lowValueField={item.value}
          closeValueField="Q1"
          openValueField="Q3"
          highValueField="maximo"
          width={4}
        >
          <Point visible={!item.name.startsWith('Meta')}></Point>
          <Reduction color={palette[index]} />
        </Series>
      ))}
      {chartType == 'candlestick' && showValueTag && titulosAMostrar.filter(item => !item?.value?.startsWith('Meta')).map((item1, index1) => (
        resultadosAMostrarAPI && resultadosAMostrarAPI.filter(item => item1.value in item).map((item, index) => {
          return item.minimo > 0 && (
            <Annotation
              series={item1.value}
              key={`candlestick-q2-annotation${index}${index1}`}
              argument={item.fecha}
              type={'text'}
              text={
                `Min: ${item.minimo}
                          Q1: ${item.Q1}
                          Q2: ${item.Q2}
                          Q3: ${item.Q3}
                          Max: ${item.maximo}`
              }
              offsetX={-60}
            />
          )
        })
      ))}
      {['candlestick'].includes(chartType) && (
        <Series
          name=" "
          type={'stock'}
          openValueField="Q2"
          highValueField="Q2"
          lowValueField="Q2"
          closeValueField="Q2"
          color={'white'}
          width={2}
          ignoreEmptyPoints={true}
        >
          <Reduction color={'white'} />
        </Series>
      )}
      <Margin bottom={20} />
      <ArgumentAxis
        name="lineasReferencia"
        showZero={true}
        visualRange={(HORIZONTAL_GRAPH.includes(chartType)) ? getValueRange() : {}}
        label={getArgumentLabel()}
        argumentType={getArgumentType()}
      >
        {(HORIZONTAL_GRAPH.includes(chartType)) && (
          lineasReferencia.map((_item, index) => (
            <ConstantLine
              key={index}
              value={_item.valor}
              width={2}
              color="#5E5C6C"
              dashStyle="dash"
            >
              <Label
                text={`${_item.nombre} (${_item.valor})`}
                horizontalAlignment="left"
                font={{ color: '#5E5C6C', weight: '700' }}
              />
            </ConstantLine>
          ))
        )}
        {(HORIZONTAL_GRAPH.includes(chartType)) && (
          getLineasSPC().map((_item, index) => (
            <ConstantLine
              key={index}
              value={_item.value}
              width={2}
              color={_item.color}
              dashStyle={_item.dashStyle}
            >
              <Label
                text={_item.name}
                verticalAlignment="bottom"
                font={{ color: _item.color, weight: '700' }}
              />
            </ConstantLine>
          ))
        )}
      </ArgumentAxis>
      <ValueAxis
        name="lineasReferencia"
        showZero={true}
        visualRange={(!HORIZONTAL_GRAPH.includes(chartType)) ? getValueRange() : {}}
      >
        {(!HORIZONTAL_GRAPH.includes(chartType)) && (
          lineasReferencia.map((_item, index) => (
            <ConstantLine
              key={index}
              value={_item.valor}
              width={2}
              color="#5E5C6C"
              dashStyle="dash"
            >
              <Label
                text={`${_item.nombre} (${_item.valor})`}
                horizontalAlignment="left"
                font={{ color: '#5E5C6C', weight: '700' }}
              />
            </ConstantLine>
          ))
        )}
        {(!HORIZONTAL_GRAPH.includes(chartType)) && (
          getLineasSPC().map((_item, index) => (
            <ConstantLine
              key={index}
              value={_item.value}
              width={2}
              color={_item.color}
              dashStyle={_item.dashStyle}
            >
              <Label
                text={_item.name}
                horizontalAlignment="right"
                font={{ color: _item.color, weight: '700' }}
              />
            </ConstantLine>
          ))
        )}
        <Label />
      </ValueAxis>
      <Legend
        verticalAlignment="bottom"
        horizontalAlignment="center"
        itemTextPosition="bottom"
      />
      <Export enabled={true} />
      {allowComments ? (
        <Tooltip
          enabled={false}
          contentComponent={(props) => (
            <TooltipTemplateWithComments
              {...props}
              showComments={GRAFICAS_AVANZADAS.includes(chartType) ? false : true}
              hideTooltips={hideTooltips}
              agregarListaComentarios={agregarListaComentarios}
            />
          )}
          interactive
        />
      ) : (
        <Tooltip
          enabled={false}
          contentComponent={(props) => (
            <TooltipTemplate hideTooltips={hideTooltips} {...props} />
          )}
          interactive
        />
      )}

      <ZoomAndPan
        panKey="shift"
        dragToZoom={true}
        valueAxis="both"
        argumentAxis="both"
      />
      <ScrollBar
        opacity={0.5}
        position='bottom'
        visible={scrollVisible}
        width={fullScreen ? 10 : 5}
      />
    </Chart>
  )
})

export { CustomizedGraph }
