import React from "react";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import { deleteObjetivo } from "../Api/objetivosPilar.api";
import Confirm from "../general-components/confirm";
import { Divider, IconButton, Tooltip } from "@mui/material";
import SellIcon from '@mui/icons-material/Sell';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

function Objetivo({ objetivo, setModalOpen, setObjetivoActivo, obtenerObjetivos }) {
  const [selected, setSelected] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);

  const removerObjetivo = () => {
    deleteObjetivo({ id: objetivo.id }).then(response => {
      if (response.status === 200) {
        toast.success("Objetivo eliminado exitosamente!");
        obtenerObjetivos();
        setOpenConfirm(false);
      } else {
        toast.error(response.message);
      }
    }).catch(error => {
      toast.error('Error al momento de eliminar el objetivo');
    });
  }

  return (
    <div>
      <section
        style={{
          padding: "10px 0",
          display: "flex",
          alignItems: "center",
          margin: "0px 3%",
        }}
      >
        <SellIcon
          fontSize="large"
          color={`${selected ? "primary" : "inherit"}`}
          style={{ cursor: "pointer" }}
          onMouseOver={() => setSelected(true)}
          onMouseOut={() => setSelected(false)}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div>
              <Tooltip title="Editar">
                <IconButton
                  onClick={() => {
                    setModalOpen(true);
                    setObjetivoActivo(objetivo);
                  }}
                >
                  <EditIcon color="primary" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar">
                <IconButton onClick={() => setOpenConfirm(true)}>
                  <DeleteForeverIcon color="error" />
                </IconButton>
              </Tooltip>
            </div>
            <b>{objetivo.nombre}</b>
          </div>
          <p style={{ textAlign: "left", fontSize: "14px" }}>
            Pilar:&nbsp;{objetivo.ObjetivoDesempeno?.nombre}
          </p>
        </div>
      </section>

      <div style={{ width: "100%", justifyContent: "center" }}>
        <Divider sx={{ width: "96%", marginLeft: "2%" }} />
      </div>
      <Confirm
        open={openConfirm}
        title={"Eliminar"}
        text={"¿Confirma que desea eliminar este objetivo?"}
        onConfirm={removerObjetivo}
        handleCloseProp={() => setOpenConfirm(false)}
      />
    </div>
  );
}

export { Objetivo };
