import { HOST } from '../Utils/config'
import { getRequest, putRequest } from './requests'

async function getValueMapPositions(valueMapId) {
  return await getRequest(`${HOST}/api/v1/valueMapPosiciones/${valueMapId}`)
}

async function putValueMapPositions(positions) {
  return await putRequest(`${HOST}/api/v1/valueMapPosiciones`, positions)
}

export { getValueMapPositions, putValueMapPositions }
