import {
  Button,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { DataGrid } from 'devextreme-react'
import {
  Column,
  FilterPanel,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Selection
} from 'devextreme-react/data-grid'
import themes from 'devextreme/ui/themes'
import React from 'react'
import { toast } from 'react-toastify'
import { getPeriodos } from '../Api/periodo.api'
import { NodoContext } from '../Context/nodoContext'
import WestIcon from '@mui/icons-material/West'
import EastIcon from '@mui/icons-material/East'
import { PermisionDeniedBar } from '../general-components/permisionDeniedBar'
import {
  asignarUsuariosABono,
  desasignarUsuariosABono,
  getUsuariosBono
} from '../Api/usuarioBono.api'
import { useDownloadExcel } from '../Hooks/useDownloadExcel'
import { Export } from 'devextreme-react/chart'
import { useNavigate } from 'react-router-dom'
import { PeriodoContext } from '../Context/periodoContext'

function AsignacionUsuariosDesempeno() {
  const [periodos, setPeriodos] = React.useState([])
  const [periodoId, setPeriodoId] = React.useState('')
  const [permissionDenied, setPermissionDenied] = React.useState(false)
  const [usuariosBono, setUsuariosBono] = React.useState([])
  const [usuariosSinBono, setUsuariosSinBono] = React.useState([])
  const todoUsuarioRef = React.useRef()
  const usuariosAsignadosRef = React.useRef()
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext)
  const { getUnicoPeriodoSeleccionado } = React.useContext(PeriodoContext)

  React.useEffect(() => {
    updatePeriodos()
    const periodo = getUnicoPeriodoSeleccionado()
    if (periodo) {
      setPeriodoId(periodo.id)
    }
  }, [])

  React.useEffect(() => {
    if (periodoId) {
      updateUsuariosBono()
    } else {
      setUsuariosBono([])
      setUsuariosSinBono([])
    }
  }, [periodoId])

  function updatePeriodos() {
    setLoading(true)
    setLoadingMessage('Cargando...')
    getPeriodos().then((response) => {
      setLoading(false)
      if (response.status === 200) {
        setPeriodos(response.body)
      } else if (response.status === 403) {
        setPermissionDenied(true)
        setPeriodos([])
        setPeriodoId('')
      } else {
        toast.error('Error al cargar los roles')
      }
    })
  }

  function updateUsuariosBono() {
    setLoading(true)
    setLoadingMessage('Cargando...')
    getUsuariosBono(periodoId).then((response) => {
      setLoading(false)
      if (response.status === 200) {
        setUsuariosBono(response.body.conBono)
        setUsuariosSinBono(response.body.sinBono)
      } else if (response.status === 403) {
        setPermissionDenied(true)
        setPeriodos([])
        setPeriodoId('')
      } else {
        toast.error('Error al cargar los usuarios asignados')
      }
    })
  }

  function asignar() {
    const usuarios = todoUsuarioRef.current.instance
      .getSelectedRowsData()
      .map((usuario) => usuario.id)
    if (usuarios.length === 0)
      return toast.warning('No seleccionó ningún usuario')
    setLoading(true)
    asignarUsuariosABono({ periodoId, usuarios }).then((response) => {
      setLoading(false)
      if (response.status === 201) {
        toast.success('Usuarios asignados')
        updateUsuariosBono()
      } else {
        toast.error('Error al asignar los usuarios')
      }
    })
  }

  function desasignar() {
    const registros = usuariosAsignadosRef.current.instance
      .getSelectedRowsData()
      .map((registro) => registro.id)
    if (registros.length === 0)
      return toast.warning('No seleccionó ningún registro')
    setLoading(true)
    desasignarUsuariosABono({ registros }).then((response) => {
      setLoading(false)
      if (response.status === 200) {
        toast.success('Usuarios desasignados')
        updateUsuariosBono()
      } else {
        toast.error('Error al desasignar los usuarios')
      }
    })
  }

  return (
    <>
      <PermisionDeniedBar display={permissionDenied} />
      <Typography variant="h4" gutterBottom>
        Asignación de usuarios a evaluación de desempeño
      </Typography>

      <Typography
        variant="h6"
        gutterBottom
        sx={{ textAlign: 'left', marginLeft: '5%', marginBottom: '10px' }}
      >
        Seleccione un periodo:
      </Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '90%',
          justifyContent: 'center',
          margin: '0 auto'
        }}
      >
        <TextField
          id="periodo"
          select
          label="Seleccione un periodo"
          value={periodoId}
          onChange={(e) => setPeriodoId(e.target.value)}
          required
          fullWidth
          sx={{ textAlign: 'left' }}
        >
          {periodos.map((periodo) => (
            <MenuItem key={periodo.id} value={periodo.id}>
              {periodo.nombre}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: window.innerWidth < 800 ? 'wrap' : 'nowrap',
          justifyContent: 'space-between'
        }}
      >
        <section style={{ padding: '15px' }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ textAlign: 'center', marginBottom: '10px' }}
          >
            Todos los usuarios
          </Typography>
          <div style={{ height: 'calc(100vh - 325px)', overflow: 'scroll' }}>
            <RolesDataGrid
              propRef={todoUsuarioRef}
              dataSource={usuariosSinBono}
            />
          </div>
        </section>
        <section
          style={{
            padding: '15px',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Tooltip title="Asignar" placement="top">
            <IconButton aria-label="asignar" onClick={asignar}>
              <EastIcon fontSize="large" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Desasignar">
            <IconButton aria-label="desasignar" onClick={desasignar}>
              <WestIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </section>
        <section style={{ padding: '15px' }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ textAlign: 'center', marginBottom: '10px' }}
          >
            Usuarios con bono
          </Typography>
          <div style={{ height: 'calc(100vh - 325px)', overflow: 'scroll' }}>
            <RolesDataGrid
              propRef={usuariosAsignadosRef}
              dataSource={usuariosBono}
              validacionInd
            />
          </div>
        </section>
      </div>
    </>
  )
}

function RolesDataGrid({ propRef, dataSource, validacionInd }) {
  const { onExporting } = useDownloadExcel()

  const nombreRender = (cell) => {
    if (validacionInd && !cell.data.conIndicadores) {
      return (
        <Tooltip title="No tiene indicadores asignados">
          <div style={{ color: 'red' }}>
            {cell.data.nombreCompleto}
          </div>
        </Tooltip>
      )
    } else if (validacionInd && cell.data.pesoTotal !== 100) {
      return (
        <Tooltip title="El peso total no es 100%">
          <div style={{ color: 'red' }}>
            {cell.data.nombreCompleto}
          </div>
        </Tooltip>
      )
    }
    return ( cell.data.nombreCompleto)
  }

  return (
    <DataGrid
      ref={propRef}
      dataSource={dataSource}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnResizingMode="nextColumn"
      onExporting={onExporting}
      // defaultColumns={columns}
      // rowRender={plantillaFila}
    >
      <Selection
        mode="multiple"
        selectAllMode={'allMode'}
        showCheckBoxesMode={
          themes.current().startsWith('material') ? 'always' : 'onClick'
        }
      />

      <Column dataField="id" caption="id" dataType="number" visible={false} />
      <Column
        dataField="nombreCompleto"
        caption="Nombre Completo"
        dataType="string"
        visible={true}
        cellRender={nombreRender}
      />
      <Column
        dataField="usuario"
        caption="Usuario"
        dataType="string"
        visible={true}
      />
      <Column
        dataField="departamento"
        caption="Gerencia"
        dataType="string"
        visible={true}
      />

      <FilterRow visible={true} />
      <FilterPanel visible={false} />
      {/* <FilterBuilderPopup position={filterBuilderPopupPosition} /> */}
      <HeaderFilter visible={true} />
      {/* <Scrolling mode="infinite" /> */}

      {/* <GroupPanel visible={true} /> */}
      {/* <SearchPanel visible={true} highlightCaseSensitive={true} /> */}
      {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}
      <Paging defaultPageSize={50} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[50, 100, 150, 200]}
        showInfo={true}
      />
      <Export enabled={true} allowExportSelectedData={true} />
    </DataGrid>
  )
}

export { AsignacionUsuariosDesempeno }
