import { HOST } from '../Utils/config'
import { deleteRequest, getRequest, postRequest, putRequest } from './requests'

async function getDashboardEvaluacion(evaluacionId) {
    return await getRequest(
        `${HOST}/api/v1/evaluacionesDesempeno/${evaluacionId || 0}`
    )
}

export {
    getDashboardEvaluacion
}
