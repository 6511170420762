import React, { useEffect, useState } from 'react'
import { ToggleButton, ToggleButtonGroup, Tooltip, IconButton } from '@mui/material';
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import PersonIcon from "@mui/icons-material/Person";
import { TreeView } from '@mui/x-tree-view';
import { SimpleTreeView } from '@mui/x-tree-view';
import { useEstructuraPuestos } from '../Hooks/useEstructuraPuestos'
import SearchInput from '../TreeView/searchInput'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import RefreshIcon from '@mui/icons-material/Refresh';
import StyledEmpleadoItem from '../EstructuraPuestos/styledEmpleadoItem';
import { PeriodoContext } from '../Context/periodoContext';
import { MessageBar } from '../general-components/messageBar';

export function DesempenoMain() {
  const { estructuraPuestos, updateEstructura } = useEstructuraPuestos()
  const { getUnicoPeriodoSeleccionado } = React.useContext(PeriodoContext);
  const [busqueda, setBusqueda] = useState(null);
  const [filtroBono, setFiltroBono] = useState(null);
  const [empleadosFiltrados, setEmpleadosFiltrados] = useState([])
  const [expanded, setExpanded] = useState([]);
  const { usuarioId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (estructuraPuestos.length)
      setEmpleadosFiltrados(filtrarEstructura(estructuraPuestos, busqueda, filtroBono));
  }, [estructuraPuestos])

  function obtenerUsuarios(empleados) {
    return empleados.reduce((acumulador, empleado) => {
      acumulador.push(empleado.usuario);
      if (empleado.subordinados) {
        acumulador.push(...obtenerUsuarios(empleado.subordinados));
      }
      return acumulador;
    }, []);
  }

  function obtenerPrimerNivel(estructura) {
    return estructura.map((empleado) => empleado.usuario);
  }

  useEffect(() => {
    if (empleadosFiltrados.length) {
      if (busqueda || filtroBono) {
        const extendidos = obtenerUsuarios(empleadosFiltrados);
        setExpanded(extendidos);
      } else {
        setExpanded(obtenerPrimerNivel(empleadosFiltrados));
      }
    }
  }, [empleadosFiltrados])

  useEffect(() => {
    const valorGuardado = localStorage.getItem('filtroBono');
    if (parseInt(valorGuardado) === 1) {
      setFiltroBono(1);
    }
  }, []);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  function filtrarArbol(estructura, filtro) {
    return estructura.reduce((acumulador, nodo) => {
      const subordinadosFiltrados = nodo.subordinados ? filtrarArbol(nodo.subordinados, filtro) : [];

      if (filtro(nodo) || subordinadosFiltrados.length > 0) {
        acumulador.push({
          ...nodo,
          subordinados: subordinadosFiltrados
        });
      }

      return acumulador;
    }, []);
  };

  function filtrarEstructura(estructura, searchValue, filterValue) {
    let filtro = empleado => filterValue === null || empleado.conBonoZafra > 0;

    if (searchValue !== null) {
      const regex = new RegExp(searchValue, 'i');
      filtro = empleado => (
        (
          regex.test(empleado.nombre) || regex.test(empleado.puesto)
        ) && (filterValue === null || empleado.conBonoZafra > 0)
      );
    }

    return filtrarArbol(estructura, filtro);
  }

  const handleFiltroBono = (event, value) => {
    setFiltroBono(value);
    localStorage.setItem('filtroBono', JSON.stringify(value));
    setEmpleadosFiltrados(filtrarEstructura(estructuraPuestos, busqueda, value));
  };

  const filterBuscador = (e) => {
    setBusqueda(e.target.value);
    // setEmpleadosFiltrados(filtrarEstructura(estructuraPuestos, e.target.value, filtroBono));
  }

  useEffect(() => {
    const delay = 300;
    if (busqueda && busqueda.length >= 3) {
      const timerId = setTimeout(() => {
        setEmpleadosFiltrados(filtrarEstructura(estructuraPuestos, busqueda, filtroBono));
      }, delay);

      return () => {
        clearTimeout(timerId);
      };
    } else if (!busqueda) {
      setEmpleadosFiltrados(filtrarEstructura(estructuraPuestos, busqueda, filtroBono));
    }
  }, [busqueda]);

  function navigateToEmpleado(empleado) {
    navigate(`${empleado.id}`)
  }

  if (usuarioId) return <Outlet />

  if (getUnicoPeriodoSeleccionado() === null)
    return (
      <MessageBar
        display={true}
        type="error"
        message="Debe de seleccionar un único periodo para asignar pesos de evaluación"
        title="Error"
      />
    )

  return (
    <div>
      <section>
        <h1>Gestionar evaluaciones del personal a mi cargo</h1>
      </section>
      <section style={{ display: 'flex', margin: '20px' }}>
        <div style={{
          display: 'flex',
          flexGrow: 1,
          // width: '250px',
          border: '1px solid black',
          borderRadius: '20px'
        }}>
          <SearchInput onChange={filterBuscador} />
        </div>
        <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-start' }}>
          <Tooltip
            title="Actualizar la estructura organizacional"
            // enterDelay={DEFAULT_DELAY}
          >
            <IconButton onClick={() => updateEstructura()}>
              <RefreshIcon color="primary" />
            </IconButton>
          </Tooltip>
        </div>
        <div style={{ display: 'flex', flexGrow: 2, justifyContent: 'flex-end' }}>
          <Tooltip title="Filtrar asignados a bono zafra">
            <ToggleButtonGroup
              value={filtroBono}
              size="small"
              exclusive
              onChange={handleFiltroBono}
              color="primary"
            >
              <ToggleButton value={1} >
                <MilitaryTechIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Tooltip>
        </div>
      </section>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <SimpleTreeView
          defaultCollapseIcon={<ExpandMoreIcon fontSize='medium' />}
          defaultExpandIcon={<ChevronRightIcon fontSize='large' />}
          // expanded={expanded}
          expandedItems={expanded}
          onExpandedItemsChange={handleToggle}
          // onNodeToggle={handleToggle}
          // defaultExpanded={empleadosFiltrados.length ? [[empleadosFiltrados[0]].usuario] : []}
          defaultEndIcon={<div style={{ width: 24 }} />}
          sx={{ flexGrow: 1, maxWidth: '80%', overflowY: "auto", marginTop: 0, marginBottom: 4 }}
        >
          {empleadosFiltrados.map((empleado) => {
            return (
              <StyledEmpleadoItem
                nodeId={`${empleado.usuario}`}
                id={empleado.id}
                labelText={empleado.nombre}
                puesto={empleado.puesto}
                labelIcon={PersonIcon}
                item={empleado}
                items={empleado.subordinados}
                level={2}
                key={empleado.id}
                color="#000"
                bgColor="#aebd36"
                onClick={navigateToEmpleado}
                puestoFontSize="18px"
                nombreFontSize="16px"
                eventIcon={ArrowForwardIcon}
                factor={2}
                conPeso
              ></StyledEmpleadoItem>
            )
          })}
        </SimpleTreeView>
      </div>
    </div>
  )
}
