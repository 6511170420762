import React from "react";
import { Handle, Position } from "react-flow-renderer";
import { Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LinearProgressWithLabel } from "../general-components/prograssBar";
import { CustomProgressBar } from "../general-components/customProgressBar";

let customNodeStyles = {
  background: "#fff",
  color: "#000",
  padding: 2,
  border: `solid 1px black`,
  borderRadius: 5,
  width: 220,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
};

const NodoSipocActividadesHijas = ({ data, selected }) => {
  const navigate = useNavigate();

  function navigateToActivity() {
    navigate(`/home/procesos/${data.id}`);
  }

  return (
    <div
      style={{
        ...customNodeStyles,
        borderStyle: selected ? "dashed" : "solid",
        resize: selected ? "none" : "both",
        backgroundColor: data.actividadExterna
          ? "#FFF"
          : "rgba(42, 111, 179, 0.35)",
      }}
      onDoubleClick={navigateToActivity}
    >
      <Handle
        type="source"
        position={Position.Right}
        style={{ borderRadius: 50 }}
        id="inputSource"
      />
      <div style={{ width: "90%", height: "100%" }}>
        <Typography
          variant="body2"
          gutterBottom
          component="div"
          style={{ fontWeight: "bold" }}
        >
          <h3>{data.text}</h3>
          {data.mostrarUltimosResultados && (
            <div>
              <Divider />
              {data.ultimosResultados?.map((resultado, index) => (
                <div key={index} style={{ textAlign: "left" }}>
                  <i style={{ fontSize: "12px" }}>
                    <b>{resultado.nombre}:</b>
                  </i>
                  <CustomProgressBar
                    score={resultado.valorNumerico}
                    goal={resultado.meta}
                    inverseGoal={resultado.metaInversa}
                    barColor={
                      resultado.valorNumerico >= resultado.meta
                        ? resultado.metaInversa? "red": "green"
                        : resultado.metaInversa? "green" : "red"
                    }
                  />
                </div>
              ))}
            </div>
          )}
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          component="div"
          dangerouslySetInnerHTML={{
            __html: data.info,
          }}
        ></Typography>
      </div>
      <Handle
        type="target"
        position={Position.Left}
        style={{ borderRadius: 50 }}
        id="inputTarget"
      />
    </div>
  );
};

export { NodoSipocActividadesHijas };
