import { HOST } from "../Utils/config";
import { getRequest, postRequest, putRequest, deleteRequest } from "./requests";

async function getNiveles() {
  return await getRequest(`${HOST}/api/v1/nivelOrganizacional`);
}

async function getNivelesPrivado(idMenu) {
  return await getRequest(`${HOST}/api/v1/nivelOrganizacional/private?idMenu=${idMenu}`);
}

async function getNivel(id) {
  return await getRequest(`${HOST}/api/v1/nivelOrganizacional/${id}`);
}

async function postNivel(data) {
  return await postRequest(`${HOST}/api/v1/nivelOrganizacional`, data);
}

async function putNivel(data) {
  return await putRequest(`${HOST}/api/v1/nivelOrganizacional`, data);
}

async function deleteNivel(data) {
  return await deleteRequest(`${HOST}/api/v1/nivelOrganizacional`, data);
}

export { getNivel, getNivelesPrivado, getNiveles, postNivel, putNivel, deleteNivel };
