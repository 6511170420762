import { HOST } from "../Utils/config";
import { deleteRequest, getRequest, postRequest, putRequest } from "./requests";


async function getResultadosGraficas(tipoRaciId, ID) {
  return await getRequest(
    `${HOST}/api/v1/resultados/graficas?raciId=${tipoRaciId}&ID=${ID}`
  );
}

async function getResultadosCompletos(tipoRaciId, fechaFin) {
  return await getRequest(
    `${HOST}/api/v1/resultados/listadoCompleto?tipoRaciId=${tipoRaciId}&fechaFin=${fechaFin}`
  );
}

async function getResumenResultados(ID) {
  return await getRequest(
    `${HOST}/api/v1/resultados/resumen?ID=${ID}`
  );
}

async function getResumenResultadoMisSubalternos() {
  return await getRequest(
    `${HOST}/api/v1/resultados/resumenSubalternos/qpm`
  );
}

async function getAvanceSubalternos() {
  return await getRequest(
    `${HOST}/api/v1/resultados/avancesubalternos/qpm`
  );
}
async function getAvanceUsuario(raciId) {
  return await getRequest(
    `${HOST}/api/v1/resultados/avanceusuario/qpm?raciid=${raciId}`
  );
}

async function getResultadosPorIndicador(tipoRaciId, indicadorId) {
  return await getRequest(
    `${HOST}/api/v1/resultados/indicador?tipoRaciId=${tipoRaciId}&indicadorId=${indicadorId}`
  );
}

async function getResultadosPorIndicadorUsuario(usuarioId, indicadorId) {
  return await getRequest(
    `${HOST}/api/v1/resultados/indicadorusuario?usuarioId=${usuarioId}&indicadorId=${indicadorId}`
  );
}

async function getResultadoIndividual(indicadorId, tipoIndicadorId) {
  return await getRequest(`${HOST}/api/v1/resultados/graficas/individuales?indicadorId=${indicadorId}&tipoIndicador=${tipoIndicadorId}`);
}

async function getMetas(nodoId) {
  return await getRequest(`${HOST}/api/v1/resultados/metas/${nodoId}`);
}

async function getResultadosByIndicadorYPeriodo(indicadorId, periodoId) {
  return await getRequest(
    `${HOST}/api/v1/resultados/graficas/indicador?indicadorId=${indicadorId}&periodoId=${periodoId}`
  );
}

async function putResultado(resultado) {
  return await putRequest(`${HOST}/api/v1/resultados`, resultado);
}

async function putMetas(data) {
  return await putRequest(`${HOST}/api/v1/resultados/metas`, data);
}

async function creaResultado(data) {
  return await postRequest(`${HOST}/api/v1/resultados/creaResultado`, data);
}

async function eliminaResultado(id){
  return await deleteRequest(`${HOST}/api/v1/resultados/${id}`);
}

async function getLineasReferencia(nodoId) {
  return await getRequest(`${HOST}/api/v1/resultados/lineasReferencia/${nodoId}`);
}

async function putLineaReferencia(data) {
  return await putRequest(`${HOST}/api/v1/resultados/lineaReferencia`, data);
}

async function putLineasReferencia(data) {
  return await putRequest(`${HOST}/api/v1/resultados/lineasReferencia`, data);
}

async function postLineasReferencia(data) {
  return await postRequest(`${HOST}/api/v1/resultados/generarLineasReferencia`, data);
}

export {
  getResultadosGraficas,  
  putResultado,
  putMetas,
  getResultadosByIndicadorYPeriodo,
  getResultadosCompletos,
  getResultadosPorIndicador,
  getResumenResultados,
  getResultadoIndividual,
  getMetas,
  creaResultado,
  eliminaResultado,
  getResultadosPorIndicadorUsuario,
  getResumenResultadoMisSubalternos,
  getAvanceSubalternos,
  getAvanceUsuario,
  getLineasReferencia,
  putLineaReferencia,
  putLineasReferencia,
  postLineasReferencia
};
