import Modal from "../general-components/modal";
import React from "react";
import { RolesForm } from "./rolesForm";

function RolesModal({ open, setOpen, updateRoles, rolActivo }) {
  function handleCloseModal() {
    setOpen(false);
  }
  return (
    <Modal open={open} handleClose={handleCloseModal} title={rolActivo ? "Editar Rol" : "Nuevo Rol"}>
      <RolesForm
        setOpen={setOpen}
        updateRoles={updateRoles}
        rolActivo={rolActivo}
      />
    </Modal>
  );
}

export { RolesModal };
