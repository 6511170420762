import { React, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import FolderIcon from '@mui/icons-material/Folder';
import SettingsIcon from '@mui/icons-material/Settings';
import CalculateIcon from '@mui/icons-material/Calculate';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate } from "react-router-dom";



export default function SearchItem({ item, closeModal }) {
    const navigate = useNavigate();

    const renderIcon = () => {
        if (item.tipoNodoId === 3) {
            return <SettingsIcon />
        } else if (item.tipoNodoId === 4) {
            return <CalculateIcon />
        } else {
            return <FolderIcon />
        }
    }

    return (
        <div style={{ margin: 15, cursor: "pointer" }}>
            <Paper onClick={() => {
                closeModal();
                if (item.tipoNodoId === 4) {
                    navigate(`../home/procesos/${item.nodoId}/indicadores?indicadorId=${item.id}`);
                } else {
                    navigate(`../home/procesos/${item.id}/indicadores`);
                }
            }}>
                <Grid container spacing={3}>
                    <Grid item xs={4} lg={2} sm={4}>
                        {renderIcon()}
                    </Grid>
                    <Grid item xs={6} lg={10} sm={6}>
                        <p><strong>{`${item.codigo} ${item.nombre}`}</strong></p>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}