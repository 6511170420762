import React, { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify'
import {
  Grid,
  Radio,
  Button,
  Tooltip,
  Checkbox,
  MenuItem,
  TextField,
  FormLabel,
  RadioGroup,
  FormControl,
  InputAdornment,
  FormControlLabel,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import { TIPOS_PERIODOS_INDICADOR } from '../../Utils/enums';
import { AccordionOptions } from '../../Indicadores/accordionOptions';
import { AccordionSection } from '../../general-components/accordionSection';
import { getModosIntegracionGlobal } from '../../Api/modoIntergacion.api'
import { getUnidadMedidaIndicador, getUnidadesMedida } from '../../Api/unidadMedidaIndicador.api'

const tituloMetaInversa = 'En las metas inversas se busca que el resultado no sobrepase el valor de la meta'
const tituloMetaMedicion = 'La meta por medición es el valor contra que se compara el resultado en cada una de las mediciones creadas'
const tituloMetaConsolidada = 'La meta consolidada es el valor contra el que se compara el resultado final consolidado'
const tituloIntegracionGlobal = 'El modo de integración global define cómo se consolidan los resultados de las mediciones'

const KRForm = (props) => {
  const [nombre, setNombre] = React.useState('')
  const [descripcion, setDescripcion] = React.useState('')
  const [unidadDeMedida, setUnidadDeMedida] = useState('')
  const [fuenteDeDatos, setFuenteDeDatos] = useState('')
  const [metaConsolidada, setMetaConsolidada] = useState(0)
  const [metaPorMedicion, setMetaPorMedicion] = useState(0)
  const [unidadMedidaId, setUnidadMedidaId] = useState('')
  const [valorFrecuencia, setValorFrecuencia] = useState(0)
  const [modoIntegracionGlobalId, setModoIntegracionGlobalId] = useState('')
  const [permiteSobregiro, setPermiteSobregiro] = useState(false)
  const [limiteSobregiro, setLimiteSobregiro] = useState(null)
  const [metaInversa, setMetaInversa] = useState(false)
  const [tipoMedicionPeriodo, setTipoMedicionPeriodo] = useState(TIPOS_PERIODOS_INDICADOR.COMPLETO)
  const [medicionIniciaLunes, setMedicionIniciaLunes] = useState(false)

  const [modosIntegracionGlobal, setModosIntegracionGlobal] = useState([])
  const [frecuenciasIndicador, setFrecuenciasIndicador] = useState([])
  const [unidadesMedida, setUnidadesMedida] = useState([])

  const [expanded, setExpanded] = React.useState({ definicion: true })

  const handleChange = (panel) => {
    setExpanded({ ...expanded, [panel]: !expanded[panel] })
  }

  const handleContraerTodos = () => {
    const newExpanded = {}
    for (const key in expanded) {
      newExpanded[key] = false
    }
    setExpanded(newExpanded)
  }

  const handleExpandirTodos = () => {
    const newExpanded = {}
    for (const key in expanded) {
      newExpanded[key] = true
    }
    setExpanded(newExpanded)
  }

  useEffect(() => {
    updateModosIntegracionGlobal()
    updateFrecuenciasIndicador()
    updateUnidadesMedida()
  }, [])

  useEffect(() => {
    limpiarForm()
    if (props.itemKR) {
      setNombre(props.itemKR?.nombre)
      setDescripcion(props.itemKR?.descripcion)
      setFuenteDeDatos(props.itemKR?.evaluacion?.fuenteDeDatos)
      setUnidadDeMedida(props.itemKR?.evaluacion?.unidadDeMedida)
      setValorFrecuencia(props.itemKR?.evaluacion?.valorFrecuencia)
      setUnidadMedidaId(props.itemKR?.evaluacion?.unidadMedidaId)
      setTipoMedicionPeriodo(props.itemKR?.evaluacion?.tipoMedicionPeriodo)
      setMedicionIniciaLunes(props.itemKR?.evaluacion?.medicionIniciaLunes)
      setModoIntegracionGlobalId(props.itemKR?.evaluacion?.modoIntegracionGlobalId)
      setMetaPorMedicion(props.itemKR?.evaluacion?.metaPorMedicion)
      setMetaConsolidada(props.itemKR?.evaluacion?.metaConsolidada)
      setMetaInversa(props.itemKR?.evaluacion?.metaInversa)
      setPermiteSobregiro(props.itemKR?.evaluacion?.permiteSobregiro)
      setLimiteSobregiro(props.itemKR?.evaluacion?.limiteSobregiro)
    }

  }, [props.itemKR])

  function updateModosIntegracionGlobal() {
    getModosIntegracionGlobal().then((response) => {
      if (response.status === 200) {
        setModosIntegracionGlobal(response.body)
      } else {
        toast.error(response.message)
      }
    })
  }

  function updateUnidadesMedida() {
    getUnidadesMedida().then((response) => {
      if (response.status === 200) {
        setUnidadesMedida(response.body)
      } else {
        toast.error(response.message)
      }
    })
  }

  function updateFrecuenciasIndicador() {
    getUnidadMedidaIndicador().then((response) => {
      if (response.status === 200) {
        setFrecuenciasIndicador(response.body)
      } else {
        toast.error(response.message)
      }
    })
  }

  function checkForm() {
    if (
      nombre.length > 0 &&
      unidadDeMedida.length > 0 &&
      fuenteDeDatos.length > 0 &&
      descripcion.length > 0 &&
      modoIntegracionGlobalId.length !== '' &&
      valorFrecuencia > 0 &&
      tipoMedicionPeriodo > 0 &&
      unidadMedidaId !== ''
    ) {
      return true
    } else {
      toast.error('Debe completar todos los campos')
      return false
    }
  }

  const limpiarForm = () => {
    setNombre('')
    setDescripcion('')
    setFuenteDeDatos('')
    setUnidadDeMedida('')
    setMetaConsolidada(0)
    setMetaPorMedicion(0)
    setUnidadMedidaId('')
    setValorFrecuencia(0)
    setModoIntegracionGlobalId('')
    setPermiteSobregiro(false)
    setLimiteSobregiro(null)
    setMetaInversa(false)
    setTipoMedicionPeriodo(TIPOS_PERIODOS_INDICADOR.COMPLETO)
    setMedicionIniciaLunes(false)
  }

  const cerrarForm = () => {
    limpiarForm()
    if (props.activeIndex === 2) props.handleBack()
    props.handleBack()
  }

  const handleSave = () => {
    if (!checkForm()) return

    const kr = {
      nombre,
      descripcion,
      evaluacion: {
        fuenteDeDatos,
        unidadDeMedida,
        valorFrecuencia,
        unidadMedidaId,
        tipoMedicionPeriodo,
        medicionIniciaLunes,
        modoIntegracionGlobalId,
        metaPorMedicion,
        metaConsolidada,
        metaInversa,
        permiteSobregiro,
        limiteSobregiro,
      }
    }
    if (props.itemKR) kr.id = props.itemKR.id
    if (props.itemKR) kr.index = props.itemKR.index
    props.onSave(kr)
    cerrarForm()
  }

  const handleToggleButton = (event, value) => {
    if (value === '2') {
      props.handleKRSlide()
    }
  }

  return (
    <div style={{ maxHeight: '550px', overflow: 'auto' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px' }}>
        <ToggleButtonGroup
          value={`${props.activeIndex}`}
          exclusive
          color="primary"
          onChange={handleToggleButton}
        >
          <ToggleButton value="1">Personalizado</ToggleButton>
          <ToggleButton value="2">Heredar Indicador</ToggleButton>
        </ToggleButtonGroup>

        <AccordionOptions
          compressHandler={handleContraerTodos}
          expandHandler={handleExpandirTodos}
          expandTitle="Expandir todos"
          compressTitle="Contraer todos"
        />
      </div>

      <AccordionSection
        id='definicion'
        name={'Definición General'}
        description={'Define el resultado clave y su descripción'}
        expanded={expanded.definicion}
        handleChange={() => handleChange('definicion')}
        children={
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            <Grid item xs={6}>
              <TextField
                id="nombre"
                label="Nombre"
                variant="outlined"
                value={nombre}
                style={{ width: '100%' }}
                onChange={(e) => setNombre(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="fuenteDatos"
                label="Fuente de datos"
                variant="outlined"
                value={fuenteDeDatos}
                style={{ width: '100%' }}
                onChange={(e) => setFuenteDeDatos(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="descripcion"
                label="Descripción"
                variant="outlined"
                value={descripcion}
                multiline
                style={{ width: '100%' }}
                onChange={(e) => setDescripcion(e.target.value)}
                required
              />
            </Grid>
          </Grid>
        }
      />
      <AccordionSection
        id={'medicion'}
        name={'Medición'}
        description={'Define la unidad y frecuencia de medición'}
        expanded={expanded.medicion}
        handleChange={() => handleChange('medicion')}
        children={
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            <Grid item xs={6}>
              <TextField
                id="unidadMedida"
                label="Unidad de Medida"
                variant="outlined"
                select
                value={unidadDeMedida}
                style={{ width: '100%' }}
                onChange={(e) => setUnidadDeMedida(e.target.value)}
                required
              >
                {unidadesMedida.map((unidad) => (
                  <MenuItem key={unidad.id} value={unidad.nombre}>
                    {unidad.nombre}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex' }}>
              <TextField
                id="valorFrecuencia"
                label="Frecuencia de medición"
                variant="outlined"
                value={valorFrecuencia}
                type={'number'}
                style={{ width: '100%' }}
                onChange={(e) => setValorFrecuencia(e.target.value)}
                required
              />
              <TextField
                id="frecuenciaIndicador"
                select
                label="Unidad de medida"
                value={unidadMedidaId}
                fullWidth
                onChange={(e) => setUnidadMedidaId(e.target.value)}
                required
              >
                {frecuenciasIndicador.map((frecuencia) => (
                  <MenuItem key={frecuencia.id} value={frecuencia.id}>
                    {frecuencia.nombre}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex' }}>
              <FormControl>
                <FormLabel id="Tipo Periodo">Tipo de Periodo</FormLabel>
                <RadioGroup
                  row
                  value={tipoMedicionPeriodo}
                  onChange={(event) => {
                    setTipoMedicionPeriodo(event.target.value)
                  }}
                >
                  <FormControlLabel value={TIPOS_PERIODOS_INDICADOR.COMPLETO} control={<Radio />} label="Completo" />
                  <FormControlLabel value={TIPOS_PERIODOS_INDICADOR.SUB_PERIODO} control={<Radio />} label="Zafra" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Tooltip title="Las fechas de medición se ajustarán al lunes más cercano
              a partir de la fecha de inicio del periodo completo o de zafra" sx={{ mr: 1 }}>
                <InfoIcon fontSize='small' />
              </Tooltip>
              ¿Medición inicia Lunes?
              <Checkbox
                checked={medicionIniciaLunes}
                onChange={(e) => setMedicionIniciaLunes(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          </Grid>
        }
      />
      <AccordionSection
        id={'integracion'}
        name={'Modos de integración'}
        description={'Define como se consolidan los resultados'}
        expanded={expanded.integracion}
        handleChange={() => handleChange('integracion')}
        children={
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            <Grid item xs={6}>
              <Tooltip
                title={tituloIntegracionGlobal}
                enterDelay={300}
                arrow
                placement="right"
              >
                <TextField
                  id="modoIntegracionGlobal"
                  select
                  label="Modo Integración global"
                  value={modoIntegracionGlobalId}
                  fullWidth
                  onChange={(e) => setModoIntegracionGlobalId(e.target.value)}
                  required
                >
                  {modosIntegracionGlobal.map((modo) => (
                    <MenuItem key={modo.id} value={modo.id}>
                      {modo.nombre}
                    </MenuItem>
                  ))}
                </TextField>

              </Tooltip>
            </Grid>
          </Grid>
        }
      />
      <AccordionSection
        id={'metas'}
        name={'Metas'}
        description={'Define las metas individuales y globales'}
        expanded={expanded.metas}
        handleChange={() => handleChange('metas')}
        children={
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            <Grid item xs={6}>
              <Tooltip
                title={tituloMetaMedicion}
                enterDelay={300}
                arrow
                placement="bottom"
              >
                <TextField
                  id="metaPorMedicion"
                  label="Meta por medición"
                  variant="outlined"
                  value={metaPorMedicion}
                  style={{ width: '100%' }}
                  type={'number'}
                  onChange={(e) => setMetaPorMedicion(e.target.value)}
                  required
                />
              </Tooltip>
            </Grid>

            <Grid item xs={6}>
              <Tooltip
                title={tituloMetaConsolidada}
                enterDelay={300}
                arrow
                placement="bottom"
              >
                <TextField
                  id="metaConsolidada"
                  label="Meta consolidada"
                  variant="outlined"
                  type={'number'}
                  value={metaConsolidada}
                  style={{ width: '100%' }}
                  onChange={(e) => setMetaConsolidada(e.target.value)}
                  required
                />

              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip
                title={tituloMetaInversa}
                enterDelay={300}
                arrow
                placement="right"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={metaInversa}
                      onChange={() => setMetaInversa(!metaInversa)}
                    />
                  }
                  label="Meta inversa"
                />
              </Tooltip>
            </Grid>
            {/* <Grid item xs={6} sx={{ display: 'flex' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'start',
                  marginTop: '0.5rem'
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={permiteSobregiro}
                      onChange={() => setPermiteSobregiro(!permiteSobregiro)}
                    />
                  }
                  label="¿Permite sobregiro?"
                />
              </div>
            </Grid> */}
            {/* {permiteSobregiro && (
              <Grid item xs={6}>
                <TextField
                  id="limiteSobregiro"
                  label="Límite sobregiro"
                  variant="outlined"
                  type={'number'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                  value={limiteSobregiro}
                  style={{ width: '100%' }}
                  onChange={(e) => setLimiteSobregiro(e.target.value)}
                />
              </Grid>
            )} */}
          </Grid>
        }
      />
      <div style={{ marginTop: '1rem', float: 'right' }}>
        <Button
          variant="contained"
          color='inherit'
          style={{ marginRight: '1rem' }}
          onClick={cerrarForm}
        >
          Cancelar
        </Button>
        <LoadingButton
          loading={props.loading}
          disabled={props.loading}
          onClick={handleSave}
          color='primary'
          variant='contained'
        >
          <span>Guardar</span>
        </LoadingButton>
      </div>
    </div>
  )

}

export default KRForm;
