import { HOST } from "../Utils/config";
import { post } from "../Utils/api";
import { getRequest, postRequest, putRequest ,deleteRequest} from "./requests";

async function getTags(nodoId) {
  return await getRequest(`${HOST}/api/v1/tagnode/${nodoId}`);
}


async function searchNode(data) {
  return await getRequest(`${HOST}/api/v1/tagnode/searchnode?criterio=${data.criterio}&tipoObjeto=${data.tipoObjeto}&tags=${data.tags}`);
}

async function postTag(data) {
  return await postRequest(`${HOST}/api/v1/tagnode`, data);
}


async function deleteTag(id) {
  return await deleteRequest(`${HOST}/api/v1/tagnodeag/${id}`);
}

export {
  getTags,
  postTag,
  deleteTag,
  searchNode,
};
