import * as React from "react";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export default function ToggleButtons({ setView }) {
  const [activityview, setActivityview] = React.useState("tree");

  const handleActivityView = (event, newView) => {
    setActivityview(newView);
    setView(newView);
  };

  return (
    <ToggleButtonGroup
      value={activityview}
      exclusive
      onChange={handleActivityView}
      aria-label="Actividad"
    >
      <ToggleButton value="tree" aria-label="Vista de árbol">
        <AccountTreeIcon />
      </ToggleButton>
      <ToggleButton value="search" aria-label="vista de búsqueda">
        <ManageSearchIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
