import { Divider, IconButton, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { toast } from "react-toastify";
import Confirm from "../general-components/confirm";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  deleteArchivo,
  getFileAsBlob,
  getFilePresignedUrl,
} from "../Api/files.api";
import { Box } from "@mui/system";
import moment from "moment";
import { TIPOS_RACI } from "../Utils/enums";

function Archivo({ archivo, updateArchivos, tipoRaciId }) {
  const [selected, setSelected] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);

  async function download() {    
    try {
      const response = await getFilePresignedUrl(archivo.internalFileName);
      if (response.status !== 200) {
        return toast.error(response.message);
      }      
      const blob = await getFileAsBlob(response.body.url);
      const blobURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobURL;
      a.style = "display: none";
      a.download = archivo.visibleFileName;
      document.body.appendChild(a);
      a.click();
    } catch (error) {
      toast.error("Ocurrió un error al intentar descargar el archivo");
    }
  }

  function removeFile() {
    deleteArchivo({ id: archivo.id }).then((response) => {
      if (response.status === 200) {
        toast.success("Archivo eliminado correctamente");
        updateArchivos();
      } else {
        toast.error(response.message);
      }
    });
  }

  return (
    <>
      <section
        style={{
          padding: "10px 0",
          display: "flex",
          alignItems: "center",
          margin: "0px 3%",
        }}
        onMouseOver={() => setSelected(true)}
        onMouseOut={() => setSelected(false)}
      >
        <AttachFileIcon
          fontSize="large"
          color={`${selected ? "primary" : "inherit"}`}
          style={{ cursor: "pointer" }}          
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div>
              <div style={{ display: "flex" }}>
                <Tooltip title="Descargar">
                  <IconButton onClick={() => download()}>
                    <FileDownloadIcon sx={{ color: "#aebd36" }} />
                  </IconButton>
                </Tooltip>
                <Box
                  visibility={`${
                    (tipoRaciId === TIPOS_RACI.RESPONSIBLE || TIPOS_RACI.DIGITADOR) ? "visible" : "hidden"
                  }`}
                >
                  <Tooltip title="Eliminar">
                    <IconButton onClick={() => setOpenConfirm(true)}>
                      <DeleteForeverIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </div>
            </div>

            <b style={{ cursor: "pointer" }} onClick={download}>
            {archivo?.fechaInicio ? `${moment(archivo?.fechaInicio).utc().format("D MMMM YYYY, h:mm:ss a")} - ` : ""}{archivo.visibleFileName}
            </b>
          </div>
        </div>
      </section>

      <div style={{ width: "100%", justifyContent: "center" }}>
        <Divider sx={{ width: "96%", marginLeft: "2%" }} />
      </div>
      <Confirm
        open={openConfirm}
        title={"Eliminar"}
        text={"¿Confirma que desea eliminar este archivo?"}
        onConfirm={removeFile}
        handleCloseProp={() => setOpenConfirm(false)}
      />
    </>
  );
}

export { Archivo };
