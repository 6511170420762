const modosIntegracion = {
  agrupado: -1,
  directo: 1,
  derivado: 2,
  calculado: 3
}

const MODO_INTEGRACION_GLOBAL = {
  SUMATORIA: 4,
  PROMEDIO: 5,
  LAST: 6
}

const TIPOS_NODO_SIPOC = {
  SUPPLIER: 1,
  CUSTOMER: 2
}

const CHARTS_COLORS = {
  RED: '#FF0000',
  GREEN: '#07c107',
  EASY_GREEN: '#00FF00',
  NONE: undefined
}

const TIPOS_RACI = {
  RESPONSIBLE: 1,
  ACCOUNTABLE: 2,
  CONSULTED: 3,
  INFORMED: 4,
  DIGITADOR: 5
}

const TIPOS_NODO = {
  INDICADOR: -1,
  PROCESO: 2,
  ACTIVIDAD: 3
}

const TIPOS_NODO_DS = {
  FOLDER: 7,
  DATASET: 8,
}

const TIPOS_PERIODOS_INDICADOR = {
  COMPLETO: 1,
  SUB_PERIODO: 2,
}

const SHOW_CREATE_NODE_BUTTON_OPTION = 0

const TURNOS = [
  {label: 'Turno Diurno', value: 6},
  {label: 'Turno Mixto', value: 14},
  {label: 'Turno Nocturno', value: 22},
]

const ESTADO_EVALUACION = [
  { label: 'Objetivos e indicadores', value: 1 },
  { label: 'Competencias', value: 2 },
  { label: 'Cierre Pendiente', value: 3 },
  { label: 'Finalizada', value: 4 },
]

const FRECUENCIAS_EVIDENCIA = [
  { label: 'Todas', value: 1 },
  { label: 'Última', value: 2 },
  { label: 'Primera', value: 3 }
]

const FRECUENCIA_EVIDENCIA = {
  TODAS: 1,
  ULTIMA: 2,
  PRIMERA: 3
}

export {
  modosIntegracion,
  MODO_INTEGRACION_GLOBAL,
  TIPOS_NODO_SIPOC,
  CHARTS_COLORS,
  TIPOS_RACI,
  SHOW_CREATE_NODE_BUTTON_OPTION,
  TIPOS_NODO,
  TIPOS_NODO_DS,
  TIPOS_PERIODOS_INDICADOR,
  TURNOS,
  ESTADO_EVALUACION,
  FRECUENCIAS_EVIDENCIA,
  FRECUENCIA_EVIDENCIA,
}
