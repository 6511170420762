import React, { useState, useEffect } from 'react'
import { Fab } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import Nivel from './nivel'
import {
  getNivel,
  postNivel,
  putNivel,
  deleteNivel,
  getNivelesPrivado
} from '../Api/nivelOrganizacional'
import NivelForm from './nivelForm'
import Modal from '../general-components/modal'
import { toast } from 'react-toastify'
import { PermisionDeniedBar } from '../general-components/permisionDeniedBar'
import { ConditionalRendering } from '../general-components/conditionalRendering'
import {
  deleteObjetivoDesempenoNivel,
  getObjetivoDesempenoPorNivel,
  postObjetivoDesempenoNivel
} from '../Api/objetivoDesempenoNivel.api'

function ListaNiveles() {
  const ETIQUETAS_MENUID = 9
  const [niveles, setNiveles] = useState([])
  const [permissionDenied, setPermissionDenied] = useState(false)
  const [nombre, setNombre] = useState('')
  const [numero, setNumero] = useState(null)
  const [descripcion, setDescripcion] = useState('')
  const [id, setId] = useState(null)
  const [etiquetas, setEtiquetas] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [tituloModal, setTituloModal] = useState('')

  useEffect(() => {
    cargarListaNiveles()
  }, [])

  const cargarListaNiveles = async () => {
    getNivelesPrivado(ETIQUETAS_MENUID).then((response) => {
      if (response.status === 200) {
        setNiveles(response.body)
      } else if (response.status === 403) {
        setPermissionDenied(true)
      } else {
        toast.error('Error al cargar los niveles')
      }
    })
  }

  useEffect(() => {
    //cuando se setean los valores de un registro para editar, se debe abrir el modal
    if (id > 0) {
      HandleOpenModal('e')
    }
  }, [id])

  const guardar = async (id, nombre, numero, descripcion) => {
    try {
      if (id > 0) {
        const data = {
          id,
          nombre,
          numero,
          descripcion
        }
        const res = await putNivel(data)
        if (res.status == '200') {
          toast.success('Registro modificado correctamente')
          handleCloseModal()
          cargarListaNiveles()
        }
      } else {
        const data = {
          nombre,
          numero,
          descripcion
        }
        const res = await postNivel(data)
        if (res.status == '201') {
          toast.success('Registro creado correctamente')
          handleCloseModal()
          cargarListaNiveles()
        }
      }
    } catch (error) {
      console.error('guardar', error)
    } finally {
    }
  }

  const limpiarValoresNivel = () => {
    setId(null)
    setNombre('')
    setNumero(null)
    setDescripcion('')
    setEtiquetas([])
  }

  const remover = async (id) => {
    try {
      const data = {
        id
      }

      await deleteNivel(data)
      cargarListaNiveles()
    } catch (error) {
      console.error('remover', error)
    }
  }

  const obtenerNivel = async (id) => {
    try {
      const res = await getNivel(id)
      await obtenerEtiquetasPorNivel(id)

      if (res.body) {
        setNombre(res.body.nombre)
        setNumero(res.body.numero)
        setDescripcion(res.body.descripcion)
        setId(id)
      }
    } catch (error) {
      console.error('obtenerNivel', error)
    }
  }

  const obtenerEtiquetasPorNivel = async (nivelId) => {
    const resp = await getObjetivoDesempenoPorNivel(nivelId)
    if (resp.status === 200) {
      setEtiquetas(resp.body)
    } else {
      toast.error('Error al cargar los objetivos de desempeño')
    }
  }

  const addTag = async (item, porcentaje) => {
    try {
      const data = {
        nivelId: id,
        objetivoId: item.id,
        porcentaje: porcentaje
      }
      const resp = await postObjetivoDesempenoNivel(data)
      if (resp.status === 201) {
        obtenerEtiquetasPorNivel(id)
      } else {
        toast.error('Error al agregar el objetivo de desempeño')
      }
    } catch (error) {
      console.error('addTag', error)
    }
  }

  const removeObjetivo = async (etiquetaNivelId) => {
    try {
      const resp = await deleteObjetivoDesempenoNivel(etiquetaNivelId)
      if (resp.status === 200) {
        obtenerEtiquetasPorNivel(id)
      } else {
        toast.error('Error al eliminar el objetivo de desempeño')
      }
    } catch (error) {
      console.error('removeTag', error)
    }
  }

  const HandleOpenModal = (modo) => {
    setOpenModal(true)
    if (modo === 'g') {
      setTituloModal('Nuevo nivel organizacional')
    } else if (modo === 'e') {
      setTituloModal('Editar nivel')
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    limpiarValoresNivel()
  }

  return (
    <div>
      <PermisionDeniedBar display={permissionDenied} />
      {niveles.map((item) => {
        return (
          <Nivel
            key={`nivel${item.id}`}
            remove={remover}
            nivel={item}
            cargarDatos={obtenerNivel}
          />
        )
      })}
      <ConditionalRendering condition={!permissionDenied}>
        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: 'fixed',
            right: 10,
            bottom: 10,
            backgroundColor: '#aebd36'
          }}
          onClick={() => HandleOpenModal('g')}
        >
          <AddIcon />
        </Fab>

        <Modal
          open={openModal}
          handleClose={handleCloseModal}
          title={tituloModal}
        >
          <NivelForm
            code={id}
            nom={nombre}
            num={numero}
            desc={descripcion}
            tags={etiquetas}
            guardar={guardar}
            removeTag={removeObjetivo}
            addTag={addTag}
          />
        </Modal>
      </ConditionalRendering>
    </div>
  )
}

export default ListaNiveles
