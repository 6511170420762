import React from 'react'
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { AGRUPADORES } from '../Utils/graphConstants';

export default function CoeficientesCPTable({ data, agrupador }) {

  const renderCP = (value) => {
    let icon = <ClearIcon fontSize='small' color='error' sx={{ marginRight: 1 }} />;
    if (value < 1) {
      icon = <CheckIcon fontSize='small' color='success' sx={{ marginRight: 1 }} />;
    } else if (value === 1) {
      icon = <HorizontalRuleIcon fontSize='small' color='warning' sx={{ marginRight: 1 }} />;
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {(value !== null) && icon}
        {value !== null ? value.toFixed(2) : 'N/A'}
      </div>
    )
  }

  const renderCPK = (value) => {
    let icon = <ClearIcon fontSize='small' color='error' sx={{ marginRight: 1 }} />;
    if (value >= 1) {
      icon = <CheckIcon fontSize='small' color='success' sx={{ marginRight: 1 }} />;
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {(value !== null) && icon}
        {value !== null ? value.toFixed(2) : 'N/A'}
      </div>
    )
  }

  return (
    <DataGrid
      dataSource={data.data || []}
      showRowLines={true}
      showColumnLines={false}
      showBorders={false}
    >
      <Column
        dataField="periodo"
        caption="Periodo"
        groupIndex={0}
        allowSorting={false}
      />
      {agrupador && (
        <Column
          dataField="serie"
          caption={AGRUPADORES.find(item => item.value === agrupador)?.label}
          allowFiltering={false}
          allowSorting={false}
        />
      )}
      <Column
        dataType='number'
        dataField="li"
        caption="LI"
        allowFiltering={false}
        allowSorting={false}
        customizeText={({ value }) => value !== null ? value.toFixed(2) : 'N/A'}
      />
      <Column
        dataType='number'
        dataField="ls"
        caption="LS"
        allowFiltering={false}
        allowSorting={false}
        customizeText={({ value }) => value !== null ? value.toFixed(2) : 'N/A'}
      />
      <Column
        dataType='number'
        dataField="mean"
        caption="μ"
        allowFiltering={false}
        allowSorting={false}
        customizeText={({ value }) => value !== null ? value.toFixed(2) : 'N/A'}
      />
      <Column
        dataType='number'
        dataField="std"
        caption="σ"
        allowFiltering={false}
        allowSorting={false}
        customizeText={({ value }) => value !== null ? value.toFixed(2) : 'N/A'}
      />
      <Column
        dataType='number'
        dataField="min"
        caption="Min"
        allowFiltering={false}
        allowSorting={false}
        customizeText={({ value }) => value !== null ? value.toFixed(2) : 'N/A'}
      />
      <Column
        dataType='number'
        dataField="max"
        caption="Max"
        allowFiltering={false}
        allowSorting={false}
        customizeText={({ value }) => value !== null ? value.toFixed(2) : 'N/A'}
      />
      <Column
        dataType='number'
        dataField="cv"
        caption="CV"
        allowFiltering={false}
        allowSorting={false}
        customizeText={({ value }) => value !== null ? value.toFixed(2) : 'N/A'}
      />
      <Column
        dataType='number'
        dataField="cp"
        caption="CP"
        allowFiltering={false}
        allowSorting={false}
        cellRender={(rowInfo) => renderCP(rowInfo.data.cp)}
      />
      <Column
        dataType='number'
        dataField="cpk"
        caption="CPK"
        allowFiltering={false}
        allowSorting={false}
        cellRender={(rowInfo) => renderCPK(rowInfo.data.cpk)}
      />
      <Column
        dataType='number'
        dataField="cpk_min"
        caption="CPi"
        allowFiltering={false}
        allowSorting={false}
        customizeText={({ value }) => value !== null ? value.toFixed(2) : 'N/A'}
      />
      <Column
        dataType='number'
        dataField="cpk_max"
        caption="CPs"
        allowFiltering={false}
        allowSorting={false}
        customizeText={({ value }) => value !== null ? value.toFixed(2) : 'N/A'}
      />
    </DataGrid>
  )
}
