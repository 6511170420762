import React from 'react'
import { useNavigate } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { AuthContext } from '../../Context/authContext'

export function DashboardNoData({ title, Icon, color, tipoRaci }) {
  const navigate = useNavigate()
  const fontSize =
    window.innerWidth < 1700
      ? '0.75rem'
      : window.innerWidth < 2200
      ? '1.75rem'
      : '2.2rem'
  const numberSize =
    window.innerWidth < 1500
      ? '1.2rem'
      : window.innerWidth < 1800
      ? '2.2rem'
      : '4.2rem'
  const { selectedUser } = React.useContext(AuthContext)
  const [porcentajeIngresado, setPorcentajeIngresado] = React.useState(0)

  return (
    <div
      style={{
        width: '100%', //(window.innerWidth /5) < 300 ? 300: (window.innerWidth /5) ,
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 10px',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',               
        cursor: 'pointer',
        margin: "5px"

      }}
      onClick={() => navigate(`graficas/${tipoRaci}`)}
      data-testid="raci-display"
    >
      <div style={{}}>
        <h3>{title}</h3>
      </div>
      <section>
        <Icon fontSize="large" color={color} />
      </section>
    </div>
  )
}
