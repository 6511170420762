import './App.css'
import { Outlet, Route, Routes } from 'react-router-dom'
import Login from './Login/containers/login'
import SignUp from './Login/containers/signUp'
import NavBar from './Login/containers/navBar'
import { ProtectedRoute } from './Login/components/protectedRoute'
import MainDrawer from './Home/components/mainDrawer'
import { ProcessDisplay } from './Process/Display/containers/processDisplay'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ProcessDiagram } from './Process/Diagrams/processDiagram'
import { ActivitiesDiagram } from './Process/Activities/Diagrams/activitiesDiagram'
import { IndicadoresMain } from './Indicadores/indicadoresMain'
import { PeriodosMain } from './Periodos/periodosMain'
import ListaNiveles from './nivel-organizacional/listaNiveles'
import { Loading } from './Custom/loading'
import { NodoContext } from './Context/nodoContext'
import React from 'react'
import { RaciDashboard } from './Home/Dashboard/raciDashboard'
import { ResultadosMain } from './Resultados/resultadosMain'
import { GraficasDashboard } from './Home/Dashboard/graficasDashboard'
import { ResultadosIndividualesMain } from './Resultados/resultadosIndividualesMain'
import { ResultadosUsuarioIndicadorMain } from './Resultados/resultadosUsuarioIndicadorMain'
import { UsuariosMain } from './Usuarios/usuariosMain'
import MapaIndicadores from './Indicadores/mapaIndicadores'
import RolesMain from './Roles/rolesMain'
import { AsignacionRolesMain } from './Roles/Asignacion/asignacionRolesMain'
import { MetasMain } from './Metas/metasMain'
import { AsignacionUsuariosDesempeno } from './Evaluaciones/asignacionUsuariosDesempeno'
import { ResultadosDigitador } from './Resultados/resultadosDigitador'

import { FamiliaMain } from './familaEtiquetas/familiaMain'
import { TagMain } from './tags/tagMain'
import IndicadoresAsignados from './Evaluaciones/indicadoresAsignados'
import { FavoritosMain } from './Favoritos/favoritosMain'
import { FavoritosDetalle } from './Favoritos/favoritosDetalle'
import { InformesDetalle } from './Informes/informesDetalle'
import { InformesMain } from './Informes/informesMain'
import { InformesDetallePublico } from './Informes/informesDetallePublico'
import { GestionesMain } from './gestiones/gestionesMain'
import { DesempenoMain } from './Desempeno/desempenoMain'
import { GestionEvaluacion } from './Desempeno/gestionEvaluacion'
import { ValueMap } from './Process/Activities/Diagrams/valueMap'
import { ValueMapsMain } from './valueMap/valueMapsMain'
import { PilaresMain } from './Pilares/pilaresMain'
import ReporteAlineacionEstrategica from './ReporteAlineacionEstrategica'
import { LineasReferencia } from './Metas/lineasReferencia'
import EvaluacionesDesempeno from './Evaluaciones'
import { ConfigEvaluacion } from './Configuracion'
import DashboardEvaluacion from './DashboardEvaluacion'
import Bitacora from './Bitacora'

import { locale, loadMessages } from 'devextreme/localization';
import esMessages from 'devextreme/localization/messages/es.json';
import { OKRMain, ResultadosOKRDigitador } from './OKRs'


function App() {
  const { loading, loadingMessage } = React.useContext(NodoContext)

  loadMessages(esMessages);
  locale('es');

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<NavBar />}>
          <Route index element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<SignUp />} />
        </Route>

        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <MainDrawer />
            </ProtectedRoute>
          }
        >
          <Route index element={<RaciDashboard />}></Route>
          <Route path="procesos" element={<Outlet />}>
            <Route path=":nodoId" element={<ProcessDisplay />}>
              <Route path="indicadores" element={<IndicadoresMain />}></Route>
            </Route>
          </Route>
          <Route path="graficas" element={<Outlet />}>
            <Route path=":raciIdParam" element={<GraficasDashboard />} />
          </Route>
          <Route path="mapadeprocesos" element={<ProcessDiagram />} />
          <Route path="mapadeactividades" element={<ActivitiesDiagram />} />
          <Route path="mapadeindicadores" element={<MapaIndicadores />} />
          <Route path="periodos" element={<PeriodosMain />} />
          <Route path="pilares" element={<PilaresMain />} />
          <Route path="etiquetas" element={<ListaNiveles />} />
          <Route path="resultados" element={<ResultadosMain />} />
          <Route
            path="resultadosIndividuales"
            element={<ResultadosIndividualesMain />}
          />
          <Route
            path="resultadosindicadorusuario"
            element={<ResultadosUsuarioIndicadorMain />}
          />
          <Route path="yo" element={<UsuariosMain />} />
          <Route path="roles" element={<RolesMain />} />
          <Route path="asignacionroles" element={<AsignacionRolesMain />} />
          <Route path="metas" element={<Outlet />}>
            <Route path=":nodoId" element={<MetasMain />} />
          </Route>
          <Route path="lineasReferencia" element={<Outlet />}>
            <Route path=":nodoId" element={<LineasReferencia />} />
          </Route>
          <Route path="bonozafra" element={<AsignacionUsuariosDesempeno />} />
          <Route path="digitador" element={<ResultadosDigitador />} />
          {/* <Route
            path="indicadoresasignados"
            element={<IndicadoresAsignados />}
          /> */}
          <Route
            path="indicadoresasignados"
            element={<DashboardEvaluacion />}
          />
          {/* <Route
            path="reportedesempenocompleto"
            element={<ReporteDesempenoCompleto />}
          /> */}
          <Route
            path="alineacionestrategica"
            element={<ReporteAlineacionEstrategica />}
          />

          <Route
            path="evaluacionesdesempeno"
            element={<EvaluacionesDesempeno />}
          />

          <Route
            path="dashboardevaluacion"
            element={<DashboardEvaluacion />}
          />

          <Route path="tableros" element={<FavoritosMain />}>
            <Route path=":tableroId" element={<FavoritosDetalle />} />
          </Route>
          <Route path="listadoinformes" element={<Outlet />}>
            <Route path=":vistaId" element={<InformesMain />} />
          </Route>
          <Route path="informes" element={<Outlet />}>
            <Route path=":informeId" element={<InformesDetalle />} />
          </Route>

          <Route path="gestiones" element={<Outlet />}>
            <Route
              path=":idObjeto/:nodoId/:nombreObjeto"
              element={<GestionesMain />}
            />
          </Route>

          <Route path="familiaetiqueta" element={<FamiliaMain />}></Route>
          <Route path="tag/:familyId" element={<TagMain />}></Route>
          <Route
            path="indicadoresasignados"
            element={<IndicadoresAsignados />}
          />
          <Route path="desempeno" element={<DesempenoMain />}>
            <Route path=":usuarioId" element={<GestionEvaluacion />} />
          </Route>
          <Route path="valuemaplist" element={<ValueMapsMain />} />

          <Route path="valuemap" element={<Outlet />}>
            <Route path=":valueMapId" element={<ValueMap />} />
          </Route>

          <Route path="configuracion" element={<Outlet />}>
            <Route path="evaluacion" element={<ConfigEvaluacion />} />
          </Route>

          <Route
            path="bitacora"
            element={<Bitacora />}
          />

          <Route path="okrs" element={<OKRMain />}>
            <Route path=":id" element={<OKRMain />} />
          </Route>
          <Route path="okrs/digitar-resultados" element={<ResultadosOKRDigitador />} />
        </Route>

        <Route path="/informes" element={<Outlet />}>
          <Route path=":informeId" element={<InformesDetallePublico />} />
        </Route>
        

        <Route
          path="*"
          element={
            <main style={{ padding: '1rem' }}>
              <p>There's nothing here! :(</p>
            </main>
          }
        ></Route>
      </Routes>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Loading loading={loading} phrase={loadingMessage} />
    </div>
  )
}

export default App
