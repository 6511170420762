import { HOST } from '../Utils/config'
import { deleteRequest, putRequest } from './requests'

async function putModifyOrder(params) {
  return await putRequest(`${HOST}/api/v1/tab/tabOrder`, params)
}

async function putVisbilidadEtiquetas(show, tableroId, pestanaId) {
  return await putRequest(`${HOST}/api/v1/tab/visibilidadEtiquetas`, {
    show,
    tableroId,
    pestanaId
  })
}

async function putTipoVisualizacion(viewType, chartType, tableroId, pestanaId) {
  return await putRequest(`${HOST}/api/v1/tab/tipoVista`, {
    viewType,
    chartType,
    tableroId,
    pestanaId
  })
}
async function putVistaTab(obj) {
  return await putRequest(`${HOST}/api/v1/tab`, obj)
}

async function deleteTabRequest(tabId) {
  return await deleteRequest(`${HOST}/api/v1/tab/${tabId}`)
}

export { putModifyOrder, deleteTabRequest, putVisbilidadEtiquetas, putTipoVisualizacion,putVistaTab }
