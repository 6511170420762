import { Divider, Tooltip } from "@mui/material";
import React from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { deleteIndicador } from "../Api/indicadores.api";
import { toast } from "react-toastify";
import Confirm from "../general-components/confirm";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Item } from "devextreme-react/accordion";
import StyleIcon from '@mui/icons-material/Style';

const WIDTH = 800;

function FamiliaItem({
  item,
  setModalOpen,
  recargarLista,
  setDataEdit,
  remover
 
}) {
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();  
  
  

  function remove() {
    remover(item.id);
  }

  
  return (
    <>
      <div style={{ width:"80%" }}>
        <div>
          <section
            style={{
              padding: "10px 0",
              display: "inline-block",
              alignItems: "center",
              paddingRight: "10px",
              width: "100%",
              borderTopRightRadius: "40px",
              borderBottomRightRadius: "40px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "15px",
                alignItems: "flex-start",
                width: "100%",
              }}
              onClick={() => {
                         
                
              }}
            >
              <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                <b style={{ cursor: "pointer", textAlign: "left" }}>
                  {item.nombre}
                </b>
               
                <div style={{ display: "flex" }}>
                
                  <Tooltip title="etiquetas">
                    <StyleIcon
                      color="warning"
                      fontSize="small"
                      style={{ cursor: "pointer", margin: "0px 2px" }}
                      onClick={() => {
                        navigate(`../tag/${item.id}`);
                              
                      }}
                    />
                  </Tooltip>
                 
              
                  <Tooltip title="Editar">
                    <EditIcon
                      color="primary"
                      fontSize="small"
                      style={{ cursor: "pointer", margin: "0px 2px" }}
                      onClick={() => {
                        setDataEdit(item.id);                       
                      }}
                    />
                  </Tooltip>
               
                  
                  <Tooltip title="Eliminar">
                    <DeleteForeverIcon
                      color="error"
                      fontSize="small"
                      style={{ cursor: "pointer", margin: "0px 3px" }}
                      onClick={() => {setOpenConfirm(true);

                    }}
                    />
                  </Tooltip>
                  
                </div>
              </div>
              <p style={{ textAlign: "left" }}>
               
              </p>
            </div>
          </section>

          <div style={{ width: "100%", justifyContent: "center" }}>
            <Divider orientation="horizontal" sx={{ margin: "0px 10px" }} />
          </div>
          <Confirm
            open={openConfirm}
            title={"Eliminar"}
            text={"¿Confirma que desea eliminar este elemento?"}
            onConfirm={remove}
            handleCloseProp={() => setOpenConfirm(false)}
          />
        </div>
        <div
          style={{
            width: "6px",
            backgroundColor: "rgba(174,189,54, 0.95)",
          }}
        ></div>
      </div>
    </>
  );
}

export { FamiliaItem };
