import { createInitialNodes } from "../../Utils/activitiesUtils";
import { getProcessesSipoc } from "../../Utils/structureMaker";

async function createProcessesSipoc(nodo, isInfoDisplayed) {
  const nodosSipoc = await getProcessesSipoc(nodo.id);
  let customers = createProcessRelationships(nodosSipoc, 2, nodo); //Customers
  let suppliers = createProcessRelationships(nodosSipoc, 1, nodo); // Suppliers  
  const initialNodes = createInitialNodes(
    nodo,
    suppliers,
    customers,
    isInfoDisplayed
  );
  return initialNodes;
}

function createProcessRelationships(nodosSipoc, validation, nodo) {
  if (!nodosSipoc) return [];
  return nodosSipoc
    .filter((nodoSipoc) => nodoSipoc.tipoNodoSipocId === validation)
    .map((nodoSipoc) => {
      return {
        id: `${nodoSipoc.id}`,        
        nombre: nodoSipoc.nombre,
        codigo: nodoSipoc.codigo,
        nodoRelacionadoId: nodo.id,
        tipoNodoId: nodo.tipoNodoId,
        info: getActividadesAsText(nodoSipoc.actividades),
        tipoNodoSipocId: nodoSipoc.tipoNodoSipocId,
      };
    });
}

function createSipocArray(nodoActual, validaton) {
  if (!nodoActual.sipoc) return [];
  return nodoActual.sipoc
    .filter((element) => element.tipoNodoSipocId === validaton)
    .map((relacion) => {
      return {
        id: relacion.nodoRelacionado.id,
        nombre: relacion.nodoRelacionado.nombre,
        codigo: relacion.nodoRelacionado.codigo,
        indicadores: relacion.indicadores,
        info: getInfoListAsText(relacion.InputOutputSipocs),
        nodoRelacionadoId: relacion.nodoId,
        nodoSipocID: relacion.id,
        tipoNodoSipocId: relacion.tipoNodoSipocId,
      };
    });
}

function createNodes(nodoActual, isInfoDisplayed, mostrarUltimosResultados) {
  let suppliers = createSipocArray(nodoActual, 1);
  let customers = createSipocArray(nodoActual, 2);
  const initialNodes = createInitialNodes(
    nodoActual,
    suppliers,
    customers,
    isInfoDisplayed,
    mostrarUltimosResultados
  );
  return initialNodes;
}

function getInfoListAsText(infoList) {
  return infoList
    .map((element, index) => {
      return `${index + 1}. ${element.texto}`;
    })
    .join("<br />");
}

function getActividadesAsText(actividades) {
  return actividades
    .split("*")
    .map((element, index) => {
      return `${index + 1}. ${element}`;
    })
    .join("<br />");
}

export { createProcessesSipoc, createNodes };
