import * as React from "react";
import Typography from "@mui/material/Typography";
import {
  asignarUsuarios,
  desasignarUsuarios,
  getRoles,
  getRolesPrivado,
  getUsuariosAsignadosARol,
  getUsuariosNoAsignadosARol,
} from "../../Api/roles.api";
import { toast } from "react-toastify";
import { NodoContext } from "../../Context/nodoContext";
import { IconButton, MenuItem, TextField, Tooltip } from "@mui/material";
import DataGrid, {
  Column,
  FilterPanel,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Selection,
} from "devextreme-react/data-grid";
import themes from "devextreme/ui/themes";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import { PermisionDeniedBar } from "../../general-components/permisionDeniedBar";
import { useMainMenu } from "../../Hooks/useMainMenu";

function AsignacionRolesMain() {
  const ASIGNACION_ROLES_MENUID = 11;
  const [roles, setRoles] = React.useState([]);
  const [rolId, setRolId] = React.useState("");
  const [permissionDenied, setPermissionDenied] = React.useState(false);
  const [usuariosAsignados, setUsuariosAsignados] = React.useState([]);
  const [usuariosNoAsignados, setUsuariosNoAsignados] = React.useState([]);
  const todoUsuarioRef = React.useRef();
  const usuariosAsignadosRef = React.useRef();
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext);
  const [, updateMenuOptions] = useMainMenu();

  React.useEffect(() => {
    updateRoles();
  }, []);

  React.useEffect(() => {
    if (rolId) {
      updateUsuariosAsignados();
      updateUsuariosNoAsignados();
    } else {
      setUsuariosAsignados([]);
      setUsuariosNoAsignados([]);
    }
  }, [rolId]);

  function updateRoles() {
    setLoading(true);
    setLoadingMessage("Cargando...");
    getRolesPrivado(ASIGNACION_ROLES_MENUID).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setRoles(response.body);
        if (rolId !== "") {
          updateUsuariosAsignados();
          updateUsuariosNoAsignados();
        }
      } else if (response.status === 403) {
        setPermissionDenied(true);
        setRoles([]);
        setRolId("");
      } else {
        toast.error("Error al cargar los roles");
      }
    });
  }

  function updateUsuariosAsignados() {
    setLoading(true);
    setLoadingMessage("Cargando...");
    getUsuariosAsignadosARol(rolId).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setUsuariosAsignados(response.body);
      } else {
        toast.error("Error al cargar los usuarios asignados");
      }
    });
  }

  function updateUsuariosNoAsignados() {
    setLoading(true);
    setLoadingMessage("Cargando...");
    getUsuariosNoAsignadosARol(rolId).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setUsuariosNoAsignados(response.body);
      } else {
        toast.error("Error al cargar los usuarios");
      }
    });
  }

  function asignar() {
    const rol = roles.find((rol) => rol.id === rolId);
    if (!rol.asignable)
      return toast.error(
        "No se puede asignar o desasignar usuarios a este rol"
      );
    const usuarios = todoUsuarioRef.current.instance
      .getSelectedRowsData()
      .map((usuario) => usuario.idUsuario);
    if (usuarios.length === 0)
      return toast.warning("No seleccionó ningún usuario");
    setLoading(true);
    asignarUsuarios({ rol: rolId, usuarios }).then((response) => {
      setLoading(false);
      if (response.status === 201) {
        toast.success("Usuarios asignados");
        updateUsuariosAsignados();
        updateUsuariosNoAsignados();
      } else {
        toast.error("Error al asignar los usuarios");
      }
    });
  }

  function desasignar() {
    const rol = roles.find((rol) => rol.id === rolId);
    if (!rol.asignable)
      return toast.error(
        "No se puede asignar o desasignar usuarios a este rol"
      );
    const registros = usuariosAsignadosRef.current.instance
      .getSelectedRowsData()
      .map((registro) => registro.id);
    if (registros.length === 0)
      return toast.warning("No seleccionó ningún registro");
    setLoading(true);
    desasignarUsuarios({ registros }).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        toast.success("Usuarios desasignados");
        updateRoles();
        updateMenuOptions();
      } else {
        toast.error("Error al desasignar los usuarios");
      }
    });
  }

  return (
    <>
      <PermisionDeniedBar display={permissionDenied} />
      <Typography variant="h4" gutterBottom>
        Asignación de Roles
      </Typography>

      <Typography
        variant="h6"
        gutterBottom
        sx={{ textAlign: "left", marginLeft: "5%", marginBottom: "10px" }}
      >
        Seleccione un rol:
      </Typography>
      <TextField
        id="rol"
        select
        label="Seleccione un rol"
        value={rolId}
        onChange={(e) => setRolId(e.target.value)}
        required
        sx={{ width: "90%", margin: "0 auto", textAlign: "left" }}
      >
        {roles.map((rol) => (
          <MenuItem key={rol.id} value={rol.id}>
            {rol.nombre}
          </MenuItem>
        ))}
      </TextField>
      <div style={{ display: "flex" }}>
        <section style={{ padding: "25px" }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ textAlign: "center", marginBottom: "10px" }}
          >
            Todos los usuarios
          </Typography>
          <div style={{ height: "calc(100vh - 350px)", overflow: "scroll" }}>
            <RolesDataGrid
              propRef={todoUsuarioRef}
              dataSource={usuariosNoAsignados}
            />
          </div>
        </section>
        <section
          style={{
            padding: "25px",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Tooltip title="Asignar" placement="top">
            <IconButton aria-label="asignar">
              <EastIcon fontSize="large" onClick={asignar} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Desasignar">
            <IconButton aria-label="desasignar">
              <WestIcon fontSize="large" onClick={desasignar} />
            </IconButton>
          </Tooltip>
        </section>
        <section style={{ padding: "25px" }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ textAlign: "center", marginBottom: "10px" }}
          >
            Usuarios asignados
          </Typography>
          <div style={{ height: "calc(100vh - 350px)", overflow: "scroll" }}>
            <RolesDataGrid
              propRef={usuariosAsignadosRef}
              dataSource={usuariosAsignados}
            />
          </div>
        </section>
      </div>
    </>
  );
}

function RolesDataGrid({ propRef, dataSource }) {
  return (
    <DataGrid
      ref={propRef}
      dataSource={dataSource}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnResizingMode="nextColumn"
      // defaultColumns={columns}
      // rowRender={plantillaFila}
    >
      <Selection
        mode="multiple"
        selectAllMode={"allMode"}
        showCheckBoxesMode={
          themes.current().startsWith("material") ? "always" : "onClick"
        }
      />

      <Column dataField="id" caption="id" dataType="number" visible={false} />
      <Column
        dataField="idUsuario"
        caption="ID Usuario"
        dataType="number"
        visible={false}
      />
      <Column
        dataField="nombreCompleto"
        caption="Nombre Completo"
        dataType="string"
        visible={true}
      />
      <Column
        dataField="usuario"
        caption="Usuario"
        dataType="string"
        visible={true}
      />

      <FilterRow visible={true} />
      <FilterPanel visible={false} />
      {/* <FilterBuilderPopup position={filterBuilderPopupPosition} /> */}
      <HeaderFilter visible={true} />
      {/* <Scrolling mode="infinite" /> */}

      {/* <GroupPanel visible={true} /> */}
      {/* <SearchPanel visible={true} highlightCaseSensitive={true} /> */}
      {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}
      <Paging defaultPageSize={50} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[50, 100, 150, 200]}
        showInfo={true}
      />
    </DataGrid>
  );
}

export { AsignacionRolesMain };
