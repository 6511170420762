import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { cambiarOrdenNodo, getArbolNodosConIndicadores } from '../Api/nodos.api'
import { TIPOS_NODO } from '../Utils/enums'
import { Tree } from './tree'
import FolderIcon from '@mui/icons-material/Folder'
import SettingsIcon from '@mui/icons-material/Settings'
import CalculateIcon from '@mui/icons-material/Calculate'
import { useEstructuraArbolConIndicadores } from '../Hooks/useEstructuraArbolConIndicadores'
import { useEstructuraArbol } from '../Hooks/useEstructuraArbol'

export default function ProcessTreeWithKPI({
  onDoubleClick = () => {},
  onNodeClick = () => {},
  fontColor = 'white',
  draggable = false,
  showAgrupaciones = true,
  showIndicadoresAgrupados = false
}) {
  
  const { estructuraArbol, actualizarEstructura } = useEstructuraArbolConIndicadores()

  return (
    <Tree
      fontColor={fontColor}
      level={0}
      iconsHash={{
        [TIPOS_NODO.PROCESO]: <FolderIcon />,
        [TIPOS_NODO.ACTIVIDAD]: <SettingsIcon />,
        [TIPOS_NODO.INDICADOR]: <CalculateIcon />
      }}
      onNodeClick={onNodeClick}
      onDoubleClick={onDoubleClick}
      onDrop={(nodo, nodoActual) => {
        if (nodo.tipoNodoId === TIPOS_NODO.INDICADOR)
          return toast.error(
            'No está permitido mover un indicador mediante drag and drop, utilice la opción de "Copiar" en el menú de opciones del indicador'
          )
        if (nodo.id === nodoActual.id) return
        console.log({ nodo, nodoActual })
        cambiarOrdenNodo({
          nodoId: nodo.id,
          ordenDestino: nodoActual.orden,
          nodoPadreDestinoId: nodoActual.nodoPadreId
        }).then((response) => {
          if (response.status === 200) {
            actualizarEstructura()
          } else {
            toast.error('Ocurrió un error al mover el nodo')
          }
        })
      }}
      estructuraArbol={estructuraArbol}
      draggable={draggable}
      showAgrupaciones={showAgrupaciones}
      showIndicadoresAgrupados={showIndicadoresAgrupados}
    />
  )
}
