import React, { useState, useContext } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import SendIcon from '@mui/icons-material/Send'
import CancelIcon from '@mui/icons-material/Cancel'
import { AuthContext } from '../Context/authContext'
import tooltipStyles from './chartTooltip.module.css'
import { addAnnotation } from '../Api/vistaInforme.api'
import { KEYS_INVALIDAS } from '../Utils/graphConstants'
import { IconButton, Paper, TextField, Divider } from '@mui/material'

const controlledKeys = {
  "minimo": "Mínimo",
  "maximo": "Máximo",
  "Q1": "Q1",
  "Q2": "Q2",
  "Q3": "Q3",
  "serie": "Serie"
}

export function TooltipTemplateWithComments({
  data,
  hideTooltips,
  showComments,
  agregarListaComentarios
}) {
  const { point } = data
  const [commentMessage, setCommentMessage] = useState('')
  const { getSelectedUserName, getToken } = useContext(AuthContext)
  const [comments, setComments] = useState(point.data?.comentarios)
  const [reloadKey, setReloadKey] = useState(0)
  const { informeId } = useParams()
  const autenticado = getToken() !== null

  const keys = Object.keys(point.data).filter((key) => !KEYS_INVALIDAS.includes(key))

  function addComment() {
    const comment = {
      id: new Date().getTime(),
      annotationDate: new Date(),
      usuario: getSelectedUserName(),
      comment: commentMessage
    }
    const commentInfo = {
      ...comment,
      informe: informeId,
      tab: point.data.tab,
      indicador: point.data.indicador,
      fecha: point.data.fecha
    }

    addAnnotation(commentInfo).then((response) => {
      if (response.status === 200) {
        toast.success('Comentario añadido correctamente')
        setComments([comment, ...comments])
        setCommentMessage('')
        setReloadKey(reloadKey + 1)
        point.data?.comentarios.unshift(comment)
        //setFireInformeReload((prev) => prev + 1);
        try {
          agregarListaComentarios({ ...commentInfo, tabId: point.data.tab })
        } catch (error) {
          console.error(error)
        }
      } else {
        toast.error('Error al añadir comentario')
      }
    })
  }

  if (data.seriesName.startsWith('Meta')) {
    return <div></div>
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <IconButton
          color="primary"
          aria-label="Post comment"
          component="label"
          onClick={hideTooltips}
          sx={{ float: 'right', marginBottom: '-10px', marginRight: '-10px' }}
        >
          <CancelIcon sx={{ color: 'red' }} fontSize="small" />
        </IconButton>
      </div>
      {showComments && (
        <div className={tooltipStyles.tooltip} >
          <div style={{}}>
            <b>Comentarios:</b>
            <div className={tooltipStyles.commentsContainer}>
              {(comments && comments.length) ? comments.reverse()?.map((comment, index) => (
                <Paper className={tooltipStyles.comment} key={index}>
                  {comment.comment}
                  <p style={{ fontSize: '8px' }}>
                    {comment.usuario} -{' '}
                    {moment(comment.annotationDate).format('DD/MM/YYYY HH:mm')}
                  </p>
                </Paper>
              )) : <div/>}
            </div>
          </div>
          {(autenticado) && (
            <div
              style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
            >
              <TextField
                label="Comentario..."
                variant="outlined"
                value={commentMessage}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    addComment()
                  }
                }}
                onChange={(e) => setCommentMessage(e.target.value)}
              />
              <IconButton
                color="primary"
                aria-label="Post comment"
                component="label"
                onClick={addComment}
              >
                <SendIcon />
              </IconButton>
            </div>
          )}
          {(!autenticado) && (
            <div
              style={{
                marginTop: '35px',
                display: 'flex',
                maxWidth: '220px',
                justifyContent: 'center'
              }}
            >
              <b>Estás en una vista de invitado, inicia sesión para comentar.</b>
            </div>
          )}
        </div>
      )}
      <div style={{ marginTop: 5 }}>
        <b>Fecha: {moment(point.data.fecha).format('DD/MM/YYYY HH:mm')}</b>
        <Divider />
        {keys.map((key) => (
          <div key={key}>
            <span style={{ color: '#828181' }}>
              {key.startsWith('Meta') ? 'Meta' : controlledKeys[key] || 'Obtenido'}:
            </span>{' '}
            <span style={{ color: '#828181' }}>{point.data[key]}</span>{' '}
          </div>
        ))}
      </div>
    </div>
  )
}
