function getInitialActivities(nodos) {
  const elements = [];
  const edges = [];
  nodos.forEach((element) => {
    elements.push({
      id: `${element.id}`,
      type: "customActivity",
      data: {
        text: `${element.nombre}`,
        codigo: element.codigo,
        id: element.id,
        padre: element.nodoPadre?.nombre,
      },
      position: { x: 0, y: 0 },
    });
    if (element.sipoc.length) {
      const color = colors.shift();
      element.sipoc.forEach((relation) => {
        edges.push({
          // Tipo Sipoc 1 = Supplier
          id:
            relation.tipoNodoSipocId === 1
              ? `e${relation.nodoRelacionadoId}${element.id}${relation.tipoNodoSipocId}`
              : `e${element.id}${relation.nodoRelacionadoId}${relation.tipoNodoSipocId}`,
          source:
            relation.tipoNodoSipocId === 1
              ? `${relation.nodoRelacionadoId}`
              : `${element.id}`,
          target:
            relation.tipoNodoSipocId === 1
              ? `${element.id}`
              : `${relation.nodoRelacionadoId}`,
          markerEnd: { type: "arrow", color },
          arrowHeadType: "arrow",
          style: { stroke: `${color}` },
          type: "smoothstep",
          sourceHandle: "inputSource",
          targetHandle: "inputTarget",
          animated: true,
        });
      });
      colors.push(color);
    }
  });
  elements.push.apply(elements, edges);
  return elements;
}

export { getInitialActivities };

const colors = [
  "#008000", //Green
  "#ff0000", //Red
  "#e78e0c", //Orange
  "#1d1dac", //Blue
  "cb11e6#", //Purple
  "#000000", //Black
];
