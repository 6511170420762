import React, { useState, useEffect } from "react";
import { Button, MenuItem, Chip } from "@mui/material";
import { NodoContext } from "../Context/nodoContext";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";



export function GestionesForm({
  handleClose,
  createNew
}) {
  const { nodoActual } = React.useContext(NodoContext);
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [disabled, setDisabled] = useState(false);
  
   const grabar = async()=>{
    if(nombre.trim()===""){
      toast.warn("Ingresa un nombre");
      return;
    }
      if(descripcion.trim()===""){
        toast.warn("Ingresa una descripción")
        return;
    }

    await createNew(nombre,descripcion);
    handleClose();
   }

  return (
    <div style={{ maxHeight: "550px", overflow: "auto" }}>
      <Grid
        container
        spacing={2}
        sx={{ marginTop: "0px", marginBottom: "10px" }}
      >
        <Grid item xs={12}>
          <TextField
            id="nombre"
            label="Nombre"
            variant="outlined"
            value={nombre}
            style={{ width: "100%" }}
            onChange={(e) => setNombre(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="descripcion"
            label="Descripción"
            variant="outlined"
            value={descripcion}
            multiline
            style={{ width: "100%" }}
            onChange={(e) => setDescripcion(e.target.value)}
          />
        </Grid>

        
      </Grid>
      <Button
        variant="contained"
        style={{ backgroundColor: "#69bd4b", color: "white" }}
        disabled={disabled}
        onClick={grabar}
      >
        Guardar
      </Button>
    </div>
  );
}
