import React, { useState, useEffect, Fragment } from 'react'
import { Grid, Fab } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { NodoContext } from '../../Context/nodoContext'
import { addNodeTag, getNodeTags, removeTag } from '../../Utils/tagsRequest'
import TagMember from './tag-member'
import Modal from '../../general-components/modal'
import PilarTree from '../../PilarOrganizacional/PilarTree'
import { toast } from 'react-toastify'
import { Tooltip } from '@mui/material'

export default function Tags() {
  const [tagList, setTagList] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const { nodoActual } = React.useContext(NodoContext)

  useEffect(() => {
    getTags()
  }, [])

  useEffect(() => {
    getTags()
  }, [nodoActual])

  const handleClose = () => {
    setOpenModal(false)
  }

  const addTag = async (item) => {
    console.log({ item })
    const PILAR = 1
    if (item.tipoNodoId === PILAR) {
      return toast.warning('Debes asignar un objetivo')
    }
    try {
      let data = {
        objetivoId: item.id,
        objetivoDesempenoId: item.objetivoDesempenoId,
        nodoId: nodoActual.id
      }
      const res = await addNodeTag(data)
      if (res.status == 201) {
        getTags()
        handleClose()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getTags = async () => {
    try {
      const data = await getNodeTags(nodoActual.id)
      setTagList(data)
    } catch (error) { }
  }

  const removerTag = async (item) => {
    const data = { id: item.id }
    const res = await removeTag(data)

    if (res.status == 200) {
      getTags()
    }
  }

  return (
    <Fragment>
      <Modal
        title="Selecciona un pilar"
        open={openModal}
        handleClose={handleClose}
      >
        <b>
          <span>Doble click para seleccionar</span>
        </b>
        <PilarTree onDoubleClick={addTag} />
      </Modal>
      {/* <TagsModal open={openModal} closeFn={handleClose} onClickItem={addTag} /> */}

      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div>
            {tagList.map(
              (item, i) =>
                nodoActual.permisos.R == 1 && (
                  <TagMember
                    key={`tagItem${item.id}`}
                    item={item}
                    onConfirm={removerTag}
                    permisos={nodoActual.permisos}
                  />
                )
            )}
          </div>
        </Grid>
      </Grid>
      {nodoActual.permisos.C == 1 && (
        <Tooltip
          title={
            tagList.length < 1
              ? 'Asignar etiqueta'
              : 'Solo es permitido asignar un pilar'
          }
        >
          <Fab
            color="primary"
            aria-label="add"
            sx={{
              position: "fixed",
              right: 10,
              bottom: 10,
              backgroundColor: "#aebd36",
            }}
            disabled={tagList.length >= 1}
            onClick={() => {
              setOpenModal(true)
            }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      )}
    </Fragment>
  )
}
