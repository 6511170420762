import React, { useEffect } from "react";
import ReactFlow, { Background, Controls, MiniMap, ReactFlowProvider } from "react-flow-renderer";
import { toast } from "react-toastify";
import { getNodos } from "../../Api/nodos.api";
import { getInitialElements, getLayoutedElements } from "./createProcessFlow";
const onLoad = (reactFlowInstance) =>
  reactFlowInstance.fitView({ padding: 0.2 });
const nodeTypes = {};

function VerticalProcessFlow() {
  const [elements, setElements] = React.useState([]);
  const [areElementsReady, setElementsReady] = React.useState(false);

  useEffect(() => {
    getNodos().then((response) => {
      if(response.status === 200){
        const initialElements = getInitialElements(response.body);
        const layoutedElements = getLayoutedElements(initialElements);
        setElements(layoutedElements);
        setElementsReady(true);
      } else {
        toast.error(response.message)
      }      
    });
  }, []);

  return (
    <div>
      {areElementsReady && (
        <div style={{ height: "calc(100vh - 130px)" }}>
          <ReactFlowProvider>
            <ReactFlow
              elements={elements}
              nodeTypes={nodeTypes}
              // onNodeDoubleClick={onNodeClick}
              onLoad={onLoad}
              elementsSelectable={false}
              nodesConnectable={false}
              nodesDraggable={false}
            >
              <MiniMap />
              <Controls showInteractive={true} style={{ top: 10, height: "50px" }}/>
              <Background color="#aaa" gap={16} />
            </ReactFlow>
          </ReactFlowProvider>
        </div>
      )}
    </div>
  );
}

export { VerticalProcessFlow };
