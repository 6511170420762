import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';

const DeleteDialog = ({
    open = false,
    handleClose = () => { },
    handleDelete = () => { },
    title = 'Confimación',
    content = '¿Estás seguro de que deseas eliminar este elemento?'
}) => {

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleDelete} color="error" variant='contained'>Eliminar</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteDialog;