import React from "react";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import { HOST } from "../../Utils/config";

export default function CreateUserInput() {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [name, setName] = React.useState("");
  const navigate = useNavigate();

  function updateUsername(e) {
    setUsername(e.target.value);
  }

  function updatePassword(e) {
    setPassword(e.target.value);
  }

  function updateName(e) {
    setName(e.target.value);
  }

  function credentialsComplete() {
    return username !== "" && password !== "" && name !== "";;
  }

  const handleOnSubmitLogin = async () => {
    if (credentialsComplete()) {
      const response = await fetch(`${HOST}/api/v1/auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: username,
          password,
          usuario: username,
          nombreCompleto: name
        }),
      });
      if (response.ok) {
        toast.success("Usuario creado correctamente");        
        navigate(`/login`);
      } else {
        toast.error("Ocurrio un error al intentar crear el usuario");
      }
    } else {
      toast.warning("Ingrese todos los datos solicitados");
    }
  };

  return (
    <div style={centeredDiv}>
      <div style={loginContainer}>
        <h2>Registro de usuarios</h2>
        <TextField
          id="outlined-name"
          label="Nombre Completo"
          variant="outlined"
          value={name}
          onChange={updateName}          
          required
          sx={{ margin: "18px 0" }}
        />
        <TextField
          id="outlined-username"
          label="Usuario"
          variant="outlined"
          value={username}          
          onChange={updateUsername}
          required
          sx={{ margin: "18px 0" }}
        />
        <TextField
          id="outlined-password"
          label="Password"
          variant="outlined"
          value={password}
          onChange={updatePassword}
          type="password"
          sx={{ margin: "18px 0" }}
          required
        />
        
        <div className="buttons__container">
          <Button
            variant="contained"
            color="success"
            sx={{ margin: "5px 0" }}
            onClick={handleOnSubmitLogin}
          >
            Crear Usuario
          </Button>
        </div>
        
      </div>
    </div>
  );
}

const centeredDiv = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  alignSelf: "center",
  height: "calc(100vh - 90px)",
};

const loginContainer = {
  backgroundColor: "white",
  borderRadius: "30px",
  padding: "20px",
  border: "3px solid rgb(128, 123, 123)",
  display: "flex",
  flexDirection: "column",
  width: "400px",
  minWidth: "350px",
  height: "410px",
  alignSelf: "center",
};
