import React from 'react';
import { toast } from 'react-toastify'
import { Autocomplete, Box, Card, CardContent, Grid, TextField } from "@mui/material";
import DiagramaCajas from "../Custom/diagramaCajas";
import { getDiagramaCajasConfig } from '../Api/configuracion.api';
import { NodoContext } from '../Context/nodoContext';
import { DataGrid } from 'devextreme-react';
import { Column, FilterPanel, FilterRow, HeaderFilter, Pager, Paging, SearchPanel } from 'devextreme-react/data-grid';
import { getPermisoAdminDesempeno } from '../Api/auth.api';


export default function ResumenProceso({ periodoId, data, allGroupsExpanded, obtenerEvaluaciones }) {

  const [boxQuantity, setBoxQuantity] = React.useState(0);
  const [diagramaCajas, setDiagramaCajas] = React.useState({});
  const [estadisticas, setEstadisticas] = React.useState({});
  const [dataSource, setDataSource] = React.useState([]);
  const [gerencias, setGerencias] = React.useState([]);
  const [gerencia, setGerencia] = React.useState(null);
  const [permisoAdminDesempeno, setPermisoAdminDesempeno] = React.useState(false);

  const { setLoading, setLoadingMessage } = React.useContext(NodoContext)

  React.useEffect(() => {
    obtenerPermisoAdminDesempeno()
  }, []);

  React.useEffect(() => {
    if (periodoId && data.length > 0) {
      getDiagrama()
      procesarEstadisticas()
      getGerencias()
    };
  }, [data, periodoId]);

  React.useEffect(() => {
    if (diagramaCajas.configuracion && periodoId && data.length > 0) {
      procesarMatrizProceso(diagramaCajas);
      procesarDataSource(diagramaCajas);
      procesarEstadisticas()
    };
  }, [gerencia]);

  const obtenerPermisoAdminDesempeno = () => {
    getPermisoAdminDesempeno().then((response) => {
      console.log(response)
      if (response.status === 200) {
        if (response.body?.permiso) {
          setPermisoAdminDesempeno(true)
        } else {
          setPermisoAdminDesempeno(false)
        }
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const getDiagrama = async () => {
    try {
      setLoading(true);
      setLoadingMessage('Obteniendo diagrama de cajas');
      const response = await getDiagramaCajasConfig(periodoId);
      console.log(response.body)
      if (response.body) {
        setBoxQuantity((response.body?.x?.length || 0) * (response.body?.y?.length || 0))
        procesarMatrizProceso(response.body);
        procesarDataSource(response.body);
      };
    } catch (error) {
      toast.error('Error al obtener el diagrama de cajas');
      return {};
    } finally {
      setLoading(false);
    }
  }

  const procesarMatrizProceso = (_diagramaCajas) => {
    // Filtrar data
    const dataFiltrada = gerencia ? data.filter((item) => item.gerencia === gerencia) : data;

    for (let key in _diagramaCajas.configuracion) {
      console.log('key ', key)
      const [x, y] = key.split(',');

      const xRango = _diagramaCajas.x.find((item) => item.ponderacionValor === parseInt(x));
      const yRango = _diagramaCajas.y.find((item) => item.ponderacionValor === parseInt(y));

      // Filtrar data por rango de x y y
      const dataFiltered = dataFiltrada.filter((item) => {
        return item.resultadoIndicadores >= xRango.rango_inicio && item.resultadoIndicadores < xRango.rango_fin &&
          item.resultadoCompetencias >= yRango.rango_inicio && item.resultadoCompetencias < yRango.rango_fin
      });

      // Obtener un array de nombres
      const nombres = dataFiltered.map((item) => item.nombreUsuario);

      // Obtener el porcentaje
      const porcentaje = Math.round((dataFiltered.length / dataFiltrada.length) * 100);

      _diagramaCajas.configuracion[key].usuarios = nombres;
      _diagramaCajas.configuracion[key].porcentaje = porcentaje;
    }

    setDiagramaCajas(_diagramaCajas);
  }

  const procesarEstadisticas = () => {
    // Filtrar data
    const dataFiltrada = gerencia ? data.filter((item) => item.gerencia === gerencia) : data;

    // Obtener totales
    const totalUsuarios = dataFiltrada.length;
    const totalUsuariosEvaluados = dataFiltrada.filter((item) => item.gestiones[0]?.resultadoEvaluacion > 0).length;
    const totalUsuariosSinEvaluaciones = dataFiltrada.filter((item) => item.totalEvaluaciones === 0).length;

    setEstadisticas({
      totalUsuarios,
      totalUsuariosEvaluados,
      totalUsuariosSinEvaluaciones
    });
  }

  const procesarDataSource = (_diagramaCajas) => {
    // Filtrar data
    let dataFiltrada = gerencia ? data.filter((item) => item.gerencia === gerencia) : data;

    dataFiltrada = dataFiltrada.map((item) => {
      const resInd = item.resultadoIndicadores || 0
      const resComp = item.resultadoCompetencias || 0

      // En base a rango_inicio y rango_fin de nivelesConfig, se obtiene el nivel correspondiente
      const nivelInd = _diagramaCajas.x.find((nivel) => resInd >= nivel.rango_inicio && resInd < nivel.rango_fin);
      const nivelComp = _diagramaCajas.y.find((nivel) => resComp >= nivel.rango_inicio && resComp < nivel.rango_fin);

      // Se obtiene el nivel de la matriz de proceso
      const nivel = _diagramaCajas.configuracion[`${nivelInd?.ponderacionValor || 0},${nivelComp?.ponderacionValor || 0}`];

      return { ...item, nivel: nivel?.nombre || 'Sin nivel' }
    });

    setDataSource(dataFiltrada);
  }

  const getGerencias = () => {
    const gerencias = data.filter(item => item.gerencia).map((item) => item.gerencia);
    const gerenciasUnicas = [...new Set(gerencias)];
    setGerencias(gerenciasUnicas);
  }

  return (
    <Box sx={{ flexGrow: 1, alignItems: 'left', justifyContent: 'left', textAlign: 'left' }}>
      <Grid container alignItems={'flex-start'}>
        <Grid xs={12} sx={{
          efontSize: "20px",
          txtAlign: "left",
          paddingBottom: "1em",
          paddingX: { xs: '0.5rem', md: '1rem' }
        }}>
          <b>
            Diagrama de {boxQuantity} cajas
          </b>
        </Grid>
        {/* Diagrama de cajas */}
        <Grid container direction='column' item xs={12} sm={12} md={5} lg={5} sx={{ paddingRight: { md: 1 } }} >
          {permisoAdminDesempeno && (
            <React.Fragment>
              <Autocomplete
                id="gerencia"
                options={gerencias}
                value={gerencia}
                onChange={(event, newValue) => {
                  setGerencia(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="Gerencia" placeholder='Filtrar por gerencia...' />}
              />
              <br />
            </React.Fragment>
          )}
          <div style={{ width: '100%' }}>
            <Card sx={{ width: '100%' }}>
              <CardContent>
                <DiagramaCajas
                  plantilla={diagramaCajas}
                  configuracion={diagramaCajas.configuracion}
                />
              </CardContent>
            </Card>
          </div>
        </Grid>

        {/* Resumen de proceso */}
        <Grid container item xs={12} sm={12} md={7} lg={7} sx={{ paddingLeft: { md: 1 } }}>
          {/* Card estadisticas */}
          <Grid item xs={12} sm={12} md={4} lg={4} sx={{ paddingRight: { md: 1 } }}>
            <Card sx={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <p>TOTAL USUARIOS</p>
                  <span style={{ fontSize: '25px', fontWeight: 'bold' }}>
                    {estadisticas.totalUsuarios || 0}
                  </span>
                </CardContent>
              </Box>
            </Card>
          </Grid>

          {/* Card estadisticas */}
          <Grid item xs={12} sm={12} md={4} lg={4} sx={{ marginTop: { xs: 1, md: 0 } }}>
            <Card sx={{ display: 'flex', justifyContent: 'left' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <p>USUARIOS EVALUADOS</p>
                  <span style={{ fontSize: '25px', fontWeight: 'bold' }}>
                    {estadisticas.totalUsuariosEvaluados || 0}
                  </span>
                </CardContent>
              </Box>
            </Card>
          </Grid>

          {/* Card estadisticas */}
          <Grid item xs={12} sm={12} md={4} lg={4} sx={{ marginTop: { xs: 1, md: 0 }, paddingLeft: { md: 1 } }}>
            <Card sx={{ display: 'flex', justifyContent: 'left' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <p>USUARIOS SIN EVALUACIÓN</p>
                  <span style={{ fontSize: '25px', fontWeight: 'bold' }}>
                    {estadisticas.totalUsuariosSinEvaluaciones || 0}
                  </span>
                </CardContent>
              </Box>
            </Card>
          </Grid>

          {/* DataGrid */}
          <Grid item container sx={{ marginTop: { xs: 1, md: 2 }, paddingX: { md: 1 } }}>
            <DataGrid
              dataSource={dataSource}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnResizingMode="nextColumn"
            >
              <Column dataField="id" caption="id" dataType="number" visible={false} />
              <Column
                dataField="nombreUsuario"
                caption="Usuario"
                dataType="string"
              />
              <Column
                dataField="gerencia"
                caption="Gerencia"
                dataType="string"
              />
              <Column
                dataField="puesto"
                caption="Puesto"
                dataType="string"
              />
              <Column
                dataField="nivel"
                caption="Resultado"
                dataType="string"
              />

              <FilterRow visible={true} />
              <FilterPanel visible={false} />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} highlightCaseSensitive={true} />

              <Paging defaultPageSize={10} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 25, 50]}
                showInfo={true}
              />
            </DataGrid>
          </Grid>

        </Grid>

      </Grid>
    </Box>
  )
}

