import React from "react";
import { Grid, Typography, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { putNodo as updateNodo } from "../../../Api/nodos.api";
import OkCancelButtons from "../../../general-components/ok-cancel-buttons";
import { useAuth } from "../../../Hooks/useAuth";
import { NodoContext } from "../../../Context/nodoContext";

function ProcessTitle() {
  const [editableMode, setEditableMode] = React.useState(false);
  const [nameState, setNameState] = React.useState("");
  const [nameBk, setNameBk] = React.useState("");
  const { getUser } = useAuth();
  const { nodoActual, reloadNodoActual, reloadTree } = React.useContext(NodoContext)

  React.useEffect(() => {
    setNameState(nodoActual.nombre);
    setNameBk(nodoActual.nombre);
  }, [nodoActual]);

  const setEdit = (value) => {
    if (!nodoActual.permisos.U) {
      return;
    }
    setEditableMode(value);
    setNameBk(nameState);
  };

  const cancelFn = () => {
    setNameState(nameBk);
    setEditableMode(false);
  };

  const handleChangeName = async (event) => {
    setNameState(event.target.value);
  };

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      save();
    } else if (e.keyCode == 27) {
      cancelFn();
    }
  };

  const save = async () => {
    if (nameBk !== nameState && nameState !== "") {
      //code to save      
      const data = {
        nombre: nameState,
        id: nodoActual.id,
      };

      const res = await updateNodo(data);
      if (res.status != 200) {
        setNameState(nameBk);
      } else if (res.status == 200) {
        setNameBk(nameState);
        reloadNodoActual(nodoActual.id)
        reloadTree();
        console.log("holanda")
      }
      setEdit(false);
    }
  };

  return (
    <React.Fragment>
      {editableMode && (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} sm={12}>
            <TextField
              id="txtName"
              label="Nombre"
              variant="outlined"
              value={nameState}
              style={{ marginTop: 20 }}
              onChange={handleChangeName}
              onKeyDown={keyPress}
              autoFocus
            />
          </Grid>
          <Grid item xs={12} lg={12} sm={12}>
            <OkCancelButtons okFn={save} cancelFn={cancelFn} />
          </Grid>
        </Grid>
      )}

      {editableMode === false && (
        <Typography
          color="text.primary"
          variant="h4"
          sx={{ display: "flex", alignItems: "flex-start", cursor: "text" }}
          onClick={() => setEdit(true)}
        >
          - {nameState}
          <EditIcon sx={{ fontSize: 14 }} />
        </Typography>
      )}
    </React.Fragment>
  );
}

export default ProcessTitle;
