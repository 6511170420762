import React from 'react'
import {
  Box,
  Tab,
  Chip,
  Tabs,
  Grid,
  Paper,
  Button,
  Tooltip,
  Checkbox,
  TextField,
  Typography,
  Autocomplete,
  FormControlLabel
} from '@mui/material'
import { toast } from 'react-toastify'
import {
  getIndicadoresVista,
  postVista,
  putVista,
} from '../../Api/vistaIndicador.api'
import TabForm from './tabForm'
import Modal from '../../general-components/modal'
import IconButton from '@mui/material/IconButton'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ProcessTreeWithKPI from '../../TreeView/ProcessTreeWithKPI'
import DatasetIcon from '@mui/icons-material/Dataset';
import ScoreIcon from '@mui/icons-material/Score';
import { NodoContext } from '../../Context/nodoContext'
import DatasetsTree from '../../TreeView/DatasetsTree'
import { TIPOS_NODO_DS } from '../../Utils/enums'
import GraficaDSFrom from '../Datasets/graficaDSForm'
import DatasetPreview from '../Datasets/datasetPreview'

const INDICADOR = -1
let contadorIndicadoresNuevos = -1
let sendingRequest = false
const src_regex = /src="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})"/gi

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: other.p || 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export function CrearFavoritoForm({
  handleCloseModal,
  updateFavoritos,
  vistaEdicion,
  agrupacionActualId
}) {
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext)

  const [nombre, setNombre] = React.useState('')
  const [descripcion, setDescripcion] = React.useState('')
  const [reporteZafra, setReporteZafra] = React.useState(false)
  const [ocultarMetas, setOcultarMetas] = React.useState(false)
  const [chartTab, setChartTab] = React.useState(0)
  const [defaultDatasetKey, setDefaultDatasetKey] = React.useState(null)
  const [dataset, setDataset] = React.useState(null)
  const [tabSeleccionadoObjeto, setTabSeleccionadoObjeto] = React.useState(null)
  const [listaTabs, setListaTabs] = React.useState([])
  const [listaDestinatarios, setListaDestinatarios] = React.useState('')
  const [listaCC, setListaCC] = React.useState('')
  const [mensajeCorreo, setMensajeCorreo] = React.useState('')
  const [draggedIndex, setDraggedIndex] = React.useState(null);

  const [tabForm, setTabForm] = React.useState(0);
  const [openTabModal, setOpenTabModal] = React.useState(false)
  const [openGraficaDS, setOpenGraficaDS] = React.useState(false)
  const [openDataPreview, setOpenDataPreview] = React.useState(false)

  React.useEffect(() => {
    setDefaultDatasetKey(null)
    if (vistaEdicion !== null) {
      getInfoCompletaVista()
    }
  }, [vistaEdicion])

  const handleTabChange = (event, newValue) => {
    setTabForm(newValue);
  };

  const handleChartTabChange = (event, newValue) => {
    setChartTab(newValue);
  };

  function getInfoCompletaVista() {
    setNombre(vistaEdicion.nombre)
    setDefaultDatasetKey(vistaEdicion.datasetS3)
    setDataset({ key: vistaEdicion.datasetS3 })
    setDescripcion(vistaEdicion.descripcion)
    setReporteZafra(vistaEdicion.reporteZafra)
    setOcultarMetas(vistaEdicion.ocultarMetas)
    setListaDestinatarios(vistaEdicion.listaDestinatarios)
    setListaCC(vistaEdicion.listaCC)
    setMensajeCorreo(vistaEdicion.mensajeCorreo)
    getIndicadoresVista(vistaEdicion.id).then((response) => {
      if (response.status === 200) {
        const tabList = response.body.map((tab) => {
          return {
            id: tab.id,
            label: tab.nombre,
            indicadores: tab.indicadores.map((item, index) => ({ ...item, orden: index + 1 })),
            is_embedded: tab.is_embedded,
            embedded_link: tab.embedded_link
          }
        })
        setListaTabs(tabList)
      } else {
        toast.error('Ocurrió un error al obtener los indicadores de la vista')
      }
    })
  }

  const handleDoubleClick = (nodo) => {
    if (nodo.tipoNodoId === INDICADOR) {
      //obtiene tab seleccionado
      if (tabSeleccionadoObjeto === null) {
        return toast.warn('Selecciona un tab para asignar este indicador.')
      }
      tabSeleccionadoObjeto.indicadores.push({
        id: /^A/.test(nodo.key) ? null : parseInt(nodo.id),
        agrupacionId: /^A/.test(nodo.key) ? parseInt(nodo.id) : null,
        nombre: nodo.nombre,
        orden: tabSeleccionadoObjeto.indicadores.length + 1,
      })
      const tabsCopy = [...listaTabs]
      const tabSeleccionadoIndex = tabsCopy.findIndex(
        (tab) => tab.id === tabSeleccionadoObjeto.id
      )
      tabsCopy[tabSeleccionadoIndex] = tabSeleccionadoObjeto
      setListaTabs(tabsCopy)
    }
  }

  const handleDatasetClick = (nodo, doubleClick=false) => {
    if (nodo.tipoNodoId === TIPOS_NODO_DS.DATASET) {
      setDataset(nodo)
      if (doubleClick) setOpenDataPreview(true)
    } else {
      setDataset(null)
    }
  }

  const handleCreateGraph = () => {
    if (tabSeleccionadoObjeto === null) {
      return toast.warn('Selecciona un tab para asignar este dataset.')
    }
    setOpenGraficaDS(true)
  }

  const handleRemoveIndicador = (indicadorId, index) => {
    const tabsCopy = [...listaTabs]
    const tabSeleccionadoIndex = tabsCopy.findIndex(
      (tab) => tab.id === tabSeleccionadoObjeto.id
    )
    const indicadores = tabSeleccionadoObjeto.indicadores.filter(
      (i, _index) => _index !== index
    )
    tabSeleccionadoObjeto.indicadores = indicadores.map((item, _index) => ({ ...item, orden: _index + 1 }))
    tabsCopy[tabSeleccionadoIndex] = tabSeleccionadoObjeto
    setListaTabs(tabsCopy)
  }

  const handleSave = () => {
    if (sendingRequest) return
    sendingRequest = true
    if (nombre === '' || descripcion === '' || listaTabs.length === 0) {
      toast.error('Debe completar todos los campos')
      sendingRequest = false
      return
    }
    const favorito = {
      nombre,
      descripcion,
      reporteZafra,
      ocultarMetas,
      tabs: listaTabs,
      listaDestinatarios,
      listaCC,
      mensajeCorreo,
      agrupacionId: agrupacionActualId,
      datasetS3: dataset ? dataset.key : null,
    }
    console.log({ listaTabs })
    // sendingRequest = false
    if (vistaEdicion !== null) {
      favorito.id = vistaEdicion.id
      favorito.agrupacionId = vistaEdicion.agrupacionId
      update(favorito)
    } else {
      save(favorito)
    }
  }

  function save(favorito) {
    setLoading(true)
    setLoadingMessage('Guardando el tablero...')
    postVista(favorito).then((response) => {
      setLoading(false)
      if (response.status === 201) {
        toast.success('Tablero creado con éxito')
        updateFavoritos()
        handleCloseModal()
      } else {
        toast.error('Error al crear el tablero')
      }
      sendingRequest = false
    })
  }

  function update(favorito) {
    setLoading(true)
    setLoadingMessage('Guardando el tablero...')
    putVista(favorito).then((response) => {
      setLoading(false)
      if (response.status === 200) {
        toast.success('Tablero actualizado con éxito')
        updateFavoritos()
        handleCloseModal()
      } else {
        toast.error('Error al actualizar el tablero')
      }
    })
    sendingRequest = false
  }

  const addGraph = (data) => {
    console.log('data ', data)

    tabSeleccionadoObjeto.indicadores.push({
      id: null,
      agrupacionId: null,
      configGrafica: data,
      porDataset: true,
      nombre: data.titulo,
      tipoGrafica: data.tipoGrafica,
      orden: tabSeleccionadoObjeto.indicadores.length + 1,
    })
    const tabsCopy = [...listaTabs]
    const tabSeleccionadoIndex = tabsCopy.findIndex(
      (tab) => tab.id === tabSeleccionadoObjeto.id
    )
    tabsCopy[tabSeleccionadoIndex] = tabSeleccionadoObjeto
    setListaTabs(tabsCopy)
    setOpenGraficaDS(false)
  }

  const addTab = (nombre) => {
    // El id es negativo para diferenciar los tabs nuevos de los que vienen de la base
    const newElement = {
      id: contadorIndicadoresNuevos--,
      label: nombre,
      indicadores: [],
      is_embedded: false,
      embedded_link: '',
    }
    const tabsCopy = [...listaTabs]
    tabsCopy.push(newElement)
    setListaTabs(tabsCopy)
    setTabSeleccionadoObjeto(newElement)
  }

  const changeListaTabsValue = (key, name, value = "") => {
    const tabsCopy = [...listaTabs]
    if (key == 'embedded_link') {
      const matches = value.match(src_regex)
      if (matches && matches.length) {
        value = matches[0].replace("src=\"", "").slice(0, -1)
      }
    }
    tabsCopy.forEach((item, index) => {
      if (item.label == name) {
        tabsCopy[index][key] = value
      }
    })
    setListaTabs(tabsCopy)
  }

  // Funciones para Drag and Drop para ordenar los indicadores
  const onDragStart = (event, index) => setDraggedIndex(index);

  const onDragOver = (event) => event.preventDefault();

  const onDrop = (event, dropIndex) => {
    event.preventDefault();

    // Crear nueva lista con el orden actualizado
    const items = Array.from(tabSeleccionadoObjeto.indicadores);
    const [draggedItem] = items.splice(draggedIndex, 1);
    items.splice(dropIndex, 0, draggedItem);

    const nuevoTabObjeto = {
      ...tabSeleccionadoObjeto,
      indicadores: items.map((item, index) => ({ ...item, orden: index + 1 })),
    }

    setTabSeleccionadoObjeto(nuevoTabObjeto);

    const tabsCopy = [...listaTabs]
    const tabSeleccionadoIndex = tabsCopy.findIndex(
      (tab) => tab.id === tabSeleccionadoObjeto.id
    )
    tabsCopy[tabSeleccionadoIndex] = nuevoTabObjeto
    setListaTabs(tabsCopy)
    setDraggedIndex(null);
  };

  return (
    <div style={{ display: 'flex', maxHeight: '90vh', overflowY: 'scroll' }}>
      <section style={{ width: '50%', maxHeight: '80vh', overflowY: 'scroll' }}>
        <Tabs value={chartTab} onChange={handleChartTabChange}>
          <Tab icon={<ScoreIcon />} label="Indicadores" />
          <Tab icon={<DatasetIcon />} label="Conjuntos de datos" />
        </Tabs>
        <CustomTabPanel value={chartTab} index={0} p={0.1}>
          <ProcessTreeWithKPI
            fontColor={!tabSeleccionadoObjeto?.is_embedded ? "black" : "grey"}
            onDoubleClick={!tabSeleccionadoObjeto?.is_embedded ? handleDoubleClick : () => { }}
            draggable={false}
            showAgrupaciones={true}
            showIndicadoresAgrupados={true}
          />
        </CustomTabPanel>
        <CustomTabPanel value={chartTab} index={1}>
          <Grid container direction='column' justifyContent='center'>
            <DatasetsTree
              fontColor={!tabSeleccionadoObjeto?.is_embedded ? "black" : "grey"}
              onNodeClick={!tabSeleccionadoObjeto?.is_embedded ? handleDatasetClick : () => { }}
              onDoubleNodeClick={!tabSeleccionadoObjeto?.is_embedded ? (nodo) => handleDatasetClick(nodo, true) : () => { }}
              defaultSelectedKey={defaultDatasetKey}
            />
            <Button
              variant='outlined'
              color='primary'
              disabled={tabSeleccionadoObjeto?.is_embedded || !dataset}
              onClick={handleCreateGraph}
            >
              Agregar Nueva Gráfica
            </Button>

          </Grid>
        </CustomTabPanel>
      </section>
      <section style={{ width: '50%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabForm} onChange={handleTabChange}>
            <Tab label="Configuración Tablero" id={0} />
            <Tab label="Configuración Correo" id={1} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabForm} index={0}>
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            <Grid item xs={12}>
              <TextField
                id="nombre"
                label="Nombre"
                variant="outlined"
                value={nombre}
                style={{ width: '100%' }}
                onChange={(e) => setNombre(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="descripcion"
                label="Descripción"
                variant="outlined"
                value={descripcion}
                multiline
                style={{ width: '100%' }}
                onChange={(e) => setDescripcion(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={6} textAlign={'end'}>
              <Tooltip
                title={'Permite ocultar metas en el tablero de indicadores, ideal para análisis estadísticos'}
                arrow
                placement="right"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ocultarMetas}
                      onChange={() => setOcultarMetas(!ocultarMetas)}
                    />
                  }
                  componentsProps={{ typography: { variant: 'caption' } }}
                  label="Ocultar Metas"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6} textAlign={'end'}>
              <Tooltip
                title={'Permite filtrar el tablero mediante dias zafra y turnos en lugar de fechas y hora calendario'}
                arrow
                placement="right"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={reporteZafra}
                      onChange={() => setReporteZafra(!reporteZafra)}
                    />
                  }
                  componentsProps={{ typography: { variant: 'caption' } }}
                  label="Ver por periodo zafra"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <div style={{ display: 'flex' }}>
                <Autocomplete
                  value={tabSeleccionadoObjeto}
                  onChange={(event, newValue) => {
                    setTabSeleccionadoObjeto(newValue)
                  }}
                  id="tabAutocomplete"
                  options={listaTabs}
                  sx={{ width: '100%' }}
                  renderInput={(params) => <TextField {...params} label="Tab" />}
                />
                <IconButton
                  color="primary"
                  aria-label="Agregar tabs"
                  onClick={() => setOpenTabModal(true)}
                >
                  <AddCircleIcon />
                </IconButton>
              </div>
            </Grid>

            {tabSeleccionadoObjeto &&
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tabSeleccionadoObjeto?.is_embedded}
                      onChange={() => changeListaTabsValue('is_embedded', tabSeleccionadoObjeto?.label, !tabSeleccionadoObjeto?.is_embedded)}
                    />
                  }
                  label="Usar embebido"
                />
              </Grid>}

            {!tabSeleccionadoObjeto?.is_embedded ?
              <Grid item container xs={12}>
                <h4>Indicadores seleccionados:</h4>
                <Paper
                  elevation={3}
                  sx={{
                    border: '1px dashed black',
                    borderRadius: '10px',
                    minHeight: '2rem',
                    maxHeight: '15rem',
                    overflowY: 'scroll',
                    padding: '5px',
                    width: '100%'
                  }}
                >
                  {tabSeleccionadoObjeto !== null && (
                    tabSeleccionadoObjeto.indicadores.map((item, index) => (
                      <div
                        key={item.detalleId}
                        draggable
                        onDragStart={(event) => onDragStart(event, index)}
                        onDragOver={onDragOver}
                        onDrop={(event) => onDrop(event, index)}
                        style={{
                          cursor: "grab",
                        }}
                      >
                        <Chip
                          label={`${item.nombre}${item.porDataset ? ' (' + item.tipoGrafica + ')' : ''}`}
                          color={item.porDataset ? 'info' : 'default'}
                          variant="outlined"
                          onClick={() => { }}
                          onDelete={() => {
                            handleRemoveIndicador(item.id, index)
                          }}
                        />
                      </div>
                    ))
                  )}
                </Paper>
              </Grid> :
              <Grid item xs={12}>
                <h4>Enlace del embebido:</h4>
                <TextField
                  id="embedded_link"
                  label="Link"
                  variant="outlined"
                  value={tabSeleccionadoObjeto?.embedded_link}
                  style={{ width: '100%' }}
                  onChange={(e) => changeListaTabsValue('embedded_link', tabSeleccionadoObjeto?.label, e.target.value)}
                  required={tabSeleccionadoObjeto?.embedded_link}
                />
              </Grid>}
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={tabForm} index={1}>
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            <Grid item xs={12}>
              <TextField
                id="listaDestinatarios"
                label="Destinatarios"
                variant="outlined"
                value={listaDestinatarios}
                style={{ width: '100%' }}
                onChange={(e) => setListaDestinatarios(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="listaCC"
                label="CC"
                variant="outlined"
                value={listaCC}
                style={{ width: '100%' }}
                onChange={(e) => setListaCC(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="mensajeCorreo"
                label="Mensaje"
                variant="outlined"
                value={mensajeCorreo}
                multiline
                style={{ width: '100%' }}
                onChange={(e) => setMensajeCorreo(e.target.value)}
              />
            </Grid>
          </Grid>
        </CustomTabPanel>
        <Button
          variant="contained"
          sx={{
            height: '50px',
            justifySelf: 'center',
            alignSelf: 'center',
            margin: '0 auto',
            marginTop: '20px'
          }}
          onClick={handleSave}
        >
          Guardar
        </Button>
        <Modal
          open={openTabModal}
          handleClose={() => setOpenTabModal(false)}
          title={`Nuevo tab`}
        >
          <TabForm addFn={addTab} closeFn={() => setOpenTabModal(false)} />
        </Modal>
        <Modal
          open={openGraficaDS}
          handleClose={() => setOpenGraficaDS(false)}
          title={`Nuevo gráfico`}
        >
          <GraficaDSFrom
            isOpen={openGraficaDS}
            addFn={addGraph}
            closeFn={() => setOpenGraficaDS(false)}
            datasetKey={dataset}
            setLoading={setLoading}
            setLoadingMessage={setLoadingMessage}
          />
        </Modal>
        <Modal
          open={openDataPreview}
          handleClose={() => setOpenDataPreview(false)}
          title={`Previsualización`}
        >
          <DatasetPreview datasetKey={dataset} />
        </Modal>
      </section>
    </div>
  )
}
