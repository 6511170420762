import { CHART_HOST } from '../Utils/config';
import { postRequest } from './requests'

async function getDatasets() {
  const url = `${CHART_HOST}/data_tableros/datasets`
  return await postRequest(url);
}

async function getMetadataDataset(dataset) {
  const url = `${CHART_HOST}/data_tableros/metadata-dataset`
  return await postRequest(url, { dataset });
}

async function getDataset(dataset, limit=50) {
  const url = `${CHART_HOST}/data_tableros/dataset`
  return await postRequest(url, { dataset, limit });
}

async function graphDataset(body) {
  const url = `${CHART_HOST}/data_tableros/graph-dataset`
  return await postRequest(url, body);
}

export {
  getDataset,
  getDatasets,
  getMetadataDataset,
  graphDataset
};
