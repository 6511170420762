import React from 'react'
import { Divider } from '@mui/material'
import moment from 'moment'
import CancelIcon from '@mui/icons-material/Cancel'
import { KEYS_INVALIDAS } from '../Utils/graphConstants'

const controlledKeys = {
  "minimo": "Mínimo",
  "maximo": "Máximo",
  "Q1": "Q1",
  "Q2": "Q2",
  "Q3": "Q3",
  "serie": "Serie"
}

export function TooltipTemplate({ data, hideTooltips }) {
  const { point } = data
  const keys = Object.keys(point.data).filter((key) => !KEYS_INVALIDAS.includes(key))

  if (data.seriesName.startsWith('Meta')) {
    return <div style={{ opacity: 0 }}>Hola Mundo!</div>
  }

  console.log('point', point)

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CancelIcon
          fontSize="small"
          onClick={hideTooltips}
          color="error"
          sx={{ marginTop: '-10px', marginRight: '-15px', cursor: 'pointer'	 }}
        />
      </div>
      <b>Fecha: {moment(point.data.fecha).format('DD/MM/YYYY HH:mm')}</b>
      <Divider />
      {keys.filter(key => point.data[key] || point.data[key] == 0).map((key) => (
        <div key={key}>
          <span style={{ color: '#828181' }}>
            {key.startsWith('Meta') ? 'Meta' : controlledKeys[key] || 'Obtenido'}:
          </span>{' '}
          <span style={{ color: '#828181' }}>{point.data[key]}</span>{' '}
        </div>
      ))}

      {/*  */}
    </div>
  )
}
