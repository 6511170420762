import { Checkbox, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React from "react";

function RaciSelection({
  tiposRaci,
  tipoRaciActual,
  setTipoRaciActual,
  setMostrarVencidos,
  mostrarVencidos,
}) {
  const moveToNextRaciType = () => {
    const siguienteTipoRaci = tiposRaci.find(
      (tipoRaci) => tipoRaci.id === tipoRaciActual.id + 1
    );
    if (siguienteTipoRaci) {
      setTipoRaciActual(siguienteTipoRaci);
    } else {
      setTipoRaciActual(tiposRaci[0]);
    }
  };

  const moveToPrevRaciType = () => {
    const siguienteTipoRaci = tiposRaci.find(
      (tipoRaci) => tipoRaci.id === tipoRaciActual.id - 1
    );
    if (siguienteTipoRaci) {
      setTipoRaciActual(siguienteTipoRaci);
    } else {
      setTipoRaciActual(tiposRaci[tiposRaci.length - 1]);
    }
  };

  return (
    <section
      style={{
        display: "flex",
        margin: "0px 25px",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton
          aria-label="delete"
          color="primary"
          onClick={moveToPrevRaciType}
        >
          <ArrowBackIcon />
        </IconButton>
        <span style={{ width: "180px", backgroundColor: "#ffffff" }}>
          <b>{tipoRaciActual?.nombre}</b>
        </span>
        <IconButton
          aria-label="delete"
          color="primary"
          onClick={moveToNextRaciType}
        >
          <ArrowForwardIcon />
        </IconButton>
      </div>
      <section
        style={{ textAlign: "left", display: "flex", alignItems: "center" }}
      >
        <Checkbox
          checked={mostrarVencidos}
          onClick={() => setMostrarVencidos(!mostrarVencidos)}
        />
        <b>Mostrar solo mediciones vencidas </b>
      </section>
    </section>
  );
}

export { RaciSelection };
