import { Typography } from "@mui/material";
import React from "react";
import { VerticalActivitiesFlow } from "./verticalActivitiesFlow";

function ActivitiesDiagram() {
  return (
    <div>
      <Typography variant="h4" noWrap component="div">
        Mapa de Actividades
      </Typography>      
      <VerticalActivitiesFlow />
    </div>
  );
}

export { ActivitiesDiagram };
