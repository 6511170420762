import React from "react";
import { useEffect, useState } from 'react'
import ReactFlow, { Background, ConnectionLineType, Controls, isNode, MiniMap, ReactFlowProvider } from "react-flow-renderer";
import NodoOKR, { NODE_HEIGHT } from "./nodoOKR";
import Elk from "elkjs";
import styles from './styles.module.css'

const DEFAULT_WIDTH = 172;
const DEFAULT_HEIGHT = 36;
const nodeTypes = { default: NodoOKR }

const elk = new Elk({
  defaultLayoutOptions: {
    "elk.algorithm": "layered",
    "elk.direction": "DOWN",
    "elk.spacing.nodeNode": "25",
    "elk.layered.spacing.nodeNodeBetweenLayers": NODE_HEIGHT,
    "elk.layered.spacing": NODE_HEIGHT,
    "elk.layered.mergeEdges": "true",
    "elk.spacing": NODE_HEIGHT,
    "elk.spacing.individual": NODE_HEIGHT,
    "elk.edgeRouting": "SPLINES"
  }
});

const OKRsFlow = ({ setItemOK, initialNodes, initialEdges }) => {

  const [elements, setElements] = useState([]);
  const reactFlowWrapper = React.useRef(null)
  const [reactFlowInstance, setReactFlowInstance] = React.useState(null)

  useEffect(() => {
    iniciarItems();
  }, [initialNodes, initialEdges]);

  useEffect(() => {
    fitView()
  }, [elements])

  const iniciarItems = async () => {
    const _elements = await createGraphLayout([...initialNodes, ...initialEdges]);
    setElements(_elements);
  }

  const onLoad = (reactFlowInstance) => {
    setReactFlowInstance(reactFlowInstance)
  }

  function fitView() {
    setTimeout(() => {
      if (reactFlowInstance) reactFlowInstance.fitView()
    }, 100)
  }

  const createGraphLayout = async (elements) => {
    const nodes = [];
    const edges = [];

    elements.forEach((el) => {
      if (isNode(el)) {
        nodes.push({
          id: el.id,
          width: el.__rf?.width ?? DEFAULT_WIDTH,
          height: el.__rf?.height ?? DEFAULT_HEIGHT
        });
      } else {
        edges.push({
          id: el.id,
          target: el.target,
          source: el.source,
        });
      }
    });

    const newGraph = await elk.layout(
      {
        id: "root",
        children: nodes,
        edges: edges
      },
      { direction: "DOWN" }
    );

    return elements.map((el) => {
      if (isNode(el)) {
        const node = newGraph?.children?.find((n) => n.id === el.id);
        if (node?.x && node?.y && node?.width && node?.height) {
          el.position = {
            x: node.x - node.width / 2 + Math.random() / 1000,
            y: node.y - node.height / 2
          };
        }
      }
      return el;
    });
  };

  return (
    <ReactFlowProvider>
      <ReactFlow
        className='okr_flow'
        onLoad={onLoad}
        elements={elements}
        nodeTypes={nodeTypes}
        elementsSelectable={true}
        connectionLineType={ConnectionLineType.SmoothStep}
        nodesConnectable={false}
        nodesDraggable={false}
        snapToGrid={true}
        defaultZoom={1}
        maxZoom={5}
        onElementClick={(event, element) => {
          // if (element.id !== '0') setItemOK(element.data);
          setItemOK(element.data);
        }}
        onSelectionChange={(elements) => {
          if (elements === null) setItemOK(null)
        }}
      >
        <MiniMap />
        <Controls />
        <Background style={{ backgroundColor: '#F5F7F8' }} color="#31363F" gap={16} />
      </ReactFlow>
    </ReactFlowProvider>
  )

}

export default OKRsFlow;
