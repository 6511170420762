import React, { useEffect } from 'react'
import ReactFlow, {
  Background,
  Controls,
  ControlButton,
  ReactFlowProvider,
  MiniMap
} from 'react-flow-renderer'
import { toast } from 'react-toastify'
import { getNodosValueMap } from '../../../Api/valueMap.api'
import { NodoContext } from '../../../Context/nodoContext'
import { NodoSipocActividadesHijas } from '../../../Nodes/nodoSipocActividadesHijas'
import { getLayoutedElements } from '../../Diagrams/createProcessFlow'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import canvas from '../style.module.css'
import { Fab, IconButton, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import Modal from '../../../general-components/modal'
import { NodosValueMap } from './nodosValueMap'
import { useParams } from 'react-router-dom'
import {
  getValueMapPositions,
  putValueMapPositions
} from '../../../Api/valueMapPosicion.api'
import SaveIcon from '@mui/icons-material/Save'

const nodeTypes = {
  customActivity: NodoSipocActividadesHijas
}

function ValueMap() {
  const { nodoActual, setLoading, setLoadingMessage } =
    React.useContext(NodoContext)
  const [elements, setElements] = React.useState([])
  const [mostrarUltimosResultados, setMostrarUltimosResultados] =
    React.useState(false)
  const reactFlowWrapper = React.useRef(null)
  const [reactFlowInstance, setReactFlowInstance] = React.useState(null)
  const [openNodoModal, setOpenNodoModal] = React.useState(false)
  const [unauthorized, setUnauthorized] = React.useState(false)

  const { valueMapId } = useParams()

  useEffect(() => {
    updateChartsData()
  }, [nodoActual, mostrarUltimosResultados])

  useEffect(() => {
    fitView()
  }, [elements])

  const onLoad = (reactFlowInstance) => {
    setReactFlowInstance(reactFlowInstance)
  }

  function fitView() {
    setTimeout(() => {
      if (reactFlowInstance) reactFlowInstance.fitView()
    }, 100)
  }

  const handleCloseNodoModal = () => {
    setOpenNodoModal(false)
  }

  function handleOpenNodoModal(e) {
    e.stopPropagation()
    setOpenNodoModal(true)
  }

  async function updateChartsData() {
    setLoading(true)
    setLoadingMessage('Cargando actividades...')
    const posicionesIniciales = await getSavedPositions()
    console.log({ posicionesIniciales })
    getNodosValueMap(valueMapId, mostrarUltimosResultados).then((response) => {
      setLoading(false)
      console.log('response', response)
      if (response.status === 200) {
        setUnauthorized(false)
        console.log('DATA PARA CHARTS', response.body)
        const relacionesUtiles = encontrarRelacionesUtiles(
          response.body.relaciones
        )
        obtenerFormatoDeGrafica(
          response.body.nodos,
          relacionesUtiles,
          posicionesIniciales
        )
        fitView()
      } else if (response.status === 401) {
        toast.error('No tienes autorización para ver este mapa')
        setUnauthorized(true)
      } else {
        toast.error('Ocurrio un error al recuperar las actividades.')
      }
    })
  }

  function encontrarRelacionesUtiles(relaciones) {
    const relacionesUtiles = []
    relaciones.forEach((relacion) => {
      if (
        !esRelacionDuplicada(
          relacion.nodoId,
          relacion.nodoRelacionadoId,
          relacionesUtiles
        )
      ) {
        relacionesUtiles.push(relacion)
      }
    })
    return relacionesUtiles
  }

  function esRelacionDuplicada(source, target, relaciones) {
    for (let index = 0; index < relaciones.length; index++) {
      const relacion = relaciones[index]
      if (relacion.nodoId === source && relacion.nodoRelacionadoId === target) {
        return true
      } else if (
        relacion.nodoId === target &&
        relacion.nodoRelacionadoId === source
      ) {
        return true
      }
    }
    return false
  }

  function obtenerFormatoDeGrafica(
    actividades,
    relaciones,
    posicionesIniciales
  ) {
    const nodos = obtenerFormatoNodosReactFlow(
      actividades,
      mostrarUltimosResultados
    )
    const aristas = obtenerRelacionesNodosReactFlow(relaciones)
    nodos.push.apply(nodos, aristas)
    let actividadesConLayout = nodos
    if (posicionesIniciales) {
      nodos.forEach((nodo) => {
        console.log({ nodo })
        const posicion = posicionesIniciales[nodo.id]
        if (posicion) {
          nodo.position = { x: posicion.x, y: posicion.y }
        }
      })
    } else {
      actividadesConLayout = getLayoutedElements(nodos, 'LR')
    }
    setElements(actividadesConLayout)
  }

  async function getSavedPositions() {
    const positionsResponse = await getValueMapPositions(valueMapId)
    if (positionsResponse.status === 200) {
      const posiciones = positionsResponse.body
      if (Object.values(posiciones).length === 0) {
        return null
      }
      return positionsResponse.body
    } else {
      toast.error('Ocurrio un error al recuperar las posiciones guardadas.')
    }
    return null
  }

  function handlePositionChange() {
    if (!reactFlowInstance)
      return toast.error('No se pudo acceder a los elementos del diagrama.')
    console.log({ reactFlowInstance: reactFlowInstance.getElements() })
    const mapPositions = reactFlowInstance
      .getElements()
      .filter((element) => element.position !== undefined) // Elimina los elementos que no tienen posicion
      .map((element) => {
        return {
          nodoId: element.id,
          valueMapId: valueMapId,
          x: element.position.x,
          y: element.position.y
        }
      })
    console.log({ mapPositions })
    putValueMapPositions(mapPositions).then((response) => {
      if (response.status === 200) {
        toast.success('Posiciones guardadas correctamente.')
      } else {
        toast.error('Ocurrio un error al guardar las posiciones.')
      }
    })
  }

  return (
    <div>
      {/* {areElementsReady && ( */}
      {elements && !unauthorized && (
        <div className={canvas.flow}>
          <ReactFlowProvider>
            <ReactFlow
              elements={elements}
              nodeTypes={nodeTypes}
              elementsSelectable={true}
              ref={reactFlowWrapper}
              // onNodeDoubleClick={onNodeClick}
              onLoad={onLoad}
              nodesConnectable={false}
              nodesDraggable={false}
            >
              {/* <MiniMap  /> */}
              <Controls
                showInteractive={true}
                style={{ top: 10, height: '50px' }}
              >
                {/* <ControlButton
                  onClick={() => {                    
                    console.log(reactFlowInstance.getElements());
                  }}
                >
                  <FormatListNumberedIcon />
                </ControlButton> */}
                {!mostrarUltimosResultados && (
                  <ControlButton
                    onClick={() =>
                      setMostrarUltimosResultados(!mostrarUltimosResultados)
                    }
                  >
                    <FormatListNumberedIcon />
                  </ControlButton>
                )}
                {mostrarUltimosResultados && (
                  <ControlButton
                    onClick={() =>
                      setMostrarUltimosResultados(!mostrarUltimosResultados)
                    }
                  >
                    <FilterListOffIcon />
                  </ControlButton>
                )}
                <ControlButton onClick={handlePositionChange} hidden={true}>
                  <SaveIcon />
                </ControlButton>
              </Controls>
              <Background color="#aaa" gap={16} />
            </ReactFlow>
          </ReactFlowProvider>

          <Fab
            color="primary"
            aria-label="add"
            sx={{
              position: 'fixed',
              right: 10,
              bottom: 10,
              backgroundColor: '#aebd36',
              zIndex: 1000
            }}
            onClick={handleOpenNodoModal}
          >
            <AddIcon />
          </Fab>
        </div>
      )}

      <Modal
        open={openNodoModal}
        handleClose={handleCloseNodoModal}
        title="Nodos en este diagrama"
      >
        <NodosValueMap
          valueMapId={valueMapId}
          isOpen={openNodoModal}
          recargarDiagrama={updateChartsData}
        />
      </Modal>
    </div>
  )
}

function obtenerFormatoNodosReactFlow(nodos, mostrarUltimosResultados) {
  const nodosReactFlow = nodos.map((element) => {
    const ultimoResultadosLength = element.ultimoResultados?.length || 0
    return {
      id: `${element.id}`,
      type: 'customActivity',
      data: {
        id: element.id,
        text: `${element.nombre}`,
        ultimosResultados: element.ultimosResultados,
        mostrarUltimosResultados,
        actividadExterna: element.actividadExterna,
        width: 280,
        height: mostrarUltimosResultados
          ? 100 + ultimoResultadosLength * 35
          : 100
      },
      position: { x: Math.random() * 10, y: Math.random() * 10 }
    }
  })
  return nodosReactFlow
}

function obtenerRelacionesNodosReactFlow(relaciones) {
  const relacionesReactFlow = relaciones.map((relacion, index) => {
    return {
      // Tipo Sipoc 1 = Supplier
      id: `e${index}${relacion.nodoId}${relacion.nodoRelacionadoId}`,
      source: `${relacion.nodoId}`,
      target: `${relacion.nodoRelacionadoId}`,
      arrowHeadType: 'arrowclosed',
      style: { stroke: `black` },
      type: 'smoothstep',
      // sourceHandle: "inputTarget",
      // targetHandle: "inputSource",
      animated: true
    }
  })
  return relacionesReactFlow
}

export { ValueMap }
