import { Paper } from "@mui/material";
import moment from "moment";

export default function Comentario({data}){

    return(
        <Paper style={{padding:10, marginTop:5, marginBottom:5}}>
            <p style={{textOverflow:"ellipsis", overflow:"hidden", fontSize:"small"}}>
                {data.mensaje}</p>
            <p style={{fontSize:"x-small", fontWeight:"bold", color:"#007bfc"}}>{`${data.autor} - ${moment(data.fechaCreacion).format("DD/MM/YYYY HH:mm")}`}</p>
        </Paper>
    )
}