import API from "./api";
import { signIn, signOut, isSignedIn } from "./auth";

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

//Valores constantes
//DESA
// const USER_CODE = 4734;
// const USER = "FacemaskApp";
// const PASSWORD = "Chocococo@Chilak!ller#";
// const PROBLEM_CODE = 274;
// const TEMPLATE_CODE = 1282;
// const TITLE = "Detección de no utilización de mascarilla";
// const DESCRIPTION = "Detección de no utilización de mascarilla";
// const AFFECTED_USER = USER_CODE;
// let LOCATION = {};
// const FIELD_CODE = 2464;
// let FIELD_TYPE_CODE = 8;

//PRD
const USER_CODE = 9614;
const USER = "EvaAppUser";
const PASSWORD = "Chocococo@Chilak!ller#";
const PROBLEM_CODE = 0;
const TEMPLATE_CODE = 0;
const TITLE = "Detección de no utilización de mascarilla";
const DESCRIPTION = "Detección de no utilización de mascarilla";
const AFFECTED_USER = USER_CODE;
let LOCATION = {};
const FIELD_CODE = 2028;
let FIELD_TYPE_CODE = 8;



let FILE_FIELD = {
  Descripcion: null,
  Nombre: "Fotografía",
  Llave: null,
  Orden: 1,
  Requerido: true,
  CodigoPlantilla: TEMPLATE_CODE,
  CodigoCampo: FIELD_CODE,
  CodigoTipoCampo: FIELD_TYPE_CODE,
  Requerir: false,
  OrigenDatos: null,
  CodigoCampoPadre: null,
  Items: [],
  DefaultValue: null,
  LlaveCampo: "attach",
  Visible: true,
  ValorPorDefecto: null,
  FileName: null,
  Valor: [],
  AttrVista: [null],
};
// let TEMPLATE_VALUES = [FILE_FIELD];
let TEMPLATE_VALUES = [];

//Autenticacion APPILU
export const handleLogin = async () => {
  let response = false;
  try {
    await API.Login(USER, PASSWORD, 1).then(async (result) => {
      const ok = await signIn(result);
      console.log("ok", ok);
      if (ok === true) {
        response = true;
      } else {
        response = false;
      }
    });
  } catch (e) {
    alert(
      "En este momento no se puede realizar tu solicitud, intentalo más tarde o llama a tu administrador"
    );
  } finally {
    return response;
  }
};

export const getUser = async(dpi)=>{
  const request = await API.fetchGetRequest(`/api/gestioneseva/user/${dpi}`);
  console.log("userRequest", request);
  return request.data;
}

export const gestionesLista = async(lista)=>{
  const request = await API.post(`/api/gestioneseva/gestioneslista/`, lista);
  console.log("listaGestiones", request);
  return request.data;
}

export const asignarUsuarios = async(codigoAviso,usuariosGestion, usuariosTareas,usuarioOpera)=>{
  const object = {
    codigoAviso:codigoAviso,
    usuariosGestion:usuariosGestion,
    usuariosTareas:usuariosTareas,
    usuarioOpera:usuarioOpera
  }
  const request = await API.post("/api/gestioneseva/asignarusuarios", object);
  console.log("asignación request", request);
  if (request.status === 401) {
    //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
    if (await signOut()) {
     alert("Acceso denegado AppILU");
    }
  } else {
   console.log("La gestión se asignó correctamente")
  }
} 

export const anularGestion = async(codigoAviso, usuario,codigoUsuario)=>{
  const object = {
    codigoAviso:codigoAviso,
    usuario:usuario,
    codigoUsuario:codigoUsuario,
    }
  const request = await API.post("/api/gestioneseva/anularGestion", object);
  console.log("anulación request", request);
  if (request.status === 401) {
    //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
    if (await signOut()) {
     alert("Acceso denegado AppILU");
    }
  } else {
   console.log("La gestión se anuló correctamente")
  }
} 

export const crearGestionAppilu = async (problemCode, title, comment,usuarioAfectado) => {
  //Graba gestión
  let codigoGestion = null;

  const session = await isSignedIn();

  if (session.response) {
    
    await getLocation();
    // console.log("b64 sendAppilu", base64Image);
    // let image = await dataURIToBlob(base64Image);
    // FILE_FIELD.Valor = [image];
    // console.log("FIELD", FILE_FIELD);
    // console.log("File", FILE_FIELD.Valor);

    const objeto = {
      CodigoProblema: problemCode,
      CodigoSolicitante: USER_CODE,
      Titulo: title,
      Descripcion: comment,
      UsuarioInserto: USER,
      CodigoUsuarioAfectado: usuarioAfectado?usuarioAfectado:AFFECTED_USER,
      PlantillaValores: TEMPLATE_VALUES,
      Longitud: LOCATION.lon,
      Latitud: LOCATION.lat,
      FechaVencimiento: null,
    };

    console.log("Lo que va a guardar", objeto);

    const request = await API.post("/api/aviso", objeto);
    console.log("aviso", request);
    if (request.status === 401) {
      //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
      if (await signOut()) {
       alert("Acceso denegado AppILU");
      }
    } else {
      codigoGestion =  request.data.data.id
      //El servidor nos devolvio que se inserto.

      //Enviar al server las imagenes/documentos del aviso.

      //this.props.history.push("/bandeja");

      //await uploadFiles(request.data.data.id);

      //toast.info(request.data.mensaje);
      // setTimeout(()=>{this.props.history.push("/bandeja")}, 5000);
    }
  } else {
    console.log(
      "Hubo un problema al crear la gestión, consulta con tu administrador"
      
    );
    toast.error("Hubo un problema al crear la gestión, consulta con tu administrador");
  }
  return codigoGestion
};

const uploadFiles = async (codigoAviso) => {
  const session = await isSignedIn();
  console.log("CodigoAviso", codigoAviso);

  if (FILE_FIELD.Valor != null && FILE_FIELD.Valor.length > 0) {
    let data = new FormData();
    data.append("CodigoAviso", codigoAviso);
    data.append("UsuarioInserto", USER);
    data.append("CodigoCampo", FIELD_CODE);
    data.append("CodigoTipoCampo", FIELD_TYPE_CODE);

    await data.append("files[]", FILE_FIELD.Valor[0], "noMask.jpg");

    console.log("data Multimedia ", data);

    let request = await API.post("/api/aviso/agregarMultimedia", data, {
      "content-type": "multipart/form-data",
    });

    console.log("File Request", request);
  }
};

const getLocation = async () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      let location = { lon: 0, lat: 0 };
      location.lon = position.coords.longitude;
      location.lat = position.coords.latitude;
      LOCATION = location;
    });
  } else {
    console.log("Su navegador no soporta geolocalización");
  }
};

function dataURIToBlob(dataURI) {
  console.log("b64 dataUriToBlob", dataURI);
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);

  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

function dataURIToBlob64(dataURI) {
  console.log("b64 dataUriToBlob", dataURI);
  // const splitDataURI = dataURI.split(",");
  const byteString = atob(dataURI);

  const mimeString = "image/jpeg";

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}
