import { HOST } from "../Utils/config";
import { putRequest, getRequest } from "./requests";

async function validateTokenApiCall() {
  return await putRequest(`${HOST}/api/v1/auth/validate`);
}

async function getPermisoAdminDesempeno() {
  return await getRequest(`${HOST}/api/v1/auth/permisoAdminDesempeno`);
}

async function getPermisoGerente() {
  return await getRequest(`${HOST}/api/v1/auth/permisoGerente`);
}

export { validateTokenApiCall, getPermisoAdminDesempeno, getPermisoGerente };
