import React from 'react'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { IconButton } from '@mui/material';

export function KRItem({ index, name, isNew, editDisabled, deleteDisabled, deleteAction, editAction }) {
  return (
    <div
      style={
        styles.serie
      }
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
        }}
      >
        {name}
        {isNew && (<AutoAwesomeIcon titleAccess='Nuevo' color='success' style={{ marginLeft: '0.5rem' }} />)}
      </div>
      <div style={{ display: 'flex' }}>
        {!editDisabled && (
          <IconButton
            title='Editar resultado clave'
            onClick={() => editAction(index)}
          >
            <ModeEditIcon color='success' />
          </IconButton>
        )}
        {!deleteDisabled && (
          <IconButton
            title='Eliminar resultado clave'
            onClick={() => deleteAction(index)}
          >
            <HighlightOffIcon />
          </IconButton>
        )}
      </div>

    </div>
  )
}

const styles = {
  serie: {
    width: '100%',
    border: '2px dashed #ccc',
    borderRadius: '5px',
    padding: '0.2rem 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
  disabled: {
    backgroundColor: '#ccc',
    pointerEvents: 'none',
    color: '#888'
  }, red: {
    backgroundColor: '#f00',
  }
}