import pako from 'pako';
import { Buffer } from 'buffer';
import { toast } from "react-toastify";

const EJES = [
  {
    label: 'Eje x',
    value: 'x',
  },
  {
    label: 'Eje y',
    value: 'y',
  }
]

function hasSameItems(arr1, arr2) {
  let isDiferent = arr1.reduce((acc, el, i) => {
    return acc || !arr2.includes(el)
  }, false)
  isDiferent = arr2.reduce((acc, el, i) => {
    return acc || !arr1.includes(el)
  }, isDiferent)
  return !isDiferent
}

function getFileType(file) {
  const type = file.type.split('/')[0]
  const ext = file.type.split('/')[1]
  return { type, ext }
}

function sameDay(d1, d2) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  )
}

function getTurno(fecha) {
  if (fecha.hour() > 6 && fecha.hour() <= 14)
    return 6
  if (fecha.hour() > 14 && fecha.hour() <= 22)
    return 14
  return 22
}

const comprimirPayload = (payload) => {
  const payloadJson = JSON.stringify(payload);
  const compressedPayload = pako.gzip(payloadJson);
  const compressedPayloadBase64 = Buffer.from(compressedPayload).toString('base64');
  return compressedPayloadBase64;
}

const descomprimirPayload = (compressedPayloadBase64) => {
  const compressedPayload = Buffer.from(compressedPayloadBase64, 'base64');
  const payloadJson = pako.ungzip(compressedPayload, { to: 'string' });
  const payload = JSON.parse(payloadJson);
  return payload;
}

const getNameInitials = (name='') => {
  return name.split(' ')
    .filter(Boolean).map(nombre => nombre[0])
    .filter((valor, indice, array) => indice == 0 || (array.length > 2 ? indice == array.length - 2 : indice == array.length - 1))
    .join('');
}

const copiarPortapapeles = (texto) => {
  navigator.clipboard.writeText(texto)
  .then(() => {
    toast.success('Valor copiado al portapapeles')
  })
  .catch(err => {
    toast.error('Error al copiar al portapapeles')
  })
}

const cronTranslation = {
  "Every": "Cada",
  "day(s)": "día(s)",
  "Every week day": "De lunes a viernes",
  "Start time": "Hora de inicio",
  "hour": "hora",
  "At": "A las",
  "minute(s)": "minuto(s)",
  "Day": "Día",
  "of every month(s)": "de cada mes",
  "Last day of every month": "Último día de cada mes",
  "On the last weekday of every month": "En el último día de la semana del mes",
  "day(s) before the end of the month": "día(s) antes del final del mes",
  "Monday": "Lunes",
  "Tuesday": "Martes",
  "Wednesday": "Miércoles",
  "Thursday": "Jueves",
  "Friday": "Viernes",
  "Saturday": "Sábado",
  "Sunday": "Domingo",
  "Minutes": "Minutos",
  "Hourly": "Horas",
  "Daily": "Días",
  "Weekly": "Semanas",
  "Monthly": "Meses",
  "Custom": "Personalizado",
  "Expression": "Expresión",
}

export { cronTranslation, EJES, hasSameItems, getFileType, sameDay, getTurno, comprimirPayload, descomprimirPayload, getNameInitials, copiarPortapapeles }
