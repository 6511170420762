import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { getPeriodos } from "../Api/periodo.api";
import {
  getPeriodosSeleccionados,
  updatePeriodosSeleccionados,
} from "../Api/periodoUsuario";
import { AuthContext } from "./authContext";

const PeriodoContext = React.createContext();
const INICIADO = 2;

const PeriodoProvider = ({ children }) => {
  const [periodos, setPeriodos] = React.useState([]);
  const [fireReload, setFireReload] = React.useState(0);
  const { isAuthenticated } = React.useContext(AuthContext);

  useEffect(() => {
    updatePeriodos();
  }, []);

  useEffect(() => {
    if (periodos.length > 0) {
      updatePeriodosSeleccionados(getSelected()).then((response) => {
        if (response.status === 201) {
          setFireReload((fireReload) => fireReload + 1);
        }
      });
    }
  }, [periodos]);

  function reloadPeriodos() {
    updatePeriodos();
  }

  function getSelected() {
    const idsSeleccionados = Object.values(periodos)
      .filter((periodo) => periodo.seleccionado)
      .map((periodo) => periodo.id);
    return idsSeleccionados;
  }

  function getUnicoPeriodoSeleccionado() {
    const periodosSeleccionados = Object.values(periodos).filter(
      (periodo) => periodo.seleccionado
    );
    if (periodosSeleccionados.length === 1) {
      return periodosSeleccionados[0];
    }
    return null;
  }
  
  function obtenerPeriodosSeleccionados() {
    const periodosSeleccionados = Object.values(periodos).filter(
      (periodo) => periodo.seleccionado
    );
    if (periodosSeleccionados.length) {
      return periodosSeleccionados;
    }
    return null;
  }

  function getMinMaxDates() {
    let fechaMin = null;
    let fechaMax = null;
    Object.values(periodos).forEach((periodo) => {
      if (periodo.seleccionado) {
        if (fechaMin === null || new Date(periodo.fechaInicio) < new Date(fechaMin)) {
          fechaMin = periodo.fechaInicio + "T00:00:00";
        }
        if (fechaMax === null || new Date(periodo.fechaFin) > new Date(fechaMax)) {
          fechaMax = periodo.fechaFin + "T00:00:00";
        }
      }
    });    
    return { fechaMin, fechaMax };
  }

  function getDiasZafra() {
    return null
  }

  async function updatePeriodos() {
    const periodosResponse = await getPeriodos();
    const periodosSeleccionadosResponse = await getPeriodosSeleccionados();
    if (
      periodosResponse.status !== 200 &&
      periodosSeleccionadosResponse.status !== 200
    ) {
      if (isAuthenticated()) {
        toast.error("Ocurrió un error al obtener los periodos");
      }
      return;
    }
    const periodosIniciados = periodosResponse.body.filter(
      (periodo) => periodo.estadoPeriodoId === INICIADO
    );
    const periodosSeleccionados = periodosSeleccionadosResponse?.body ? periodosSeleccionadosResponse.body.map(
      (periodo) => periodo.periodoId
    ) : [];

    setPeriodos(
      periodosIniciados.map((periodo) => {
        return {
          id: periodo.id,
          nombre: periodo.nombre,
          fechaInicio: periodo.fechaInicio,
          fechaFin: periodo.fechaFin,
          fechaSubInicio: periodo.fechaSubInicio,
          fechaSubFin: periodo.fechaSubFin,
          recalculoHabilitado: periodo.recalculoHabilitado,
          seleccionado: periodosSeleccionados.includes(periodo.id),
        };
      })
    );
  }

  return (
    <PeriodoContext.Provider
      value={{
        periodos,
        setPeriodos,
        reloadPeriodos,
        getSelected,
        fireReload,
        getMinMaxDates,
        getUnicoPeriodoSeleccionado,
        obtenerPeriodosSeleccionados
      }}
    >
      {children}
    </PeriodoContext.Provider>
  );
};

export { PeriodoContext, PeriodoProvider };
