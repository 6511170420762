import React, { useEffect } from "react";
import { Fab, modalClasses } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { TagItem } from "./tagItem";
import Button from "@mui/material/Button";
// import { IndicadoresModal } from "./indicadoresModal";
import { toast } from "react-toastify";

import { useLocation, useSearchParams } from "react-router-dom";
import Modal from "../general-components/modal";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import {deleteTag,getTag,getTags,postTag}  from "../Api/tag.api";
import {getTagFamily}  from "../Api/tagFamily.api";
import { clear } from "@testing-library/user-event/dist/clear";

function TagMain({ height }) {  
  const {familyId} = useParams();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [nombre, setNombre] = React.useState('');
  const [id, setId] = React.useState(null);
  const [tagList, setTagList] = React.useState([]);
  const[nombreFamilia,setNombreFamilia] = React.useState("");

  useEffect(() => {
    
   cargarLista();
   getFamilyData();
  }, []);

  const setDataEdit = async(id)=>{
    clear();
    const data = await getTag(id);    
    if(data.status==200){
      setNombre(data.body.nombre);
      setId(data.body.id);
      openModal();
    }
  }

  async function remove(id){
    await deleteTag(id);
    cargarLista();
  }

if(!familyId){return}

    async function save() {    
     if(nombre.trim()===""){
      toast.warn("Ingresa un nombre válido")
      return;
     }

     let data = {

     }

     if(id === null){
      
      //crear
      data = {
        nombre:nombre,
        tagFamilyId: parseInt(familyId),
      };      
      await postTag(data);
      clear();
      handleCloseModal();
      cargarLista();
     }


  }

 const cargarLista = async()=>{
  if(!familyId){
    return;
  }
  try {
    const data = await getTags(familyId);    
    if(data.status == 200){
      setTagList(data.body);
    }
    
  } catch (error) {
    console.error(error);
  }
 }
  
 function clear(){
  setNombre("");
  setId(null);
 }

  function openModal() {
    setIsModalOpen(true);

  }


  function handleCloseModal(){
    setIsModalOpen(false);
  }

  const getFamilyData = async(id)=>{
    if(!familyId){return;}

    const data = await getTagFamily(familyId);    
    if(data.status==200){
      setNombreFamilia(data.body.nombre);
          }
  }

  return (
    <div style={{ width: "inherit" }}>
      <h2>Etiquetas de la familia {nombreFamilia}</h2>
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "inherit",
        }}
      >
<div style={{ boxShadow: "2px -1px 2px -2px #888", width: "100%", minWidth: "100%", float: "left" }}>
      {tagList.map((item) => {
        return (
           <TagItem
            key={item.id}
            item={item}
            setModalOpen={setIsModalOpen}
            cargarFamilias  = {cargarLista}
            setDataEdit = {setDataEdit}
            remover = {remove}
          />
          
        );
      })}
    </div>
      
        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: "fixed",
            right: 40,
            bottom: 15,
            zIndex: 5,
            backgroundColor: "#aebd36",
          }}
          onClick={() =>{clear(); openModal()}}
        >
          <AddIcon />
        </Fab>
     
      {/* <IndicadoresModal
        open={isModalOpen}
        setOpen={setModalOpen}
        updateIndicadores={updateIndicadores}
       
      /> */}
     </div>

     <Modal open={isModalOpen} handleClose={handleCloseModal} title={`Etiqueta para la familia ${nombreFamilia}`}>
     <div style={{ maxHeight: "550px", overflow: "auto" }}>
      <Grid container spacing={3} sx={{ marginTop: "0px" }}>
        <Grid item xs={6}>
          <TextField
            id="nombre"
            label="Nombre"
            variant="outlined"
            value={nombre}
            style={{ width: "100%" }}
            onChange={(e) => setNombre(e.target.value)}
            type="text"
            InputLabelProps={{
                shrink: true,
              }}
          />
        </Grid>
        <Grid item xs={12} lg={12} sm={12}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#69bd4b",
              color: "white",
              marginRight: "20px",
            }}
            onClick={() => save()}
          >
            Guardar
          </Button>
        </Grid>
        </Grid>
        </div>
    </Modal>

    </div>
  );
}

export { TagMain }
