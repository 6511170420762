import { Divider, IconButton, Tooltip } from "@mui/material";
import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import Confirm from "../general-components/confirm";
import { deleteRol } from "../Api/roles.api";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";

function Rol({ rol, setModalOpen, setRolActivo, updateRoles }) {
  const [selected, setSelected] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);

  function removeIndicador() {
    deleteRol(rol.id).then((response) => {
      if (response.status === 200) {
        toast.success("Rol eliminado exitosamente!");
        setRolActivo(null);
        setOpenConfirm(false);
        updateRoles();
      } else {
        toast.error(response.message);
      }
    });
  }

  function handleEdit() {
    if (rol.editable) {
      setRolActivo(rol);
      setModalOpen(true);
    } else {
      toast.error("No puedes editar o eliminar este rol");
    }
  }

  return (
    <>
      <section
        style={{
          padding: "10px 0",
          display: "flex",
          alignItems: "center",
          margin: "0px 3%",
        }}
      >
        <SwitchAccountIcon
          fontSize="large"
          color={`${selected ? "primary" : "inherit"}`}
          style={{ cursor: "pointer" }}
          onMouseOver={() => setSelected(true)}
          onMouseOut={() => setSelected(false)}
          onClick={handleEdit}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div>
              {rol.editable === true && (
                <div>
                  <Tooltip title="Editar">
                    <IconButton onClick={handleEdit}>
                      <EditIcon color="primary" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Eliminar">
                    <IconButton onClick={() => setOpenConfirm(true)}>
                      <DeleteForeverIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>

            <b>{rol.nombre}</b>
          </div>
        </div>
      </section>

      <div style={{ width: "100%", justifyContent: "center" }}>
        <Divider sx={{ width: "96%", marginLeft: "2%" }} />
      </div>
      <Confirm
        open={openConfirm}
        title={"Eliminar"}
        text={"¿Confirma que desea eliminar este elemento?"}
        onConfirm={removeIndicador}
        handleCloseProp={() => setOpenConfirm(false)}
      />
    </>
  );
}

export { Rol };
