import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { NodoContext } from "../Context/nodoContext";
import { useNavigate, useParams } from "react-router-dom";
import { deleteInforme, getInformes, sendEmail } from "../Api/vistaInforme.api";
import Confirm from "../general-components/confirm";
import { InformeList } from "./informeList";

export function InformesMain() {
  const [informeId, setInformeId] = React.useState(null);
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext);
  const [deleteInformeConf, setDeleteInformeConf] = React.useState(false);
  const [emailInformeConf, setEmailInformeConf] = React.useState(false);
  const [informes, setInformes] = React.useState([]);
  const { vistaId } = useParams();

  useEffect(() => {
    console.log("Me estoy ejevutando")
    if (vistaId) {
      updateInformes();
    } else {
      toast.error("No se encontró la vista");
    }
  }, []);

  function updateInformes() {
    // Prevenimos que se soliciten los informes si ya se tienen
    setLoading(true);
    setLoadingMessage("Actualizando informes...");
    getInformes(vistaId).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setInformes(response.body);
        console.log({informes: response.body})
      } else {
        toast.error("Ocurrió un error al actualizar los informes");
      }
    });
  }

  function removeInforme() {
    if (informeId !== null) {
      setLoading(true);
      setLoadingMessage("Eliminando informe...");
      deleteInforme(informeId).then((response) => {
        setLoading(false);
        if (response.status === 200) {
          toast.success("Informe eliminado correctamente");
          setDeleteInformeConf(false);
          setInformeId(null);
          updateInformes({ forceUpdate: true });
        } else {
          toast.error("Error al eliminar el informe");
        }
      });
    }
  }

  function sendEmailInforme() {
    if (informeId !== null) {
      setLoading(true);
      setLoadingMessage("Enviando email...");
      sendEmail(informeId).then((response) => {
        console.log({response})
        setLoading(false);
        if (response.status === 201) {
          toast.success("Email enviado correctamente");
          setEmailInformeConf(false);
          setInformeId(null);
        } else {
          toast.error("Error al enviar el email");
        }
      });
    }
  }

  return (
    <div>
      <InformeList
        informes={informes}
        setInformeId={setInformeId}
        setDeleteInformeConf={setDeleteInformeConf}       
        setEmailInformeConf={setEmailInformeConf} 
      />
      <Confirm
        open={emailInformeConf}
        title={"Enviar informe por correo"}
        text={"¿Confirma que desea enviar este informe por correo?"}
        onConfirm={sendEmailInforme}
        handleCloseProp={() => setEmailInformeConf(false)}
      />
      <Confirm
        open={deleteInformeConf}
        title={"Eliminar"}
        text={"¿Confirma que desea eliminar este informe?"}
        onConfirm={removeInforme}
        handleCloseProp={() => setDeleteInformeConf(false)}
      />
    </div>
  );
}
