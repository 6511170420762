import React from "react";
import { TreeItem, treeItemClasses } from '@mui/x-tree-view';
import { styled } from "@mui/material/styles";

const StyledTreeItemRoot = styled(TreeItem)(
  ({ theme, coloritem = "white", level = 2 }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
      color: coloritem,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      "&.Mui-expanded": {
        fontWeight: theme.typography.fontWeightRegular,
      },
      /*"&:hover": {
        backgroundColor: theme.palette.action.hover,
      },*/
      //"&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      "&.Mui-selected, &.Mui-selected.Mui-focused": {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: "var(--tree-view-color)",
      },
      [`& .${treeItemClasses.label}`]: {
        fontWeight: "inherit",
        color: "inherit",
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 0,
      [`& .${treeItemClasses.content}`]: {
        paddingLeft: theme.spacing(level),
      },
    },
  })
);

export default StyledTreeItemRoot;
