import { getRequest, postRequest, putRequest } from "./requests";
import { HOST } from "../Utils/config";

async function getPresignedUrl({ name, type, format }) {
  return await getRequest(
    `${HOST}/api/v1/files/presignedURL?name=${name}&type=${type}&format=${format}`
  );
}

async function uploadImageURL({ fileName, type, ext }) {
  return await getRequest(
    `${HOST}/api/v1/files/image?name=${fileName}&type=${type}&format=${ext}`
  );
}

async function getArchivosByResultados(idResultado) {
  return await getRequest(`${HOST}/api/v1/mediaResultado/${idResultado}`);
}

async function deleteArchivo(body) {
  return await putRequest(`${HOST}/api/v1/mediaResultado`, body);
}

async function getFilePresignedUrl(name) {
  return await getRequest(`${HOST}/api/v1/files?name=${name}`);
}

async function getFileAsBlob(url) {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error(error);
    return null;
  }
}
async function postFilesMetadata(filesMetadata) {
  return new Promise(async (resolve, reject) => {
    try {
      await postRequest(`${HOST}/api/v1/mediaResultado`, filesMetadata);
      resolve();
    } catch (error) {
      reject();
    }
  });
}

async function putToS3(file, url, ContentType) {  
  try {
    const response = await fetch(url, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": ContentType,
      },
    });
    if (response.ok) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

export {
  getPresignedUrl,
  putToS3,
  postFilesMetadata,
  deleteArchivo,
  getArchivosByResultados,
  getFilePresignedUrl,
  getFileAsBlob,
  uploadImageURL
};
