import { HOST } from "./config";
import { post, put, delBody } from "./api";
import {postRequest,deleteRequest} from "../Api/requests"

async function getUserList() {
  const response = await fetch(`${HOST}/api/v1/usuarios/`);
  if (response.ok) {
    const jsonResponse = await response.json();
    if (jsonResponse.body) {
      return jsonResponse.body;
    }
    return [];
  }
  return [];
}

async function getRaciUsers(nodoId) {
  const response = await fetch(`${HOST}/api/v1/raci/items/${nodoId}`);
  if (response.ok) {
    const jsonResponse = await response.json();
    if (jsonResponse.body) {
      return jsonResponse.body;
    }
    return [];
  }
  return [];
}

async function addRaciUser(data) {
  // const response = await post(`${HOST}/api/v1/raci`, data);
  const response = await postRequest(`${HOST}/api/v1/raci`, data);
    return response;
}

async function removeRaciUser(data) {
  // const response = await delBody(`${HOST}/api/v1/raci`, data);
  const response = await deleteRequest(`${HOST}/api/v1/raci`, data);
  return response;
}

export { getUserList, getRaciUsers, addRaciUser, removeRaciUser };
