import { HOST } from "../Utils/config";
import { deleteRequest, getRequest, postRequest, putRequest } from "./requests";


async function getGestiones(idObjeto, nombreObjeto) {
  return await getRequest(`${HOST}/api/v1/gestionObjeto/items?idObjeto=${idObjeto}&nombreObjeto=${nombreObjeto}`);
}



async function createGestion(gestion) {
  return await postRequest(`${HOST}/api/v1/gestionObjeto`, gestion);
}


async function deleteGestion(id) {
  return await deleteRequest(`${HOST}/api/v1/gestionObjeto/${id}`);
}


export {
 getGestiones,
 createGestion,
 deleteGestion
};
