import React from "react";
import { Box, Chip } from "@mui/material";
import { TreeItem, treeItemClasses } from '@mui/x-tree-view';
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";

const StyledEmpleadoItemRoot = styled(TreeItem)(
  ({ theme, coloritem = "white", level = 2, factor = 1 }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
      color: coloritem,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightLight,
      "&.Mui-expanded": {
        fontWeight: theme.typography.fontWeightRegular,
      },
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: "var(--tree-view-color)",
      },
      [`& .${treeItemClasses.label}`]: {
        fontWeight: "inherit",
        color: "inherit",
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 0,
      [`& .${treeItemClasses.content}`]: {
        paddingLeft: theme.spacing(level * factor),
      },
    },
  })
);



function StyledEmpleadoItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    eventIcon: EventIcon,
    labelText,
    puesto,
    nodeId,
    id,
    label,
    item,
    items = [],
    level,
    itemColor = "black",
    onClick,
    ...other
  } = props;

  function onEventClick(e) {
    // e.preventDefault();
    e.stopPropagation();
    onClick(item);
  }

  return (
    <StyledEmpleadoItemRoot
      coloritem={itemColor}
      level={level}
      factor={other.factor}
      label={
        <Box
          sx={{
            display: "flex",
            color: itemColor,
            alignItems: "center",
            p: 0.5,
            pr: 0,
          }}
        >
          <Box component={LabelIcon} sx={{ mr: 1 }} />
          <div style={{ display: 'flex', width: "100%", justifyContent: other.conPeso ? 'space-between' : 'flex-start', alignItems: 'center' }}>
            <div></div>
            <div>
              <b style={{ fontSize: other.puestoFontSize }}>{puesto}</b>
              <p style={{ fontSize: other.nombreFontSize }}>{labelText}</p>
            </div>
            {other.conPeso && (
              <div style={{ marginRight: '1rem' }}>
                <Chip label={item.pesoTotal ? `${item.pesoTotal}%` : '0%'} color={ item.pesoTotal === 100 ? 'success' : 'error' } />
              </div>
            )}
          </div>
          {(other.noPrimerNodo && item.primerNodo) ? (
            <div/>
          ) : (
            <Box component={EventIcon} sx={{ mr: 1 }} onClick={onEventClick} />
          )}
        </Box>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      {...other}
      nodeId={nodeId}
      itemId={nodeId}
    >
      {items.map((empleado) => {
        return (
          <StyledEmpleadoItem
            nodeId={`${empleado.usuario}`}
            id={empleado.id}
            labelText={empleado.nombre}
            puesto={empleado.puesto}
            labelIcon={PersonIcon}
            eventIcon={EventIcon}
            item={empleado}
            items={empleado.subordinados}
            level={level + 1}
            key={empleado.id}
            color="#000"
            bgColor="#aebd36"
            itemColor={itemColor}
            onClick={onClick}
            puestoFontSize={other.puestoFontSize}
            nombreFontSize={other.nombreFontSize}
            factor={other.factor}
            noPrimerNodo={other.noPrimerNodo}
            conPeso={other.conPeso}
          ></StyledEmpleadoItem>
        );
      })}
    </StyledEmpleadoItemRoot>
  );
}

StyledEmpleadoItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelText: PropTypes.string.isRequired,
};

export default StyledEmpleadoItem;
