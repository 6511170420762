import React from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import TreeViewSipoc from "../TreeView/treeViewSipoc";
import PerfectScrollbar from "react-perfect-scrollbar";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import {createValueMap} from "../Api/valueMap.api"

export function ValueMapForm({valueMapId,recargarListaMapas, handleClose}){

    const [nombre,setNombre] = React.useState("");
    const [descripcion, setDescripcion] = React.useState("");
    const [nodoId,setNodoId] = React.useState(null);
    const [nodoNombre, setNodoNombre] = React.useState("");

    const guardar = async ()=>{
        //validaciones
        if(nombre==""){
            toast.warn("Ingresa un nombre");
            return;
        }

        if(!nodoId){
            toast.warn("Debes seleccionar una actividad.");
            return;
        }

        const obj = {
            valueMapId,
            nodoId,
            nombre,
            descripcion
        }

        const resultado = await createValueMap(obj);
        if(resultado.status==201){
            toast.success("El mapa se creo exitosamente");
            recargarListaMapas();
            handleClose();
        }
    }
    

    return(
        <>
            <Grid container spacing={3}>
        <Grid item xs={11} lg={11} sm={11}>
          <TextField
            id="txtNombre"
            label="Nombre"
            variant="outlined"
            value={nombre}
            style={{ width: "100%" }}
            onChange={(event) => setNombre(event.target.value)}
             />
        </Grid>
        <Grid item xs={11} lg={11} sm={11}>
          <TextField
            id="txtDescripcion"
            label="Descripción"
            variant="outlined"
            value={descripcion}
            style={{ width: "100%" }}
            onChange={(event) => setDescripcion(event.target.value)}
             />
        </Grid>

        <Grid item xs={12} lg={12} sm={12}>
        <div style={{ maxHeight: "600px", overflow: "scroll" }}>
          <Grid container spacing={3}>
            {!nodoId && (
              <Grid item xs={12} lg={12} sm={12} style={{ paddingTop: 60 }}>
                <label>No se ha seleccionado la actividad</label>
              </Grid>
            )}

            {nodoId && (
              <Grid item xs={12} lg={12} sm={12} style={{ paddingTop: 60 }}>
                <label>
                  Actividad Seleccionada <b> {nodoNombre}</b>
                </label>
              </Grid>
            )}

            
            
              <Grid
                item
                xs={12}
                lg={12}
                sm={12}
                style={{ backgroundColor: "#fff", maxHeight: 300 }}
              >
                <PerfectScrollbar>
                  <TreeViewSipoc
                    setSelectedNodeId={setNodoId}
                    setSelectedNodeName={setNodoNombre}
                  />
                </PerfectScrollbar>
              </Grid>
           
            

    
            <Grid item xs={12} lg={12} sm={12}>
              <Button
                variant="contained"
                style={{ backgroundColor: "#69bd4b", color: "white" }}
                onClick={() => guardar()}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </div>
        </Grid>

        </Grid>
        </>
    )
}