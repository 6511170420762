import { Divider } from "@mui/material";
import React, { useEffect, useState  } from "react";
import SearchInput from "../TreeView/searchInput";
import { Informe } from "./informe";

import { IconButton, Tooltip, Pagination, PaginationItem } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";


const PaginatedList = ({ data, itemsPerPage, setInformeId, setDeleteInformeConf, setEmailInformeConf }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);

  return (
    <div>
      {/* Render your paginated data */}
      <ul>
        {currentData.map((informe, index) => (
          // <li key={index}>{item}</li>
          <Informe
            key={informe.id}
            informe={informe}
            setInformeId={setInformeId}
            setDeleteInformeConf={setDeleteInformeConf}
            setEmailInformeConf={setEmailInformeConf}
          />
        ))}
      </ul>

      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', margin: '2rem 0' }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          shape="rounded"
          color="primary"
          renderItem={(item) => (
            <PaginationItem
              component="div"
              {...item}
              onClick={(e) => handlePageChange(e, item.page)}
            />
          )}
        />
      </div>
    </div>
  );
};

export function InformeList({
  informes,
  setInformeId,
  setDeleteInformeConf,
  setEmailInformeConf,
}) {
  const [informesFiltrados, setInformesFiltrados] = React.useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setInformesFiltrados(informes);
  }, [informes]);

  const filterInformes = (e) => {
    setInformesFiltrados(
      informes.filter((informe) =>
        informe.nombre.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: "2rem", alignItems: "center", marginLeft: "1rem" }}>
        <div>
          <Tooltip title="Regresar">
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        </div>
        <section
          style={{
            width: "250px",
            border: "1px solid black",
            borderRadius: "20px",
            margin: "20px",
          }}
        >
          <SearchInput onChange={filterInformes} />
        </section>
      </div>
      <Divider />
      <PaginatedList
        data={informesFiltrados}
        itemsPerPage={20}
        setInformeId={setInformeId}
        setDeleteInformeConf={setDeleteInformeConf}
        setEmailInformeConf={setEmailInformeConf}
      />
      {/* {informesFiltrados.map((informe) => {
        return (
          <Informe
            key={informe.id}
            informe={informe}
            setInformeId={setInformeId}
            setDeleteInformeConf={setDeleteInformeConf}
            setEmailInformeConf={setEmailInformeConf}
          />
        );
      })} */}
    </div>
  );
}
