import { HOST } from "../Utils/config";
import { getRequest, postRequest, putRequest } from "./requests";

async function getEvaluacionDesempenoCompleta(periodoId) {
  return await getRequest(
    `${HOST}/api/v1/evaluacionesDesempeno/reportedesempenocompleto?periodoId=${periodoId}`
  );
}

async function getIndicadoresAsignadosConPesoYObjetivo(usuarioId) {
  return await getRequest(
    `${HOST}/api/v1/evaluacionesDesempeno/indicadoresconpeso/usuario/${usuarioId}`
  );
}

async function getReporteOrganizacional() {
  return await getRequest(
    `${HOST}/api/v1/evaluacionesDesempeno/reporteorganizacional`
  );
}

async function getEvaluacionIndicadoresAsignados(periodoId, usuarioId) {
  return await getRequest(
    `${HOST}/api/v1/evaluacionesDesempeno/indicadoresasignados?periodoId=${periodoId}&usuarioId=${usuarioId}`
  );
}

async function getEvaluacionIndicadoresDesempenoAsignados(periodoId, usuarioId) {
  return await getRequest(
    `${HOST}/api/v1/evaluacionesDesempeno/indicadoresdesempenoasignados?periodoId=${periodoId}&usuarioId=${usuarioId}`
  );
}

// Evaluacion de Desempeño
async function postEvaluaciones(periodoId) {
  return await postRequest(`${HOST}/api/v1/evaluacionesDesempeno`, { periodoId });
}

async function updateEvaluaciones(periodoId) {
  return await putRequest(`${HOST}/api/v1/evaluacionesDesempeno/actualizarEvaluaciones?periodoId=${periodoId}`, { });
}

async function getEvaluaciones(periodoId) {
  return await getRequest(`${HOST}/api/v1/evaluacionesDesempeno?periodoId=${periodoId}`);
}

async function postGestionesEvaluaciones(periodoId, bonoZafra) {
  return await postRequest(`${HOST}/api/v1/evaluacionesDesempeno/evaluaciones`, { periodoId, bonoZafra });
}

async function postGestionEvaluacion(evaluacionId) {
  return await postRequest(`${HOST}/api/v1/evaluacionesDesempeno/evaluacion`, { evaluacionId });
}


export {
  getEvaluacionDesempenoCompleta,
  getIndicadoresAsignadosConPesoYObjetivo,
  getReporteOrganizacional,
  getEvaluacionIndicadoresAsignados,
  getEvaluacionIndicadoresDesempenoAsignados,
  postEvaluaciones,
  updateEvaluaciones,
  getEvaluaciones,
  postGestionesEvaluaciones,
  postGestionEvaluacion,
};
