import React from 'react';
import PropTypes from "prop-types";
import { toast } from 'react-toastify'
import { Box, Tab, Tabs, Grid } from "@mui/material";
import { NodoContext } from '../Context/nodoContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { getDashboardEvaluacion } from '../Api/dashboardEvaluacion.api'
import SimpleBreadcrumbs, { BreadcrumbItem } from '../general-components/breadcums';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Indicadores from './indicadores';
import Evaluaciones from './evaluaciones';
import Competencias from './competencias';
import { PeriodoContext } from '../Context/periodoContext';
import { MessageBar } from '../general-components/messageBar';
import { getConfiguracionNiveles } from '../Api/configuracion.api';
import { getPermisoAdminDesempeno } from '../Api/auth.api';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div style={{ width: '100%' }}>
                    {children}
                </div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const DashboardEvaluacion = () => {

    const location = useLocation()
    const navigate = useNavigate();
    const { id } = location?.state || {}

    const { setLoading, setLoadingMessage } = React.useContext(NodoContext)
    const { getUnicoPeriodoSeleccionado } = React.useContext(PeriodoContext);

    const [data, setData] = React.useState({})
    const [tabIndex, setTabIndex] = React.useState(0);
    const [nivelesConfig, setNivelesConfig] = React.useState([]);
    const [competenciasDisabled, setCompetenciasDisabled] = React.useState(true);
    const [permisoAdminDesempeno, setPermisoAdminDesempeno] = React.useState(false);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    React.useEffect(() => {
        setLoading(true)
        obtenerPermisoAdminDesempeno()
        getDashboardEvaluacion(id).then((response) => {
            const { body } = response || {}
            const { periodoId } = body || {}
            const json = JSON.parse(body?.json || '{}')
            const competencias = json?.competencias || {}
            setCompetenciasDisabled(!(Object.keys(competencias).length > 0))
            setData(body)
            if (periodoId) {
                getConfiguracionNiveles(periodoId).then((response1) => {
                    setNivelesConfig(response1.body)
                }).catch((error) => {
                    toast.error('No se pudieron recuperar las configuraciones de niveles')
                }).finally(() => {
                    setLoading(false)
                })
            } else {
                toast.error('No se encontró un periodo válido')
                setLoading(false)
            }
        }).catch((error) => {
            toast.error('No se pudieron recuperar las evaluaciones')
            setLoading(false)
        })
    }, [])

    const obtenerPermisoAdminDesempeno = () => {
        getPermisoAdminDesempeno().then((response) => {
            if (response.status === 200) {
                if (response.body?.permiso) {
                    setPermisoAdminDesempeno(true)
                } else {
                    setPermisoAdminDesempeno(false)
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    if (!id && getUnicoPeriodoSeleccionado() === null)
        return (
            <MessageBar
                display={true}
                type="error"
                message="Debe de seleccionar un único periodo para visualizar los resultados"
                title="Error"
            />
        )

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <section
                style={{
                    textAlign: 'left',
                    marginLeft: '20px',
                    marginTop: '20px'
                }}
            >
                <SimpleBreadcrumbs>
                    {id && (
                        <BreadcrumbItem
                            title="Evaluaciones de desempeño"
                            link="/home/evaluacionesdesempeno"
                            IconHome={AssignmentIcon}
                        />
                    )}
                    <BreadcrumbItem
                        title={`Dashboard Evaluación: ${data?.usuarioNombreCompleto}`}
                        IconHome={!id ? AssignmentIcon : null}
                        link="/"
                        isLast
                    />
                </SimpleBreadcrumbs>
            </section>
            <Grid container sx={{ marginTop: '20px' }}>
                {/* tabs */}
                <Grid xs={12} style={{
                    fontSize: "20px",
                    textAlign: "left",
                    // marginLeft: "20px",
                }}>
                    <Tabs value={tabIndex} onChange={handleTabChange}>
                        <Tab label="Indicadores" />
                        <Tab label="Competencias" disabled={competenciasDisabled} />
                        <Tab label="Retroalimentación" />
                    </Tabs>
                </Grid>
                {/* contenido */}
                <Grid xs={12} sx={{ marginTop: 3, paddingLeft: 1, paddingRight: 1 }}>
                    <TabPanel value={tabIndex} index={0}>
                        <Indicadores data={data} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <Competencias data={data} />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2}>
                        <Evaluaciones data={data} nivelesConfig={nivelesConfig} setData={setData} permisoAdminDesempeno={permisoAdminDesempeno} />
                    </TabPanel>
                </Grid>
            </Grid>
        </div>
    )
}

export default DashboardEvaluacion;
