import { HOST } from '../Utils/config'
import { getRequest, postRequest, putRequest } from './requests'

async function getMapPositions(nodoId) {
  return await getRequest(`${HOST}/api/v1/mapaActividades/${nodoId}`)
}

async function putMapPositions(positions) {
  return await putRequest(`${HOST}/api/v1/mapaActividades`, positions)
}

export { getMapPositions, putMapPositions }
