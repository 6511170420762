import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const responsiveCellStyle = {
    xs: 12,
    sm: 6,
    md: 6,
}

const CrearMatrizPuntos = ({
    configuracion,
    setConfiguracion,
    periodos,
}) => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
                <Grid item {...responsiveCellStyle}>
                    <TextField
                        required
                        type='number'
                        label="Rango Inicio"
                        variant="outlined"
                        value={configuracion?.rango_inicio}
                        onChange={(e) => setConfiguracion({ ...configuracion, rango_inicio: e.target.value })}
                        fullWidth
                    />
                </Grid>
                <Grid item {...responsiveCellStyle}>
                    <TextField
                        required
                        type='number'
                        label="Rango Fin"
                        variant="outlined"
                        value={configuracion?.rango_fin}
                        onChange={(e) => setConfiguracion({ ...configuracion, rango_fin: e.target.value })}
                        fullWidth
                    />
                </Grid>
                <Grid item {...responsiveCellStyle}>
                    <TextField
                        required
                        type='number'
                        label="Puntos"
                        variant="outlined"
                        value={configuracion?.puntos}
                        onChange={(e) => setConfiguracion({ ...configuracion, puntos: e.target.value })}
                        fullWidth
                    />
                </Grid>
                <Grid item {...responsiveCellStyle}>
                    <Autocomplete
                        required
                        options={periodos || []}
                        value={periodos?.find((periodo) => periodo.id === configuracion?.periodoId) || null}
                        getOptionLabel={(option) => option?.nombre || ''}
                        getOptionKey={(option) => option.id || ''}
                        onChange={(e, newValue) => {
                            setConfiguracion({ ...configuracion, periodoId: newValue?.id || null });
                        }}
                        renderInput={(params) => <TextField {...params} label="Período" variant="outlined" />}
                        style={{ width: "100%" }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default CrearMatrizPuntos;