import React from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LogoComponent from "./logoComponent";
import MySettings from "./mySettingsComponent";
import TemporaryDrawer from "../../general-components/temporary-drawer";
import { PeriodoSelectionList } from "../../Periodos/periodoSelectionList";
import EventNoteIcon from "@mui/icons-material/EventNote";
import Tooltip from "@mui/material/Tooltip";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import EstructuraPuestosMain from "../../EstructuraPuestos/estructuraPuestosMain";
import { DEFAULT_DELAY } from "../../Utils/config";

export default function DrawerTopBar({ open, setOpen }) {
  const [openPeriodosDrawer, setOpenPeriodosDrawer] = React.useState(false);
  const [openEstructuraDrawer, setOpenEstructuraDrawer] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  return (
    <Toolbar
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex" }}>
      <Tooltip title="Abrir el drawer" enterDelay={DEFAULT_DELAY}>
        <IconButton
          color="inherit"
          aria-label="árbol de navegaciónr"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>
        </Tooltip>        
        <LogoComponent />
        
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <MySettings />
        <Tooltip title="Periodos">
          <EventNoteIcon
            sx={{ marginLeft: "10px", cursor: "pointer" }}
            onClick={() => setOpenPeriodosDrawer(true)}
          />
        </Tooltip>
        <Tooltip title="Estructura Organizacional">
          <AccountTreeIcon
            sx={{ marginLeft: "10px", cursor: "pointer" }}
            onClick={() => setOpenEstructuraDrawer(true)}
          />
        </Tooltip>
        <TemporaryDrawer
          position={"right"}
          title="Periodos"
          show={openPeriodosDrawer}
          handleClose={() => setOpenPeriodosDrawer(false)}
          hideBackdrop={false}
        >
          <PeriodoSelectionList />
        </TemporaryDrawer>
        <TemporaryDrawer
          position={"right"}
          title="Estructura Organizacional"
          show={openEstructuraDrawer}
          handleClose={() => setOpenEstructuraDrawer(false)}
          hideBackdrop={false}
        >
          <EstructuraPuestosMain />
        </TemporaryDrawer>
      </div>
    </Toolbar>
  );
}
