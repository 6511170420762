import { HOST } from "./config";
import { post, put, delBody } from "./api";
import {postRequest,deleteRequest} from "../Api/requests"

async function getList() {
  const response = await fetch(`${HOST}/api/v1/etiqueta/`);
  if (response.ok) {
    const jsonResponse = await response.json();
    if (jsonResponse.body) {
      return jsonResponse.body;
    }
    return [];
  }
  return [];
}

async function getNodeTags(nodoId) {
  const response = await fetch(`${HOST}/api/v1/etiquetaNodo/items/${nodoId}`);
  if (response.ok) {
    const jsonResponse = await response.json();
    if (jsonResponse.body) {
      return jsonResponse.body;
    }
    return [];
  }
  return [];
}

async function addNodeTag(data) {
  // const response = await post(`${HOST}/api/v1/etiquetaNodo`, data);
  const response = await postRequest(`${HOST}/api/v1/etiquetaNodo`, data);
  return response;
}

async function removeTag(data) {
  // const response = await delBody(`${HOST}/api/v1/etiquetaNodo`, data);
  const response = await deleteRequest(`${HOST}/api/v1/etiquetaNodo`, data);
  return response;
}

export { getList, getNodeTags, addNodeTag, removeTag };
