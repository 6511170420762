import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getReporteOrganizacional } from '../Api/evaluacionDesempeno.api'
import { DataGrid } from 'devextreme-react'
import {
    Column,
    ColumnChooser,
    FilterPanel,
    FilterRow,
    GroupItem,
    GroupPanel,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
    Selection,
    Sorting,
    StateStoring,
    Summary,
    TotalItem
} from 'devextreme-react/data-grid'
import { Export } from 'devextreme-react/chart'
import { useDownloadExcel } from '../Hooks/useDownloadExcel'
import themes from 'devextreme/ui/themes'
import { Link } from 'react-router-dom'

export function ReporteResumen({ data, obtenerResultados }) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <section style={{ margin: '0px 40px' }}>
                <IndicadoresAsignadosTable
                    dataSource={data}
                    setDatasource={obtenerResultados}
                />
            </section>
        </div>
    )
}

function IndicadoresAsignadosTable({ dataSource, setDatasource }) {
    const { onExporting } = useDownloadExcel()

    return (
        <DataGrid
            dataSource={dataSource}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnResizingMode="nextColumn"
            onExporting={onExporting}
        >
            <StateStoring
                enabled={true}
                type="localStorage"
                storageKey={'reporteDesempenoStorage'}
            />
            <Sorting mode="multiple" />
            <ColumnChooser
                enabled={true}
                mode="dragAndDrop"
            />
            <GroupPanel visible={true} />
            <Selection
                mode="multiple"
                selectAllMode={'allMode'}
                showCheckBoxesMode={
                    themes.current().startsWith('material') ? 'always' : 'onClick'
                }
            />
            <Column
                dataField="pilarNombre"
                caption="Pilar"
                dataType="string"
                visible={true}
                groupIndex={0}
            />
            <Column
                dataField="objetivoNombre"
                caption="Objetivo"
                dataType="string"
                visible={true}
                groupIndex={1}
            />
            <Column
                dataField="nodoNombre"
                caption="Proceso/Actividad"
                dataType="string"
                visible={true}
                groupIndex={2}
                cellRender={(rowInfo) => (
                    <Link to={`/home/procesos/${rowInfo.data.nodoId}/indicadores`}>
                        {rowInfo.value}
                    </Link>
                )}
            />
            <Column dataField="evaluacionId" caption="Id Evaluación" dataType="number" visible={false} />
            <Column
                dataField="evaluacionNombre"
                caption="Indicador"
                dataType="string"
                visible={true}
                cellRender={(rowInfo) => (
                    <Link to={`/home/procesos/${rowInfo.data.nodoId}/indicadores`}>
                        {rowInfo.value}
                    </Link>
                )}
            />
            <Column
                dataField="periodoNombre"
                caption="Periodo"
                dataType="string"
                visible={true}
            />
            <Column
                dataField="frecuencia"
                caption="Frecuencia"
                dataType="string"
                visible={true}
            />
            <Column
                dataField="modoIntegracionId"
                caption="Modo de Integración"
                dataType="string"
                cellRender={cellInfo => cellInfo.value == 1 ? 'Directo' : cellInfo.value == 2 ? 'Derivado' : 'Calculado'}
                visible={true}
            />
            <Column
                dataField="meta"
                caption="Meta"
                dataType="number"
                visible={true}
            />
            <Column
                dataField="resultado"
                caption="Resultado"
                dataType="number"
                visible={true}
            />
            <FilterRow visible={true} />
            <FilterPanel visible={false} />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} highlightCaseSensitive={true} />
            <Paging defaultPageSize={50} />
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[50, 100, 150, 200]}
                showInfo={true}
            />
            <Export enabled={true} allowExportSelectedData={true} />
            <Summary>
                {/* <TotalItem
                    column="resultadoPonderado"
                    summaryType="sum"
                    valueFormat="number"
                    customizeText={(data) => `${data.value.toFixed(2)}%`}
                /> */}
                <GroupItem
                    column="resultado"
                    summaryType="sum"
                    valueFormat="number"
                    customizeText={(data) => `${data.value.toFixed(2)}`}
                    alignByColumn={true}
                />
            </Summary>
        </DataGrid>
    )
}
