import React from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { Divider, IconButton, Tooltip } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import FeedIcon from '@mui/icons-material/Feed'
import MessageIcon from '@mui/icons-material/Message'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { StyledBadge } from '../general-components/StyledBadge'

export function Informe({ informe, setDeleteInformeConf, setInformeId, setEmailInformeConf }) {
  const [selected, setSelected] = React.useState(false)
  const navigate = useNavigate()

  function handleDelete(e) {
    e.stopPropagation()
    setDeleteInformeConf(true)
    setInformeId(informe.id)
  }
  
  function handleSendEmail(e) {
    e.stopPropagation()
    setEmailInformeConf(true)
    setInformeId(informe.id)
  }

  return (
    <>
      <section
        style={{
          padding: '10px 0',
          display: 'flex',
          alignItems: 'center',
          margin: '0px 3%',
          cursor: 'pointer'
        }}
        onMouseOver={() => setSelected(true)}
        onMouseOut={() => setSelected(false)}
        onClick={() => navigate(`/home/informes/${informe.id}`)}
      >
        <FeedIcon
          fontSize="large"
          color={`${selected ? 'primary' : 'inherit'}`}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '20px',
            alignItems: 'flex-start',
            width: '100%'
          }}
        >
          <div
            style={{
              display: 'flex',
              // justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <div style={{ display: 'flex', flex: 0.9 }}>
              <b style={{ textAlign: 'left' }}>{informe.nombre}</b>
            </div>
            <div style={{ display: 'flex', flex: 0.10, justifyContent: 'flex-end' }}>
              {informe.publico && (
                <Tooltip title="Enviar Correo">
                  <IconButton onClick={handleSendEmail}>
                    <EmailIcon color="primary" />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Eliminar">
                <IconButton onClick={handleDelete}>
                  <DeleteForeverIcon color="error" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <small>
              Fecha creación: {moment(informe.fechaCreacion).format('LL')}
            </small>
            <small style={{ display: 'flex', alignItems: 'center' }}>
              <StyledBadge badgeContent={informe.comentarios} color="primary">
                <MessageIcon color={informe.comentarios ? 'primary' : 'disabled'} fontSize="small" />
              </StyledBadge>
            </small>
            <small style={{ display: 'flex', alignItems: 'center' }}>
              <StyledBadge badgeContent={informe.archivos} color="primary">
                <AttachFileIcon color={informe.archivos ? 'primary' : 'disabled'} fontSize="small" />
              </StyledBadge>
            </small>
          </div>
        </div>
      </section>

      <div style={{ width: '100%', justifyContent: 'center' }}>
        <Divider sx={{ width: '96%', marginLeft: '2%' }} />
      </div>
    </>
  )
}
