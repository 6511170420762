import React from 'react'
import { Tree } from './tree'
import { TIPOS_NODO } from '../Utils/enums'
import FolderIcon from '@mui/icons-material/Folder'
import SettingsIcon from '@mui/icons-material/Settings'
import { cambiarOrdenNodo } from '../Api/nodos.api'
import { toast } from 'react-toastify'
import { useEstructuraArbol } from '../Hooks/useEstructuraArbol'

export default function ProcessTree({
  onNodeClick = () => {},
  fontColor = 'white'
}) {
  const { estructuraArbol, actualizarEstructura } = useEstructuraArbol()

  console.log({ estructuraArbol })
  return (
    <Tree
      fontColor={fontColor}
      level={0}
      iconsHash={{
        [TIPOS_NODO.PROCESO]: <FolderIcon />,
        [TIPOS_NODO.ACTIVIDAD]: <SettingsIcon />
      }}
      onNodeClick={onNodeClick}
      onDrop={(nodo, nodoActual) => {
        if (nodo.id === nodoActual.id) return
        cambiarOrdenNodo({
          nodoId: nodo.id,
          ordenDestino: nodoActual.Orden,
          nodoPadreDestinoId: nodoActual.nodoPadreId
        }).then((response) => {
          if (response.status === 200) {
            actualizarEstructura()
          } else {
            toast.error('Ocurrió un error al mover el nodo')
          }
        })
      }}
      estructuraArbol={estructuraArbol}
    />
  )
}
