import { HOST } from "../Utils/config";
import { post } from "../Utils/api";
import { getRequest, postRequest, putRequest ,deleteRequest} from "./requests";

async function getTagFamilies() {
  return await getRequest(`${HOST}/api/v1/tagfamily`);
}

async function getTagFamily(id) {
  return await getRequest(`${HOST}/api/v1/tagfamily/${id}`);
}

async function postTagFamily(data) {
  return await postRequest(`${HOST}/api/v1/tagFamily`, data);
}

async function putTagFamily(data) {
  return await putRequest(`${HOST}/api/v1/tagFamily`, data);
}

async function deleteTagFamily(id) {
  return await deleteRequest(`${HOST}/api/v1/tagfamily/${id}`);
}

export {
  getTagFamily,
  getTagFamilies,
  postTagFamily,
  putTagFamily,
  deleteTagFamily
};
