import React from 'react'
import { toast } from 'react-toastify'
import EditIcon from '@mui/icons-material/Edit'
import {
  Menu,
  Divider,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material'
import { modosIntegracion } from '../Utils/enums'
import Confirm from '../general-components/confirm'
import ReportIcon from '@mui/icons-material/Report';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { PeriodoContext } from '../Context/periodoContext'
import { useLocation, useNavigate } from 'react-router-dom'
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt'
import { deleteAgrupacion } from '../Api/agrupaciones.api'
import { reiniciarIndicadorPeriodo, solicitarReinicioIndicador } from '../Api/periodo.api'
import { deleteIndicador, getDiferenciasIndicador } from '../Api/indicadores.api'
import styles from '../Chart/graph.module.css'
import TransitionsModal from '../general-components/modal'

function Indicador({
  indicador,
  setModalOpen,
  setIndicadorActivo,
  updateIndicadores,
  indicadorActivo,
  permisos,
  setOpenModalCopia,
  nodoId,
  setOpenModalCompuesto
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [openConfirm2, setOpenConfirm2] = React.useState(false)
  const [openConfirm3, setOpenConfirm3] = React.useState(false)
  const [diferenciaIndicador, setDiferenciaIndicador] = React.useState({ esDiferente: false, diferencias: [] })
  const { getUnicoPeriodoSeleccionado } = React.useContext(PeriodoContext)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    if (indicador && indicador.id) {
      obtenerDiferencias()
    }
  }, [indicador])

  async function obtenerDiferencias() {
    try {
      if (!getUnicoPeriodoSeleccionado()) return null
      const response = await getDiferenciasIndicador(
        indicador.id,
        getUnicoPeriodoSeleccionado()?.id,
        indicador.modoIntegracionId === modosIntegracion.agrupado ? 1 : 0
      )
      if (response.status === 200) {
        setDiferenciaIndicador(response.body)
      }
      return null
    } catch (error) {
      console.error(error)
      return null
    }
  }

  async function removeIndicador(e) {
    let response
    if (indicador.modoIntegracionId === modosIntegracion.agrupado) {
      response = await deleteAgrupacion(indicador.id)
    } else {
      response = await deleteIndicador(indicador.id)
    }
    if (response.status === 200) {
      toast.success('Indicador eliminado exitosamente!')
      setIndicadorActivo(response.body)
      setOpenConfirm(false)
      updateIndicadores()
    } else {
      toast.error(response.message)
    }
  }

  async function resetIndicador(e) {
    const response = await reiniciarIndicadorPeriodo(
      indicador.id,
      getUnicoPeriodoSeleccionado().id,
      indicador.modoIntegracionId === modosIntegracion.agrupado
    )
    if (response.status === 200) {
      toast.success('Indicador reiniciado exitosamente!')
      setOpenConfirm2(false)
      updateIndicadores()
    } else {
      toast.error(response.message)
    }
  }
  
  async function solicitarReinicio(e) {
    const response = await solicitarReinicioIndicador(
      indicador.id,
      getUnicoPeriodoSeleccionado().id,
      indicador.modoIntegracionId === modosIntegracion.agrupado
    )
    if (response.status === 201) {
      toast.success('Se ha solicitado el reinicio del indicador exitosamente!')
      setOpenConfirm2(false)
      updateIndicadores()
    } else {
      toast.error(response.message)
    }
  }

  function getDescripcionSubstring(descripcion) {
    const descripcionCorta = descripcion.substring(0, 80)
    if (descripcion.length > 80) {
      return `${descripcionCorta}...`
    }
    return descripcionCorta
  }

  const navegarGestiones = (e) => {
    e.stopPropagation()
    navigate(`/home/gestiones/${indicador.id}/${nodoId}/Tindicador`)
  }
  const navegarIndicador = (event) => {
    event.stopPropagation()
    setIndicadorActivo(indicador)
    navigate(pathname)
  }

  const handleClick = (event) => { setAnchorEl(event.currentTarget) };
  const handleClose = () => { setAnchorEl(null) };

  return (
    <>
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ width: '100%' }}>
          <section
            style={{
              padding: '10px 0',
              display: 'flex',
              alignItems: 'center',
              paddingRight: '10px',
              borderTopRightRadius: '40px',
              borderBottomRightRadius: '40px',
              width: '100%'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '15px',
                alignItems: 'flex-start',
                width: '100%'
              }}
              onClick={navegarIndicador}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
                data-testid="indicadorTitulo"
              >
                <b style={{ cursor: 'pointer', textAlign: 'left' }}>
                  {indicador.nombre}
                </b>

                <div style={{ display: 'flex' }}>
                  {(diferenciaIndicador.esDiferente) && (
                    <Tooltip
                      arrow
                      placement='right'
                      title='Indicador con diferencias respecto al periodo seleccionado'
                    >
                      <IconButton
                        id="alert-button"
                        className={styles.blink}
                        onClick={(e) => {
                          e.stopPropagation()
                          setOpenConfirm3(true)
                        }}
                      >
                        <ReportIcon color='warning' />
                      </IconButton>
                    </Tooltip>
                  )}
                  <IconButton
                    id="long-button"
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    {permisos.U == 1 && (
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation()
                          setIndicadorActivo(indicador)
                          setOpenModalCopia(true)
                        }}
                      >
                        <ListItemIcon>
                          <ContentCopyIcon
                            color="warning"
                            fontSize="small"
                          />
                        </ListItemIcon>
                        <ListItemText>Copiar / Mover</ListItemText>
                      </MenuItem>
                    )}
                    {permisos.U == 1 && (
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation()
                          setOpenConfirm2(true)
                        }}
                        disabled={!getUnicoPeriodoSeleccionado()}
                      >
                        <ListItemIcon>
                          <RestartAltIcon
                            color="secondary"
                            fontSize="small"
                          />
                        </ListItemIcon>
                        <ListItemText>
                          {!getUnicoPeriodoSeleccionado()?.recalculoHabilitado ? "Solicitar reinicio" : "Reiniciar"}
                        </ListItemText>
                      </MenuItem>
                    )}
                    {permisos.U == 1 && (
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation()
                          setIndicadorActivo(indicador)
                          setModalOpen(true)
                        }}
                      >
                        <ListItemIcon>
                          <EditIcon
                            color="primary"
                            fontSize="small"
                          />
                        </ListItemIcon>
                        <ListItemText>Editar</ListItemText>
                      </MenuItem>
                    )}
                    {permisos.R == 1 && (
                      <MenuItem
                        onClick={navegarGestiones}
                      >
                        <ListItemIcon>
                          <AppSettingsAltIcon
                            color="primary"
                            fontSize="small"
                          />
                        </ListItemIcon>
                        <ListItemText>Gestiones AppILU</ListItemText>
                      </MenuItem>
                    )}
                    {permisos.D == 1 && (
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation()
                          setOpenConfirm(true)
                        }}
                      >
                        <ListItemIcon>
                          <DeleteForeverIcon
                            color="error"
                            fontSize="small"
                          />
                        </ListItemIcon>
                        <ListItemText>Eliminar</ListItemText>
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              </div>
              <p style={{ textAlign: 'left' }}>
                {getDescripcionSubstring(indicador.descripcion)}
              </p>
            </div>
          </section>

          <div style={{ justifyContent: 'center' }}>
            <Divider orientation="horizontal" sx={{ margin: '0px 10px' }} />
          </div>
          <Confirm
            open={openConfirm}
            title={'Eliminar'}
            text={'¿Confirma que desea eliminar este elemento?'}
            onConfirm={removeIndicador}
            handleCloseProp={() => setOpenConfirm(false)}
          />
          <Confirm
            open={openConfirm2}
            title={!getUnicoPeriodoSeleccionado()?.recalculoHabilitado ? 'Solicitar reinicio de indicador' : 'Reiniciar Indicador'}
            text={
              !getUnicoPeriodoSeleccionado()?.recalculoHabilitado ?
              '¿Confirma que desea solicitar un reinicio de este indicador para el periodo seleccionado? Esta acción enviará una solicitud al administrador del sistema'
              : '¿Confirma que desea reiniciar este indicador para el periodo seleccionado?'}
            onConfirm={!getUnicoPeriodoSeleccionado()?.recalculoHabilitado ? solicitarReinicio : resetIndicador}
            handleCloseProp={() => setOpenConfirm2(false)}
          />
          <TransitionsModal
            open={openConfirm3}
            title={'Diferencias del Indicador'}
            handleClose={() => setOpenConfirm3(false)}
          >
            <List>
              {diferenciaIndicador.diferencias.map((diferencia, index) => (
                <ListItem disablePadding key={index}>
                  <ListItemButton>
                    <p>
                      <b>{diferencia.key}: </b>
                      {diferencia.evaluacion}
                    </p>
                  </ListItemButton>
                </ListItem>
              ))}
              {diferenciaIndicador.diferencias.length === 0 && (
                <Typography textAlign='center' variant='body1'>
                  El indicador no ha sido iniciado para el periodo seleccionado
                </Typography>
              )}
            </List>
          </TransitionsModal>
        </div>
        <div
          style={{
            width: '6px',
            backgroundColor: indicadorActivo?.id === indicador.id ? 'rgba(174,189,54, 0.95)' : '#fff'
          }}
        ></div>
      </div>
    </>
  )
}

export { Indicador }
