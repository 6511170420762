import React, {  useState } from "react";
import Grid from "@mui/material/Grid";
import CkEditor from "../general-components/ckeditor";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { NodoContext } from "../Context/nodoContext";
import { postNodo } from "../Api/nodos.api";

export default function NodeForm({
  nodeParentId = null,
  nodeTypeId,
  closeModalFn
}) {
  const [nombre, setNombre] = useState("");
  const [codigo, setCodigo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [empresaId, setEmpresaId] = useState(1);
  const [tipoNodoId, setTipoNodoId] = useState(null);
  const {
    reloadTree
  } = React.useContext(NodoContext)

  const handleChangeName = (event) => {
    setNombre(event.target.value);
  };

  const handleChangeCode = (event) => {
    setCodigo(event.target.value);
  };

  const handleChangeDescription = (value) => {
    setDescripcion(value);
  };

  const validateInputs = () => {
    let res = true;
    if (nombre === null || nombre === undefined || nombre === "") {
      res = false;
    }

    if (codigo === null || codigo === undefined || codigo === "") {
      res = false;
    }
    if (
      descripcion === null ||
      descripcion === undefined ||
      descripcion === ""
    ) {
      res = false;
    }

    return res;
  };

  const save = async () => {
    if (!validateInputs()) {
      toast.warn("por favor llena todos los campos");
      return;
    }

    let data = {
      nombre: nombre,
      codigo: codigo,
      descripcion: descripcion,
      empresaId: empresaId,
      tipoNodoId: nodeTypeId,
    };
    if (nodeParentId) {
      data = { ...data, nodoPadreId: nodeParentId };
    }
    
    const res = await postNodo(data);
    if (res.status === 201) {
      toast.success("El nodo se creo correctamente");
      try {
        closeModalFn();
      } catch (error) {}

      try {
        reloadTree();
      } catch (error) {}
    } else {
      toast.error("Error al crear el nodo");
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12} sm={12}>
          <TextField
            id="txtName"
            label="Nombre"
            variant="outlined"
            value={nombre}
            style={{ width: "100%" }}
            onChange={handleChangeName}
          />
        </Grid>
        <Grid item xs={12} lg={12} sm={12}>
          <TextField
            id="txtCode"
            label="Código"
            variant="outlined"
            value={codigo}
            style={{ width: "100%" }}
            onChange={handleChangeCode}
          />
        </Grid>

        <Grid item xs={12} lg={12} sm={12}>
          <CkEditor
            value={descripcion}
            setValue={handleChangeDescription}
            titulo={"Descripción"}
          />
        </Grid>
        <Grid item xs={12} lg={12} sm={12}>
          <Button
            variant="contained"
            style={{ backgroundColor: "#69bd4b", color: "white" }}
            onClick={() => save()}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
