import Modal from "../../general-components/modal";
import React from "react";
import { CrearFavoritoForm } from "./crearFavoritoForm";

export function CrearFavoritoModal({
  open,
  handleCloseModal,
  updateFavoritos,
  vistaEdicion,
  agrupacionActualId,
}) {
  return (
    <Modal
      open={open}
      handleClose={handleCloseModal}
      title="Crear Tablero"
    >
      <CrearFavoritoForm
        handleCloseModal={handleCloseModal}
        updateFavoritos={updateFavoritos}
        vistaEdicion={vistaEdicion}
        agrupacionActualId={agrupacionActualId}
      />
    </Modal>
  );
}
