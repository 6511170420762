import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Fab } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { deleteGestion, getGestiones,createGestion } from "../Api/gestionObjeto.api";
import Confirm from "../general-components/confirm";
import { GestionesList } from "./gestionesList";
import {GestionesForm} from "./gestionesForm";
import Modal from "../general-components/modal";
import AddIcon from "@mui/icons-material/Add";
import {handleLogin,crearGestionAppilu,asignarUsuarios,getUser,gestionesLista, anularGestion} from "../AppILU/app-ilu-interactions";
import {getRaciUsers} from "../Utils/RaciRequest"
import { AuthContext } from "../Context/authContext";
import { NodoContext } from "../Context/nodoContext";

export function GestionesMain() {
  const [gestionId, setGestionId] = React.useState(null);
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext);
  const [deleteGestionConf, setDeleteGestionConf] = React.useState(false);
  const [gestiones, setGestiones] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const { idObjeto,nodoId,nombreObjeto } = useParams();
  const { getUsuario } = React.useContext(AuthContext);
  const { nodoActual } = React.useContext(NodoContext);

  useEffect(() => {
    if (idObjeto && nombreObjeto) {
      updateGestiones();
    } else {
      toast.error("No se encontró el elemento");
    }
  }, []);

  async function updateGestiones() {
    try{
    // Prevenimos que se soliciten los informes si ya se tienen
    setLoading(true);
    setLoadingMessage("Actualizando gestiones...");
    getGestiones(idObjeto,nombreObjeto).then(async(response) => {
      
      if (response.status === 200) {
        let gestionesLocales =  response.body;
       
        //Obtiene lista de gestiones Appilu
        const authOk = await handleLogin();

      if(!authOk){
        toast.error("No se pudo autenticar en Appilu");
          return;
      }
        let listaCodigosGestion = response.body.map((item)=>item.numeroGestion)
        console.log("gestiones eva",listaCodigosGestion)
        let listaParams = {listaGestiones:listaCodigosGestion}
        const gestionesAppilu = await gestionesLista(listaParams);
        console.log("gestionesAppilu", gestionesAppilu)

        //fusiona
        let fusionadas =  gestionesLocales.map((item)=>{
          const appilu = gestionesAppilu.filter((x)=>x.CodigoAviso == item.numeroGestion)
          item = {...item,appilu:(appilu.length>0?appilu[0]:{Titulo:null,Descripcion:null, CodigoAviso:null, estado:null})}
          return item
        })

        console.log("fusionadas", fusionadas)

        setGestiones(fusionadas);

      } else {
        toast.error("Ocurrió un error al actualizar las gestiones");
      }
    });
  }catch(err){
    console.error(err);
  }finally{
    setLoading(false);
  }
  }

  function handleClose(){
    setOpenModal(false);
  }

  async function removeGestion() {
    if (gestionId!== null) {
      setLoading(true);
      setLoadingMessage("Eliminando gestión...");
      deleteGestion(gestionId).then(async (response) => {
        setLoading(false);
        if (response.status === 200) {
          toast.success("Gestión eliminada correctamente");
          setDeleteGestionConf(false);
          setGestionId(null);
          updateGestiones({ forceUpdate: true });


          //conecta con Appilu
        const authOk = await handleLogin();

        if(!authOk){
        toast.error("No se pudo autenticar en Appilu");
        return;
        }

        //obtiene codigo de usuario solicitante
        const usuarioActual = getUsuario();
        const codigoUsuario = await getUser(usuarioActual);
        console.log("CodigoUSuario",codigoUsuario);

        //obtiene el código de aviso
        const gestion = gestiones.find((item)=>item.id == gestionId);
        if(gestion== null){
          return;
        }

        console.log("gestion", gestion)

        //Anula gestión en APPIlu
        const anularResponse = await anularGestion(gestion.numeroGestion,usuarioActual,codigoUsuario)

        } else {
          toast.error("Error al eliminar la gestión");
        }
      });
    }
  }

  const createNew = async(nombre, descripcion)=>{
    setLoading(true);
    setLoadingMessage("Creando gestión...");

    //conecta con Appilu
   const authOk = await handleLogin();

   if(!authOk){
    toast.error("No se pudo autenticar en Appilu");
      return;
   }

   //obtiene codigo de usuario solicitante
  const usuarioActual = getUsuario();
  const codigoUsuario = await getUser(usuarioActual);
  console.log("CodigoUSuario",codigoUsuario);

    const codigoAviso = await crearGestionAppilu(619,nombre,descripcion,codigoUsuario);
    console.log("CodigoAviso", codigoAviso);
    if(codigoAviso===null){
      toast.error("No se pudo crear la gestión en Appilu");
      return;
    }

    //asigna usuarios a tareas
    // obtiene matriz raci
    if(nodoId){
      const raciUsers =  await getRaciUsers(nodoId);
      console.log("raciUsers",raciUsers)

      let usuariosGestion = raciUsers.filter((el)=>el.TipoRaciId==2).map((item,index)=>item.Usuario.usuario)
      let usuariosTarea = raciUsers.filter((el)=>el.TipoRaciId==1).map((item,index)=>item.Usuario.usuario)

      console.log("usuariosGestion",usuariosGestion)
      console.log("usuariosTareas",usuariosTarea)
      
      const asignarUsuariosRequest = await asignarUsuarios(codigoAviso,usuariosGestion,usuariosTarea,usuarioActual)
      console.log("asignarUsuariosRequest",asignarUsuariosRequest)
          }

   
  


    const objeto = {
    nombre:nombre,
    numeroGestion:codigoAviso,
    idObjeto:idObjeto,
    nombreObjeto:nombreObjeto,  
  }

    createGestion(objeto).then((response) => {
      setLoading(false);
      if (response.status === 201) {
        updateGestiones();
      } else {
        toast.error("Ocurrió un error al actualizar las gestiones");
      }
    });
  }

  
  

  return ( 

     
        <div>
           {nodoActual?.permisos.R == 1 && (
        <GestionesList
          gestiones={gestiones}
          setGestionId={setGestionId}
          setDeleteGestionConf={setDeleteGestionConf}     
          puedeBorrar = {nodoActual?.permisos.C}   
          updateGestiones = {updateGestiones}
        />
           )}
         {nodoActual?.permisos.C == 1 && (
         <Fab
            color="primary"
            aria-label="add"
            sx={{
              position: "fixed",
              right: 10,
              bottom: 10,
              backgroundColor: "#aebd36",
            }}
            onClick={() => {            
              setOpenModal(true);
            }}
          >
            <AddIcon />
          </Fab>
         )}
  
          <Modal
          title={"Nueva gestión"}
          open={openModal}
          handleClose={handleClose}
        >
          <GestionesForm
            handleClose={handleClose}
            createNew = {createNew}
          />
        </Modal>
  
        <Confirm
          open={deleteGestionConf}
          title={"Eliminar"}
          text={"¿Confirma que desea eliminar esta gestión?"}
          onConfirm={removeGestion}
          handleCloseProp={() => setDeleteGestionConf(false)}
        />
      </div>
     
   
  
  
  );

}
