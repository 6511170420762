import React, { useState, useEffect, Fragment } from "react";
import { Tooltip, Fab } from "@mui/material";
import TagsNodeModal from "../../general-components/tagsNodeModal";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import { useAuth } from "../../Hooks/useAuth";
import { NodoContext } from "../../Context/nodoContext";
import {
  addNodeTag,
  getNodeTags,
  removeTag,
} from "../../Utils/TagNodeRequest";
import TagNodeMember from "./tagNodeMember";

export default function NodeTags() {
  const [tagList, setTagList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const { getUser } = useAuth();
  const { nodoActual } = React.useContext(NodoContext);

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    getTags();
  }, [nodoActual]);

  const handleClose = () => {
    setOpenModal(false);
  };

  const addTag = async (item) => {
    try {
      let data = {
        TagId: item.id,
        nodoId: nodoActual.id,
      };
      const res = await addNodeTag(data);
      if (res.status == 201) {
        getTags();
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getTags = async () => {
    try {
      const data = await getNodeTags(nodoActual.id);
      if (data.status == 200) {
        setTagList(data.body);
      }

    } catch (error) { }
  };

  const removerTag = async (item) => {

    const res = await removeTag(item.id);

    if (res.status == 200) {
      getTags();
    }
  };

  return (
    <Fragment>
      <TagsNodeModal open={openModal} closeFn={handleClose} onClickItem={addTag} />
      <Grid container spacing={1}>
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <div>
            {tagList.map((item, i) => (
              nodoActual.permisos.R == 1 && (
                <TagNodeMember
                  key={`tagItem${item.id}`}
                  item={item}
                  onConfirm={removerTag}
                  permisos={nodoActual.permisos}
                />
              )
            ))}
          </div>
        </Grid>
      </Grid>
      {nodoActual.permisos.C == 1 && (
        <Tooltip title='Agregar etiqueta'>
          <Fab
            color="primary"
            aria-label="add"
            sx={{
              position: "fixed",
              right: 10,
              bottom: 10,
              backgroundColor: "#aebd36",
            }}
            onClick={() => {
              setOpenModal(true)
            }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      )}
    </Fragment>
  );
}
