import React from "react";
import TransitionsModal from "../general-components/modal";
import ProcessTreeWithKPI from "../TreeView/ProcessTreeWithKPI";
import ProcessTree from "../TreeView/ProcessTree";
import { Button, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

const ModalNodos = ({ open, handleCloseModal, loading, onSave, isRestart }) => {
  
  const [nodo, setNodo] = React.useState(null)
  const [checked, setChecked] = React.useState(true);
  
  React.useEffect(() => {
    if (!open)
      setNodo(null)
  }, [open])

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) setNodo(null)
  };

  const handleDoubleClick = (nodo) => {
    setNodo(nodo)
  }

  const handleSave = () => {
    if (checked) return onSave(null)
    if (!nodo) return toast.error('Seleccione un proceso o actividad')
    onSave(nodo)
  }

  return (
    <TransitionsModal
      open={open}
      title={`Seleccionar Proceso / Actividad a ${isRestart ? 'reiniciar' : 'iniciar'}`}
      handleClose={handleCloseModal}
    >
      <div style={{ maxHeight: '550px', overflow: 'auto' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', margin: '10px' }}>
          <FormGroup>
            <FormControlLabel control={<Switch checked={checked} onChange={handleChange} />} label="Periodo Completo" />
          </FormGroup>
        </div>
        <section style={{ width: '100%', overflowY: 'scroll' }}>
          <ProcessTree
            fontColor={!checked ? "black" : "grey"}
            onNodeClick={!checked ? handleDoubleClick : () => { }}
            onDoubleClick={!checked ? handleDoubleClick : () => { }}
            draggable={false}
            showIndicadoresAgrupados={false}
            showAgrupaciones={false}
          />
        </section>

        <div style={{ marginTop: '1rem', float: 'right' }}>
          <Button
            variant="contained"
            color='inherit'
            style={{ marginRight: '1rem' }}
            onClick={handleCloseModal}
          >
            Cancelar
          </Button>
          <LoadingButton
            loading={loading}
            disabled={loading}
            onClick={handleSave}
            color='primary'
            variant='contained'
          >
            <span>Reiniciar</span>
          </LoadingButton>
        </div>
      </div>
    </TransitionsModal>
  )
}

export default ModalNodos;
