import React from 'react'
import style from './ChartSkeleton.module.css'

export function ChartSkeleton({ message }) {
  return (
    <div>
      <div className={`${style['chart-title']} ${style['skeleton']}` }></div>
      <div className={`${style['chart-content']} ${style['skeleton']}`}>{message || 'Realizando análisis...'}</div>
    </div>
  )
}
