import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { Checkbox, FormControlLabel } from "@mui/material";
import { postRol, putRol } from "../Api/roles.api";
import Autocomplete from "@mui/material/Autocomplete";
import { getUserList } from "../Utils/RaciRequest";
import { getPeriodos, getPeriodosPrivado } from "../Api/periodo.api";
import{evaluacionesPorPeriodo,evaluacionEquivalente} from "../Api/indicadores.api"
import{creaResultado,eliminaResultado} from "../Api/resultado.api"
import { isDate } from "moment";

function ResultadoForm({handleClose, usuarioId, indicadorId,updateResultados}) {
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [meta, setMeta] = useState(null);
  const [indicadorSeleccionadoObjeto, setIndicadorSeleccionadoObjeto] =
    useState(null);
  const [usuarioSeleccionadoObjeto, setusuarioSeleccionadoObjeto] =
    useState(null);
  const [periodoSeleccionadoObjeto, setPeriodoSeleccionadoObjeto] =
    useState(null);
  const [listaIndicadores, setListaIndicadores] = useState([]);
  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [listaPeriodos, setListaPeriodos] = useState([]);
  // const [open, setOpen] = useState(false);

  
  useEffect(() => {
    //cargarListaUsuarios();    
    cargarListaPeriodos();
  }, []);

  useEffect(()=>{
    if(periodoSeleccionadoObjeto!==null && periodoSeleccionadoObjeto.id){
       // cargarListaIndicadores(periodoSeleccionadoObjeto.id);
       obtenerEvaluacionEquivalente(periodoSeleccionadoObjeto.id)
    }else{
        setIndicadorSeleccionadoObjeto(null);
    }
  },[periodoSeleccionadoObjeto]);

  const cargarListaUsuarios = async () => {
    let res = await getUserList();
    if (Array.isArray(res)) {
      res = res.map((item) => {
        return {
          id: item.id,
          label: item.nombreCompleto,
        };
      });
      setListaUsuarios(res);
    }
  };

  const cargarListaPeriodos = async () => {
    let res = await getPeriodosPrivado(5);    
    if (Array.isArray(res.body)) {
      res = res.body;
      res = res.map((item) => {
        return {
          id: item.id,
          label: item.nombre,
        };
      });
      setListaPeriodos(res);
    }
  };

  const cargarListaIndicadores = async (periodoId) => {
    let res = await evaluacionesPorPeriodo(periodoId);    
    if (Array.isArray(res.body)) {
      res = res.body;
            setListaIndicadores(res);
    }
  };

  const obtenerEvaluacionEquivalente = async (periodoId) => {
    let res = await evaluacionEquivalente(periodoId,indicadorId);    
   if(res.status==200){
    if(Array.isArray(res.body) && res.body.length>0)
                  setIndicadorSeleccionadoObjeto(res.body[0]);
    }else{
        setIndicadorSeleccionadoObjeto(null);
    }
  };

  const save = () => {
    // valida 
    const finicio  = new Date(fechaInicio);
    if(isNaN(finicio.getTime())){
        toast.warn("La fecha de inicio no es válida");
        return;
    }
    const ffin = new Date(fechaFin)
    if(isNaN(ffin.getTime())){
        toast.warn("La fecha fin no es válida");
        return;
    }

    if(fechaInicio>fechaFin){
        toast.warn("La fecha de inicio debe ser menor que la final");
        return;
    }

    if(isNaN(meta) || meta<1){
        toast.warn("Ingresa un número en la meta");
        return;
    }

    if(indicadorSeleccionadoObjeto === null || indicadorSeleccionadoObjeto.id <1){
        toast.warn("El indicador seleccionado no se encuentra disponible");
        return;
    }

    // if(usuarioSeleccionadoObjeto === null || usuarioSeleccionadoObjeto.id <1){
    //     toast.warn("Selecciona un usuario");
    //     return;
    // }

    /*if(usuarioId === null || usuarioId <1){
        toast.warn("Hace falta determinar un usuario válido");
        return;
    }*/

    const data = {
        fechaInicio:fechaInicio,
        fechaFin:fechaFin,
        valorNumerico:null,
        meta:meta,
        evaluacionId:indicadorSeleccionadoObjeto.id,
        // usuarioId:usuarioSeleccionadoObjeto.id
        usuarioId:usuarioId
    }

    creaResultado(data).then((response) => {        
      if (response.status === 201) {
        toast.success("Resultado creado correctamente");
        updateResultados();
        handleClose();
       
      } else {
        toast.error(response.message);
      }
    });
  };

  return (
    <div style={{ maxHeight: "550px", overflow: "auto" }}>
      <Grid container spacing={3} sx={{ marginTop: "0px" }}>
        <Grid item xs={6}>
          <TextField
            id="fechaInicio"
            label="Fecha inicio"
            variant="outlined"
            value={fechaInicio}
            style={{ width: "100%" }}
            onChange={(e) => setFechaInicio(e.target.value)}
            type="date"
            InputLabelProps={{
                shrink: true,
              }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="fechaFin"
            label="Fecha fin"
            variant="outlined"
            value={fechaFin}
            style={{ width: "100%" }}
            onChange={(e) => setFechaFin(e.target.value)}
            type="date"
            InputLabelProps={{
                shrink: true,
              }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="meta"
            label="Meta"
            variant="outlined"
            value={meta}
            style={{ width: "100%" }}
            onChange={(e) => setMeta(e.target.value)}
            type="number"
          />
        </Grid>

        <Grid item xs={12} lg={12} sm={12}>
          <Autocomplete
            value={periodoSeleccionadoObjeto}
            onChange={(event, newValue) => {
              setPeriodoSeleccionadoObjeto(newValue);
            }}
            id="periodoAutocomplete"
            options={listaPeriodos}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Periodo" />}
          />
        </Grid>

        <Grid item xs={12} lg={12} sm={12}>
          {/* <Autocomplete
            value={indicadorSeleccionadoObjeto}
            onChange={(event, newValue) => {
              setIndicadorSeleccionadoObjeto(newValue);
            }}
            id="IndicadorAutocomplete"
            options={listaIndicadores}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Indicador" />
            )}
          /> */}
          {periodoSeleccionadoObjeto==null?(<h4>No ha seleccionado un período</h4>):(<></>) }
          {indicadorSeleccionadoObjeto===null && periodoSeleccionadoObjeto!==null?(<h4>Este indicador no está activo en el periodo seleccionado</h4>):(<></>)}
          {indicadorSeleccionadoObjeto!==null && periodoSeleccionadoObjeto!==null?(<h4>{indicadorSeleccionadoObjeto.nombre}</h4>):(<></>) }
        </Grid>

        <Grid item xs={12} lg={12} sm={12}>

          {/* <Autocomplete
            value={usuarioSeleccionadoObjeto}
            onChange={(event, newValue) => {
              setusuarioSeleccionadoObjeto(newValue);
            }}
            id="usuarioAutocomplete"
            options={listaUsuarios}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Usuario" />}
          /> */}
        </Grid>

        <Grid item xs={12} lg={12} sm={12}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#69bd4b",
              color: "white",
              marginRight: "20px",
            }}
            onClick={() => save()}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export { ResultadoForm };
