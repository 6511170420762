import { Chip, Paper } from '@mui/material'
import React from 'react'
import { ANALYSIS_RULES } from '../Utils/analysisRules'
import Modal from '../general-components/modal'

export function AnalisisRiesgosModal({
  modalOpen,
  setModalOpen,
  analysisData
}) {
  return (
    <Modal
      open={modalOpen}
      handleClose={() => setModalOpen(false)}
      title="Riesgos encontrados"
    >
      {analysisData && (
        <div style={{ width: '100%', maxHeight: '80vh', overflowY: 'scroll' }}>
          {Object.values(analysisData.rules).map((rule) => {
            if (rule.outsideOfLimits) {
              return (
                <Paper
                  key={rule.id}
                  elevation={3}
                  sx={{
                    padding: '10px',
                    margin: '10px 5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    borderRadius: '10px'
                  }}
                >
                  <b>{ANALYSIS_RULES[rule.id].name}</b>
                  <p>{ANALYSIS_RULES[rule.id].description}</p>
                  <div>
                    <b>Causas probables:</b>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        flexWrap: 'wrap'
                      }}
                    >
                      {ANALYSIS_RULES[rule.id].causes.map((cause, index) => (
                        <Chip label={cause} key={index} />
                      ))}
                    </div>
                  </div>
                </Paper>
              )
            }
            return null
          })}
        </div>
      )}
    </Modal>
  )
}
