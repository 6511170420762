import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import CompressIcon from "@mui/icons-material/Compress";
import ExpandIcon from "@mui/icons-material/Expand";

export function AccordionOptions({
  expandTitle,
  compressTitle,
  expandHandler,
  compressHandler,
}) {
  return (
    <div>
      <Tooltip title={expandTitle}>
        <IconButton aria-label="expand" onClick={expandHandler}>
          <ExpandIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={compressTitle}>
        <IconButton aria-label="compress" onClick={compressHandler}>
          <CompressIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}
