import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Divider, IconButton, Tooltip } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FeedIcon from "@mui/icons-material/Feed";

export function Gestion({
  gestion,
  setDeleteGestionConf,
  setGestionId,  
  puedeBorrar
}) {
  const [selected, setSelected] = React.useState(false);
  const navigate = useNavigate();

  function handleDelete(e) {
    e.stopPropagation();
    setDeleteGestionConf(true);
    setGestionId(gestion.id);
  }

  return (
    <>
    
      <section
        style={{
          padding: "10px 0",
          display: "flex",
          alignItems: "center",
          margin: "0px 3%",
          cursor: "pointer",
        }}
        onMouseOver={() => setSelected(true)}
        onMouseOut={() => setSelected(false)}
        onClick={() => window.open('https://gestionapp.launion.com.gt/gestion/'+gestion.numeroGestion, '_blank')}
      >
        <FeedIcon
          fontSize="large"
          color={`${selected ? "primary" : "inherit"}`}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            {puedeBorrar &&(
            <div>
            <Tooltip title="Eliminar">
              <IconButton onClick={handleDelete}>
                <DeleteForeverIcon color="error" />
              </IconButton>
            </Tooltip>
          </div>
            )}


            <b>{`${gestion.numeroGestion?('#' + gestion.numeroGestion):''} ${gestion.appilu.Titulo?gestion.appilu.Titulo:gestion.nombre}`}</b>
          </div>
          <small>{`Descripción: ${gestion.appilu.Descripcion?gestion.appilu.Descripcion:""}`}</small>
          <small>
            Fecha creación: {moment(gestion.fechaCreacion).format("LL")}
          </small>
          <small>
          {`Estado: ${gestion.appilu.estado?gestion.appilu.estado:""}`}
          </small>
        </div>
      </section>


      <div style={{ width: "100%", justifyContent: "center" }}>
        <Divider sx={{ width: "96%", marginLeft: "2%" }} />
      </div>
    </>
  );
}
