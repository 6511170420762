import React from "react";
import {
  deleteVista,
  putModificarOrden,
  putVista,
} from "../Api/vistaIndicador.api";
import { toast } from "react-toastify";
import { Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import { ConditionalRendering } from "../general-components/conditionalRendering";
import { NodoContext } from "../Context/nodoContext";

export function FavoritoThumbnailPlaceHolder({
  updateFavoritos,
  agrupacionId,
  setOpenVistaModal,
  setAgrupacionActualId,
  ordenDestino,
}) {
  const thRef = React.useRef(null);
  const [isDragging, setIsDragging] = React.useState(false);
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext);

  function handleDragEnter(e) {    
    e.preventDefault();
    setIsDragging(true);
  }

  function handleDragLeave(e) {    
    setIsDragging(false);
  }

  function handleOnDrop(e) {    
    e.preventDefault();
    if (isDragging) {
      setIsDragging(false);
    }
    // thRef.current.style.backgroundColor = "#f5f5f5";
    setLoading(true);
    setLoadingMessage("Guardando vista...");
    const vista = JSON.parse(e.dataTransfer.getData("vista"));
    putModificarOrden({
      vistaId: vista.id,
      ordenDestino: ordenDestino + 1,
      agrupacionDestinoId: agrupacionId,
    }).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        updateFavoritos();
      } else {
        toast.error("Error al cambiar la agrupación de la vista");
      }
    });
  }

  function handleDragOver(e) {
    if (e.preventDefault) {
      e.stopPropagation();
      e.preventDefault();
    }
    return false;
  }

  function handleCreateVista() {
    setOpenVistaModal(true);
    setAgrupacionActualId(agrupacionId);
  }

  return (
    <Paper
      sx={{
        width: "400px",
        height: "200px",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        margin: "10px",
        backgroundColor: "transparent",
        border: isDragging ? "4px dashed #e0e0e0" : "2px dashed #e0e0e0",
      }}
      elevation={3}
      ref={thRef}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDragEnter={handleDragEnter}
      onDrop={handleOnDrop}
      onClick={handleCreateVista}
    >
      <ConditionalRendering condition={!isDragging}>
        <AddIcon sx={{ fontSize: 100, color: "#e0e0e0", margin: "auto" }} />
      </ConditionalRendering>
      <ConditionalRendering condition={isDragging}>
        <div style={{ color: "#e0e0e0", margin: "auto" }}>
          <LocationSearchingIcon sx={{ fontSize: 100 }} />
          <h2 style={{ marginTop: "10px" }}>Sueltame!</h2>
        </div>
      </ConditionalRendering>
    </Paper>
  );
}
