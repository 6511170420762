import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Modal from '../general-components/modal'
import { getIndicadoresPorNodo } from '../Api/indicadores.api'
import { toast } from 'react-toastify'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { NodoContext } from '../Context/nodoContext'
import StyledTreeItem from '../TreeView/treeViewItem'
import FolderIcon from '@mui/icons-material/Folder'
import SettingsIcon from '@mui/icons-material/Settings'
import { Autocomplete, Button, TextField } from '@mui/material'
import { Calculadora } from './calculadora'
import { TreeView } from '@mui/x-tree-view';
import { SimpleTreeView } from '@mui/x-tree-view';

function ModalCalculadora({ open, setOpen, expresion, setExpresion }) {
  const [selectedNode, setSelectedNode] = React.useState(-1)
  const [expresionLocal, setExpresionLocal] = React.useState('')
  const [indicadores, setIndicadores] = React.useState([])
  const [indicador, setIndicador] = React.useState(null)
  const [options, setOptions] = React.useState([])

  const { nodos, reloadTree, estructuraArbolNodos } =React.useContext(NodoContext);

  React.useEffect(() => {
    if (estructuraArbolNodos.length > 0) return;
    reloadTree();
  }, []);

  useEffect(() => {
    setExpresionLocal(expresion)
  }, [expresion, open])

  useEffect(() => {
    setSelectedNode(-1)
    setIndicadores([])
  }, [open])

  useEffect(() => {
    setIndicador(null)
    if (selectedNode !== -1) {
      getIndicadoresPorNodo(selectedNode, false).then((response) => {
        if (response.status === 200) {
          setIndicadores(response.body)
        } else {
          toast.error('No se pudieron recuperar los indicadores')
        }
      })
    }
  }, [selectedNode])

  useEffect(() => {
    setOptions(
      indicadores.map((option) => {
        const firstLetter = option.nombre[0].toUpperCase()
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option
        }
      })
    )
  }, [indicadores])

  function agregarIndicador() {
    if (indicador !== null) {
      setExpresionLocal((expresion) => expresion + `IND_${indicador.id}`)
    }
  }

  function modificarValorYCerrarModal() {
    setExpresion(expresionLocal)
    setOpen(false)
  }
  console.log({estructuraArbolNodos})

  return (
    <Modal open={open} title="Editor de fórmulas">
      <div style={{ display: 'flex' }}>
        <Box
          sx={{
            flexGrow: 1,
            width: '35%',
            margin: '0 10px',
            backgroundColor: 'black',
            padding: '1rem',
            borderRadius: '15px'
          }}
        >
          <Calculadora
            expresion={expresionLocal}
            setExpresion={setExpresionLocal}
          />
        </Box>
        <Box
          sx={{
            width: '60%',
            margin: '0 20px'
          }}
        >
          <h2>Agregar indicador</h2>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <section
              style={{
                height: '380px',
                overflowY: 'scroll'
              }}
            >
              <SimpleTreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                selectedItems={selectedNode}
                onSelectedItemsChange={(event, nodeId) => setSelectedNode(nodeId)}
                defaultEndIcon={<div style={{ width: 24 }} />}
                sx={{
                  flexGrow: 1,
                  maxWidth: 450,
                  overflowY: 'auto',
                  marginTop: 2
                }}
              >
                {estructuraArbolNodos.map((nodo) => {
                  return (
                    <StyledTreeItem
                      nodeId={`${nodo.id}`}
                      labelText={nodo.nombre}
                      labelIcon={
                        nodo.tipoNodoId === 2 ? FolderIcon : SettingsIcon
                      }
                      items={nodo.items}
                      level={2}
                      key={nodo.id}
                      color="#000"
                      bgColor="#aebd36"
                      itemColor="black"
                    ></StyledTreeItem>
                  )
                })}
              </SimpleTreeView>
            </section>
            <section
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <Autocomplete
                id="grouped-demo"
                options={options.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                onChange={(event, newValue) => {
                  setIndicador(newValue)
                }}
                value={indicador}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.id + ' - ' + option.nombre}
                groupBy={(option) => option.firstLetter}
                renderInput={(params) => (
                  <TextField {...params} label="Indicador" />
                )}
                sx={{ width: '250px' }}
                //
              />
              <section
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  sx={{ m: 1 }}
                  onClick={agregarIndicador}
                >
                  Agregar
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  disableElevation
                  sx={{ m: 1 }}
                  onClick={() => setOpen(false)}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  disableElevation
                  sx={{ m: 1 }}
                  onClick={modificarValorYCerrarModal}
                >
                  Guardar
                </Button>
              </section>
            </section>
          </div>
        </Box>
      </div>
    </Modal>
  )
}

export { ModalCalculadora }
