import { HOST } from "../Utils/config";
import { getRequest, postRequest, putRequest, deleteRequest } from "./requests";

async function getObjetivos() {
  return await getRequest(`${HOST}/api/v1/objetivoPilar`);
}

async function postObjetivo(data) {
  return await postRequest(`${HOST}/api/v1/objetivoPilar`, data);
}

async function putObjetivo(data) {
  return await putRequest(`${HOST}/api/v1/objetivoPilar`, data);
}

async function deleteObjetivo(data) {
  return await deleteRequest(`${HOST}/api/v1/objetivoPilar`, data);
}

export { getObjetivos, postObjetivo, putObjetivo, deleteObjetivo };