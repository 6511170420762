import React from "react";
import { Box, Card, CardContent, Chip, Tooltip, IconButton, Grid, Typography, Modal, Link } from "@mui/material";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterPanel,
  SearchPanel,
  FilterRow,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Selection,
  Summary,
  TotalItem,
  StateStoring,
  Export,
} from "devextreme-react/data-grid";
import CloudIcon from '@mui/icons-material/Cloud';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import EstadosEvaluacion from "../Custom/estadosEvaluacion";
import moment from "moment";
import { ModalArchivos } from "./ModalArchivos";

const toolbarColumn = (row, setArchivos, setFilesModalOpen) => {
  const { data } = row
  const { evidencias } = data || {}
  const { faltantes, adjuntos } = evidencias || {}
  const done = faltantes?.length === 0
  return (
    <Tooltip
      title={
        <React.Fragment>
          <Typography color="inherit">{adjuntos?.length} evidencia(s)</Typography>
          {!done &&
            <React.Fragment>
              <Typography color="inherit">Faltan {faltantes?.length}:</Typography>
              <ul>
                {faltantes?.map((item, index) => {
                  return <li>
                    {moment(item).utc().format("D MMMM YYYY, h:mm:ss a")}
                  </li>
                })}
              </ul>
            </React.Fragment>
          }
        </React.Fragment>
      }
      placement="right"
    >
      <IconButton
        color='default'
        size="small"
        onClick={adjuntos?.length > 0 ?
          () => {
            setArchivos(adjuntos || [])
            setFilesModalOpen(true)
          } : () => { }
        }
      >
        {done ? <CloudDoneIcon fontSize="small" color="success" /> : <CloudIcon fontSize="small" />}
      </IconButton>
    </Tooltip>
  )
}

const Indicadores = ({
  data
}) => {

  const [dataSource, setDataSource] = React.useState([])
  const [estados, setEstados] = React.useState({})
  const [filesModalOpen, setFilesModalOpen] = React.useState(false)
  const [archivos, setArchivos] = React.useState([])

  React.useEffect(() => {
    const json = JSON.parse(data?.json || '{}')
    const _data = json?.indicadores || []
    _data.forEach((item, index) => {
      item.usuarioNombreCompleto = data?.usuarioNombreCompleto
    })
    setDataSource(_data)
    setEstados(json?.estados || {})
  }, [data])

  return (
    <Box sx={{ flexGrow: 1, alignItems: 'left', justifyContent: 'left', textAlign: 'left' }}>
      {/* Header */}
      <Grid container sx={{ paddingX: { xs: '0.5rem', md: '1rem' } }}>
        {/* Contenedor cards puntuacion total y total indicadores */}
        <Grid container xs={12} sm={12} md={12} lg={6}>
          {/* Card puntuación total */}
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ paddingRight: { md: 1 } }} >
            <Card sx={{ display: 'flex', justifyContent: 'left' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <p>PUNTUACIÓN TOTAL</p>
                  <span style={{ fontSize: '25px', fontWeight: 'bold' }}>{parseFloat(data?.resultadoIndicadores || 0 + '').toFixed(2)}%</span>
                </CardContent>
              </Box>
              {/* <CardContent sx={{ display: 'flex' }}>
                                <div style={{ alignSelf: 'flex-end' }}>
                                    <Chip label={`Meta: ${90}`} />
                                </div>
                            </CardContent> */}
            </Card>
          </Grid>
          {/* Card total indicadores */}
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ marginTop: { xs: 1, md: 0 }, paddingLeft: { md: 1 } }}>
            <Card sx={{ display: 'flex', justifyContent: 'left' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <p>TOTAL INDICADORES</p>
                  <span style={{ fontSize: '25px', fontWeight: 'bold' }}>{dataSource?.length || 0}</span>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        </Grid>
        {/* Contenedor historial de pasos (stepper) */}
        <Grid container xs={12} sm={12} md={12} lg={6} sx={{ paddingX: { md: 2 } }}>
          <Grid item xs sx={{ marginBottom: '1rem' }}>
            <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: 1 }}>Etapa:</Typography>
            <EstadosEvaluacion estados={estados} />
          </Grid>
        </Grid>
      </Grid>
      {/* Tabla de indicadores */}
      <Grid container sx={{ width: '100%', height: 'auto', overflow: 'auto', paddingX: { xs: '0.5rem', md: '1rem' } }}>
        <DataGrid
          dataSource={dataSource}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnResizingMode="nextColumn"
        >
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey={'desempenoGrid'}
          />
          <GroupPanel visible={true} />
          <ColumnChooser enabled={true} />
          <Column
            dataField="id"
            caption="Evidencias"
            allowFiltering={false}
            allowSorting={false}
            cellRender={(row) => toolbarColumn(row, setArchivos, setFilesModalOpen)}
            width={'auto'}
            allowEditing={false}
            alignment={'center'}
          />
          <Column
            dataField="indicador"
            caption="Indicador"
            width={'auto'}
            filt
            cellRender={({ data }) => {
              return (
                <Link
                  component="button"
                  onClick={() => window.open(`/home/procesos/${data?.nodoId}/indicadores?indicadorId=${data?.indicadorId}`, '_blank')}
                  style={{ color: 'black' }}
                >
                  {data?.indicador}
                </Link>
              )
            }}
          />
          <Column
            dataField="nodo"
            caption="Proceso/Actividad"
          />
          <Column
            dataField="tiporaci"
            caption="Responsabilidad"
          />
          <Column
            dataField="frecuencia"
            caption="Frecuencia"
          />
          <Column
            dataField="objetivoDesempeno"
            caption="Objetivo"
          />
          <Column
            dataField="meta"
            caption="Meta"
            customizeText={({ value }) => (value !== null && value !== undefined) ? parseFloat(value + '').toFixed(2) : null}
          />
          <Column
            dataField="resultado"
            caption="Resultado"
            customizeText={({ value }) => (value !== null && value !== undefined) ? parseFloat(value + '').toFixed(2) : null}
          />
          <Column
            dataField="resultadoPorcentual"
            caption="Resultado Porcentual"
            customizeText={({ value }) => (value !== null && value !== undefined) ? parseFloat(value + '').toFixed(2) : null}
          />
          <Column
            dataField="peso"
            caption="Peso"
          />
          <Column
            dataField="resultadoPonderado"
            caption="Resultado Obtenido"
            customizeText={({ value }) => (value !== null && value !== undefined) ? parseFloat(value + '').toFixed(2) : null}
          />
          <FilterRow visible={true} />
          <FilterPanel visible={false} />
          <HeaderFilter visible={true} />
          <SearchPanel visible={true} />
          <Paging defaultPageSize={50} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[50, 100, 150, 200]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Summary>
            <TotalItem
              column="peso"
              summaryType="sum"
              valueFormat="number"
              customizeText={(data) => `${data?.value?.toFixed(2)}%`}
            />
            <TotalItem
              column="resultadoPonderado"
              summaryType="sum"
              valueFormat="number"
              customizeText={(data) => `${data?.value?.toFixed(2)}%`}
            />
          </Summary>
        </DataGrid>
      </Grid>
      <ModalArchivos
        open={filesModalOpen}
        setOpen={setFilesModalOpen}
        archivos={archivos}
      />
    </Box>
  )
}

export default Indicadores