import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { getAgrupacion } from '../Api/agrupaciones.api'

export function useIndicadorAgrupado({indicadorId, agrupado}) {
    const [indicador, setIndicador] = useState({})

    const updateIndicador = async () => {
        if (!indicadorId || !agrupado) return
        const response = await getAgrupacion({idAgrupacion: indicadorId})
        if (response.status === 200) {
            setIndicador(response.body)
        }
    }

    useEffect(() => {   
        updateIndicador()
    }
    , [indicadorId])

    return { indicador, updateIndicador }
}
