import React, { useRef } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { Divider, IconButton, Tooltip, Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import Confirm from "../general-components/confirm";
import { putPeriodo, iniciarPeriodo, reiniciarPeriodo, establecerCargaAutomatica, toogleHabilitarPeriodo, reiniciarActividadPeriodo } from "../Api/periodo.api";
import { PeriodoContext } from "../Context/periodoContext";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { NodoContext } from "../Context/nodoContext";
import InsightsIcon from '@mui/icons-material/Insights';
import ModalNodos from "./modalNodos";

function Periodo({ periodo, setModalOpen, setPeriodoActivo, updatePeriodos }) {
  const [selected, setSelected] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openRestartConfirm, setOpenRestartConfirm] = React.useState(false);
  const [openActivarCargaConfirm, setOpenActivarCargaConfirm] = React.useState(false);
  const [openRecalculoConfirm, setOpenRecalculoConfirm] = React.useState(false);
  const { reloadPeriodos } = React.useContext(PeriodoContext)
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext);
  const buttonRef = useRef();
  const navigate = useNavigate();

  let enableButton = true;

  function removeIndicador() {
    putPeriodo({ id: periodo.id, eliminado: true }).then(
      (response) => {
        if (response.status === 200) {
          toast.success("Periodo eliminado exitosamente!");
          setPeriodoActivo(-1);
          setOpenConfirm(false);
          updatePeriodos();
        } else {
          toast.error(response.message);
        }
      }
    );
  }

  const startAndDisablePlay = (event) => {
    try {
      if (enableButton) {
        enableButton = false;
        buttonRef.current.display = "none";
        setTimeout(() => { startPeriodo() }, 1000);
      }

    } catch (e) {
      enableButton = true;
    }
  }

  const startPeriodo = async() => {
    try {
      setLoading(true);
      setLoadingMessage("Iniciando periodo...");
      const response = await iniciarPeriodo({ id: periodo.id });
      if (response.status === 200 || response.status === 201) {
        updatePeriodos();
        reloadPeriodos();
        toast.success("Periodo iniciado exitosamente!");
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const reiniciarPeriodoNodo = (nodo) => {
    if (nodo === null) {
      restartPeriodo();
    } else {
      restartActividad(nodo);
    }
  }

  const restartPeriodo = () => {
    setLoading(true);
    setLoadingMessage("Reiniciando periodo...");
    try {
      reiniciarPeriodo({ id: periodo.id })
      
      // Esperar 5 segundos para que el servidor termine de reiniciar el periodo
      setTimeout(() => {
        updatePeriodos();
        reloadPeriodos();
        toast.info("Reinicio en proceso. Este proceso puede tardar unos minutos.");
        setLoading(false);
        setOpenRestartConfirm(false);
      }, 5000);
    } catch (e) {
    }
  }

  async function restartActividad(nodo) {
    setLoading(true);
    setLoadingMessage("Reiniciando actividad...");
    try {
      const resp = await reiniciarActividadPeriodo(
        nodo.id,
        periodo.id
      );
      if (resp.status === 200) {
        toast.success("Actividad reiniciada con éxito");
        setOpenRestartConfirm(false);
      } else {
        toast.error("Error reiniciando actividad...");
      }
    } catch (e) {
      toast.error("Error reiniciando actividad...");
    } finally {
      setLoading(false);
    }
  }

  const activarCargaPeriodo = () => {
    setLoading(true);
    setLoadingMessage("Activando carga automática...");
    establecerCargaAutomatica(periodo.id).then((response) => {
      if (response.status === 200) {
        toast.success("Periodo activado para carga automática!");
        setOpenActivarCargaConfirm(false);
        setLoading(false);
        updatePeriodos();
        reloadPeriodos();
      } else {
        toast.error(response.message);
      }
    });
    setLoading(false);
  }

  const toogleRecalculoHabilitar = () => {
    setLoading(true);
    toogleHabilitarPeriodo(periodo.id).then((response) => {
      if (response.status === 200) {
        // toast.success("Periodo activado para carga automática!");
        setOpenRecalculoConfirm(false);
        setLoading(false);
        updatePeriodos();
        reloadPeriodos();
      } else {
        toast.error(response.message);
      }
    });
    setLoading(false);
  }

  return (
    <>
      <section
        style={{
          padding: "10px 0",
          display: "flex",
          alignItems: "center",
          margin: "0px 3%",
        }}
      >
        <CalendarMonthIcon
          fontSize="large"
          color={`${selected ? "primary" : "inherit"}`}
          //onClick={() => navigate(`${periodo.id}`)}
          style={{ cursor: "pointer" }}
          onMouseOver={() => setSelected(true)}
          onMouseOut={() => setSelected(false)}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {periodo.permiteCargaAutomatica ? (
                <Tooltip title="Carga automática activada">
                  <InsightsIcon color="success" />
                </Tooltip>
              ) : (
                <Tooltip title="Activar carga automática">
                  <IconButton onClick={() => setOpenActivarCargaConfirm(true)}>
                    <InsightsIcon color="disabled" />
                  </IconButton>
                </Tooltip>
              )}
              {periodo.estadoPeriodoId === 1 ? (
                <div>
                  <Tooltip title="Iniciar">
                    <IconButton onClick={startAndDisablePlay} ref={buttonRef} >
                      <PlayCircleIcon sx={{ color: "#aebd36" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Editar">
                    <IconButton
                      onClick={() => {
                        setModalOpen(true);
                        setPeriodoActivo(periodo);
                      }}
                    >
                      <EditIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Eliminar">
                    <IconButton onClick={() => setOpenConfirm(true)}>
                      <DeleteForeverIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </div>
              ) : (
                periodo.recalculoHabilitado && (
                  <React.Fragment>
                    <Tooltip title="Reiniciar periodo con nuevos integrantes de matriz RACI">
                      <IconButton
                        onClick={() =>
                          setOpenRestartConfirm(true)
                        }
                      >
                        <PublishedWithChangesIcon color="warning" />
                      </IconButton>
                    </Tooltip>
                  </React.Fragment>
                )
              )}
              {periodo.estadoPeriodoId !== 1 && (
                <Tooltip title={`${periodo.recalculoHabilitado ? 'Deshabilitar' : 'Habilitar'} recálculo`}>
                  <Switch
                    checked={periodo.recalculoHabilitado}
                    onChange={() => {
                      setOpenRecalculoConfirm(true);
                    }}
                  />
                </Tooltip>
              )}
            </div>
            <b>{periodo.nombre}</b>
          </div>
          <b style={{ textAlign: "left", fontSize: "14px" }}>
            Periodo Completo:&nbsp;
            {moment(periodo.fechaInicio).format("LL")}&nbsp;-&nbsp;
            {moment(periodo.fechaFin).format("LL")}
          </b>
          <p style={{ textAlign: "left", fontSize: "14px" }}>
            Periodo Zafra:&nbsp;
            {periodo.fechaSubInicio ? moment(periodo.fechaSubInicio).format("LL") : ""}&nbsp;-&nbsp;
            {periodo.fechaSubFin ? moment(periodo.fechaSubFin).format("LL") : ""}
          </p>
          <p style={{ textAlign: "left", margin: 2 }}>{periodo.descripcion}</p>
        </div>
      </section>
      <div style={{ width: "100%", justifyContent: "center" }}>
        <Divider sx={{ width: "96%", marginLeft: "2%" }} />
      </div>
      <Confirm
        open={openConfirm}
        title={"Eliminar"}
        text={"¿Confirma que desea eliminar este elemento?"}
        onConfirm={removeIndicador}
        handleCloseProp={() => setOpenConfirm(false)}
      />
      <ModalNodos
        open={openRestartConfirm}
        handleCloseModal={() => setOpenRestartConfirm(false)}
        loading={false}
        isRestart={true}
        onSave={reiniciarPeriodoNodo}
      />
      <Confirm
        open={openActivarCargaConfirm}
        title={"Activar carga para este periodo"}
        text={"¿Confirma que desea reiniciar el periodo?. Solamente puede existir un periodo con carga automática de indicadores, el periodo que se encontraba activo se desactivará."}
        onConfirm={activarCargaPeriodo}
        handleCloseProp={() => setOpenActivarCargaConfirm(false)}
      />
      <Confirm
        open={openRecalculoConfirm}
        title={periodo.recalculoHabilitado ? 'Deshabilitar' : 'Habilitar'}
        text={`¿Confirma que desea ${periodo.recalculoHabilitado ? 'deshabilitar' : 'habilitar'} recálculo?`}
        onConfirm={toogleRecalculoHabilitar}
        handleCloseProp={() => setOpenRecalculoConfirm(false)}
      />
    </>
  );
}

export { Periodo };
