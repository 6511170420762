import { Button } from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import TagsModal from "../../general-components/tags-modal";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import IconButton from "@mui/material/IconButton";
import { useAuth } from "../../Hooks/useAuth";
import { NodoContext } from "../../Context/nodoContext";
import { Fab } from "@mui/material";
import {
 addDocument,
 getNodeDocuments,
 removeDocument
} from "../../Utils/documentosRequest";
import ItemMember from "./item-member";
import { nodeType } from "../../Utils/node-types";
import Modal from "../../general-components/modal";
import TextField from "@mui/material/TextField";
import { Chip } from "@mui/material";

export default function Documents() {
  const [itemList, setItemList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const { getUser } = useAuth();
  const { nodoActual } = React.useContext(NodoContext);
  const [nombre,setNombre] = useState("");
  const [descripcion,setDescripcion] = useState("");
  const [url,setUrl] = useState("");
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    getDocuments();
  }, []);

  useEffect(() => {
    getDocuments();
  }, [nodoActual]);

  const handleClose = () => {
    setOpenModal(false);
  };

  const addItem = async (item) => {
    setDisabled(true);
    try {
     setDisabled(true);
      
      let data = {
        nombre: nombre,
        descripcion:descripcion,
        url:url,
        nodoId: nodoActual.id,
        
      };
      
      const res = await addDocument(data);
      if (res.status == 201) {
        getDocuments();
        handleClose();
      }
      
    } catch (error) {
      console.error(error);
    }finally{
        setDisabled(false);
    }
  };


  const getDocuments = async () => {
    try {
        const nodoId = nodoActual.id;        
      const data = await getNodeDocuments(nodoId);
      setItemList(data);
    } catch (error) {}
  };

  const removerItem = async (item) => {    
    const data = { id: item.id };
    const res = await removeDocument(data);
    getDocuments();
    }
  

  return (
    <Fragment>
      
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className="panelTitle">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {" "}
              {/* <IconButton
                aria-label="delete"
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                <AddIcon />
              </IconButton> */}
            </div>
            <div>{/* <h4>Etiquetas asignadas</h4> */}</div>
          </div>
          <div>
            {itemList.map((item, i) => (
                nodoActual.permisos.R==1&&(
              <ItemMember
                key={`tagItem${item.id}`}
                item={item}
                onConfirm={removerItem}
                permisos={nodoActual.permisos}
              />
              )
            ))}
          </div>
        </Grid>
      </Grid>
    
        {nodoActual.tipoNodoId===3 && nodoActual.permisos.C==1 &&(
      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          right: 10,
          bottom: 10,
          backgroundColor: "#aebd36",
        }}
        onClick={() =>  {setOpenModal(true); setNombre(""); setDescripcion(""); setUrl("")}}
      >
        <AddIcon />
      </Fab>
        )}

      <Modal title="Nuevo documento" open={openModal} handleClose={handleClose}>
      <div style={{ maxHeight: "550px", overflow: "auto" }}>
      <Grid container spacing={3} sx={{ marginTop: "0px" }}>
        <Grid item xs={12}>
          <TextField
            id="nombre"
            label="Nombre"
            variant="outlined"
            value={nombre}
            style={{ width: "100%" }}
            onChange={(e) => setNombre(e.target.value)}
          />
        </Grid>
        
        
        <Grid item xs={12}>
          <TextField
            id="descripcion"
            label="Descripción"
            variant="outlined"
            value={descripcion}
            multiline
            style={{ width: "100%" }}
            onChange={(e) => setDescripcion(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="URL"
            label="URL"
            variant="outlined"
            value={url}
            style={{ width: "100%" }}
            onChange={(e) => setUrl(e.target.value)}
          />
        </Grid>

        
        <Grid item xs={12} lg={12} sm={12}>
          <Button
            variant="contained"
            style={{ backgroundColor: "#69bd4b", color: "white" }}
            disabled = {disabled}
            onClick={addItem}
          >
            Guardar
          </Button>
        </Grid>
       
      </Grid>

     </div>
      </Modal>
    </Fragment>
  );
}
