import { HOST } from "../Utils/config";
import { getRequest, postRequest, putRequest, deleteRequest } from "./requests";

async function getRoles() {
  return await getRequest(`${HOST}/api/v1/rol`);
}

async function getRolesPrivado(idMenu) {
  return await getRequest(`${HOST}/api/v1/rol/private?idMenu=${idMenu}`);
}

async function getRol(id) {
  return await getRequest(`${HOST}/api/v1/rol/${id}`);
}

async function getUsuariosAsignadosARol(id) {
  return await getRequest(`${HOST}/api/v1/rol/usuariosAsignados/${id}`);
}

async function getUsuariosNoAsignadosARol(id) {
  return await getRequest(`${HOST}/api/v1/rol/usuariosNoAsignados/${id}`);
}

async function asignarUsuarios(data) {
  return await postRequest(`${HOST}/api/v1/rolUsuario/`, data);
}

async function desasignarUsuarios(data) {
  return await putRequest(`${HOST}/api/v1/rolUsuario/`, data);
}

async function postRol(data) {
  return await postRequest(`${HOST}/api/v1/rol`, data);
}

async function putRol(data) {
  return await putRequest(`${HOST}/api/v1/rol`, data);
}

async function deleteRol(id) {
  return await deleteRequest(`${HOST}/api/v1/rol/${id}`);
}

export {
  getRoles,
  getRolesPrivado,
  getRol,
  asignarUsuarios,
  postRol,
  putRol,
  deleteRol,
  getUsuariosAsignadosARol,
  getUsuariosNoAsignadosARol,
  desasignarUsuarios
};
