import React from 'react';
import {
    Box,
    Grid,
    Avatar,
} from '@mui/material';
import ProgressBar from "@ramonak/react-progress-bar";
import { getNameInitials } from '../Utils/utilities';
import {
    PolarChart,
    CommonSeriesSettings,
    Series,
    Margin,
    ValueAxis,
    VisualRange,
} from 'devextreme-react/polar-chart';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const excludedCategoriaKeys = [
    'descripcion',
    'promedio',
]

const spiderColors = [
    '#1f77b4',
    '#2ca02c',
    '#d62728',
    '#ff7f0e',
    '#9467bd',
]

const Competencias = ({
    data
}) => {

    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const md = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const lg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const xl = useMediaQuery(theme.breakpoints.up('lg'));

    console.log({ sm, md, lg, xl })

    const [competencias, setCompetencias] = React.useState({})
    const [spiderDataSource, setSpiderDataSource] = React.useState([])
    const [tiposEvaluacion, setTiposEvaluacion] = React.useState([])

    React.useEffect(() => {
        const json = JSON.parse(data?.json || '{}')
        setCompetencias(json?.competencias || {})
        setSpiderDataSource(Object.keys(json?.competencias || {}).map((categoria, index) => {
            const data = {
                arg: categoria,
            }
            Object.keys(json?.competencias[categoria] || {}).filter(key => !excludedCategoriaKeys.includes(key)).forEach((tipoEvaluacion, index) => {
                data[tipoEvaluacion] = json?.competencias[categoria][tipoEvaluacion]?.competencias?.promedio || 0
                if (!tiposEvaluacion.includes(tipoEvaluacion)) {
                    setTiposEvaluacion([...tiposEvaluacion, tipoEvaluacion])
                }
            })
            return data
        }))
    }, [data])

    return (
        <Box sx={{ flexGrow: 1 }}>
            {Object.keys(competencias).length > 0 ? (
                <Grid container spacing={2} style={{ height: lg || xl ? 'calc(100vh - 240px)' : 'auto', overflow: lg || xl ? 'hidden' : 'auto' }}>
                    <Grid item sm={12} lg={7} style={{ height: '100%', overflow: 'auto' }}>
                        {Object.keys(competencias).map((categoria, index) => {
                            const competencia = competencias[categoria]
                            return (
                                <Grid container spacing={2} paddingY={2}>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            {/* Información de la categoría */}
                                            <Grid item sm={12} md={6} paddingX={3}>
                                                <h2 style={{ textAlign: 'left' }}>
                                                    {categoria}
                                                </h2>
                                                <div style={{ padding: '1em 0em' }}>
                                                    <ProgressBar
                                                        maxCompleted={99.99}
                                                        completed={(competencia?.promedio || 0) * 33.33}
                                                        customLabel={competencia?.promedio || 0}
                                                        bgColor="#2fb559"
                                                    />
                                                </div>
                                                <p style={{ textAlign: 'justify' }}>
                                                    {competencia?.descripcion}
                                                </p>
                                            </Grid>
                                            {/* Evaluaciones */}
                                            <Grid item sm={12} md={6} paddingX={3}>
                                                {/* Header */}
                                                <Grid container xs={12} paddingX={3}>
                                                    <Grid item xs={4}>
                                                        <h3 style={{ textAlign: 'left' }}>
                                                            EVALUACIÓN
                                                        </h3>
                                                    </Grid>
                                                    <Grid item xs={2} />
                                                    <Grid item xs={2}>
                                                        <h3 style={{ textAlign: 'center' }}>
                                                            PROM
                                                        </h3>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <h3 style={{ textAlign: 'center' }}>
                                                            MIN
                                                        </h3>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <h3 style={{ textAlign: 'center' }}>
                                                            MAX
                                                        </h3>
                                                    </Grid>
                                                </Grid>
                                                {/* Resultados de tipos de evaluación */}
                                                {Object.keys(competencia).filter(key => !excludedCategoriaKeys.includes(key)).map((tipoEvaluacion, index1) => {
                                                    const evaluacion = competencia[tipoEvaluacion]?.competencias || {}
                                                    return (
                                                        <Grid container xs={12} padding={3}>
                                                            <Grid item xs={4} sx={{ display: 'flex' }}>
                                                                <Avatar sx={{ bgcolor: spiderColors[index1], width: 24, height: 24 }}>
                                                                    <span style={{ fontSize: 15 }}>
                                                                        {getNameInitials((tipoEvaluacion || '').toUpperCase())}
                                                                    </span>
                                                                </Avatar>
                                                                <h4 style={{ textAlign: 'left', fontWeight: 'normal', display: 'inline-block', alignSelf: 'flex-start', padding: '0em 0.5em' }}>
                                                                    {tipoEvaluacion}
                                                                </h4>
                                                            </Grid>
                                                            <Grid item xs={2} />
                                                            <Grid item xs={2}>
                                                                <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>
                                                                    {evaluacion?.promedio || 0}
                                                                </h4>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>
                                                                    {evaluacion?.min || 0}
                                                                </h4>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <h4 style={{ textAlign: 'center', fontWeight: 'normal' }}>
                                                                    {evaluacion?.max || 0}
                                                                </h4>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Grid item sm={12} lg={5}>
                        <div id="evaluacionSpiderChartContainer">
                            <PolarChart
                                id="evaluacionSpiderChart"
                                dataSource={spiderDataSource}
                                useSpiderWeb={true}
                            >
                                <CommonSeriesSettings type='area' />
                                {tiposEvaluacion.map((tipo, index) => {
                                    return (
                                        <Series
                                            valueField={tipo}
                                            name={tipo}
                                            color={spiderColors[index]}
                                        />
                                    )
                                })}
                                <Margin
                                    left={50}
                                />
                                <ValueAxis>
                                    <VisualRange startValue={0} endValue={3} />
                                </ValueAxis>
                            </PolarChart>
                        </div>
                    </Grid>
                </Grid>
            ) : (
                <h3 style={{ color: 'GrayText' }}>
                    Aún no hay datos de evaluación
                </h3>
            )}
        </Box>
    );
}

export default Competencias;