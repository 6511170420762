import { HOST } from "../Utils/config";
import { getRequest } from "./requests";

async function getEstructuraSubordinados(DPISeleccionado) {
  return await getRequest(
    `${HOST}/api/v1/estructurapuestos?DPI=${DPISeleccionado}`
  );
}

export { getEstructuraSubordinados };
