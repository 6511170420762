import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getResultadosGraficas } from "../../Api/resultado.api";
import { PeriodoContext } from "../../Context/periodoContext";
import { IconButton, Tooltip } from "@mui/material";
import { getTiposRaci } from "../../Api/tipoRaci.api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { NodoContext } from "../../Context/nodoContext";
import { AuthContext } from "../../Context/authContext";
import { DEFAULT_DELAY } from "../../Utils/config";
import { CustomizedGraph } from "../../Chart/graph";
import ZoomDialog from "../../Chart/zoomDialog";

function GraficasDashboard() {
  const { raciIdParam } = useParams();
  const [raciId, setRaciId] = React.useState(-1);
  const { fireReload } = React.useContext(PeriodoContext);
  const [leyendas, setLeyendas] = React.useState([]);
  const [indicadores, setIndicadores] = React.useState([]);
  const [tiposRaci, setTiposRaci] = React.useState([]);
  const [avisoZoom, setAvisoZoom] = React.useState(false);

  const { selectedUser, getSelectedUserID } = React.useContext(AuthContext);
  const { loading, setLoading, setLoadingMessage } =
    React.useContext(NodoContext);
  const navigate = useNavigate();

  useEffect(() => {
    updateTiposRaci();
    setRaciId(parseInt(raciIdParam));

    const isFirstVisit = !localStorage.getItem('avisoZoom');
    if (isFirstVisit) {
      setTimeout(() => {
        setAvisoZoom(true);
      }, 1000);
      localStorage.setItem('avisoZoom', 'true');
    }
  }, []);

  useEffect(() => {
    if (raciId !== -1) {
      updateResultados();
    }
  }, [raciId, fireReload, selectedUser]);

  function updateTiposRaci() {
    getTiposRaci().then((response) => {
      if (response.status === 200) {
        setTiposRaci(response.body);
      } else {
        toast.error(response.message);
      }
    });
  }

  function updateResultados() {
    setLoading(true);
    setLoadingMessage("Cargando..");
    getResultadosGraficas(raciId, getSelectedUserID()).then((response) => {
      if (response.status === 200) {
        obtenerLeyendas(response.body.periodos);
        setIndicadores(response.body.indicadores);        
      } else {
        toast.error("No se pudieron recuperar los resultados");
      }
      setLoading(false);
    });
  }

  function obtenerLeyendas(periodos) {
    setLeyendas(
      periodos.map((periodo) => {
        return { value: periodo.nombre, name: periodo.nombre };
      })
    );
  }

  const moveToNextRaciType = () => {
    const siguienteTipoRaci = tiposRaci.find(
      (tipoRaci) => tipoRaci.id === raciId + 1
    );
    if (siguienteTipoRaci) {
      setRaciId(siguienteTipoRaci.id);
    } else {
      setRaciId(tiposRaci[0].id);
    }
  };

  const moveToPrevRaciType = () => {
    const siguienteTipoRaci = tiposRaci.find(
      (tipoRaci) => tipoRaci.id === raciId - 1
    );
    if (siguienteTipoRaci) {
      setRaciId(siguienteTipoRaci.id);
    } else {
      setRaciId(tiposRaci[tiposRaci.length - 1].id);
    }
  };

  function getNombreRaci() {
    if (tiposRaci.length > 0) {
      const raciActual = tiposRaci.find((tipoRaci) => tipoRaci.id === raciId);
      return raciActual.nombre;
    }
    return "";
  }
  if (loading) {
    return <div></div>;
  }
  return (
    <div style={{ height: "100%" }} data-testid="graficasDashboard">
      <ZoomDialog open={avisoZoom} onClose={() => setAvisoZoom(false)} />

      <section
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 10px",
        }}        
      >
        <div>
          <Tooltip
            title="Regresar al resumen de indicadores"
            enterDelay={DEFAULT_DELAY}
          >
            <IconButton onClick={() => navigate("/home")}>
              <ArrowBackIcon color="primary" />
            </IconButton>
          </Tooltip>
        </div>
        <h1 style={{ textAlign: "center" }}> Indicadores Asignados: </h1>
        <div>{/* Div Necesario para mantener el formato*/}</div>
      </section>
      <section
        style={{
          display: "flex",
          margin: "0px 25px",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="Visualizar rol anterior" enterDelay={DEFAULT_DELAY}>
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={moveToPrevRaciType}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <span style={{ width: "220px", backgroundColor: "#ffffff" }}>
            <b>{getNombreRaci()}</b>
          </span>
          <Tooltip title="Visualizar siguiente rol" enterDelay={DEFAULT_DELAY}>
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={moveToNextRaciType}
            >
              <ArrowForwardIcon />
            </IconButton>
          </Tooltip>
        </div>
      </section>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {indicadores.map((indicador, index) => {
          return (
            <div key={index} style={{ display: 'flex' }}>
              <CustomizedGraph
                titulos={leyendas}
                indicadorId={indicador.id}
                resultados={indicador.resultados}
                etiquetaEjeX={indicador.etiquetaEjeX}
                graficarEjeXDiscreto={indicador.graficarEjeXDiscreto}
                formatoFecha={indicador.formatoFecha}
                titulo={indicador.nombre}
                operacionIntegracion={indicador.operacionIntegracion}
                metaInversa={indicador.metaInversa}
                onDoubleClick={() =>
                  navigate(
                    `/home/resultadosIndividuales?raciId=${raciId}&indicadorId=${indicador.id}`
                  )
                }
                maxWidth={
                  indicadores.length === 1
                    ? window.innerWidth - 20
                    : (window.innerWidth - 60) / 2
                }
                minWidth={
                  indicadores.length === 1
                    ? window.innerWidth - 320
                    : (window.innerWidth - 360) / 2
                }
                height={
                  indicadores.length === 1 ? window.innerHeight - 200 : 500
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export { GraficasDashboard };
