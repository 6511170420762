import { Chip } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {getNodosDisponibles,getNodosExistentes,agregarNodo,removerNodo} from "../../../Api/valueMap.api";

import React, { useEffect } from "react"
import { toast } from "react-toastify";
const NodosValueMap =  ({isOpen, valueMapId,recargarDiagrama})=>{

    const[nodosExistentes,setNodosExistentes] = React.useState([]);
    const[nodosDisponibles,setNodosDisponibles] = React.useState([]);

    useEffect(()=>{
        if(isOpen){
            cargarNodosDisponibles();
            cargarNodosExistentes();
        }
    }, [isOpen])

    const cargarNodosDisponibles = async()=>{
        console.log("ValueMapID",valueMapId)
        const req = await getNodosDisponibles(valueMapId);
        if(req.status==200){
            setNodosDisponibles(req.body);
        }
    }

    const cargarNodosExistentes = async()=>{
        const req = await getNodosExistentes(valueMapId);
        if(req.status==200){
            setNodosExistentes(req.body);
        }
    }

    const handleAgregarNodo = async(nodoId)=>{
        const obj =  {valueMapId, nodoId}
        const req =  await agregarNodo(obj);
        if(req.status===201){
            toast.success("La actividad se agregó exitosamente");
            cargarNodosExistentes();
            cargarNodosDisponibles();
            recargarDiagrama();
        }
    }

    const handleRemoverNodo = async(nodoId)=>{
        const obj =  {valueMapId, nodoId}
        console.log("valueMapId", valueMapId)
        const req =  await removerNodo(obj);
        if(req.status===200){
            toast.success("La actividad se removió exitosamente");
            cargarNodosExistentes();
            cargarNodosDisponibles();
            recargarDiagrama();
        }
    }
    
    return(
        <>
        <div style={{marginTop:10}}>
            <h4>Agregados</h4>
        </div>
            {nodosExistentes.map((nodo)=>(
                   <Chip
                   label={nodo.nombre}
                   onClick={()=>{}}
                   onDelete={()=>{handleRemoverNodo(nodo.id)}}
                   deleteIcon={<DeleteIcon />}
                   variant="outlined"
                   color="success"
                   style={{marginLeft:5}}
                 />
            ))}

            <div style={{marginTop:15}}>
                <h4>Disponibles para agregar</h4>
                <div>
                {nodosDisponibles.map((nodo)=>(
                   <Chip
                   label={nodo.nombre}
                   onClick={()=>{}}
                   onDelete={()=>{handleAgregarNodo(nodo.id)}}
                   deleteIcon={<AddIcon />}
                   variant="outlined"
                   color="primary"
                   style={{marginLeft:5}}
                 />
            ))}
                </div>
            </div>
        </>
    )
}

export{NodosValueMap}