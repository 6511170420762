import React from 'react'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { DataGrid } from 'devextreme-react'
import {
  Column,
  ColumnChooser,
  FilterPanel,
  FilterRow,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Selection,
  Summary,
  TotalItem,
  StateStoring,
  Grouping
} from 'devextreme-react/data-grid'
import { Export } from 'devextreme-react/chart'
import { useDownloadExcel } from '../Hooks/useDownloadExcel'
import themes from 'devextreme/ui/themes'
import { getEvaluacionDesempenoCompleta } from '../Api/evaluacionDesempeno.api'

export function ReporteDesempenoCompleto({ periodoId, expandedGroups, setLoading, setLoadingMessage }) {
  const [indicadoresAsignados, setIndicadoresAsignados] = React.useState([])
  const { onExporting } = useDownloadExcel()

  useEffect(() => {
    updateIndicadoresDesemepenoAsignados()
  }, [periodoId])

  function updateIndicadoresDesemepenoAsignados() {
    if (!periodoId) return
    setLoading(true)
    setLoadingMessage('Cargando...')
    getEvaluacionDesempenoCompleta(periodoId).then((response) => {
      setLoading(false)
      if (response.status === 200) {
        setIndicadoresAsignados(response.body)
      } else {
        toast.error('No se pudieron recuperar los indicadores asignados')
      }
    })
  }

  return (
    <DataGrid
      dataSource={indicadoresAsignados}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnResizingMode="nextColumn"
      onExporting={onExporting}
    >
      <Grouping
        autoExpandAll={expandedGroups}
      />
      <StateStoring
        enabled={true}
        type="localStorage"
        storageKey={'evaluacoesDesempenoCompleto'}
      />
      <GroupPanel visible={true} />
      <ColumnChooser enabled={true} />
      <Selection
        mode="multiple"
        selectAllMode={'allMode'}
        showCheckBoxesMode={
          themes.current().startsWith('material') ? 'always' : 'onClick'
        }
      />

      <Column dataField="id" caption="id" dataType="number" visible={false} />
      <Column
        dataField="idUsuario"
        caption="idUsuario"
        dataType="number"
        visible={false}
      />
      <Column
        dataField="gerencia"
        caption="Gerencia"
        dataType="string"
        visible={true}
        groupIndex={0}
      />
      <Column
        dataField="nombreUsuario"
        caption="Usuario"
        dataType="string"
        visible={true}
        groupIndex={1}
      />
      <Column
        dataField="puesto"
        caption="Puesto"
        dataType="string"
        visible={false}
      />
      <Column
        dataField="indicador"
        caption="Indicador"
        dataType="string"
        visible={true}
      />
      <Column
        dataField="nodo"
        caption="Proceso/Actividad"
        dataType="string"
        visible={true}
      />
      <Column
        dataField="tiporaci"
        caption="Responsabilidad"
        dataType="string"
        visible={true}
      />
      <Column
        dataField="frecuencia"
        caption="Frecuencia"
        dataType="string"
        visible={true}
      />
      <Column
        dataField="objetivoDesempeno"
        caption="Objetivo de desempeño"
        dataType="string"
        visible={true}
      />
      <Column
        dataField="meta"
        caption="Meta"
        dataType="number"
        visible={true}
        customizeText={({ value }) => (value !== null && value !== undefined) ? parseFloat(value + '').toFixed(2) : null}
      />
      <Column
        dataField="resultado"
        caption="Resultado"
        dataType="number"
        visible={true}
        customizeText={({ value }) => (value !== null && value !== undefined) ? parseFloat(value + '').toFixed(2) : null}
      />
      <Column
        dataField="resultadoPorcentual"
        caption="Resultado Porcentual (100%)"
        dataType="number"
        visible={true}
        cellRender={(rowInfo) => {
          const { resultadoPorcentual } = rowInfo.data
          if (resultadoPorcentual !== null) {
            if (typeof resultadoPorcentual === 'number') {
              return `${resultadoPorcentual.toFixed(2)}%`
            }
            return `${parseFloat(resultadoPorcentual).toFixed(2)}%`
          }
          return ''
        }}
      />
      <Column
        dataField="peso"
        caption="Peso"
        dataType="number"
        visible={true}
      />
      <Column
        dataField="resultadoPonderado"
        caption="Resultado Obtenido"
        dataType="number"
        visible={true}
        cellRender={(rowInfo) => {
          const { resultadoPonderado } = rowInfo.data
          if (resultadoPonderado !== null) {
            if (typeof resultadoPonderado === 'number') {
              return `${resultadoPonderado.toFixed(2)}%`
            }
            return `${parseFloat(resultadoPonderado).toFixed(2)}%`
          }
          return ''
        }}
      />

      <FilterRow visible={true} />
      <FilterPanel visible={false} />
      <HeaderFilter visible={true} />
      <Paging defaultPageSize={50} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[50, 100, 150, 200]}
        showInfo={true}
      />
      <Export enabled={true} allowExportSelectedData={true} />
      <Summary>
        <TotalItem
          column="peso"
          summaryType="sum"
          valueFormat="number"
          customizeText={(data) => `${data.value.toFixed(2)}%`}
        />
        <GroupItem
          column="peso"
          summaryType="sum"
          valueFormat="number"
          customizeText={(data) => `${data.value.toFixed(2)}%`}
          alignByColumn={true}
        />
        <TotalItem
          column="resultadoPonderado"
          summaryType="sum"
          valueFormat="number"
          customizeText={(data) => `${data.value.toFixed(2)}%`}
        />
        <GroupItem
          column="resultadoPonderado"
          summaryType="sum"
          valueFormat="number"
          customizeText={(data) => `${data.value.toFixed(2)}%`}
          alignByColumn={true}
        />
      </Summary>
    </DataGrid>
  )
}
