import React from "react";
import { useEffect, useState } from 'react'
import { NodoContext } from "../../Context/nodoContext";
import { PeriodoContext } from "../../Context/periodoContext";
import Toolbar from "./toolbar";
import OKRsFlow from "./okrsFlow";
import { getOKRsPlano, updateCumplimiento } from "../../Api/okr.api";
import FloattingToolbar from "./floatingToolbar";
import OKModalForm from "../okForm";
import { getUsuarios, getGerencias } from "../../Api/usuarios.api";
import KRMedicionesModal from "../keyResults";
import { toast } from "react-toastify";

const OKRMain = (props) => {
  const [data, setData] = useState([]);
  const [itemOK, setItemOK] = useState(null);
  const [periodoId, setPeriodoId] = useState('')
  const [openModalForm, setOpenModalForm] = useState(false)
  const [openModalKR, setOpenModalKR] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [usuarios, setUsuarios] = useState([])
  const [gerencias, setGerencias] = useState([])
  const [filters, setFilters] = React.useState({
    gerencias: [],
    departamentos: [],
    puestos: []
  });

  const { setLoading, setLoadingMessage } = React.useContext(NodoContext)
  const { getUnicoPeriodoSeleccionado, getSelected, periodos } = React.useContext(PeriodoContext)

  useEffect(() => {
    if (periodoId) getOKRs(periodoId);
  }, [periodoId]);

  useEffect(() => {
    if (!periodoId) {
      if (getSelected().length === 1) {
        setPeriodoId(getSelected()[0]);
      }
    }
  }, [periodos]);

  const handleCloseModalForm = () => {
    setOpenModalForm(false);
    setIsEdit(false);
  }
  
  const getOKRs = async (periodoId) => {
    try {
      setLoading(true);
      // Actualizar cumplimiento
      await updateCumplimiento(periodoId);
      const response = await getOKRsPlano(periodoId, filters);
      if (filters?.gerencias == undefined || filters?.gerencias?.length === 0) {
        const response1 = await getUsuarios();
        const response2 = await getGerencias();
        if (response1.status === 200) {
          setUsuarios(response1?.body || []);
        }
        if (response2.status === 200) {
          setGerencias(response2?.body || []);
        }
      }
      if (response.status === 200) {
        // Buscar el periodo seleccionado periodoId
        const _periodo = periodos.find((element) => element.id === periodoId);
        const nodoRaiz = {
          id: 0,
          nombre: _periodo?.nombre || 'Objetivo general',
          objetivoPadreId: null
        }
        // A los objetivos que no tengan padre, se les asigna el padre 0
        const data = response.body.map((item) => {
          if (!item.objetivoPadreId) {
            item.objetivoPadreId = 0;
          }
          return item;
        });
        // Se agrega el nodo raíz a la lista de objetivos
        setData([nodoRaiz, ...data]);

        // Buscar el ok seleccionado, segundo el id itemOK
        if (itemOK) {
          const ok = data.find((element) => element.id === itemOK.id);
          setItemOK(ok);
        }
      }
    } catch (error) {
      console.log('error', error);
      toast.error(`Error al obtener los OKRs: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }

  const obtenerFormatoNodosReactFlow = () => {
    const nodosReactFlow = data.map((element) => {
      return {
        id: `${element.id}`,
        type: 'default',
        text: `${element.nombre}`,
        data: {
          ...element,
          text: `${element.nombre}`,
          responsables: element.responsables || [],
          onBottomClick: (e) => {
            e.stopPropagation();
            setItemOK(element);
            setOpenModalForm(true);
          }
        },
        position: { x: 0, y: 0 }
      }
    });

    return nodosReactFlow;
  }

  const obtenerRelacionesNodosReactFlow = () => {
    const relacionesReactFlow = data.map((item, index) => {
      if (item.objetivoPadreId !== null && item.objetivoPadreId !== undefined) {
        return {
          id: `e${item.objetivoPadreId}-${item.id}`,
          source: `${item.objetivoPadreId}`,
          target: `${item.id}`,
          arrowHeadType: 'arrowclosed',
          // style: { stroke: `black` },
          type: 'smoothstep',
          animated: false
        }
      }
      return null
    })
    return relacionesReactFlow.filter((item) => item !== null);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Toolbar
        periodoId={periodoId}
        setPeriodoId={setPeriodoId}
        periodos={periodos}
        gerencias={gerencias}
        getOKRs={getOKRs}
        filters={filters}
        setFilters={setFilters}
      />

      <section style={{ width: '100vw', height: 'calc(100vh - 80px - 60px - 40px - 5px)' }}>
        <OKRsFlow
          setItemOK={setItemOK}
          initialEdges={obtenerRelacionesNodosReactFlow()}
          initialNodes={obtenerFormatoNodosReactFlow()}
        />
      </section>

      {/* Floatting action */}
      <FloattingToolbar
        createAction={() => setOpenModalForm(true)}
        editAction={() => {
          setIsEdit(true)
          setOpenModalForm(true)
        }}
        viewAction={() => setOpenModalKR(true)}
        editDisabled={!itemOK}
        createDisabled={!itemOK}
        viewDisabled={!itemOK}
      />

      {/* Modales */}
      <OKModalForm
        open={openModalForm}
        handleCloseModal={handleCloseModalForm}
        objetivoPadre={itemOK || {}}
        isEdit={isEdit}
        periodoId={periodoId}
        getOKRs={getOKRs}
        usuarios={usuarios}
      />

      <KRMedicionesModal
        open={openModalKR}
        handleCloseModal={() => setOpenModalKR(false)}
        objetivoClave={itemOK || {}}
      />
    </div>
  )

}

export default OKRMain;
