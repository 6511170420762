import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { HorizontalFlow } from "../../Activities/activitiesComponent";
import { NodoContext } from "../../../Context/nodoContext";
import Raci from "../../Activities/raci";
import Tags from "../../Activities/tags";
import NodeTags from "../../Activities/NodeTags";
import Documents from "../../Activities/documents";
import { IndicadoresMain } from "../../../Indicadores/indicadoresMain";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SipocActividadesHijas } from "../../Activities/Diagrams/sipocActividadesHijas";
import { nodeType } from "../../../Utils/node-types";
import Bitacora from "../../Activities/bitacora";
import ProcessDescription from "./processDescription";
import styles from "../style.module.css";
import { Riesgos } from "../../../Riesgos/riesgosMain";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "3px 0 3px  0" }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ProcessTabs({ descriptionref }) {
  const [tabIndex, setTabIndex] = React.useState(0);
  const [height, setHeight] = React.useState(450);
  const { nodoActual, tabId, setTabId } = React.useContext(NodoContext);
  const location = useLocation().pathname;
  const navigate = useNavigate();

  React.useEffect(() => {
    if (location.includes("indicadores")) {
      setTabIndex(4);
    } else {
      if (nodoActual.tipoNodoId === nodeType.actividad && tabId === 3) {
        setTabIndex(0);
        setTabId(0);
      } else {
        setTabIndex(tabId);
      }
    }
  }, [nodoActual]);

  React.useEffect(() => {
    if (descriptionref.current) {
      if (descriptionref.current.clientHeight / 0.3 > window.innerHeight) {
        setHeight(450);
      } else {
        const availableHeight =
          window.innerHeight - descriptionref.current.clientHeight - 200;
        setHeight(availableHeight);
      }
    }
  }, [descriptionref.current]);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    setTabId(newValue);
  };

  return (
    <Box sx={{ width: "100%", height: "auto" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="process-tabs"
          variant="scrollable"
        >
          <Tab label="Descripción" {...a11yProps(0)} />
          <Tab label="SIPOC" {...a11yProps(1)} />
          <Tab label="Pilar Organizacional" {...a11yProps(2)} />
          <Tab
            label="Actividades"
            {...a11yProps(3)}
            sx={{
              display:
                nodoActual.tipoNodoId === nodeType.proceso ? "block" : "none",
              paddingTop: "15px",
            }}
          />
          <Tab
            label="Indicadores"
            {...a11yProps(4)}
            onClick={() => navigate("indicadores")}
          />
          <Tab label="RACI" {...a11yProps(5)} />
          <Tab label="Riesgos" {...a11yProps(6)} />
          <Tab label="Documentos" {...a11yProps(7)} />
          <Tab label="Etiquetas" {...a11yProps(8)} />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <div className={styles["content-style"]}>
          <ProcessDescription />
        </div>
      </TabPanel>
      <TabPanel value={tabIndex} index={1} sx={{ padding: 0 }}>
        <HorizontalFlow descriptionref={descriptionref}></HorizontalFlow>
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <div className={styles["content-style"]}>
          <Tags />
        </div>
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <SipocActividadesHijas />
      </TabPanel>
      <TabPanel value={tabIndex} index={4}>
        <div className={styles["content-style"]}>
          <IndicadoresMain height={height} />
        </div>
      </TabPanel>
      <TabPanel value={tabIndex} index={5}>
        <div className={styles["content-style"]}>
          <Raci />
        </div>
      </TabPanel>
      <TabPanel value={tabIndex} index={6}>
        <div className={styles["content-style"]}>
          <Riesgos />
        </div>
      </TabPanel>
      <TabPanel value={tabIndex} index={7}>
        <div className={styles["content-style"]}>
          <Documents />
        </div>
      </TabPanel>
      <TabPanel value={tabIndex} index={8}>
        <div className={styles["content-style"]}>
          <NodeTags />
        </div>
      </TabPanel>
    </Box>
  );
}

export { ProcessTabs };
