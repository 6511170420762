import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';

const TablaFormatos = () => {
  return (
    <Paper>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Formato</TableCell>
            <TableCell>Ejemplo</TableCell>
            <TableCell>Descripción</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>M/d/yy</TableCell>
            <TableCell>9/7/98</TableCell>
            <TableCell>Mes y día de un solo dígito, año de dos dígitos, separados por /</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>MM/dd/yy</TableCell>
            <TableCell>09/07/98</TableCell>
            <TableCell>Mes, día y año de dos dígitos, separados por /</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>M/d/yyyy</TableCell>
            <TableCell>09/07/98</TableCell>
            <TableCell>Mes y día de un solo dígito, año completo, separados por /</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>MMM dd yyyy</TableCell>
            <TableCell>Sep 07 1998</TableCell>
            <TableCell>Nombre del mes abreviado, día de dos dígitos, año completo, separado por espacios</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>MMMM dd yyyy</TableCell>
            <TableCell>Septiembre 07 1998</TableCell>
            <TableCell>Nombre completo del mes, día de dos dígitos, año completo, separado por espacios</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>dd/MM/YYYY</TableCell>
            <TableCell>07/09/1998</TableCell>
            <TableCell>Día, mes y año, separados por /</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>MMM-yy</TableCell>
            <TableCell>Sep-98</TableCell>
            <TableCell>Nombre del mes abreviado, año completo, separado por -</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>yyyy</TableCell>
            <TableCell>1998</TableCell>
            <TableCell>Año completo</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>yy</TableCell>
            <TableCell>98</TableCell>
            <TableCell>Descripción</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>MMMM</TableCell>
            <TableCell>Septiembre</TableCell>
            <TableCell>Nombre del mes completo</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>MMM</TableCell>
            <TableCell>Sep</TableCell>
            <TableCell>Nombre del mes abreviado</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>MM</TableCell>
            <TableCell>09</TableCell>
            <TableCell>Mes de dos dígitos</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>dddd</TableCell>
            <TableCell>Lunes</TableCell>
            <TableCell>Nombre completo del día de la semana.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ddd</TableCell>
            <TableCell>Lun</TableCell>
            <TableCell>Nombre abreviado del día de la semana</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>dd</TableCell>
            <TableCell>07</TableCell>
            <TableCell>Día de dos dígitos</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>hh:mm</TableCell>
            <TableCell>14:45</TableCell>
            <TableCell>Hora de dos dígitos 00-23 (hora militar), Minutos de dos dígitos 00 a 60</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>hh:mm:ss</TableCell>
            <TableCell>14:45:44</TableCell>
            <TableCell>Hora de dos dígitos 00-23 (hora militar), Minutos de dos dígitos 00 a 60, Segundos de dos dígitos 00 a 60</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default TablaFormatos;
