import { HOST } from "../Utils/config";
import { getRequest, postRequest, putRequest, deleteRequest } from "./requests";

async function getPoliticas() {
  return await getRequest(`${HOST}/api/v1/politica`);
}

async function getPolitica(id) {
  return await getRequest(`${HOST}/api/v1/rol/${id}`);
}


async function postPolitica(data) {
  return await postRequest(`${HOST}/api/v1/politica`, data);
}

async function putPolitica(data) {
  return await putRequest(`${HOST}/api/v1/politica`, data);
}

async function deletePolitica(id) {
  return await deleteRequest(`${HOST}/api/v1/politica/${id}`);
}

export {
  getPoliticas,
  getPolitica,
 postPolitica,
 putPolitica,
 deletePolitica
};
