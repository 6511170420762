import { React, useEffect, useState } from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { searchNode } from "../Api/tagNode.api"
import Grid from "@mui/material/Grid";
import SearchItem from "./searchItem";
import 'react-perfect-scrollbar/dist/css/styles.css';
import IconButton from '@mui/material/IconButton';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TagsNodeModal from '../general-components/tagsNodeModal';
import Chip from '@mui/material/Chip';
import ModalMaxHeight from '../general-components/modalMaxHeight';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function SearchModal({ open, handleClose }) {
    const [criterio, setCriterio] = useState("");
    const [resultados, setResultados] = useState([]);
    const [openTagModal, setOpentagModal] = useState(false);
    const [tagsSeleccionados, setTagsSeleccionados] = useState([]);
    const [tipoObjeto, setTipoObjeto] = useState('1');

    const handleTipoObjetoChange = (event) => {
        setTipoObjeto(event.target.value);
    };

    useEffect(() => {
        setTagsSeleccionados([]);
    }, [])

    async function search() {
        try {
            const tagArray = tagsSeleccionados.map((item) => {
                return item.id
            })
            const tagListString = tagArray.join();

            const data = {
                criterio: criterio,
                tipoObjeto: tipoObjeto,
                tags: tagsSeleccionados.length > 0 ? tagListString : ''
            }
            const res = await searchNode(data);
            if (res.status == 200) {
                setResultados(res.body);
            } else {
                setResultados([]);
            }


        } catch (error) {
            console.error(error);
        }
    }

    const limpiar = () => {
        setResultados([]); setTagsSeleccionados([]);
        setCriterio("");
    }

    const closeFnTagModal = () => {
        setOpentagModal(false);
    }

    const onItemClicTagModal = async (item) => {
        tagsSeleccionados.push(item)
        closeFnTagModal();
    }

    const removeTag = (item) => {
        const arr = tagsSeleccionados.filter((x) => x !== item);
        setTagsSeleccionados(arr);
    }

    return (
        <ModalMaxHeight
            open={open}
            handleClose={() => { limpiar(); handleClose() }}
            title={`Búsqueda`}
        >
            <Grid container spacing={3} sx={{ mt: '0px' }}>
                <Grid container item xs={12} lg={12} sm={12} alignItems='center'>
                    <Grid item xs={10} lg={10} sm={10}>
                        <TextField
                            id="txtCriterio"
                            label="Criterio"
                            variant="outlined"
                            value={criterio}
                            style={{ width: "100%" }}
                            onChange={(e) => setCriterio(e.target.value)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item container xs={2} lg={2} sm={2} justifyContent='center'>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: "#69bd4b", color: "white" }}
                            onClick={() => search()}
                        >
                            Buscar
                        </Button>
                    </Grid>
                </Grid>
                <Grid container item xs={12} lg={12} sm={12} alignItems='center'>
                    <Grid item xs={6} lg={6} sm={6}>
                        <strong>Incluir estas etiquetas</strong>
                        <IconButton onClick={() => { setOpentagModal(true) }}>
                            <LocalOfferIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={6} lg={6} sm={6}>
                        <FormControl>
                            <RadioGroup
                                row
                                name="tipoObjeto"
                                value={tipoObjeto}
                                onChange={handleTipoObjetoChange}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Todo" />
                                <FormControlLabel value="2" control={<Radio />} label="Procesos/Actividades" />
                                <FormControlLabel value="3" control={<Radio />} label="Indicadores" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid>
                    <div style={{ paddingLeft: 15 }}>
                        {tagsSeleccionados.map((item) => (
                            <Chip label={item.nombre} variant="outlined" onDelete={() => { removeTag(item) }} />
                        ))}
                    </div>
                </Grid>
            </Grid>

            <h4 style={{ paddingTop: 10 }}>Resultados de la búsqueda:</h4>
            <div style={{ maxHeight: 330, overflowY: "scroll" }}>
                {Array.isArray(resultados) && (
                    resultados.map((fila) => (
                        <SearchItem item={fila} closeModal={handleClose} />
                    )
                    ))}

            </div>

            <TagsNodeModal
                open={openTagModal}
                closeFn={closeFnTagModal}
                onClickItem={onItemClicTagModal}
            />
        </ModalMaxHeight>


    )

}