import React, { useEffect } from "react";
import { Divider } from "@mui/material";
import SearchInput from "../TreeView/searchInput";
import { Rol } from "./rol";

function RolesList({ roles, setModalOpen, setRolActivo, updateRoles }) {
  const [rolesFiltrados, setRolesFiltrados] = React.useState([]);

  useEffect(() => {
    setRolesFiltrados(roles);
  }, [roles]);

  const filterPeriodos = (e) => {
    setRolesFiltrados(
      roles.filter((rol) =>
        rol.nombre.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };
  return (
    <div>
      <section
        style={{
          width: "250px",
          border: "1px solid black",
          borderRadius: "20px",
          margin: "20px",
        }}
      >
        <SearchInput onChange={filterPeriodos} />
      </section>
      <Divider />
      {rolesFiltrados.map((rol) => {
        return (
          <Rol
            key={rol.id}
            rol={rol}
            setModalOpen={setModalOpen}
            setRolActivo={setRolActivo}
            updateRoles={updateRoles}
          />
        );
      })}
    </div>
  );
}

export { RolesList };
