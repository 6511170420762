import { HOST } from "./config";
import { post, put, delBody } from "./api";
import { getIndicadores } from "../Api/indicadores.api";
import { getNodos, getNodo as getNodoRequest } from "../Api/nodos.api";

async function createIndicadoresTreeStructure() {
  const response = await getIndicadores();
  if (response.status === 200) {
    const indicadores = response.body;
    if (indicadores) {
      // Se obtienen los nodos de mas Alto Nivel, Gerencia
      const indicadoresRaiz = indicadores.filter(
        (element) => element.IndicadorId === null
      );
      const indicadoresStack = [...indicadoresRaiz];
      while (indicadoresStack.length > 0) {
        // A partir de ellos, se busca de forma iterativa
        const element = indicadoresStack.pop();
        element["items"] = indicadores.filter(
          (indicador) => indicador.IndicadorId === element.id
        );
        indicadoresStack.push(...element["items"]);
      }      
      return indicadoresRaiz;
    }
  }
  return [];
}

function createTreeStructure(nodos) {
  if (nodos) {
    const nodosTree = {};
    const gerencias = []; // Las gerencias no tienen un nodo padre    
    // Este algoritmo tiene orden 2*n, donde n es la cantidad de nodos
    // En la primera pasada se busca insertar todos los nodos en el arbol con su ID
    // En la segunda pasada se busca insertar los nodos en su nodo padre    
    nodos.forEach((nodo) => {
      nodo['items'] = [];
      nodosTree[nodo.id] = nodo;      
    });
    nodos.forEach((nodo) => {
      if(nodo.nodoPadreId !== null) {
        const nodoPadre = nodosTree[nodo.nodoPadreId];
        if(nodoPadre) {
          nodoPadre.items.push(nodo);
        } else {
          console.error("No se encontro el nodo padre: ", nodo.nodoPadreId);
        }        
      } else {
        gerencias.push(nodo);
      }
    });    
    return gerencias;
  }
  return [];
}

async function getProcessesSipoc(id = 1) {
  const response = await fetch(`${HOST}/api/v1/nodossipoc/procesos/${id}`);
  if (response.ok) {
    const jsonResponse = await response.json();
    if (jsonResponse.body && jsonResponse.body.length > 0) {
      return jsonResponse.body;
    }
    return null;
  }
  return null;
}

async function getActividades() {
  const response = await fetch(`${HOST}/api/v1/nodos/actividades`);
  if (response.ok) {
    const jsonResponse = await response.json();
    if (jsonResponse.body && jsonResponse.body.length > 0) {
      return jsonResponse.body;
    }
    return null;
  }
  return null;
}

async function getNodo(idNodo) {
  // const response = await fetch(`${HOST}/api/v1/nodos/${idNodo}`);
  const response = await getNodoRequest(idNodo);
  if (response.status == 200) {
    return response.body;
  } else {
    return null;
  }
  /*if (response.ok) {
    const jsonResponse = await response.json();
    if (jsonResponse.body) {      
      return jsonResponse.body;
    }
    return null;
  }
  return null;
  */
}

async function createAssociateNode(data) {
  const response = await post(`${HOST}/api/v1/nodossipoc`, data);
  return response;
}

async function createNode(data) {
  const response = await post(`${HOST}/api/v1/nodos`, data);
  return response;
}

async function updateNodo(data) {
  const response = await put(`${HOST}/api/v1/nodos`, data);
  return response;
}

async function deleteNode(data) {
  const response = await delBody(`${HOST}/api/v1/nodos`, data);
  return response;
}

export {
  createTreeStructure,
  createIndicadoresTreeStructure,
  getNodo,
  createAssociateNode,
  createNode,
  getActividades,
  updateNodo,
  getProcessesSipoc,
  deleteNode,
};
