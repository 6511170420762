import { Button, Paper } from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import UsersModal from "../../general-components/users-modal";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useAuth } from "../../Hooks/useAuth";
import { NodoContext } from "../../Context/nodoContext";
import {
  addRaciUser,
  getRaciUsers,
  getUserList,
  removeRaciUser,
} from "../../Utils/RaciRequest";
import RaciMember from "./raci-member";
import { nodeType } from "../../Utils/node-types";
import { ConditionalRendering } from "../../general-components/conditionalRendering";

export default function Raci() {
  const [raciUserList, setRaciUserList] = useState([]);
  const [responsibleList, setResponsibleList] = useState([]);
  const [accountableList, setAccountableList] = useState([]);
  const [consultedList, setConsultedList] = useState([]);
  const [digitadorList, setDigitadorList] = useState([]);
  const [informedList, setInformedList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [tipoRaciId, setTipoRaciId] = useState(null);
  const { getUser } = useAuth();
  const { nodoActual } = React.useContext(NodoContext);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getUsers();
  }, [nodoActual]);

  const handleClose = () => {
    setOpenModal(false);
  };

  const addUser = async (item) => {
    try {
      let data = {
        usuarioId: item.id,
        nodoId: nodoActual.id,
        tipoRaciId: tipoRaciId,
      };
      const res = await addRaciUser(data);
      if (res.status === 201) {
        getUsers();
        handleClose();
      }      
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {    
  }, [raciUserList]);

  const getUsers = async () => {
    try {
      const data = await getRaciUsers(nodoActual.id);
      setRaciUserList(data);

      const responsible = data.filter((el) => el.TipoRaci.id === 1);      
      setResponsibleList(responsible);

      const accountable = data.filter((el) => el.TipoRaci.id === 2);      
      setAccountableList(accountable);

      const consulted = data.filter((el) => el.TipoRaci.id === 3);
      setConsultedList(consulted);

      const informed = data.filter((el) => el.TipoRaci.id === 4);
      setInformedList(informed);

      const digitador = data.filter((el) => el.TipoRaci.id === 5);
      setDigitadorList(digitador);

      
    } catch (error) {
      console.error(error);
    }
  };

  const removeUser = async (item) => {
    const data = { id: item.id };
    const res = await removeRaciUser(data);

    if (res.status == 200) {
      getUsers();
    }
  };

  return (
    <Fragment>
      <UsersModal
        open={openModal}
        closeFn={handleClose}
        onClickItem={addUser}
      />

      <Grid container spacing={1}>
        
        <Grid item lg={2.4} >
          <div className="panelTitle">
            {nodoActual.permisos.C == 1 && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    setTipoRaciId(1);
                    setOpenModal(true);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </div>
            )}
            <div>
              <h4>Responsable de ejecución</h4>
            </div>
          </div>
          <div>
            {responsibleList.map((item, i) => (
              <RaciMember
                key={`raciItem${item.id}`}
                item={item}
                onConfirm={removeUser}
                permisos={nodoActual.permisos}
              />
            ))}
          </div>
        </Grid>
        
        <ConditionalRendering
          condition={nodoActual.tipoNodoId === nodeType.actividad}
        >
          <Grid item lg={2.4}>
            <div className="panelTitle">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {" "}
                {nodoActual.permisos.C == 1 && (
                  <IconButton
                    disabled={accountableList.length > 0 ? true : false}
                    aria-label="delete"
                    onClick={() => {
                      setTipoRaciId(2);
                      setOpenModal(true);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </div>

              <div>
                <h4>Responsable directivo</h4>
              </div>
            </div>
            <div>
              {accountableList.map((item, i) => (
                <RaciMember
                  key={`raciItem${item.id}`}
                  item={item}
                  onConfirm={removeUser}
                  permisos={nodoActual.permisos}
                />
              ))}
            </div>
          </Grid>
        </ConditionalRendering>
        <ConditionalRendering
          condition={nodoActual.tipoNodoId === nodeType.actividad}
        >
          <Grid  item lg={2.4}>
            <div className="panelTitle">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {" "}
                {nodoActual.permisos.C == 1 && (
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setTipoRaciId(3);
                      setOpenModal(true);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </div>
              <div>
                <h4>Consultado</h4>
              </div>
            </div>
            <div>
              {consultedList.map((item, i) => (
                <RaciMember
                  key={`raciItem${item.id}`}
                  item={item}
                  onConfirm={removeUser}
                  permisos={nodoActual.permisos}
                />
              ))}
            </div>
          </Grid>
        </ConditionalRendering>

        <Grid  item lg={2.4}>
          <div className="panelTitle">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {" "}
              {nodoActual.permisos.C == 1 && (
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    setTipoRaciId(4);
                    setOpenModal(true);
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </div>
            <div>
              <h4>Informado</h4>
            </div>
          </div>
          <div>
            {informedList.map((item, i) => (
              <RaciMember
                key={`raciItem${item.id}`}
                item={item}
                onConfirm={removeUser}
                permisos={nodoActual.permisos}
              />
            ))}
          </div>
        </Grid>

        <ConditionalRendering
          condition={nodoActual.tipoNodoId === nodeType.actividad}
        >
          <Grid item lg={2.4}>
            <div className="panelTitle">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>                
                {nodoActual.permisos.C == 1 && (
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      setTipoRaciId(5);
                      setOpenModal(true);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </div>
              <div>
                <h4>Digitador</h4>
              </div>
            </div>
            <div>
              {digitadorList.map((item, i) => (
                <RaciMember
                  key={`raciItem${item.id}`}
                  item={item}
                  onConfirm={removeUser}
                  permisos={nodoActual.permisos}
                />
              ))}
            </div>
          </Grid>
        </ConditionalRendering>
      </Grid>
    </Fragment>
  );
}
