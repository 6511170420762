import React, { useState, useEffect } from "react";
import Modal from "../general-components/modal";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { getUsuariosVista } from '../Api/vistaIndicador.api'

import { getUserList } from "../Utils/RaciRequest";
import {
  Checkbox,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import { removerUsuario } from "../Api/vistaIndicador.api";

export default function UsersModal({ open, closeFn, addUser,removeUser,hacerPublico, vista  }) {
  const [textoBusqueda, setTextoBusqueda] = useState("");
  const [originalItems, setOriginalItems] = useState([]);
  const [items, setItems] = useState([]);
  const [usuariosRelacionados,setUsuariosRelacionados] = React.useState([]);
  const [publico,setPublico] = React.useState(false);

  const getItems = async () => {
    const res = await getUserList();
    if (Array.isArray(res)) {
      setOriginalItems(res);
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    setTextoBusqueda("");
    setItems([]);
  }, [open]);

  useEffect(()=>{
    if(vista && open){
       getUsuariosRelacionados()
       if(vista && open){
        setPublico(vista.publico);      
      }
    }
   },[open])

  //  useEffect(()=>{
  //   console.log("publico",vista.publico);
  //   if(vista){
  //     setPublico(vista.publico);      
  //   }
  //  },[vista])

  const getUsuariosRelacionados = async()=>{
    try{
      const response = await getUsuariosVista(vista.id);
      console.log("getUsuariosRelacionados", response);
      if(response.status==200){
        setUsuariosRelacionados(response.body);
      }

    }catch(e){
      console.error(e);
    }
  }

  const handleSearch = async () => {
    const res = originalItems.filter((el) => {
      return el.nombreCompleto
        .toLowerCase()
        .includes(textoBusqueda.toLowerCase());
    });
    setItems(res);
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  return (
    <Modal title="Compartir tablero" open={open} handleClose={closeFn}>
        <div style={{maxHeight: "90vh"}}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "0.5rem",
                }}
              >
                <Tooltip
                  title={"Comparte el tablero con todos los usuarios"}
                  enterDelay={500}
                  arrow
                  placement="right"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={publico}
                        onChange={async() => {
                          const r = await hacerPublico(!publico);
                          setPublico(!publico);
                          getUsuariosRelacionados();
                        }}
                      />
                    }
                    label="¿Público?"
                  />
                </Tooltip>
              </div>
            </Grid>
            </Grid>

          {publico==false &&(
            <Grid container spacing={1}>
            <Grid item xs={12}><h5>Compartido con:</h5></Grid>
            <Grid item xs={12}>
                    {usuariosRelacionados.map((item)=>(
                        <Chip avatar={<Avatar>{item.Usuario.nombreCompleto.substring(0,1)}</Avatar>} label={item.Usuario.nombreCompleto} variant="outlined" 
                        onDelete={async()=>{
                          await removerUsuario(item.id);
                          getUsuariosRelacionados();
                        }
                        }  />
                    ))}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  id="txtTextoBusquedaTag"
                  label={"Usuario"}
                  value={textoBusqueda}
                  onChange={(event) => setTextoBusqueda(event.target.value)}
                  margin="normal"
                  inputProps={{ maxLength: 512 }}
                  onKeyDown={keyPress}
                  style={{ width: "100%" }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<SearchIcon>send</SearchIcon>}
                  onClick={handleSearch}
                >
                  Buscar
                </Button>
              </div>
            </Grid>
         

          <Grid item xs={12} lg={12} sm={12}>
            <Grid container spacing={1}>
              <List
                component="nav"
                aria-label="main mailbox folders"
                style={{ width: "100%" }}
              >
                {items.length === 0 && (
                  <div>
                    <div style={{ textAlign: "center" }}>No hay registros.</div>
                  </div>
                )}
                <div style={{maxHeight: "60vh", overflowY: "scroll", marginTop: "10px"}}>
                {items.map((item) => (
                  <ListItem   
                  style={{cursor:"pointer",width: "100% !important"}}                 
                    onClick={async () => {
                      await addUser(item.id);
                      setTextoBusqueda("");
                      getUsuariosRelacionados();
                    }}
                    
                  >
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={item.nombreCompleto}
                      // secondary={
                      //   item.TiempoEsperaEstimado > 0
                      //     ? convertirHHMM(item.TiempoEsperaEstimado)
                      //     : ""
                      // }
                    />
                  </ListItem>
                ))}
                </div>
              </List>
            </Grid>
          </Grid>
          </Grid>
          )}
        </div>
    </Modal>
  );
}
