import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { Autocomplete, Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { postRol, putRol } from "../Api/roles.api";
import { getUsuariosGerentes } from "../Api/usuarios.api";

function RolesForm({ setOpen, updateRoles, rolActivo }) {
  const [nombre, setNombre] = useState("");
  const [editable, setEditable] = useState(true);
  const [responsableProceso, setResponsableProceso] = useState(false);
  const [encargadoProceso, setEncargadoProceso] = useState(null);
  const [gerentes, setGerentes] = useState([]);

  useEffect(() => {
    obtenerGerentes()
  }, []);

  useEffect(() => {
    cargarRol();
  }, [rolActivo]);

  async function obtenerGerentes() {
    try {
      const items = await getUsuariosGerentes();
      setGerentes(items.body || []);
      return items.body
    } catch {
      return []
    }
  }

  async function cargarRol() {
    if (rolActivo) {
      const _gerentes = await obtenerGerentes();
      setNombre(rolActivo.nombre);
      setEditable(rolActivo.editable);
      setResponsableProceso(rolActivo.responsableProceso);
      if (rolActivo.encargadoProceso) {
        const encargado = _gerentes.find(item => item.id == rolActivo.encargadoProceso);
        setEncargadoProceso(encargado);
      }
    }
  }

  function save() {
    if (!nombre) {
      toast.warning('Nombre es requerido');
    }
    else if (responsableProceso && !encargadoProceso) {
      toast.warning('Gerente Encargado es requerido');
    }
    else {
      const rol = {
        nombre,
        editable,
        responsableProceso,
        encargadoProceso: responsableProceso ? encargadoProceso.id : null
      };
      if (rolActivo?.id) {
        rol.id = rolActivo.id;
        updateRol(rol);
      } else {
        createRol(rol);
      }
    }
  }

  const createRol = (rol) => {
    postRol(rol).then((response) => {
      if (response.status === 201) {
        toast.success("Rol creado correctamente");
        setOpen(false);
        updateRoles();
      } else {
        toast.error(response.message);
      }
    });
  };

  const updateRol = (rol) => {
    putRol(rol).then((response) => {
      if (response.status === 200) {
        toast.success("Rol modificado correctamente");
        setOpen(false);
        updateRoles();
      } else {
        toast.error(response.message);
      }
    });
  };

  return (
    <div style={{ maxHeight: "550px", overflow: "auto" }}>
      <Grid container spacing={3} sx={{ marginTop: "0px" }}>
        <Grid item xs={6}>
          <TextField
            id="nombre"
            label="Nombre"
            variant="outlined"
            value={nombre}
            style={{ width: "100%" }}
            onChange={(e) => setNombre(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "0.5rem",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={editable}
                  onChange={() => setEditable(!editable)}
                />
              }
              label="¿Editable?"
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "0.5rem",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={responsableProceso}
                  onChange={() => setResponsableProceso(!responsableProceso)}
                />
              }
              label="¿Responsable del Proceso?"
            />
            <Tooltip title="Permite asignar permisos para evaluación de desempeño a los usuarios que tengan este rol">
              <InfoIcon fontSize='small' />
            </Tooltip>
          </div>
        </Grid>
        {(responsableProceso) && (
          <Grid item xs={6}>
            <Autocomplete
              id="encargadoProceso"
              value={encargadoProceso}
              options={gerentes}
              onChange={(event, newValue) => {
                setEncargadoProceso(newValue);
              }}
              sx={{ width: "100%" }}
              getOptionLabel={(option) => option.nombreCompleto}
              renderInput={(params) => <TextField {...params} label="Gerente Encargado" />}
            />
          </Grid>
        )}

        <Grid item xs={12} lg={12} sm={12}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#69bd4b",
              color: "white",
              marginRight: "20px",
            }}
            onClick={() => save()}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export { RolesForm };
