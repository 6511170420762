import React from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { Badge, Divider, IconButton, Tooltip } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import FeedIcon from '@mui/icons-material/Feed'
import MessageIcon from '@mui/icons-material/Message'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { StyledBadge } from '../general-components/StyledBadge'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { getMapList,createValueMap,removeValueMap,getMapasCompartidosConmigo,getUsuariosMap,agregarUsuario,removerUsuario,updatePublico} from "../Api/valueMap.api";
import UsersModal from "./users-modal";

export function Map({ element, setDeleteConf, setId,showButtons }) {
  const [selected, setSelected] = React.useState(false)
  const navigate = useNavigate()
  const [openUserModal,setOpenUserModal]  = React.useState(false);

  function handleDelete(e) {
    e.stopPropagation()
    setDeleteConf(true)
    setId(element.id)
  }

  const addUser = async(mapId, userId)=>{
    await agregarUsuario(mapId,userId);
  }

  const hacerPublico = async(mapId,publico)=>{
    const r = await updatePublico(mapId,publico)
    if(r.status==200){
      element.publico = publico;
    }
  }

  function handleCloseModal(){
    setOpenUserModal(false);
  }

const handleCompartir = async(e)=>{
  e.stopPropagation();
  setOpenUserModal(true);
}
 

  return (
    <>
      <section
        style={{
          padding: '10px 0',
          display: 'flex',
          alignItems: 'center',
          margin: '0px 3%',
          cursor: 'pointer'
        }}
        onMouseOver={() => setSelected(true)}
        onMouseOut={() => setSelected(false)}
        onClick={() => navigate(`/home/valuemap/${element.id}`)}
      >
        <FeedIcon
          fontSize="large"
          color={`${selected ? 'primary' : 'inherit'}`}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '20px',
            alignItems: 'flex-start',
            width: '100%'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%'
            }}
          >
            {showButtons===true&&(<>
              <Tooltip title="Compartir">
              <IconButton onClick={handleCompartir}>
                <PersonAddAltIcon color="info" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar">
              <IconButton onClick={handleDelete}>
                <DeleteForeverIcon color="error" />
              </IconButton>
            </Tooltip>
            </>
            )}
            
            <b>{element.nombre}</b>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <small>
              Fecha creación: {moment(element.fechaCreacion).format('LL')}
            </small>
            {/* <small style={{ display: 'flex', alignItems: 'center' }}>
              <StyledBadge badgeContent={informe.comentarios} color="primary">
                <MessageIcon color="action" fontSize="small" />
              </StyledBadge>
            </small> */}
            {/* <small style={{ display: 'flex', alignItems: 'center' }}>
              <StyledBadge badgeContent={informe.archivos} color="primary">
                <AttachFileIcon color="action" fontSize="small" />
              </StyledBadge>
            </small> */}
          </div>
        </div>
      </section>

      <div style={{ width: '100%', justifyContent: 'center' }}>
        <Divider sx={{ width: '96%', marginLeft: '2%' }} />
      </div>

      <UsersModal open={openUserModal} addUser = {addUser} closeFn={handleCloseModal} hacerPublico={hacerPublico} map={element}/>
    </>
  )
}
