import { Fab } from "@mui/material";
import React, { useEffect } from "react";
import { ArchivosList } from "../Archivos/archivosList";
import Modal from "../general-components/modal";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { toast } from "react-toastify";
import { NodoContext } from "../Context/nodoContext";
import { Box } from "@mui/system";
import {
    getArchivosByResultados,
    getPresignedUrl,
    postFilesMetadata,
    putToS3,
} from "../Api/files.api";
import { ConditionalRendering } from "../general-components/conditionalRendering";
import { TIPOS_RACI } from "../Utils/enums";

function ModalArchivos(props) {
    const { open, setOpen, archivos } = props;
    const { setLoading, setLoadingMessage } = React.useContext(NodoContext);
    const filesRef = React.useRef();

    function handleCloseModal() {
        setOpen(false);
    }

    function getFileType(file) {
        return { type: file.type.split("/")[0], format: file.type.split("/")[1] };
    }

    async function askForPresignedUrl({ name, type, format }) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await getPresignedUrl({ name, type, format });
                if (response.status !== 200) {
                    toast.error(`Error uploading ${name}`);
                    reject();
                }
                resolve({ url: response.body.url, key: response.body.key });
            } catch (error) {
                reject(error);
            }
        });
    }

    return (
        <Modal open={open} handleClose={handleCloseModal} title="Archivos Adjuntos">
            <div style={{ maxHeight: "300px", height: "300px", overflow: "auto" }}>
                <ArchivosList
                    archivos={archivos}
                    setModalOpen={setOpen}
                    updateArchivos={() => {}}
                    tipoRaciId={0}
                />
            </div>
        </Modal>
    );
}

export { ModalArchivos };
