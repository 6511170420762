import React from "react";
import { Objetivo } from "./objetivo";


function ObjetivosList({
  objetivos,
  setModalOpen,
  setObjetivoActivo,
  obtenerObjetivos
}) {
  return (
    <div>
      {objetivos.map((objetivo) => {
        return (
          <Objetivo
            key={objetivo.id}
            objetivo={objetivo}
            setModalOpen={setModalOpen}
            setObjetivoActivo={setObjetivoActivo}
            obtenerObjetivos={obtenerObjetivos}
          />
        );
      })}
    </div>
  );
}

export { ObjetivosList };
