import React, { useEffect } from 'react'
import { Fab, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { IndicadoresList } from './IndicadoresList'
import { IndicadoresModal } from './indicadoresModal'
import { NodoContext } from '../Context/nodoContext'
import { getIndicadoresPorNodo } from '../Api/indicadores.api'
import { toast } from 'react-toastify'
import { GraficasSecundarias } from './graficasSecundarias'
import { getResultadoIndividual } from '../Api/resultado.api'
import { modosIntegracion } from '../Utils/enums'
import { PeriodoContext } from '../Context/periodoContext'
import { useLocation, useSearchParams } from 'react-router-dom'
import { ModalCopiaIndicador } from '../general-components/modalCopiaIndicador'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import styles from '../Styles/main.module.css'
import { CustomizedGraph } from '../Chart/graph'
import { ModalIndicadorCompuesto } from './modalIndicadorCompuesto'
import ZoomDialog from '../Chart/zoomDialog'

function IndicadoresMain({ height }) {
  const [isModalOpen, setModalOpen] = React.useState(false)
  const [displayMainGraph, setDisplayMainGraph] = React.useState(false)
  const [displaySubGraphs, setDisplaySubGraphs] = React.useState(false)
  const [indicadores, setIndicadores] = React.useState([])
  const [subGraphs, setSubGraphs] = React.useState([])
  const [indicadorActivo, setIndicadorActivo] = React.useState({})
  const [mainGraphTitles, setMainGraphTitles] = React.useState([])
  const [mainGraphValues, setMainGraphValues] = React.useState([])
  const [openModalCopia, setOpenModalCopia] = React.useState(false)
  const [openModalCompuesto, setOpenModalCompuesto] = React.useState(false)  
  const { fireReload } = React.useContext(PeriodoContext)
  const { nodoActual, setLoading, setLoadingMessage } =
    React.useContext(NodoContext)
  const [tablaResultados, setTablaResultados] = React.useState([])
  const [esParteRaci, setEsParteRaci] = React.useState(false)
  const [listaColumnas,   setListaColumnas] = React.useState([])
  const [avisoZoom, setAvisoZoom] = React.useState(false);
  const [searchParams] = useSearchParams()
  const { search } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const isFirstVisit = !localStorage.getItem('avisoZoom');
    if (isFirstVisit) {
      setTimeout(() => {
        setAvisoZoom(true);
      }, 1000);
      localStorage.setItem('avisoZoom', 'true');
    }
  }, []);

  useEffect(() => {
    setDisplayMainGraph(false)
    setDisplaySubGraphs(false)
    updateIndicadores()
  }, [nodoActual])  

  useEffect(() => {
    setDisplayMainGraph(false)
    setDisplaySubGraphs(false)
    console.log('indicadorActivo ', indicadorActivo)
    updateResultados()
  }, [indicadorActivo, fireReload])

  useEffect(() => {
    updateSelectedIndicador()
  }, [search, indicadores])

  function updateSelectedIndicador() {
    if (indicadores.length === 0) return
    if (searchParams.get('indicadorId')) {
      const indicador = indicadores.find(
        (indicador) =>
          indicador.id === parseInt(searchParams.get('indicadorId'))
      )
      setIndicadorActivo(indicador)
    } else {
      setIndicadorActivo({})
    }
  }

  function updateResultados() {
    if (!indicadorActivo?.id) return
    setLoading(true)
    setLoadingMessage('Cargando...')
    getResultadoIndividual(
      indicadorActivo.id,
      indicadorActivo.modoIntegracionId
    ).then((response) => {
      setLoading(false)
      if (response.status === 200) {        
        setMainGraphTitles(response.body.periodos)
        setMainGraphValues(response.body.resultados)
        setSubGraphs(response.body.factores)
        if(response.body.tablaResultados){
          setTablaResultados(response.body.tablaResultados)
          setEsParteRaci(response.body.esParteRaci)
          setListaColumnas(response.body.indicadores)
        }
        showGraphs()
      } else {
        toast.error(response.message)
      }
    })
  }

  function showGraphs() {
    if (indicadorActivo.modoIntegracionId === modosIntegracion.directo) {
      setDisplayMainGraph(true)
      setDisplaySubGraphs(false)
    } else if (
      indicadorActivo.modoIntegracionId === modosIntegracion.agrupado
    ) {
      setDisplayMainGraph(true)
      setDisplaySubGraphs(false)
    } else {
      setDisplayMainGraph(true)
      setDisplaySubGraphs(true)
    }
  }

  function updateIndicadores() {
    getIndicadoresPorNodo(nodoActual.id, true).then((response) => {
      if (response.status === 200) {
        setIndicadores(response.body)
      } else {
        toast.error(response.message)
      }
    })
  }

  function openModal() {
    setIndicadorActivo({})
    setModalOpen(true)
  }

  return (
    <div style={{ width: 'inherit' }}>
      <ZoomDialog open={avisoZoom} onClose={() => setAvisoZoom(false)} />
      <div
        style={{
          display: 'flex',
          height: '100%',
          width: 'inherit'
        }}
      >
        <div className={styles['indicadores-selection']}>
          <IndicadoresList
            indicadores={indicadores}
            setModalOpen={setModalOpen}
            setOpenModalCopia={setOpenModalCopia}
            setIndicadorActivo={setIndicadorActivo}
            updateIndicadores={updateIndicadores}
            indicadorActivo={indicadorActivo}
            permisos={nodoActual.permisos}
            nodoId={nodoActual.id}
            setOpenModalCompuesto={setOpenModalCompuesto}
          />
        </div>
        <div className={styles['grafica-indicadores']}>
          {displayMainGraph && (
            <CustomizedGraph
              titulos={mainGraphTitles}
              resultados={mainGraphValues}
              titulo={indicadorActivo?.nombre}
              etiquetaEjeX={indicadorActivo?.etiquetaEjeX}
              graficarEjeXDiscreto={indicadorActivo?.graficarEjeXDiscreto}
              formatoFecha={indicadorActivo?.formatoFecha}
              indicadorId={indicadorActivo?.id}
              descripcion={indicadorActivo?.descripcion}
              operacionIntegracion={indicadorActivo?.modoIntegracionGlobalId}
              metaInversa={indicadorActivo?.metaInversa}
              agrupado={indicadorActivo?.modoIntegracionId === -1}
              esAgrupadoSeries={indicadorActivo?.modoIntegracionId === -1}
              tablaResultados={mainGraphValues}
              listaColumnas={listaColumnas}
              esParteRaci={esParteRaci}
              updateResultados={updateResultados}
              height={'calc(100vh - 200px)'}
              maxWidth={
                displaySubGraphs
                  ? (window.innerWidth - 420) / 2
                  : window.innerWidth - 380
              }
              minWidth={
                displaySubGraphs
                  ? (window.innerWidth - 720) / 2
                  : window.innerWidth - 680
              }
              additionalButtons={
                indicadorActivo?.agrupado
                  ? []
                  : [
                      {
                        icon: <EditIcon />,
                        onClick: () =>
                          navigate(
                            `/home/resultadosIndividuales?raciId=1&indicadorId=${indicadorActivo.id}`
                          ),
                        title: 'Editar Resultados'
                      }
                    ]
              }
            />
          )}
          {displaySubGraphs && (
            <GraficasSecundarias
              subGraphs={subGraphs}
              titulos={mainGraphTitles}
              etiquetaEjeX={indicadorActivo?.etiquetaEjeX}
              graficarEjeXDiscreto={indicadorActivo?.graficarEjeXDiscreto}
              formatoFecha={indicadorActivo?.formatoFecha}
              meta={indicadorActivo?.metaIndividualPorMedicion}
              modoIntegracionId={indicadorActivo?.modoIntegracionId}
              metaInversa={indicadorActivo?.metaInversa}
            />
          )}
        </div>
      </div>
      <Fab
        style={
          {
            position: 'absolute',
            bottom: '20px',
            right: '20px',
            backgroundColor: 'rgba(174, 189, 54, 0.95)',
            color: 'white'
          }
        }
      >
        <AddIcon onClick={() => openModal()} />
      </Fab>
      <IndicadoresModal
        open={isModalOpen}
        setOpen={setModalOpen}
        updateIndicadores={updateIndicadores}
        indicadorActivo={indicadorActivo}
      />
      <ModalCopiaIndicador
        open={openModalCopia}
        setOpen={setOpenModalCopia}
        indicadorActivo={indicadorActivo}
      />
      <ModalIndicadorCompuesto
        open={openModalCompuesto}
        setOpen={setOpenModalCompuesto}
        updateIndicadores={updateIndicadores}
        nodoId={nodoActual.id}
        indicadorActivoBlueprint={indicadorActivo}
      />
    </div>
  )
}

export { IndicadoresMain }
