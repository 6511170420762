import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { NodoProvider } from "./Context/nodoContext";
import { AuthProvider } from "./Context/authContext";
import { PeriodoProvider } from "./Context/periodoContext";
import { LayoutProvider } from "./Context/layoutContext";
import { PrimeReactProvider } from 'primereact/api';

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <AuthProvider>
        <NodoProvider>
          <PeriodoProvider>
            <LayoutProvider>
              <PrimeReactProvider>
                <App />
              </PrimeReactProvider>
            </LayoutProvider>
          </PeriodoProvider>
        </NodoProvider>
      </AuthProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);
