import React from 'react'
import PropTypes from "prop-types";
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import EvaluacionesGrid from './evaluacionesGrid'
import { MenuItem, TextField, Tab, Tabs, Box, IconButton, Tooltip, ToggleButtonGroup, ToggleButton } from '@mui/material'
import { NodoContext } from '../Context/nodoContext'
import { PeriodoContext } from '../Context/periodoContext'
import { AccordionOptions } from '../Indicadores/accordionOptions'
import { postEvaluaciones, getEvaluaciones, updateEvaluaciones } from '../Api/evaluacionDesempeno.api'
import SimpleBreadcrumbs, { BreadcrumbItem } from '../general-components/breadcums'
import AssignmentIcon from '@mui/icons-material/Assignment';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import SyncIcon from '@mui/icons-material/Sync';
import { ReporteDesempenoCompleto } from './reporteDesempenoCompleto';
import { getPermisoAdminDesempeno } from '../Api/auth.api';
import ResumenProceso from './resumenProceso';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{ width: '100%' }}>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function EvaluacionesDesempeno() {

  const [data, setData] = useState([]);
  const [dataFiltrado, setDataFiltrado] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [periodoId, setPeriodoId] = useState('')
  const [filtroBono, setFiltroBono] = useState(null);
  const [expandedGroups, setExpandedGroups] = useState(true);
  const [permisoAdminDesempeno, setPermisoAdminDesempeno] = React.useState(false);
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext)
  const { getSelected, periodos } = React.useContext(PeriodoContext)

  useEffect(() => {
    if (periodoId) {
      obtenerEvaluaciones()
      actualizarEvaluaciones()
      generarEvaluaciones()
      obtenerPermisoAdminDesempeno()
    }
  }, [periodoId]);

  useEffect(() => {
    // Si no hay un periodo seleccionado, toma el seleccionado global de la App
    if (!periodoId) {
      if (getSelected().length === 1) {
        setPeriodoId(getSelected()[0]);
      }
    }
  }, [periodos]);

  const obtenerPermisoAdminDesempeno = () => {
    getPermisoAdminDesempeno().then((response) => {
      console.log(response)
      if (response.status === 200) {
        if (response.body?.permiso) {
          setPermisoAdminDesempeno(true)
        } else {
          setPermisoAdminDesempeno(false)
        }
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const generarEvaluaciones = () => {
    postEvaluaciones(periodoId).then((response) => {
      console.log(response)
      if (response.status === 201 && response.body?.body?.nuevasEvaluaciones === true) {
        actualizarEvaluaciones()
      }
    })
  }

  const actualizarEvaluaciones = () => {
    updateEvaluaciones(periodoId).then((response) => {
      // obtenerEvaluaciones()
    })
  }

  const obtenerEvaluaciones = () => {

    setLoading(true)
    setLoadingMessage('Cargando...')

    const valorGuardado = localStorage.getItem('filtroBono');
    if (parseInt(valorGuardado) === 1) setFiltroBono(1);

    getEvaluaciones(periodoId).then((response) => {
      setLoading(false)
      if (response.status === 200) {
        const _data = response.body.map((item) => ({
          ...item,
          gerencia: item.usuario.gerencia,
          nombreUsuario: item.usuario.nombreCompleto,
          puesto: item.usuario.puesto,
          totalEvaluaciones: item.gestiones.filter((gestion) => gestion.resultadoEvaluacion !== null).length,
        }))
        setData(_data)
        setDataFiltrado(_data.filter((item) => valorGuardado !== '1' || item.esBono))
      } else {
        toast.error('No se pudieron recuperar las evaluaciones')
      }
    })
  }

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const compressHandler = () => {
    setExpandedGroups(true);
    setTimeout(() => {
      setExpandedGroups(false);
    }, 100);
  };

  const handleFiltroBono = (event, value) => {
    setFiltroBono(value);
    localStorage.setItem('filtroBono', JSON.stringify(value));
    setDataFiltrado(data.filter((item) => value !== 1 || item.esBono));
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <section
        style={{
          textAlign: 'left',
          marginLeft: '20px',
          marginTop: '20px'
        }}
      >
        <SimpleBreadcrumbs>
          <BreadcrumbItem title="Evaluaciones de desempeño" link="/" IconHome={AssignmentIcon} isLast />
        </SimpleBreadcrumbs>
      </section>
      <section
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '20px'
        }}
      >
        <div style={{ display: 'flex' }}>
          <TextField
            id="periodo"
            select
            label="Seleccione un periodo"
            value={periodoId}
            onChange={(e) => setPeriodoId(e.target.value)}
            required
            sx={{
              textAlign: 'left',
              width: '500px',
              float: 'left'
            }}
          >
            {periodos.map((periodo) => (
              <MenuItem key={periodo.id} value={periodo.id}>
                {periodo.nombre}
              </MenuItem>
            ))}
          </TextField>
          {/* Boton de refrescar */}
          <IconButton
            title='Actualizar evaluaciones'
            onClick={() => {
              obtenerEvaluaciones()
              actualizarEvaluaciones()
            }}
            sx={{ marginLeft: '10px' }}
          >
            <SyncIcon color='info' />
          </IconButton>
        </div>
        <div style={{ display: 'flex', justifyContent: 'right', margin: '10px' }}>
          <AccordionOptions
            compressHandler={compressHandler}
            expandHandler={() => setExpandedGroups(true)}
            expandTitle="Expandir todos"
            compressTitle="Contraer todos"
          />
          <div style={{ display: 'flex' }}>
            <Tooltip title="Filtrar asignados a bono zafra">
              <ToggleButtonGroup
                value={tabIndex === 1 ? 1 : filtroBono}
                size="small"
                exclusive
                onChange={handleFiltroBono}
                disabled={tabIndex === 1}
                color="primary"
              >
                <ToggleButton value={1} >
                  <MilitaryTechIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Tooltip>
          </div>
        </div>
      </section>
      <section style={{ margin: '0px 20px' }}>
        <Box sx={{ margin: 1, width: "100%", height: "auto" }}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
          >
            <Tab label="Tablero de Evaluaciones" id={'tab-0'} />
            {permisoAdminDesempeno &&
              <Tab label="Reporte Pago de Bono" id={'tab-1'} />
            }
            <Tab label="Resumen por Proceso" id={'tab-2'} />
          </Tabs>
        </Box>
        <TabPanel value={tabIndex} index={0}>
          <EvaluacionesGrid
            periodoId={periodoId}
            data={dataFiltrado}
            allGroupsExpanded={expandedGroups}
            obtenerEvaluaciones={obtenerEvaluaciones}
          />
        </TabPanel>
        {permisoAdminDesempeno &&
          <TabPanel value={tabIndex} index={1}>
            <ReporteDesempenoCompleto
              periodoId={periodoId}
              expandedGroups={expandedGroups}
              setLoading={setLoading}
              setLoadingMessage={setLoadingMessage}
            />
          </TabPanel>
        }
        <TabPanel value={tabIndex} index={permisoAdminDesempeno ? 2 : 1}>
          <ResumenProceso
            periodoId={periodoId}
            data={dataFiltrado}
            allGroupsExpanded={expandedGroups}
            obtenerEvaluaciones={obtenerEvaluaciones}
          />
        </TabPanel>
      </section>
    </div>
  )
}
