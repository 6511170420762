import React, { useEffect } from "react";
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  ReactFlowProvider,
} from "react-flow-renderer";
import { toast } from "react-toastify";
import { NodoContext } from "../../../Context/nodoContext";
import { CustomActivitiesNode } from "../../../Nodes/customActivitiesNode";
import { getActividades } from "../../../Utils/structureMaker";
import { getLayoutedElements } from "../../Diagrams/createProcessFlow";
import { getInitialActivities } from "./createActivitiesFlow";
const onLoad = (reactFlowInstance) => {
    setTimeout(() => {
        reactFlowInstance.fitView({ padding: 0.2 });
    }, 100)
}
  

const nodeTypes = {
  customActivity: CustomActivitiesNode,
};

function VerticalActivitiesFlow() {
  const [elements, setElements] = React.useState([]);
  const [areElementsReady, setElementsReady] = React.useState(false);
  const {
    getNewActivitiesGraphID
  } = React.useContext(NodoContext)

  useEffect(() => {
    getActividades().then((data) => {
      if (data) {
        const initialElements = getInitialActivities(data);        
        const layoutedElements = getLayoutedElements(initialElements, "LR");        
        setElements(layoutedElements);
        setElementsReady(true);
      } else {
        toast.error("Ocurrió un error al recuperar los datos");
      }
    });
  }, []);

  return (
    <div>
      {areElementsReady && (
        <div style={{ height: "calc(100vh - 130px)" }}>
          <ReactFlowProvider>
            <ReactFlow            
              elements={elements}
              nodeTypes={nodeTypes}
              elementsSelectable={true}
              // onNodeDoubleClick={onNodeClick}
              onLoad={onLoad}              
              nodesConnectable={false}
              nodesDraggable={false}
            >
              <MiniMap />
              <Controls showInteractive={true} style={{ top: 10, height: "50px" }}/>
              <Background color="#aaa" gap={16} />
            </ReactFlow>
          </ReactFlowProvider>
        </div>
      )}
    </div>
  );
}

export { VerticalActivitiesFlow };
