import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { postObjetivo, putObjetivo } from "../Api/objetivosPilar.api";
import { Autocomplete } from "@mui/material";

function ObjetivoForm({ closeModal, obtenerObjetivos, objetivoActivo, pilares }) {
  const [cargando, setCargando] = useState(false);

  const [nombre, setNombre] = useState(objetivoActivo.nombre || "");
  const [pilar, setPilar] = useState(objetivoActivo?.ObjetivoDesempeno || null);

  function save() {
    const body = {
      nombre,
      objetivoDesempenoId: pilar?.id,
    };
    if (!nombre) {
      toast.error("Nombre del objetivo es necesario");
    } else if (!pilar) {
      toast.error("Pilar es necesario");
    } else {
      if (objetivoActivo.id) {
        body.id = objetivoActivo.id;
        updateObjetivo(body);
      } else {
        createObjetivo(body);
      }
    }
  }

  const createObjetivo = async (body) => {
    setCargando(true);
    try {
      postObjetivo(body).then((response) => {
        if (response.status === 201) {
          toast.success("Objetivo creado correctamente");
          closeModal();
          obtenerObjetivos();
        } else {
          toast.error(response.message);
        }
      });
    } finally {
      setCargando(true);
    }
  };

  const updateObjetivo = (body) => {
    try {
      putObjetivo(body).then((response) => {
        if (response.status === 200) {
          toast.success("Objetivo modificado correctamente");
          closeModal();
          obtenerObjetivos();
        } else {
          toast.error(response.message);
        }
      });
    } finally {

    }
  };

  return (
    <div style={{ maxHeight: "550px", overflow: "auto" }}>
      <Grid container spacing={3} sx={{ marginTop: "0px" }}>
        <Grid item xs={6}>
          <TextField
            id="nombre"
            label="Nombre"
            variant="outlined"
            value={nombre}
            style={{ width: "100%" }}
            onChange={(e) => setNombre(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            id="pilar"
            value={pilar}
            options={pilares}
            onChange={(event, newValue) => {
              setPilar(newValue);
            }}
            sx={{ width: "100%" }}
            getOptionLabel={(option) => option.nombre}
            renderInput={(params) => <TextField {...params} label="Pilar" />}
          />
        </Grid>
        <Grid item xs={12} lg={12} sm={12}>
          <Button
            variant="contained"
            disabled={cargando}
            loading={cargando}
            style={{
              backgroundColor: "#69bd4b",
              color: "white",
              marginRight: "20px",

            }}
            onClick={() => save()}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export { ObjetivoForm };
