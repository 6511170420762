import { HOST } from "../Utils/config";
import { getRequest, postRequest, putRequest, deleteRequest } from "./requests";

async function createFeedbackEvaluacion(comentario, evaluacionId, tipoEvaluacion) {
    return await postRequest(
        `${HOST}/api/v1/feedbackEvaluacion/`,
        { comentario, evaluacionId, tipoEvaluacion }
    );
}

async function updateFeedbackEvaluacion(id, comentario) {
    return await putRequest(
        `${HOST}/api/v1/feedbackEvaluacion/${id}/`,
        { comentario }
    );
}

async function getFeedbackEvaluacionIA(evaluacionId) {
    return await getRequest(
        `${HOST}/api/v1/evaluacionesDesempeno/generarFeedbackIA/${evaluacionId}/`
    );
}

export { 
    createFeedbackEvaluacion,
    getFeedbackEvaluacionIA,
    updateFeedbackEvaluacion,
};
