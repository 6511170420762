import * as React from "react";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NonDecoratedLink } from "../Custom/nonDecoratedLink";

import Collapse from "@mui/material/Collapse";
import { ListItemButton } from "@mui/material";
import styled from "@emotion/styled";
import { useMainMenu } from "../Hooks/useMainMenu";
import { NodoContext } from "../Context/nodoContext";
import TourIcon from '@mui/icons-material/Tour';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ApartmentIcon from "@mui/icons-material/Apartment";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";

const CustomizedListItemButton = styled(ListItemButton)`
  &.Mui-selected {
    background-color: #acb236;
  }
  &.Mui-selected:hover {
    background-color: #acb236;
  }
  :hover {
    background-color: rgba(172, 178, 54, 0.2);
  }
`;

function MainMenu() {
  const [menuItems, updateMenuOptions] = useMainMenu();
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const { setDisplayTreeView } = React.useContext(NodoContext);
  const [isProcesosMenuOpen, setProcesosMenuOpen] = React.useState(false);
  const [isOKRsMenuOpen, setOKRsMenuOpen] = React.useState(false);
  const [isEvaluacionMenuOpen, setEvaluacionMenuOpen] = React.useState(false);
  const [isAdministracionMenuOpen, setMenuOrganizacionalOpen] =
    React.useState(false);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  React.useEffect(() => {
    if(menuItems.firstLoad) {
      updateMenuOptions();
    }
  }, []);

  function displayMenu(menuName) {
    switch (menuName) {
      case "procesos":
        const procesosLength = menuItems?.procesos.filter(
          (item) => item.visible === 1
        ).length;
        return procesosLength > 0;
      case "evaluaciones":
        const evaluacionesLength = menuItems?.evaluaciones.filter(
          (item) => item.visible === 1
        ).length;
        return evaluacionesLength > 0;
      case "okrs":
        const okrsLength = menuItems?.okrs.filter(
          (item) => item.visible === 1
        ).length;
        return okrsLength > 0;
      case "administracion":
        const administracionLength = menuItems?.administracion.filter(
          (item) => item.visible === 1
        ).length;
        return administracionLength > 0;
      default:
        return false;
    }
  }

  return (
    <List>
      {displayMenu("procesos") && (
        <>
          <ListItemButton
            onClick={() => setProcesosMenuOpen(!isProcesosMenuOpen)}
            key="procesos"
          >
            <ListItemIcon sx={{ color: "white" }}>
              <ApartmentIcon />
            </ListItemIcon>
            <ListItemText primary="Procesos" />
            {isProcesosMenuOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse
            in={isProcesosMenuOpen}
            timeout="auto"
            unmountOnExit
            key="procesos-collapse"
          >
            <List component="div" disablePadding>
              {menuItems?.procesos?.map((menuItem) => {
                return (
                  <NonDecoratedLink
                    to={menuItem.to}
                    visible={menuItem.visible}
                    key={menuItem.index}
                  >
                    <CustomizedListItemButton
                      selected={selectedIndex === menuItem.index}
                      onClick={(e) =>
                        menuItem.onClick(
                          e,
                          handleListItemClick,
                          setDisplayTreeView
                        )
                      }
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon sx={{ color: "white" }}>
                        {menuItem.icon()}
                      </ListItemIcon>
                      <ListItemText primary={menuItem.name} />
                    </CustomizedListItemButton>
                  </NonDecoratedLink>
                );
              })}
            </List>
          </Collapse>
        </>
      )}
      {displayMenu("okrs") && (
        <>
          <ListItemButton
            onClick={() => setOKRsMenuOpen(!isOKRsMenuOpen)}
            key="okrs"
          >
            <ListItemIcon sx={{ color: "white" }}>
              <TourIcon />
            </ListItemIcon>
            <ListItemText primary="Estrategia" />
            {isOKRsMenuOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse
            in={isOKRsMenuOpen}
            timeout="auto"
            unmountOnExit
            key="okrsCollapse"
          >
            <List component="div" disablePadding>
              {menuItems?.okrs?.map((menuItem) => {
                return (
                  <NonDecoratedLink
                    to={menuItem.to}
                    visible={menuItem.visible}
                    key={menuItem.index}
                  >
                    <CustomizedListItemButton
                      selected={selectedIndex === menuItem.index}
                      onClick={(e) =>
                        menuItem.onClick(
                          e,
                          handleListItemClick,
                          setDisplayTreeView
                        )
                      }
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon sx={{ color: "white" }}>
                        {menuItem.icon()}
                      </ListItemIcon>
                      <ListItemText primary={menuItem.name} />
                    </CustomizedListItemButton>
                  </NonDecoratedLink>
                );
              })}
            </List>
          </Collapse>
        </>
      )}
      {displayMenu("evaluaciones") && (
        <>
          <ListItemButton
            onClick={() => setEvaluacionMenuOpen(!isEvaluacionMenuOpen)}
            key="evaluaciones"
          >
            <ListItemIcon sx={{ color: "white" }}>
              <MilitaryTechIcon />
            </ListItemIcon>
            <ListItemText primary="Evaluación de desempeño" />
            {isEvaluacionMenuOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse
            in={isEvaluacionMenuOpen}
            timeout="auto"
            unmountOnExit
            key="evaluacionesCollapse"
          >
            <List component="div" disablePadding>
              {menuItems?.evaluaciones?.map((menuItem) => {
                return (
                  <NonDecoratedLink
                    to={menuItem.to}
                    visible={menuItem.visible}
                    key={menuItem.index}
                  >
                    <CustomizedListItemButton
                      selected={selectedIndex === menuItem.index}
                      onClick={(e) =>
                        menuItem.onClick(
                          e,
                          handleListItemClick,
                          setDisplayTreeView
                        )
                      }
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon sx={{ color: "white" }}>
                        {menuItem.icon()}
                      </ListItemIcon>
                      <ListItemText primary={menuItem.name} />
                    </CustomizedListItemButton>
                  </NonDecoratedLink>
                );
              })}
            </List>
          </Collapse>
        </>
      )}
      {displayMenu("administracion") && (
        <>
          <ListItemButton
            onClick={() => setMenuOrganizacionalOpen(!isAdministracionMenuOpen)}
            key="administracion"
          >
            <ListItemIcon sx={{ color: "white" }}>
              <CorporateFareIcon />
            </ListItemIcon>
            <ListItemText primary="Administración" />
            {isAdministracionMenuOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse
            in={isAdministracionMenuOpen}
            timeout="auto"
            unmountOnExit
            key="administracionCollapse"
          >
            <List component="div" disablePadding>
              {menuItems?.administracion?.map((menuItem) => {
                return (
                  <NonDecoratedLink
                    to={menuItem.to}
                    visible={menuItem.visible}
                    key={menuItem.index}
                  >
                    <CustomizedListItemButton
                      selected={selectedIndex === menuItem.index}
                      onClick={(e) =>
                        menuItem.onClick(
                          e,
                          handleListItemClick,
                          setDisplayTreeView
                        )
                      }
                      sx={{ pl: 4 }}
                    >
                      <ListItemIcon sx={{ color: "white" }}>
                        {menuItem.icon()}
                      </ListItemIcon>
                      <ListItemText primary={menuItem.name} />
                    </CustomizedListItemButton>
                  </NonDecoratedLink>
                );
              })}
            </List>
          </Collapse>
        </>
      )}
    </List>
  );
}

export { MainMenu };
