import { HOST } from '../Utils/config'
import { putRequest } from './requests'

async function putObjetivoDesempenoUsuarioIndicador(id, data) {
  return await putRequest(
    `${HOST}/api/v1/objetivosDesempenoUsuarioIndicador/${id}`,
    data
  )
}

export { putObjetivoDesempenoUsuarioIndicador }
