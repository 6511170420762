import React from "react";
import {
  MenuItem, TextField, IconButton, Drawer,
  Button, Checkbox, Grid, Tooltip
} from "@mui/material";
import FilterIcon from "@mui/icons-material/FilterAlt";
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import SyncIcon from '@mui/icons-material/Sync';
import ExpandIcon from '@mui/icons-material/Expand';
import CollapseIcon from '@mui/icons-material/Compress';

export default function Toolbar({
  periodoId, setPeriodoId, periodos, gerencias,
  getOKRs, filters, setFilters
}) {

  const [openFilters, setOpenFilters] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState({});
  const [expandedItems, setExpandedItems] = React.useState([]);

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds);
  };

  const removeAllSelectedItems = () => {
    setSelectedItems({});
    setFilters({ gerencias: [], departamentos: [], puestos: [] });
  };

  const selectAllItems = () => {
    //Se seleccionan todos los elementos
    let newSelectedItems = {};
    let newFilters = { gerencias: [], departamentos: [], puestos: [] };
    gerencias.forEach((gerencia, index) => {
      newSelectedItems[`gerencia-${index}`] = true;
      newFilters.gerencias.push(gerencia);
      gerencia.departamentos.forEach((departamento, index1) => {
        newSelectedItems[`gerencia-${index}-departamento-${index1}`] = true;
        newFilters.departamentos.push({ ...departamento, gerencia: gerencia.gerencia });
        departamento.puestos.forEach((puesto, index2) => {
          newSelectedItems[`gerencia-${index}-departamento-${index1}-puesto-${index2}`] = true;
          newFilters.puestos.push({ ...puesto, departamento: departamento.departamento, gerencia: gerencia.gerencia });
        });
      });
    });
    setFilters(newFilters);
    setSelectedItems(newSelectedItems);
  }

  const expanAllItems = () => {
    let newExpandedItems = [];
    gerencias.forEach((gerencia, index) => {
      newExpandedItems.push(`gerencia-${index}`);
      gerencia.departamentos.forEach((departamento, index1) => {
        newExpandedItems.push(`gerencia-${index}-departamento-${index1}`);
      });
    });
    setExpandedItems(newExpandedItems);
  }

  const collapseAllItems = () => {
    setExpandedItems([]);
  }

  function changeFilters(key, name, items, filters, add = true) {
    return {
      ...filters,
      [key]: add ? [
        ...filters[key], ...items.filter(i => !filters[key].find(f => f[name] == i[name]))
      ] : filters[key].filter((i) => !items.find((i1) => i1[name] === i[name]))
    };
  }

  const handleCheckboxChange = (itemId, isChecked) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = { ...prevSelectedItems };
      newSelectedItems[itemId] = isChecked;
      let _filters = { ...filters };

      const updateParentState = (parentId) => {
        let totalHijos = 0;
        let hijosSeleccionados = 0;

        if (parentId.includes('gerencia') && !parentId.includes('departamento') && !parentId.includes('puesto')) {
          const [gerenciaIndex] = parentId.match(/\d+/g);
          const gerencia = gerencias[gerenciaIndex];

          gerencia.departamentos.forEach((departamento, index1) => {
            totalHijos++;
            if (newSelectedItems[`gerencia-${gerenciaIndex}-departamento-${index1}`]) {
              hijosSeleccionados++;
            }
            departamento.puestos.forEach((puesto, index2) => {
              totalHijos++;
              if (newSelectedItems[`gerencia-${gerenciaIndex}-departamento-${index1}-puesto-${index2}`]) {
                hijosSeleccionados++;
              }
            });
          });
        }

        if (parentId.includes('departamento') && !parentId.includes('puesto')) {
          const [gerenciaIndex, departamentoIndex] = parentId.match(/\d+/g);
          const departamento = gerencias[gerenciaIndex].departamentos[departamentoIndex];
          totalHijos = departamento.puestos.length;
          hijosSeleccionados = departamento.puestos.filter((puesto, index2) => newSelectedItems[`gerencia-${gerenciaIndex}-departamento-${departamentoIndex}-puesto-${index2}`]).length;
        }

        const isIndeterminate = hijosSeleccionados > 0 && hijosSeleccionados < totalHijos;
        const isChecked = hijosSeleccionados === totalHijos;

        if (isIndeterminate) {
          newSelectedItems[parentId] = 'indeterminate';
        } else {
          newSelectedItems[parentId] = isChecked;
        }
      };

      // Si es un padre, selecciona/deselecciona todos sus hijos
      if (itemId.includes('gerencia') && !itemId.includes('departamento') && !itemId.includes('puesto')) {
        gerencias.forEach((gerencia, index) => {
          if (`gerencia-${index}` === itemId) {
            _filters = changeFilters('gerencias', 'gerencia', [gerencia], _filters, isChecked);
            gerencia.departamentos.forEach((departamento, index1) => {
              _filters = changeFilters('departamentos', 'departamento', [{ ...departamento, gerencia: gerencia.gerencia }], _filters, isChecked);
              newSelectedItems[`gerencia-${index}-departamento-${index1}`] = isChecked;
              departamento.puestos.forEach((puesto, index2) => {
                _filters = changeFilters('puestos', 'puesto', [{ ...puesto, departamento: departamento.departamento, gerencia: gerencia.gerencia }], _filters, isChecked);
                newSelectedItems[`gerencia-${index}-departamento-${index1}-puesto-${index2}`] = isChecked;
              });
            });
          }
        });
      }

      // Si es un departamento, selecciona/deselecciona todos sus puestos
      if (itemId.includes('departamento') && !itemId.includes('puesto')) {
        const [gerenciaIndex, departamentoIndex] = itemId.match(/\d+/g);
        const gerencia = gerencias[gerenciaIndex];
        const departamento = gerencias[gerenciaIndex].departamentos[departamentoIndex];
        _filters = changeFilters('departamentos', 'departamento', [{ ...departamento, gerencia: gerencia.gerencia }], _filters, isChecked);
        _filters = changeFilters('gerencias', 'gerencia', [gerencia], _filters, isChecked || _filters.departamentos.some((d) => d.gerencia === gerencia.gerencia));
        departamento.puestos.forEach((puesto, index2) => {
          _filters = changeFilters('puestos', 'puesto', [{ ...puesto, departamento: departamento.departamento, gerencia: gerencia.gerencia }], _filters, isChecked);
          newSelectedItems[`gerencia-${gerenciaIndex}-departamento-${departamentoIndex}-puesto-${index2}`] = isChecked;
        });
      }

      // Si es un puesto, se agrega al filter
      if (itemId.includes('puesto')) {
        const [gerenciaIndex, departamentoIndex, puestoIndex] = itemId.match(/\d+/g);
        const gerencia = gerencias[gerenciaIndex];
        const departamento = gerencias[gerenciaIndex].departamentos[departamentoIndex];
        const puesto = gerencias[gerenciaIndex].departamentos[departamentoIndex].puestos[puestoIndex];
        _filters = changeFilters('puestos', 'puesto', [{ ...puesto, departamento: departamento.departamento, gerencia: gerencia.gerencia }], _filters, isChecked);
        _filters = changeFilters('departamentos', 'departamento', [{ ...departamento, gerencia: gerencia.gerencia }], _filters, isChecked || _filters.puestos.some((p) => p.departamento === departamento.departamento));
        _filters = changeFilters('gerencias', 'gerencia', [gerencia], _filters, isChecked || _filters.departamentos.some((d) => d.gerencia === gerencia.gerencia));
      }

      // Update parent states
      if (itemId.includes('puesto')) {
        const [gerenciaIndex, departamentoIndex] = itemId.match(/\d+/g);
        updateParentState(`gerencia-${gerenciaIndex}-departamento-${departamentoIndex}`);
        updateParentState(`gerencia-${gerenciaIndex}`);
      } else if (itemId.includes('departamento')) {
        const [gerenciaIndex] = itemId.match(/\d+/g);
        updateParentState(`gerencia-${gerenciaIndex}`);
      }

      setFilters(_filters);

      return newSelectedItems;
    });
  };

  const isIndeterminate = (itemId) => {
    if (itemId.includes('gerencia') && !itemId.includes('departamento') && !itemId.includes('puesto')) {
      const [gerenciaIndex] = itemId.match(/\d+/g);
      const gerencia = gerencias[gerenciaIndex];
      let totalHijos = 0;
      let hijosSeleccionados = 0;

      gerencia.departamentos.forEach((departamento, index1) => {
        totalHijos++;
        if (selectedItems[`gerencia-${gerenciaIndex}-departamento-${index1}`]) {
          hijosSeleccionados++;
        }
        departamento.puestos.forEach((puesto, index2) => {
          totalHijos++;
          if (selectedItems[`gerencia-${gerenciaIndex}-departamento-${index1}-puesto-${index2}`]) {
            hijosSeleccionados++;
          }
        });
      });

      return hijosSeleccionados > 0 && hijosSeleccionados < totalHijos;
    }

    if (itemId.includes('departamento') && !itemId.includes('puesto')) {
      const [gerenciaIndex, departamentoIndex] = itemId.match(/\d+/g);
      const departamento = gerencias[gerenciaIndex].departamentos[departamentoIndex];
      const totalPuestos = departamento.puestos.length;
      const puestosSeleccionados = departamento.puestos.filter((puesto, index2) => selectedItems[`gerencia-${gerenciaIndex}-departamento-${departamentoIndex}-puesto-${index2}`]).length;

      return puestosSeleccionados > 0 && puestosSeleccionados < totalPuestos;
    }

    return false;
  };

  return (
    <section
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '20px'
      }}
    >
      <TextField
        id="periodo"
        select
        label="Seleccione un periodo"
        value={periodoId}
        onChange={(e) => setPeriodoId(e.target.value)}
        required
        sx={{
          textAlign: 'left',
          width: '500px',
          float: 'left'
        }}
      >
        {periodos.map((periodo) => (
          <MenuItem key={periodo.id} value={periodo.id}>
            {periodo.nombre}
          </MenuItem>
        ))}
      </TextField>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end'}} >
        {/* Refresh */}
        <IconButton
          title="Actualizar OKRs"
          onClick={() => getOKRs(periodoId, filters)}
          size="large"
        >
          <SyncIcon fontSize="inherit" />
        </IconButton>
        {/* Abrir filtros */}
        <IconButton
          title="Filtros"
          onClick={() => setOpenFilters(true)}
          size="large"
        >
          <FilterIcon fontSize="inherit" />
        </IconButton>
      </div>
      {/* Filtros */}
      <Drawer
        anchor="right"
        open={openFilters}
        onClose={() => setOpenFilters(false)}
      >
        <Grid container spacing={2} padding={2}>
          <Grid item xs>
            <Tooltip title="Seleccionar todos los elementos">
              <Button
                variant="outlined"
                onClick={() => {
                  selectAllItems()
                }}
                style={{ width: '100%' }}
                startIcon={<DoneAllIcon />}
              />
            </Tooltip>
          </Grid>
          <Grid item xs>
            <Tooltip title="Deseleccionar todos los elementos">
              <Button
                variant="outlined"
                onClick={() => {
                  removeAllSelectedItems()
                }}
                style={{ width: '100%' }}
                startIcon={<RemoveDoneIcon />}
              />
            </Tooltip>
          </Grid>
          <Grid item xs>
            <Tooltip title="Expandir todos los elementos">
              <Button
                variant="outlined"
                onClick={() => {
                  expanAllItems()
                }}
                style={{ width: '100%' }}
                startIcon={<ExpandIcon />}
              />
            </Tooltip>
          </Grid>
          <Grid item xs>
            <Tooltip title="Contraer todos los elementos">
              <Button
                variant="outlined"
                onClick={() => {
                  collapseAllItems()
                }}
                style={{ width: '100%' }}
                startIcon={<CollapseIcon />}
              />
            </Tooltip>
          </Grid>
        </Grid>
        <SimpleTreeView
          expandedItems={expandedItems}
          onExpandedItemsChange={handleExpandedItemsChange}
          sx={{ height: '96%', overflow: 'auto', padding: '1em' }}
        >
          {gerencias?.map((gerencia, index) => {
            return (
              <TreeItem
                key={`gerencia-${index}`}
                itemId={`gerencia-${index}`}
                label={
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    {gerencia.gerencia}
                    <Checkbox
                      onClick={(e) => e.stopPropagation()}
                      checked={!!selectedItems[`gerencia-${index}`]}
                      indeterminate={isIndeterminate(`gerencia-${index}`)}
                      onChange={(e) => handleCheckboxChange(`gerencia-${index}`, e.target.checked)}
                    />
                  </div>
                }
              >
                {gerencia?.departamentos?.map((departamento, index1) => {
                  return (
                    <TreeItem
                      key={`gerencia-${index}-departamento-${index1}`}
                      itemId={`gerencia-${index}-departamento-${index1}`}
                      label={
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                          {departamento.departamento}
                          <Checkbox
                            onClick={(e) => e.stopPropagation()}
                            checked={!!selectedItems[`gerencia-${index}-departamento-${index1}`]}
                            indeterminate={isIndeterminate(`gerencia-${index}-departamento-${index1}`)}
                            onChange={(e) => handleCheckboxChange(`gerencia-${index}-departamento-${index1}`, e.target.checked)}
                          />
                        </div>
                      }
                    >
                      {departamento?.puestos?.map((puesto, index2) => {
                        return (
                          <TreeItem
                            key={`gerencia-${index}-departamento-${index1}-puesto-${index2}`}
                            itemId={`gerencia-${index}-departamento-${index1}-puesto-${index2}`}
                            label={
                              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                                {puesto.puesto}
                                <Checkbox
                                  onClick={(e) => e.stopPropagation()}
                                  checked={!!selectedItems[`gerencia-${index}-departamento-${index1}-puesto-${index2}`]}
                                  onChange={(e) => handleCheckboxChange(`gerencia-${index}-departamento-${index1}-puesto-${index2}`, e.target.checked)}
                                />
                              </div>
                            }
                          />
                        )
                      })}
                    </TreeItem>
                  )
                })}
              </TreeItem>
            )
          })}
        </SimpleTreeView>
        <Button
          variant="outlined"
          onClick={() => {
            getOKRs(periodoId, filters);
            setOpenFilters(false);
          }}
        >
          Aplicar filtros
        </Button>
      </Drawer>
    </section>
  )
}
