import React, { useState } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import style from './tree.module.css'
import { IconButton } from '@mui/material'

export function Tree({
  level = 0,
  onNodeClick = () => {},
  onDoubleClick = () => {},
  fontColor = 'white',
  iconsHash = {},
  onDrop = () => {},
  estructuraArbol = [],
  draggable = false,
  showAgrupaciones = true,
  showIndicadoresAgrupados = false,
  defaultSelectedKey = null
}) {
  const [selectedKey, setSelectedKey] = useState(null)

  React.useEffect(() => {
    if (defaultSelectedKey) setSelectedKey(defaultSelectedKey)
  }, [defaultSelectedKey])

  return (
    <div style={{ color: fontColor }}>
      <ul>
        {estructuraArbol.map((mainNode) => (
          <TreeNode
            node={mainNode}
            key={mainNode.key}
            setSelectedKey={setSelectedKey}
            selectedKey={selectedKey}
            level={level}
            onNodeClick={onNodeClick}
            onDoubleClick={onDoubleClick}
            onDrop={onDrop}
            iconsHash={iconsHash}
            draggable={draggable}
            showAgrupaciones={showAgrupaciones}
            showIndicadoresAgrupados={showIndicadoresAgrupados}
          />
        ))}
      </ul>
    </div>
  )
}

function TreeNode({
  node,
  setSelectedKey,
  selectedKey,
  level,
  onNodeClick = () => {},
  onDoubleClick = () => {},
  onDrop = () => {},
  iconsHash = {},
  draggable = false,
  showAgrupaciones = true,
  showIndicadoresAgrupados = false
}) {
  const [childVisible, setChildVisiblity] = useState(false)
  const [isTarget, setIsTarget] = useState(false)
  const hasChild = node.items?.length > 0 ? true : false

  function handleChildVisiblity(e) {
    setChildVisiblity((prev) => !prev)
    e.stopPropagation()
  }

  function handleNodeClick(e) {
    setSelectedKey(node.key)
    onNodeClick(node)
  }

  function onDragStart(e) {
    e.dataTransfer.setData('node', JSON.stringify(node))
    setIsTarget(true)
  }

  function onDragEnter(e) {
    e.preventDefault()
    setIsTarget(true)
  }

  function onDragLeave(e) {
    e.preventDefault()
    setIsTarget(false)
  }

  function handleOnDragOver(e) {
    e.preventDefault()
    e.stopPropagation()
    if (!isTarget) setIsTarget(true)
  }

  function handleOnDrop(e) {
    e.preventDefault()
    e.stopPropagation()
    setIsTarget(false)
    const data = e.dataTransfer.getData('node')
    const nodo = JSON.parse(data)
    onDrop(nodo, node)
  }

  function showItem() {
    const key = node.key.split('-')[0]
    if (key === 'A')
      return showAgrupaciones
    if (key === 'IA')
      return showIndicadoresAgrupados
    return true
  }

  
  if (showItem()) {
    return (
      <>
        <li
          draggable={draggable}
          className={`${style['tree-node']} ${
            node.key === selectedKey ? style['selected'] : ''
          }`}
          onDrag={(e) => console.log('Drag')}
          onClick={handleNodeClick}
          onDoubleClick={() => onDoubleClick(node)}
          style={{
            paddingLeft: `${level * 10}px`,
            marginLeft: '10px',
            borderTop: isTarget ? '1px solid #1976d2' : '1px solid transparent'
          }}
          onDragStart={onDragStart}
          onDragOver={handleOnDragOver}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={handleOnDrop}
          onDragEnd={() => setIsTarget(false)}
        >
          <div
            onClick={handleChildVisiblity}
            style={{ marginRight: '2px' }}
            className={`${
              hasChild
                ? style['tree-toggler-visible']
                : style['tree-toggler-hidden']
            }
                          ${
                            childVisible
                              ? style['tree-toggler-open']
                              : style['tree-toggler-closed']
                          }`}
          >
            <IconButton
              aria-label="refresh"
              sx={{ color: 'inherit', padding: '5px' }}
            >
              <ArrowForwardIosIcon sx={{ fontSize: '0.8rem' }} />
            </IconButton>
          </div>
  
          <div className={style['content']}>
            {iconsHash[node.tipoNodoId]}
            <p className={style.title}>
              {node.codigo ? `${node.codigo} - ` : ''} {node.nombre}
            </p>
          </div>
        </li>
        {/* Codigo para que se rendericen los hijos*/}
        {hasChild && childVisible && (
          <div className="d-tree-content">
            <ul
              className="d-flex d-tree-container flex-column"
              style={{ listStyle: 'none' }}
            >
              {node.items.map((childNode) => (
                <TreeNode
                  node={childNode}
                  key={childNode.id}
                  setSelectedKey={setSelectedKey}
                  selectedKey={selectedKey}
                  onNodeClick={onNodeClick}
                  onDoubleClick={onDoubleClick}
                  level={level + 1}
                  onDrop={onDrop}
                  iconsHash={iconsHash}
                  draggable={draggable}
                  showAgrupaciones={showAgrupaciones}
                  showIndicadoresAgrupados={showIndicadoresAgrupados}
                />
              ))}
            </ul>
          </div>
        )}
      </>
    )
  }
  return <div/>

}
