import React, { useEffect, useState } from "react";
import { CustomInputNode } from "../../Nodes/customInputNode";
import { CustomOutputNode } from "../../Nodes/customOutputNode";
import { CustomProcessNode } from "../../Nodes/customProcessNode";
import FloatingMenuButton from "./floating-button-menu";
import { createAssociateNode } from "../../Utils/structureMaker";
import Modal from "../../general-components/modal";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import NodeForm from "../../Nodes/node-form";
import { nodeType, sipocNodeType } from "../../Utils/node-types";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import CloseFullscreenOutlinedIcon from "@mui/icons-material/CloseFullscreenOutlined";
import ReactFlow, {
  Background,
  ControlButton,
  Controls,
  ReactFlowProvider,
} from "react-flow-renderer";
import { useNavigate } from "react-router-dom";
import TreeViewSipoc from "../../TreeView/treeViewSipoc";
import ToggleButtons from "./toogle-button";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { createNodes, createProcessesSipoc } from "../Diagrams/sipocCreator";
import ListDelete from "../../general-components/list-delete";
import { NodoContext } from "../../Context/nodoContext";
import { getNodos } from "../../Api/nodos.api";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import { EdgeWithDeleteButton } from "../../general-components/edgeWithDeleteButton";
import canvas from "./style.module.css";

const ACTIVIDADES_ID = 3;
const PROCESOS_ID = 2;

const HorizontalFlow = () => {
  const [elements, setElements] = useState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [showAssociateNodeModal, setShowAssociateNodeModal] = useState(false);
  const [showNodeFormModal, setShowNodeFormModal] = useState(false);
  const [nodeList, setActividadesList] = useState([]);
  const [tipoNodoSipoc, setTipoNodoSipoc] = useState(null);
  const [nodeTypeId, setNodeTypeId] = useState(null);
  const [newAssociateNodeObj, setNewAssociateNodeObj] = useState(null);
  const [newAssociateNodeId, setNewAssociateNodeId] = useState(null);
  const [newAssociateNodeName, setNewAssociateNodeName] = useState("");
  const [activityview, setActivityView] = React.useState("tree");
  const [isInfoDisplayed, setInfoDisplayed] = useState(false);
  const [mostrarUltimosResultados, setMostrarUltimosResultados] =
    React.useState(false);
  const [height, setHeight] = useState(10);
  const navigate = useNavigate();
  const [inputOutputs, setInputOutputs] = useState([]);
  const { nodoActual, reloadNodoActual } = React.useContext(NodoContext);

  const onLoad = (reactFlowInstance) => {
    setReactFlowInstance(reactFlowInstance);
    setTimeout(() => {
      reactFlowInstance.fitView();
    }, 450);
  };

  const onNodeDoubleClick = (event, node) => {
    navigate(`/home/procesos/${node.data.nodo.id}`);
  };

  useEffect(() => {
    if (nodoActual.tipoNodoId === PROCESOS_ID) {
      createProcessesSipoc(nodoActual, isInfoDisplayed).then((initialNodes) => {
        console.log("initialNodes ", initialNodes);
        setElements(initialNodes);
      });
    } else {
      const initialNodes = createNodes(
        nodoActual,
        isInfoDisplayed,
        mostrarUltimosResultados
      );
      console.log("initialNodes ", initialNodes);
      setElements(initialNodes);
    }
  }, [nodoActual, isInfoDisplayed, mostrarUltimosResultados]);

  useEffect(() => {
    if (reactFlowInstance) {
      setTimeout(() => {
        reactFlowInstance.fitView();
      }, 50);
    }
  }, [elements, isInfoDisplayed]);

  useEffect(() => {
    if (newAssociateNodeObj !== null) {
      setNewAssociateNodeId(newAssociateNodeObj.id);
    } else {
      setNewAssociateNodeId(null);
    }
  }, [newAssociateNodeObj]);

  const handleClose = () => {
    setShowAssociateNodeModal(false);
    setNewAssociateNodeObj(null);
    setNewAssociateNodeId(null);
    clearInputOutput();
  };

  const handleCloseNodeFormModal = () => {
    setShowNodeFormModal(false);
    setTimeout(500, () => {
      setNodeTypeId(null);
    });
  };

  const addSupplierModal = async () => {
    setNewAssociateNodeObj(null);
    setNewAssociateNodeId(null);
    setTipoNodoSipoc(sipocNodeType.supplier);
    setShowAssociateNodeModal(true);
    loadActividadesList();
  };

  const addCustomerModal = async () => {
    setNewAssociateNodeId(null);
    setNewAssociateNodeObj(null);
    await loadActividadesList();
    setTipoNodoSipoc(sipocNodeType.customer);
    setShowAssociateNodeModal(true);
  };

  const addProcessModal = async () => {
    setNodeTypeId(nodeType.proceso);
    setShowNodeFormModal(true);
  };

  const addActivityModal = async () => {
    setNodeTypeId(nodeType.actividad);
    setShowNodeFormModal(true);
  };

  const loadActividadesList = async () => {
    const response = await getNodos();
    if (response.status === 200) {
      const nodes = response.body;
      let activities = nodes.filter((nodo) => {
        return nodo.tipoNodoId === ACTIVIDADES_ID;
      });

      activities = activities.map((item, index) => {
        return { label: item.codigo + " - " + item.nombre, id: item.id };
      });

      setActividadesList(activities);
    }
    //only activities
  };

  const saveAssociateNode = async () => {
    if (newAssociateNodeId === null || newAssociateNodeId === undefined) {
      toast.warn("Selecciona una actividad");
      return;
    }

    // if (
    //   editorValue === null ||
    //   editorValue === undefined ||
    //   editorValue === ""
    // ) {
    //   toast.warn("Debes llenar el campo de entradas / salidas");
    //   return;
    // }

    const data = {
      nodoId: nodoActual.id,
      nodoRelacionadoId: newAssociateNodeId,
      tipoNodoSipocId: tipoNodoSipoc,
      //info: editorValue,
      inputOutputs: inputOutputs,
    };

    const res = await createAssociateNode(data);
    if (res.status === 201) {
      toast.success(res.body);
      loadNodeData(nodoActual.id);
      handleClose();
      reloadNodoActual(nodoActual.id);
    } else if (res.status === 300) {
      toast.warning(res.body);
    }
  };

  const nodeTypes = {
    customProcess: CustomProcessNode,
    customInput: CustomInputNode,
    customOutput: CustomOutputNode,
  };

  const edgeTypes = {
    buttonedge: EdgeWithDeleteButton,
  };


  const loadNodeData = async (nodeId) => {
    navigate(`/home/procesos/${nodeId}`);
  };

  const setView = (view) => {
    setActivityView(view);
  };

  const addInputOutput = (item) => {
    let itemsCopy = [...inputOutputs];
    itemsCopy.push(item);
    setInputOutputs(itemsCopy);
  };

  const removeInputOutput = (item) => {
    let newArr = inputOutputs.filter((x) => {
      return x !== item;
    });

    setInputOutputs(newArr);
  };

  const clearInputOutput = () => {
    setInputOutputs([]);
  };

  function changeMostrarUltimosResultados() {
    if (mostrarUltimosResultados) {
      setMostrarUltimosResultados(false);
    } else {
      setMostrarUltimosResultados(true);
      setInfoDisplayed(false);
    }
  }

  function changeInfoDisplayed() {
    if (isInfoDisplayed) {
      setInfoDisplayed(false);
    } else {
      setInfoDisplayed(true);
      setMostrarUltimosResultados(false);
    }
  }

  return (
    <div>
      {nodoActual.sipoc ? (
        <div className={canvas.flow}>
          {elements && (
            <ReactFlowProvider>
              <ReactFlow
                elements={elements}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onNodeDoubleClick={onNodeDoubleClick}
                onLoad={onLoad}
                elementsSelectable={true}
                nodesConnectable={false}
                nodesDraggable={false}
              >
                <Controls style={{ top: 10, height: "50px" }}>
                  {!isInfoDisplayed && (
                    <ControlButton onClick={changeInfoDisplayed}>
                      <CloseFullscreenOutlinedIcon />
                    </ControlButton>
                  )}
                  {isInfoDisplayed && (
                    <ControlButton onClick={changeInfoDisplayed}>
                      <OpenInFullOutlinedIcon />
                    </ControlButton>
                  )}
                  {!mostrarUltimosResultados &&
                    nodoActual.tipoNodoId !== PROCESOS_ID && (
                      <ControlButton onClick={changeMostrarUltimosResultados}>
                        <FormatListNumberedIcon />
                      </ControlButton>
                    )}
                  {mostrarUltimosResultados &&
                    nodoActual.tipoNodoId !== PROCESOS_ID && (
                      <ControlButton onClick={changeMostrarUltimosResultados}>
                        <FilterListOffIcon />
                      </ControlButton>
                    )}
                </Controls>
                <Background color="#aaa" gap={16} />
              </ReactFlow>
            </ReactFlowProvider>
          )}
        </div>
      ) : (
        <div>
          <p>No se encontró información de Nodos Sipoc</p>
        </div>
      )}

      {nodoActual.permisos.C == 1 && (
        <FloatingMenuButton
          addSupplier={addSupplierModal}
          addCustomer={addCustomerModal}
          addProcess={addProcessModal}
          addActivity={addActivityModal}
          tpNodo={nodoActual.tipoNodoId}
        />
      )}


      <Modal
        open={showAssociateNodeModal}
        handleClose={handleClose}
        title={`Nuevo ${tipoNodoSipoc === sipocNodeType.supplier ? "proveedor" : "cliente"
          }`}
      >
        <div style={{ maxHeight: "600px", overflow: "scroll" }}>
          <Grid container spacing={3}>
            {!newAssociateNodeId && (
              <Grid item xs={12} lg={12} sm={12} style={{ paddingTop: 60 }}>
                <label>No se ha seleccionado la actividad</label>
              </Grid>
            )}

            {newAssociateNodeId && (
              <Grid item xs={12} lg={12} sm={12} style={{ paddingTop: 60 }}>
                <label>
                  Actividad Seleccionada <b> {newAssociateNodeName}</b>
                </label>
              </Grid>
            )}

            <Grid item xs={12} lg={12} sm={12}>
              <ToggleButtons setView={setView} />
            </Grid>
            {activityview === "search" && (
              <Grid item xs={12} lg={12} sm={12}>
                <Autocomplete
                  value={newAssociateNodeObj}
                  onChange={(event, newValue) => {
                    setNewAssociateNodeObj(newValue);
                  }}
                  id="AssociateNode"
                  options={nodeList}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Actividades" />
                  )}
                />
              </Grid>
            )}
            {activityview === "tree" && (
              <Grid
                item
                xs={12}
                lg={12}
                sm={12}
                style={{ backgroundColor: "#fff", maxHeight: 300 }}
              >
                <PerfectScrollbar>
                  <TreeViewSipoc
                    setSelectedNodeId={setNewAssociateNodeId}
                    setSelectedNodeName={setNewAssociateNodeName}
                  />
                </PerfectScrollbar>
              </Grid>
            )}
            {/* <Grid item xs={12} lg={12} sm={12}>
              <CkEditor
                value={editorValue}
                setValue={setValue}
                titulo={
                  tipoNodoSipoc === sipocNodeType.supplier
                    ? "Entradas"
                    : "Salidas"
                }
              />
            </Grid> */}

            <Grid item xs={12} lg={12} sm={12}>
              <h4>
                {tipoNodoSipoc === sipocNodeType.supplier
                  ? "Entradas"
                  : "Salidas"}
              </h4>
              <ListDelete
                items={inputOutputs}
                fnAdd={addInputOutput}
                fnDelete={removeInputOutput}
              />
            </Grid>
            <Grid item xs={12} lg={12} sm={12}>
              <Button
                variant="contained"
                style={{ backgroundColor: "#69bd4b", color: "white" }}
                onClick={() => saveAssociateNode()}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>

      {/* modal para crear nuevo nodo */}
      <Modal
        open={showNodeFormModal}
        handleClose={handleCloseNodeFormModal}
        title={`${nodeTypeId === nodeType.proceso ? "Nuevo proceso" : "Nueva actividad"
          }`}
      >
        <div>
          <NodeForm
            nodeParentId={nodoActual.id}
            nodeTypeId={nodeTypeId}
            closeModalFn={handleCloseNodeFormModal}
          />
        </div>
      </Modal>
    </div>
  );
};

export { HorizontalFlow };
