import React from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Button, Checkbox, MenuItem, TextField } from "@mui/material";
import { getPeriodos } from "../Api/periodo.api";
import { NodoContext } from "../Context/nodoContext";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  FilterPanel,
  FilterRow,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Selection,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { Export } from "devextreme-react/chart";
import { useDownloadExcel } from "../Hooks/useDownloadExcel";
import themes from "devextreme/ui/themes";
import {
  getEvaluacionIndicadoresAsignados,
  getEvaluacionIndicadoresDesempenoAsignados,
} from "../Api/evaluacionDesempeno.api";
import { ConditionalRendering } from "../general-components/conditionalRendering";
import { AuthContext } from "../Context/authContext";
import { Link } from "react-router-dom";
import { PeriodoContext } from "../Context/periodoContext";

export default function IndicadoresAsignados() {
  const [indicadoresAsignados, setIndicadoresAsignados] = React.useState([]);
  const [periodos, setPeriodos] = React.useState([]);
  const [mostrarSoloDesempeno, setMostrarSoloDesempeno] = React.useState(false);
  const [tieneEvaluacion, setTieneEvaluacion] = React.useState(true);  
  const [periodoId, setPeriodoId] = React.useState("");
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext);
  const { getSelectedUserID, getSelectedUserName, selectedUser } =
    React.useContext(AuthContext);
  const { getSelected } = React.useContext(PeriodoContext);

  useEffect(() => {
    updatePeriodos();
  }, []);

  useEffect(() => {
    setPeriodoId("");
    setTieneEvaluacion(true);
    setMostrarSoloDesempeno(false);
    setIndicadoresAsignados([]);
  }, [selectedUser]);

  useEffect(() => {    
    setMostrarSoloDesempeno(false);
    setTieneEvaluacion(true);
    if (periodoId !== "") {      
      reloadIndicadores();
    }
  }, [periodoId]);

  useEffect(() => {
    // Si no hay un periodo seleccionado, toma el seleccionado global de la App
    if (!periodoId) {
      if (getSelected().length === 1) {
        setPeriodoId(getSelected()[0]);
      }
    }
  }, [periodos]);

  useEffect(() => {    
    reloadIndicadores();
  }, [mostrarSoloDesempeno]);

  function reloadIndicadores() {
    if (!periodoId) return;
    if (mostrarSoloDesempeno) {      
      updateIndicadoresDesemepenoAsignados();
    } else {      
      updateIndicadoresAsignados();
    }
  }

  function updateIndicadoresAsignados() {
    setLoading(true);
    setLoadingMessage("Cargando...");
    getEvaluacionIndicadoresAsignados(periodoId, getSelectedUserID()).then(
      (response) => {
        setLoading(false);
        if (response.status === 200) {          
          setIndicadoresAsignados(response.body);
        } else {
          toast.error("No se pudieron recuperar los indicadores asignados");
        }
      }
    );
  }

  function updateIndicadoresDesemepenoAsignados() {
    setLoading(true);
    setLoadingMessage("Cargando...");
    getEvaluacionIndicadoresDesempenoAsignados(
      periodoId,
      getSelectedUserID()
    ).then((response) => {
      setLoading(false);
      if (response.status === 200) { 
        setIndicadoresAsignados(response.body); 
      } else {
        toast.error("No se pudieron recuperar los indicadores asignados");
      }
    });
  }

  function updatePeriodos() {
    setLoading(true);
    setLoadingMessage("Cargando...");
    getPeriodos().then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setPeriodos(response.body);
      } else {
        toast.error("Error al cargar los roles");
      }
    });
  }    

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <section
        style={{
          fontSize: "30px",
          textAlign: "left",
          marginLeft: "20px",
          marginTop: "20px",
        }}
      >
        <b>
          Indicadores Asignados: {getSelectedUserID()} - {getSelectedUserName()}
        </b>
      </section>
      <section
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "20px",
        }}
      >
        <TextField
          id="periodo"
          select
          label="Seleccione un periodo"
          value={periodoId}
          onChange={(e) => setPeriodoId(e.target.value)}
          required
          sx={{
            textAlign: "left",
            width: "500px",
            float: "left",
          }}
        >
          {periodos.map((periodo) => (
            <MenuItem key={periodo.id} value={periodo.id}>
              {periodo.nombre}
            </MenuItem>
          ))}
        </TextField>
        <ConditionalRendering condition={tieneEvaluacion}>
          <section
            style={{ textAlign: "left", display: "flex", alignItems: "center" }}
          >
            <Checkbox
              checked={mostrarSoloDesempeno}
              onClick={() => setMostrarSoloDesempeno(!mostrarSoloDesempeno)}
            />
            <b>Mostrar solo indicadores de medición de desempeño personal</b>
          </section>
        </ConditionalRendering>
      </section>
      <section style={{ margin: "0px 40px" }}>
        <IndicadoresAsignadosTable
          dataSource={indicadoresAsignados}
          setDatasource={setIndicadoresAsignados}
          mostrarSoloDesempeno={mostrarSoloDesempeno}
          userId={getSelectedUserID()}
        />
      </section>      
    </div>
  );
}

export function IndicadoresAsignadosTable({
  dataSource,
  setDatasource,
  mostrarSoloDesempeno,
  userId,
}) {
  const { onExporting } = useDownloadExcel(); 

  return (
    <DataGrid
      dataSource={dataSource}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnResizingMode="nextColumn"
      onExporting={onExporting}
      // defaultColumns={columns}
      // rowRender={plantillaFila}
    >
      <GroupPanel visible={true} />
      <ColumnChooser enabled={true} />
      <Selection
        mode="multiple"
        selectAllMode={"allMode"}
        showCheckBoxesMode={
          themes.current().startsWith("material") ? "always" : "onClick"
        }
      />

      <Column dataField="id" caption="id" dataType="number" visible={false} />
      <Column
        dataField="indicador"
        caption="Indicador"
        dataType="string"
        visible={true}
        cellRender={(rowInfo) => (
          <Link
            to={`/home/resultadosIndividuales?raciId=${rowInfo.data.tiporaciId}&indicadorId=${rowInfo.data.indicadorId}`}
          >
            {rowInfo.data.indicador}
          </Link>
        )}
      />
      <Column
        dataField="nodo"
        caption="Proceso/Actividad"
        dataType="string"
        visible={true}
        cellRender={(rowInfo) => (
          <Link to={`/home/procesos/${rowInfo.data.nodoId}`}>
            {rowInfo.data.nodo}
          </Link>
        )}
      />
      <Column
        dataField="tiporaci"
        caption="Responsabilidad"
        dataType="string"
        visible={true}
      />
      <Column
        dataField="frecuencia"
        caption="Frecuencia"
        dataType="string"
        visible={true}
      />
      <Column
        dataField="objetivoDesempeno"
        caption="Objetivo de Desempeño"
        dataType="string"
        visible={true}
        groupIndex={0}
      />
      <Column
        dataField="meta"
        caption="Meta"
        dataType="number"
        visible={true}
      />
      <Column
        dataField="resultado"
        caption="Resultado"
        dataType="number"
        visible={true}
      />

      <Column
        dataField="resultadoPorcentual"
        caption="Resultado Porcentual (100%)"
        dataType="number"
        visible={mostrarSoloDesempeno}
        cellRender={(rowInfo) => {
          const { resultadoPorcentual } = rowInfo.data;
          if (resultadoPorcentual !== null) {
            if (typeof resultadoPorcentual === "number") {
              return `${resultadoPorcentual.toFixed(2)}%`;
            }
            return `${parseFloat(resultadoPorcentual).toFixed(2)}%`;
          }
          return "";
        }}
      />
      <Column
        dataField="peso"
        caption="Peso"
        dataType="number"
        // visible={mostrarSoloDesempeno}        
      />
      <Column
        dataField="resultadoPonderado"
        caption="Resultado Obtenido"
        dataType="number"
        visible={mostrarSoloDesempeno}
        cellRender={(rowInfo) => {
          const { resultadoPonderado } = rowInfo.data
          if (resultadoPonderado !== null) {
            if (typeof resultadoPonderado === 'number') {
              return `${resultadoPonderado.toFixed(2)}%`
            }
            return `${parseFloat(resultadoPonderado).toFixed(2)}%`
          }
          return ''
        }}         
      />

      <FilterRow visible={true} />
      <FilterPanel visible={false} />
      {/* <FilterBuilderPopup position={filterBuilderPopupPosition} /> */}
      <HeaderFilter visible={true} />
      {/* <Scrolling mode="infinite" /> */}

      {/* <GroupPanel visible={true} /> */}
      {/* <SearchPanel visible={true} highlightCaseSensitive={true} /> */}
      {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}
      <Paging defaultPageSize={50} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[50, 100, 150, 200]}
        showInfo={true}
      />
      <Export enabled={true} allowExportSelectedData={true} />
      <Summary>
        {/* <TotalItem column="peso" summaryType="sum" />
          <GroupItem column="peso" summaryType="sum" alignByColumn={true} /> */}
        <TotalItem
          column="peso"
          summaryType="sum"
          valueFormat="number"
          customizeText={(data) => `${data.value.toFixed(2)}%`}
        />
        <GroupItem
          column="peso"
          summaryType="sum"
          valueFormat="number"
          customizeText={(data) => `${data.value.toFixed(2)}%`}
          alignByColumn={true}
        />
        <TotalItem
          column="resultadoPonderado"
          summaryType="sum"
          valueFormat="number"
          customizeText={(data) => `${data.value.toFixed(2)}%`}
        />
        <GroupItem
          column="resultadoPonderado"
          summaryType="sum"
          valueFormat="number"
          customizeText={(data) => `${data.value.toFixed(2)}%`}
          alignByColumn={true}
        />
        {/* <TotalItem
            column="Total"
            summaryType="sum"
            valueFormat="currency"
            customizeText={(data) => `$${data.value.toLocaleString("en")}`}
          />
          <GroupItem
            column="Total"
            summaryType="sum"
            valueFormat="currency"
            howInGroupFooter={false}
            alignByColumn={true}
            customizeText={(data) => `$${data.value.toLocaleString("en")}`}
          /> */}
      </Summary>
    </DataGrid>
  );
}
