import { HOST } from "../Utils/config";
import { getRequest, postRequest, putRequest, deleteRequest } from "./requests";

async function getPilares() {
  return await getRequest(`${HOST}/api/v1/objetivosDesempeno`);
}

async function postPilar(data) {
  return await postRequest(`${HOST}/api/v1/objetivosDesempeno`, data);
}

async function putPilar(data) {
  return await putRequest(`${HOST}/api/v1/objetivosDesempeno`, data);
}

async function deletePilar(data) {
  return await deleteRequest(`${HOST}/api/v1/objetivosDesempeno`, data);
}

export { getPilares, postPilar, putPilar, deletePilar };