import { HOST } from "../Utils/config";
import { getRequest } from "./requests";

async function getBitacoraItems(id) {
  const data = await getRequest(`${HOST}/api/v1/bitacora/${id}`);  
  if(data.status==200){
    return data.body;
  }else{
    return [];
  }
}

async function getAllBitacoraItems() {
  const data = await getRequest(`${HOST}/api/v1/bitacora`);  
  if(data.status==200){
    return data.body;
  }else{
    return [];
  }
}

export { getBitacoraItems, getAllBitacoraItems };
