import React from 'react';
import { Alert, AlertTitle, Step, StepButton, Stepper } from '@mui/material';

const steps = ['Objetivos e Indicadores', 'Competencias', 'Finalizada'];

const EstadosEvaluacion = ({ estados }) => {

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [tooltipOpen, setTooltipOpen] = React.useState(-1);

  React.useEffect(() => {
    if (estados) {
      marcarEstado();
      marcarEstadoActivo();
    }
  }, [estados]);

  const marcarEstado = () => {
    setCompleted({
      0: estados.etapaIndicadores,
      1: estados.etapaCompetencias,
      2: estados.estado === 4
    });
  }

  const marcarEstadoActivo = () => {
    setActiveStep(0);
    if (estados.evaluaciones || estados.feedback || estados.etapaIndicadores)
      setActiveStep(1);
    if (estados.etapaIndicadores && estados.etapaCompetencias)
      setActiveStep(2);
  }

  const getTarea = (index) => {
    if (index === 0) {
      if (estados.resultados && estados.evidencias) return '2/2 Finalizada'
      if (estados.resultados) return '1/2 Evidencias pendientes'
      return '0/2 Resultados pendientes'
    } else if (index === 1) {
      if (estados.evaluaciones && estados.feedback) return '2/2 Finalizada'
      if (estados.evaluaciones) return '1/2 Retroalimentación pendiente'
      return '0/2 Evaluación pendiente'
    } else {
      if (estados.estado === 4) return '1/1 Evalución cerrada'
      return '0/1 Pendiente el cierre de la evaluación'
    }
  }

  return (
    <div style={{ marginBottom: '2.5rem' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step
            key={label}
            completed={completed[index]}
            active={index <= activeStep}
            sx={{
              '& .MuiStepLabel-root .Mui-completed': {
                color: 'success.main', // circle color (COMPLETED)
              },
            }}
          >
            <StepButton
              color="inherit"
              // onClick={() => setTooltipOpen(index)}
              onMouseOver={() => setTooltipOpen(index)}
              onMouseOut={() => setTooltipOpen(-1)}
            >
              {label}
            </StepButton>
            {(index <= activeStep) && (
              <div style={{ display: `${(tooltipOpen === index) ? 'flex' : 'none'}`, position: 'absolute' }}>
                <Alert severity="info">{`Paso ${getTarea(index)}`}</Alert>
              </div>
            )}
          </Step>
        ))}
      </Stepper>
    </div>
  )
}

export default EstadosEvaluacion;