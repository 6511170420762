import Modal from "../general-components/modal";
import React from "react";
import { PeriodosForm } from "./periodoForm";


function PeriodosModal({
  open,
  setOpen,
  updatePeriodos,
  periodoActivo,
}) {
  function handleCloseModal() {
    setOpen(false);
  }
  return (
    <Modal open={open} handleClose={handleCloseModal} title="Nuevo Periodo">
      <PeriodosForm
        setOpen={setOpen}
        updatePeriodos={updatePeriodos}
        periodoActivo={periodoActivo}
      />
    </Modal>
  );
}

export { PeriodosModal };
