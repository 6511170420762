import React, { useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { getResultadosCompletos } from "../Api/resultado.api";
import { NodoContext } from "../Context/nodoContext";
import { PeriodoContext } from "../Context/periodoContext";
import { DashboardTable } from "../Home/Dashboard/dashboardTable";
import { TIPOS_RACI } from "../Utils/enums";
import { AccordionOptions } from "../Indicadores/accordionOptions";
import {
  IconButton, List, ListItem, ListItemAvatar, ListItemText,
  Dialog, DialogTitle, DialogActions, Button
} from "@mui/material";
import KeyboardIcon from '@mui/icons-material/Keyboard';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import SwapVertIcon from '@mui/icons-material/SwapVert';

export function SimpleDialog({ onClose, open }) {

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Atajos del teclado</DialogTitle>
      <List sx={{ pt: 0 }}>
        <ListItem>
          <ListItemAvatar>
            <KeyboardTabIcon color="primary" />
          </ListItemAvatar>
          <ListItemText
            primary="Puedes navegar con la tecla de tab desplazandote hacia abajo, de esa forma puedes 
            ingresar los resultados de cada indicador en forma vertical. El puntero siempre quedará activo
            con esta forma de navegar."
            secondary="Tecla TAB"
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <KeyboardReturnIcon color="secondary" />
          </ListItemAvatar>
          <ListItemText
            primary="Puedes usar la tecla enter para activar la edición de una celda, esto activará el puntero y habilitará la
            opción de ingresar un nuevo valor. También puedes usar la tecla enter para desplazarte hacia abajo, pero
            el puntero no quedará activo."
            secondary="Tecla ENTER"
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <SwapVertIcon color="success" />
          </ListItemAvatar>
          <ListItemText
            primary="Si el puntero no está activo, puedes usar las flechas de direcciones para desplazar la celda seleccionada
            hacia arriba o abajo. Si tienes el puntero activo en una celda, la estarás editando y puedes usar las
            flechas para aumentar o disminuir de uno en uno el valor ingresado."
            secondary="Flechas de dirección"
          />
        </ListItem>
      </List>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}

function ResultadosDigitador() {
  const [resultados, setResultados] = React.useState([]);
  const [mostrarSoloVencidos, setMostrarSoloVencidos] = React.useState(false);
  const [atajosTeclado, setAtajosTeclado] = React.useState(false);
  const { fireReload } = React.useContext(PeriodoContext);
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext);
  const [expandedGroups, setExpandedGroups] = React.useState(false);

  useEffect(() => {
    const isFirstVisit = !localStorage.getItem('atajosTeclado');
    if (isFirstVisit) {
      setTimeout(() => {
        setAtajosTeclado(true);
      }, 1000);
      localStorage.setItem('atajosTeclado', 'true');
    }
  }, []);

  useEffect(() => {
    updateResultados();
  }, [fireReload]);

  function getExpirationDate() {
    return mostrarSoloVencidos ? moment().format("YYYY-MM-DD") : "2099-11-11";
  }

  function updateResultados() {
    setLoading(true);
    setLoadingMessage("Cargando..");
    getResultadosCompletos(TIPOS_RACI.DIGITADOR, getExpirationDate()).then(
      (response) => {
        setLoading(false);
        if (response.status === 200) {
          setResultados(response.body);
        } else {
          toast.error("No se pudieron recuperar los resultados");
        }
      }
    );
  }

  const compressHandler = () => {
    setExpandedGroups(true);
    setTimeout(() => {
      setExpandedGroups(false);
    }, 100);
  }

  return (
    <>
      <section
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          fontSize: "30px",
          textAlign: "left",
          padding: "0.5rem 1rem",
        }}
      >
        <b>Mediciones: </b>
        <div style={{ display: 'flex', justifyContent: 'right', margin: '10px' }}>
          <IconButton
            title="Atajos del teclado"
            onClick={() => setAtajosTeclado(!atajosTeclado)}
            color="info"
          >
            <KeyboardIcon />
          </IconButton>
          <AccordionOptions
            compressHandler={compressHandler}
            expandHandler={() => setExpandedGroups(true)}
            expandTitle="Expandir todos"
            compressTitle="Contraer todos"
          />
        </div>
      </section>
      <section>
        <DashboardTable
          resultados={resultados}
          tipoRaciId={TIPOS_RACI.DIGITADOR}
          updateResultados={updateResultados}
          storageKey={'resultadosDigitadorStorage'}
          allGroupsExpanded={expandedGroups}
        />
      </section>
      <SimpleDialog open={atajosTeclado} onClose={() => setAtajosTeclado(false)} />
    </>
  );
}

export { ResultadosDigitador };
