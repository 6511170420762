import { HOST } from "../Utils/config";
import { deleteRequest, getRequest, postRequest, putRequest } from "./requests";


async function getMapList() {
  return await getRequest(`${HOST}/api/v1/valuemap/valuemaplist`);
}

async function createValueMap(object) {
  return await postRequest(`${HOST}/api/v1/valuemap/createvaluemap`, object);
}

async function removeValueMap(object) {
  return await putRequest(`${HOST}/api/v1/valuemap/removevaluemap`, object);
}

async function getNodosValueMap(valueMapId, ultimosResultados) {
  return await getRequest(`${HOST}/api/v1/valuemap/nodosvaluemap/${valueMapId}?ultimosResultados=${ultimosResultados}`);
}

async function getNodosExistentes(valueMapId) {
  return await getRequest(`${HOST}/api/v1/valuemap/nodosexistentes/${valueMapId}`);
}

async function getNodosDisponibles(valueMapId) {
  return await getRequest(`${HOST}/api/v1/valuemap/nodosDisponibles/${valueMapId}`);
}

async function agregarNodo(object) {
  return await postRequest(`${HOST}/api/v1/valuemap/agregarnodo`, object);
}

async function removerNodo(object) {
  return await putRequest(`${HOST}/api/v1/valuemap/removernodo`, object);
}

async function getUsuariosMap(valueMapId) {
  return await getRequest(`${HOST}/api/v1/valuemap/getusuariosmap/${valueMapId}`);
}

async function updatePublico(valueMapId, publico) {
  const obj =  {id:valueMapId, publico}
  return await putRequest(`${HOST}/api/v1/valuemap/updatepublico`, obj);
}

async function agregarUsuario(valueMapId, userId) {
  const obj = {valueMapId:valueMapId, usuarioId:userId}
  return await postRequest(`${HOST}/api/v1/valuemap/agregarusuario`, obj);
}

async function removerUsuario(valuemapusuarioId) {
  const obj = {id:valuemapusuarioId}
  return await deleteRequest(`${HOST}/api/v1/valuemap/removerusuario`, obj);
}

async function getMapasCompartidosConmigo() {
  return await getRequest(`${HOST}/api/v1/valuemap/compartidos/joder`);
}


export { getNodosValueMap,
getNodosDisponibles,
getNodosExistentes,
agregarNodo,
removerNodo,
getMapList,
createValueMap,
removeValueMap,
getUsuariosMap,
updatePublico,
agregarUsuario,
removerUsuario,
getMapasCompartidosConmigo
};
