import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const Item = ({
    id,
    item,
    setItem = (id) => { },
    editable = false,
    selectedItem,
    showIconEditing = false,
}) => {

    const ref = React.useRef(null);
    const [height, setHeight] = React.useState("100%");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        setHeight(ref?.current?.getBoundingClientRect()?.width || 0);
        window.addEventListener('resize', () => {
            setHeight(ref?.current?.getBoundingClientRect()?.width || 0);
        });
    }, [ref, ref?.current?.getBoundingClientRect()?.width]);

    return (
        <div
            ref={ref}
            style={{
                width: "100%",
                height: height,
                display: 'flex',
                alignItems: 'stretch',
                background: `#${item?.backgroundColor}`,
                border: '1px solid #c9c9c9',
                borderRadius: '5px',
                cursor: editable ? 'pointer' : 'default',
                position: 'relative',
                flexDirection: 'column',
                boxShadow: selectedItem === id ? '0 0 10px 8px #9ce8ff' : 'none'
            }}
            onClick={editable ? () => setItem(id) : () => { }}
        >
            {/* HEADER */}
            <div style={{ display: 'flex', flexGrow: 0.5, flexDirection: 'row', alignSelf: 'flex-start', width: '100%', justifyContent: 'left', alignItems: 'left', padding: "2.5%", color: `#${item?.headerColor}` }}>
                <span className='responsive-font' style={{ textAlign: 'left', wordWrap: 'break-word' }}>
                    <b>{item?.valor}</b> {item?.nombre}
                </span>
            </div>
            {/* content */}
            <div style={{ display: 'flex', flexGrow: 0.5, flexDirection: 'column', alignSelf: 'center', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                {((item?.showIcon && !item?.usuarios) || showIconEditing) && <AccessibilityNewIcon sx={{ fontSize: { xl: '2.5vw', lg: '2.5vw', md: '3vw', sm: '5vw' }, color: `#${item?.headerColor}` }} />}
                {(item?.usuarios && item?.usuarios?.length > 0) && <span className='responsive-font2' style={{ fontWeight: 'bold', color: `#${item?.headerColor}` }}>
                    {item?.porcentaje || 0}% ({item?.usuarios?.length || 0})
                </span>}
            </div>
            {/* ACTIONS */}
            <div style={{ display: 'flex', flexGrow: 1, alignSelf: 'flex-end', width: '100%', justifyContent: 'center', alignItems: 'center', padding: "0 10%" }}>
                {!!(item?.usuarios && item?.usuarios?.length) &&
                    <>
                        <p
                            id={`${id}-button`}
                            className='responsive-font'
                            onClick={handleClick}
                            style={{ cursor: 'pointer', border: '0.5px solid black', width: height, textAlign: 'center', borderRadius: '5px' }}
                        >
                            Usuarios
                        </p>
                        <Menu
                            id={`${id}-menu`}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': `${id}-button`,
                            }}
                        >
                            {item?.usuarios?.map((usuario, index) => (
                                <MenuItem key={index}>
                                    {usuario}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                }
            </div>
        </div>
    );
}

const DiagramaCajas = ({
    editable = false,
    plantilla = {
        x: [],
        y: [],
    },
    configuracion = {},
    setSelectedConfiguracion,
    selectedItem,
}) => {

    return (
        <Box sx={{ width: '100%' }}>
            {plantilla?.y && plantilla?.y?.length > 0 && plantilla?.y.concat([null]).map((y, yIndex) => {
                if (y) {
                    return (
                        <Grid key={yIndex} container rowSpacing={1} columnSpacing={1}>
                            {plantilla?.x && plantilla?.x?.length > 0 && [null].concat(plantilla?.x).map((x, xIndex) => { // El null se añade para los labels
                                if (x) {
                                    const id = `${x.ponderacionValor},${y.ponderacionValor}`
                                    const showIconEditing = editable ?
                                        xIndex === Math.round(plantilla?.x?.length / 2) && yIndex === Math.round((plantilla?.y?.length - 1) / 2) :
                                        false;
                                    return (
                                        <Grid xs>
                                            <Item
                                                id={id}
                                                item={configuracion[id]}
                                                setItem={setSelectedConfiguracion}
                                                editable={editable}
                                                selectedItem={selectedItem}
                                                showIconEditing={showIconEditing}
                                            />
                                        </Grid>
                                    )
                                } else {
                                    return (
                                        <Grid xs sx={{ display: 'flex' }}>
                                            <div style={{ display: 'flex', width: '100%', alignSelf: 'center', /*justifyContent: 'flex-end'*/ }}>
                                                <span className='responsive-font' style={{ textAlign: 'center' }}>
                                                    {y?.ponderacionNombre}
                                                </span>
                                            </div>
                                        </Grid>
                                    )
                                }
                            })}
                        </Grid>
                    )
                } else {
                    return (
                        <Grid container rowSpacing={1} columnSpacing={1}>
                            {plantilla?.x && plantilla?.x?.length > 0 && [null].concat(plantilla?.x).map((x, xIndex) => { // El null se añade para los labels
                                return (
                                    <Grid xs sx={{ display: 'flex' }}>
                                        <div style={{ display: 'flex', width: '100%', alignSelf: 'center', justifyContent: 'center' }}>
                                            <span className='responsive-font' style={{ textAlign: 'center' }}>
                                                {x?.ponderacionNombre}
                                            </span>
                                        </div>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    )
                }
            })}
        </Box>
    );
}

export default DiagramaCajas;