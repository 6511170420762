import { HOST } from "../Utils/config";
import { getRequest, postRequest, putRequest, deleteRequest } from "./requests";


// Configuración de matriz de puntos
async function getConfiguracionMatrizPuntos(periodos) {
    return await getRequest(
        `${HOST}/api/v1/configuracion/matrizpuntos/?periodos=${periodos}`
    );
}

async function createConfiguracionMatrizPuntos(data, periodos) {
    return await postRequest(
        `${HOST}/api/v1/configuracion/matrizpuntos/?periodos=${periodos}`,
        data
    );
}

async function updateConfiguracionMatrizPuntos(id, data, periodos) {
    return await putRequest(
        `${HOST}/api/v1/configuracion/${id}/matrizpuntos/?periodos=${periodos}`,
        data
    );
}

async function deleteConfiguracionMatrizPuntos(id, periodos) {
    return await deleteRequest(
        `${HOST}/api/v1/configuracion/${id}/matrizpuntos/?periodos=${periodos}`,
        {}
    );
}

// Configuración de niveles
async function getConfiguracionNiveles(periodos) {
    return await getRequest(
        `${HOST}/api/v1/configuracion/niveles/?periodos=${periodos}`
    );
}

async function createConfiguracionNiveles(data, periodos) {
    return await postRequest(
        `${HOST}/api/v1/configuracion/niveles/?periodos=${periodos}`,
        data
    );
}

async function updateConfiguracionNiveles(id, data, periodos) {
    return await putRequest(
        `${HOST}/api/v1/configuracion/${id}/niveles/?periodos=${periodos}`,
        data
    );
}

async function deleteConfiguracionNiveles(id, periodos) {
    return await deleteRequest(
        `${HOST}/api/v1/configuracion/${id}/niveles/?periodos=${periodos}`,
        {}
    );
}

// Configuración de ponderaciones
async function getConfiguracionPonderaciones(periodos) {
    return await getRequest(
        `${HOST}/api/v1/configuracion/ponderacion/?periodos=${periodos}`
    );
}

async function createConfiguracionPonderaciones(data, periodos) {
    return await postRequest(
        `${HOST}/api/v1/configuracion/ponderacion/?periodos=${periodos}`,
        data
    );
}

async function updateConfiguracionPonderaciones(id, data, periodos) {
    return await putRequest(
        `${HOST}/api/v1/configuracion/${id}/ponderacion/?periodos=${periodos}`,
        data
    );
}

async function deleteConfiguracionPonderaciones(id, periodos) {
    return await deleteRequest(
        `${HOST}/api/v1/configuracion/${id}/ponderacion/?periodos=${periodos}`,
        {}
    );
}

async function getDiagramaCajasConfig(periodo){
    return await getRequest(
        `${HOST}/api/v1/configuracion/${periodo}/diagramacajas/`
    );
}

async function updateDiagramaCajasConfig(periodo, data){
    return await putRequest(
        `${HOST}/api/v1/configuracion/${periodo}/diagramacajas/`,
        data
    );
}

export { 
    getConfiguracionMatrizPuntos, 
    createConfiguracionMatrizPuntos,
    updateConfiguracionMatrizPuntos,
    deleteConfiguracionMatrizPuntos,
    getConfiguracionNiveles,
    createConfiguracionNiveles,
    updateConfiguracionNiveles,
    deleteConfiguracionNiveles,
    getConfiguracionPonderaciones,
    createConfiguracionPonderaciones,
    updateConfiguracionPonderaciones,
    deleteConfiguracionPonderaciones,
    getDiagramaCajasConfig,
    updateDiagramaCajasConfig
};
