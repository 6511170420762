import { Divider, IconButton, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import AssignmentSharpIcon from "@mui/icons-material/AssignmentSharp";
import { useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { deleteIndicador } from "../Api/indicadores.api";
import { toast } from "react-toastify";
import Confirm from "../general-components/confirm";
import moment from "moment";

function Nivel({ nivel, remove, cargarDatos }) {
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const navigate = useNavigate();

  function removeIndicador() {
    // putPeriodo({ id: periodo.id, eliminado: true }).then((response) => {
    //   if (response.status === 200) {
    //     toast.success("Periodo eliminado exitosamente!");
    //     setPeriodoActivo(-1);
    //     setOpenConfirm(false);
    //     updatePeriodos();
    //   } else {
    //     toast.error(response.message);
    //   }
    // });
  }

  return (
    <>
      <section
        style={{
          padding: "10px 0",
          display: "flex",
          alignItems: "center",
          margin: "0px 3%",
        }}
      >
        {/* <AssignmentSharpIcon
          fontSize="large"
          color={`${selected ? "primary" : "inherit"}`}
          onClick={() => navigate(`${nivel.id}`)}
          style={{ cursor: "pointer" }}
          onMouseOver={() => setSelected(true)}
          onMouseOut={() => setSelected(false)}
        /> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div>
              <div>
                <Tooltip title="Editar">
                  <IconButton
                    onClick={() => {
                      cargarDatos(nivel.id);
                    }}
                  >
                    <EditIcon color="primary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Eliminar">
                  <IconButton onClick={() => setOpenConfirm(true)}>
                    <DeleteForeverIcon color="error" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>

            <b>{nivel.nombre}</b>
          </div>
          <b style={{ textAlign: "left", fontSize: "14px" }}>
            {`(${nivel.numero})`}
          </b>
          <p style={{ textAlign: "left", margin: 2 }}>{nivel.descripcion}</p>
        </div>
      </section>

      <div style={{ width: "100%", justifyContent: "center" }}>
        <Divider sx={{ width: "96%", marginLeft: "2%" }} />
      </div>
      <Confirm
        open={openConfirm}
        title={"Eliminar"}
        text={"¿Confirma que desea eliminar este elemento?"}
        onConfirm={() => remove(nivel.id)}
        handleCloseProp={() => setOpenConfirm(false)}
      />
    </>
  );
}

export default Nivel;
