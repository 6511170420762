import React, { useEffect } from "react";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

export default function ZoomDialog({ onClose, open }) {

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Zoom en las gráficas</DialogTitle>
      <DialogContent>
        Ahora puedes hacer zoom en las gráficas, solo selecciona el área que deseas ampliar y listo. También puedes
        hacer zoom con la rueda del mouse, acercando o alejando la rueda, o desplazándote con el puntero sobre la gráfica.
        <Typography variant="body1" sx={{ marginY: '0.5rem' }}>
          Para hacer zoom o desplazarte sobre la gráfica tienes que mantener presionada la tecla
          &nbsp;<Chip label="SHIFT" size="small" color="info" />
        </Typography>
      </DialogContent>
      <img src="/zoomChart.gif" alt="Zoom en gráficas" style={{ width: '100%', margin: '1rem 0' }} />
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}