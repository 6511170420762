import Modal from '../general-components/modal'
import React from 'react'
import { IndicadoresForm } from './indicadoresForm'

function IndicadoresModal({
  open,
  setOpen,
  updateIndicadores,
  indicadorActivo
}) {
  function handleCloseModal() {
    setOpen(false)
  }
  return (
    <Modal open={open} handleClose={handleCloseModal} title="Indicador">
      <IndicadoresForm
        setOpen={setOpen}
        updateIndicadores={updateIndicadores}
        indicadorMetadata={indicadorActivo}
      />
    </Modal>
  )
}

export { IndicadoresModal }
