import React from "react";
import { Handle, Position } from "react-flow-renderer";
import TemporaryDrawer from "../general-components/temporary-drawer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Divider, Typography } from "@mui/material";
import { LinearProgressWithLabel } from "../general-components/prograssBar";

let customNodeStyles = {
  background: "#fff",
  color: "#000",
  padding: 10,
  border: `solid 2px #000`,
  borderRadius: 5,
  // width: 180,
  // height: 90,
  width: 220,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
};
const CustomProcessNode = ({ data, selected }) => {
  const [isToolTipoVisible, setTooltipVisible] = React.useState(false);
  

  function handleTooltip() {
    setTooltipVisible(!isToolTipoVisible);
  }

  function closeTooltip() {
    if (isToolTipoVisible) {
      setTooltipVisible(false);
    }
  }
  return (
    <div
      style={{
        ...customNodeStyles,
        borderStyle: selected ? "dashed" : "solid",
      }}
      // onClick={closeTooltip}
    >
      <Handle
        type="target"
        position={Position.Left}
        style={{ borderRadius: 50 }}
        id="processTarget"
      />
      {!data.displayResultados && <div>{data.nodo.nombre}</div>}
      {data.displayResultados && (
        <div>
          <Typography
            variant="body2"
            gutterBottom
            component="div"
            style={{ fontWeight: "bold" }}
          >
            {data.nodo.nombre}
          </Typography>
          <Divider />
          {data.nodo.indicadores?.map((indicador, index) => {
            return <div key={index} style={{textAlign: "left"}}>
              <i style={{fontSize: "12px"}}><b>{indicador.nombre}:</b></i>
              <LinearProgressWithLabel score={indicador.valorNumerico} goal={indicador.meta}/>
              </div>;
          })}          
        </div>
      )}
      {/* {selected && (
        <InfoOutlinedIcon
          sx={{ position: "absolute", top: "5px", right: "5px" }}
          onClick={handleTooltip}
        />
      )} */}
      <Handle
        type="source"
        position={Position.Right}
        id="inputSource"
        style={{ borderRadius: 50 }}
      />
      {/* <TemporaryDrawer
        position={"right"}
        content={data.info}
        title={data.tipoNodoSipocId === "1" ? "Entradas" : "Salidas"}
        show={isToolTipoVisible}
        handleClose={closeTooltip}
        hideBackdrop={true}
      ></TemporaryDrawer> */}
    </div>
  );
};

export { CustomProcessNode };
