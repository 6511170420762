import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import BadgeIcon from "@mui/icons-material/Badge";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import PasswordIcon from "@mui/icons-material/Password";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import FaxIcon from '@mui/icons-material/Fax';
import { changePassword, getMiUsuario } from "../Api/usuarios.api";
import { toast } from "react-toastify";
import { PasswordField } from "../general-components/passwordField";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function UsuariosMain() {
  const [nombre, setNombre] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [usuario, setUsuario] = React.useState("");
  const [nivel, setNivel] = React.useState("");
  const [puesto, setPuesto] = React.useState("");
  const [departamento, setDepartamento] = React.useState("");
  const [cambiarPassword, setCambiarPassword] = React.useState(false);
  const [previusPassword, setPreviusPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [showPreviousPassword, setShowPreviousPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [id, setId] = React.useState(-1);

  useEffect(() => {
    getMiUsuario().then((response) => {
      if (response.status === 200) {
        setNombre(response.body.nombreCompleto);
        setEmail(response.body.email);
        setUsuario(response.body.usuario);
        setNivel(response.body.nivelOrganizacionalId);
        setId(response.body.id);
        setPuesto(response.body.puesto);
        setDepartamento(response.body.departamento);
      } else {
        toast.error("Error al obtener los datos de usuario");
      }
    });
  }, []);

  function saveNewPassword() {
    changePassword({ password: previusPassword, newPassword }).then(
      (response) => {        
        if (response.status === 201) {
          toast.success("Contraseña cambiada correctamente");
          setCambiarPassword(false);
          setPreviusPassword("");
          setNewPassword("");
        } else if (response.status === 401) {
          toast.error("La contraseña anterior no es correcta");
        } else {
          toast.error("Error al cambiar la contraseña");
        }
      }
    );
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h4" noWrap component="div">
          Mi cuenta
        </Typography>
      </Paper>
      <Paper elevation={3} sx={{ p: 2, m: 3 }}>
        <Grid container spacing={2} sx={{ pt: 2 }}>
          <Grid item xs={6}>
            <Item>
              <div style={headerStyle}>
                <AccountCircleIcon color="primary" />
                <b style={{ paddingLeft: "10px" }}>Nombre</b>
              </div>
              {nombre}
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <div style={headerStyle}>
                <FingerprintIcon color="primary" />
                <b style={{ paddingLeft: "10px" }}>Usuario</b>
              </div>
              {usuario}
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <div style={headerStyle}>
                <AlternateEmailIcon color="primary" />
                <b style={{ paddingLeft: "10px" }}>Email </b>
              </div>
              {email}
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <div style={headerStyle}>
                <BadgeIcon color="primary" />
                <b style={{ paddingLeft: "10px" }}>DPI </b>
              </div>
              {usuario}
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <div style={headerStyle}>
                <AccountTreeIcon color="primary" />
                <b style={{ paddingLeft: "10px" }}>Nivel Organizacional </b>
              </div>
              {nivel}
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <div style={headerStyle}>
                <DeviceHubIcon color="primary" />
                <b style={{ paddingLeft: "10px" }}>Puesto </b>
              </div>
              {puesto}
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <div style={headerStyle}>
                <FaxIcon color="primary" />
                <b style={{ paddingLeft: "10px" }}>Departamento </b>
              </div>
              {departamento}
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item sx={{ height: "75px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={headerStyle}>
                  <PasswordIcon color="primary" />
                  <b style={{ paddingLeft: "10px" }}>Password </b>
                </div>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => setCambiarPassword(true)}
                >
                  Cambiar Password
                </Button>
                <div></div>
              </div>
            </Item>
          </Grid>
          {cambiarPassword && (
            <>
              <Typography variant="h6" sx={{ p: 3 }}>
                Cambio de Password:{" "}
              </Typography>
              <Grid item xs={12}>
                <Item>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={headerStyle}>
                      <SyncLockIcon color="primary" />
                      <b style={{ paddingLeft: "10px" }}>Cambiar </b>
                    </div>
                    <PasswordField
                      password={previusPassword}
                      setShowPassword={setShowPreviousPassword}
                      showPassword={showPreviousPassword}
                      setPassword={setPreviusPassword}
                      inputLabel="Password anterior"
                    />
                    <PasswordField
                      password={newPassword}
                      setShowPassword={setShowNewPassword}
                      showPassword={showNewPassword}
                      setPassword={setNewPassword}
                      inputLabel="Nuevo password"
                    />
                    <Button
                      variant="contained"
                      color="success"
                      onClick={saveNewPassword}
                    >
                      Aceptar
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => setCambiarPassword(false)}
                    >
                      Cancelar
                    </Button>
                    <div></div>
                  </div>
                </Item>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </Box>
  );
}

const headerStyle = { display: "flex", alignItems: "center" };

export { UsuariosMain };
