import React from 'react'
import { copiarIndicador, moverIndicador } from '../Api/indicadores.api'
import { toast } from 'react-toastify'
import { Button } from '@mui/material'
import { modosIntegracion, TIPOS_NODO } from '../Utils/enums'
import ProcessTreeWithKPI from '../TreeView/ProcessTreeWithKPI'

export default function CopiarIndicadorForm({ indicadorActivo, handleCloseModal }) {
  const [nodoDestino, setNodoDestino] = React.useState(null)

  function handleCopyMove(isMove = false) {
    if (!indicadorActivo) {
      return toast.warn('No se ha seleccionado ningún indicador')
    }
    if (nodoDestino === null) {
      return toast.warn('Debe seleccionar un proceso o actividad destino')
    }
    if (
      nodoDestino.tipoNodoId === TIPOS_NODO.PROCESO &&
      indicadorActivo.modoIntegracionId !== modosIntegracion.calculado
    ) {
      return toast.warn(
        'Solo se pueden copiar indicadores calculados a procesos'
      )
    }
    const settings = {
      nodoId: nodoDestino.id,
      indicadorId: indicadorActivo.id,
      agrupado: indicadorActivo.agrupado
    }
    if (isMove) {
      moverIndicador(settings).then((response) => {
        if (response.status === 201) {
          toast.success('indicador movido con éxito')
          handleCloseModal();
        } else {
          toast.error('Error al mover el indicador')
        }
      })
    } else {
      copiarIndicador(settings).then((response) => {
        if (response.status === 201) {
          toast.success('indicador copiado con éxito')
        } else {
          toast.error('Error al copiar el indicador')
        }
      })
    }
  }

  function handleSelect(nodo) {

    setNodoDestino(nodo)
  }

  return (
    <div>
      <br />
      {nodoDestino ? (
        <p>
          {nodoDestino.tipoNodoId === TIPOS_NODO.PROCESO
            ? 'Proceso destino:'
            : 'Actividad destino:'}{' '}
          <b>{nodoDestino.nombre}</b>
        </p>
      ) : (
        <p>Seleccione un proceso o actividad destino</p>
      )}
      <div style={{ maxHeight: 'calc(100vh*0.7)', overflow: 'auto' }}>
        {/* <ProcessTree onNodeClick={handleSelect} fontColor="black" /> */}
        <ProcessTreeWithKPI
          fontColor="black"
          onNodeClick={handleSelect}
          draggable={false}
          showAgrupaciones={false}
          showIndicadoresAgrupados={true}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '10px', float: 'right' }}
          onClick={() => handleCopyMove(false)}
        >
          Copiar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{ marginTop: '10px', float: 'right', marginLeft: '10px' }}
          onClick={() => handleCopyMove(true)}
        >
          Mover
        </Button>
      </div>
    </div>
  )
}
