import { HOST } from '../Utils/config'
import { putRequest } from './requests'

async function putVistaIndicadorDetalle(detalle) {
  return await putRequest(
    `${HOST}/api/v1/vistaIndicadorDetalle`,
    detalle
  )
}

export { putVistaIndicadorDetalle }
