import React, { useEffect } from 'react'
import { Button, Chip, Grid, Paper, TextField } from '@mui/material'
import { toast } from 'react-toastify'
import { NodoContext } from '../Context/nodoContext'
import Modal from '../general-components/modal'
import ProcessTreeWithKPI from '../TreeView/ProcessTreeWithKPI'
import { postAgrupacion, putAgrupacion } from '../Api/agrupaciones.api'
import { useIndicadorAgrupado } from '../Hooks/useIndicadorAgrupado'
const INDICADOR = -1

export function ModalIndicadorCompuesto({ open, setOpen, nodoId, indicadorActivoBlueprint, updateIndicadores }) {
  const [indicadores, setIndicadores] = React.useState([])
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext)
  const { indicador, updateIndicador } = useIndicadorAgrupado({ indicadorId: indicadorActivoBlueprint?.id, agrupado: indicadorActivoBlueprint?.agrupado })
  const [nombre, setNombre] = React.useState('')
  const [descripcion, setDescripcion] = React.useState('')
  

  useEffect(() => {
    setNombre('')
    setDescripcion('')
    setIndicadores([])
    if (open && indicadorActivoBlueprint.id) {      
      updateIndicador()
    }    
  }, [indicadorActivoBlueprint, open])

  useEffect(() => {
    console.log({indicador})
    if(indicador.nombre && indicador.descripcion) {
      setNombre(indicador.nombre)
      setDescripcion(indicador.descripcion)
      setIndicadores(indicador.indicadores)
    }
  }, [indicador])

 

  const createAgrupacion = () => {
    console.log({indicadores})
    if(nombre === '' || descripcion === '') {
      return toast.error('Debe completar los campos de nombre y descripción')
    }
    if(indicadores.length === 0) {
      return toast.error('Debe seleccionar al menos un indicador')
    }
    const body = {
      nombre,
      descripcion,
      indicadores: indicadores,
      idNodo: nodoId
    }
    if(indicadorActivoBlueprint.id) {
      body.id = indicadorActivoBlueprint.id
      updateAgrupacion(body)
    } else {
      createNewAgrupacion(body)
    }
    
  }

  const createNewAgrupacion = (body) => {
    postAgrupacion(body).then((response) => {
      if (response.status === 201) {
        setOpen(false)
        updateIndicadores()
      } else {
        toast.error('No se pudo crear la agrupación')
      }
    })
  }

  const updateAgrupacion = (body) => {
    putAgrupacion(body).then((response) => {
      if (response.status === 200) {
        setOpen(false)
        updateIndicadores()
      } else {
        toast.error('No se pudo actualizar la agrupación')
      }
    })
  }

  const handleDoubleClick = (nodo) => {
    console.log(nodo)
    if (nodo.tipoNodoId === INDICADOR) {
      const indicadorExiste = indicadores.find(
        (indicador) => indicador.idIndicador === parseInt(nodo.id)
      )
      if (indicadorExiste) {
        if(indicadorExiste.eliminado) {
          indicadorExiste.eliminado = false
          setIndicadores([...indicadores])
          return
        }
        return toast.warn(
          'El indicador ya se encuentra en el tab seleccionado.'
        )
      }
      setIndicadores([
        ...indicadores,
        {
          id: -1,
          nombre: nodo.nombre,
          idIndicador: parseInt(nodo.id),
          eliminado: false
        }
      ])
    }
  }

  const handleRemoveIndicador = (indicadorId) => {
    console.log({indicadores})
    console.log({indicadorId})
    const indicador = indicadores.find((i) => i.idIndicador === indicadorId)
    if(indicador.id !== -1) {
      indicador.eliminado = true
      setIndicadores([...indicadores])
      return
    }
    const indicadoresCopy = indicadores.filter(
      (indicador) => indicador.idIndicador !== indicadorId
    )
    setIndicadores(indicadoresCopy)
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      handleClose={() => setOpen(false)}
      closeAfterTransition
      title="Crear agrupación de indicadores"
    >
      <div style={{ display: 'flex', maxHeight: '90vh', overflowY: 'scroll' }}>
        <section
          style={{ width: '50%', maxHeight: '80vh', overflowY: 'scroll' }}
        >
          <ProcessTreeWithKPI
            fontColor="black"
            onDoubleClick={handleDoubleClick}
            draggable={false}
          />
        </section>
        <section style={{ width: '50%' }}>
          <Grid container spacing={3} sx={{ marginTop: '0px' }}>
            <Grid item xs={12}>
              <TextField
                id="nombre"
                label="Nombre"
                variant="outlined"
                style={{ width: '100%' }}
                required
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="descripcion"
                label="Descripción"
                variant="outlined"
                multiline
                style={{ width: '100%' }}
                required
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <h4>Indicadores seleccionados:</h4>
              <Paper
                elevation={3}
                sx={{
                  border: '1px dashed black',
                  borderRadius: '10px',
                  minHeight: '2rem',
                  maxHeight: '15rem',
                  overflowY: 'scroll',
                  padding: '5px'
                }}
              >
                {indicadores.filter( i => !i.eliminado).map((item) => (
                  <Chip
                    key={item.idIndicador}
                    label={`${item.nombre}`}
                    variant="outlined"
                    onDelete={() => {
                      handleRemoveIndicador(item.idIndicador)
                    }}
                  />
                ))}
              </Paper>
            </Grid>
            <Button
              variant="contained"
              sx={{
                height: '50px',
                justifySelf: 'center',
                alignSelf: 'center',
                margin: '0 auto',
                marginTop: '20px'
              }}
              onClick={createAgrupacion}
            >
              Guardar
            </Button>
          </Grid>
        </section>
      </div>
    </Modal>
  )
}
