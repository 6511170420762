// * HOST PRINCIPAL
// const DEFAULT_HOST = 'http://localhost:3001' // LOCAL
// const DEFAULT_HOST = "https://kijszw1kwe.execute-api.us-east-1.amazonaws.com" // DEV
const DEFAULT_HOST = 'https://x8ynl31wad.execute-api.us-east-1.amazonaws.com' // PROD

// * HOST PARA GRAFICOS
// const CHART_HOST = "https://orvd7ov7gg.execute-api.us-east-1.amazonaws.com/api/send" // dev
const CHART_HOST = "https://u4i074vayg.execute-api.us-east-1.amazonaws.com/api/send" // prod

// * HOST PARA ANALISIS ESTADISTICO
const ANALYSIS_HOST = 'https://t43thu37oswnqiveebzud6shqi0xxiof.lambda-url.us-east-1.on.aws/'

// * URL APPILU
const APPILU_HOST = 'https://gestionapp.launion.com.gt'

const PERIODO_CREADO = 1
const PERIODO_INICIADO = 2
const RACI_RESPONSIBLE = 1
const RACI_ACCOUNTABLE = 2
const RACI_CONSULTED = 3
const RACI_INFORMED = 4
const DEFAULT_DELAY = 800
const HOST = process.env.REACT_APP_API_URL || DEFAULT_HOST
export {
  HOST,
  CHART_HOST,
  ANALYSIS_HOST,
  APPILU_HOST,
  PERIODO_CREADO,
  PERIODO_INICIADO,
  RACI_RESPONSIBLE,
  RACI_ACCOUNTABLE,
  RACI_CONSULTED,
  RACI_INFORMED,
  DEFAULT_DELAY
}
