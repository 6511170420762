import React from "react";
import {Grid, Typography, TextField} from "@mui/material";
import { putNodo as updateNodo } from "../../../Api/nodos.api";
import OkCancelButtons from "../../../general-components/ok-cancel-buttons";
import { useAuth } from "../../../Hooks/useAuth";
import { NodoContext } from "../../../Context/nodoContext";
import EditIcon from '@mui/icons-material/Edit';

function ProcessCode() {
  const [editableMode, setEditableMode] = React.useState(false);
  const [codeState, setCodeState] = React.useState("");
  const [codeBk, setCodeBk] = React.useState("");
  const { nodoActual, reloadNodoActual, reloadTree } = React.useContext(NodoContext)
  const { getUser } = useAuth();

  React.useEffect(() => {
    setCodeState(nodoActual.codigo);
    setCodeBk(nodoActual.codigo);
  }, [nodoActual]);

  const setEdit = (value) => {
    if (!nodoActual.permisos.U) {
      return;
    }
    setEditableMode(value);
    setCodeBk(codeState);
  };

  const cancelFn = () => {
    setCodeState(codeBk);
    setEditableMode(false);
  };

  const handleChangeCode = async (event) => {
    setCodeState(event.target.value);
  };

  const save = async () => {
    if (codeBk !== codeState && codeState !== "") {
      //code to save      
      const data = {
        codigo: codeState,
        id: nodoActual.id
      };

      const res = await updateNodo(data);
      if (res.status != 200) {
        setCodeBk(codeState);
      } else if (res.status == 200) {
        setCodeBk(codeState);
        reloadNodoActual(nodoActual.id)
        reloadTree();
      }
    }
    setEdit(false);
  };

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      save();
    } else if (e.keyCode == 27) {
      cancelFn();
    }
  };

  return (
    <React.Fragment>
      {editableMode && (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} sm={12}>
            <TextField
              id="txtCode"
              label="Código"
              variant="outlined"
              value={codeState}
              style={{ marginTop: 20 }}
              onChange={handleChangeCode}
              //onBlur={save}
              autoFocus
              onKeyDown={keyPress}
            />
          </Grid>
          <Grid item xs={12} lg={12} sm={12}>
            <OkCancelButtons okFn={save} cancelFn={cancelFn} />
          </Grid>
        </Grid>
      )}

      {editableMode === false && (
        <Typography
          color="text.primary"
          variant="h4"
          sx={{ display: "flex", alignItems: "flex-start", cursor: "text" }}
          onClick={() => setEdit(true)}
        >
          {codeState}
        </Typography>
      )}
    </React.Fragment>
  );
}

export default ProcessCode;
