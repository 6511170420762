import { Divider } from "@mui/material";
import React, { useEffect } from "react";
import SearchInput from "../TreeView/searchInput";
import { Map } from "./map";

import { IconButton, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

export function MapList({
  lista,
  setId,
  setDeleteConf,  
  showButtons
}) {
  const [elementosFiltrados, setElementosFiltrados] = React.useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setElementosFiltrados(lista);
  }, [lista]);

  const filterList = (e) => {
    setElementosFiltrados(
      lista.filter((el) =>
        el.nombre.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  return (
    <div>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: "2rem", alignItems: "center", marginLeft: "1rem"}}>
        <div>
        <Tooltip title="Regresar">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        </div>
        <section
          style={{
            width: "250px",
            border: "1px solid black",
            borderRadius: "20px",
            margin: "20px",
          }}
        >
          <SearchInput onChange={filterList} />
        </section>
      </div>
      <Divider />
      {elementosFiltrados.map((el) => {
        return (
          <Map
            key={el.id}
            element={el}
            setId={setId}
            setDeleteConf={setDeleteConf}  
            showButtons ={showButtons}        
          />
        );
      })}
    </div>
  );
}
