import React from 'react'
import { useEffect } from 'react'
import { HOST } from '../../Utils/config'
import LoginInput from '../components/loginInputContainer'
import { useAuth } from '../../Hooks/useAuth'
import { useNavigate } from 'react-router-dom'

export default function Login() {
  const auth = useAuth()
  const navigate = useNavigate()
  React.useEffect(() => {
    fetch(HOST).then((response) => {
      // Esta funcion hace que se disminuya el cold start
      console.log('Funcion despertada', response)
    })

    if (auth.getToken()) {
      navigate('/home')
    }
  }, [])

  return <LoginInput />
}
