import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import {
  postAgrupacionVistas,
  putAgrupacionVistas,
} from "../../Api/vistaAgrupacion.api";

export function CrearAgrupacionForm({
  handleCloseModal,
  updateFavoritos,
  agrupacionEdicion,
}) {
  const [nombre, setNombre] = React.useState("");
  const [descripcion, setDescripcion] = React.useState("");
  let buttonEnabled = true;

  React.useEffect(() => {
    if(agrupacionEdicion){
      setNombre(agrupacionEdicion.nombre);
      setDescripcion(agrupacionEdicion.descripcion);
    }
  }, [agrupacionEdicion]);

  const handleSave = () => {
    if (buttonEnabled) {
      buttonEnabled = false;
      if (nombre === "" || descripcion === "") {
        toast.error("Debe completar todos los campos");
        return;
      }
      const agrupacion = {
        nombre,
        descripcion,
      };

      if (agrupacionEdicion !== null) {
        agrupacion.id = agrupacionEdicion.id;
        update(agrupacion);
      } else {
        save(agrupacion);
      }
    }
  };

  function save(vista) {
    postAgrupacionVistas(vista).then((response) => {
      if (response.status === 201) {
        toast.success("Agrupación creada con éxito");
        updateFavoritos();
        handleCloseModal();
      } else {
        toast.error("Error al crear agrupación");
      }
    });
  }

  function update(vista) {
    putAgrupacionVistas(vista).then((response) => {
      if (response.status === 200) {
        toast.success("agrupación actualizada con éxito");
        updateFavoritos();
        handleCloseModal();
      } else {
        toast.error("Error al actualizar la agrupación");
      }
    });
  }

  return (
    <div style={{ display: "flex" }}>      
        <Grid container spacing={3} sx={{ marginTop: "0px" }}>
          <Grid item xs={12}>
            <TextField
              id="nombre"
              label="Nombre"
              variant="outlined"
              value={nombre}
              style={{ width: "100%" }}
              onChange={(e) => setNombre(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="descripcion"
              label="Descripción"
              variant="outlined"
              value={descripcion}
              multiline
              style={{ width: "100%" }}
              onChange={(e) => setDescripcion(e.target.value)}
              required
            />
          </Grid>
          <Button
            variant="contained"
            sx={{
              height: "50px",
              justifySelf: "center",
              alignSelf: "center",
              margin: "0 auto",
              marginTop: "20px",
            }}
            onClick={handleSave}
          >
            Guardar
          </Button>
        </Grid>      
    </div>
  );
}
