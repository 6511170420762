import React from "react";
import Box from "@mui/material/Box";
import { useTreeItemState } from '@mui/x-tree-view';

import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import FolderIcon from "@mui/icons-material/Folder";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import { cambiarOrdenNodo } from "../Api/nodos.api";
import { toast } from "react-toastify";
import { NodoContext } from "../Context/nodoContext";
import StyledTreeItemRoot from "./treeViewItemStyles";
import clsx from "clsx";

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelText,
    nodeId,
    label,
    padre,
    items = [],
    selected,
    level,
    itemColor,
    ...other
  } = props;
  const { reloadTree } = React.useContext(NodoContext);

  function move(direction) {
    cambiarOrdenNodo(nodeId, padre, direction)
      .then((response) => {
        if (response.status === 200) {
          return reloadTree();
        }
        return toast.error("Ocurrio un error al mover el nodo");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <StyledTreeItemRoot
      // ContentComponent={CustomContent}
      coloritem={itemColor}
      level={level}
      label={
        <div>
          <Box
            sx={{
              display: "flex",
              color: itemColor,
              alignItems: "center",
              p: 0.5,
              pr: 0,
            }}
          >
            <Box component={LabelIcon} sx={{ mr: 1 }} />
            <Typography
              variant="body2"
              sx={{ fontWeight: "inherit", flexGrow: 1, textAlign: "left" }}
            >
              {labelText}
            </Typography>
            {nodeId === selected && (
              <div>
                <NorthIcon
                  sx={{ fontSize: "14px", marginRight: "1px" }}
                  onClick={(e) => move("up")}
                />
                <SouthIcon
                  sx={{ fontSize: "14px", marginLeft: "1px" }}
                  onClick={(e) => move("down")}
                />
                <div
                  draggable
                >
                  <SouthIcon sx={{ fontSize: "14px", marginLeft: "1px" }} />
                </div>
              </div>
            )}
          </Box>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      {...other}
      nodeId={nodeId}
      itemId={nodeId}
    >
      {items.map((element) => {
        return (
          <StyledTreeItem
            nodeId={`${element.id}`}
            labelText={`${element.codigo} - ${element.nombre}`}
            labelIcon={element.tipoNodoId === 2 ? FolderIcon : SettingsIcon}
            items={element.items}
            level={level + 1}
            selected={selected}
            padre={nodeId}
            key={element.id}
            color={color}
            bgColor={bgColor}
            itemColor={itemColor}
          ></StyledTreeItem>
        );
      })}
    </StyledTreeItemRoot>
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelText: PropTypes.string.isRequired,
};

const styles = {
  textAlign: "left",
  whiteSpace: "initial",
};

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItemState(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event) => {
    handleSelection(event);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      draggable
      ref={ref}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography
        onClick={handleSelectionClick}
        component="div"
        className={classes.label}
      >
        {label}
      </Typography>
    </div>
  );
});

export default StyledTreeItem;
