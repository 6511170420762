import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Box, Tab, Tabs } from "@mui/material";
import { ReporteResumen } from "./reporteResumen";
import { PeriodoContext } from "../Context/periodoContext";
import { getReporteOrganizacional } from '../Api/evaluacionDesempeno.api';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div style={{ width: '100%' }}>
                    {children}
                </div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function ReporteAlineacionEstrategica() {
    const [tabIndex, setTabIndex] = useState(0);
    const [dataResumen, setDataResumen] = useState([]);
    const { fireReload } = React.useContext(PeriodoContext);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    useEffect(() => {
        obtenerResultados();
    }, []);
    
    useEffect(() => {
        obtenerResultados();
    }, [fireReload]);

    const obtenerResultados = async() => {
        try {
            const response = await getReporteOrganizacional()
            setDataResumen(response.body)
        } catch (error) {
            toast.error('No se pudo cargar el reporte') 
        }
    }

    return (
        <div>
            {/* <PermisionDeniedBar display={permissionDenied} /> */}
            <section
                style={{
                    fontSize: '30px',
                    textAlign: 'left',
                    marginLeft: '20px',
                    marginTop: '20px'
                }}
            >
                <b>Reporte de Alineación Estratégica</b>
            </section>
            <Box sx={{ margin: 1, width: "100%", height: "auto" }}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    variant="scrollable"
                >
                    <Tab label="Resumen" id={'tab-0'} />
                    {/* <Tab label="Objetivos" id={'tab-1'} /> */}
                </Tabs>
            </Box>
            <TabPanel value={tabIndex} index={0}>
                <ReporteResumen data={dataResumen} obtenerResultados={obtenerResultados}  />
            </TabPanel>
            {/* <TabPanel value={tabIndex} index={1}>
            </TabPanel> */}
        </div>
    );
}

export default ReporteAlineacionEstrategica;
