import React from 'react'
import { Divider } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

export function TooltipTemplateDS({ data, hideTooltips, ...props }) {

  console.log('props ', props)

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CancelIcon
          fontSize="small"
          onClick={hideTooltips}
          color="error"
          sx={{ marginTop: '-10px', marginRight: '-15px', cursor: 'pointer'	 }}
        />
      </div>
      <b>{data.argumentText}</b>
      <Divider />
      <span style={{ color: '#828181' }}>{data.valueText}</span>
    </div>
  )
}
