import React from "react";
import { useEffect, useState } from 'react'
import { Grid, Typography } from "@mui/material";
import { CustomizedGraph } from "../../Chart/graph";

const ResultadosGraph = ({ keyResult, mediciones }) => {

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1rem 3rem' }}>
      <CustomizedGraph
        titulo={keyResult?.nombre}
        descripcion={keyResult?.descripcion}
        titulos={mediciones?.periodos || []}
        resultados={mediciones?.resultados || []}
        etiquetaEjeX={null}
        graficarEjeXDiscreto={false}
        indicadorId={null}
        operacionIntegracion={keyResult?.modoIntegracionGlobalId}
        metaInversa={keyResult?.metaInversa}
        agrupado={false}
        tablaResultados={mediciones?.resultados || []}
        listaColumnas={[]}
        esParteRaci={false}
        updateResultados={null}
        height={'calc(100vh - 200px)'}
        maxWidth={window.innerWidth - 380}
        minWidth={window.innerWidth - 680}
        additionalButtons={[]}
      />
    </div>
  )

}

export default ResultadosGraph;
