import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Fab, Tab, Tabs } from "@mui/material";
import { toast } from "react-toastify";
import { PilaresList } from "./pilaresList";
import AddIcon from "@mui/icons-material/Add";
import { getPilares } from "../Api/pilares.api";
import { getObjetivos } from "../Api/objetivosPilar.api";
import Modal from "../general-components/modal";
import { PermisionDeniedBar } from "../general-components/permisionDeniedBar";
import { ConditionalRendering } from "../general-components/conditionalRendering";
import { PilarForm } from "./pilarForm";
import { ObjetivosList } from "./objetivosList";
import { ObjetivoForm } from "./objetivoForm";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{ width: '100%' }}>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function PilaresMain() {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [isModalOpen2, setModalOpen2] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [pilares, setPilares] = React.useState([]);
  const [objetivos, setObjetivos] = React.useState([]);
  const [pilarActivo, setPilarActivo] = React.useState({});
  const [objetivoActivo, setObjetivoActivo] = React.useState({});
  const [permissionDenied, setPermissionDenied] = React.useState(false);

  useEffect(() => {
    obtenerPilares();
    obtenerObjetivos();
  }, []);

  function obtenerPilares() {
    getPilares().then((response) => {
      if (response.status === 200) {
        setPilares(response.body);
      } else if (response.status === 403) {
        setPermissionDenied(true);
      } else {
        toast.error(response.message);
      }
      setPilarActivo({});
    });
  }

  function obtenerObjetivos() {
    getObjetivos().then((response) => {
      if (response.status === 200) {
        setObjetivos(response.body);
      } else if (response.status === 403) {
        setPermissionDenied(true);
      } else {
        toast.error(response.message);
      }
      setPilarActivo({});
    });
  }

  function closeModal() {
    setModalOpen(false);
    setPilarActivo({});
  }

  function openModal() {
    setPilarActivo({});
    setModalOpen(true);
  }

  function closeModalObjetivo() {
    setModalOpen2(false);
    setObjetivoActivo({});
  }

  function openModalObjetivo() {
    setObjetivoActivo({});
    setModalOpen2(true);
  }

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <div>
      <PermisionDeniedBar display={permissionDenied} />
      <Box sx={{ margin: 1, width: "100%", height: "auto" }}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          variant="scrollable"
        >
          <Tab label="Pilares" id={'tab-0'} />
          <Tab label="Objetivos" id={'tab-1'} />

        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <PilaresList
          pilares={pilares}
          setModalOpen={setModalOpen}
          setPilarActivo={setPilarActivo}
          obtenerPilares={obtenerPilares}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <ObjetivosList
          objetivos={objetivos}
          setModalOpen={setModalOpen2}
          setObjetivoActivo={setObjetivoActivo}
          obtenerObjetivos={obtenerObjetivos}
        />
      </TabPanel>
      <ConditionalRendering condition={!permissionDenied}>
        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: "fixed",
            right: 10,
            bottom: 10,
            backgroundColor: "#aebd36",
          }}
          onClick={() => tabIndex == 0 ? openModal() : openModalObjetivo()}
        >
          <AddIcon />
        </Fab>
        <Modal open={isModalOpen} handleClose={closeModal} title="Nuevo Pilar">
          <PilarForm
            closeModal={closeModal}
            obtenerPilares={obtenerPilares}
            pilarActivo={pilarActivo}
          />
        </Modal>
        <Modal open={isModalOpen2} handleClose={closeModalObjetivo} title="Nuevo Objetivo">
          <ObjetivoForm
            closeModal={closeModalObjetivo}
            obtenerObjetivos={obtenerObjetivos}
            objetivoActivo={objetivoActivo}
            pilares={pilares}
          />
        </Modal>
      </ConditionalRendering>
    </div>
  );
}

export { PilaresMain };
