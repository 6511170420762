import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";

function Calculadora({ expresion, setExpresion }) {
  const screenRef = React.useRef();

  useEffect(() => {
    screenRef.current.focus();
  }, [expresion]);
  return (
    <div>
      <Grid container spacing={2}>
        <PantallaCalculadora
          expresion={expresion}
          setExpresion={setExpresion}
          screenRef={screenRef}
        />
        <BotonCalculadora
          text="AC"
          bgcolor="#a5a5a5"
          setExpresion={setExpresion}
          behavior={() => setExpresion("")}
        />
        <BotonCalculadora
          text="("
          bgcolor="#a5a5a5"
          setExpresion={setExpresion}
        />
        <BotonCalculadora
          text=")"
          bgcolor="#a5a5a5"
          setExpresion={setExpresion}
        />
        <BotonCalculadora
          text="/"
          bgcolor="#f69906"
          setExpresion={setExpresion}
        />
        <BotonCalculadora text="7" setExpresion={setExpresion} />
        <BotonCalculadora text="8" setExpresion={setExpresion} />
        <BotonCalculadora text="9" setExpresion={setExpresion} />
        <BotonCalculadora
          text="x"
          bgcolor="#f69906"
          setExpresion={setExpresion}
        />
        <BotonCalculadora text="4" setExpresion={setExpresion} />
        <BotonCalculadora text="5" setExpresion={setExpresion} />
        <BotonCalculadora text="6" setExpresion={setExpresion} />
        <BotonCalculadora
          text="-"
          bgcolor="#f69906"
          setExpresion={setExpresion}
        />
        <BotonCalculadora text="1" setExpresion={setExpresion} />
        <BotonCalculadora text="2" setExpresion={setExpresion} />
        <BotonCalculadora text="3" setExpresion={setExpresion} />
        <BotonCalculadora
          text="+"
          bgcolor="#f69906"
          setExpresion={setExpresion}
        />
        <BotonCalculadora text="0" setExpresion={setExpresion} />
        <BotonCalculadora text="." setExpresion={setExpresion} />
        <BotonCalculadora text="Ind" setExpresion={setExpresion} />
        <BotonCalculadora
          text="="
          bgcolor="#f69906"
          setExpresion={setExpresion}
        />
      </Grid>
    </div>
  );
}

function BotonCalculadora({
  text,
  bgcolor = "#6c6c6c",
  color = "#fff",
  xs = 3,
  setExpresion,
  behavior = null,
}) {
  const style = { ...Styles.boton };
  style.color = color;
  style.backgroundColor = bgcolor;
  return (
    <Grid item xs={xs}>
      <div
        style={style}
        onClick={
          behavior === null
            ? () => setExpresion((expresion) => expresion + text)
            : behavior
        }
      >
        <b>{text}</b>
      </div>
    </Grid>
  );
}

function PantallaCalculadora({ expresion, setExpresion, screenRef }) {
  const [fontSize, setFontSize] = React.useState("1.2rem");

  useEffect(() => {    
    if (expresion.length > 70) {
      setFontSize("0.8rem");
    } else if (expresion.length > 40) {
      setFontSize("1rem");
    }else {
      setFontSize("1.2rem");
    }
  }, [expresion]);

  function onFocus(e) {  

    if (screenRef.current) {
      screenRef.current.focus();
      e.currentTarget.setSelectionRange(expresion.length, expresion.length);
    }
  }

  return (
    <Grid item xs={12} tabIndex={0} onKeyPress={(e) => console.log(e.key)}>
      <textarea
        ref={screenRef}
        type="text"
        style={{...Styles.pantalla, fontSize}}
        value={expresion}
        onChange={(e) => setExpresion(e.target.value)}
        onFocus={onFocus}
      />
    </Grid>
  );
}
const Styles = {
  pantalla: {
    borderRadius: "10px",
    backgroundColor: "#403e3e",
    letterSpacing: "0.15rem",
    color: "#fff",
    minHeight: "5rem",
    overflowY: "scroll",
    margin: "0 auto",
    padding: "0px 10px",
    width: "100%",
  },
  boton: {
    fontSize: "1rem",
    borderRadius: "50px",
    backgroundColor: "#6c6c6c",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "3rem",
  },
};
export { Calculadora };
