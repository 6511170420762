import React from "react";
import {
  IconButton,
  TextField,
  Tooltip,
  Typography,
  Grid,
  Button,
  MenuItem
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import { getMetas, putResultado, putMetas } from "../Api/resultado.api";
import { toast } from "react-toastify";
import { DataGrid } from "devextreme-react";
import Modal from '../general-components/modal'
import {
  Column,
  FilterPanel,
  FilterRow,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import moment from "moment";
import SaveIcon from "@mui/icons-material/Save";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import { LoadingButton } from '@mui/lab'
import { PeriodoContext } from "../Context/periodoContext";

const ENTER = 13;
let previousValue = null;

function MetasMain() {
  const { nodoId } = useParams();
  const navigate = useNavigate();
  const [nodo, setNodo] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [evaluacionId, setEvaluacionId] = useState(null);
  const [meta, setMeta] = useState(null);
  const [disableButtons, setDisableButtons] = useState(false);

  const { getSelected, getUnicoPeriodoSeleccionado } = React.useContext(PeriodoContext);

  useEffect(() => {
    updateMetas();
  }, [nodoId]);

  const permisoReinicio = () => {
    if (getSelected().length === 0) {
      return [false, 'Debes seleccionar un periodo para reiniciar los indicadores'];
    }
    else if (getSelected().length > 1) {
      return [false, 'Solo puedes seleccionar un periodo a la vez'];
    }
    else if (!getUnicoPeriodoSeleccionado()?.recalculoHabilitado) {
      return [false, 'El periodo seleccionado no tiene habilitado el recálculo de indicadores'];
    }
    return [true, ''];
  }

  function updateMetas() {
    const permiso = permisoReinicio();
    if (permiso[0]) {
      getMetas(nodoId).then((response) => {
        if (response.status === 200) {
          setNodo(response.body);
        } else {
          toast.error("Error al recuperar los resultados");
        }
      });
    } else {
      toast.error(permiso[1]);
    }
  }

  function obtenerValoresIndicadores(array) {
    const valoresUnicos = new Set();
    array.forEach(item => {
      const combinacion = `${item['Indicador']}-${item['evaluacionId']}`;
      valoresUnicos.add(combinacion);
    });
    return Array.from(valoresUnicos).map(combinacion => {
      const [indicador, id] = combinacion.split('-');
      return { ['Indicador']: indicador, ['evaluacionId']: parseInt(id, 10) };
    });
  }

  function onCloseModal() {
    setOpenModal(false);
    setEvaluacionId(null);
    setMeta(null);
  }

  function actualizarMetas() {
    const permiso = permisoReinicio();
    if (!evaluacionId || !meta) {
      return toast.error('Por favor ingresa todos los campos');
    }
    if (permiso[0]) {
      setDisableButtons(true);
      putMetas({ id: evaluacionId, meta }).then(response => {
        if (response.status === 200) {
          toast.success('Metas actualizadas');
          updateMetas();
          onCloseModal();
        } else {
          toast.error('Error al actualizar las metas');
        }
        setDisableButtons(false);
      });
    } else {
      toast.error(permiso[1]);
    }
  }

  return (
    <div>
      <IconButton
        onClick={() => navigate(`/home/procesos/${nodoId}`)}
        style={{ float: "left", marginLeft: "1rem" }}
      >
        <ArrowBackIcon color="primary" />
      </IconButton>
      <Typography variant="h4" noWrap component="div">
        Editor de metas
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'column', margin: "0 auto", width: "95%" }}>
        <div style={{ display: 'flex', margin: '1rem 0' }}>
          <Button
            variant="contained"
            endIcon={<DesignServicesIcon />}
            disabled={!permisoReinicio()[0]}
            onClick={() => setOpenModal(true)}
          >
            Editar metas masivas
          </Button>
        </div>
        <MetasTable resultados={nodo.metas} updateMetas={updateMetas} disabledUpdate={!permisoReinicio()[0]} />
      </div>
      <Modal
        open={openModal}
        handleClose={onCloseModal}
        closeAfterTransition
        title="Editar metas para indicador"
      >
        <Grid container spacing={3} sx={{ marginTop: '0px' }}>
          <Grid item xs={12}>
            <TextField
              id="evaluacionId"
              label="Indicador"
              variant="outlined"
              select
              value={evaluacionId}
              style={{ width: '100%' }}
              onChange={(e) => setEvaluacionId(e.target.value)}
              required
            >
              {obtenerValoresIndicadores(nodo.metas || []).map((item) => (
                <MenuItem key={item.evaluacionId} value={item.evaluacionId}>
                  {item.Indicador}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="meta"
              label="Meta"
              variant="outlined"
              value={meta}
              type={'number'}
              style={{ width: '100%' }}
              onChange={(e) => setMeta(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1rem', float: 'right' }}>
            <LoadingButton
              loading={disableButtons}
              disabled={disableButtons}
              onClick={() => actualizarMetas()}
              color='primary'
              variant='contained'
            >
              <span>Guardar</span>
            </LoadingButton>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}

function MetasTable({ resultados, updateMetas, disabledUpdate }) {
  const [valores, setValores] = React.useState({});

  useEffect(() => {
    if (resultados) {
      const vals = {};
      resultados.forEach((resultado) => {
        vals[resultado.id] = resultado.meta;
      });
      setValores(vals);
    }
  }, [resultados]);

  const updateValoresResultados = (valor, id) => {
    valores[id] = valor;
  };

  function handleOnBlur(rowId) {
    const valorResultado = valores[rowId];
    if (isNaN(valorResultado) || valorResultado === null) return;
    if (valorResultado === previousValue) return;
    previousValue = null;
    saveResultado(rowId);
  }

  function handleOnFocus(rowId) {
    const valorResultado = valores[rowId];
    previousValue = valorResultado;
  }

  function handleKeyDown(keyCode, rowId) {
    if (keyCode === ENTER) {
      saveResultado(rowId);
    }
  }

  const saveResultado = (rowId) => {
    const valorMeta = valores[rowId];
    if (isNaN(valorMeta) || valorMeta === null) {
      return toast.error("Por favor ingresa un valor numérico");
    }
    putResultado({ id: rowId, meta: parseFloat(valorMeta) }).then(
      (response) => {
        if (response.status === 200) {
          updateMetas();
          toast.success("Meta modificada");
        } else {
          toast.error("Error al modificar la meta");
        }
      }
    );
  };

  const inputColumn = (rowInfo) => {
    if (rowInfo.data.esCalculado && rowInfo.data.modoIntegracionId === 2)
      return <b style={{ fontSize: "0.9rem" }}>{rowInfo.data.meta}</b>;
    return (
      <TextField
        id="descripcion"
        variant="standard"
        type="number"
        defaultValue={rowInfo.data.meta}
        style={{ width: "100%" }}
        onChange={(e) =>
          updateValoresResultados(e.target.value, rowInfo.data.id)
        }
        disabled={disabledUpdate}
        onKeyDown={(e) => handleKeyDown(e.keyCode, rowInfo.data.id)}
        onBlur={(e) => handleOnBlur(rowInfo.data.id)}
        onFocus={(e) => handleOnFocus(rowInfo.data.id)}
      />
    );
  };

  const saveColumn = (rowInfo) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Tooltip title="Guardar">
          <div>
            <IconButton
              aria-label="save"
              color="warning"
              onClick={() => saveResultado(rowInfo.data.id)}
              disabled={
                Boolean(rowInfo.data.esCalculado && rowInfo.data.modoIntegracionId === 2)
              }
            >
              <SaveIcon />
            </IconButton>
          </div>
        </Tooltip>
      </div>
    );
  };
  return (
    <DataGrid
      dataSource={resultados}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnResizingMode="nextColumn"
    // defaultColumns={columns}
    // rowRender={plantillaFila}
    >
      {/* <ColumnChooser enabled={true} /> */}
      <GroupPanel visible={true} />
      {/*<ColumnFixing enabled={true} />
        <Scrolling mode="virtual" /> */}

      <Column dataField="id" caption="id" dataType="number" visible={false} />
      <Column
        dataField="meta"
        caption="Meta"
        dataType="number"
        visible={false}
      />
      <Column
        dataField="modoIntegracionId"
        caption="Modo de Integración"
        dataType="number"
        visible={false}
      />
      <Column
        dataField="esCalculado"
        caption="¿Es calculado?"
        dataType="boolean"
        visible={false}
      />

      <Column
        dataField="Indicador"
        caption="Indicador"
        dataType="string"
        allowSorting={true}
      />

      <Column
        dataField="fechaInicio"
        caption="Fecha Inicio"
        dataType="datetime"
        cellRender={(rowInfo) =>
          moment(rowInfo.data.fechaInicio).utc().format("D MMMM YYYY, h:mm:ss a")
        }
        allowSorting={true}
      />
      <Column
        dataField="fechaFin"
        caption="Fecha Fin"
        dataType="datetime"
        cellRender={(rowInfo) =>
          moment(rowInfo.data.fechaFin).utc().format("D MMMM YYYY, h:mm:ss a")
        }
        allowSorting={true}
      />

      <Column
        caption="Resultado"
        dataField="valorNumerico"
        allowFiltering={true}
        allowSorting={true}
        visible
      />
      <Column
        caption="Responsable"
        dataField="Responsable"
        allowFiltering={true}
        allowSorting={true}
        visible
      />
      <Column
        caption="Meta"
        allowFiltering={false}
        allowSorting={false}
        cellRender={inputColumn}
        visible
      />
      <Column
        caption="Opciones"
        allowFiltering={false}
        allowSorting={false}
        cellRender={saveColumn}
      />
      <FilterRow visible={true} />
      <FilterPanel visible={false} />
      {/* <FilterBuilderPopup position={filterBuilderPopupPosition} /> */}
      <HeaderFilter visible={true} />
      {/* <Scrolling mode="infinite" /> */}

      {/* <GroupPanel visible={true} /> */}
      <SearchPanel visible={true} highlightCaseSensitive={true} />
      {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}
      <Paging defaultPageSize={50} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[50, 100, 150, 200]}
        showInfo={true}
      />
    </DataGrid>
  );
}

export { MetasMain };
