import { HOST } from "../Utils/config";
import { getRequest } from "./requests";

async function getUnidadMedidaIndicador() {
  return await getRequest(`${HOST}/api/v1/unidadesmedidaindicador`);  
}

async function getUnidadesMedida() {
  return await getRequest(`${HOST}/api/v1/unidadmedida`);  
}


export { getUnidadMedidaIndicador, getUnidadesMedida };