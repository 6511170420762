import { HOST } from '../Utils/config'
import { deleteRequest, getRequest, postRequest, putRequest } from './requests'

async function getAgrupacion({idAgrupacion}) {
  return await getRequest(`${HOST}/api/v1/agrupaciones/${idAgrupacion}`)
}

async function postAgrupacion(agrupacion) {
  return await postRequest(`${HOST}/api/v1/agrupaciones`, agrupacion)
}

async function putAgrupacion(agrupacion) {
  return await putRequest(`${HOST}/api/v1/agrupaciones`, agrupacion)
}

async function deleteAgrupacion(agrupacionId) {
  return await deleteRequest(`${HOST}/api/v1/agrupaciones/${agrupacionId}`)
}

export { getAgrupacion, postAgrupacion, putAgrupacion, deleteAgrupacion }
