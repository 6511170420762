import { CloseFullscreen } from "@mui/icons-material";
import { Button, Chip, Grid, Paper, TextField } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";

export default function TabForm({addFn,closeFn}){
    const [nombre,setNombre] =  React.useState("");

    const guardar = async()=>{
        if(nombre!== "" && nombre !==null){
            addFn(nombre);
            closeFn();
        }
        
    }

    return(
        <div>
              
         <Grid container spacing={3} sx={{ marginTop: "0px" }}>
          <Grid item xs={12}>
            <TextField
              id="nombre"
              label="Nombre"
              variant="outlined"
              value={nombre}
              style={{ width: "100%" }}
              onChange={(e) => setNombre(e.target.value)}
              required
            />
          </Grid>
          <Button
            variant="contained"
            sx={{
              height: "50px",
              justifySelf: "center",
              alignSelf: "center",
              margin: "0 auto",
              marginTop: "20px",
            }}
            onClick={guardar}
          >
            Guardar
          </Button>
          </Grid>
        </div>
    );
}