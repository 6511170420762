import React, { useRef } from 'react'
import { Divider, InputAdornment } from '@mui/material'

import TextField from '@mui/material/TextField'
import { putObjetivoDesempenoUsuarioIndicador } from '../Api/objetivoDesempenoUsuarioIndicador.api'
import { toast } from 'react-toastify'
import { PeriodoContext } from '../Context/periodoContext'

export function IndicadorChip({
  id,
  indicadorId,
  nombre,
  actividad,
  responsabilidad,
  objetivoId,
  peso,
  disabled,
  showPeso,
  updateUI,
  pesoTotal
}) {
  const pesoRef = useRef()
  const pesoAnterior = useRef(peso)

  const { getSelected, getUnicoPeriodoSeleccionado } = React.useContext(PeriodoContext);

  const permisoReinicio = () => {
    if (getSelected().length === 0) {
      return [false, 'Debes seleccionar un periodo para reiniciar los indicadores'];
    }
    else if (getSelected().length > 1) {
      return [false, 'Solo puedes seleccionar un periodo a la vez'];
    }
    else if (!getUnicoPeriodoSeleccionado()?.recalculoHabilitado) {
      return [false, 'El periodo seleccionado no tiene habilitado el recálculo de indicadores'];
    }
    return [true, ''];
  }

  function dragStart(e) {
    console.log({ id, indicadorId })
    e.dataTransfer.setData(
      'item',
      JSON.stringify({ id, indicadorId, objetivoId })
    )
  }

  const validarLimite = (pesoAnterior, pesoNuevo) => {
    const pesoActualizado = (parseFloat(pesoTotal) - parseFloat(pesoAnterior)) + parseFloat(pesoNuevo);
    return pesoActualizado > 100
  }

  async function updatePeso() {
    console.log('peso anterior ', pesoAnterior?.current)
    const [permiso, mensaje] = permisoReinicio();
    if (!permiso) {
      toast.error(mensaje);
      pesoRef.current.value = pesoAnterior.current;
      return;
    }
    
    if (!pesoRef.current.value) return
    if (validarLimite(pesoAnterior.current, pesoRef.current.value)) {
      pesoRef.current.value = pesoAnterior.current;
      toast.warning('El peso asignado supera el 100%')
      return 
    }
    const resp = await putObjetivoDesempenoUsuarioIndicador(id, {
      peso: pesoRef.current.value
    })
    if (resp.status === 200) {
      toast.success('Peso actualizado')
    }
    const pesoNumerico = parseFloat(pesoRef.current.value)
    updateUI(indicadorId, pesoNumerico)
    pesoAnterior.current = pesoNumerico
  }

  return (
    <div
      draggable
      onDragStart={dragStart}
      style={{
        display: 'inline-block',
        margin: '5px',
        padding: '10px 20px',
        borderRadius: '10px',
        backgroundColor: '#f3efef',
        color: '#000',
        cursor: 'grab'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '5px'
        }}
      >
        <div style={{ display: 'flex', gap: '5px' }}>
          <b>Actividad:</b>
          {actividad}
        </div>
        <div style={{ display: 'flex', gap: '5px' }}>
          <b>Indicador:</b>
          {nombre}
        </div>
        {showPeso && (
          <div
            style={{
              display: 'flex',
              gap: '5px',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <b>Peso:</b>
            <TextField
              disabled={disabled}
              id="outlined-basic"
              variant="standard"
              type="number"
              defaultValue={disabled ? null : peso}
              inputRef={pesoRef}
              sx={{ width: 'auto', marginLeft: '10px' }}
              onBlur={updatePeso}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />
          </div>
        )}
      </div>
      <Divider />
      <span style={{ fontSize: '0.8rem' }}>
        <b>{responsabilidad}</b>
      </span>
    </div>
  )
}
