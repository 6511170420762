import React from "react";
import DataGrid, {
    Column,
    Grouping,
    FilterRow,
    HeaderFilter,
    FilterPanel,
    Pager,
    Paging,
    ColumnChooser,
    GroupPanel,
    SearchPanel,
    Toolbar,
    Item,
    Sorting,
    StateStoring,
} from "devextreme-react/data-grid";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import 'devextreme/dist/css/dx.light.css';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    getConfiguracionNiveles,
    createConfiguracionNiveles,
    updateConfiguracionNiveles,
    deleteConfiguracionNiveles,
    getDiagramaCajasConfig,
    updateDiagramaCajasConfig
} from "../../../Api/configuracion.api";
import { PeriodoContext } from "../../../Context/periodoContext";
import { hasSameItems } from "../../../Utils/utilities";
import { Button as GridButton } from 'devextreme-react/button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import ColorizeIcon from '@mui/icons-material/Colorize';
import DoneIcon from '@mui/icons-material/Done';
import Button from '@mui/material/Button';
import CrearMatrizPuntos from "./crear";
import { toast } from "react-toastify";
import DeleteDialog from "../../../Custom/DeleteDialog";
import DiagramaCajas from "../../../Custom/diagramaCajas";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { ColorPicker } from 'primereact/colorpicker';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import TextField from '@mui/material/TextField';
import useEyeDropper from 'use-eye-dropper'

const Nivel = () => {

    const { getSelected, periodos: allPeriodos } = React.useContext(PeriodoContext);

    const { open: dropperOpen, isSupported } = useEyeDropper()

    const [loading, setLoading] = React.useState(false);
    const [deleteDialog, setDeleteDialog] = React.useState({ open: false });
    const [periodos, setPeriodos] = React.useState([]);
    const [configuracion, setConfiguracion] = React.useState({}); // valores de la matriz editando
    const [selectedConfiguracion, setSelectedConfiguracion] = React.useState(null);
    const [diagramaCajas, setDiagramaCajas] = React.useState({ open: false });
    const [configuraciones, setConfiguraciones] = React.useState([]);

    const onCreationClose = () => {
        setConfiguracion({ open: false });
    }

    const onDiagramaCajasClose = () => {
        setSelectedConfiguracion(null);
        setDiagramaCajas({ open: false });
        setConfiguracion({});
    }

    const saveDiagramaCajas = () => {
        if (diagramaCajas?.periodoId) {
            setLoading(true);
            updateDiagramaCajasConfig(diagramaCajas.periodoId, { config: configuracion })
                .then((response) => {
                    if (response.status === 200) {
                        onDiagramaCajasClose();
                        toast.success("Configuración actualizada exitosamente!");
                    } else {
                        toast.error(response.message);
                    }
                })
                .catch((error) => {
                    toast.error('Error al momento de actualizar la configuración');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    // Obtener periodos actuales
    React.useEffect(() => {
        const temp_periodos = getSelected();
        const newPeriodos = !hasSameItems(temp_periodos, periodos);
        if (newPeriodos) {
            setPeriodos(temp_periodos);
        }
    }, [getSelected()]);

    // Actualizar conforme los periodos
    React.useEffect(() => {
        if (periodos && periodos.length > 0) {
            setLoading(true);
            getConfiguracionNiveles(periodos)
                .then((response) => {
                    const { body } = response;
                    setConfiguraciones(body || []);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [periodos]);

    return (
        <div>
            <section
                style={{
                    padding: "10px 0",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px 3%",
                }}
            >
                <DataGrid
                    dataSource={configuraciones}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnResizingMode="nextColumn"
                >
                    <Toolbar>
                        <Item location="after">
                            <GridButton
                                text="Agregar"
                                onClick={() => {
                                    setConfiguracion({ open: true });
                                }}
                                icon="add"
                            />
                        </Item>
                        {/* <Item name="groupPanel" /> */}
                        {/* <Item name="columnChooserButton" /> */}
                        <Item name="searchPanel" />
                    </Toolbar>
                    <Column dataField="id" caption="id" dataType="number" visible={false} />
                    <Column
                        dataField="periodoNombre"
                        caption="Período"
                        groupIndex={0}
                        groupCellRender={(group) => {
                            let items = group?.data?.items || [];
                            items = items.length > 0 ? items[0].items : [];
                            const rowInfo = items.length > 0 ? items[0] : {};
                            const { periodoId } = rowInfo;
                            return (
                                <>
                                    <span>{group.value}</span>
                                    <div
                                        style={{ display: 'inline-block', marginLeft: '10px' }}
                                    >
                                        <Button
                                            startIcon={<EditIcon />}
                                            variant="outlined"
                                            size="small"
                                            onClick={() => {
                                                setLoading(true);
                                                getDiagramaCajasConfig(periodoId)
                                                    .then((response) => {
                                                        const { body } = response;
                                                        const { configuracion } = body;
                                                        delete body.configuracion;
                                                        setDiagramaCajas({ open: true, data: body, periodoId });
                                                        setConfiguracion(configuracion || {})
                                                    })
                                                    .catch((error) => {
                                                        toast.error('Error al momento de obtener el diagrama de cajas');
                                                    })
                                                    .finally(() => {
                                                        setLoading(false);
                                                    });
                                            }}
                                        >
                                            Diagrama de cajas
                                        </Button>
                                    </div>
                                </>
                            )
                        }}
                    />
                    <Column
                        dataField="eje"
                        caption="Eje"
                        groupIndex={1}
                    />
                    <Column
                        dataField="rango_inicio"
                        caption="Rango Inicio"
                    />
                    <Column
                        dataField="rango_fin"
                        caption="Rango Fin"
                    />
                    <Column
                        dataField="ponderacionNombre"
                        caption="Ponderación"
                    />
                    <Column
                        width={'auto'}
                        dataField="id"
                        caption=""
                        allowFiltering={false}
                        allowSorting={false}
                        allowSearch={false}
                        allowGrouping={false}
                        allowReordering={false}
                        allowResizing={false}
                        cellRender={(rowInfo) => {
                            const { id, rango_inicio, rango_fin, eje, ponderacionId, periodoId } = rowInfo.data
                            return (
                                <span>
                                    <IconButton aria-label="edit" onClick={() => {
                                        setConfiguracion({ open: true, id, rango_inicio, rango_fin, eje, ponderacionId, periodoId });
                                    }}>
                                        <EditIcon sx={{ color: "#ffd105" }} />
                                    </IconButton>
                                    <IconButton aria-label="delete" onClick={() => {
                                        setDeleteDialog({
                                            open: true,
                                            handleDelete: () => {
                                                setLoading(true);
                                                deleteConfiguracionNiveles(id, periodos)
                                                    .then((response) => {
                                                        if (response.status === 200) {
                                                            const { body } = response;
                                                            setConfiguraciones(body?.body || []);
                                                            toast.success("Configuración eliminada exitosamente!");
                                                            setDeleteDialog({ open: false });
                                                        } else {
                                                            toast.error(response.message);
                                                        }
                                                    })
                                                    .catch((error) => {
                                                        toast.error('Error al momento de eliminar la configuración');
                                                    })
                                                    .finally(() => {
                                                        setLoading(false);
                                                    });
                                            }
                                        })
                                    }}>
                                        <DeleteIcon sx={{ color: "red" }} />
                                    </IconButton>
                                </span>
                            )
                        }
                        }
                    />
                    <Grouping autoExpandAll={true} />
                    <StateStoring
                        enabled={true}
                        type="localStorage"
                        storageKey={'NivelesConfigGRID'}
                    />
                    {/* <ColumnChooser
                        enabled={true}
                        mode="dragAndDrop"
                    /> */}
                    <GroupPanel visible={true} />
                    <FilterRow visible={true} />
                    <FilterPanel visible={false} />
                    <HeaderFilter visible={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                    <Paging defaultPageSize={50} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[50, 100, 150, 200]}
                        showInfo={true}
                    />
                </DataGrid>
            </section>
            {/* Creación/Edición configuración */}
            <Dialog
                fullWidth
                maxWidth={"md"}
                open={configuracion.open}
                onClose={onCreationClose}
            >
                <DialogTitle>
                    {configuracion.id ? "Editar" : "Crear"} configuración
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onCreationClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <CrearMatrizPuntos
                        configuracion={configuracion}
                        setConfiguracion={setConfiguracion}
                        periodos={allPeriodos.filter((periodo) => periodos.includes(periodo.id))}
                    />
                </DialogContent>
                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ padding: "1em 1em 1em 0em" }}>
                        <Button variant="text" onClick={onCreationClose}>
                            Cancelar
                        </Button>
                        <Button variant="contained" onClick={() => {
                            const { rango_inicio, rango_fin, eje, ponderacionId, periodoId, id } = configuracion;
                            const required = [rango_inicio, rango_fin, eje, ponderacionId, periodoId];
                            if (!required.includes(null) && !required.includes(undefined) && !required.includes('')) {
                                if (id) {
                                    // Editar
                                    setLoading(true);
                                    updateConfiguracionNiveles(id, configuracion, periodos)
                                        .then((response) => {
                                            if (response.status === 200) {
                                                const { body } = response;
                                                setConfiguraciones(body?.body || []);
                                                onCreationClose();
                                                toast.success("Configuración actualizada exitosamente!");
                                            } else {
                                                toast.error(response.message);
                                            }
                                        })
                                        .catch((error) => {
                                            toast.error('Error al momento de actualizar la configuración');
                                        })
                                        .finally(() => {
                                            setLoading(false);
                                        });
                                } else {
                                    // Crear
                                    setLoading(true);
                                    createConfiguracionNiveles(configuracion, periodos)
                                        .then((response) => {
                                            if (response.status === 201) {
                                                const { body } = response;
                                                setConfiguraciones(body?.body || []);
                                                onCreationClose();
                                                toast.success("Configuración creada exitosamente!");
                                            } else {
                                                toast.error(response.message);
                                            }
                                        })
                                        .catch((error) => {
                                            toast.error('Error al momento de crear la configuración');
                                        })
                                        .finally(() => {
                                            setLoading(false);
                                        });
                                }
                            } else {
                                toast.error("Por favor, llene todos los campos");
                            }
                        }} >
                            Guardar
                        </Button>
                    </div>
                </div>
            </Dialog>
            {/* Diálogo de eliminación */}
            <DeleteDialog
                {...deleteDialog}
                handleClose={() => setDeleteDialog({ open: false })}
            />
            {/* Diagrama de cajas */}
            <Dialog
                fullWidth
                maxWidth={"lg"}
                open={diagramaCajas?.open}
                onClose={onDiagramaCajasClose}
            >
                <DialogTitle>
                    Configuración Diagrama de Cajas
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ width: '100%' }}>
                        <Grid container rowSpacing={1} columnSpacing={1}>
                            {!selectedConfiguracion && (
                                <Grid xs={2} />
                            )}
                            <Grid xs={8}>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                    <div style={{ width: '70%' }}>
                                        <DiagramaCajas
                                            editable={true}
                                            plantilla={diagramaCajas?.data || {}}
                                            configuracion={configuracion}
                                            setSelectedConfiguracion={setSelectedConfiguracion}
                                            selectedItem={selectedConfiguracion}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid xs>
                                {selectedConfiguracion && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                padding: '0.5em 0'
                                            }}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Nombre"
                                                variant="outlined"
                                                value={configuracion[selectedConfiguracion]?.nombre || ''}
                                                onChange={(e) => {
                                                    const newConfiguracion = { ...configuracion };
                                                    newConfiguracion[selectedConfiguracion] = {
                                                        ...newConfiguracion[selectedConfiguracion],
                                                        nombre: e.target.value
                                                    };
                                                    setConfiguracion(newConfiguracion);
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                padding: '0.5em 0'
                                            }}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Valor"
                                                variant="outlined"
                                                value={configuracion[selectedConfiguracion]?.valor || ''}
                                                onChange={(e) => {
                                                    const newConfiguracion = { ...configuracion };
                                                    newConfiguracion[selectedConfiguracion] = {
                                                        ...newConfiguracion[selectedConfiguracion],
                                                        valor: e.target.value
                                                    };
                                                    setConfiguracion(newConfiguracion);
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                padding: '0.5em 0'
                                            }}
                                        >
                                            <div
                                                style={{ display: 'flex', width: '50%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
                                            >
                                                <label htmlFor="headerColorPicker" style={{ textAlign: 'center' }}>
                                                    Color de texto
                                                </label>
                                                <ColorPicker
                                                    inputId="headerColorPicker"
                                                    value={configuracion[selectedConfiguracion]?.headerColor || "000000"}
                                                    onChange={(e) => {
                                                        const newConfiguracion = { ...configuracion };
                                                        newConfiguracion[selectedConfiguracion] = {
                                                            ...newConfiguracion[selectedConfiguracion],
                                                            headerColor: e.value
                                                        };
                                                        setConfiguracion(newConfiguracion);
                                                    }}
                                                />
                                                {isSupported() ?
                                                    <IconButton
                                                        aria-label="text-dropper"
                                                        size="small"
                                                        onClick={() => {
                                                            const openPicker = async () => {
                                                                try {
                                                                    const color = await dropperOpen()
                                                                    const newConfiguracion = { ...configuracion };
                                                                    newConfiguracion[selectedConfiguracion] = {
                                                                        ...newConfiguracion[selectedConfiguracion],
                                                                        headerColor: color.sRGBHex.replace("#", "")
                                                                    };
                                                                    setConfiguracion(newConfiguracion);
                                                                } catch (e) {
                                                                    console.log("colorError", e)
                                                                }
                                                            }
                                                            openPicker()
                                                        }}
                                                    >
                                                        <ColorizeIcon fontSize="inherit" />
                                                    </IconButton>
                                                    : null
                                                }
                                                <div
                                                    style={{
                                                        padding: '0.5em',
                                                    }}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="HEX"
                                                        size="small"
                                                        value={configuracion[selectedConfiguracion]?.headerColor || "000000"}
                                                        variant="outlined"
                                                        onChange={(e) => {
                                                            const newConfiguracion = { ...configuracion };
                                                            newConfiguracion[selectedConfiguracion] = {
                                                                ...newConfiguracion[selectedConfiguracion],
                                                                headerColor: e.target.value
                                                            };
                                                            setConfiguracion(newConfiguracion);
                                                        }}
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                style={{ display: 'flex', width: '50%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
                                            >
                                                <label htmlFor="backgroundColorPicker" style={{ textAlign: 'center' }}>
                                                    Color de fondo
                                                </label>
                                                <ColorPicker
                                                    inputId="backgroundColorPicker"
                                                    value={configuracion[selectedConfiguracion]?.backgroundColor || "ffffff"}
                                                    onChange={(e) => {
                                                        const newConfiguracion = { ...configuracion };
                                                        newConfiguracion[selectedConfiguracion] = {
                                                            ...newConfiguracion[selectedConfiguracion],
                                                            backgroundColor: e.value
                                                        };
                                                        setConfiguracion(newConfiguracion);
                                                    }}
                                                />
                                                {isSupported() ?
                                                    <IconButton
                                                        aria-label="bg-dropper"
                                                        size="small"
                                                        onClick={() => {
                                                            const openPicker = async () => {
                                                                try {
                                                                    const color = await dropperOpen()
                                                                    const newConfiguracion = { ...configuracion };
                                                                    newConfiguracion[selectedConfiguracion] = {
                                                                        ...newConfiguracion[selectedConfiguracion],
                                                                        backgroundColor: color.sRGBHex.replace("#", "")
                                                                    };
                                                                    setConfiguracion(newConfiguracion);
                                                                } catch (e) {
                                                                    console.log("colorError", e)
                                                                }
                                                            }
                                                            openPicker()
                                                        }}
                                                    >
                                                        <ColorizeIcon fontSize="inherit" />
                                                    </IconButton>
                                                    : null
                                                }
                                                <div
                                                    style={{
                                                        padding: '0.5em',
                                                    }}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label="HEX"
                                                        size="small"
                                                        value={configuracion[selectedConfiguracion]?.backgroundColor || "ffffff"}
                                                        variant="outlined"
                                                        onChange={(e) => {
                                                            const newConfiguracion = { ...configuracion };
                                                            newConfiguracion[selectedConfiguracion] = {
                                                                ...newConfiguracion[selectedConfiguracion],
                                                                backgroundColor: e.target.value
                                                            };
                                                            setConfiguracion(newConfiguracion);
                                                        }}
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* Boton listo */}
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                padding: '0.5em 0'
                                            }}
                                        >
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    setSelectedConfiguracion(null);
                                                }}
                                                startIcon={<DoneIcon />}
                                            >
                                                Listo
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDiagramaCajasClose}>Cancelar</Button>
                    <Button onClick={saveDiagramaCajas} variant='contained'>Guardar</Button>
                </DialogActions>
            </Dialog>
            {/* Pantalla de carga */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default Nivel;