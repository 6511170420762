import React from 'react';
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";
import KeyboardIcon from '@mui/icons-material/Keyboard';
import { NodoContext } from '../../Context/nodoContext';
import { PeriodoContext } from "../../Context/periodoContext";
import { AccordionOptions } from "../../Indicadores/accordionOptions";
import { SimpleDialog } from '../../Resultados/resultadosDigitador';
import { getResultadosOKRs } from '../../Api/okr.api';
import ResultadosGrid from './resultadosGrid';


export default function ResultadosOKRDigitador() {

  const [resultados, setResultados] = React.useState([]);
  const [atajosTeclado, setAtajosTeclado] = React.useState(false);
  const [expandedGroups, setExpandedGroups] = React.useState(false);

  const { setLoading, setLoadingMessage } = React.useContext(NodoContext);
  const { fireReload, obtenerPeriodosSeleccionados } = React.useContext(PeriodoContext);

  React.useEffect(() => {
    const isFirstVisit = !localStorage.getItem('atajosTeclado');
    if (isFirstVisit) {
      setTimeout(() => {
        setAtajosTeclado(true);
      }, 1000);
      localStorage.setItem('atajosTeclado', 'true');
    }
  }, []);

  React.useEffect(() => {
    updateResultados();
  }, [fireReload]);

  async function updateResultados() {
    try {
      setLoading(true);
      const response = await getResultadosOKRs([])
      if (response.status === 200) {
        setResultados(response.body);
      } else {
        toast.error("No se pudieron recuperar los resultados");
      }
    } catch (error) {
      console.error(error);
      toast.error(`Error al obtener los resultados: ${error.message}`);
    } finally {
      setLoading(false);
    }

  }

  const compressHandler = () => {
    setExpandedGroups(true);
    setTimeout(() => {
      setExpandedGroups(false);
    }, 100);
  }

  return (
    <React.Fragment>
      <section
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          fontSize: "30px",
          textAlign: "left",
          padding: "0.5rem 1rem",
        }}
      >
        <b>Mediciones OKR: </b>
        <div style={{ display: 'flex', justifyContent: 'right', margin: '10px' }}>
          <IconButton
            title="Atajos del teclado"
            onClick={() => setAtajosTeclado(!atajosTeclado)}
            color="info"
          >
            <KeyboardIcon />
          </IconButton>
          <AccordionOptions
            compressHandler={compressHandler}
            expandHandler={() => setExpandedGroups(true)}
            expandTitle="Expandir todos"
            compressTitle="Contraer todos"
          />
        </div>
      </section>
      <section>
        <ResultadosGrid
          resultados={resultados}
          updateResultados={updateResultados}
          allGroupsExpanded={expandedGroups}
          storageKey='resultadosOKRGrid'
        />
      </section>
      <SimpleDialog open={atajosTeclado} onClose={() => setAtajosTeclado(false)} />
    </React.Fragment>
  )
}