import { HOST } from "../Utils/config";
import { post } from "../Utils/api";
import { getRequest, postRequest, putRequest ,deleteRequest} from "./requests";

async function getTags(familyId) {
  return await getRequest(`${HOST}/api/v1/tag/${familyId}`);
}

async function getAllTags() {
  return await getRequest(`${HOST}/api/v1/tag/getalltags/`);
}

async function getTag(id) {
  return await getRequest(`${HOST}/api/v1/tag/${id}`);
}

async function postTag(data) {
  return await postRequest(`${HOST}/api/v1/tag`, data);
}

async function putTag(data) {
  return await putRequest(`${HOST}/api/v1/tag`, data);
}

async function deleteTag(id) {
  return await deleteRequest(`${HOST}/api/v1/tag/${id}`);
}

export {
  getTags,
  getTag,
  postTag,
  deleteTag,
  getAllTags,
};
