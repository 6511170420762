import React from 'react'
import { toast } from 'react-toastify'
import { putObjetivoDesempenoUsuarioIndicador } from '../Api/objetivoDesempenoUsuarioIndicador.api'
import { IndicadorChip } from './IndicadorChip'
import { Chip, Grid, Stack, Tooltip, Typography } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WarningIcon from '@mui/icons-material/Warning';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { PeriodoContext } from '../Context/periodoContext';

export function ObjetivoDrop({
  id,
  nombre,
  pesoMinimo = 0,
  showPeso = false,
  indicadores,
  handleIndicadorDrop,
  usuarioId,
  updateUI,
  pesoTotal
}) {

  const { getSelected, getUnicoPeriodoSeleccionado } = React.useContext(PeriodoContext);

  const permisoReinicio = () => {
    if (getSelected().length === 0) {
      return [false, 'Debes seleccionar un periodo para reiniciar los indicadores'];
    }
    else if (getSelected().length > 1) {
      return [false, 'Solo puedes seleccionar un periodo a la vez'];
    }
    else if (!getUnicoPeriodoSeleccionado()?.recalculoHabilitado) {
      return [false, 'El periodo seleccionado no tiene habilitado el recálculo de indicadores'];
    }
    return [true, ''];
  }

  const indicadoresFiltrados = indicadores
  .filter((indicador) => indicador.objetivoId === id);

  const aproximarPeso = (peso) => {
    return Number(parseFloat(peso).toFixed(4));
  }

  const pesoAsignado = indicadoresFiltrados.reduce((acc, indicador) => {
    return acc + aproximarPeso(indicador.peso);
  }, 0);

  function dragOver(e) {
    e.preventDefault()
  }

  async function drop(e) {
    e.preventDefault()
    const permiso = permisoReinicio();
    if (!permiso[0]) {
      return toast.error(permiso[1]);
    }
    const objetivoId = id
    const item = JSON.parse(e.dataTransfer.getData('item'))
    if(item.objetivoId === objetivoId) return 
    const requestData = {
      ObjetivoDesempenoId: objetivoId,
      IndicadorId: item.indicadorId,
      UsuarioId: usuarioId
    }
    if (objetivoId == null) requestData.peso = 0
    const resp = await putObjetivoDesempenoUsuarioIndicador(
      item.id,
      requestData
    )
    if (resp.status !== 200) {
      return toast.error('No se pudo actualizar el registro')
    }
    handleIndicadorDrop(item.indicadorId, objetivoId, resp.body.body)
  }

  return (
    <div style={{ width: '95%', margin: '10px auto' }}>
      <div
        style={{
          padding: showPeso ? '10px 10px' : '10px',
          borderRadius: '10px',
          backgroundColor: '#202e3c',
          color: '#fff'
        }}
      >
        {showPeso && (
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item container md={3} justifyContent={'flex-start'}>
              <AccountBalanceIcon/>
            </Grid>
            <Grid item md={6}>
              <Typography variant='h6'>{nombre}</Typography>
            </Grid>
            <Grid item container md={3} justifyContent={'flex-end'}>
              <Stack direction="row" spacing={1}>
                <Tooltip title={aproximarPeso(pesoAsignado) >= pesoMinimo ? 'Peso mínimo completado' : 'Aún no se ha completado el peso mínimo'}>
                  <Chip
                    icon={aproximarPeso(pesoAsignado) >= pesoMinimo ? <CheckBoxIcon /> : <WarningIcon/>}
                    label={`Peso asignado: ${aproximarPeso(pesoAsignado)}% de un mínimo: ${pesoMinimo} %`}
                    color={aproximarPeso(pesoAsignado) >= pesoMinimo ? 'primary' : 'warning'}
                  />
                </Tooltip>
              </Stack>
            </Grid>
          </Grid>
        )}
        {!showPeso && <h3>{nombre}</h3>}
      </div>
      <div
        onDragOver={dragOver}
        onDrop={drop}
        style={{
          border: '2px dashed  black',
          minHeight: window.innerHeight * 0.1,
          borderRadius: '10px',
          backgroundColor: '#fff'
        }}
      >
        {indicadores
          .filter((indicador) => indicador.objetivoId === id)
          .map((indicador) => {
            return (
              <IndicadorChip
                key={indicador.indicadorId}
                objetivoId={id}
                {...indicador}
                disabled={id === null}
                showPeso={showPeso}
                updateUI={updateUI}
                pesoTotal={pesoTotal}
              />
            )
          })}
      </div>
    </div>
  )
}
