import { HOST } from '../Utils/config'
import { deleteRequest, getRequest, postRequest, putRequest } from './requests'

async function getAgrupacionesVistas() {
  return await getRequest(`${HOST}/api/v1/agrupacionesvistas`)
}

async function postAgrupacionVistas(agrupacion) {
  return await postRequest(`${HOST}/api/v1/agrupacionesvistas`, agrupacion)
}

async function postAgrupacionesPersonal() {
  return await postRequest(`${HOST}/api/v1/agrupacionesvistas/personal`)
}

async function putAgrupacionesPersonal(vistaId) {
  return await putRequest(`${HOST}/api/v1/agrupacionesvistas/personal`, {
    vistaId
  })
}

async function putAgrupacionVistas(agrupacion) {
  return await putRequest(`${HOST}/api/v1/agrupacionesvistas`, agrupacion)
}

async function deleteAgrupacionVistas(agrupacionId) {
  return await deleteRequest(
    `${HOST}/api/v1/agrupacionesvistas/${agrupacionId}`
  )
}

export {
  getAgrupacionesVistas,
  postAgrupacionVistas,
  postAgrupacionesPersonal,
  putAgrupacionesPersonal,
  putAgrupacionVistas,
  deleteAgrupacionVistas
}
