import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Chip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import TagsModal from "../general-components/tags-modal";
import { ObjetivoDesempenoModal } from "../general-components/objetivo-desempeno-modal";

function NivelForm({ code, nom, num, desc, tags, guardar, removeTag, addTag }) {
  const [nombre, setNombre] = useState("");
  const [numero, setNumero] = useState(null);
  const [descripcion, setDescripcion] = useState("");
  const [id, setId] = useState(null);
  const [openTagsModal, setOpenTagsModal] = useState(false);

  useEffect(() => {
    if (code !== null && code > 0) {
      setId(code);
      setNombre(nom);
      setNumero(num);
      setDescripcion(desc);
    }
  }, [code]);

  const handleCloseTagsModal = () => {
    setOpenTagsModal(false);
  };

  const selectTag = async (item, porcentaje) => {
    await addTag(item, porcentaje);
    handleCloseTagsModal();
  };

  return (
    <div style={{ maxHeight: "550px", overflow: "auto" }}>
      <Grid container spacing={3} sx={{ marginTop: "0px" }}>
        <Grid item xs={6}>
          <TextField
            id="nombre"
            label="Nombre"
            variant="outlined"
            value={nombre}
            style={{ width: "100%" }}
            onChange={(e) => setNombre(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            id="numero"
            label="Número nivel"
            variant="outlined"
            value={numero}
            style={{ width: "100%" }}
            onChange={(e) => setNumero(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="descripcion"
            label="Descripción"
            variant="outlined"
            value={descripcion}
            multiline
            style={{ width: "100%" }}
            onChange={(e) => setDescripcion(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} lg={12} sm={12}>
          {Array.isArray(tags) && id > 0 && (
            <div>
              <h4>
                Objetivos de desempeño
                <IconButton
                  aria-label="Agregar objetivo"
                  size="small"
                  onClick={() => {
                    setOpenTagsModal(true);
                  }}
                >
                  <AddIcon fontSize="inherit" />
                </IconButton>
              </h4>
              {tags.map((item) => (
                <Chip
                  label={`${item.ObjetivoDesempeno.nombre} ${item.porcentaje}%`}
                  variant="outlined"
                  onDelete={() => {
                    removeTag(item.id);
                  }}
                />
              ))}
            </div>
          )}
        </Grid>

        <Grid item xs={12} lg={12} sm={12}>
          <Button
            variant="contained"
            style={{ backgroundColor: "#69bd4b", color: "white" }}
            onClick={() => guardar(id, nombre, numero, descripcion)}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>

      <ObjetivoDesempenoModal
        open={openTagsModal}
        closeFn={handleCloseTagsModal}
        onClickItem={selectTag}
        mostrarPorcentaje={true}
      />
    </div>
  );
}

export default NivelForm;
