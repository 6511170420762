import React from "react";
import "./processDisplay.css";
import CkEditor from "../../../general-components/ckeditor";
import { putNodo as updateNodo } from "../../../Api/nodos.api";
import OkCancelButtons from "../../../general-components/ok-cancel-buttons";
import { NodoContext } from "../../../Context/nodoContext";

function ProcessDescription() {
  const [editableMode, setEditableMode] = React.useState(false);
  const [dataState, setDataState] = React.useState("");
  const [dataBk, setDataBk] = React.useState("");
  const { nodoActual, reloadNodoActual } = React.useContext(NodoContext);  

  React.useEffect(() => {    
    setDataState(nodoActual.descripcion);
    setDataBk(nodoActual.descripcion);
  }, [nodoActual]);  

  const setEdit = (value) => {
    if (!nodoActual.permisos.U) {
      return;
    }
    setEditableMode(value);
    setDataBk(dataState);
  };
  const cancelFn = () => {
    setEditableMode(false);
    setDataState(dataBk);
  };

  const handleChangeData = async (value) => {
    setDataState(value);
  };

  const save = async () => {
    if (dataBk !== dataState && dataState !== "") {
      //code to save
      const data = {
        descripcion: dataState,
        id: nodoActual.id,
      };

      const res = await updateNodo(data);
      if (res.status !== 200) {
        setDataState(nodoActual.descripcion);
      } else if (res.status === 200) {
        setDataBk(dataState);
        reloadNodoActual(nodoActual.id);
      }
    }
    setEdit(false);
  };

  return (
    <>
      {!editableMode ? (
        <div
          dangerouslySetInnerHTML={{
            __html: dataState,
          }}
          style={{
            textAlign: "justify",
            padding: "5px 25px",
            width: "100%",
          }}
          className="editable-object"
          onClick={() => setEdit(true)}
        ></div>
      ) : (
        <>
          <CkEditor
            value={dataState}
            setValue={handleChangeData}
            titulo={"Descripción"}
          />
          <OkCancelButtons okFn={save} cancelFn={cancelFn} />
        </>
      )}
    </>
  );
}

export default ProcessDescription;
