import { HOST } from "../Utils/config";
import { deleteRequest, getRequest, postRequest, putRequest } from "./requests";

async function getFechasVista(vistaId) {
  return await getRequest(`${HOST}/api/v1/vistas/fechas/${vistaId}`);
}

async function getFechasInicioFin(vistaId) {
  return await getRequest(`${HOST}/api/v1/vistas/fechasiniciofin/${vistaId}`);
}

async function getVista(vistaId) {
  return await getRequest(`${HOST}/api/v1/vistas/${vistaId}`);
}

async function getVistaConResultados(vistaId, fechaIni, fechaFin, offset, periodo) {
  return await getRequest(`${HOST}/api/v1/vistas/completa/${vistaId}?fechaIni=${fechaIni}&fechaFin=${fechaFin}&offset=${offset}&periodo=${periodo}`);
}

async function getVistas() {
  return await getRequest(`${HOST}/api/v1/vistas`);
}

async function getIndicadoresVista(vistaId) {
  return await getRequest(`${HOST}/api/v1/vistas/indicadores/${vistaId}`);
}

async function postVista(vista) {
  return await postRequest(`${HOST}/api/v1/vistas`, vista);
}

async function postDuplicateVista(vistaId) {
  return await postRequest(`${HOST}/api/v1/vistas/duplicate/${vistaId}`);
}

async function putVista(vista) {
  return await putRequest(`${HOST}/api/v1/vistas`, vista);
}

async function putModificarOrden(modificacionParams) {
  return await putRequest(`${HOST}/api/v1/vistas/orden`, modificacionParams);
}

async function deleteVista(vistaId) {
  return await deleteRequest(`${HOST}/api/v1/vistas/${vistaId}`);
}

async function getTabs(vistaId) {
  return await getRequest(`${HOST}/api/v1/tab/${vistaId}`);
}

async function updatetipoVista(modificacionParams) {
  return await putRequest(`${HOST}/api/v1/vistas/updateTipoVista`, modificacionParams);
}

async function updRangoFechas(modificacionParams) {
  return await putRequest(`${HOST}/api/v1/vistas/updateRangoFecha`, modificacionParams);
}

async function updateGraficaVisible(modificacionParams) {
  return await putRequest(`${HOST}/api/v1/vistas/updateGraficaVisible`, modificacionParams);
}

async function getUsuariosVista(vistaId) {
  return await getRequest(`${HOST}/api/v1/vistas/getusuariosvista/${vistaId}`);
}

async function updatePublico(vistaId, publico) {
  const obj =  {id:vistaId, publico}
  return await putRequest(`${HOST}/api/v1/vistas/updatepublico`, obj);
}

async function agregarUsuario(vistaId, userId) {
  const obj = {vistaId:vistaId, usuarioId:userId}
  return await postRequest(`${HOST}/api/v1/vistas/agregarusuario`, obj);
}

async function removerUsuario(vistaIndicadorUsuarioId) {
  const obj = {id:vistaIndicadorUsuarioId}
  return await deleteRequest(`${HOST}/api/v1/vistas/removerusuario`, obj);
}

async function getTablerosCompartidosConmigo() {
  return await getRequest(`${HOST}/api/v1/vistas/compartidos/joder`);
}

async function updateEsBorrador(vistaId, esBorrador) {
  const obj =  {id:vistaId, esBorrador}
  return await putRequest(`${HOST}/api/v1/vistas/updateesborrador`, obj);
}

async function updateResumen(vista) {
  return await putRequest(`${HOST}/api/v1/vistas/updateresumen`, vista);
}

async function favoritoUsuario(obj) {
  return await putRequest(`${HOST}/api/v1/vistas/favoritousuario`, obj);
}

async function getTopFavoritos() {
  return await getRequest(`${HOST}/api/v1/vistas/topfavoritos/miercoles`);
}



export {
  getFechasVista,
  getVista,
  getVistaConResultados,
  getVistas,
  getIndicadoresVista,
  postVista,
  postDuplicateVista,
  putVista,
  putModificarOrden,
  deleteVista,
  getTabs,
  updatetipoVista,
  updRangoFechas,
  getFechasInicioFin,
  updateGraficaVisible,
  getUsuariosVista,
  agregarUsuario,
  removerUsuario,
  updatePublico,
  getTablerosCompartidosConmigo,
  updateEsBorrador,
  updateResumen,
  favoritoUsuario,
  getTopFavoritos
};
