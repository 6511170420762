import React, { useEffect } from "react";
import { Fab, modalClasses } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { FamiliaItem } from "./familiaItem";
import Button from "@mui/material/Button";
// import { IndicadoresModal } from "./indicadoresModal";
import { toast } from "react-toastify";
import {
  getResultadoIndividual,
  getResultadosByIndicadorYPeriodo,
} from "../Api/resultado.api";
import { useLocation, useSearchParams } from "react-router-dom";
import Modal from "../general-components/modal";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import {deleteTagFamily,getTagFamilies,getTagFamily,postTagFamily,putTagFamily}  from "../Api/tagFamily.api";
import { clear } from "@testing-library/user-event/dist/clear";

function FamiliaMain({ height }) {  
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [nombre, setNombre] = React.useState('');
  const [id, setId] = React.useState(null);
  const [familiaList, setFamiliaList] = React.useState([]);

  useEffect(() => {
   cargarFamilias();
  }, []);

  const setDataEdit = async(id)=>{
    clear();
    const data = await getTagFamily(id);    
    if(data.status==200){
      setNombre(data.body.nombre);
      setId(data.body.id);
      openModal();
    }
  }

  async function remove(id){
    await deleteTagFamily(id);
    cargarFamilias();
  }


    async function save() {    
     if(nombre.trim()===""){
      toast.warn("Ingresa un nombre válido")
      return;
     }

     let data = {

     }

     if(id === null){
      //crear
      data = {
        nombre:nombre
      };
      await postTagFamily(data);
      clear();
      handleCloseModal();
      cargarFamilias();
     }else{
      //modificar
      data = {id:id,nombre:nombre}
      await putTagFamily(data);
      clear();
      handleCloseModal();
      cargarFamilias();
     }


  }

 const cargarFamilias = async()=>{
  try {
    const data = await getTagFamilies();    
    if(data.status == 200){
      setFamiliaList(data.body);
    }
    
  } catch (error) {
    console.error(error);
  }
 }
  
 function clear(){
  setNombre("");
  setId(null);
 }

  function openModal() {
    setIsModalOpen(true);

  }


  function handleCloseModal(){
    setIsModalOpen(false);
  }

  return (
    <div style={{ width: "inherit" }}>
      <h2>Familia de etiquetas</h2>
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "inherit",
        }}
      >
<div style={{ boxShadow: "2px -1px 2px -2px #888", width: "100%", minWidth: "100%", float: "left" }}>
      {familiaList.map((item) => {
        return (
           <FamiliaItem
            key={item.id}
            item={item}
            setModalOpen={setIsModalOpen}
            cargarFamilias  = {cargarFamilias}
            setDataEdit = {setDataEdit}
            remover = {remove}
          />
          
        );
      })}
    </div>
      
        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: "fixed",
            right: 40,
            bottom: 15,
            zIndex: 5,
            backgroundColor: "#aebd36",
          }}
          onClick={() =>{clear(); openModal()}}
        >
          <AddIcon />
        </Fab>
     
      {/* <IndicadoresModal
        open={isModalOpen}
        setOpen={setModalOpen}
        updateIndicadores={updateIndicadores}
       
      /> */}
     </div>

     <Modal open={isModalOpen} handleClose={handleCloseModal} title="Famila de etiquetas">
     <div style={{ maxHeight: "550px", overflow: "auto" }}>
      <Grid container spacing={3} sx={{ marginTop: "0px" }}>
        <Grid item xs={6}>
          <TextField
            id="nombre"
            label="Nombre"
            variant="outlined"
            value={nombre}
            style={{ width: "100%" }}
            onChange={(e) => setNombre(e.target.value)}
            type="text"
            InputLabelProps={{
                shrink: true,
              }}
          />
        </Grid>
        <Grid item xs={12} lg={12} sm={12}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#69bd4b",
              color: "white",
              marginRight: "20px",
            }}
            onClick={() => save()}
          >
            Guardar
          </Button>
        </Grid>
        </Grid>
        </div>
    </Modal>

    </div>
  );
}

export { FamiliaMain }
