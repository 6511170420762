import dagre from "dagre";
import { isNode } from "react-flow-renderer";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

// In order to keep this example simple the node width and height are hardcoded.
// In a real world app you would use the correct width and height values of
// const nodes = useStoreState(state => state.nodes) and then node.__rf.width, node.__rf.height

const nodeWidth = 250;
const nodeHeight = 90;
const edgeType = "smoothstep";

function getInitialElements(nodos) {
  const elements = [];
  const edges = [];
  nodos.forEach((element) => {
    elements.push({
      id: `${element.id}`,
      data: { label: `${element.nombre}` },
      position : { x: 0, y: 0 },
    });
    if (element.nodoPadreId) {
      edges.push({
        id: `e${element.id}${element.nodoPadreId}`,
        source: `${element.nodoPadreId}`,
        target: `${element.id}`,
        type: edgeType,
        animated: true,
      });
    }
  });
  elements.push.apply(elements, edges);
  return elements;
}

const getLayoutedElements = (elements, direction = "TB") => {      
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: el.data.width || nodeWidth, height: el.data.height || nodeHeight });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? "right" : "top";
      el.sourcePosition = isHorizontal ? "left" : "bottom";

      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      const finalNodeWidth = el.data.width || nodeWidth;
      const finalNodeHeight = el.data.height || nodeHeight;
      el.position = {
        x: nodeWithPosition.x - finalNodeWidth / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - finalNodeHeight / 2,
      };
    }
    return el;
  });
};

export {
    getInitialElements,
    getLayoutedElements
}
