import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { NodoContext } from "../Context/nodoContext";
import { Checkbox, FormControlLabel, MenuItem } from "@mui/material";
import { postPeriodo, putPeriodo } from "../Api/periodo.api";
import { PERIODO_CREADO } from "../Utils/config";

function PeriodosForm({ setOpen, updatePeriodos, periodoActivo }) {
  const [cargando, setCargando] = useState(false);

  const [nombre, setNombre] = useState(periodoActivo.nombre || "");
  const [descripcion, setDescripcion] = useState(
    periodoActivo.descripcion || ""
  );
  const [fechaInicio, setFechaInicio] = useState(
    periodoActivo.fechaInicio || ""
  );
  const [fechaFin, setFechaFin] = useState(periodoActivo.fechaFin || "");
  const [fechaSubInicio, setFechaSubInicio] = useState(
    periodoActivo.fechaSubInicio || ""
  );
  const [fechaSubFin, setFechaSubFin] = useState(periodoActivo.fechaSubFin || "");

  function save() {
    const periodo = {
      nombre,
      descripcion,
      fechaInicio,
      fechaFin,
      fechaSubInicio,
      fechaSubFin,
      estadoPeriodoId: PERIODO_CREADO,
    };
    if (validateSubInicio(false)) {
      toast.error("Fecha inicio del periodo zafra no es válida");
    } else if (validateSubFin(false)) {
      toast.error("Fecha fin del periodo zafra no es válida");
    } else {
      if (periodoActivo.id) {
        periodo.id = periodoActivo.id;
        updatePeriodo(periodo);
      } else {
        createIndicador(periodo);
      }
    }
  }

  const createIndicador = async (periodo) => {
    setCargando(true);
    try {
      postPeriodo(periodo).then((response) => {
        if (response.status === 201) {
          toast.success("Periodo creado correctamente");
          setOpen(false);
          updatePeriodos();

        } else {
          toast.error(response.message);
        }
      });
    } finally {
      setCargando(true);
    }
  };

  const updatePeriodo = (periodo) => {

    try {

      putPeriodo(periodo).then((response) => {
        if (response.status === 200) {
          toast.success("Periodo modificado correctamente");
          setOpen(false);
          updatePeriodos();
        } else {
          toast.error(response.message);
        }
      });
    } finally {

    }
  };

  const validateSubInicio = (isText) => {
    if (fechaInicio && fechaFin && fechaSubInicio) {
      const fecha = new Date(fechaSubInicio)
      const fechaI = new Date(fechaInicio)
      const fechaF = new Date(fechaFin)
      if (fecha >= fechaI && fecha <= fechaF)
        return false
      return isText ? 'La fecha de inicio del periodo zafra debe estar dentro del rango del periodo' : true
    }
    else if (fechaSubInicio && fechaSubFin) {
      const fechaI = new Date(fechaSubInicio)
      const fechaF = new Date(fechaSubFin)
      if (fechaI < fechaF)
        return false
      return isText ? 'La fecha de inicio del periodo zafra debe ser menor a la fecha de fin del periodo zafra' : true
    }
    return isText ? 'Fechas no validas' : true
  }
  
  const validateSubFin = (isText=false) => {
    if (fechaInicio && fechaFin && fechaSubFin) {
      const fecha = new Date(fechaSubFin)
      const fechaI = new Date(fechaInicio)
      const fechaF = new Date(fechaFin)
      if (fecha >= fechaI && fecha <= fechaF)
        return false
      return isText ? 'La fecha de fin del periodo zafra debe estar dentro del rango del periodo' : true
    }
    else if (fechaSubInicio && fechaSubFin) {
      const fechaI = new Date(fechaSubInicio)
      const fechaF = new Date(fechaSubFin)
      if (fechaI < fechaF)
        return false
      return isText ? 'La fecha de inicio del periodo zafra debe ser menor a la fecha de fin del periodo zafra' : true
    }
    return isText ? 'Fechas no validas' : true
  }

  return (
    <div style={{ maxHeight: "550px", overflow: "auto" }}>
      <Grid container spacing={3} sx={{ marginTop: "0px" }}>
        <Grid item xs={6}>
          <TextField
            id="nombre"
            label="Nombre"
            variant="outlined"
            value={nombre}
            style={{ width: "100%" }}
            onChange={(e) => setNombre(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="descripcion"
            label="Descripción"
            variant="outlined"
            value={descripcion}
            multiline
            style={{ width: "100%" }}
            onChange={(e) => setDescripcion(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="fechaInicio"
            label="Fecha Inicio"
            variant="outlined"
            type="date"
            value={fechaInicio}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%" }}
            onChange={(e) => {
              setFechaInicio(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="fechaFin"
            label="Fecha Fin"
            variant="outlined"
            type="date"
            value={fechaFin}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%" }}
            onChange={(e) => {
              setFechaFin(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="fechaSubInicio"
            label="Fecha Inicio Periodo Zafra"
            variant="outlined"
            type="date"
            value={fechaSubInicio}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%" }}
            onChange={(e) => setFechaSubInicio(e.target.value)}
            error={validateSubInicio(false)}
            helperText={validateSubInicio(true)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="fechaFin"
            label="Fecha Fin Periodo Zafra"
            variant="outlined"
            type="date"
            value={fechaSubFin}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%" }}
            onChange={(e) => setFechaSubFin(e.target.value)}
            error={validateSubFin(false)}
            helperText={validateSubFin(true)}
          />
        </Grid>
        <Grid item xs={12} lg={12} sm={12}>
          <Button
            variant="contained"
            disabled={cargando}
            loading={cargando}
            style={{
              backgroundColor: "#69bd4b",
              color: "white",
              marginRight: "20px",

            }}
            onClick={() => save()}
            // onClick={presave}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export { PeriodosForm };
