import React, { useEffect } from "react";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { NodoContext } from "../Context/nodoContext";
import { toast } from "react-toastify";
import { getPeriodos, getPeriodosPrivado } from "../Api/periodo.api";
import { PeriodoList } from "./periodoList";
import { PeriodosModal } from "./periodoModal";
import { PermisionDeniedBar } from "../general-components/permisionDeniedBar";
import { ConditionalRendering } from "../general-components/conditionalRendering";

function PeriodosMain() {
  const PERIODOS_MENUID = 5;
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [periodos, setPeriodos] = React.useState([]);
  const { nodoActual } = React.useContext(NodoContext);
  const [periodoActivo, setPeriodoActivo] = React.useState({});
  const [permissionDenied, setPermissionDenied] = React.useState(false);

  useEffect(() => {
    updatePeriodos();
  }, [nodoActual]);

  function updatePeriodos() {
    getPeriodosPrivado(PERIODOS_MENUID).then((response) => {
      if (response.status === 200) {
        setPeriodos(response.body);
      } else if (response.status === 403) {
        setPermissionDenied(true);
      } else {
        toast.error(response.message);
      }
      setPeriodoActivo({});
    });
  }

  function closeModal() {
    setModalOpen(false);
  }

  function openModal() {
    setPeriodoActivo({});
    setModalOpen(true);
  }

  return (
    <div>
      <PermisionDeniedBar display={permissionDenied} />
      <PeriodoList
        periodos={periodos}
        setModalOpen={setModalOpen}
        setPeriodoActivo={setPeriodoActivo}
        updatePeriodos={updatePeriodos}
      />
      <ConditionalRendering condition={!permissionDenied}>
      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          right: 10,
          bottom: 10,
          backgroundColor: "#aebd36",
        }}
        onClick={() => openModal()}
      >
        <AddIcon />
      </Fab>
      <PeriodosModal
        open={isModalOpen}
        setOpen={setModalOpen}
        updatePeriodos={updatePeriodos}
        periodoActivo={periodoActivo}
      />
      </ConditionalRendering>
      
    </div>
  );
}

export { PeriodosMain };
