import React from 'react'
import { Handle, Position } from 'react-flow-renderer'
import TemporaryDrawer from '../general-components/temporary-drawer'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Divider, Tooltip, Typography } from '@mui/material'
import InputOutputCrud from './inputOutputCrud'
import { LinearProgressWithLabel } from '../general-components/prograssBar'
import CloseIcon from '@mui/icons-material/Close'
import { NodoContext } from '../Context/nodoContext'
import { useParams } from 'react-router-dom'
import { deleteRelacion } from '../Api/nodoSipoc.api'
import { toast } from 'react-toastify'
import { TIPOS_NODO } from '../Utils/enums'

let customNodeStyles = {
  background: '#fff',
  color: '#000',
  padding: 10,
  border: `solid 2px #2d2adc`,
  borderRadius: 5,
  // width: 180,
  // height: 90,
  width: 220,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center'
}
const CustomInputNode = ({ data, selected }) => {
  const [isToolTipoVisible, setTooltipVisible] = React.useState(false)
  const { reloadNodoActual, nodoActual } = React.useContext(NodoContext)
  const { nodoId } = useParams()

  function handleTooltip() {
    setTooltipVisible(!isToolTipoVisible)
  }

  function closeTooltip() {
    if (isToolTipoVisible) {
      setTooltipVisible(false)
    }
  }

  function handleDeleteSipoc() {
    console.log({ nodoId: parseInt(nodoId) })
    console.log({ Nodorelacionado: data.nodo.id })
    deleteRelacion(parseInt(nodoId), parseInt(data.nodo.id)).then((res) => {
      if (res.status === 200) {
        reloadNodoActual(nodoId)
      } else {
        toast.error('Error al eliminar la relacion')
      }
    })
  }

  return (
    <div
      style={{
        ...customNodeStyles,
        borderStyle: selected ? 'dashed' : 'solid',
        overflow: 'auto',
        alignItems: data.isExpanded ? 'flex-start' : 'center'
      }}
    >
      <Handle
        type="target"
        position={Position.Left}
        style={{ borderRadius: 50 }}
        id="inputTarget"
      />
      {!data.isExpanded && !data.displayResultados && (
        <div>{data.nodo.nombre}</div>
      )}
      {data.isExpanded && (
        <div>
          <Typography
            variant="body2"
            gutterBottom
            component="div"
            style={{ fontWeight: 'bold' }}
          >
            {data.nodo.nombre}
          </Typography>
          <Divider />
          <Typography
            variant="body2"
            gutterBottom
            component="div"
            dangerouslySetInnerHTML={{
              __html: data.nodo.info
            }}
          ></Typography>
        </div>
      )}
      {data.displayResultados && (
        <div>
          <Typography
            variant="body2"
            gutterBottom
            component="div"
            style={{ fontWeight: 'bold' }}
          >
            {data.nodo.nombre}
          </Typography>
          <Divider />
          {data.nodo.indicadores?.map((indicador, index) => {
            return (
              <div key={index} style={{ textAlign: 'left' }}>
                <i style={{ fontSize: '12px' }}>
                  <b>{indicador.nombre}:</b>
                </i>
                <LinearProgressWithLabel
                  score={indicador.valorNumerico}
                  goal={indicador.meta}
                />
              </div>
            )
          })}
        </div>
      )}
      {selected && (
        <Tooltip title="Mostrar Entradas/Salidas" arrow>
          <InfoOutlinedIcon
            sx={{
              position: 'absolute',
              top: '5px',
              right: '5px'
            }}
            fontSize="small"
            onClick={handleTooltip}
          />
        </Tooltip>
      )}
      {selected && nodoActual.tipoNodoId === TIPOS_NODO.ACTIVIDAD && (
        <Tooltip title="Eliminar relación" arrow>
          <CloseIcon
            onClick={handleDeleteSipoc}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              top: 2,
              left: 2,
              color: 'red'
            }}
            fontSize="small"
          />
        </Tooltip>
      )}
      <TemporaryDrawer
        position={'right'}
        title={'Salidas'}
        show={isToolTipoVisible}
        handleClose={closeTooltip}
        hideBackdrop={true}
      >
        <InputOutputCrud nodoSipocId={data.nodo.nodoSipocID} />
      </TemporaryDrawer>
    </div>
  )
}

export { CustomInputNode }
