import React from 'react';
import { Breadcrumbs, Typography, Link } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useNavigate } from 'react-router-dom';

export function BreadcrumbItem({ IconHome, isHome, isLast, link, title }) {

  const navigate = useNavigate();

  if (isLast) {
    return (
      <Typography
        sx={{ display: 'flex', alignItems: 'center' }}
        color="inherit"
        variant='h5'
      >
        {IconHome ? <IconHome sx={{ mr: 0.5, fontSize: 40 }} /> : null}
        {title}
      </Typography>
    )
  }

  return (
    <Link
      underline="hover"
      sx={{ display: 'flex', alignItems: 'center' }}
      // color="inherit"
      color="text.primary"
      onClick={() => navigate(link)}
      href={link}
      variant='h5'
    >
      {IconHome ? <IconHome sx={{ mr: 0.5, fontSize: 40 }} fontSize="inherit" /> : null}
      {title}
    </Link>
  )
}

export default function SimpleBreadcrumbs({ children }) {

  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }

  return (
    <div role="presentation" onClick={handleClick} style={{ marginBottom: '0.5rem' }}>
      <Breadcrumbs aria-label="breadcrumb">
        {children}
      </Breadcrumbs>
    </div>
  )
}
