import React, { useEffect } from 'react'
import {
  deleteVista,
  postDuplicateVista,
  getUsuariosVista,
  updatePublico,
  agregarUsuario,
  removerUsuario,
  favoritoUsuario
  
} from '../Api/vistaIndicador.api'
import { toast } from 'react-toastify'
import { ConditionalRendering } from '../general-components/conditionalRendering'
import { IconButton, Paper, Tooltip } from '@mui/material'
import { putModificarOrden } from '../Api/vistaIndicador.api'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ArticleIcon from '@mui/icons-material/Article'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import LocationSearchingIcon from '@mui/icons-material/LocationSearching'
import RefreshIcon from '@mui/icons-material/Refresh'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Confirm from '../general-components/confirm'
import { NodoContext } from '../Context/nodoContext'
import { useNavigate } from 'react-router-dom'
import { putAgrupacionesPersonal } from '../Api/vistaAgrupacion.api'
import UsersModal from './users-modal'
import Checkbox from '@mui/material/Checkbox';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';


export function FavoritoThumbnail({
  vista,
  updateFavoritos,
  handleOpenModal,
  agrupacionPersonal,
  own,
  favorito,
  updateFavCheck
}) {
  const [isTarget, setIsTarget] = React.useState(false)
  const [deleteTableroConf, setDeleteTableroConf] = React.useState(false)
  const [duplicateTableroConf, setDuplicateTableroConf] = React.useState(false)
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext)
  const [openUserModal, setOpenUserModal] = React.useState(false)
  const [favoritoState,setFavoritoState] = React.useState(false)

  const navigate = useNavigate()

  const label = { inputProps: { 'aria-label': 'Favorito' } };


  useEffect(() => {
    if (vista) {
      setFavoritoState(favorito)
      console.log('VISTA', vista)
    }
  }, [vista])

  function deleteFavorito(e) {
    e.stopPropagation()
    setLoading(true)
    setLoadingMessage('Eliminando vista...')
    deleteVista(vista.id).then((response) => {
      setLoading(false)
      if (response.status === 200) {
        updateFavoritos()
      } else {
        toast.error('Ocurrió un error al eliminar la vista')
      }
    })
  }

  function duplicateFavorito(e) {
    e.stopPropagation()
    setLoading(true)
    setLoadingMessage('Duplicando vista...')
    postDuplicateVista(vista.id).then((response) => {
      setLoading(false)
      if (response.status === 201) {
        updateFavoritos()
        setDuplicateTableroConf(false)
        toast.success('Vista duplicada con éxito')
      } else {
        toast.error('Ocurrió un error al duplicar la vista')
      }
    })
  }

  function handleOnClick() {
    navigate(`${vista.id}`)
  }

  function handleOnDelete(e) {
    e.stopPropagation()
    setDeleteTableroConf(true)
  }

  function handleOnDuplicate(e) {
    e.stopPropagation()
    setDuplicateTableroConf(true)
  }

  function editFavorito(e) {
    e.stopPropagation()
    handleOpenModal(vista)
  }

  function handleDragStart(e) {
    console.log('Drag start')
    e.dataTransfer.setData('vista', JSON.stringify(vista))
  }

  function handleDragEnd(e) {
    console.log('Drag end')
  }

  function handleDragEnter(e) {
    console.log('Drag enter')
    e.preventDefault()
    setIsTarget(true)
  }

  function handleDragLeave(e) {
    console.log('Drag leave')
    setIsTarget(false)
  }

  function handleOnDrop(e) {
    console.log('Drop')
    e.preventDefault()
    setIsTarget(false)
    const vistaTransferida = JSON.parse(e.dataTransfer.getData('vista'))
    if (vista.id !== vistaTransferida.id) {
      setLoading(true)
      setLoadingMessage('Guardando vista...')
      putModificarOrden({
        vistaId: vistaTransferida.id,
        ordenDestino: vista.orden,
        agrupacionDestinoId: vista.agrupacionId
      }).then((response) => {
        setLoading(false)
        if (response.status === 200) {
          updateFavoritos()
        } else {
          toast.error('Ocurrió un error al cambiar el orden de las vistas')
        }
      })
    }
  }

  function handleDragOver(e) {
    if (e.preventDefault) {
      e.stopPropagation()
      e.preventDefault()
    }
    return false
  }

  function refreshTablero(e) {
    e.stopPropagation()
    putAgrupacionesPersonal(vista.id).then((response) => {
      if (response.status === 200) {
        toast.success('Tablero actualizado')
      } else {
        toast.error('Ocurrió un error al actualizar el tablero')
      }
    })
  }

  const handleOpenUserModal = (e) => {
    e.stopPropagation()
    setOpenUserModal(true)
  }
  const handleCloseUserModal = () => {
    setOpenUserModal(false)
  }

  const addUser = async (usuarioId) => {
    try {
      const response = await agregarUsuario(vista.id, usuarioId)
      console.log('AgregarUsuario', response)
      if (response.status == 200) {
      }
    } catch (e) {
      console.error(e)
    }
  }

  const removeUser = async (vistaIndicadorUsuarioId) => {
    try {
      const response = await removerUsuario(vistaIndicadorUsuarioId)
      console.log('removerUsuario', response)
      if (response.status == 200) {
      }
    } catch (e) {
      console.error(e)
    }
  }

  const hacerPublico = async (value) => {
    let r = false
    try {
      const response = await updatePublico(vista.id, value)
      console.log('hacerPublico', response)
      if (response.status == 200) {
        r = true
        vista.publico = value
      }
      return r
    } catch (e) {
      console.error(e)
    }
  }



  const handleCliceFav = async(e)=>{
    e.stopPropagation();
    e.preventDefault();

    const obj = {
      vistaId: vista.id,
      activo:!favoritoState,
      eliminado:favoritoState
    }

    const req  =  await(favoritoUsuario(obj));
    if(req.status==200){
      setFavoritoState(!favoritoState);
      console.log("vista.agrupacionId", vista.agrupacionId);
      console.log("vista.id", vista.id)
      updateFavCheck(vista.agrupacionId,vista.id, !favoritoState)
    }else{
      console.log(req.message);
    }   

  }

  return (
    <>
      <Paper
        sx={{
          width: '400px',
          height: '200px',
          cursor: 'move',
          display: 'flex',
          flexDirection: 'column',
          margin: '10px',
          backgroundColor: isTarget ? '#ffffff' : '#f5f5f5',
          border: isTarget ? '4px dashed #e0e0e0' : '4px solid transparent'
        }}
        elevation={3}
        onClick={handleOnClick}
        draggable
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDragEnter={handleDragEnter}
        onDrop={handleOnDrop}
      >
        <ConditionalRendering condition={!isTarget}>
         
            <section style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Tooltip title="Ver informes">
                  <IconButton
                    aria-label="informes"
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate(`/home/listadoinformes/${vista.id}`)
                    }}
                  >
                    <ArticleIcon color="warning" />
                  </IconButton>
                </Tooltip>
                 
                {!agrupacionPersonal && own && (
                  <Tooltip title="Compartir con otros usuarios">
                    <IconButton
                      aria-label="duplicate"
                      onClick={handleOpenUserModal}
                    >
                      <PersonAddAltIcon color="warn" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>

              {!agrupacionPersonal && own &&(
                <div>
                  <Tooltip title="Duplicar">
                    <IconButton
                      aria-label="duplicate"
                      onClick={handleOnDuplicate}
                    >
                      <ContentCopyIcon color="warn" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Editar">
                    <IconButton aria-label="update" onClick={editFavorito}>
                      <EditIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Eliminar">
                    <IconButton aria-label="delete" onClick={handleOnDelete}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                   
                </div>
              )}
              {agrupacionPersonal==1 && own &&(
                <IconButton color="primary" onClick={refreshTablero}>
                  <RefreshIcon />
                </IconButton>
              )}
            </section>
       
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginTop: '20px',
              alignItems: 'center'
            }}
          >
            <h1>{vista.nombre}</h1>
            <h5>{vista.descripcion}</h5>
            <div>
            <Checkbox
            checked={favoritoState}
            {...label} icon={<StarBorderIcon />} checkedIcon={<StarIcon color='warning' />} 
            //onChange={handleChangeFav}
            onClick={handleCliceFav}
            />
            </div>
          </div>
        </ConditionalRendering>
        <ConditionalRendering condition={isTarget}>
          <div
            style={{ color: '#e0e0e0', margin: 'auto' }}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDragEnter={handleDragEnter}
            onDrop={handleOnDrop}
          >
            <LocationSearchingIcon sx={{ fontSize: 100 }} />
            <h2 style={{ marginTop: '10px' }}>Sueltame!</h2>
          </div>
        </ConditionalRendering>
      </Paper>
      <Confirm
        open={deleteTableroConf}
        title={'Eliminar'}
        text={'¿Confirma que desea eliminar este tablero?'}
        onConfirm={deleteFavorito}
        handleCloseProp={() => setDeleteTableroConf(false)}
      />
      <Confirm
        open={duplicateTableroConf}
        title={'Duplicar'}
        text={'¿Confirma que desea duplicar este tablero?'}
        onConfirm={duplicateFavorito}
        handleCloseProp={() => setDuplicateTableroConf(false)}
      />

      <UsersModal
        open={openUserModal}
        closeFn={handleCloseUserModal}
        addUser={addUser}
        //publico = {publico}
        removeUser={removeUser}
        hacerPublico={hacerPublico}
        vista={vista}
      />
    </>
  )
}
