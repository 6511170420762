import React from "react";
import OKForm from "./form";
import KRForm from "./krForm";
import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import Modal from '../../general-components/modal';
import Confirm from '../../general-components/confirm';
import { createKR, createOK, deleteKR, getKRs, getOK, updateKR, updateOK } from "../../Api/okr.api";
import { toast } from "react-toastify";
import 'swiper/css';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import IndicadorForm from "./indicadorForm";

const OKModalForm = ({ open, handleCloseModal, periodoId, objetivoPadre, isEdit, getOKRs, usuarios }) => {

  const [itemKR, setItemKR] = useState(null)
  const [itemsKR, setItemsKR] = useState([])
  const [itemsKRDeleted, setItemsKRDeleted] = useState([])
  const [itemOK, setItemOK] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false)
  const swiperRef = React.useRef();

  useEffect(() => {
    if (isEdit && objetivoPadre.id) {
      resetearState()
      obtenerOK()
      obtenerKRs()
    }
  }, [isEdit, objetivoPadre])

  const resetearState = () => {
    setItemKR(null)
    setItemsKR([])
    setItemsKRDeleted([])
  }

  const obtenerOK = async () => {
    try {
      const response = await getOK(objetivoPadre.id)
      console.log('response ', response)
      if (response.status === 200) {
        setItemOK(response.body)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const obtenerKRs = async () => {
    try {
      const response = await getKRs(objetivoPadre.id)
      console.log('response ', response)
      if (response.status === 200) {
        setItemsKR(response.body)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleCreateOK = async (data) => {
    try {
      setLoading(true)
      data.objetivoPadreId = objetivoPadre.id || null
      data.periodoId = periodoId
      console.log('data', data)

      const response = await createOK(data)
      console.log('KR response ', response)
      if (response.status === 201) {
        toast.success('OKR creado correctamente')
        const { id } = response.body?.body
        // Crear los KRs
        const responseKR = await Promise.all(itemsKR.map((item) => {
          if (item.enviar) {
            // Eliminar la propiedad enviar
            delete item.enviar
            console.log('KR item ', item)
            return createKR({ ...item, objetivoClaveId: id })
          }
        }
        ))
        if (responseKR.filter(item => [200, 201].includes(item?.status)).length > 0) toast.success('KRs creados correctamente')
        getOKRs(periodoId)
        handleCloseModal()
        resetearState()
      }

    } catch (error) {
      console.log('error', error)
      toast.error(`Error al crear el OKR: ${error.message}`)
    } finally {
      setLoading(false)
    }
  }

  const handleEditOK = async (data) => {
    try {
      setLoading(true)
      console.log('data', data)

      const response = await updateOK(itemOK.id, data)
      console.log('response ', response)
      if (response.status === 200) {
        // Crear los KRs nuevos y actualizar los existentes
        toast.success('OKR actualizado correctamente')
        const responseKR = await Promise.all(itemsKR.map((item) => {
          if (item.enviar) {
            // Eliminar la propiedad enviar
            delete item.enviar
            if (item.index !== undefined) delete item.index
            console.log('KR item ', item)
            if (item.id) {
              const id = item.id
              delete item.id
              return updateKR(id, item)
            } else {
              return createKR({ ...item, objetivoClaveId: itemOK.id })
            }
          }
          return null
        }))

        // Eliminar los KRs eliminados
        const responseKRDeleted = await Promise.all(itemsKRDeleted.map((id) => {
          return deleteKR(id)
        }))

        if (responseKR.filter(item => [200, 201].includes(item?.status)).length > 0) toast.success('KRs actualizados correctamente')

        getOKRs(periodoId)
        handleCloseModal()
        resetearState()
      }

    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  const handleAddKR = (data) => {
    console.log('data', data)

    // Validar que tenga una propiedad index
    if (data.index === undefined) {
      setItemsKR([...itemsKR, { ...data, enviar: true }])
    } else {
      const newItemsKR = itemsKR.map((item, index) => {
        if (index === data.index) {
          return { ...data, enviar: true }
        }
        return item
      })
      setItemsKR(newItemsKR)
    }
  }

  const handleRemoveKR = () => {
    console.log('item', itemKR)
    if (itemKR.id) {
      setItemsKRDeleted([...itemsKRDeleted, itemKR.id])
    }
    const newItemsKR = itemsKR.filter((item, i) => i !== itemKR.index)
    setItemsKR(newItemsKR)
    setItemKR(null)
    setOpenConfirm(false)
  }

  const handleSlideChange = () => {
    if (swiperRef.current) {
      setActiveIndex(swiperRef.current.activeIndex);
    }
  };

  const handleKRSlide = (kr) => {
    setItemKR(kr)
    swiperRef.current.slideNext()
  }

  const handleBack = () => {
    setItemKR(null)
    swiperRef.current.slidePrev()
  }

  return (
    <Modal
      open={open}
      title={activeIndex ? 'Resultado clave' : isEdit ? 'Editar OK' : 'Nuevo OK'}
      handleClose={handleCloseModal}
    >
      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={handleSlideChange}
        allowTouchMove={false}
      >
        <SwiperSlide>
          <OKForm
            objetivoPadre={isEdit ? itemOK?.objetivoPadre : objetivoPadre}
            itemOK={itemOK}
            onSave={isEdit ? handleEditOK : handleCreateOK}
            onClose={handleCloseModal}
            loading={loading}
            isEdit={isEdit}
            usuarios={usuarios}
            handleKRSlide={handleKRSlide}
            onRemoveKR={(item) => {
              setItemKR(item)
              setOpenConfirm(true)
            }}
            handleBack={handleBack}
            itemsKR={itemsKR}
          />
        </SwiperSlide>
        <SwiperSlide>
          <KRForm
            loading={loading}
            itemKR={itemKR}
            activeIndex={activeIndex}
            handleBack={handleBack}
            handleKRSlide={handleKRSlide}
            onSave={handleAddKR}
          />
        </SwiperSlide>
        <SwiperSlide>
          <IndicadorForm
            loading={loading}
            activeIndex={activeIndex}
            handleBack={handleBack}
            handleKRSlide={handleKRSlide}
            onSave={handleAddKR}
          />
        </SwiperSlide>
      </Swiper>
      <Confirm
        open={openConfirm}
        title={"Eliminar"}
        text={"¿Confirma que desea eliminar este resultado clave?"}
        onConfirm={handleRemoveKR}
        handleCloseProp={() => {
          setItemKR(null)
          setOpenConfirm(false)
        }}
      />
    </Modal>
  )
}

export default OKModalForm
