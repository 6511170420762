import React from "react";
import { Pilar } from "./pilar";


function PilaresList({
  pilares,
  setModalOpen,
  setPilarActivo,
  obtenerPilares
}) {
  return (
    <div>
      {pilares.map((pilar) => {
        return (
          <Pilar
            key={pilar.id}
            pilar={pilar}
            setModalOpen={setModalOpen}
            setPilarActivo={setPilarActivo}
            obtenerPilares={obtenerPilares}
          />
        );
      })}
    </div>
  );
}

export { PilaresList };
