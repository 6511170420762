import { useState, useEffect, useRef } from 'react';
import { Grid } from "@mui/material";
import { Audit } from 'jwatch-viewer'

const Bitacora = () => {

    const public_key = process.env.REACT_APP_JWATCH_PUBLIC_KEY
    const containerRef = useRef(null);
    const [availableHeight, setAvailableHeight] = useState(0);

    useEffect(() => {
        const updateHeight = () => {
            if (containerRef.current) {
                const containerRect = containerRef.current.getBoundingClientRect();
                const height = window.innerHeight - containerRect.top;
                console.log('available height', height);
                setAvailableHeight(height);
            }
        };

        // Update height after the component mounts
        updateHeight();

        // Update height on window resize
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);

    return (
        <Grid container ref={containerRef} sx={{  justifyContent: 'center', padding: 2 }}>
            <Grid xs={'auto'} maxWidth={'100% !important'} item>
                <Audit
                    public_key={public_key}
                    height={`${availableHeight - 40}px`}
                />
            </Grid>
        </Grid>
    )
}

export default Bitacora;