import { HOST } from "../Utils/config";
import { getRequest, postRequest, putRequest, deleteRequest } from "./requests";
import { comprimirPayload } from "../Utils/utilities";

async function getOKRsPlano(periodoId, filters={}) {
  let _filters = {};
  try {
    _filters.gerencias = filters.gerencias.map((g) => ({ gerencia: g.gerencia }));
    _filters.departamentos = filters.departamentos.map((d) => ({ departamento: d.departamento, gerencia: d.gerencia }));
    _filters.puestos = filters.puestos.map((p) => ({ puesto: p.puesto, departamento: p.departamento, gerencia: p.gerencia }));
  } catch (error) {
    _filters = {};
  }
  return await getRequest(`${HOST}/api/v1/okr?${new URLSearchParams({ periodoId, filters: comprimirPayload(_filters || {}) }).toString()}`);
}

async function getOKRsArbol(periodoId) {
  return await getRequest(`${HOST}/api/v1/okr/arbol?periodoId=${periodoId}`);
}

async function getOK(id) {
  return await getRequest(`${HOST}/api/v1/okr/${id}`);
}

async function createOK(body) {
  return await postRequest(`${HOST}/api/v1/okr`, body);
}

async function updateOK(id, body) {
  return await putRequest(`${HOST}/api/v1/okr/${id}`, body);
}

// Funciones para Resultados Clave
async function getKRs(objetivoClaveId) {
  return await getRequest(`${HOST}/api/v1/okr-resultados?objetivoClaveId=${objetivoClaveId}`);
}

async function createKR(body) {
  return await postRequest(`${HOST}/api/v1/okr-resultados`, body);
}

async function updateKR(id, body) {
  return await putRequest(`${HOST}/api/v1/okr-resultados/${id}`, body);
}

async function deleteKR(id) {
  return await deleteRequest(`${HOST}/api/v1/okr-resultados/${id}`);
}

async function getMedicionesKR(id) {
  return await getRequest(`${HOST}/api/v1/okr-resultados/${id}/mediciones`);
}

async function getResultadosOKRs(periodos) {
  return await getRequest(`${HOST}/api/v1/okr-resultados/resultados-digitar?periodosId=${periodos.join(',')}`);
}

async function updateCumplimiento(periodoId) {
  return await putRequest(`${HOST}/api/v1/okr/calcularCumplimiento`, { periodoId });
}

export {
  getOKRsPlano, getOKRsArbol, getOK, createOK, updateOK,
  getKRs, createKR, updateKR, deleteKR, getMedicionesKR,
  getResultadosOKRs, updateCumplimiento
};
