import React, { useEffect } from 'react'
import Paper from '@mui/material/Paper'
import { useNavigate } from 'react-router-dom'

import {
  CircularGauge,
  Scale,
  Label,
  RangeContainer,
  Range,
  Export,
  Title,
  Font
} from 'devextreme-react/circular-gauge'
import style from './raciDashboard.module.css'
import { LayoutContext } from '../../Context/layoutContext'

function DashboardRaciDisplay({
  title,
  asignados,
  evaluados,
  pendientes,
  Icon,
  color,
  tipoRaci,
  avanceMeta
}) {
  const navigate = useNavigate()
  const fontSize =
    window.innerWidth < 1700
      ? '0.75rem'
      : window.innerWidth < 2200
      ? '1rem'
      : '1rem'
  const numberSize =
    window.innerWidth < 1500
      ? '1rem'
      : window.innerWidth < 1800
      ? '2rem'
      : '3rem'
  const [porcentajeIngresado, setPorcentajeIngresado] = React.useState(0)
  const { isDrawerOpen } = React.useContext(LayoutContext)

  const customizeText = ({ valueText }) => {
    return `${valueText} %`
  }

  useEffect(() => {
    let total = evaluados + pendientes
    total = total === 0 ? 1 : total
    let porcentaje = (evaluados * 100) / total
    porcentaje = porcentaje.toFixed(2)
    setPorcentajeIngresado(porcentaje)
  }, [evaluados])

  return (
    <div
      style={{
        width: '100%',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 10px',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        margin: '5px',
        padding: '10px 0px',
        gap: '5px'
      }}
      onClick={() => navigate(`graficas/${tipoRaci}`)}
      data-testid="raci-display"
    >
      <div style={{}}>
        <h3>{title}</h3>
      </div>
      <section>
        <Icon fontSize="large" color={color} />
      </section>

      <Paper sx={{ padding: '10px 40px' }} elevation={8}>
        <section>
          <b style={{ fontSize: numberSize }}>{`${porcentajeIngresado}%`}</b>
          <p
            style={{
              color: 'white',
              fontSize,
              backgroundColor: '#202E3C',
              borderRadius: '10px',
              padding: '5px 10px'
            }}
          >
            {`${evaluados} de ${evaluados + pendientes} `}
          </p>
        </section>
      </Paper>
      <Paper style={{ width: '80%' }}>
        <CircularGauge id="gauge" value={avanceMeta} key={'ANKS' + isDrawerOpen}>
          <Scale startValue={0} endValue={100} tickInterval={10}>
            <Label customizeText={customizeText} />
          </Scale>
          <RangeContainer>
            <Range startValue={0} endValue={20} color="#CE2029" />
            <Range startValue={20} endValue={50} color="#FFD700" />
            <Range startValue={50} endValue={100} color="#228B22" />
          </RangeContainer>
          <Export enabled={true} />
          <Title text={avanceMeta?`Resultado: ${avanceMeta}%`:""}>
            <Font size={20} />
          </Title>
        </CircularGauge>
      </Paper>
    </div>
  )
}

export { DashboardRaciDisplay }
