import Modal from "../../general-components/modal";
import React from "react";
import { CrearAgrupacionForm } from "./crearAgrupacionForm";

export function CrearAgrupacionModal({
  open,
  handleCloseModal,
  updateFavoritos,
  agrupacionEdicion,
}) {
  return (
    <Modal
      open={open}
      handleClose={handleCloseModal}
      title="Agregar nueva agrupación"
    >
      <CrearAgrupacionForm
        handleCloseModal={handleCloseModal}
        updateFavoritos={updateFavoritos}
        agrupacionEdicion={agrupacionEdicion}
      />
    </Modal>
  );
}
