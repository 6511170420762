import React from "react";
import { Checkbox, IconButton } from "@mui/material";
import { PeriodoContext } from "../Context/periodoContext";
import ClearIcon from "@mui/icons-material/Clear";
import DoneAllIcon from "@mui/icons-material/DoneAll";

function PeriodoSelectionList() {
  const { periodos, setPeriodos } = React.useContext(PeriodoContext);

  function updateSelected(id) {
    const newPeriodos = [...periodos];
    const periodo = newPeriodos.find((periodo) => periodo.id === id);
    periodo.seleccionado = !periodo.seleccionado;
    setPeriodos(newPeriodos);
  }

  function setPeriodosToSelected() {
    const newPeriodos = [...periodos];
    newPeriodos.forEach((periodo) => {
      periodo.seleccionado = true;
    });
    setPeriodos(newPeriodos);
  }

  function setPeriodosToNotSelected() {
    const newPeriodos = [...periodos];
    newPeriodos.forEach((periodo) => {
      periodo.seleccionado = false;
    });
    setPeriodos(newPeriodos);
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <section style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={setPeriodosToSelected}
        >
          <IconButton color="primary" aria-label="add to shopping cart">
            <DoneAllIcon />
          </IconButton>
          <b>Seleccionar todos</b>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={setPeriodosToNotSelected}
        >
          <IconButton color="primary" aria-label="add to shopping cart">
            <ClearIcon />
          </IconButton>
          <b>Limpiar</b>
        </div>
      </section>
      <section>
        {periodos.map((periodo) => {
          return (
            <section
              key={`${periodo.id}`}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Checkbox
                onClick={() => updateSelected(periodo.id)}
                checked={periodo.seleccionado}
              />
              <p>{periodo.nombre}</p>
            </section>
          );
        })}
      </section>
    </div>
  );
}

export { PeriodoSelectionList };
