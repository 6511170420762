import React from "react";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import InputIcon from "@mui/icons-material/Input";
import OutputIcon from "@mui/icons-material/Output";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import SettingsIcon from '@mui/icons-material/Settings';
import { nodeType } from "../../Utils/node-types";

export default function FloatingMenuButton({
  addCustomer,
  addSupplier,
  addProcess,
  addActivity,
  tpNodo,
}) {

  return (
    <div>
      {tpNodo === nodeType.actividad && (
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: 'absolute', bottom: 20, right: 20, zIndex: 1000}}
          icon={<SpeedDialIcon />}
        >
          <SpeedDialAction
            key={1}
            icon={<OutputIcon style={{ fontSize: 20 }} />}
            tooltipTitle={'Nuevo cliente'}
            onClick={() => {
              addCustomer();
            }}
          />
          <SpeedDialAction
            key={2}
            icon={<InputIcon style={{ fontSize: 20 }} />}
            tooltipTitle={'Nuevo proveedor'}
            onClick={() => {
              addSupplier();
            }}
          />
        </SpeedDial>
      )}
      {tpNodo === nodeType.proceso && (
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: 'absolute', bottom: 20, right: 20, zIndex: 1000 }}
          icon={<SpeedDialIcon />}
        >
          <SpeedDialAction
            key={1}
            icon={<SettingsIcon style={{ fontSize: 20 }} />}
            tooltipTitle={'Nueva actividad'}
            onClick={() => {
              addActivity();
            }}
          />
          <SpeedDialAction
            key={2}
            icon={<FolderOpenIcon style={{ fontSize: 20 }} />}
            tooltipTitle={'Nuevo proceso'}
            onClick={() => {
              addProcess();
            }}
          />
        </SpeedDial>
      )}
    </div>
  );
}
