import { HOST } from "../Utils/config";
import { getRequest, postRequest, deleteRequest } from "./requests";

async function getPermisosRequest(nodoId) {
  const res = await getRequest(`${HOST}/api/v1/nodorolpolitica/${nodoId}`);
  if (res.body) {
    return res.body;
  } else {
    return [];
  }
}

async function asignarPermisoRequest(data) {
  const response = await postRequest(`${HOST}/api/v1/nodorolpolitica`, data);
  return response;
}

async function removerPermiso(data) {
  const response = await deleteRequest(`${HOST}/api/v1/nodorolpolitica`, data);
  return response;
}

export { getPermisosRequest, asignarPermisoRequest, removerPermiso };
