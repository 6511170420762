import { postRequest } from './requests'
import { CHART_HOST } from '../Utils/config';

const apis = {
    "candlestick": `${CHART_HOST}/graphics`,
    "distribucion": `${CHART_HOST}/graphics`,
    "histograma": `${CHART_HOST}/graphics`,
    "agrupado_semana": `${CHART_HOST}/graphics`,
    "agrupado_mes": `${CHART_HOST}/graphics`,
    "agrupado_periodo": `${CHART_HOST}/graphics`,
    "lineasReferencia": `${CHART_HOST}/lineas-referencia`,
    "processCapability": `${CHART_HOST}/process_capability`,
}

async function getGraphicData(chartType, body={}) {
    const url = apis[chartType]
    if (url) {
        const data = await postRequest(url, { ...body, tipo: chartType });
        if (data.status == 201) {
            return data.body;
        }
    }
    return null
}

async function getRefenceLines(body={}) {
    const url = apis['lineasReferencia']
    if (url) {
        const data = await postRequest(url, body);
        if (data.status == 201) {
            return data.body;
        }
    }
    return null
}

async function getCoeficientesCP(body={}) {
    const url = apis['processCapability']
    if (url) {
        const data = await postRequest(url, body);
        if (data.status == 201) {
            return data.body;
        }
    }
    return null
}

export { getGraphicData, getRefenceLines, getCoeficientesCP }
