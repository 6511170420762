import React from 'react'
import { Indicador } from './Indicador'
import { Checkbox, Divider, FormControlLabel } from '@mui/material'

function IndicadoresList({
  indicadores,
  setModalOpen,
  setIndicadorActivo,
  updateIndicadores,
  indicadorActivo,
  permisos,
  setOpenModalCopia,
  nodoId,
  setOpenModalCompuesto
}) {
  return (
    <div>      
      {indicadores.map((indicador) => {
        return (
          permisos.R == 1 && (
            <Indicador
              key={indicador.id}
              indicador={indicador}
              setModalOpen={setModalOpen}
              setIndicadorActivo={setIndicadorActivo}
              updateIndicadores={updateIndicadores}
              indicadorActivo={indicadorActivo}
              permisos={permisos}
              setOpenModalCopia={setOpenModalCopia}
              nodoId={nodoId}
              setOpenModalCompuesto={setOpenModalCompuesto}
            />
          )
        )
      })}
    </div>
  )
}

export { IndicadoresList }
