import { HOST } from "./config";
import { post, put, delBody } from "./api";
import {postRequest,deleteRequest} from "../Api/requests";

async function getNodeRiesgos(nodoId) {
  const response = await fetch(`${HOST}/api/v1/riesgo/riesgos/${nodoId}`);
  if (response.ok) {
    const jsonResponse = await response.json();
    if (jsonResponse.body) {
      return jsonResponse.body;
    }
    return [];
  }
  return [];
}

async function getRiesgo(id) {
  const response = await fetch(`${HOST}/api/v1/riesgo/${id}`);
  if (response.ok) {
    const jsonResponse = await response.json();
    if (jsonResponse.body) {
      return jsonResponse.body;
    }
    return [];
  }
  return [];
}

async function addRiesgo(data) {
  // const response = await post(`${HOST}/api/v1/riesgo`, data);
  const response = await postRequest(`${HOST}/api/v1/riesgo`, data);
  return response;
}

async function updateRiesgo(data) {
  const response = await put(`${HOST}/api/v1/riesgo`, data);
  return response;
}

async function removeRiesgo(data) {
  // const response = await delBody(`${HOST}/api/v1/riesgo`, data);
  const response = await deleteRequest(`${HOST}/api/v1/riesgo`, data);
  return response;
}

export { getRiesgo,getNodeRiesgos, addRiesgo, removeRiesgo , updateRiesgo};
