import { Fab } from "@mui/material";
import React, { useEffect } from "react";
import { NodoContext } from "../Context/nodoContext";
import { RolesList } from "./rolesList";
import AddIcon from "@mui/icons-material/Add";
import { getRoles, getRolesPrivado } from "../Api/roles.api";
import { toast } from "react-toastify";
import { RolesModal } from "./rolesModal";
import { PermisionDeniedBar } from "../general-components/permisionDeniedBar";
import { ConditionalRendering } from "../general-components/conditionalRendering";

export default function RolesMain() {
  const GESTION_ROLES_MEDUID = 10;
  const [roles, setRoles] = React.useState([]);
  const { nodoActual, setLoading, setLoadingMessage } =
    React.useContext(NodoContext);
  const [rolActivo, setRolActivo] = React.useState({});
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [permissionDenied, setPermissionDenied] = React.useState(false);

  useEffect(() => {
    updateRoles();
  }, []);

  function updateRoles() {
    setLoading(true);
    setLoadingMessage("Cargando...");
    getRolesPrivado(GESTION_ROLES_MEDUID).then((response) => {
      setLoading(false);
      if (response.status === 200) {        
        setRoles(response.body);
      } else if (response.status === 403) {
        setPermissionDenied(true);
      } else {
        toast.error("Error al cargar los roles");
      }
    });
  }

  function closeModal() {
    setModalOpen(false);
  }
  function openModal() {
    setRolActivo(null);
    setModalOpen(true);
  }

  return (
    <div>
      <PermisionDeniedBar display={permissionDenied} />
      <RolesList
        roles={roles}
        setModalOpen={setModalOpen}
        setRolActivo={setRolActivo}
        updateRoles={updateRoles}
      />
      <ConditionalRendering condition={!permissionDenied}>
        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: "fixed",
            right: 10,
            bottom: 10,
            backgroundColor: "#aebd36",
          }}
          onClick={() => openModal()}
        >
          <AddIcon />
        </Fab>
        <RolesModal
          open={isModalOpen}
          setOpen={setModalOpen}
          updateRoles={updateRoles}
          rolActivo={rolActivo}
        />
      </ConditionalRendering>
    </div>
  );
}
