import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'

import styles from '../Styles/main.module.css'
import { CustomizedGraph } from '../Chart/graph'

function GraficasSecundarias({
  subGraphs,
  titulos,
  etiquetaEjeX,
  graficarEjeXDiscreto,
  formatoFecha,
  meta,
  modoIntegracionId,
  metaInversa,
}) {
  useEffect(() => { }, [subGraphs, titulos])
  const navigate = useNavigate()

  if (titulos.length > 0) {
    return (
      <div className={styles['graficas-secundarias']}>
        {subGraphs.map((subGraph, index) => {
          return (
            <CustomizedGraph
              key={subGraph.nombre + index}
              customKey={subGraph.nombre + index}
              titulos={titulos}
              etiquetaEjeX={etiquetaEjeX}
              graficarEjeXDiscreto={graficarEjeXDiscreto}
              formatoFecha={formatoFecha}
              metaInversa={metaInversa}
              resultados={subGraph.resultados}
              titulo={subGraph.nombre}
              indicadorId={subGraph.id}
              meta={meta}
              maxWidth={(window.innerWidth - 400) / 2}
              minWidth={(window.innerWidth - 700) / 2}
              onDoubleClick={() => {
                if (modoIntegracionId === 3)
                  navigate(
                    `/home/procesos/${subGraph.nodoId}/indicadores?indicadorId=${subGraph.id}`
                  )
              }}
              additionalButtons={
                modoIntegracionId !== 2
                  ? []
                  : [
                    {
                      icon: <EditIcon />,
                      onClick: () =>
                        navigate(
                          `/home/procesos/${subGraph.nodoId}/indicadores?indicadorId=${subGraph.id}`
                        ),
                      title: 'Editar Resultados'
                    }
                  ]
              }
            />

          )
        })}
      </div>
    )
  }
  return <></>
}

export { GraficasSecundarias }
