import React from "react";
import { Box, Fab, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import MatrizPuntos from "./matrizPuntos";
import Nivel from "./nivel";
import Ponderacion from "./ponderacion";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div style={{ width: '100%' }}>
                    {children}
                </div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const Evaluacion = () => {

    const [tabIndex, setTabIndex] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <>
            <h1>Configuración para evaluaciones</h1>
            <Box sx={{ margin: 1, width: "100%", height: "auto" }}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    variant="scrollable"
                >
                    <Tab label="Matriz de puntos" id={'tab-0'} />
                    <Tab label="Niveles" id={'tab-1'} />
                    <Tab label="Ponderaciones" id={'tab-2'} />
                </Tabs>
            </Box>
            <TabPanel value={tabIndex} index={0}>
                <MatrizPuntos />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <Nivel />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                <Ponderacion />
            </TabPanel>
        </>
    );
}

export default Evaluacion;