import React, { useState, useEffect } from "react";
import { Button, MenuItem, Chip } from "@mui/material";
import { NodoContext } from "../Context/nodoContext";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { getRiesgosProbabilidad } from "../Api/riesgoprobabilidad.api";
import { getRiesgosImpacto } from "../Api/riesgoimpacto.api";
import { getRiesgo, postRiesgo, putRiesgo } from "../Api/riesgo.api";

export function RiesgosForm({
  selectedItem,
  handleClose,
  getElements,
  setSelectedItem,
}) {
  const { nodoActual } = React.useContext(NodoContext);
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [calificacionResidualEsperada, setCalificacionResidualEsperada] =
    useState(0);
  const [disabled, setDisabled] = useState(false);
  const [probabilidades, setProbabilidades] = useState([]);
  const [impactos, setImpactos] = useState([]);
  const [controles, setControles] = useState([]);
  const [riesgoProbabilidad, setRiesgoProbabilidad] = useState("");
  const [riesgoImpacto, setRiesgoImpacto] = useState("");
  const [nombreControl, setNombreControl] = useState("");
  const [objetivoControl, setObjetivoControl] = useState("");

  useEffect(() => {
    updateProbabilidades();
    updateImpactos();
  }, []);

  useEffect(() => {
    if (selectedItem) {
      updateRiesgo();
    } else {
      setNombre("");
      setDescripcion("");
      setCalificacionResidualEsperada(0);
      setRiesgoProbabilidad("");
      setRiesgoImpacto("");
      setControles([]);
    }
  }, [selectedItem]);

  function updateRiesgo() {
    getRiesgo(selectedItem.id).then((res) => {
      if (res.status === 200) {
        setNombre(res.body.nombre);
        setDescripcion(res.body.descripcion);
        setCalificacionResidualEsperada(res.body.calificacionResidualEsperada);
        setRiesgoProbabilidad(res.body.riesgoProbabilidadId);
        setRiesgoImpacto(res.body.riesgoImpactoId);
        setControles(res.body.RiesgoControls);
      }
    });
  }

  const addItem = async (item) => {
    setDisabled(true);
    try {
      setDisabled(true);
      let data = {
        nombre: nombre,
        descripcion: descripcion,
        nodoId: nodoActual.id,
        riesgoProbabilidadId: riesgoProbabilidad,
        riesgoImpactoId: riesgoImpacto,
        calificacionResidualEsperada,
        controles: controles,
      };
      if (selectedItem) {
        data.id = selectedItem.id;
        putRiesgo(data).then((res) => {
          if (res.status === 200) {
            getElements();
            handleClose();            
          } else {
            toast.error("Error al actualizar riesgo");
          }
        });
      } else {
        postRiesgo(data).then((res) => {
          if (res.status === 201) {
            getElements();
            handleClose();
          } else {
            toast.error("Error al agregar riesgo");
          }
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDisabled(false);
    }
  };

  const updateProbabilidades = async () => {
    getRiesgosProbabilidad().then((res) => {
      if (res.status === 200) {
        setProbabilidades(res.body);
      } else {
        toast.error("Error al obtener probabilidades");
      }
    });
  };

  const updateImpactos = async () => {
    getRiesgosImpacto().then((res) => {
      if (res.status === 200) {
        setImpactos(res.body);
      } else {
        toast.error("Error al obtener impactos");
      }
    });
  };

  return (
    <div style={{ maxHeight: "550px", overflow: "auto" }}>
      <Grid
        container
        spacing={2}
        sx={{ marginTop: "0px", marginBottom: "10px" }}
      >
        <Grid item xs={12}>
          <TextField
            id="nombre"
            label="Nombre"
            variant="outlined"
            value={nombre}
            style={{ width: "100%" }}
            onChange={(e) => setNombre(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="descripcion"
            label="Descripción"
            variant="outlined"
            value={descripcion}
            multiline
            style={{ width: "100%" }}
            onChange={(e) => setDescripcion(e.target.value)}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            id="riesgoProbabilidad"
            select
            label="Probabilidad"
            value={riesgoProbabilidad}
            onChange={(e) => setRiesgoProbabilidad(e.target.value)}
            fullWidth
            required
          >
            {probabilidades.map((probabilidad) => (
              <MenuItem key={probabilidad.id} value={probabilidad.valor}>
                {probabilidad.nombre}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={4}>
          <TextField
            id="riesgoImpacto"
            select
            label="Impacto"
            value={riesgoImpacto}
            onChange={(e) => setRiesgoImpacto(e.target.value)}
            fullWidth
            required
          >
            {impactos.map((impacto) => (
              <MenuItem key={impacto.id} value={impacto.valor}>
                {impacto.nombre}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="riesgocalificacionesperada"
            label="Riesgo Residual esperado"
            value={calificacionResidualEsperada}
            onChange={(e) => setCalificacionResidualEsperada(e.target.value)}
            fullWidth
            required
            type={"number"}
            step={1}
          />
        </Grid>
      </Grid>
      <b>Controles: </b>
      <br />
      {controles.map((control, i) => (
        <Chip
          key={i}
          label={`${control.nombre} - ${control.objetivo}`}
          onDelete={() => {
            setControles(controles.filter((c) => c.nombre !== control.nombre));
          }}
          deleteIcon={<DeleteIcon />}
          variant="outlined"
          sx={{ margin: "5px" }}
        />
      ))}
      <Grid
        container
        spacing={2}
        sx={{ marginTop: "0px", marginBottom: "10px" }}
      >
        <Grid item xs={5.5}>
          <TextField
            id="nombrecontrol"
            label="Nombre"
            variant="outlined"
            value={nombreControl}
            style={{ width: "100%" }}
            onChange={(e) => setNombreControl(e.target.value)}
          />
        </Grid>

        <Grid item xs={5.5}>
          <TextField
            id="objetivo"
            label="Objetivo"
            variant="outlined"
            value={objetivoControl}
            style={{ width: "100%" }}
            onChange={(e) => setObjetivoControl(e.target.value)}
          />
        </Grid>
        <Grid item xs={1} sx={{ margin: "auto" }}>
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => {
              if (nombreControl === "" || objetivoControl === "") return;
              setControles([
                ...controles,
                { nombre: nombreControl, objetivo: objetivoControl },
              ]);
              setNombreControl("");
              setObjetivoControl("");
            }}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        style={{ backgroundColor: "#69bd4b", color: "white" }}
        disabled={disabled}
        onClick={addItem}
      >
        Guardar
      </Button>
    </div>
  );
}
