import React, { useState } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import Modal from '../general-components/modal'
import { nodeType } from '../Utils/node-types'
import NodeForm from '../Nodes/node-form'
import { Divider, Tooltip } from '@mui/material'
import { NodoContext } from '../Context/nodoContext'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { DEFAULT_DELAY } from '../Utils/config'
import { useNavigate } from 'react-router-dom'
import { LayoutContext } from '../Context/layoutContext'
import { ConditionalRendering } from '../general-components/conditionalRendering'
import Button from '@mui/material/Button'
import SearchIcon from '@mui/icons-material/Search'
import SearchModal from './searchModal'
import style from '../Styles/main.module.css'
import ProcessTree from './ProcessTree'
import ProcessTreeWithKPI from './ProcessTreeWithKPI'
import { TIPOS_NODO } from '../Utils/enums'

export default function Sidebar() {
  const [showNodeFormModal, setShowNodeFormModal] = useState(false)
  const [openSearchModal, setOpenSearchModal] = useState(false)
  const [backIconColor, setBackIconColor] = useState('inherit')
  const { showCreateNodeButton } = React.useContext(LayoutContext)
  const navigate = useNavigate()

  const { setDisplayTreeView } = React.useContext(NodoContext)

  const handleSelect = (node) => {
    if (node.tipoNodoId === TIPOS_NODO.INDICADOR) {
      navigate(`procesos/${node.nodoId}/indicadores?indicadorId=${node.id}`)
    } else {
      navigate(`procesos/${node.id}`)
    }
  }

  const handleCloseNodeFormModal = () => {
    setShowNodeFormModal(false)
  }
  const handleCloseSearchModal = () => {
    setOpenSearchModal(false)
  }
  const addProcessModal = async () => {
    setShowNodeFormModal(true)
  }
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ display: { xs: 'none', md: 'flex', margin: '10px 0' } }}>
          <Tooltip
            title="Regresar al menú principal"
            enterDelay={DEFAULT_DELAY}
          >
            <ArrowBackIcon
              fontSize="small"
              sx={{ margin: 'auto', cursor: 'pointer' }}
              onClick={() => setDisplayTreeView(false)}
              color={backIconColor}
              onMouseEnter={() => setBackIconColor('primary')}
              onMouseLeave={() => setBackIconColor('inherit')}
            />
          </Tooltip>
          {/* <Tooltip title="Actualizar procesos" enterDelay={DEFAULT_DELAY}>
            <IconButton
              color="inherit"
              aria-label="Reload"
              component="span"
              onClick={() => reloadTree()}
            >
              <ReplayIcon />
            </IconButton>
          </Tooltip> */}
          <Box sx={{ width: showCreateNodeButton ? '220px' : '225px' }}>
            {/* <SearchInput onChange={handleChange} /> */}
            <Button
              variant="outlined"
              style={{ width: '100%' }}
              startIcon={<SearchIcon />}
              onClick={() => {
                setOpenSearchModal(true)
              }}
            >
              Buscar
            </Button>
          </Box>
          <ConditionalRendering condition={showCreateNodeButton}>
            <Tooltip title="Agregar nuevo proceso" enterDelay={DEFAULT_DELAY}>
              <IconButton
                color="inherit"
                aria-label="upload picture"
                component="span"
                onClick={addProcessModal}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </ConditionalRendering>
        </Box>
      </Box>
      <Divider />

      <div sx={{ width: '100%' }}></div>

      <div className={style['sidebar-tree']}>
        <ProcessTreeWithKPI onNodeClick={handleSelect} draggable={true} />
      </div>
      {/* modal para crear nuevo nodo */}
      <Modal
        open={showNodeFormModal}
        handleClose={handleCloseNodeFormModal}
        title={`Nuevo proceso`}
      >
        <div>
          <NodeForm
            nodeParentId={null}
            nodeTypeId={nodeType.proceso}
            closeModalFn={handleCloseNodeFormModal}
          />
        </div>
      </Modal>
      <SearchModal
        open={openSearchModal}
        handleClose={handleCloseSearchModal}
      />
    </div>
  )
}
