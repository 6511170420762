import React from 'react'
import { useState } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CheckIcon from '@mui/icons-material/Check';

export function SerieItem({ index, name, disabled, deleteAction }) {
  return (
    <div
      style={
        styles.serie
      }
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
        }}
      >
        {name}
      </div>
      {!disabled && (<HighlightOffIcon
        onClick={() => deleteAction(index)}
        style={{
          color: '#f00',
          cursor: 'pointer',
        }}
      />)}

    </div>
  )
}

const styles = {
  serie: {
    width: '100%',
    height: '40px',
    border: '2px dashed #ccc',
    borderRadius: '5px',
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontWeight: 'bold'
  },
  disabled: {
    backgroundColor: '#ccc',
    pointerEvents: 'none',
    color: '#888'
  }, red: {
    backgroundColor: '#f00',
  }
}