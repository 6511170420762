import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { Grid, IconButton, Paper } from '@mui/material';
import { LayoutContext } from '../../Context/layoutContext';


const FloattingToolbar = ({ createDisabled, createAction, editDisabled, editAction, viewDisabled, viewAction }) => {

  const { isDrawerOpen } = React.useContext(LayoutContext)

  return (
    <Box sx={{ position: 'absolute', bottom: '15px', right: 0, display: 'flex', width: `calc(100% - ${isDrawerOpen ? '300px' : '0px'})`, justifyContent: 'center', zIndex: 100 }}>
      <Grid
        container
        component={Paper}
        justifyContent='center'
        alignItems='center'
        elevation={4}
        spacing={3}
        sx={{ width: 'auto', minWidth: '20%' }}
      >
        <div style={{ display: 'flex', flex: 1, justifyContent: 'space-evenly', alignItems: 'center' }}>
          <IconButton
            title='Nuevo OK'
            onClick={createAction}
            disabled={createDisabled}
          >
            <AddIcon color={createDisabled ? 'disabled' : 'success'}/>
            {/* <AddIcon color='success' sx={{ fontSize: '2rem' }}/> */}
          </IconButton>
          <IconButton
            title='Editar OK'
            onClick={editAction}
            disabled={editDisabled}
          >
            <EditIcon color={editDisabled ? 'disabled' : 'primary'}/>
          </IconButton>
          <IconButton
            title='Detalle'
            onClick={viewAction}
            disabled={viewDisabled}
          >
            <VisibilityIcon color={viewDisabled ? 'disabled' : 'primary'}/>
          </IconButton>
        </div>
      </Grid>
    </Box>
  )
}

export default FloattingToolbar;