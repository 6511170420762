import { Fab } from "@mui/material";
import React, { useEffect } from "react";
import { ArchivosList } from "./archivosList";
import Modal from "../general-components/modal";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { toast } from "react-toastify";
import { NodoContext } from "../Context/nodoContext";
import { Box } from "@mui/system";
import {
  getArchivosByResultados,
  getPresignedUrl,
  postFilesMetadata,
  putToS3,
} from "../Api/files.api";
import { ConditionalRendering } from "../general-components/conditionalRendering";
import { TIPOS_RACI } from "../Utils/enums";

function ArchivosMain(props) {
  const { open, setOpen, tipoRaciId, rowId, updateResultados } = props;
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext);
  const [archivos, setArchivos] = React.useState([]);
  const filesRef = React.useRef();

  //const [archivos, setArchivos] = React.useState([]);

  useEffect(() => {
    if (!open) {
      setArchivos([]);
    } else {
      updateArchivos();
    }
  }, [open]);

  function handleCloseModal() {
    setOpen(false);
  }

  function updateArchivos() {
    getArchivosByResultados(rowId).then((response) => {
      if (response.status === 200) {
        setArchivos(response.body);
        //updateResultados()
      } else {
        toast.error(response.message);
      }
    });
  }

  function getFileType(file) {
    return { type: file.type.split("/")[0], format: file.type.split("/")[1] };
  }

  async function askForPresignedUrl({ name, type, format }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await getPresignedUrl({ name, type, format });
        if (response.status !== 200) {
          toast.error(`Error uploading ${name}`);
          reject();
        }
        resolve({ url: response.body.url, key: response.body.key });
      } catch (error) {
        reject(error);
      }
    });
  }

  async function uploadFileToS3(file, url) {
    return new Promise(async (resolve, reject) => {
      try {
        const uploadSuccess = await putToS3(file, url, file.type);
        if (!uploadSuccess) {
          toast.error(`Error uploading ${file.name}`);
          reject();
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  function getUploadPercentage(uploadResponses, files) {
    return ((uploadResponses.length / files.length) * 100).toFixed(0) + "%";
  }

  function filesAreSelected(files) {
    return files.length !== 0;
  }

  function getFileMetadata(internalFileName, visibleFileName, resultadoId) {
    return {
      internalFileName,
      visibleFileName,
      resultadoId,
    };
  }

  function checkForCompleted(uploadResponses, files) {
    if (uploadResponses.length === files.length) {
      toast.success(
        `Se cargaron correctamente ${uploadResponses.filter(Boolean).length} archivos!`
      );
      handleFilesUploaded();
    }
  }

  async function uploadFiles() {
    const files = filesRef.current.files;
    if (!filesAreSelected(files)) {
      return toast.error("Please first choose the file(s)...");
    }
    const uploadResponses = [];
    setLoading(true);
    for (let t = 0; t < files.length; t++) {
      const file = files[t];
      try {
        const { type, format } = getFileType(file);
        const { url, key } = await askForPresignedUrl({
          name: file.name,
          type,
          format,
        });
        await uploadFileToS3(file, url);
        await postFilesMetadata(getFileMetadata(key, file.name, rowId));
        uploadResponses.push(true);
        setLoadingMessage(getUploadPercentage(uploadResponses, files));
        checkForCompleted(uploadResponses, files);
      } catch (error) {
        toast.error("Error uploading ", file.name);
        console.error(error);
        uploadResponses.push(false);
        continue;
      }
    }
  }

  async function handleFilesUploaded(filesMetadata) {
    try {
      updateArchivos();
      updateResultados();
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Ocurrió un error, por favor intente nuevamente");
    }
  }

  const actualizarArchivos = async () => {
    await updateArchivos();
    await updateResultados();
  }

  return (
    <Modal open={open} handleClose={handleCloseModal} title="Archivos Adjuntos">
      <div style={{ maxHeight: "300px", height: "300px", overflow: "auto" }}>
        <ArchivosList
          archivos={archivos}
          setModalOpen={setOpen}
          updateArchivos={actualizarArchivos}
          tipoRaciId={tipoRaciId}
        />
        <ConditionalRendering
          condition={
            tipoRaciId === TIPOS_RACI.RESPONSIBLE || TIPOS_RACI.DIGITADOR
          }
        >
          <Fab
            color="primary"
            aria-label="add"
            sx={{
              position: "fixed",
              right: 10,
              bottom: 10,
              backgroundColor: "#aebd36",
            }}
            onClick={() => filesRef.current.click()}
            size="small"
          >
            <FileUploadIcon />
          </Fab>
        </ConditionalRendering>
      </div>
      <input
        ref={filesRef}
        type="file"
        name="myfile"
        onChange={uploadFiles}
        multiple
        style={{ visibility: "hidden" }}
      />
    </Modal>
  );
}

export { ArchivosMain };
