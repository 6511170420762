import { Divider } from "@mui/material";
import React, { useEffect } from "react";
import SearchInput from "../TreeView/searchInput";
import { Gestion } from "./gestion";
import CachedIcon from '@mui/icons-material/Cached';

import { IconButton, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

export function GestionesList({
  idObjeto,
  nombreObjeto,
  gestiones,
  setGestionId,
  setDeleteGestionConf,  
  puedeBorrar,
  updateGestiones
}) {
  const [gestionesFiltradas, setGestionesFiltradas] = React.useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setGestionesFiltradas(gestiones);
  }, [gestiones]);

  const filterGestiones = (e) => {
    setGestionesFiltradas(
      gestiones.filter((el) =>
        el.nombre.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  return (
    <div>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: "2rem", alignItems: "center", marginLeft: "1rem"}}>
        <div>
        <Tooltip title="Regresar">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        </div>
        <section
          style={{
            width: "250px",
            border: "1px solid black",
            borderRadius: "20px",
            margin: "20px",
          }}
        >
          <SearchInput onChange={filterGestiones} />         
        </section>
        <IconButton
            onClick={updateGestiones}
          >
            <CachedIcon />
          </IconButton>
      </div>
      <Divider />
      {gestionesFiltradas.map((gestion) => {
        return (
          <Gestion
            key={gestion.id}
            gestion={gestion}
            setGestionId={setGestionId}
            setDeleteGestionConf={setDeleteGestionConf}           
            puedeBorrar={puedeBorrar} 
          />
        );
      })}
    </div>
  );
}
