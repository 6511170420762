import React from 'react'
import { toast } from 'react-toastify'
import { getArbolNodosConIndicadores, getNodos } from '../Api/nodos.api'
import { createTreeStructure } from '../Utils/structureMaker'
import { getNodo } from '../Utils/structureMaker'
const NodoContext = React.createContext()

function NodoProvider(props) {
  const [nodos, setNodos] = React.useState([])
  const [estructuraArbolNodos, setEstructuraArbolNodos] = React.useState([])
  const [estructuraArbolNodosIndicadores, setEstructuraArbolNodosIndicadores] =
    React.useState([])
  const [nodoActual, setNodoActual] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [loadingMessage, setLoadingMessage] = React.useState(false)
  const [displayTreeView, setDisplayTreeView] = React.useState(false)
  const [tabId, setTabId] = React.useState(0)

  const reloadTree = async () => {
    getNodos().then((response) => {
      reloadNodosConIndicadores();
      console.log('Nodos: ', response.body)
      if (response.status === 200) {
        setNodos(response.body)
        const estructuraArbol = createTreeStructure(response.body)
        console.log('Estructura Arbol: ', estructuraArbol)
        setEstructuraArbolNodos(estructuraArbol)

      } else {
        toast.error('Ocurrio un error al recuperar los nodos')
      }
    })
  }

  const reloadNodosConIndicadores = async () => {
    getArbolNodosConIndicadores().then((response) => {
      console.log('Nodos', response.body.arbol)
      if (response.status === 200) {
        setEstructuraArbolNodosIndicadores(response.body.arbol)
      } else {
        toast.error('Error al obtener el arbol de nodos con indicadores')
      }
    })
  }

  const reloadNodoActual = async (id) => {
    setLoading(true)
    setLoadingMessage('Cargando información...')
    getNodo(id).then((nodo) => {
      setLoading(false)
      if (nodo === null)
        return toast.error('Ocurrio un error al recuperar los datos')
      setNodoActual(nodo)
    })
  }

  return (
    <NodoContext.Provider
      value={{
        nodos,
        reloadTree,
        nodoActual,
        reloadNodoActual,
        displayTreeView,
        setDisplayTreeView,
        loading,
        setLoading,
        loadingMessage,
        setLoadingMessage,
        estructuraArbolNodos,
        tabId,
        setTabId,
        estructuraArbolNodosIndicadores,
        reloadNodosConIndicadores
      }}
    >
      {props.children}
    </NodoContext.Provider>
  )
}

export { NodoContext, NodoProvider }
