import { HOST } from "../Utils/config";
import { deleteRequest, getRequest, postRequest } from "./requests";

async function getUsuariosBono(periodoId) {
  return await getRequest(`${HOST}/api/v1/usuariobono?periodoId=${periodoId}`);
}

async function asignarUsuariosABono(config) {
  return await postRequest(`${HOST}/api/v1/usuariobono`, config);
}

async function desasignarUsuariosABono(config) {
  return await deleteRequest(`${HOST}/api/v1/usuariobono`, config);
}

export { getUsuariosBono, asignarUsuariosABono, desasignarUsuariosABono };
