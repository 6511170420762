import React, { useEffect, useMemo } from 'react'
import { Fab, IconButton, Tooltip } from '@mui/material'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { CrearFavoritoModal } from './FavoritoForm/crearFavoritoModal'
import { FavoritoThumbnail } from './favoritoThumbnail'
import { toast } from 'react-toastify'
import { AccordionSection } from '../general-components/accordionSection'
import { AccordionOptions } from '../Indicadores/accordionOptions'
import { FavoritoThumbnailPlaceHolder } from './favoritoThumbnailPlaceHolder'
import {
  deleteAgrupacionVistas,
  getAgrupacionesVistas,
  postAgrupacionesPersonal
} from '../Api/vistaAgrupacion.api'
import { getTablerosCompartidosConmigo } from "../Api/vistaIndicador.api"
import { CrearAgrupacionModal } from './AgrupacionTableros/crearAgrupacionModal'
import Confirm from '../general-components/confirm'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Outlet, useParams } from 'react-router-dom'
import GroupsIcon from '@mui/icons-material/Groups'
import AddIcon from '@mui/icons-material/Add'
import { NodoContext } from '../Context/nodoContext'

export function FavoritosMain() {
  /* Modales */
  const [openVistaModal, setOpenVistaModal] = React.useState(false)
  const [openAgrupacionModal, setOpenAgrupacionModal] = React.useState(false)
  const [deleteAgrupacionConf, setDeleteAgrupacionConf] = React.useState(false)
  const [generarAgrupacionesConf, setGenerarAgrupacionesConf] = React.useState(false)

  /* Elementos seleccionados */
  const [vistaSeleccionada, setVistaSeleccionada] = React.useState(null)
  const [agrupacionSeleccionada, setAgrupacionSeleccionada] = React.useState(null);

  // Lista de agrupaciones e indicadores
  const [agrupaciones, setAgrupaciones] = React.useState([])
  const [compartidosConmigo, setCompartidosConmigo] = React.useState([]);
  const [visualizacion, setVisualizacion] = React.useState('mios');
  const { tableroId } = useParams()

  // Tablero de evaluacion de desempeño
  const [existeTableroDesempeno, setExisteTableroDesempeno] = React.useState(false)

  // Dialogo de carga
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext)

  const updateFavCheck = (agrupacionId, vistaId, favorito) => {
    try {
      const agrupacionIndex = agrupaciones.findIndex((x) => x.id == agrupacionId);

      const vistaIndex = agrupaciones[agrupacionIndex].VistaIndicadors.findIndex((x) => x.id == vistaId);

      agrupaciones[agrupacionIndex].VistaIndicadors[vistaIndex].favorito = favorito;
    } catch (error) {
      console.error(error);
    }
  }

  const updateFavCheckCompartidosConmigo = (agrupacionId, vistaId, favorito) => {
    try {
      console.log("vistaId", vistaId);
      console.log("favorito", favorito);
      const vistaIndex = compartidosConmigo.findIndex((x) => x.id == vistaId);
      console.log("vista", compartidosConmigo[vistaIndex]);
      compartidosConmigo[vistaIndex].favorito = favorito;
    } catch (error) {
      console.error(error);
    }
  }

  const agrupacionesComponent = useMemo(() => {
    setExisteTableroDesempeno(false)
    return agrupaciones.map((agrupacion) => {
      if (agrupacion.agrupacionPersonal) setExisteTableroDesempeno(true)
      return (
        <AccordionSection
          key={agrupacion.id}
          name={agrupacion.nombre}
          description={agrupacion.descripcion}
          expanded={agrupacion.expanded}
          handleChange={() => handleAgrupacionChange(agrupacion.id)}
          id={agrupacion.id}
          children={
            <section
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-evenly',
                gap: '5px'
              }}
            >
              {agrupacion.VistaIndicadors.map((vista) => (
                <FavoritoThumbnail
                  key={vista.id}
                  vista={vista}
                  agrupacionPersonal={agrupacion.agrupacionPersonal}
                  //setSelectedFavorito={setVistaSeleccionada}
                  updateFavoritos={updateFavoritos}
                  handleOpenModal={handleOpenVistaModal}
                  own={true}
                  favorito={vista.favorito}
                  updateFavCheck={updateFavCheck}
                />
              ))}
              {!agrupacion.agrupacionPersonal && (
                <FavoritoThumbnailPlaceHolder
                  updateFavoritos={updateFavoritos}
                  agrupacionId={agrupacion.id}
                  setOpenVistaModal={setOpenVistaModal}
                  setAgrupacionActualId={setAgrupacionSeleccionada}
                  ordenDestino={
                    agrupacion.VistaIndicadors.length > 0
                      ? agrupacion.VistaIndicadors[
                        agrupacion.VistaIndicadors.length - 1
                      ].orden
                      : 0
                  }
                />
              )}
            </section>
          }
          options={
            <div>
              <IconButton
                color="error"
                onClick={(e) => handleDeleteClick(e, agrupacion.id)}
              >
                <DeleteIcon />
              </IconButton>
              {!agrupacion.agrupacionPersonal && (
                <IconButton
                  color="primary"
                  onClick={(e) => handleEditClick(e, agrupacion)}
                >
                  <EditIcon />
                </IconButton>
              )}
            </div>
          }
        />
      )
    })
  }, [agrupaciones])

  useEffect(() => {
    obtenerCompartidosConmigo()
    updateFavoritos()
  }, [])


  const obtenerCompartidosConmigo = async () => {
    const vistasRequest = await getTablerosCompartidosConmigo();
    if (vistasRequest.status === 200) {
      console.log("CompartidosConmigo", vistasRequest.body)
      setCompartidosConmigo(vistasRequest.body);
    }
  }

  function handleOpenVistaModal(vista) {
    setVistaSeleccionada(vista)
    setOpenVistaModal(true)
  }

  function handleCloseVistaModal() {
    setVistaSeleccionada(null)
    setOpenVistaModal(false)
  }

  function handleCloseAgrupacionModal() {
    setAgrupacionSeleccionada(null)
    setOpenAgrupacionModal(false)
  }

  const handleExpandirAgrupaciones = () => {
    const agrupacionesCopy = [...agrupaciones]
    Object.values(agrupacionesCopy).forEach((agrupacion) => {
      agrupacion.expanded = true
    })
    setAgrupaciones(agrupacionesCopy)
  }

  function handleContraerAgrupaciones() {
    const agrupacionesCopy = [...agrupaciones]
    Object.values(agrupacionesCopy).forEach((agrupacion) => {
      agrupacion.expanded = false
    })
    setAgrupaciones(agrupacionesCopy)
  }

  const handleAgrupacionChange = (panelId) => {
    const agrupacionesCopy = [...agrupaciones]
    const index = agrupacionesCopy.findIndex(
      (agrupacion) => agrupacion.id === panelId
    )
    agrupacionesCopy[index].expanded = !agrupacionesCopy[index].expanded
    setAgrupaciones(agrupacionesCopy)
  }

  function updateFavoritos() {
    setLoading(true)
    setLoadingMessage('Cargando agrupaciones...')
    getAgrupacionesVistas().then((response) => {
      setLoading(false)
      if (response.status === 200) {
        console.log("agrupaciones", response)
        setAgrupaciones(
          response.body.map((agrupacion) => ({
            ...agrupacion,
            expanded: true
          }))
        )
      } else {
        toast.error('Error al obtener las agrupaciones de vistas')
      }
    })
  }

  function removeAgrupacion() {
    deleteAgrupacionVistas(agrupacionSeleccionada).then((response) => {
      if (response.status === 200) {
        setAgrupacionSeleccionada(null)
        toast.success('Agrupación eliminada correctamente')
        setDeleteAgrupacionConf(false)
        updateFavoritos()
      } else {
        toast.error('Error al eliminar la agrupación')
      }
    })
  }

  function handleDeleteClick(e, agrupacionId) {
    e.stopPropagation()
    setAgrupacionSeleccionada(agrupacionId)
    setDeleteAgrupacionConf(true)
  }

  function handleEditClick(e, agrupacion) {
    e.stopPropagation()
    setAgrupacionSeleccionada(agrupacion)
    setOpenAgrupacionModal(true)
  }

  function generarAgrupaciones() {
    setLoading(true)
    setLoadingMessage('Generando agrupaciones...')
    postAgrupacionesPersonal().then((response) => {
      setLoading(false)
      if (response.status === 201) {
        toast.success('Agrupaciones generadas correctamente')
        setGenerarAgrupacionesConf(false)
        updateFavoritos()
      } else {
        toast.error('Error al generar las agrupaciones')
      }
    })
  }

  function handleVisualizacion(e, param) {
    e.stopPropagation();
    console.log("que pumas");
    setVisualizacion(param)
  }

  if (tableroId) return <Outlet />

  return (
    <div>
      <section>
        <h1>Tableros de indicadores</h1>
      </section>
      <div>
        <ButtonGroup variant="contained" aria-label="outlined primary button group">
          <Button onClick={(e) => handleVisualizacion(e, 'mios')}>Mis tableros</Button>
          <Button onClick={(e) => handleVisualizacion(e, 'compartidos')}>compartidos conmigo</Button>
        </ButtonGroup>
      </div>

      {visualizacion === 'compartidos' && (
        <section style={{ marginLeft: '10px', marginRight: '10px', marginTop: "10px" }}>
          <div><h2>Compartidos conmigo</h2></div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '10px'
            }}
          >
            {compartidosConmigo.map((vista) => (
              <FavoritoThumbnail
                key={vista.id}
                vista={vista}
                agrupacionPersonal={1}
                updateFavoritos={updateFavoritos}
                handleOpenModal={handleOpenVistaModal}
                own={false}
                favorito={vista.favorito}
                updateFavCheck={updateFavCheckCompartidosConmigo}
              />
            ))}
          </div>
        </section>
      )}

      {visualizacion === 'mios' && (
        <div>
          <section style={{ marginLeft: '10px', marginRight: '10px', marginTop: "15px" }}>
            <h2>Mis tableros</h2>
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
                margin: '10px'
              }}
            >
              {!existeTableroDesempeno && (
                <Tooltip title="Generar evaluaciones de desempeño">
                  <IconButton onClick={() => setGenerarAgrupacionesConf(true)}>
                    <GroupsIcon />
                  </IconButton>
                </Tooltip>
              )}
              <AccordionOptions
                expandTitle="Expandir todo"
                compressTitle={'Contrar todo'}
                expandHandler={handleExpandirAgrupaciones}
                compressHandler={handleContraerAgrupaciones}
              />
            </div>
            {agrupacionesComponent}
          </section>

          <Fab
            color="primary"
            aria-label="add"
            sx={{
              position: 'fixed',
              right: 10,
              bottom: 10,
              backgroundColor: '#aebd36'
            }}
            onClick={() => setOpenAgrupacionModal(true)}
          >
            <AddIcon />
          </Fab>
        </div>
      )}
      <CrearFavoritoModal
        open={openVistaModal}
        handleCloseModal={handleCloseVistaModal}
        updateFavoritos={updateFavoritos}
        vistaEdicion={vistaSeleccionada}
        agrupacionActualId={agrupacionSeleccionada}
      />
      <CrearAgrupacionModal
        open={openAgrupacionModal}
        handleCloseModal={handleCloseAgrupacionModal}
        updateFavoritos={updateFavoritos}
        agrupacionEdicion={agrupacionSeleccionada}
      />
      <Confirm
        open={deleteAgrupacionConf}
        title={'Eliminar'}
        text={
          '¿Confirma que desea eliminar esta agrupación? Las vistas que contiene se eliminarán también.'
        }
        onConfirm={removeAgrupacion}
        handleCloseProp={() => setDeleteAgrupacionConf(false)}
      />
      <Confirm
        open={generarAgrupacionesConf}
        title={'Crear nueva agrupación'}
        text={'¿Desea generar la nueva sección de personal a su cargo?'}
        onConfirm={generarAgrupaciones}
        handleCloseProp={() => setGenerarAgrupacionesConf(false)}
      />
    </div>
  )
}
