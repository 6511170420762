import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IconButton, Paper, Tooltip } from '@mui/material'
import ArticleIcon from '@mui/icons-material/Article'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import StarsIcon from '@mui/icons-material/Stars'

export default function FavoritoCard({ nombre, descripcion, id }) {
  const navigate = useNavigate()

  return (
    <Paper style={{ width: 200, height: 100, margin: 5, cursor: 'pointer' }}>
      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <StarsIcon htmlColor="#f1c40f" />
        <div>
          {nombre.length > 15 ? (
            <Tooltip title={nombre}>
              <h4>{`${nombre.substring(0, 15)}...`}</h4>
            </Tooltip>
          ) : (
            <h4>{nombre}</h4>
          )}
        </div>
        <div>
          <Tooltip title="Ver detalle">
            <IconButton
              aria-label="detalle"
              onClick={(e) => {
                e.stopPropagation()
                navigate(`/home/tableros/${id}`)
              }}
            >
              <AnalyticsIcon color="primary" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Ver informes">
            <IconButton
              aria-label="informes"
              onClick={(e) => {
                e.stopPropagation()
                navigate(`/home/listadoinformes/${id}`)
              }}
            >
              <ArticleIcon color="warning" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </Paper>
  )
}
