import { ANALYSIS_HOST } from '../Utils/config'
import { fetchResponse, getHTTPStatusAsText } from './fetchResponse'

async function sendDataToAnalyze(data) {
  try {
    const response = await fetch(ANALYSIS_HOST, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    if (response.ok) {
      const body = await response.json()
      return fetchResponse(200, body, getHTTPStatusAsText(201))
    }
    return fetchResponse(
      response.status,
      null,
      getHTTPStatusAsText(response.status)
    )
  } catch (error) {
    return fetchResponse(500, null, error)
  }
}

export { sendDataToAnalyze }
