import React, { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify'
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import ProcessTreeWithKPI from '../../TreeView/ProcessTreeWithKPI';

const INDICADOR = -1

const IndicadorForm = (props) => {

  const [indicador, setIndicador] = useState(null)

  const cerrarForm = () => {
    setIndicador(null)
    props.handleBack()
    props.handleBack()
  }

  const handleToggleButton = (event, value) => {
    if (value === '1') {
      props.handleBack()
    }
  }

  const handleDoubleClick = (nodo) => {
    if (nodo.tipoNodoId === INDICADOR) {
      setIndicador(nodo)
    }
  }

  const handleSave = () => {
    if (!indicador) {
      toast.error('Seleccione un indicador')
      return
    }

    const kr = {
      nombre: indicador.nombre,
      descripcion: indicador.nombre,
      indicadorId: indicador.id,
    }

    props.onSave(kr)
    cerrarForm()
  }

  return (
    <div style={{ maxHeight: '550px', overflow: 'auto' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', margin: '10px' }}>
        <ToggleButtonGroup
          value={`${props.activeIndex}`}
          exclusive
          color="primary"
          onChange={handleToggleButton}
        >
          <ToggleButton value="1">Personalizado</ToggleButton>
          <ToggleButton value="2">Heredar Indicador</ToggleButton>
        </ToggleButtonGroup>
      </div>

      <section style={{ width: '100%', overflowY: 'scroll' }}>
        <ProcessTreeWithKPI
          fontColor={"black"}
          onNodeClick={handleDoubleClick}
          onDoubleClick={handleDoubleClick}
          draggable={false}
          showAgrupaciones={false}
          showIndicadoresAgrupados={true}
        />
      </section>

      <div style={{ marginTop: '1rem', float: 'right' }}>
        <Button
          variant="contained"
          color='inherit'
          style={{ marginRight: '1rem' }}
          onClick={cerrarForm}
        >
          Cancelar
        </Button>
        <LoadingButton
          loading={props.loading}
          disabled={props.loading}
          onClick={handleSave}
          color='primary'
          variant='contained'
        >
          <span>Guardar</span>
        </LoadingButton>
      </div>
    </div>
  )
}

export default IndicadorForm;
