import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  getVista,
  getVistaConResultados,
  updateEsBorrador
} from '../Api/vistaIndicador.api'
import { updRangoFechas } from '../Api/vistaIndicador.api'
import { createInforme } from '../Api/vistaInforme.api'
import { Tooltip, IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar'
import { NodoContext } from '../Context/nodoContext'
import { PeriodoContext } from '../Context/periodoContext'
import { FavoritosTabs } from './favoritosTabs'
import { PermisionDeniedBar } from '../general-components/permisionDeniedBar'
import moment from 'moment'
import Confirm from '../general-components/confirm'
import { deleteTabRequest } from '../Api/vistaIndicadorTab.api'
import { MessageBar } from '../general-components/messageBar.js'
import TemporaryDrawer from '../general-components/temporary-drawer'
import { FavoritosOpciones } from './favoritosOpciones'
import { TURNOS } from '../Utils/enums.js'
import { comprimirPayload, descomprimirPayload, getTurno } from '../Utils/utilities.js'

let tabMarkedForDelete = null

export function FavoritosDetalle() {
  const navigate = useNavigate()
  const { tableroId } = useParams()
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext)
  const { obtenerPeriodosSeleccionados, getUnicoPeriodoSeleccionado, fireReload, getMinMaxDates } = React.useContext(PeriodoContext)

  const [tabIndex, setTabIndex] = React.useState(0)
  const [leyendas, setLeyendas] = React.useState([])
  const [vista, setVista] = React.useState(null)
  const [tabs, setTabs] = React.useState([])
  const [resultadosEvaluacion, setResultadosEvaluacion] = React.useState([])
  const [nombreInforme, setNombreInforme] = React.useState('')
  const [permissionDenied, setPermissionDenied] = React.useState(false)
  const [deleteTabConfirmation, setDeleteTabConfirmation] = React.useState(false)
  const [fechaInicioConfirmada, setFechaInicioConfirmada] = React.useState(null)
  const [fechaFinConfirmada, setFechaFinConfirmada] = React.useState(null)
  const [keyToReload, setKeyToReload] = React.useState(0)
  const [itemOverDelete, setItemOverDelete] = React.useState(null)
  const [openDrawerOpciones, setOpenDrawerOpciones] = useState(false)
  const [diasZafra, setDiasZafra] = useState([]);
  const [diaZafraSelected, setDiaZafraSelected] = useState(null);
  const [turnoSelected, setTurnoSelected] = useState(null);
  
  const fechaInicioRef = React.useRef(null)
  const fechaFinRef = React.useRef(null)
  
  let savingState = false

  useEffect(() => {
    updateVista();
  }, [])

  useEffect(() => {
    if (
      vista !== null &&
      getUnicoPeriodoSeleccionado() !== null
    ) {
      if (vista && vista.reporteZafra && !vista.cambioBorrador) {
        const _dias = getDiasZafra();
        asignarDiaZafra(_dias)
      }
    }
  }, [vista, fireReload])

  useEffect(() => {
    calcularFechasDiaZafra();
  }, [diaZafraSelected, turnoSelected])

  useEffect(() => {
    if (
      fechaInicioConfirmada !== null &&
      fechaFinConfirmada !== null &&
      vista !== null &&
      obtenerPeriodosSeleccionados() !== null
    ) {
      asignarNombreInforme();
      updateGraficas()
    }
  }, [vista, fechaInicioConfirmada, fechaFinConfirmada, fireReload])

  const updateTabsMemory = (value) => {
    setTabs(value)
  }

  const setDescripcionTab = async (id, descripcion) => {
    const newTabs = tabs
    newTabs.map((tab) => {
      if (tab.id == id) {
        tab.descripcion = descripcion
      }
      return tab
    })

    setTabs(newTabs)
  }

  const tabRender = useMemo(() => {
    if (tabs.length === 0 || leyendas.length === 0) return null

    return (
      <FavoritosTabs
        tabs={tabs}
        leyendas={leyendas}
        resultadosEvaluacion={resultadosEvaluacion}
        updateTabsMemory={updateTabsMemory}
        updateVista={updateVista}
        setPropsTabIndex={setTabIndex}
        setTabs={setTabs}
        tipoUsuario={vista.tipoUsuario}
        esBorrador={vista.esBorrador}
        esTableroPersonal={vista.tableroPersona}
        vista={vista}
        recargarVista={updateVista}
        setDescripcionTab={setDescripcionTab}
        fechaInicioConfirmada={fechaInicioConfirmada}
        fechaFinConfirmada={fechaFinConfirmada}
      />
    )
  }, [tabs, leyendas])

  function getDiasZafra() {
    const _dias = []
    const periodo = getUnicoPeriodoSeleccionado()
    if (periodo != null && vista && vista.reporteZafra) {
      let dia = 1;
      let fechaInicioZafra = moment(periodo.fechaSubInicio).hours(6).minutes(0).seconds(0);
      const fechaFinZafra = moment(periodo.fechaSubFin).hours(6).minutes(0).seconds(0);
      _dias.push({ label: 'Dia 0', value: 'Dia 0', fecha: fechaInicioZafra.clone().subtract(1, 'days') })
      while (fechaInicioZafra <= fechaFinZafra) {
        _dias.push({
          label: `Dia ${dia} (${fechaInicioZafra.format('DD/MM/YYYY')})`,
          value: `Dia ${dia}`,
          fecha: fechaInicioZafra.clone()
        });
        fechaInicioZafra.add(1, 'day')
        dia += 1;
      }
      setDiasZafra(_dias)
      return _dias
    }
  }

  /* CLONAR TODO */
  function asignarDiaZafra(_diasZafra) {
    let fechaDia = moment();
    if (fechaDia.hour() <= 6) fechaDia.subtract(1, 'day')
    fechaDia = fechaDia.hours(6).minutes(0).seconds(0);
    const dia = _diasZafra.find(item => moment(item.fecha).format() === fechaDia.format());
    const turno = TURNOS.find(item => item.value === getTurno(moment()))
    setDiaZafraSelected(dia ? dia : _diasZafra.length ? _diasZafra[0] : null)
    setTurnoSelected(turno)
  }

  function calcularFechasDiaZafra() {
    if (diaZafraSelected && turnoSelected) {
      const fechaInicioDia = moment(diaZafraSelected.fecha);
      const multiTurno = parseInt(Math.floor(turnoSelected.value / 6));
      fechaInicioDia.hours(6).minutes(0).seconds(0)
      const fechaFinDia = moment(fechaInicioDia).add(8 * multiTurno, 'hours').subtract(1, 'minute');

      guardarNuevasFechas(fechaInicioDia.format(), fechaFinDia.format());
    }
  }

  function guardarNuevasFechas(fechaI, fechaF) {
    setFechaInicioConfirmada(new Date(fechaI));
    setFechaFinConfirmada(new Date(fechaF));
  }

  function asignarNombreInforme() {
    let nombre = ''
    const fechaIniLocal = moment(fechaInicioConfirmada).format('DD/MM/YYYY')
    const fechaFinLocal = moment(fechaFinConfirmada).format('DD/MM/YYYY')
    const horaIniLocal = moment(fechaInicioConfirmada).format('h:mma')
    const horaFinLocal = moment(fechaFinConfirmada).format('h:mma')
    if (vista && vista.reporteZafra) {
      nombre = `${diaZafraSelected?.value} ${turnoSelected?.label} - ${fechaIniLocal}`
    } else {
      if (fechaIniLocal === fechaFinLocal) {
        nombre = `${vista?.nombre} ${fechaIniLocal} ${horaIniLocal} - ${horaFinLocal}`
      } else {
        nombre = `${vista?.nombre} ${fechaIniLocal} ${horaIniLocal} - ${fechaFinLocal} ${horaFinLocal}`
      }
    }
    setNombreInforme(nombre)
  }

  function updateVista() {
    if (!tableroId) return
    setLoading(true)
    setLoadingMessage('Cargando vista...')
    getVista(tableroId).then((response) => {
      if (response.status === 200) {
        const vista = response.body
        console.log('vista ', vista)
        setVista(response.body)
        if (!vista.reporteZafra) actualizarFechasGuardadas(vista)
      } else if (response.status === 401) {
        setPermissionDenied(true)
      } else {
        toast.error('Ocurrió un error al obtener la vista de indicadores')
      }
      setLoading(false)
    })
  }

  function actualizarFechasGuardadas(vista) {
    let fechaFinPeriodo
    if (vista.fechaInicio !== null) {
      setFechaInicioConfirmada(new Date(vista.fechaInicio))
    } else {
      const { fechaMin, fechaMax } = getMinMaxDates()
      setFechaInicioConfirmada(new Date(fechaMin))
      fechaFinPeriodo = new Date(fechaMax)
    }
    if (vista.fechaFin !== null) {
      setFechaFinConfirmada(new Date(vista.fechaFin))
    } else {
      setFechaFinConfirmada(fechaFinPeriodo)
    }
    setKeyToReload((prev) => prev + 1)
  }

  function resetearFechasPeriodos() {
    const { fechaMin, fechaMax } = getMinMaxDates();
    setFechaInicioConfirmada(new Date(fechaMin));
    fechaInicioRef.current.value = moment(fechaMin).format('YYYY-MM-DDTHH:mm');
    setFechaFinConfirmada(new Date(fechaMax));
    fechaFinRef.current.value = moment(fechaMax).format('YYYY-MM-DDTHH:mm');
    saveViewChanges();
  }

  function updateGraficas() {
    if (!tableroId) return
    if (fechaInicioConfirmada === null || fechaFinConfirmada === null) return
    setLoading(true)
    setLoadingMessage('Cargando Resultados...')
    const _periodos = obtenerPeriodosSeleccionados()
    if (!_periodos) return toast.error('Debe seleccionar al menos un periodo')
    getVistaConResultados(
      tableroId,
      fechaInicioConfirmada.toISOString().replace('Z', ''),
      fechaFinConfirmada.toISOString().replace('Z', ''),
      new Date().getTimezoneOffset(),
      _periodos[0].id
    ).then((responseCompressed) => {
      setLoading(false)
      if (responseCompressed.status === 200) {
        const response = descomprimirPayload(responseCompressed.body)
        console.log('response ', response)
        setResultadosEvaluacion(response.resultadosEvaluacion)
        const tabs = response.tabs
        setTabs(tabs)
        let _leyendas = response.periodos
        if (vista.ocultarMetas) _leyendas = _leyendas.filter(periodo => !periodo.nombre.startsWith('Meta'))
        setLeyendas(
          _leyendas.map((periodo) => {
            return { ...periodo, value: periodo.nombre, name: periodo.nombre }
          })
        )
      } else {
        toast.error('Ocurrió un error al obtener las gráficas')
      }
    })
  }

  async function createJsonData() {
    try {
      setLoading(true)
      setLoadingMessage('Creando informe...')
      const _periodos = obtenerPeriodosSeleccionados()
      if (!_periodos) return toast.error('Debe seleccionar al menos un periodo')
      const tabsResponseCompressed = await getVistaConResultados(
        tableroId,
        fechaInicioConfirmada.toISOString().replace('Z', ''),
        fechaFinConfirmada.toISOString().replace('Z', ''),
        new Date().getTimezoneOffset(),
        _periodos[0].id
      )
      if (tabsResponseCompressed.status !== 200) {
        throw toast.error('Ocurrió un error al crear el informe')
      }
      const tabsResponse = descomprimirPayload(tabsResponseCompressed.body)
      const tabsActualizados = tabsResponse.tabs
      const resultadosEvaluacion = tabsResponse.resultadosEvaluacion
      // Agregamos la lista de comentarios a cada indicador
      tabsActualizados.forEach((tab) => {
        //tab.descripcion = ''
        tab.indicadores.forEach((indicador) => {
          indicador.resultados.forEach((resultado) => {
            resultado.comentarios = []
            resultado.indicador = indicador.id
            resultado.tab = tab.id
          })
        })
      })
      const informe = {
        id: vista.id,
        nombre: vista.nombre,
        descripcion: vista.descripcion,
        leyendas: leyendas,
        tabs: tabsActualizados,
        resultadosEvaluacion,
        fechaInicial: fechaInicioConfirmada.toISOString().replace('Z', ''),
        fechaFinal: fechaFinConfirmada.toISOString().replace('Z', ''),
        tableroPersona: vista.tableroPersona
      }
      const bodyCompress = comprimirPayload({
        informe,
        vistaId: vista.id,
        nombre: nombreInforme,
        resumen: vista.resumen
      })
      const informeResp = await createInforme({data: bodyCompress})
      if (informeResp.status !== 201) {
        throw toast.error('Ocurrió un error al crear el informe')
      }
      setNombreInforme('')
      actualizarEsBorrador(false) //quita banderita de que es borrador.
      if (informeResp?.body?.body?.id) {
        navigate(`/home/informes/${informeResp.body.body.id}`)
      } else {
        navigate(`/home/listadoinformes/${tableroId}`)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const saveViewChanges = async () => {
    try {
      if (savingState) {
        return
      }

      savingState = true
      if (vista?.reporteZafra) {
        updateGraficas()
      } else {
        const date = new Date() // Obtenemos la fecha actual
        const iniDate = new Date(fechaInicioRef.current.value)
        const endDate = new Date(fechaFinRef.current.value)
        const obj = {
          id: vista.id,
          fechaInicio: iniDate.toISOString().replace('Z', ''),
          fechaFin: endDate.toISOString().replace('Z', ''),
          offset: date.getTimezoneOffset()
        }
        setFechaInicioConfirmada(iniDate)
        setFechaFinConfirmada(endDate)
  
        if (vista.tipoUsuario !== 'R') {
          // si el usuario que está viendo la vista es solo colaborador (tablero compartido) no debe grabar las fechas
          await updRangoFechas(obj)
        }
      }

    } catch (error) {
      console.error(error)
    } finally {
      savingState = false
    }
    return null
  }

  function handleOnDrop(e) {
    e.preventDefault()
    const data = JSON.parse(e.dataTransfer.getData('tab'))
    tabMarkedForDelete = data
    setItemOverDelete(false)
    setDeleteTabConfirmation(true)
  }

  function deleteTab() {
    if (!tabMarkedForDelete)
      return toast.error('No se ha seleccionado ninguna pestaña')
    setDeleteTabConfirmation(false)
    deleteTabRequest(tabMarkedForDelete.id).then((response) => {
      if (response.status === 200) {
        const tabsActualizados = tabs.filter(
          (tab) => tab.id !== tabMarkedForDelete.id
        )
        setTabs(tabsActualizados)
        tabMarkedForDelete = null
      } else {
        toast.error('Ocurrió un error al eliminar la pestaña')
      }
    })
  }

  const actualizarEsBorrador = async (value) => {
    let vs = { ...vista }
    let esBorrador = !vs.esBorrador
    if (value !== undefined) {
      esBorrador = value
    }
    const response = await updateEsBorrador(tableroId, esBorrador)
    if (response.status === 200) {
      vs.esBorrador = esBorrador
      vs.cambioBorrador = true
      setVista(vs)
    }
  }

  if (permissionDenied) {
    return <PermisionDeniedBar display={true} />
  }

  if (obtenerPeriodosSeleccionados() === null) {
    return (
      <MessageBar
        display={true}
        type="error"
        message="Es obligatorio seleccionar al menos un periodo para generar el reporte"
        title="Error"
      />
    )
  }
  
  if (vista && vista.reporteZafra && getUnicoPeriodoSeleccionado() === null) {
    return (
      <MessageBar
        display={true}
        type="error"
        message="Es obligatorio seleccionar un periodo para generar el reporte"
        title="Error"
      />
    )
  }

  return (
    <div>
      <div style={{ marginTop: '10px' }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
            paddingLeft: '10px'
          }}
        >
          <Tooltip title="Volver">
            <IconButton
              onClick={() => {
                navigate('/home/tableros')
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        </div>
        {vista && (
          <>
            <header style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <div style={{ marginRight: '10px' }}>
                <h1>{vista?.nombre}</h1>
                <h5>{vista?.descripcion}</h5>
              </div>
            </header>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Tooltip title="Abrir opciones">
                <IconButton
                  onClick={() => setOpenDrawerOpciones(!openDrawerOpciones)}
                  color={'primary'}
                >
                  <ViewSidebarIcon />
                </IconButton>
              </Tooltip>
              {vista.tipoUsuario !== 'R' && (
                <Tooltip title="Arrastre un tab para eliminarlo">
                  <DeleteIcon
                    fontSize="large"
                    color="error"
                    onDragOver={(e) => {
                      e.preventDefault()
                      setItemOverDelete(true)
                    }}
                    onDragLeave={() => {
                      setItemOverDelete(false)
                    }}
                    onDrop={handleOnDrop}
                    sx={{
                      transform: itemOverDelete ? 'scale(1.5)' : 'scale(1)',
                      zIndex: 10
                    }}
                  />
                </Tooltip>
              )}
            </div>

            {tabRender}
          </>
        )}
      </div>
      <TemporaryDrawer
        position={'right'}
        title="Opciones"
        show={openDrawerOpciones}
        handleClose={() => setOpenDrawerOpciones(false)}
        hideBackdrop={false}
      >
        <FavoritosOpciones
          fechaInicioRef={fechaInicioRef}
          fechaFinRef={fechaFinRef}
          fechaInicioConfirmada={fechaInicioConfirmada}
          fechaFinConfirmada={fechaFinConfirmada}
          saveViewChanges={saveViewChanges}
          keyToReload={keyToReload}
          vista={vista}
          nombreInforme={nombreInforme}
          setNombreInforme={setNombreInforme}
          actualizarEsBorrador={actualizarEsBorrador}
          createJsonData={createJsonData}
          tabs={tabs}
          setTabs={setTabs}
          tabIndex={tabIndex}
          tableroId={tableroId}
          resetearFechasPeriodos={resetearFechasPeriodos}
          diasZafra={diasZafra}
          diaZafraSelected={diaZafraSelected}
          setDiaZafraSelected={setDiaZafraSelected}
          turnoSelected={turnoSelected}
          setTurnoSelected={setTurnoSelected}
          asignarNombreInforme={asignarNombreInforme}
        />
      </TemporaryDrawer>
      <Confirm
        open={deleteTabConfirmation}
        title={'Eliminar'}
        text={'¿Confirma que desea eliminar esta pestaña?'}
        onConfirm={deleteTab}
        handleCloseProp={() => setDeleteTabConfirmation(false)}
      />
    </div>
  )
}
