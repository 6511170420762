import React from 'react'
import DataGrid, {
  Column,
  Grouping,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  Pager,
  Paging,
  ColumnChooser,
  GroupPanel,
  SearchPanel,
  Sorting,
  StateStoring,
  Item,
  Toolbar,
  Summary,
  TotalItem,
  GroupItem,
  Export,
} from "devextreme-react/data-grid";
import { toast } from 'react-toastify';
import { APPILU_HOST } from "../Utils/config";
import { useNavigate } from "react-router-dom";
import ModalGestionConfirm from './modalGestion';
import { ESTADO_EVALUACION } from '../Utils/enums';
import Confirm from "../general-components/confirm";
import { NodoContext } from '../Context/nodoContext';
import { AuthContext } from '../Context/authContext';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { Button as GridButton } from 'devextreme-react/button';
import { Chip, IconButton, Tooltip, styled } from '@mui/material';
import { getPermisoAdminDesempeno, getPermisoGerente } from '../Api/auth.api';
import { postGestionesEvaluaciones, postGestionEvaluacion } from '../Api/evaluacionDesempeno.api';

export default function EvaluacionesGrid({ periodoId, data, allGroupsExpanded, obtenerEvaluaciones }) {

  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openConfirm2, setOpenConfirm2] = React.useState(false);
  const [evaluacionId, setEvalucionId] = React.useState(null);
  const [permisoGerente, setPermisoGerente] = React.useState(false);
  const [permisoAdminDesempeno, setPermisoAdminDesempeno] = React.useState(false);
  const { getUsuario } = React.useContext(AuthContext)
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext)
  const navigate = useNavigate();

  React.useEffect(() => {
    obtenerPermisoGerente()
    obtenerPermisoAdminDesempeno()
  }, [])

  const obtenerPermisoGerente = () => {
    getPermisoGerente().then((response) => {
      console.log(response)
      if (response.status === 200) {
        if (response.body?.permiso) {
          setPermisoGerente(true)
        } else {
          setPermisoGerente(false)
        }
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const obtenerPermisoAdminDesempeno = () => {
    getPermisoAdminDesempeno().then((response) => {
      console.log(response)
      if (response.status === 200) {
        if (response.body?.permiso) {
          setPermisoAdminDesempeno(true)
        } else {
          setPermisoAdminDesempeno(false)
        }
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const enviarGestiones = (conBono) => {
    setLoading(true);
    setLoadingMessage('Cargando...');
    postGestionesEvaluaciones(periodoId, conBono).then((response) => {
      setLoading(false)
      console.log('response ', response)
      if (response.status === 201 && response.body?.body?.length) {
        toast.success(`Se han enviado ${response.body.body.length} evaluaciones exitosamente`, { autoClose: 10000 });
        setOpenConfirm(false);
        obtenerEvaluaciones()
      } else if (response.status === 201) {
        toast.info("No existen colaboradores nuevos para enviar evaluaciones");
      } else {
        toast.error('No se pudieron enviar las evaluaciones')
      }
    }).catch((error) => {
      setLoading(false)
      console.log(error)
    })
  }

  const enviarGestionColaborador = () => {
    setLoading(true);
    setLoadingMessage('Cargando...');
    postGestionEvaluacion(evaluacionId).then((response) => {
      setLoading(false)
      if (response.status === 201) {
        toast.success('Se ha enviado la evaluación exitosamente');
        setOpenConfirm2(false);
        setEvalucionId(null);
        obtenerEvaluaciones()
      } else {
        toast.error('No se pudo enviar la evaluación')
      }
    }).catch((error) => {
      setLoading(false)
      console.log(error)
    })
  }

  const LightChip = styled(Chip)(({ theme, chipcolor }) => ({
    backgroundColor: theme.palette[chipcolor]?.light || theme.palette.error.light,
    color: theme.palette.getContrastText(theme.palette[chipcolor]?.light || theme.palette.error.light),
  }));

  const etapaRender = (estadoEvaluacion, estados) => {
    const estado = ESTADO_EVALUACION.find((estado) => estado.value === estadoEvaluacion)
    let color = 'error'
    let tooltipText = 'Resultados pendientes'
    if (estados?.etapaIndicadores) { tooltipText = 'Encuesta pendiente' }
    if (estados?.etapaIndicadores && estados?.evaluaciones && !estados?.feedback) { color = 'warning'; tooltipText = 'Retroalimentación pendiente' }
    if (estados?.resultados && !estados?.evidencias) { color = 'warning'; tooltipText = 'Evidencias pendientes' }
    if (estados?.etapaIndicadores && estados?.etapaCompetencias) { color = 'success'; tooltipText = 'Evaluación finalizada' }
    return (
      <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        <Tooltip title={tooltipText}>
          <LightChip label={estado.label} chipcolor={color} />
        </Tooltip>
      </div>
    )
  }

  const indicadoresCompletadosRender = (row) => {
    const { totalIndicadores, totalConEvidencias } = row?.data || {}
    return totalIndicadores ? (
      <span>{totalConEvidencias}/{totalIndicadores}</span>
    ) : '0'
  }

  const evaluacionesCompletadasRender = (row) => {
    const { totalEvaluaciones } = row?.data || {}
    return totalEvaluaciones ? (
      <span>{totalEvaluaciones}/{1}</span>
    ) : '0/1'
  }

  const toolbarColumn = (row) => (
    <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', flex: 1 }}>
      <Tooltip title={'Ver dashboard'}>
        <IconButton
          color='default'
          onClick={() => {
            const { id } = row?.data || {}
            if (id) {
              navigate(`/home/dashboardevaluacion`, { state: { id } })
            }
          }}
          size="small"
        >
          <VisibilityIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      {(row.data.gestiones.length === 0 && permisoGerente && (getUsuario() !== row.data?.usuario?.usuario || permisoAdminDesempeno)) ? (
        <Tooltip title={'Enviar gestión de evaluación'}>
          <IconButton
            color='success'
            onClick={() => {
              setEvalucionId(row.data.id)
              setOpenConfirm2(true)
            }}
            size="small"
          >
            <NoteAddIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        (row.data.gestiones?.length && (getUsuario() !== row.data?.usuario?.usuario || permisoAdminDesempeno)) ?(
          <Tooltip title={'Ver gestión de evaluación'}>
            <a href={`${APPILU_HOST}/gestion/${row.data.gestiones[0].gestionId}`} target="_blank" rel="noopener noreferrer">
              <IconButton
                color='primary'
                size="small"
              >
                <StickyNote2Icon fontSize="small" />
              </IconButton>
            </a>
          </Tooltip>
        ) : null
      )}
    </div>
  )

  return (
    <React.Fragment>
      <DataGrid
        dataSource={data}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnResizingMode="nextColumn"
      // onExporting={onExporting}
      >
        <Toolbar>
          <Item location="after">
            <GridButton
              style={(!permisoGerente && !permisoAdminDesempeno) ? { display: 'none' } : {}}
              text="Enviar evaluaciones"
              onClick={() => {
                setOpenConfirm(true)
              }}
              icon="add"
            />
          </Item>
          <Item name="groupPanel" />
          <Item name="exportButton" />
          <Item name="columnChooserButton" />
          <Item name="searchPanel" text='Buscar' />
        </Toolbar>
        <Grouping
          autoExpandAll={allGroupsExpanded}
        />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey={'EvaluacionesGrid'}
        />
        <Sorting mode="multiple" />
        <ColumnChooser
          enabled={true}
          mode="dragAndDrop"
        />
        <GroupPanel visible={true} />

        <Column
          dataField="opciones"
          caption="Opciones"
          allowFiltering={false}
          allowSorting={false}
          cellRender={toolbarColumn}
          width={120}
          allowEditing={false}
        />
        <Column dataField="id" caption="id" dataType="number" visible={false} />
        <Column
          dataField="nombreUsuario"
          caption="Usuario"
          dataType="string"
        />
        <Column
          dataField="gerencia"
          caption="Gerencia"
          dataType="string"
          groupIndex={0}
        />
        <Column
          dataField="puesto"
          caption="Puesto"
          dataType="string"
        />
        <Column
          dataField="estadoEvaluacion"
          caption="Estado"
          calculateGroupValue={(row) => ESTADO_EVALUACION.find((estado) => estado.value === row.estadoEvaluacion).label}
          calculateCellValue={(row) => ESTADO_EVALUACION.find((estado) => estado.value === row.estadoEvaluacion).label}
          cellRender={(row) => etapaRender(row.data.estadoEvaluacion, row.data.estados || {})}
        />
        <Column
          dataField="totalConEvidencias"
          caption="Indicadores con evidencias"
          cellRender={(row) => indicadoresCompletadosRender(row)}
        />
        <Column
          dataField="resultadoIndicadores"
          caption="Resultado Indicadores"
          dataType="number"
          customizeText={({ value }) => (value !== null && value !== undefined) ? parseFloat(value + '').toFixed(2) : null}
        />
        <Column
          dataField="totalEvaluaciones"
          caption="Encuestas Finalizadas"
          cellRender={(row) => evaluacionesCompletadasRender(row)}
        />
        <Column
          dataField="resultadoCompetencias"
          caption="Resultado Competencias"
          dataType="number"
        />

        <FilterRow visible={true} />
        <FilterPanel visible={false} />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <Paging defaultPageSize={50} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[50, 100, 150, 200]}
          showInfo={true}
        />
        <Export enabled={true} allowExportSelectedData={true} />

        <Summary>
          <TotalItem
            column="totalEvaluaciones"
            summaryType="avg"
            valueFormat="number"
            customizeText={(data) => `${(data.value * 100).toFixed(2)}%`}
          />
          <GroupItem
            column="totalEvaluaciones"
            summaryType="avg"
            valueFormat="number"
            customizeText={(data) => `${(data.value * 100).toFixed(2)}%`}
            alignByColumn={true}
          />

        </Summary>

      </DataGrid>

      <ModalGestionConfirm
        open={openConfirm}
        onConfirm={enviarGestiones}
        handleCloseProp={() => setOpenConfirm(false)}
      />
      <Confirm
        open={openConfirm2}
        title={"Enviar la gestión de evaluación a este colaborador"}
        text={"¿Confirma que desea enviar la evaluación de competencias al colabororador seleccionado?"}
        onConfirm={enviarGestionColaborador}
        handleCloseProp={() => {
          setOpenConfirm2(false)
          setEvalucionId(null)
        }}
      />
    </React.Fragment>

  )
}

