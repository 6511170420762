import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { getPilares } from '../Api/pilares.api'
import { Tree } from '../TreeView/tree'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'

export default function PilarTree({
  onDoubleClick = () => {},
  onClick = () => {},
  fontColor = 'black'
}) {
  const [pilares, setPilares] = React.useState([])

  useEffect(() => {
    getPilares().then((response) => {
      if (response.status === 200) {
        setPilares(response.body)
      } else {
        toast.error('Error al obtener los pilares organizacionales y objetivos')
      }
    })
  }, [])

  return (
    <Tree
      fontColor={fontColor}
      level={0}
      iconsHash={{
        1: <AccountBalanceIcon />,
        2: <LocalOfferIcon />
      }}
      onNodeClick={onClick}
      onDoubleClick={onDoubleClick}
      onDrop={() => {}}
      estructuraArbol={pilares}
    />
  )
}
