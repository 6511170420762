import React, { useEffect } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { getResultadosCompletos } from "../Api/resultado.api";
import { getTiposRaci } from "../Api/tipoRaci.api";
import { PeriodoContext } from "../Context/periodoContext";
import { DashboardTable } from "../Home/Dashboard/dashboardTable";
import { RaciSelection } from "./raciSelection";
import { NodoContext } from "../Context/nodoContext";
import { Fab, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardIcon from '@mui/icons-material/Keyboard';
import Modal from "../general-components/modal";
import { AccordionOptions } from "../Indicadores/accordionOptions";
import { ResultadoForm } from "./resultadoForm";
import { SimpleDialog } from "./resultadosDigitador";


function ResultadosMain() {
  const [resultados, setResultados] = React.useState([]);
  const [atajosTeclado, setAtajosTeclado] = React.useState(false);
  const [tiposRaci, setTiposRaci] = React.useState([]);
  const [mostrarSoloVencidos, setMostrarSoloVencidos] = React.useState(true);
  const [tipoRaciActual, setTipoRaciActual] = React.useState(null);
  const { fireReload } = React.useContext(PeriodoContext);
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext);
  const [openModal, setOpenModal] = React.useState(false);
  const [expandedGroups, setExpandedGroups] = React.useState(false);

  useEffect(() => {
    updateTiposRaci();

    const isFirstVisit = !localStorage.getItem('atajosTeclado');
    if (isFirstVisit) {
      setTimeout(() => {
        setAtajosTeclado(true);
      }, 1000);
      localStorage.setItem('atajosTeclado', 'true');
    }
  }, []);

  useEffect(() => {
    if (tipoRaciActual) {
      updateResultados();
    }
  }, [tipoRaciActual, mostrarSoloVencidos, fireReload]);

  function updateTiposRaci() {
    setLoading(true);
    setLoadingMessage("Cargando..");
    getTiposRaci().then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setTiposRaci(response.body);
        setTipoRaciActual(response.body[0]);
      } else {
        toast.error(response.message);
      }
    });
  }

  function getExpirationDate() {
    return mostrarSoloVencidos ? moment().format("YYYY-MM-DD") : "2099-11-11";
  }

  function updateResultados() {
    setLoading(true);
    setLoadingMessage("Cargando..");
    getResultadosCompletos(tipoRaciActual.id, getExpirationDate()).then(
      (response) => {
        setLoading(false);
        if (response.status === 200) {
          setResultados(response.body);
        } else {
          toast.error("No se pudieron recuperar los resultados");
        }
      }
    );
  }

  const handleClose = () => {
    setOpenModal(false);
  };

  const compressHandler = () => {
    setExpandedGroups(true);
    setTimeout(() => {
      setExpandedGroups(false);
    }, 100);
  }

  return (
    <>
      <RaciSelection
        mostrarVencidos={mostrarSoloVencidos}
        setMostrarVencidos={setMostrarSoloVencidos}
        tiposRaci={tiposRaci}
        setTipoRaciActual={setTipoRaciActual}
        tipoRaciActual={tipoRaciActual}
      />
      <section
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          fontSize: "30px",
          textAlign: "left",
          padding: "0.5rem 1rem",
        }}
      >
        <b>Mediciones: </b>
        <div style={{ display: 'flex', justifyContent: 'right', margin: '10px' }}>
          <IconButton
            title="Atajos del teclado"
            onClick={() => setAtajosTeclado(!atajosTeclado)}
            color="info"
          >
            <KeyboardIcon />
          </IconButton>
          <AccordionOptions
            compressHandler={compressHandler}
            expandHandler={() => setExpandedGroups(true)}
            expandTitle="Expandir todos"
            compressTitle="Contraer todos"
          />
        </div>
      </section>
      <section>
        <DashboardTable
          resultados={resultados}
          tipoRaciId={tipoRaciActual?.id || null}
          updateResultados={updateResultados}
          storageKey={'resultadosMainStorage'}
          allGroupsExpanded={expandedGroups}
        />
      </section>
      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          right: 10,
          bottom: 10,
          backgroundColor: "#aebd36",
        }}
        onClick={() => { setOpenModal(true) }}
      >
        <AddIcon />
      </Fab>

      <Modal title="Nuevo resultado" open={openModal} handleClose={handleClose}>
        <ResultadoForm handleClose={handleClose} />
      </Modal>

      <SimpleDialog open={atajosTeclado} onClose={() => setAtajosTeclado(false)} />
    </>
  );
}

export { ResultadosMain };
