import { HOST } from "../Utils/config";
import { getRequest, postRequest, putRequest } from "./requests";

async function getRiesgo(riesgoId) {
  return await getRequest(`${HOST}/api/v1/riesgo/${riesgoId}`);
}

async function postRiesgo(riesgo) {
  return await postRequest(`${HOST}/api/v1/riesgo`, riesgo);
}

async function putRiesgo(riesgo) {
  return await putRequest(`${HOST}/api/v1/riesgo`, riesgo);
}

export { getRiesgo, putRiesgo, postRiesgo };
