import React from "react";
import { useEffect, useState } from 'react'
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Modal from '../../general-components/modal';
import Confirm from '../../general-components/confirm';
import { getKRs, getMedicionesKR } from "../../Api/okr.api";
import { NodoContext } from "../../Context/nodoContext";
import ResultadosGraph from "./resultadosGraph";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import 'swiper/css';
import { Typography } from "@mui/material";

const KRMedicionesModal = ({ open, handleCloseModal, objetivoClave }) => {

  const swiperRef = React.useRef();
  const [itemsKR, setItemsKR] = useState([])
  const [mediciones, setMediciones] = useState({ periodos: [], resultados: [] })
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext)

  useEffect(() => {
    if (objetivoClave.id) {
      setMediciones({ periodos: [], resultados: [] })
      obtenerKRs()
    }
  }, [objetivoClave])

  const obtenerKRs = async () => {
    try {
      setLoading(true)
      const response = await getKRs(objetivoClave.id)
      if (response.status === 200) {
        if (response.body.length > 0) {
          obtenerMediciones(response.body[0])
        }
        console.log('KR response.body', response.body)
        setItemsKR(response.body)

      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  const obtenerMediciones = async (kr) => {
    try {
      setLoading(true)
      const response = await getMedicionesKR(kr.id)
      if (response.status === 200) {
        console.log('KR response.body mediciones', response.body)
        setMediciones(response.body)
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  const handleSlideChange = () => {
    if (swiperRef.current) {
      obtenerMediciones(itemsKR[swiperRef.current.activeIndex]);
    }
  };

  return (
    <Modal
      open={open}
      title={'Resultados clave'}
      handleClose={handleCloseModal}
      customStyles={{ width: '80%', p: 3 }}
      width={'80%'}
    >
      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        spaceBetween={50}
        slidesPerView={1}
        navigation={true}
        pagination={{ type: "progressbar" }}
        modules={[Pagination, Navigation]}
        onSlideChange={handleSlideChange}
      >
        {itemsKR.map((kr, index) => (
          <SwiperSlide key={index}>
            <ResultadosGraph keyResult={kr} mediciones={mediciones} />
          </SwiperSlide>
        ))}
      </Swiper>

      {!itemsKR.length && (
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
          <Typography variant='body1' color='textSecondary' textAlign='center'>
            No hay resultados clave
          </Typography>
        </div>
      )}

    </Modal>
  )

}

export default KRMedicionesModal;
