import React, { useEffect } from 'react'
import { PeriodoContext } from '../../Context/periodoContext'
import { NodoContext } from '../../Context/nodoContext'
import { DashboardRaciDisplay } from '../Dashboard/dashboardRaciDisplay'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import EngineeringIcon from '@mui/icons-material/Engineering'
import { toast } from 'react-toastify'
import {
  getResumenResultados,
  getResumenResultadoMisSubalternos,
  getAvanceUsuario,
  getAvanceSubalternos
} from '../../Api/resultado.api'
import { AuthContext } from '../../Context/authContext'
import { getTopFavoritos } from '../../Api/vistaIndicador.api'
import { DashboardNoData } from './dashboardNoData'
import FavoritoCard from './favoritoCard'
import { ProgresoPersona } from './progresoPersona'
import style from './raciDashboard.module.css'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

function RaciDashboard() {
  const navigate = useNavigate()
  const [resumen, setResumen] = React.useState(null)
  const { fireReload } = React.useContext(PeriodoContext)
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext)
  const { selectedUser, getSelectedUserID } = React.useContext(AuthContext)
  const [tablerosFavoritos, setTablerosFavoritos] = React.useState([])
  // const [resumenSubalternos, setResumenSubalternos] = React.useState([])
  const [avanceR, setAvanceR] = React.useState(null)
  const [avanceA, setAvanceA] = React.useState(null)
  const [avanceSubalternos, setAvanceSubalternos] = React.useState([])

  useEffect(() => {
    updateResultados()
    topFavoritos()
    avanceSubAlternosFn()
  }, [])

  useEffect(() => {
    updateResultados()
    topFavoritos()
    avanceSubAlternosFn()
  }, [fireReload, selectedUser])

  const topFavoritos = async () => {
    let req = await getTopFavoritos()
    console.log('tablerosReq', req)
    if (req.status == 200) {
      setTablerosFavoritos(req.body)
    }
  }

  async function updateResultados() {
    setLoading(true)
    setLoadingMessage('Cargando...')
    getResumenResultados(getSelectedUserID()).then((response) => {
      setLoading(false)
      if (response.status === 200) {
        setResumen(response.body)
      } else {
        toast.error('No se pudieron recuperar los resultados')
      }
    })
    const reqR = await getAvanceUsuario(1)
    console.log('reqR', reqR)
    const reqA = await getAvanceUsuario(2)
    if (reqR.status == 200) {
      if (reqR.body.length > 0) {
        setAvanceR(reqR.body[0].porcentajeAvance)
      }
    }
    if (reqA.status == 200) {
      if (reqA.body.length > 0) {
        setAvanceA(reqA.body[0].porcentajeAvance)
      }
    }
  }

  // const resumenResultadoSubAlternos = async () => {
  //   const req = await getResumenResultadoMisSubalternos()
  //   console.log('subalternos', req)
  //   if (req.status === 200) {
  //     setResumenSubalternos(req.body)
  //   }
  // }

  const avanceSubAlternosFn = async () => {
    const req = await getAvanceSubalternos()
    console.log('subalternos', req)
    if (req.status == 200) {
      setAvanceSubalternos(req.body)
    }
  }

  return (
    <div className={style.main} data-test-id="raciDashbopard">
      <div className={style.panel}>
        <div className={style['mini-panel']}>
          <DashboardRaciDisplay
            title={'Resp. Ejecución'}
            asignados={resumen?.totalAsignados[0]}
            evaluados={resumen?.responsible[0]}
            pendientes={resumen?.responsible[1]}
            avanceMeta={avanceR}
            Icon={EngineeringIcon}
            color="default"
            tipoRaci={1}
          />
          <DashboardRaciDisplay
            title={'Resp. Directivo'}
            asignados={resumen?.totalAsignados[1]}
            evaluados={resumen?.accountable[0]}
            pendientes={resumen?.accountable[1]}
            avanceMeta={avanceA}
            Icon={EmojiPeopleIcon}
            color="default"
            tipoRaci={2}
          />
        </div>
        <div className={style['mini-panel']}>
          <DashboardNoData
            title={'Consultado'}
            Icon={HelpOutlineIcon}
            color="default"
            tipoRaci={3}
          />
          <DashboardNoData
            title={'Informado'}
            Icon={RecordVoiceOverIcon}
            color="default"
            tipoRaci={4}
          />
        </div>
      </div>
      <div className={style.panel}>
        <div className={style['mini-panel']}>
          <div className={style['panel-with-title']}>
            <h3>Mis tableros favoritos</h3>
            {tablerosFavoritos.length > 0 && (
              <div className={style['horizontal-scroll-tablero']}>
                {tablerosFavoritos.map((fav) => (
                  <FavoritoCard
                    nombre={fav.nombre}
                    descripcion={fav.descripcion}
                    id={fav.id}
                  />
                ))}
              </div>
            )}
            {tablerosFavoritos.length === 0 && (
              <>
                <p>No tienes tableros favoritos</p>
                <Button
                  onClick={() => {
                    navigate('/home/tableros')
                  }}
                >
                  Ir a tableros
                </Button>
              </>
            )}
          </div>
        </div>

        {avanceSubalternos.length > 0 && (
          <div className={style['mini-panel']}>
            <div className={style['panel-with-title']}>
              <h3>Personas a mi cargo</h3>
              <div className={style['horizontal-scroll']}>
                {avanceSubalternos.map((persona) => (
                  <ProgresoPersona persona={persona} />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export { RaciDashboard }
