import React from "react";
import { Box, Card, CardContent, Tooltip, IconButton, CardHeader, Avatar, TextField, InputAdornment, Grid, Typography } from "@mui/material";
import DiagramaCajas from "../Custom/diagramaCajas";
import moment from "moment";
import { AuthContext } from "../Context/authContext";
import { getNameInitials } from "../Utils/utilities";
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import EstadosEvaluacion from "../Custom/estadosEvaluacion";
import { NodoContext } from "../Context/nodoContext";
import { createFeedbackEvaluacion, getFeedbackEvaluacionIA, updateFeedbackEvaluacion } from "../Api/feedbackEvaluacion.api";
import { toast } from "react-toastify";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CkEditor from "../general-components/ckeditor";
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { APPILU_HOST } from "../Utils/config";

const temp_coments = [
    { fecha: '2024-01-01', usuarioNombreCompleto: 'RICARDO GUSTAVO CUEVAS ROSALES', comentario: 'Muy bien hecho' },
    { fecha: '2024-02-12', usuarioNombreCompleto: 'SERGIO HUMBERTO GUZMAN MALDONADO', comentario: 'Gracias :D' },
]

const Evaluaciones = ({ data, nivelesConfig, setData, permisoAdminDesempeno }) => {

    const [boxQuantity, setBoxQuantity] = React.useState(0)
    const [matrizConfig, setMatrizConfig] = React.useState({})
    const { getNombre, getUsuario } = React.useContext(AuthContext)
    const [estados, setEstados] = React.useState({})
    const [esJefe, setEsJefe] = React.useState(false)
    const [esEvaluado, setEsEvaluado] = React.useState(false)
    const [allowComment, setAllowComment] = React.useState(false)
    const [feedbackIA, setFeedbackIA] = React.useState("")
    const [editFeedbackId, setEditFeedbackId] = React.useState(null)

    const { setLoading } = React.useContext(NodoContext)
    const [newComment, setNewComment] = React.useState('')

    React.useEffect(() => {
        const json = JSON.parse(data?.json || '{}')
        setEstados(json?.estados || {})
        setBoxQuantity((data?.matriz?.x?.length || 0) * (data?.matriz?.y?.length || 0))
        setMatrizConfig(data?.matriz?.configuracion || {})
        if (nivelesConfig?.length) {
            const resInd = data?.resultadoIndicadores || 0
            // En base a rango_inicio y rango_fin de nivelesConfig, se obtiene el nivel correspondiente
            const nivelIndicadores = nivelesConfig.find(nivel => resInd >= nivel.rango_inicio && resInd < nivel.rango_fin && nivel.eje === 'x')
            const posicion = `${nivelIndicadores?.ponderacionValor || 0},${data?.resultadoCompetencias || 0}`
            if (data?.matriz?.configuracion) {
                const config = data?.matriz?.configuracion[posicion]

                // Setear un nuevo valor showIcon en la posicion correspondiente
                const newConfig = {
                    ...data?.matriz?.configuracion,
                    [posicion]: {
                        ...config,
                        showIcon: true
                    }
                }
                setMatrizConfig(newConfig)
            }
        }
        const faseFeedback = json?.estados?.etapaIndicadores && json?.estados?.evaluaciones
        const _esEvaluado = data?.usuarioUserId === getUsuario()
        const _esJefe = data?.usuarioJefeInmediato === getUsuario()
        const _allowComment = faseFeedback && json?.estados?.estado != 4 ?
            _esJefe ? data?.comentarios?.filter(item => item.usuarioUserId === getUsuario()).length === 0 :
                _esEvaluado && data?.comentarios?.filter(item => item.tipoEvaluacion === 2).length ?
                    data?.comentarios?.filter(item => item.usuarioUserId === getUsuario()).length === 0 : false
            : false
        setEsJefe(_esJefe)
        setEsEvaluado(_esEvaluado)
        setAllowComment(_allowComment)
        console.log("data", data)
    }, [data, nivelesConfig])

    const getNombreNivelDesempeno = () => {
        const resInd = data?.resultadoIndicadores || 0
        const nivelIndicadores = nivelesConfig.find(nivel => resInd >= nivel.rango_inicio && resInd < nivel.rango_fin && nivel.eje === 'x')
        return nivelIndicadores?.ponderacionNombre || ''
    }

    const getNombreNivelPotencial = () => {
        const resComp = data?.resultadoCompetencias || 0
        const nivelComp = nivelesConfig.find(nivel => resComp >= nivel.rango_inicio && resComp < nivel.rango_fin && nivel.eje === 'y')
        return resComp ? nivelComp?.ponderacionNombre || '' : 'Sin calificar'
    }

    const handleClose = () => {
        setFeedbackIA("")
    }

    const handleFeedbackAI = () => {
        setLoading(true)
        getFeedbackEvaluacionIA(data?.id).then((response) => {
            const { body } = response || {}
            const { feedback } = body || {}
            setNewComment(feedback)
        }).catch((error) => {
            toast.error('Ocurrió un error al obtener el feedback de IA')
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleSendComment = (isIA = false) => {
        setLoading(true)
        const tipoEvaluacion = esJefe ? 2 : esEvaluado ? 1 : null
        const action = editFeedbackId ? updateFeedbackEvaluacion(editFeedbackId, isIA ? feedbackIA : newComment) :
            createFeedbackEvaluacion(isIA ? feedbackIA : newComment, data?.id, tipoEvaluacion)
        action.then((response) => {
            const { body } = response || {}
            setData(body?.body || {})
            // data?.comentarios.push(body?.body)
            setNewComment('')
            setFeedbackIA('')
            toast.success('Comentario enviado correctamente')
        }).catch((error) => {
            setLoading(false)
            toast.error('Ocurrió un error al enviar el comentario')
        })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Box sx={{ flexGrow: 1, alignItems: 'left', justifyContent: 'left', textAlign: 'left' }}>
            {/* titulo */}
            <Grid xs={12} sx={{
                fontSize: "20px",
                textAlign: "left",
                paddingBottom: "1em",
                paddingX: { xs: '0.5rem', md: '1rem' }
            }}>
                <b>
                    Diagrama de {boxQuantity} cajas
                </b>
            </Grid>
            <Grid container alignItems={'flex-start'}>
                {/* Diagrama de cajas */}
                <Grid container xs={12} sm={12} md={5} lg={5} xl={4} sx={{ paddingRight: { md: 1 } }} >
                    <div style={{ width: '100%' }}>
                        <Card sx={{ width: '100%' }}>
                            <CardContent>
                                <DiagramaCajas
                                    plantilla={data?.matriz}
                                    configuracion={matrizConfig}
                                />
                            </CardContent>
                        </Card>
                    </div>
                </Grid>
                {/* Resultados card*/}
                <Grid container xs={12} sm={12} md={7} lg={7} xl={8} sx={{ paddingLeft: { md: 1 } }}>
                    {/* Card resultados evaluacion de indicadores */}
                    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ paddingRight: { md: 1 } }}>
                        <Card sx={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <p>CALIFICACIÓN DE DESEMPEÑO</p>
                                    <span style={{ fontSize: '25px', fontWeight: 'bold' }}>
                                        {getNombreNivelDesempeno()}
                                    </span>
                                </CardContent>
                            </Box>
                            {/* <CardContent sx={{ display: 'flex' }}>
                                <div style={{ alignSelf: 'flex-end' }}>
                                    <Chip label={`Meta: ${90}`} />
                                </div>
                            </CardContent> */}
                        </Card>
                    </Grid>
                    {/* Card resultados evaluacion de competencias */}
                    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ marginTop: { xs: 1, md: 0 }, paddingLeft: { md: 1 } }}>
                        <Card sx={{ display: 'flex', justifyContent: 'left' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <p>CALIFICACIÓN DE COMPETENCIAS</p>
                                    <span style={{ fontSize: '25px', fontWeight: 'bold' }}>
                                        {getNombreNivelPotencial()}&nbsp;
                                        {data?.gestiones?.length > 0 ? (
                                            (getUsuario() !== data?.usuarioUserId || permisoAdminDesempeno) &&
                                                <Tooltip title={'Ver gestión de evaluación'}>
                                                    <a href={`${APPILU_HOST}/gestion/${data.gestiones[0].gestionId}`} target="_blank" rel="noopener noreferrer">
                                                        <IconButton
                                                            color='primary'
                                                            size="small"
                                                        >
                                                            <StickyNote2Icon fontSize="small" />
                                                        </IconButton>
                                                    </a>
                                                </Tooltip>
                                        ) :
                                            <span style={{ fontSize: '15px', fontWeight: 'normal' }}>
                                                (No hay evaluación)
                                            </span>
                                        }
                                    </span>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    {/* Contenedor historial de pasos (stepper) */}
                    <Grid item container xs={12} sx={{ paddingX: { md: 2 }, marginTop: { xs: 1 } }}>
                        <Grid item xs sx={{ marginBottom: '1rem' }}>
                            <Typography variant="h6" sx={{ fontWeight: 700, marginBottom: 1 }}>Etapa:</Typography>
                            <EstadosEvaluacion estados={estados} />
                        </Grid>
                    </Grid>
                    {/* Retroalimentación */}
                    <Grid item xs={12}>
                        <div style={{ fontSize: "20px", textAlign: "left", }}>
                            <b>
                                Retroalimentación:
                            </b>
                        </div>
                        {data?.comentarios?.map((comentario, index) => {
                            return (
                                <Card key={index} sx={{ width: '100%', margin: '0.5em 0' }}>
                                    <CardHeader
                                        avatar={
                                            <Avatar sx={{}} aria-label="recipe">
                                                {getNameInitials(comentario?.usuarioNombreCompleto || 'Usuario Anónimo')}
                                            </Avatar>
                                        }
                                        title={comentario?.usuarioNombreCompleto}
                                        subheader={moment(comentario.fechaCreacion).fromNow()}
                                        action={
                                            getUsuario() === comentario?.usuarioUserId && estados?.estado != 4 &&
                                            <Tooltip title="Editar">
                                                <IconButton onClick={() => {
                                                    setFeedbackIA(comentario?.comentario)
                                                    setEditFeedbackId(comentario?.id)
                                                }}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    />
                                    <CardContent style={{ padding: '0em 2em 2em 2em' }}>
                                        <div dangerouslySetInnerHTML={{ __html: comentario?.comentario }}></div>
                                    </CardContent>
                                </Card>
                            )
                        })}
                        {!data?.comentarios?.length &&
                            <p style={{ textAlign: 'center', color: '#bfbfbf' }}>
                                Aún no hay comentarios
                            </p>
                        }
                        {/* Caja para comentar */}
                        {allowComment &&
                            <Card sx={{ width: '100%', margin: '0.5em 0' }}>
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{}} aria-label="recipe">
                                            {getNameInitials(getNombre() || 'Usuario Anónimo')}
                                        </Avatar>
                                    }
                                    title={getNombre()}
                                />
                                <CardContent>
                                    <Grid container xs={12} >
                                        {/* Editor para comentario */}
                                        <Grid item xs={10} xl={11}>
                                            <CkEditor
                                                value={newComment}
                                                setValue={setNewComment}
                                                titulo={""}
                                            />
                                        </Grid>
                                        <Grid item xs style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div style={{ alignSelf: 'center' }}>
                                                <Tooltip title="Generar feedback IA">
                                                    {esJefe &&
                                                        <IconButton onClick={handleFeedbackAI}>
                                                            <AutoFixHighIcon />
                                                        </IconButton>
                                                    }
                                                </Tooltip>
                                                <Tooltip title="Enviar feedback">
                                                    <IconButton onClick={() => handleSendComment(false)}>
                                                        <SendIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        }
                        <Dialog
                            open={feedbackIA !== ""}
                            onClose={handleClose}
                            maxWidth="md"
                            fullWidth
                        >
                            <DialogTitle>
                                {"Feedback"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText style={{ padding: '1em', color: 'black' }}>
                                    <CkEditor
                                        value={feedbackIA}
                                        setValue={setFeedbackIA}
                                        titulo={""}
                                    />
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>
                                    Cancelar
                                </Button>
                                <Button variant="contained" onClick={() => handleSendComment(true)} autoFocus>
                                    Enviar feedback
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Evaluaciones