import React, { useEffect } from "react";
import { Handle, Position } from "react-flow-renderer";
import TemporaryDrawer from "../general-components/temporary-drawer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Divider, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CalculateIcon from "@mui/icons-material/Calculate";
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';

let customNodeStyles = {
  background: "#fff",
  color: "#000",
  padding: 2,
  border: `solid 1px black`,
  borderRadius: 5,
  width: 210,
  height: 120,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
};

const CustomIndicadorNode = ({ data, selected }) => {
  const navigate = useNavigate();
  

  function navigateToActivity() {
    navigate(`/home/procesos/${data.indicador.nodoId}/indicadores?indicadorId=${data.indicador.id}`);
  }

  function getIcon() {
    if (data.indicador.modoIntegracionId === 1) {
        return <Tooltip title="Indicador Calculado"><PersonIcon style={{ fontSize: "1.5rem", float: "left" }} /></Tooltip>
    } else if (data.indicador.modoIntegracionId === 2) {
        return <Tooltip title="Indicador Calculado"><GroupsIcon style={{ fontSize: "1.5rem", float: "left" }} /></Tooltip>
    } else if (data.indicador.modoIntegracionId === 3) {
        return <Tooltip title="Indicador Calculado"><CalculateIcon style={{ fontSize: "1.5rem", float: "left" }} /></Tooltip>
    }
  }
  

  return (
    <div
      style={{
        ...customNodeStyles,
        borderStyle: selected ? "dashed" : "solid",
        overflow: "auto",
        resize: selected ? "none" : "both",
      }}
      onDoubleClick={navigateToActivity}
    >
      <Handle
        type="source"
        position={Position.Left}
        style={{ borderRadius: 50 }}
        id="inputSource"
      />
      <div style={{ width: "90%", height: "100%" }}>
        { getIcon() }
        <Typography
          variant="body2"
          gutterBottom
          component="div"
          style={{ fontWeight: "bold" }}
        >
          {data.indicador.id} - {data.indicador.indicador}
        </Typography>
        <Divider />
        <div style={{ fontSize: 10, color: "GrayText" }}>
          <div style={{ textAlign: "center" }}>
            <b>Actividad:</b> {data.indicador.nodo}
          </div>
        </div>
        {/* <Typography
          variant="body2"
          gutterBottom
          component="div"
          dangerouslySetInnerHTML={{
            __html: data.info,
          }}
        ></Typography> */}
      </div>
      <Handle
        type="target"
        position={Position.Right}
        style={{ borderRadius: 50 }}
        id="inputTarget"
      />
    </div>
  );
};

export { CustomIndicadorNode };
