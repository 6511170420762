import { HOST } from '../Utils/config'
import { deleteRequest, getRequest, postRequest, putRequest } from './requests'

async function getIndicadoresPorNodo(nodoId, incluirtodos = true) {
  return await getRequest(
    `${HOST}/api/v1/indicadores/nodo/${nodoId}?incluirtodos=${incluirtodos}`
  )
}

async function getIndicadoresDerivados() {
  return await getRequest(`${HOST}/api/v1/indicadores/derivados`)
}

async function getIndicadoresAsignados(periodos) {
  return await getRequest(
    `${HOST}/api/v1/indicadores/asignados?periodos=[${periodos.toString()}]`
  )
}

async function getIndicadores() {
  return await getRequest(`${HOST}/api/v1/indicadores`)
}

async function getIndicador(indicador, agrupado = false) {
  return await getRequest(`${HOST}/api/v1/indicadores/${indicador}?agrupado=${agrupado}`)
}

async function getMapaDeIndicadores(incluirTodos) {
  return await getRequest(
    `${HOST}/api/v1/indicadores/mapa?incluirtodos=${incluirTodos}`
  )
}

async function copiarIndicador(settings) {
  return await postRequest(`${HOST}/api/v1/indicadores/copiar`, settings)
}

async function moverIndicador(settings) {
  return await postRequest(`${HOST}/api/v1/indicadores/mover`, settings)
}

async function postIndicador(indicador) {
  return await postRequest(`${HOST}/api/v1/indicadores`, indicador)
}

async function putIndicador(indicador) {
  return await putRequest(`${HOST}/api/v1/indicadores`, indicador)
}

async function putConfMCU(data) {
  return await putRequest(`${HOST}/api/v1/indicadores/updateConfMCU`, data)
}

async function deleteIndicador(indicadorId) {
  return await deleteRequest(`${HOST}/api/v1/indicadores/${indicadorId}`)
}

async function evaluacionesPorPeriodo(periodoId) {
  return await getRequest(
    `${HOST}/api/v1/indicadores/evaluacionesPorPeriodo/${periodoId}`
  )
}

async function evaluacionEquivalente(periodoId, indicadorId) {
  return await getRequest(
    `${HOST}/api/v1/indicadores/evaluacionequivalente?periodoId=${periodoId}&indicadorId=${indicadorId}`
  )
}

async function getDiferenciasIndicador(id, periodoId, esSeries=false) {
  return await getRequest(
    `${HOST}/api/v1/indicadores/${id}/diferencias?periodoId=${periodoId}&esSeries=${esSeries}`
  )
}

export {
  getIndicadores,
  getIndicadoresDerivados,
  getIndicadoresAsignados,
  getIndicadoresPorNodo,
  getMapaDeIndicadores,
  copiarIndicador,
  moverIndicador,
  postIndicador,
  putIndicador,
  getIndicador,
  deleteIndicador,
  evaluacionesPorPeriodo,
  evaluacionEquivalente,
  getDiferenciasIndicador,
  putConfMCU,
}
