import React, { useEffect } from "react";
import DataGrid, {
  Column,
  Grouping,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  Pager,
  Paging,
  ColumnChooser,
  GroupPanel,
  SearchPanel,
  Sorting,
  StateStoring,
  Editing,
  KeyboardNavigation,
} from "devextreme-react/data-grid";
import { Box, IconButton, TextField, Tooltip, Skeleton } from "@mui/material";
import moment from "moment";
import "moment/locale/es";
import { toast } from "react-toastify";
import { putResultado } from "../../Api/resultado.api";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from '@mui/icons-material/Delete';
import { ArchivosMain } from "../../Archivos/archivosMain";
import LaunchIcon from "@mui/icons-material/Launch";
import { useNavigate } from "react-router-dom";
import { TIPOS_RACI } from "../../Utils/enums";
import { ConditionalRendering } from "../../general-components/conditionalRendering";
import { eliminaResultado } from "../../Api/resultado.api"
import Confirm from "../../general-components/confirm";

moment.locale("es");
const ENTER = 13;
let previousValue = null;

function DashboardTable({
  resultados,
  allGroupsExpanded = false,
  tipoRaciId,
  updateResultados,
  extendedTable = true,
  storageKey = "resultadosStorage"
}) {
  const [resultadoSeleccionado, setResultadoSeleccionado] = React.useState(-1);
  const [filesModalOpen, setFilesModalOpen] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [rowIdDelete, setRowIdDelete] = React.useState(null);
  const navigate = useNavigate();

  function attachFiles(id) {
    setResultadoSeleccionado(id);
    setFilesModalOpen(true);
  }

  const saveColumn = (rowInfo) => {
    return (
      <section style={{ display: "flex" }}>
        <Tooltip title={`Adjuntos (${rowInfo.data.adjuntos})`}>
          <IconButton
            aria-label="delete"
            color={rowInfo.data.adjuntos > 0 ? "primary" : "default"}
            onClick={() => attachFiles(rowInfo.data.id)}
            size="small"
          >
            <AttachFileIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {/* <ConditionalRendering
          condition={
            tipoRaciId === TIPOS_RACI.RESPONSIBLE ||
            tipoRaciId === TIPOS_RACI.DIGITADOR
          }
        >
          <Tooltip title="Guardar">
            <IconButton
              aria-label="Guardar"
              color="warning"
              disabled={rowInfo.data.modoIntegracionId === 3}
              onClick={() => saveResultado(rowInfo.data.id)}
            >
              <SaveIcon />
            </IconButton>
          </Tooltip>
        </ConditionalRendering> */}
        <Tooltip title="Eliminar">
          <IconButton
            aria-label="delete"
            color="warning"
            onClick={() => { setOpenConfirm(true); setRowIdDelete(rowInfo.data.id) }}
            size="small"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </section>
    );
  };

  const handleEliminarResultado = async() => {
    console.log('eliminandoooooooooooooooooo')
    await eliminaResultado(rowIdDelete);
    console.log('eliminado')
    await updateResultados();
    setOpenConfirm(false);
    setRowIdDelete(null);
  }

  const actividadColumn = (rowInfo) => {
    return (
      <div
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => navigate(`/home/procesos/${rowInfo.data.nodoId}`)}
      >
        <p style={{ marginRight: "10px" }}>{rowInfo.data.nodo}</p>
        <LaunchIcon fontSize="small" />
      </div>
    );
  };

  const indicadorColumn = (rowInfo) => {
    return (
      <div
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() =>
          navigate(`/home/procesos/${rowInfo.data.nodoId}/indicadores`)
        }
      >
        <p style={{ marginRight: "10px" }}>{rowInfo.data.indicador}</p>
        <LaunchIcon fontSize="small" />
      </div>
    );
  };

  const onSaved = (e) => {
    const itemChanged = e.changes[0].data;
    if (itemChanged && itemChanged?.id) {
      saveResultado(itemChanged.id, itemChanged.valorNumerico);
    }
  }

  const saveResultado = (id, valor) => {
    if (isNaN(valor)) {
      return toast.error("Por favor ingresa un valor numérico");
    }
    putResultado({ id, valorNumerico: parseFloat(valor) }).then(
      (response) => {
        if (response.status === 200) {
          toast.success("Resultado Modificado correctamente");
        }
      }
    );
  };

  return (
    <div style={{ margin: "0 40px 40px 40px" }}>
      <DataGrid
        keyExpr="id"
        dataSource={resultados || []}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnResizingMode="nextColumn"
        onSaved={onSaved}
      >
        <Editing
          mode="cell"
          selectTextOnEditStart={true}
          allowUpdating={true}
        />
        <KeyboardNavigation
          editOnKeyPress={true}
          enterKeyDirection={"column"}
        />
        <Grouping
          autoExpandAll={allGroupsExpanded}
        />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey={storageKey}
        />
        <Sorting mode="multiple" />
        <ColumnChooser
          enabled={true}
          mode="dragAndDrop"
        />
        <GroupPanel visible={true} />

        <Column dataField="id" caption="id" dataType="number" visible={false} />
        <Column
          dataField="adjuntos"
          caption="adjuntos"
          dataType="number"
          visible={false}
        />
        <Column
          dataField="nodo"
          caption="Actividad"
          dataType="string"
          cellRender={actividadColumn}
          allowSorting={true}
          visible={false}
        />
        <Column
          dataField="padre"
          caption="Indicador Padre"
          dataType="string"
          allowSorting={true}
          groupIndex={0}
          allowEditing={false}
        />
        <Column
          dataField="periodo"
          caption="Periodo"
          dataType="string"
          allowSorting={true}
          allowEditing={false}
        />
        <Column
          dataField="indicador"
          caption="Indicador"
          dataType="string"
          cellRender={indicadorColumn}
          defaultSortOrder={'desc'}
          defaultSortIndex={2}
          sortOrder={'desc'}
          sortIndex={2}
          allowSorting={true}
          visible={extendedTable}
          allowEditing={false}
        />
        <Column
          dataField="fecha_inicio"
          caption="Fecha Inicio"
          dataType="datetime"
          cellRender={(rowInfo) =>
            moment(rowInfo.data.fecha_inicio).utc().format("D MMMM YYYY, h:mm:ss a")
          }
          defaultSortOrder={'asc'}
          defaultSortIndex={1}
          sortOrder={'asc'}
          sortIndex={1}
          allowSorting={true}
          allowEditing={false}
        />
        <Column
          dataField="fecha_fin"
          caption="Fecha Fin"
          dataType="datetime"
          cellRender={(rowInfo) =>
            moment(rowInfo.data.fecha_fin).utc().format("D MMMM YYYY, h:mm:ss a")
          }
          allowSorting={true}
          allowEditing={false}
        />
        <Column
          dataField="modoIntegracionId"
          caption="Modo de Integración"
          dataType="number"
          cellRender={(rowInfo) =>
            rowInfo.data.modoIntegracionId === 1
              ? "Directo"
              : rowInfo.data.modoIntegracionId === 2
                ? "Derivado"
                : "Calculado"
          }
          visible={false}
          allowSorting={true}
        />
        <Column
          dataField="asignados"
          caption="Usuario Evaluado"
          dataType="string"
          allowSorting={true}
          visible={tipoRaciId === TIPOS_RACI.DIGITADOR}
          allowEditing={false}
        />
        <Column
          dataField="meta"
          allowFiltering={false}
          allowSorting={false}
          caption="Meta"
          dataType="number"
          // cellRender={inputReadOnlyColumn}
          width={'auto'}
          allowEditing={false}
        />
        <Column
          caption="Resultado"
          dataField="valorNumerico"
          allowFiltering={false}
          allowSorting={false}
          width={100}
          allowEditing={tipoRaciId === TIPOS_RACI.RESPONSIBLE || tipoRaciId === TIPOS_RACI.DIGITADOR}
        />
        <Column
          caption="Opciones"
          allowFiltering={false}
          allowSorting={false}
          cellRender={saveColumn}
          width={120}
          allowEditing={false}
        />
        <FilterRow visible={true} />
        <FilterPanel visible={false} />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <Paging defaultPageSize={50} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[50, 100, 150, 200]}
          showInfo={true}
        />
      </DataGrid>
      <ArchivosMain
        open={filesModalOpen}
        setOpen={setFilesModalOpen}
        tipoRaciId={tipoRaciId}
        rowId={resultadoSeleccionado}
        updateResultados={updateResultados}
      />

      <Confirm
        open={openConfirm}
        title={"Eliminar"}
        text={"¿Confirma que desea eliminar este elemento?"}
        onConfirm={handleEliminarResultado}
        handleCloseProp={() => setOpenConfirm(false)}
      />

    </div>
  );
}

export { DashboardTable };
