import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { NodoContext } from "../Context/nodoContext";
import { useNavigate, useParams } from "react-router-dom";
import { getMapList,createValueMap,removeValueMap,getMapasCompartidosConmigo,getUsuariosMap,agregarUsuario,removerUsuario,updatePublico} from "../Api/valueMap.api";
import Confirm from "../general-components/confirm";
import { MapList } from "./mapList";
import { Fab, IconButton, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import Modal from "../general-components/modal"
import {ValueMapForm} from "./valueMapForm"
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';


export function ValueMapsMain() {
  const [valueMapId, setValueMapId] = React.useState(null);
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext);
  const [deleteConf, setDeleteConf] = React.useState(false);
  const [maps, setMaps] = React.useState([]);
  const [mapsCompartidosConmigo, setMapsCompartidosConmigo] = React.useState([]);
  const [tipoVisualizacion,setTipoVisualizacion] = React.useState('mios');
  const [openNodoModal, setOpenNodoModal] =  React.useState(false);
  

  useEffect(() => {   
      updateLista();   
  }, []);

  async function updateLista() {
    setLoading(true);
    setLoadingMessage("Actualizando mapas...");
    getMapList().then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setMaps(response.body);
        console.log({maps: response.body})
      } else {
        toast.error("Ocurrió un error al actualizar los mapas");
      }
    });

    const req = await getMapasCompartidosConmigo();
    if(req.status===200){
      setMapsCompartidosConmigo(req.body);
    }
  }

  function remove() {
    if (valueMapId !== null) {
      setLoading(true);
      setLoadingMessage("Eliminando mapa...");
      const obj = {valueMapId}
      removeValueMap(obj).then((response) => {
        setLoading(false);
        if (response.status === 200) {
          toast.success("Mapa eliminado correctamente");
          setDeleteConf(false);
          setValueMapId(null);
          updateLista();
        } else {
          toast.error("Error al eliminar el mapa");
        }
      });
    }
  }

  const handleOpenNodoModal = ()=>{
    setOpenNodoModal(true);
  }

  const handleCloseNodoModal = ()=>{
    setOpenNodoModal(false);
  }

  const handleVisualizacion = async(e,tipo)=>{
    console.log("tipo", tipo)
    setTipoVisualizacion(tipo);
  }

  return (
    <div>
       <div>
      <ButtonGroup variant="contained" aria-label="outlined primary button group">
        <Button color={tipoVisualizacion==="mios"?"success":"primary"} onClick={(e)=>handleVisualizacion(e,'mios')}>Mis mapas</Button>
        <Button color={tipoVisualizacion==="mios"?"primary":"success"} onClick={(e)=>handleVisualizacion(e,'compartidos')}>Compartidos conmigo</Button>
      </ButtonGroup>
      </div>
      
      <section style={{ marginLeft: '10px', marginRight: '10px', marginTop:"10px" }}>
         <div><h2>{tipoVisualizacion==="mios"?"Mis mapas":'Compartidos conmigo'}</h2></div>
      </section>

      <MapList
        lista = {tipoVisualizacion === 'mios'?maps:mapsCompartidosConmigo}
        setId = {setValueMapId}
        setDeleteConf = {setDeleteConf}  
        showButtons = {tipoVisualizacion==='mios'?true:false}     
      />

      <Fab
                color="primary"
                aria-label="add"
                sx={{
                  position: 'fixed',
                  right: 10,
                  bottom: 10,
                  backgroundColor: '#aebd36',
                  zIndex:1000,
                }}
                onClick={handleOpenNodoModal}
              >
                <AddIcon />
      </Fab>

      <Modal open={openNodoModal} handleClose={handleCloseNodoModal} title="Nuevo mapa">
                <ValueMapForm valueMapId={valueMapId} recargarListaMapas = {updateLista} handleClose={handleCloseNodoModal}/>
      </Modal>

      


      <Confirm
        open={deleteConf}
        title={"Eliminar"}
        text={"¿Confirma que desea eliminar este mapa?"}
        onConfirm={remove}
        handleCloseProp={() => setDeleteConf(false)}
      />
    </div>
  );
}
