import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DEFAULT_DELAY } from '../Utils/config'
import { Grid, IconButton, Tooltip, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { getIndicadoresAsignadosConPesoYObjetivo } from '../Api/evaluacionDesempeno.api'
import { toast } from 'react-toastify'
import { ObjetivoDrop } from './ObjetivoDrop'
import { useObjetivosDesempenoPonderados } from '../Hooks/useObjetivosDesempenoPonderados'
import ProgressBar from './progressBar'
import cssStyles from './desempeno.module.css'

export function GestionEvaluacion() {
  const { usuarioId } = useParams()
  const { objetivosDesempeno } = useObjetivosDesempenoPonderados(usuarioId)
  const [indicadores, setIndicadores] = React.useState([])
  const [usuario, setUsuario] = React.useState({})
  const navigate = useNavigate()

  useEffect(() => {
    updateIndicadoresAsignados()
  }, [])

  const aproximarPeso = (peso) => {
    return Number(parseFloat(peso).toFixed(4));
  }

  async function updateIndicadoresAsignados() {
    const response = await getIndicadoresAsignadosConPesoYObjetivo(usuarioId)
    if (response.status === 200) {
      setUsuario(response.body.usuario)
      setIndicadores(response.body.indicadores)
    } else {
      toast.error('Error al obtener los indicadores asignados')
    }
  }

  async function handleIndicadorDrop(indicadorId, objetivoId, newItem) {
    const indicadoresCopy = [...indicadores]
    const indicador = indicadoresCopy.find(
      (indicador) => indicador.indicadorId === indicadorId
    )
    indicador.objetivoId = objetivoId
    indicador.id = newItem.id
    indicador.peso = parseFloat(newItem.peso)
    setIndicadores(indicadoresCopy)
  }

  async function updateUI(indicadorId, peso) {
    const indicadoresCopy = [...indicadores]
    const indicador = indicadoresCopy.find(
      (indicador) => indicador.indicadorId === indicadorId
    )
    if (!indicador) return toast.error('No se encontró el indicador')
    indicador.peso = parseFloat(peso)
    setIndicadores(indicadoresCopy)
  }

  const pesoAsignado = indicadores.reduce((acc, indicador) => {
    return acc + (indicador.objetivoId ? parseFloat(indicador.peso) : 0 ) ;
  }, 0);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <div className={cssStyles.titleContainer}>
          <div className={cssStyles.titleHeader}>
            <Tooltip
              title="Regresar al resumen de indicadores"
              enterDelay={DEFAULT_DELAY}
            >
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon color="primary" />
              </IconButton>
            </Tooltip>
            <div className={cssStyles.titleBar}>
              <Typography  variant='h4'>Indicadores Asignados:</Typography>
              <Typography className={cssStyles.guionText} variant='h4'>&nbsp;</Typography>
              <Typography  variant='h4'>{usuario?.nombreCompleto}</Typography>
            </div>
            <div/>
          </div>
          {((usuario?.nombreCompleto)) && (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center'}}>
              <ProgressBar avance={aproximarPeso(pesoAsignado)} minimo={100} />
            </div>
          )}
        </div>
      </div>
      <div>
        {objetivosDesempeno.map((objetivo) => {
          return (
            <ObjetivoDrop
              key={objetivo.id}
              id={objetivo.id}
              nombre={objetivo.nombre}
              pesoMinimo={objetivo.pesoMinimo ? objetivo.pesoMinimo : 0}
              indicadores={indicadores}
              handleIndicadorDrop={handleIndicadorDrop}
              usuarioId={usuarioId}
              updateUI={updateUI}
              pesoTotal={parseFloat(pesoAsignado)}
              showPeso
            />
          )
        })}
        <ObjetivoDrop
          id={null}
          nombre="No ponderados"
          indicadores={indicadores}
          handleIndicadorDrop={handleIndicadorDrop}
          usuarioId={usuarioId}
        />
      </div>
    </div>
  )
}
