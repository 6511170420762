import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IconButton, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { PeriodoContext } from "../Context/periodoContext";
import { DashboardTable } from "../Home/Dashboard/dashboardTable";
import { NodoContext } from "../Context/nodoContext";
import { getResultadosPorIndicador } from "../Api/resultado.api";
import { DEFAULT_DELAY } from "../Utils/config";
import AddIcon from "@mui/icons-material/Add";
import Modal from "../general-components/modal";
import { ResultadoForm } from "./resultadoForm";
import { Fab } from "@mui/material";
import { useAuth } from "../Hooks/useAuth";

function ResultadosIndividualesMain() {
  const [resultados, setResultados] = React.useState([]);
  const [indicadorId, setIndicadorId] = React.useState(null);
  const [nodoId, setNodoId] = React.useState(null);
  const [nombreIndicador, setNombreIndicador] = React.useState("");
  const [nombreActividad, setNombreActividad] = React.useState("");
  const [raciId, setRaciId] = React.useState(null);
  const { fireReload } = React.useContext(PeriodoContext);
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext);
  const [searchParams] = useSearchParams();
  const [openModal, setOpenModal] = React.useState(false);
  const navigate = useNavigate();
  const { getUser } = useAuth();

  useEffect(() => {
    searchParams.get("indicadorId") &&
      setIndicadorId(parseInt(searchParams.get("indicadorId")));
    searchParams.get("raciId") &&
      setRaciId(parseInt(searchParams.get("raciId")));
  }, []);

  useEffect(() => {
    if (raciId && indicadorId) {
      updateResultados();
    }
  }, [raciId, fireReload, indicadorId]);

  function updateResultados() {
    setLoading(true);
    setLoadingMessage("Cargando..");
    getResultadosPorIndicador(raciId, indicadorId).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setResultados(response.body.resultados);
        setNombreIndicador(response.body.indicador);
        setNombreActividad(response.body.nodo);
        setNodoId(response.body.nodoId);        
      } else {
        toast.error("No se pudieron recuperar los resultados");
      }
    });
  }

  const handleClose = () => {
    setOpenModal(false);
  };

  function handleNodoClick() {
    navigate(`/home/procesos/${nodoId}`);
  }

  function handleIndicadorClick() {
    navigate(`/home/procesos/${nodoId}/indicadores`);
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <Tooltip title="Regresar" sx={{ marginRight: "auto" }}>
          <IconButton
            onClick={() =>
              navigate(-1)
            }
          >
            <ArrowBackIcon color="primary" />
          </IconButton>
        </Tooltip>
      </div>
      <section
        style={{
          fontSize: "30px",
          textAlign: "left",
          margin: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <b>Mediciones: </b>{" "}
        <Tooltip title="Ir a la actividad" enterDelay={DEFAULT_DELAY}>
        <p style={tagProperties} onClick={handleNodoClick}>
          {nombreActividad}{" "}
        </p>
        </Tooltip>
        <p style={tagProperties}>/</p>
        <Tooltip title="Ir al indicador" enterDelay={DEFAULT_DELAY}>
        <p style={tagProperties} onClick={handleIndicadorClick}>        
          {nombreIndicador}
        </p>
        </Tooltip>
      </section>
      <section>
        <DashboardTable
          resultados={resultados}
          tipoRaciId={raciId}
          updateResultados={updateResultados}
          extendedTable={false}
          storageKey={'resultadosIndividualStorage'}
          allGroupsExpanded
        />
      </section>
      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          right: 10,
          bottom: 10,
          backgroundColor: "#aebd36",
        }}
        onClick={() =>  {setOpenModal(true)}}
      >
        <AddIcon />
      </Fab>

      <Modal title="Nuevo resultado" open={openModal}  handleClose={handleClose}>
          <ResultadoForm handleClose={handleClose} indicadorId = {indicadorId} usuarioId= {getUser} 
          updateResultados={updateResultados}/>
      </Modal>
    </>
  );
}

const tagProperties = {
  marginLeft: "5px",
  fontSize: "25px",
  cursor: "pointer",
};

export { ResultadosIndividualesMain };
