import React, { useState, useEffect, Fragment } from "react";
import ListDelete from "../general-components/list-delete";
import {
  getInputOutputSipoc,
  createInputOutputSipoc,
  removeInputOutputSipoc,
} from "../Utils/inputOutputSipoc";
import { useAuth } from "../Hooks/useAuth";
import { toast } from "react-toastify";
import { ConfirmationNumber } from "@mui/icons-material";
import { NodoContext } from "../Context/nodoContext";
import { Button } from "@mui/material";

export default function InputOutputCrud({ nodoSipocId }) {
  const [items, setItems] = useState([]);
  const { nodoActual, reloadNodoActual } = React.useContext(NodoContext);
  const { getUser } = useAuth();  

  useEffect(() => {
    getItems();
  }, [nodoSipocId]);

  const getItems = async () => {
    try {
      if (nodoSipocId < 1) {
        return;
      }

      const serverItems = await getInputOutputSipoc(nodoSipocId);
      if (Array.isArray(serverItems)) {
        setItems(serverItems);
      }
    } catch (error) {
      console.error("err", error);
    }
  };

  const add = async (newItem) => {
    //Add to server code
    try {      
      let data = {
        nodoSipocId: nodoSipocId,
        texto: newItem,        
      };
      const res = await createInputOutputSipoc(data);
      if (res.status === 201) {
        getItems();
        reloadNodoActual(nodoActual.id);
        // Esta es la linea Arda
      } else {
        toast.error("Ocurrió un problema al intentar crear el nuevo elemento");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const remove = async (item) => {    
    let data = {
      id: item.id,      
    };
    const res = await removeInputOutputSipoc(data);
    if (res.status === 200) {
      getItems();
      reloadNodoActual(nodoActual.id);
      // Esta es la linea Arda
    } else {
      toast.error("Ocurrió un problema al intentar remover el elemento");
    }
  };

  function eliminarRelacionSipoc() {
    // Aqui va el codigo para eliminar la relacion sipoc completa
  }

  return (
    <div>
      <ListDelete fnAdd={add} fnDelete={remove} items={items} objectType={1} />
      {/* <Button color="error" variant="contained" onClick={eliminarRelacionSipoc} sx={{position: "absolute", bottom: 20, right: 120}}>
        Eliminar relación
      </Button> */}
    </div>
  );
}
