const CHART_MIN_WIDTH = 350

const TOTAL_LABELS = 30

const LIMITE_PUNTOS = 60

const LINE_CHART = 'line'
const BAR_CHART = 'bar'
const SCATTER_CHART = 'scatter'
const CANDLESTICK_CHART = 'candlestick'
const HISTOGRAM_CHART = 'histogram'
const DISTRIBUCION_CHART = 'distribucion'

const GRAFICAS_BASICAS = [LINE_CHART, BAR_CHART, SCATTER_CHART]

const GRAFICAS_AVANZADAS = [CANDLESTICK_CHART, HISTOGRAM_CHART, DISTRIBUCION_CHART]

const HORIZONTAL_GRAPH = [DISTRIBUCION_CHART, HISTOGRAM_CHART]

const GRAFICAS_AGRUPADAS = [BAR_CHART, LINE_CHART, SCATTER_CHART, CANDLESTICK_CHART]

const AGRUPADORES = [
    { label: 'Semana', value: 'agrupado_semana' },
    { label: 'Mes', value: 'agrupado_mes' },
    { label: 'Periodo', value: 'agrupado_periodo' },
    { label: 'Sin agrupar', value: null },
]

const TITULOS_GRAFICAS = {
    'candlestick': 'Gráfico de cajas',
    'distribucion': 'Gráfico de distribución',
    'histograma': 'Gráfico de histograma',
    'bar': 'Gráfico de barras',
    'line': 'Gráfico de líneas',
    'scatter': 'Gráfico de dispersión',
}

const OPERACIONES_AGG = [
    { label: 'Ninguna', value: 'None' },
    { label: 'Suma', value: 'sum' },
    { label: 'Promedio', value: 'mean' },
    { label: 'Mínimo', value: 'min' },
    { label: 'Máximo', value: 'max' },
    { label: 'Conteo', value: 'count' },
    { label: 'Desviación estándar', value: 'std' },
    { label: 'Varianza', value: 'var' },
    { label: 'Conteo distinto', value: 'nunique' },
]

const KEYS_INVALIDAS = ['fecha', 'serie', 'valorNumerico', 'tab', 'comentarios', 'indicador', 'etiquetaDiscreta']

// Paleta de colores
const PALETA_GRAFICAS = ['#9BCF53', '#0081A7', '#E72929', '#9BA2FF', '#2E294E', '#4CE0D2', '#0e1c36', '#D5573B', '#DE9151', '#453750']

const PALETA_METAS =    ['#ff8959', '#BE97C6', '#F5B841', '#0EB1D2', '#D56AA0', '#75b8c8', '#FFD6BA', '#3D3B30', '#FF8600', '#731DD8']

const formarPaleta = (periodos = [], agrupado = false) => {
    const paletaGraficas = []
    const paletaMetas = []
    if (agrupado) periodos = periodos.concat(periodos)
    periodos.forEach((periodo, index) => {
        paletaGraficas.push(PALETA_GRAFICAS[index])
        paletaMetas.push(PALETA_METAS[index])
    })
    // Invertirlas
    if (periodos.length >= 1) {
        paletaGraficas.reverse()
        paletaMetas.reverse()
    }
    const paleta = paletaGraficas.concat(paletaMetas)
    return paleta.length ? paleta : PALETA_GRAFICAS
}

export {
    CHART_MIN_WIDTH,
    TOTAL_LABELS,
    LIMITE_PUNTOS,
    GRAFICAS_BASICAS,
    GRAFICAS_AVANZADAS,
    HORIZONTAL_GRAPH,
    GRAFICAS_AGRUPADAS,
    AGRUPADORES,
    TITULOS_GRAFICAS,
    KEYS_INVALIDAS,
    PALETA_GRAFICAS,
    PALETA_METAS,
    OPERACIONES_AGG,
    formarPaleta
}