import { useEffect } from 'react'
import { getIndicador } from '../Api/indicadores.api'
import { useState } from 'react'

export function useIndicador({ indicadorId, agrupado }) {
  const [indicador, setIndicador] = useState({})

  console.log({ indicadorId, agrupado })
  const updateIndicador = async () => {
    if (!indicadorId) return
    const response = await getIndicador(indicadorId, agrupado)
    if (response.status === 200) {
      setIndicador(response.body)
    }
  }

  useEffect(() => {
    updateIndicador()
  }, [indicadorId])

  return { indicador, updateIndicador }
}
