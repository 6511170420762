import React from 'react';
import {
  LinearGauge, Scale, Label, SubvalueIndicator, Animation, ValueIndicator, Size, Tooltip
} from 'devextreme-react/linear-gauge';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const ERROR_COLOR = '#ed6c02';

export default function ProgressBar({ avance, minimo }) {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const customizeTooltip = (arg) => {
    let result = `${arg.valueText} %`;
    return { text: `Peso asignado: ${result}` };
  }

  const customizeText = ({ valueText }) => {
    return `${valueText} %`;
  }

  return (
    <LinearGauge
      id="c5"
      value={avance}
      subvalues={[avance]}
      theme='material.blue.light'
    >
      <Size
        height={100}
        width={matches ? 800 : 300}
      />
      <Scale
        startValue={0}
        endValue={100}
        tickInterval={100}
      >
        <Label customizeText={customizeText}></Label>
      </Scale>
      <Animation
        easing="easeOutCubic"
        duration={1000}
      />
      <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
      <ValueIndicator
        type="rangebar"
        color={avance == 100 ? undefined : ERROR_COLOR}
      ></ValueIndicator>
      <SubvalueIndicator
        type="textCloud"
        color={avance == 100 ? undefined : ERROR_COLOR}
        text={{ customizeText: customizeText, font: { size: 16 } }}
      ></SubvalueIndicator>
    </LinearGauge>
  )
}