import React, { useEffect } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  Pager,
  Paging,
  ColumnChooser,
  GroupPanel,
  SearchPanel,
} from "devextreme-react/data-grid";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import moment from "moment";
import "moment/locale/es";
import { toast } from "react-toastify";

import SaveIcon from "@mui/icons-material/Save";
import { putResultado } from "../Api/resultado.api";
import { CustomTooltip } from "../Custom/CustomTooltip";




const ENTER = 13;

export function AgrupadoTable({ tablaResultados, esParteRaci, updateResultados, listaColumnas }) {
  
  const saveResultado = (resultId, value) => {        
    console.log({resultId, value})    
    const valorResultado = value;    
    if(valorResultado === "") return;
    console.log(isNaN(value))
    if (isNaN(valorResultado) || valorResultado === null) {
      return toast.error("Por favor ingresa un valor numérico");
    }    
    
    putResultado({ id: resultId, valorNumerico: parseFloat(valorResultado) }).then(
      (response) => {
        if (response.status !== 200) {
          toast.error("Error al modificar el resultado");
        } else {          
          //updateResultados();
        }
      }
    );    
  };

  const updateValoresResultados = (valor, id) => {
    const resultado = tablaResultados.find( row => {
      return row.resultadoId === id
    }
    );
    resultado.valorNumerico = valor;
  };

  function handleKeyDown(keyCode, rowId) {
    if (keyCode === ENTER) {
      saveResultado(rowId);
    }
  }

  function handleOnBlur(rowId) {

    /*const valorResultado = valores[rowId];
    if (isNaN(valorResultado) || valorResultado === null) return;
    if( valorResultado === previousValue) return;
    previousValue = null;
    saveResultado(rowId);    */
  }

  function handleOnFocus(rowId) {
    /*const valorResultado = valores[rowId];
    previousValue = valorResultado;    */
  }

 

  const inputColumn = (rowInfo, nombre) => {    
    return (
      <TextField
        id="Valor Numérico"
        variant="standard"
        type="number"
        defaultValue={rowInfo.data[nombre]}
        style={{ width: "100%" }}
        disabled={rowInfo.data.resultadoId < 0}
        /*onChange={(e) =>
          updateValoresResultados(e.target.value, rowInfo.data.resultadoId)
        }*/
        //onKeyDown={(e) => handleKeyDown(e.keyCode, rowInfo.data.id)}
        onBlur={(e) => {
          saveResultado(rowInfo.data[`${nombre}_id`], e.target.value);
        }}
      />
    );
  };

  console.log("listaColumnas", listaColumnas);
  console.log("tablaResultados", tablaResultados);
  return (
    <div style={{ margin: "20px 40px 40px 40px" }}>
      <DataGrid
        dataSource={tablaResultados}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnResizingMode="nextColumn"
        // defaultColumns={columns}
        // rowRender={plantillaFila}
      >
        {/* <ColumnChooser enabled={true} /> */}
        <GroupPanel visible={true} />
        {/*<ColumnFixing enabled={true} />
      <Scrolling mode="virtual" /> */}

        <Column dataField="id" caption="id" dataType="number" visible={false} />
        
        <Column
          dataField="periodo"
          caption="Periodo"
          dataType="string"
          allowSorting={true}
        />
        <Column
          dataField="fecha"
          caption="Fecha"
          dataType="datetime"
          cellRender={(rowInfo) =>
            moment(rowInfo.data.fecha).format("D MMMM YYYY, h:mm:ss a")
          }
          allowSorting={true}
        />
        
        { !esParteRaci && 
          listaColumnas.map((columna) => {
            return (
              <Column
                key={columna.nombre}
                dataField={columna.nombre}
                caption={columna.nombre}
                visible={true}
              />
            );
          })
        }
        {esParteRaci && 
          listaColumnas.map((columna) => {
            return (
              <Column
                key={columna.nombre}
                dataField={columna.nombre}
                caption={columna.nombre}
                cellRender={(data) => inputColumn(data, columna.nombre)}
                visible={true}
              />
            );
          })
        }      
        {/* { esParteRaci && (<Column
          caption="Eficiencia Industrial Semanal"
          allowFiltering={false}
          allowSorting={false}
          cellRender={inputColumn}
          width={120}
          visible={true}
        />
        )}
        { esParteRaci && (<Column
          caption="Eficiencia Industrial Acumulada"
          allowFiltering={false}
          allowSorting={false}
          cellRender={inputColumn}
          width={120}
          visible={true}
        />
        )} */}        
        <FilterRow visible={true} />
        <FilterPanel visible={false} />
        {/* <FilterBuilderPopup position={filterBuilderPopupPosition} /> */}
        <HeaderFilter visible={true} />
        {/* <Scrolling mode="infinite" /> */}

        {/* <GroupPanel visible={true} /> */}
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}
        <Paging defaultPageSize={50} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[50, 100, 150, 200]}
          showInfo={true}
        />
      </DataGrid>
      

    </div>
  );
}


