import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { NodoContext } from "../../../Context/nodoContext";
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ACTIVIDAD = 3;

export default function MenuTresPuntitos(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { nodoActual } = React.useContext(NodoContext);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const options = [
    { name: "Administrar permisos", action: () => mostrarPermisos(), visible: true },
    { name: "Eliminar", action: () => eliminar(), visible: true  },
    { name: "Copiar actividad", action: () => copiar(), visible: nodoActual.tipoNodoId === ACTIVIDAD  },
    { name: "Editar metas", action: () => administrarMetas(), visible: true  },
    { name: "Editar líneas de referencia", action: () => administrarLineasRef(), visible: true  },
    { name: "Bitácora", action: () => bitacora(), visible: true  },    
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const mostrarPermisos = () => {
    props.mostrarPermisos();
    handleClose();
  };

  const eliminar = () => {
    props.eliminar();
    handleClose();
  };

  const copiar = () => {
    props.abrirModalCopia();
    handleClose();
  };


  const bitacora = () => {
    props.mostrarBitacora();
    handleClose();
  };

  const administrarMetas = () => {
    navigate(`/home/metas/${nodoActual.id}`);
    handleClose();
  };
  
  const administrarLineasRef = () => {
    navigate(`/home/lineasReferencia/${nodoActual.id}`);
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "26ch",
          },
        }}
      >
        {options.filter(option => option.visible).map((option) => (
          <MenuItem
            key={option.name}
            selected={option === "Pyxis"}
            onClick={option.action}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
