import React from 'react'
import Avatar from '@mui/material/Avatar'
import { Paper, Tooltip } from '@mui/material';
import {
  CircularGauge,
  Scale,
  Label,
  RangeContainer,
  Range,
  Export,
  Title,
  Font
} from 'devextreme-react/circular-gauge'

export function ProgresoPersona({ persona }) {
  function stringToColor(string) {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.slice(-2)
    }
    /* eslint-enable no-bitwise */

    return color
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name)
      },
      children: `${name.split(' ')[0][0]}${
        name.split(' ')[2][0] == null
          ? name.split(' ')[1][0]
          : name.split(' ')[2][0]
      }`
    }
  }

  const customizeText = ({ valueText }) => {
    return `${valueText} %`
  }

  return (
    <Paper
      style={{
        width: 200,
        height: 200,
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        margin: '5px'
      }}
      elevation={5}
    >
      <Tooltip title={persona.nombreCompleto}>
        <Avatar {...stringAvatar(persona.nombreCompleto)} />
      </Tooltip>
      <div style={{ fontSize: '12px' }}>
        <strong>
          {persona.nombreCompleto.length > 35
            ? persona.nombreCompleto.substring(0, 35) + '...'
            : persona.nombreCompleto}
        </strong>
      </div>
      
        <Paper sx={{ padding: '10px 40px' }} elevation={3}>
        <section>
          <b
            style={{ fontSize: 16 }}
          >{persona.porcentajeAvance>0?persona.porcentajeAvance + '%':'ND'}</b>
          {/* <p
            style={{
              color: 'white',
              fontSize: 16,
              backgroundColor: '#202E3C',
              borderRadius: '10px',
              padding: '5px 10px'
            }}
          >
            {`${persona.evaluados} de ${
              persona.evaluados + persona.pendientes
            } `}
          </p> */}
        </section>
      </Paper>
      
      
      {/* <Paper style={{ width: '50%' }}>
        <CircularGauge id="gauge" value={persona.porcentajeAvance} key={'ANKS' + persona.id} style={{width:'100%', height:300}}>
          <Scale startValue={0} endValue={100} tickInterval={10}>
            <Label customizeText={customizeText} />
          </Scale>
          <RangeContainer>
            <Range startValue={0} endValue={20} color="#CE2029" />
            <Range startValue={20} endValue={50} color="#FFD700" />
            <Range startValue={50} endValue={100} color="#228B22" />
          </RangeContainer>
          <Export enabled={true} />
          <Title text={persona.porcentajeAvance?`${persona.porcentajeAvance}% completado`:""}>
            <Font size={8} />
          </Title>
        </CircularGauge>
      </Paper> */}
    </Paper>
  )
}
