import { HOST } from './config'
import { post, put, delBody } from './api'
import { postRequest, deleteRequest, getRequest } from '../Api/requests'

async function getList(tagFamilyId) {
  const response = await fetch(`${HOST}/api/v1/tag/${tagFamilyId}`)
  if (response.ok) {
    const jsonResponse = await response.json()
    if (jsonResponse.body) {
      return jsonResponse.body
    }
    return []
  }
  return []
}

async function getNodeTags(nodoId) {
  const response = await getRequest(`${HOST}/api/v1/tagNode/${nodoId}`)
  return response
}

async function addNodeTag(data) {
  // const response = await post(`${HOST}/api/v1/etiquetaNodo`, data);
  const response = await postRequest(`${HOST}/api/v1/tagNode`, data)
  return response
}

async function removeTag(id) {
  // const response = await delBody(`${HOST}/api/v1/etiquetaNodo`, data);
  const response = await deleteRequest(`${HOST}/api/v1/tagNode/${id}`)
  return response
}

export { getList, getNodeTags, addNodeTag, removeTag }
