import React from 'react'
import { Tree } from './tree'
import FolderIcon from '@mui/icons-material/Folder'
import DatasetIcon from '@mui/icons-material/Dataset';
import { TIPOS_NODO_DS } from '../Utils/enums';
import { getDatasets } from '../Api/vistaDatasets';
import { NodoContext } from '../Context/nodoContext';

export default function DatasetsTree({
  defaultSelectedKey,
  onNodeClick = () => {},
  onDoubleNodeClick = () => {},
  fontColor = 'white',
}) {
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext)
  const [estructuraArbol, setEstructuraArbol] = React.useState([])

  React.useEffect(() => {
    obtenerEstructuraArbol()
  }, [])

  const obtenerEstructuraArbol = async () => {
    try {
      setLoading(true)
      setLoadingMessage('Obteniendo conjuntos de datos...')
      const response = await getDatasets()
      console.log('response ', response)
      setEstructuraArbol(response.body)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Tree
      fontColor={fontColor}
      level={0}
      iconsHash={{
        [TIPOS_NODO_DS.FOLDER]: <FolderIcon />,
        [TIPOS_NODO_DS.DATASET]: <DatasetIcon />
      }}
      onNodeClick={onNodeClick}
      onDoubleClick={onDoubleNodeClick}
      estructuraArbol={estructuraArbol}
      defaultSelectedKey={defaultSelectedKey}
    />
  )
}