import React from "react";
import { NodoContext } from "../Context/nodoContext";

const nodeTypes = {
  customInput: "customInput",
  customOutput: "customOutput",
  customProcess: "customProcess",
};
let verticalSeparation = 20;
let totalHeight = 0;
let displayInputsOutputs = false;
let displayUltimosResultados = false;

function createInitialNodes(
  nodo,
  suppliers,
  customers,
  expandedSipoc,
  expandedResultados
) {
  totalHeight = 0;
  let initialItems = [];
  displayInputsOutputs = expandedSipoc || false;
  displayUltimosResultados = expandedResultados || false;
  if (suppliers.length === 0 && customers.length === 0) {
    initialItems.push(
      getNodeStructure(550, 110, nodo, nodeTypes.customProcess)
    );
  } else {
    const initialRelations = [];
    //Suppliers
    addSuppliers(initialItems, initialRelations, suppliers);
    //Customers
    addCustomers(initialItems, initialRelations, customers);
    initialItems.push.apply(initialItems, initialRelations);
    //Proceso
    initialItems.push(
      getNodeStructure(550, getYLocation(), nodo, nodeTypes.customProcess, "P")
    );
  }
  return initialItems;
}

function getYLocation() {  
  return totalHeight / 2 - verticalSeparation;
}

function addSuppliers(initialItems, initialRelations, suppliers) {
  let initialY = 50;
  suppliers.forEach((nodo) => {
    initialItems.push(
      getNodeStructure(200, initialY, nodo, nodeTypes.customOutput, "S")
    );
    initialRelations.push(
      getNodeLink(`S${nodo.id}`, `P${nodo.nodoRelacionadoId}`)
    );
    initialY += verticalSeparation + getHeight(nodo);
  });
  if (initialY > totalHeight) {
    totalHeight = initialY;
  }
}

function addCustomers(initialItems, initialRelations, customers) {
  let initialY = 50;
  customers.forEach((nodo) => {
    initialItems.push(
      getNodeStructure(900, initialY, nodo, nodeTypes.customInput, "C")
    );
    initialRelations.push(
      getNodeLink(`P${nodo.nodoRelacionadoId}`, `C${nodo.id}`)
    );
    initialY += verticalSeparation + getHeight(nodo);
  });
  if (initialY > totalHeight) {
    totalHeight = initialY;
  }
}

function getNodeStructure(x, y, nodo, type, nodeType) {  
  return {
    id: `${nodeType}${nodo.id}`,
    type,
    data: {
      nodo,
      isExpanded: displayInputsOutputs,
      displayResultados: displayUltimosResultados,
    },
    position: { x, y },
  };
}

function getNodeLink(sourdeId, targetId) {
  return {
    id: `e${sourdeId}-${targetId}`,
    source: `${sourdeId}`,
    type: "smoothstep",
    target: `${targetId}`,
    animated: true,
  };
}

function getHeight(nodo) {
  let expandedHeight = 0;
  if (displayInputsOutputs) {
    nodo.info.split("<br />").forEach((element) => {
      expandedHeight += (element.length / 20) * 30;
    });
  } else if (displayUltimosResultados) {
    expandedHeight += nodo.indicadores? nodo.indicadores.length * 50 : 0;
  }
  return (nodo.nombre.length / 20) * 25 + 40 + expandedHeight;
}

export { createInitialNodes };
