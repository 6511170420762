import { HOST } from "../Utils/config";
import { getRequest, postRequest } from "./requests";

async function getPeriodosSeleccionados() {
  return await getRequest(`${HOST}/api/v1/periodousuario`);
}

async function updatePeriodosSeleccionados(periodos) {
  return await postRequest(`${HOST}/api/v1/periodousuario?periodos=[${periodos}]`);  
}
export {
    getPeriodosSeleccionados,
    updatePeriodosSeleccionados
};
