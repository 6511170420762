import React from "react";
import {
  IconButton,
  TextField,
  Tooltip,
  Typography,
  Grid,
  Button,
  MenuItem,
  Switch,
  Autocomplete
} from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import { getLineasReferencia, postLineasReferencia, putLineaReferencia, putLineasReferencia } from "../Api/resultado.api";
import { toast } from "react-toastify";
import { DataGrid } from "devextreme-react";
import Modal from '../general-components/modal'
import {
  Column,
  ColumnChooser,
  FilterPanel,
  FilterRow,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import moment from "moment";
import SaveIcon from "@mui/icons-material/Save";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import { LoadingButton } from '@mui/lab'
import { PeriodoContext } from "../Context/periodoContext";
import { MessageBar } from "../general-components/messageBar";
import { getIndicadoresPorNodo } from "../Api/indicadores.api";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { descomprimirPayload } from "../Utils/utilities";

const ENTER = 13;
let previousValue = null;

function LineasReferencia() {
  const { nodoId } = useParams();
  const navigate = useNavigate();
  const { fireReload, getUnicoPeriodoSeleccionado } = React.useContext(PeriodoContext);
  const [nodo, setNodo] = useState([]);
  const [indicadores, setIndicadores] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [verFechas, setVerFechas] = useState(false);
  const [indicadorId, setIndicadorId] = useState(null);
  const [evaluacionId, setEvaluacionId] = useState(null);
  const [linea, setLinea] = useState(null);
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFin, setFechaFin] = useState(null);
  const [valor, setValor] = useState(null);
  const [disableButtons, setDisableButtons] = useState(false);

  useEffect(() => {
    updateLineas();
    updateIndicadores();
  }, [nodoId]);

  useEffect(() => {
    updateLineas();
    updateIndicadores();
  }, [fireReload]);

  function updateIndicadores() {
    getIndicadoresPorNodo(nodoId, true).then((response) => {
      if (response.status === 200) {
        setIndicadores(response.body)
      }
    })
  }

  

  function updateLineas() {
    getLineasReferencia(nodoId).then((response) => {
      if (response.status === 200) {
        // Descomprimir 
        setNodo(descomprimirPayload(response.body));
      } else {
        toast.error("Error al recuperar los resultados");
      }
    });
  }

  function obtenerValoresIndicadores(array) {
    const valoresUnicos = new Set();
    array.forEach(item => {
      const combinacion = `${item['evaluacion']}-${item['evaluacionId']}`;
      valoresUnicos.add(combinacion);
    });
    return Array.from(valoresUnicos).map(combinacion => {
      const [indicador, id] = combinacion.split('-');
      return { ['Indicador']: indicador, ['evaluacionId']: parseInt(id, 10) };
    });
  }

  function obtenerValoresNombreLineas(array) {
    const valoresUnicos = new Set();
    array.forEach(item => {
      if (item.evaluacionId === evaluacionId) {
        const combinacion = item['nombre'];
        valoresUnicos.add(combinacion);
      }
    });
    return Array.from(valoresUnicos).map(item => {
      return { ['Linea']: item, ['id']: item };
    });
  }

  function onCloseModal() {
    setOpenModal(false);
    setEvaluacionId(null);
    setLinea(null);
    setValor(null);
    setFechaInicio(null);
    setFechaFin(null);
  }

  function onCloseModal2() {
    setOpenModal2(false);
    setIndicadorId(null);
  }

  function formatDate(date, isFin = false) {
    const fecha = new Date(date);
    if (isFin) {
      fecha.setDate(fecha.getDate() + 1);
      fecha.setSeconds(fecha.getSeconds() - 1);
    }
    return fecha.toISOString();
  }

  function actualizarLineas() {
    if (!evaluacionId || !linea || !valor) {
      return toast.error('Por favor ingrese todos los campos');
    }
    setDisableButtons(true);
    const body = {
      id: evaluacionId,
      linea,
      fechaInicio: fechaInicio ? formatDate(fechaInicio) : null,
      fechaFin: fechaFin ? formatDate(fechaFin, true) : null,
      valor
    }
    putLineasReferencia(body).then(response => {
      if (response.status === 200) {
        toast.success('Lineas de referencia actualizadas');
        updateLineas();
        onCloseModal();
      } else {
        toast.error('Error al actualizar las metas');
      }
      setDisableButtons(false);
    });
  }

  function regenerarLineas() {
    if (!indicadorId) {
      return toast.error('Por favor seleccione un indicador');
    }
    setDisableButtons(true);
    postLineasReferencia({ indicadorId }).then(response => {
      if (response.status === 200 || response.status === 201) {
        toast.success('Lineas de referencia regeneradas');
        updateLineas();
        onCloseModal2();
      } else {
        toast.error('Error al regenerar las metas');
      }
      setDisableButtons(false);
    });
  }

  const handleVerFechas = (event) => {
    setFechaInicio(null);
    setFechaFin(null);
    setVerFechas(event.target.checked);
  };

  if (getUnicoPeriodoSeleccionado() === null)
    return (
      <MessageBar
        display={true}
        type="error"
        message="Debe de seleccionar un único periodo para editar las líneas de referencia"
        title="Error"
      />
    )

  return (
    <div>
      <IconButton
        onClick={() => navigate(`/home/procesos/${nodoId}`)}
        style={{ float: "left", marginLeft: "1rem" }}
      >
        <ArrowBackIcon color="primary" />
      </IconButton>
      <Typography variant="h4" noWrap component="div">
        Editor de líneas de referencia
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'column', margin: "0 auto", width: "95%" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '1rem 0' }}>
          <Button
            variant="contained"
            endIcon={<DesignServicesIcon />}
            onClick={() => setOpenModal(true)}
          >
            Editar líneas de referencia masivas
          </Button>
          <Button
            variant="contained"
            endIcon={<RestartAltIcon />}
            onClick={() => setOpenModal2(true)}
            color="inherit"
          >
            Regenerar líneas de referencia
          </Button>
        </div>
        <LineasRefTable resultados={nodo} updateLineas={updateLineas} />
      </div>
      <Modal
        open={openModal2}
        handleClose={onCloseModal2}
        closeAfterTransition
        title="Regenear líneas de referencia para el indicador"
      >
        <Grid container spacing={3} sx={{ marginTop: '0px' }}>
          <Grid item xs={12}>
            <Autocomplete
              id="indicadorId"
              options={indicadores}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} label="Indicador" />}
              getOptionLabel={(option) => option.nombre}
              onChange={(e, value) => setIndicadorId(value.id)}
              value={indicadores.find(item => item.id === indicadorId) || null}
              required
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1rem', float: 'right' }}>
            <LoadingButton
              loading={disableButtons}
              disabled={disableButtons}
              onClick={regenerarLineas}
              color='primary'
              variant='contained'
            >
              <span>Guardar</span>
            </LoadingButton>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={openModal}
        handleClose={onCloseModal}
        closeAfterTransition
        title="Editar líneas de referencia para el indicador"
      >
        <Grid container spacing={3} sx={{ marginTop: '0px' }}>
          <Grid item xs={12}>
            <TextField
              id="evaluacionId"
              label="Indicador"
              variant="outlined"
              select
              value={evaluacionId}
              style={{ width: '100%' }}
              onChange={(e) => setEvaluacionId(e.target.value)}
              required
            >
              {obtenerValoresIndicadores(nodo || []).map((item) => (
                <MenuItem key={item.evaluacionId} value={item.evaluacionId}>
                  {item.Indicador}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="linea"
              label="Línea de referencia"
              variant="outlined"
              select
              value={linea}
              style={{ width: '100%' }}
              onChange={(e) => setLinea(e.target.value)}
              required
            >
              {obtenerValoresNombreLineas(nodo || []).map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.Linea}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch checked={verFechas} onChange={handleVerFechas} />
                }
                label="Por rango de fechas" />
            </FormGroup>
          </Grid>
          {verFechas && (
            <Grid item container xs={12}>
              <Grid item xs={6}>
                <TextField
                  id="fechaInicio"
                  label="Fecha Inicio"
                  variant="outlined"
                  type="date"
                  value={fechaInicio}
                  InputLabelProps={{ shrink: true }}
                  style={{ width: "100%" }}
                  onChange={(e) => setFechaInicio(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="fechaFin"
                  label="Fecha Fin"
                  variant="outlined"
                  type="date"
                  value={fechaFin}
                  InputLabelProps={{ shrink: true }}
                  style={{ width: "100%" }}
                  onChange={(e) => setFechaFin(e.target.value)}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              id="valorLinea"
              label="Valor"
              variant="outlined"
              value={valor}
              type={'number'}
              style={{ width: '100%' }}
              onChange={(e) => setValor(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: '1rem', float: 'right' }}>
            <LoadingButton
              loading={disableButtons}
              disabled={disableButtons}
              onClick={() => actualizarLineas()}
              color='primary'
              variant='contained'
            >
              <span>Guardar</span>
            </LoadingButton>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}

function LineasRefTable({ resultados, updateLineas }) {
  const [valores, setValores] = React.useState({});

  useEffect(() => {
    if (resultados) {
      const vals = {};
      resultados.forEach((resultado) => {
        vals[resultado.id] = resultado.valor;
      });
      setValores(vals);
    }
  }, [resultados]);

  const updateValoresResultados = (valor, id) => {
    valores[id] = valor;
  };

  function handleOnBlur(rowId) {
    const valorResultado = valores[rowId];
    if (isNaN(valorResultado) || valorResultado === null) return;
    if (valorResultado === previousValue) return;
    previousValue = null;
    saveResultado(rowId);
  }

  function handleOnFocus(rowId) {
    const valorResultado = valores[rowId];
    previousValue = valorResultado;
  }

  function handleKeyDown(keyCode, rowId) {
    if (keyCode === ENTER) {
      saveResultado(rowId);
    }
  }

  const saveResultado = (rowId) => {
    const valor = valores[rowId];
    if (isNaN(valor) || valor === null) {
      return toast.error("Por favor ingresa un valor numérico");
    }
    putLineaReferencia({ id: rowId, valor: parseFloat(valor) }).then(
      (response) => {
        if (response.status === 200) {
          updateLineas();
          toast.success("Valor modificado");
        } else {
          toast.error("Error al modificar el valor");
        }
      }
    );
  };

  const inputColumn = (rowInfo) => {
    return (
      <TextField
        id="valorLineaReferencia"
        variant="standard"
        type="number"
        defaultValue={rowInfo.data.valor}
        style={{ width: "100%" }}
        onChange={(e) =>
          updateValoresResultados(e.target.value, rowInfo.data.id)
        }
        onKeyDown={(e) => handleKeyDown(e.keyCode, rowInfo.data.id)}
        onBlur={(e) => handleOnBlur(rowInfo.data.id)}
        onFocus={(e) => handleOnFocus(rowInfo.data.id)}
      />
    );
  };

  const saveColumn = (rowInfo) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Tooltip title="Guardar">
          <div>
            <IconButton
              aria-label="save"
              color="warning"
              onClick={() => saveResultado(rowInfo.data.id)}
            >
              <SaveIcon />
            </IconButton>
          </div>
        </Tooltip>
      </div>
    );
  };
  return (
    <DataGrid
      dataSource={resultados}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnResizingMode="nextColumn"
    >
      <ColumnChooser
        enabled={true}
        mode="dragAndDrop"
      />
      <GroupPanel visible={true} />

      <Column dataField="id" caption="id" dataType="number" visible={false} />
      <Column
        dataField="evaluacion"
        caption="Indicador"
        dataType="string"
        allowSorting={true}
        groupIndex={0}
      />
      <Column
        dataField="fechaInicio"
        caption="Fecha Inicio"
        dataType="datetime"
        cellRender={(rowInfo) =>
          moment(rowInfo.data.fechaInicio).utc().format("D MMMM YYYY, h:mm:ss a")
        }
        allowSorting={true}
      />
      <Column
        dataField="fechaFin"
        caption="Fecha Fin"
        dataType="datetime"
        cellRender={(rowInfo) =>
          moment(rowInfo.data.fechaFin).utc().format("D MMMM YYYY, h:mm:ss a")
        }
        allowSorting={true}
      />
      <Column
        dataField="nombre"
        caption="Línea de Referencia"
        dataType="string"
        allowSorting={true}
      />
      <Column
        caption="Valor"
        allowFiltering={false}
        allowSorting={false}
        cellRender={inputColumn}
        visible
      />
      <Column
        caption="Opciones"
        allowFiltering={false}
        allowSorting={false}
        cellRender={saveColumn}
      />
      <FilterRow visible={true} />
      <FilterPanel visible={false} />
      <HeaderFilter visible={true} />
      <SearchPanel visible={true} highlightCaseSensitive={true} />
      <Paging defaultPageSize={50} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[50, 100, 150, 200]}
        showInfo={true}
      />
    </DataGrid>
  );
}

export { LineasReferencia };
