import { HOST } from "../Utils/config";
import { getRequest} from "./requests";

async function getObjetivosDesempeno() {
  return await getRequest(`${HOST}/api/v1/objetivosDesempeno/`);
}

async function getObjetivosDesempenoPonderados(usuarioId) {
  return await getRequest(`${HOST}/api/v1/objetivosDesempeno/ponderados/${usuarioId}`);
}


export { getObjetivosDesempeno, getObjetivosDesempenoPonderados };
