import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Confirm from "../../general-components/confirm";
import { Tooltip } from "@mui/material";

export default function TagNodeMember({
  item,
  onConfirm,
  permisos = { C: 0, R: 0, U: 0, D: 0 },
}) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm(item);
    handleClose();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          m: 1,
          width: "100%",
        },
      }}
    >
      <Paper style={{ paddingBottom: 15, marginBottom: 5, marginTop: 8 }}>
        {permisos.D == 1 && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Tooltip title='Eliminar esta etiqueta' placement="right">
              <IconButton
                size="small"
                aria-label="delete"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}

        <p>{item.Tag.nombre}</p>
      </Paper>
      <Confirm
        title={"Remover"}
        text={"¿Confirma que desea eliminar este elemento?"}
        handleCloseProp={handleClose}
        onConfirm={handleConfirm}
        open={open}
      />
    </Box>
  );
}
