import React, { useState, useEffect, Fragment } from "react";
import { Fab, Paper, Tooltip } from "@mui/material";
import { NodoContext } from "../Context/nodoContext";
import { getNodeRiesgos, removeRiesgo } from "../Utils/riesgosRequest";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "../general-components/modal";
import { toast } from "react-toastify";
import Confirm from "../general-components/confirm";
import { RiesgosForm } from "./riesgosForm";
import { useNavigate } from 'react-router-dom'
import { MatrizRiesgo } from "./martizRiesgo";
import "./table-style.css";

export function Riesgos() {
  const [itemList, setItemList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const { nodoActual } = React.useContext(NodoContext);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate()

  useEffect(() => {
    getElements();
  }, [nodoActual]);
  

  const handleClose = () => {
    setOpenModal(false);
  };

  const getElements = async () => {
    try {
      const nodoId = nodoActual.id;      
      const data = await getNodeRiesgos(nodoId);
      console.log("riesgos", data)
      setItemList(data);
      setSelectedItem(null);
    } catch (error) {}
  };

  const getColor = (calificacion) => {
    const opacity = 0.8;
    if (calificacion < 2) {
      return `rgba(95, 177, 87, ${opacity})`;
    } else if (calificacion < 4) {
      return `rgba(76, 142, 70, ${opacity})`;
    } else if (calificacion < 9) {
      return `rgba(246, 235, 80, ${opacity})`;
    } else if (calificacion < 12) {
      return `rgba(233, 150, 64, ${opacity})`;
    } else if (calificacion < 16) {
      return `rgba(171, 41, 37, ${opacity})`;
    } else {
      return `rgba(180, 20, 20, ${opacity})`;
    }
  };

  const getColorClass = (impactoId, probabilidadId)=>{
    let className = "";

    if(probabilidadId == 5 && impactoId==1){
      className = "lowmed";
    }else if(probabilidadId == 5 && impactoId==2){
      className = "medium";
    }else if(probabilidadId == 5 && impactoId==3){
      className = "medh";
    }else if(probabilidadId == 5 && impactoId==4){
      className = "high";
    }else if(probabilidadId == 5 && impactoId==5){
      className = "high";
    }else if(probabilidadId == 4 && impactoId==1){
      className = "low";
    }else if(probabilidadId == 4 && impactoId==2){
      className = "lowmed";
    }else if(probabilidadId == 4 && impactoId==3){
      className = "medium";
    }else if(probabilidadId == 4 && impactoId==4){
      className = "medh";
    }else if(probabilidadId == 4 && impactoId==5){
      className = "high";
    }else if(probabilidadId == 3 && impactoId==1){
      className = "low";
    }else if(probabilidadId == 3 && impactoId==2){
      className = "lowmed";
    }else if(probabilidadId == 3 && impactoId==3){
      className = "medium";
    }else if(probabilidadId == 3 && impactoId==4){
      className = "medh";
    }else if(probabilidadId == 3 && impactoId==5){
      className = "medh";
    }else if(probabilidadId == 2 && impactoId==1){
      className = "low";
    }else if(probabilidadId == 2 && impactoId==2){
      className = "lowmed";
    }else if(probabilidadId == 2 && impactoId==3){
      className = "lowmed";
    }else if(probabilidadId == 2 && impactoId==4){
      className = "medium";
    }else if(probabilidadId == 2 && impactoId==5){
      className = "medh";
    }else if(probabilidadId == 1 && impactoId==1){
      className = "low";
    }else if(probabilidadId == 1 && impactoId==2){
      className = "low";
    }else if(probabilidadId == 1 && impactoId==3){
      className = "lowmed";
    }else if(probabilidadId == 1 && impactoId==4){
      className = "medium";
    }else if(probabilidadId == 1 && impactoId==5){
      className = "medium";
    }
    console.log("classname",className)
    return className;
  }

  const removerItem = async () => {
    if (!selectedItem) return toast.warning("Seleccione un riesgo a eliminar");
    const data = { id: selectedItem.id };
    const res = await removeRiesgo(data);
    getElements();
    setOpenConfirm(false);
    setSelectedItem(null);
  };

  return (
    <Fragment>
      <Grid container spacing={1}>
     
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {Array.isArray(itemList) && itemList.length>0 &&(
            <MatrizRiesgo itemList={itemList} />
        )}
      
      </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "stretch",
              flexWrap: "wrap",
              gap: "0.5rem",
            }}
          >
            {itemList.map(
              (item, i) =>
                nodoActual.permisos.R == 1 && (
                  <Paper
                    key={i}
                    sx={{
                      p: 2,
                      display: "flex",
                      margin: "auto",
                      width: 450,
                      marginTop: 2,
                      marginBottom: 2,
                      flexDirection: "column",
                      //backgroundColor: getColor(item.calificacion),
                      fontSize: "1rem",
                      position: "relative",
                    }}
                    elevation={3}
                    className={getColorClass(item.riesgoImpactoId, item.riesgoProbabilidadId)}
                  >
                    {nodoActual.tipoNodoId === 3 &&
                      nodoActual.permisos.C == 1 && (
                        <>
                          <Tooltip
                            title="Eliminar"
                            sx={{ position: "absolute", right: 5, bottom: 5 }}
                          >
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                setSelectedItem(item);
                                setOpenConfirm(true);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title="Gestiones"
                            sx={{ position: "absolute", right: 40, bottom: 5 }}
                          >
                            <IconButton
                              aria-label="Gestiones"
                              onClick={() => {
                                navigate(`/home/gestiones/${item.id}/${nodoActual.id}/Triesgo`)
                              }}
                            >
                              <AppSettingsAltIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title="Editar"
                            sx={{ position: "absolute", right: 75, bottom: 5 }}
                          >
                            <IconButton
                              aria-label="edit"
                              onClick={() => {
                                setOpenModal(true);
                                setSelectedItem(item);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                         
                        </>
                      )}
                    <h2>{`${i+1}. ${item.nombre}`}</h2>
                    <br />
                    <div>
                      <b>Descripción: </b>
                      {item.descripcion}
                      <br />
                    </div>
                    <div>
                      <b>Probabilidad: </b>
                      {item.probabilidad}
                      <br />
                    </div>
                    <div>
                      <b>Impacto: </b>
                      {item.impacto}
                      <br />
                    </div>
                    <div>
                      <b>Riesgo Inherente: </b>
                      {item.calificacion}
                      <br />
                    </div>
                    <div>
                      <b>Riesgo Residual: </b>
                      {item.calificacionResidualEsperada}
                    </div>
                  </Paper>
                  // <ItemMember
                  //   key={`tagItem${item.id}`}
                  //   item={item}
                  //   onConfirm={removerItem}
                  //   permisos={nodoActual.permisos}
                  // />
                )
            )}
          </div>
        </Grid>
      </Grid>
      {nodoActual.tipoNodoId === 3 && nodoActual.permisos.C == 1 && (
        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: "fixed",
            right: 10,
            bottom: 10,
            backgroundColor: "#aebd36",
          }}
          onClick={() => {
            setSelectedItem(null);
            setOpenModal(true);
          }}
        >
          <AddIcon />
        </Fab>
      )}

      <Modal
        title={selectedItem ? "Editar Riesgo" : "Nuevo riesgo"}
        open={openModal}
        handleClose={handleClose}
      >
        <RiesgosForm
          handleClose={handleClose}
          getElements={getElements}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </Modal>
      <Confirm
        open={openConfirm}
        title={"Eliminar"}
        text={"¿Confirma que desea eliminar este riesgo?"}
        onConfirm={removerItem}
        handleCloseProp={() => setOpenConfirm(false)}
      />
    </Fragment>
  );
}
