import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import { FormControlLabel, Grid, Switch } from "@mui/material";

export default function ModalGestionConfirm({
  open,
  handleCloseProp,
  onConfirm,
}) {

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose = () => {
    handleCloseProp();
  };

  const handleConfirm = () => {
    onConfirm(checked);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={Paper}
        aria-labelledby="title"
      >
        <DialogTitle id="draggable-dialog-title">
          Enviar evaluaciones a los colaboradores
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Confirma que desea enviar las evaluaciones de competencias al personal a cargo, según el filtro elegido?
          </DialogContentText>
          <Grid container justifyContent="center" mt={2}>
            <FormControlLabel control={<Switch checked={checked} onChange={handleChange} />} label="Solo usuarios con bono" />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            No
          </Button>
          <Button onClick={handleConfirm}>Sí</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
