import { Button } from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import TagsModal from "../../general-components/tags-modal";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import IconButton from "@mui/material/IconButton";
import { useAuth } from "../../Hooks/useAuth";
import { NodoContext } from "../../Context/nodoContext";
import { Fab } from "@mui/material";
import {
 getBitacoraItems
} from "../../Api/bitacora.api";
import BitacoraMember from "./bitacora-member";
import { nodeType } from "../../Utils/node-types";
import Modal from "../../general-components/modal";
import TextField from "@mui/material/TextField";
import { Chip } from "@mui/material";

export default function Bitacora() {
  const [itemList, setItemList] = useState([]);    
  const { nodoActual } = React.useContext(NodoContext);     

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    getItems();
  }, [nodoActual]);

  

  useEffect(() => {    
  }, [itemList]);

  const getItems = async () => {
    try {
        const nodoId = nodoActual.id;        
      const data = await getBitacoraItems(nodoId);
      setItemList(data);
    } catch (error) {}
  };

  
  

  return (
    <Fragment>
      
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className="panelTitle">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {" "}
              {/* <IconButton
                aria-label="delete"
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                <AddIcon />
              </IconButton> */}
            </div>
            <div>{/* <h4>Etiquetas asignadas</h4> */}</div>
          </div>
          <div style={{ display: "flex",
         alignItems: "center",
         justifyContent: "center",
        
         }}>
          <Grid container spacing={1} style={{width:"80%", margin:"auto", position:"relative !important"}}>
       
            {itemList.map((item, i) => (
                nodoActual.permisos.R==1&&(
                  <Grid item xs={12} sm={12} md={12} lg={12}>
              <BitacoraMember
                key={`tagItem${item.id}`}
                item={item}
                onConfirm={()=>{}}
                permisos={nodoActual.permisos}
              />
              </Grid>
              )
            ))}
          </ Grid>
          </div>
        </Grid>
      </Grid>

    
    </Fragment>
  );
}
