import { HOST } from "./config";
import { post, put, delBody } from "./api";

async function getInputOutputSipoc(nodoSipocId) {
  const response = await fetch(
    `${HOST}/api/v1/inputoutputsipoc/items/${nodoSipocId}`
  );
  if (response.ok) {
    const jsonResponse = await response.json();
    if (jsonResponse.body) {
      return jsonResponse.body;
    }
    return [];
  }
  return [];
}

async function createInputOutputSipoc(data) {
  const response = await post(`${HOST}/api/v1/inputoutputsipoc`, data);
  return response;
}

async function removeInputOutputSipoc(data) {
  const response = await delBody(`${HOST}/api/v1/inputoutputsipoc`, data);
  return response;
}

export { getInputOutputSipoc, createInputOutputSipoc, removeInputOutputSipoc };
