import { Divider } from "@mui/material";
import React, { useEffect } from "react";
import SearchInput from "../TreeView/searchInput";
import { Archivo } from "./archivo";

function ArchivosList({
  archivos,
  setModalOpen,
  updateArchivos,
  tipoRaciId
}) {
  const [archivosFiltrados, setArchivosFiltrados] = React.useState([]);

  useEffect(() => {
    setArchivosFiltrados(archivos);
  }, [archivos]);

  const filterArchivos = (e) => {    
    setArchivosFiltrados(
      archivos.filter(
        (archivo) =>
          archivo.visibleFileName.toLowerCase().includes(e.target.value.toLowerCase())          
      )
    );
  };

  return (
    <div>
      <section
        style={{
          width: "250px",
          border: "1px solid black",
          borderRadius: "20px",
          margin: "20px",
        }}
      >
        <SearchInput onChange={filterArchivos} />
      </section>
      <Divider />
      {archivosFiltrados.map((archivo) => {
        return (
          <Archivo
            key={archivo.id}
            archivo={archivo}            
            updateArchivos={updateArchivos}
            tipoRaciId={tipoRaciId}
          />
        );
      })}
    </div>
  );
}

export { ArchivosList }