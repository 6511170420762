import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import PersonIcon from "@mui/icons-material/Person";
import Confirm from "../../general-components/confirm";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import NameBasedIcon from "../../Home/containers/nameBasedIcon";

export default function BitacoraMember({
  item,
  onConfirm,
  permisos = { C: 0, R: 0, U: 0, D: 0 },
}) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm(item);
    handleClose();
  };  

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          m: 1,
          width: "100%",
        },
      }}
    >
      <Paper style={{ padding: 15, marginBottom: 5, marginTop: 8 }}>
        {/* <PersonIcon/> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",            
          }}
        >
          <div style={{margin: "0 2rem"}}>
          <NameBasedIcon
            username={item.descripcion ? item.descripcion : ""}
            onClick={() => {}}
          />
          </div>

          <p>{item.descripcion}</p>
          {item.url && (
            <a
              style={{
                color: "#000",
                justifyContent: "center",
                alignItems: "center",
              }}
              href={item.url}
              target="_blank"
            >
              <InsertLinkIcon /> {"  " + item.url}
            </a>
          )}
        </div>
      </Paper>
      <Confirm
        title={"Remover"}
        text={"¿Confirma que desea eliminar este elemento?"}
        handleCloseProp={handleClose}
        onConfirm={handleConfirm}
        open={open}
      />
    </Box>
  );
}
