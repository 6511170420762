import React from 'react';
import  "./table-style.css"
import Paper from '@mui/material/Paper';
export function MatrizRiesgo({itemList}){
    return(
        <Paper style={{padding:8, marginTop:10}}>
     <table className='tabla-riesgo'>
      <tr>
        <th></th><th>Insignificante</th> <th>Menor</th><th>Significativo</th><th>Mayor</th><th>Severo</th>
       </tr>
       {/* fila 1 */}
       <tr>
         <th>Casi seguro</th>
         <td className='lowmed'>
           {itemList.map((item, i) =>(
             item.riesgoImpactoId === 1 && item.riesgoProbabilidadId === 5 &&(
               <span>{i+1}</span>
             )
          
           ))}
         </td>
         <td className='medium'>
         {itemList.map((item, i) =>(
             item.riesgoImpactoId === 2 && item.riesgoProbabilidadId === 5 &&(
               <span>{i+1}</span>
             )           
           ))}
         </td>
         <td className='medh'>{itemList.map((item, i) =>(
             item.riesgoImpactoId === 3 && item.riesgoProbabilidadId === 5 &&(
               <span>{i+1}</span>
             )           
           ))}</td>
         <td className='high'>{itemList.map((item, i) =>(
             item.riesgoImpactoId === 4 && item.riesgoProbabilidadId === 5 &&(
               <span>{i+1}</span>
             )           
           ))}</td>
         <td className='high'>
         {itemList.map((item, i) =>(
             item.riesgoImpactoId === 5 && item.riesgoProbabilidadId === 5 &&(
               <span>{i+1}</span>
             )           
           ))}
         </td>
       </tr>
       <tr>
         {/* fila2 */}
         <th>Probable</th>
         <td className='low'>
           {itemList.map((item, i) =>(
             item.riesgoImpactoId === 1 && item.riesgoProbabilidadId === 4 &&(
               <span>{i+1}</span>
             )
          
           ))}
         </td>
         <td className='lowmed'>
         {itemList.map((item, i) =>(
             item.riesgoImpactoId === 2 && item.riesgoProbabilidadId === 4 &&(
               <span>{i+1}</span>
             )           
           ))}
         </td>
         <td className='medium'>{itemList.map((item, i) =>(
             item.riesgoImpactoId === 3 && item.riesgoProbabilidadId === 4 &&(
               <span>{i+1}</span>
             )           
           ))}</td>
         <td className='medh'>{itemList.map((item, i) =>(
             item.riesgoImpactoId === 4 && item.riesgoProbabilidadId === 4 &&(
               <span>{i+1}</span>
             )           
           ))}</td>
         <td className='high'>
         {itemList.map((item, i) =>(
             item.riesgoImpactoId === 5 && item.riesgoProbabilidadId === 4 &&(
               <span>{i+1}</span>
             )           
           ))}
         </td>
       </tr>
       <tr>
         {/* fila3 */}
         <th>Moderado</th>
         <td className='low'>
           {itemList.map((item, i) =>(
             item.riesgoImpactoId === 1 && item.riesgoProbabilidadId === 3 &&(
               <span>{i+1}</span>
             )
          
           ))}
         </td>
         <td className='lowmed'>
         {itemList.map((item, i) =>(
             item.riesgoImpactoId === 2 && item.riesgoProbabilidadId === 3 &&(
               <span>{i+1}</span>
             )           
           ))}
         </td>
         <td className='medium'>{itemList.map((item, i) =>(
             item.riesgoImpactoId === 3 && item.riesgoProbabilidadId === 3 &&(
               <span>{i+1}</span>
             )           
           ))}</td>
         <td className='medh'>{itemList.map((item, i) =>(
             item.riesgoImpactoId === 4 && item.riesgoProbabilidadId === 3 &&(
               <span>{i+1}</span>
             )           
           ))}</td>
         <td className='medh'>
         {itemList.map((item, i) =>(
             item.riesgoImpactoId === 5 && item.riesgoProbabilidadId === 3 &&(
               <span>{i+1}</span>
             )           
           ))}
         </td>
       </tr>
       <tr>
         {/* fila4 */}
         <th>Poco probable</th>
         <td className='low'>
           {itemList.map((item, i) =>(
             item.riesgoImpactoId === 1 && item.riesgoProbabilidadId === 2 &&(
               <span>{i+1}</span>
             )           
           ))}
         </td>
         <td className='lowmed'>
         {itemList.map((item, i) =>(
             item.riesgoImpactoId === 2 && item.riesgoProbabilidadId === 2 &&(
               <span>{i+1}</span>
             )           
           ))}
         </td>
         <td className='lowmed'>{itemList.map((item, i) =>(
             item.riesgoImpactoId === 3 && item.riesgoProbabilidadId === 2 &&(
               <span>{i+1}</span>
             )           
           ))}</td>
         <td className='medium'>{itemList.map((item, i) =>(
             item.riesgoImpactoId === 4 && item.riesgoProbabilidadId === 2 &&(
               <span>{i+1}</span>
             )           
           ))}</td>
         <td className='medh'>
         {itemList.map((item, i) =>(
             item.riesgoImpactoId === 5 && item.riesgoProbabilidadId === 2 &&(
               <span>{i+1}</span>
             )           
           ))}
         </td>
       </tr>

       <tr>
         {/* fila5 */}
         <th>Raro</th>
         <td className='low'>
           {itemList.map((item, i) =>(
             item.riesgoImpactoId === 1 && item.riesgoProbabilidadId === 1 &&(
               <span>{i+1}</span>
             )           
           ))}
         </td>
         <td className='low'>
         {itemList.map((item, i) =>(
             item.riesgoImpactoId === 2 && item.riesgoProbabilidadId === 1 &&(
               <span>{i+1}</span>
             )           
           ))}
         </td>
         <td className='lowmed'>{itemList.map((item, i) =>(
             item.riesgoImpactoId === 3 && item.riesgoProbabilidadId === 1 &&(
               <span>{i+1}</span>
             )           
           ))}</td>
         <td className='medium'>{itemList.map((item, i) =>(
             item.riesgoImpactoId === 4 && item.riesgoProbabilidadId === 1 &&(
               <span>{i+1}</span>
             )           
           ))}</td>
         <td className='medium'>
         {itemList.map((item, i) =>(
             item.riesgoImpactoId === 5 && item.riesgoProbabilidadId === 1 &&(
               <span>{i+1}</span>
             )           
           ))}
         </td>
       </tr>
     </table>
     </Paper>
    )
}