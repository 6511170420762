import { Divider } from "@mui/material";
import React, { useEffect } from "react";
import SearchInput from "../TreeView/searchInput";
import { Periodo } from "./periodo";

function PeriodoList({
  periodos,
  setModalOpen,
  setPeriodoActivo,
  updatePeriodos,
}) {
  const [periodosFiltrados, setPeriodosFiltrados] = React.useState([]);

  useEffect(() => {
    setPeriodosFiltrados(periodos);
  }, [periodos]);

  const filterPeriodos = (e) => {    
    setPeriodosFiltrados(
      periodos.filter(
        (periodo) =>
          periodo.nombre.toLowerCase().includes(e.target.value.toLowerCase()) ||
          periodo.descripcion
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
      )
    );
  };

  return (
    <div>
      <section
        style={{
          width: "250px",
          border: "1px solid black",
          borderRadius: "20px",
          margin: "20px",
        }}
      >
        <SearchInput onChange={filterPeriodos} />
      </section>
      <Divider />
      {periodosFiltrados.map((periodo) => {
        return (
          <Periodo
            key={periodo.id}
            periodo={periodo}
            setModalOpen={setModalOpen}
            setPeriodoActivo={setPeriodoActivo}
            updatePeriodos={updatePeriodos}
          />
        );
      })}
    </div>
  );
}

export { PeriodoList };
