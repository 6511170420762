import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { EJES } from '../../../../Utils/utilities';
import { getConfiguracionPonderaciones } from '../../../../Api/configuracion.api';

const responsiveCellStyle = {
    xs: 12,
    sm: 6,
    md: 6,
}

const CrearNivel = ({
    configuracion,
    setConfiguracion,
    periodos,
}) => {

    const [loading, setLoading] = React.useState(false);
    const [ponderaciones, setPonderaciones] = React.useState([]);

    React.useEffect(() => {
        if (periodos) {
            setLoading(true);
            getConfiguracionPonderaciones(periodos?.map(periodo => periodo.id) || []).then((response) => {
                setPonderaciones(response.body || []);
                setLoading(false);
            }).catch((error) => {
                console.error(error);
                setLoading(false);
            });
        }
    }, []);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
                <Grid item {...responsiveCellStyle}>
                    <TextField
                        required
                        type='number'
                        label="Rango Inicio"
                        variant="outlined"
                        value={configuracion?.rango_inicio}
                        onChange={(e) => setConfiguracion({ ...configuracion, rango_inicio: e.target.value })}
                        fullWidth
                    />
                </Grid>
                <Grid item {...responsiveCellStyle}>
                    <TextField
                        required
                        type='number'
                        label="Rango Fin"
                        variant="outlined"
                        value={configuracion?.rango_fin}
                        onChange={(e) => setConfiguracion({ ...configuracion, rango_fin: e.target.value })}
                        fullWidth
                    />
                </Grid>
                <Grid item {...responsiveCellStyle}>
                    <Autocomplete
                        required
                        options={periodos || []}
                        value={periodos?.find((periodo) => periodo.id === configuracion?.periodoId) || null}
                        getOptionLabel={(option) => option?.nombre || ''}
                        getOptionKey={(option) => option.id || ''}
                        onChange={(e, newValue) => {
                            setConfiguracion({ ...configuracion, periodoId: newValue?.id || null, ponderacionId: null });
                        }}
                        renderInput={(params) => <TextField {...params} label="Período" variant="outlined" />}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item {...responsiveCellStyle}>
                    <Autocomplete
                        required
                        options={EJES || []}
                        value={EJES?.find((eje) => eje.value === configuracion?.eje) || null}
                        getOptionLabel={(option) => option?.label || ''}
                        getOptionKey={(option) => option.value || ''}
                        onChange={(e, newValue) => {
                            setConfiguracion({ ...configuracion, eje: newValue?.value || null, ponderacionId: null });
                        }}
                        renderInput={(params) => <TextField {...params} label="Eje" variant="outlined" />}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item {...responsiveCellStyle}>
                    <Autocomplete
                        required
                        options={ponderaciones.filter((ponderacion) => ponderacion.eje === configuracion?.eje && ponderacion.periodoId == configuracion?.periodoId) || []}
                        value={ponderaciones?.find((ponderacion) => ponderacion.id === configuracion?.ponderacionId) || null}
                        getOptionLabel={(option) => `${option?.valor || ''} - ${option?.nombre || ''}`}
                        getOptionKey={(option) => option.id || ''}
                        onChange={(e, newValue) => {
                            setConfiguracion({ ...configuracion, ponderacionId: newValue?.id || null });
                        }}
                        renderInput={(params) => <TextField {...params} label="Ponderación" variant="outlined" />}
                        style={{ width: "100%" }}
                    />
                </Grid>
            </Grid>
            {/* Pantalla de carga */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}

export default CrearNivel;