import { HOST } from "../Utils/config";
import { post } from "../Utils/api";
import { getRequest, postRequest, putRequest } from "./requests";

async function getPeriodos() {
  return await getRequest(`${HOST}/api/v1/periodos`);
}

async function getPeriodosPrivado(idMenu) {
  return await getRequest(`${HOST}/api/v1/periodos/private?idMenu=${idMenu}`);
}

async function getPeriodosPorIndicador(idIndicador) {
  return await getRequest(`${HOST}/api/v1/periodos/indicador/${idIndicador}`);
}

async function getPeriodo(periodoId) {
  return await getRequest(`${HOST}/api/v1/periodos/${periodoId}`);
}

async function postPeriodo(periodo) {
  return await postRequest(`${HOST}/api/v1/periodos`, periodo);
}

async function putPeriodo(periodo) {
  return await putRequest(`${HOST}/api/v1/periodos`, periodo);
}

async function iniciarPeriodo(data) {
  const response = await postRequest(`${HOST}/api/v1/periodos/start`, data);
  return response;
}

async function reiniciarPeriodo(data) {
  const response = await postRequest(`${HOST}/api/v1/periodos/restart`, data);
  return response;
}

async function reiniciarActividadPeriodo(actividadId, periodoId) {  
  return await putRequest(`${HOST}/api/v1/periodos/restart/${periodoId}/actividad/${actividadId}`, {});
}

async function establecerCargaAutomatica(periodoId) {  
  return await putRequest(`${HOST}/api/v1/periodos/cargaAutomatica/${periodoId}`, {});
}

async function toogleHabilitarPeriodo(periodoId) {  
  return await putRequest(`${HOST}/api/v1/periodos/toogleRecalculo/${periodoId}`, {});
}

async function reiniciarIndicadorPeriodo(indicadorId, periodoId, esSeries=false) {  
  return await putRequest(`${HOST}/api/v1/periodos/restart/${periodoId}/indicador/${indicadorId}`, {esSeries});
}

async function solicitarReinicioActividad(actividadId, periodoId) {  
  const body = {
    descripcion: `Solicitud de reinicio de actividad`,
    periodoId,
    nodoId: actividadId,
  }
  return await postRequest(`${HOST}/api/v1/solicitudes`, body);
}

async function solicitarReinicioIndicador(indicadorId, periodoId, esSeries=false) {  
  const body = {
    descripcion: `Solicitud de reinicio de actividad`,
    periodoId,
  }
  if (esSeries) {
    body.agrupacionId = indicadorId;
  } else {
    body.indicadorId = indicadorId;
  }
  return await postRequest(`${HOST}/api/v1/solicitudes`, body);
}

export {
  getPeriodo,
  getPeriodosPrivado,
  getPeriodosPorIndicador,
  getPeriodos,
  postPeriodo,
  putPeriodo,
  iniciarPeriodo,
  reiniciarPeriodo,
  reiniciarActividadPeriodo,
  reiniciarIndicadorPeriodo,
  solicitarReinicioActividad,
  solicitarReinicioIndicador,
  establecerCargaAutomatica,
  toogleHabilitarPeriodo
};
