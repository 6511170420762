import { Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ReactFlow, {
  Background,
  ControlButton,
  Controls,
  MiniMap,
  ReactFlowProvider,
} from "react-flow-renderer";
import { toast } from "react-toastify";
import { getMapaDeIndicadores } from "../Api/indicadores.api";
import { CustomIndicadorNode } from "../Nodes/customIndicadorNode";
import { getLayoutedElements } from "../Process/Diagrams/createProcessFlow";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
const onLoad = (reactFlowInstance) =>
  reactFlowInstance.fitView({ padding: 0.2 });
const nodeTypes = {
  customIndicador: CustomIndicadorNode,
};

export default function MapaIndicadores() {
  const [elements, setElements] = React.useState([]);
  const [areElementsReady, setElementsReady] = React.useState(false);
  const [mostrarTodosNodos, setMostrarTodosNodos] = React.useState(false);

  useEffect(() => {
    actualizarIndicadores();
  }, []);

  useEffect(() => {
    actualizarIndicadores();
  }, [mostrarTodosNodos]);

  function actualizarIndicadores() {
    getMapaDeIndicadores(mostrarTodosNodos).then((response) => {
      if (response.status === 200) {        
        const indicadores = getInitialElements(response.body.indicadores);
        const relaciones = getInitialRelations(response.body.relaciones);
        indicadores.push.apply(indicadores, relaciones);
        const layoutedElements = getLayoutedElements(indicadores, "LR");
        setElements(layoutedElements);
        setElementsReady(true);
      } else {
        toast.error(response.message);
      }
    });
  }

  function getInitialElements(indicadores) {
    const indicadoresFormateados = indicadores.map((indicador) => {
      return {
        id: `${indicador.id}`,
        type: "customIndicador",
        data: {
          indicador,
        },
        position: { x: 0, y: 0 },
      };
    });
    return indicadoresFormateados;
  }

  function getInitialRelations(relaciones) {
    const relacionesFormateadas = relaciones.map((relacion) => {
      return {
        id: `e${relacion.from}${relacion.to}`,
        source: `${relacion.from}`,
        target: `${relacion.to}`,
        type: "smoothstep",
        animated: true,
      };
    });
    return relacionesFormateadas;
  }

  return (
    <div>
      <Typography variant="h4" noWrap component="div">
        Mapa de Indicadores
      </Typography>
      {areElementsReady && (
        <div style={{ height: "calc(100vh - 130px)" }}>
          <ReactFlowProvider>
            <ReactFlow
              elements={elements}
              nodeTypes={nodeTypes}
              // onNodeDoubleClick={onNodeClick}
              onLoad={onLoad}
              elementsSelectable={false}
              nodesConnectable={false}
              nodesDraggable={false}
            >
              <MiniMap />
              <Controls
                showInteractive={true}
                style={{ top: 10, height: "50px" }}
              >
                {!mostrarTodosNodos && (
                  <ControlButton
                    onClick={() => setMostrarTodosNodos(!mostrarTodosNodos)}
                  >
                    <Tooltip title="Mostrar todos los nodos">
                      <VisibilityIcon />
                    </Tooltip>
                  </ControlButton>
                )}
                {mostrarTodosNodos && (
                  <ControlButton
                    onClick={() => setMostrarTodosNodos(!mostrarTodosNodos)}
                  >
                    <Tooltip title="Mostrar solo nodos relacionados">
                      <VisibilityOffIcon />
                    </Tooltip>
                  </ControlButton>
                )}
              </Controls>
              <Background color="#aaa" gap={16} />
            </ReactFlow>
          </ReactFlowProvider>
        </div>
      )}
    </div>
  );
}
