import React from "react";
import { toast } from "react-toastify";
import { SimpleTreeView } from '@mui/x-tree-view';
import { AuthContext } from "../Context/authContext";
import { getEstructuraSubordinados } from "../Api/estructuraPuestos.api";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CoPresentIcon from '@mui/icons-material/CoPresent';
import StyledEmpleadoItem from "./styledEmpleadoItem";
import PersonIcon from "@mui/icons-material/Person";
import { NodoContext } from "../Context/nodoContext";

export default function EstructuraPuestosMain() {
  const [estructura, setEstructura] = React.useState([]);
  const { selectedUser, getSelectedUserDPI } = React.useContext(AuthContext);
  const { setLoading, setLoadingMessage } = React.useContext(NodoContext);

  React.useEffect(() => {
    updateEstructura();
  }, []);

  React.useEffect(() => {
    updateEstructura();
  }, [selectedUser]);

  function updateEstructura() {
    setLoading(true);
    setLoadingMessage("Cargando..");
    getEstructuraSubordinados(getSelectedUserDPI()).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setEstructura(response.body);        
      } else {
        toast.error("No se pudo recuperar la estructura organizacional");
      }
    });
  }

  return <ArbolDePuestos estructura={estructura} />;
}

function ArbolDePuestos({ estructura }) {
  const { selectedUser, setSelectedUser } = React.useContext(AuthContext);

  function asumirUsuario(empleado){    
    setSelectedUser({nombre: empleado.nombre, usuario: empleado.usuario, id: empleado.id});
  }

  return (
    <SimpleTreeView
      aria-label="controlled"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ flexGrow: 1, maxWidth: 400, overflowY: "auto", marginTop: 2 }}
    >
      {estructura.map((empleado) => {
        return (
          <StyledEmpleadoItem
            nodeId={`${empleado.usuario}`}
            id={empleado.id}
            labelText={empleado.nombre}
            puesto={empleado.puesto}
            labelIcon={PersonIcon}
            eventIcon={CoPresentIcon}
            item={empleado}
            items={empleado.subordinados}
            level={2}
            key={empleado.id}
            color="#000"
            bgColor="#aebd36"
            onClick={asumirUsuario}
            puestoFontSize="14px"
            nombreFontSize="12px"
            noPrimerNodo={true}
          ></StyledEmpleadoItem>
        );
      })}
    </SimpleTreeView>
  );
}
