import React from "react";
import Box from "@mui/material/Box";
import { nodeType } from "../Utils/node-types";
import { getNodos } from "../Api/nodos.api";
import { toast } from "react-toastify";
import { NodoContext } from "../Context/nodoContext";
import CustomizedTreeView from "../TreeView/treeView";
const PROCESS_ID = 2;

export default function TreeViewSipoc({ setSelectedNodeId, setSelectedNodeName }) {
  const [selected, setSelected] = React.useState([]);
  const [allNodes, setAllNodes] = React.useState([]);
  const { nodos, reloadTree } = React.useContext(NodoContext);

  const handleSelect = (event, nodeIdString) => {
    const nodeId = parseInt(nodeIdString);
    //busca nodo para conocer de que tipo es y si es tipo actividad permite setearlo como actividad seleccionada para sipoc
    const node = allNodes.find((el) => el.id === nodeId);
    if (node.tipoNodoId === nodeType.actividad) {
      setSelectedNodeId(nodeId);      
      setSelectedNodeName(node.nombre);
    }    
  };

  React.useEffect(() => {
    reloadTree();
    reloadNodos();
  }, []);

  const reloadNodos = () => {
    getNodos().then((response) => {
      if (response.status === 200) {
        setAllNodes(response.body);
      } else {
        toast.error(response.message);
      }
    });
  };
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <CustomizedTreeView
          handleSelect={handleSelect}
          selected={selected}
          setSelected={setSelected}
          color="blue"
          bgColor="#aebd36"          
          itemColor="black"
        />
      </Box>
    </div>
  );
}
