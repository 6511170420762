import React from "react";
import "moment/locale/es";
import moment from "moment";
import { toast } from "react-toastify";
import DataGrid, {
  Column,
  Grouping,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  Pager,
  Paging,
  ColumnChooser,
  GroupPanel,
  SearchPanel,
  Sorting,
  StateStoring,
  Editing,
  KeyboardNavigation,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { Checkbox, FormControlLabel, FormGroup, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import Confirm from "../../general-components/confirm";
import { ArchivosMain } from "../../Archivos/archivosMain";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { eliminaResultado } from "../../Api/resultado.api"
import { putResultado } from "../../Api/resultado.api";

moment.locale("es");

export default function ResultadosGrid({
  resultados,
  allGroupsExpanded = false,
  updateResultados,
  storageKey = "resultadosStorage"
}) {

  const [resultadoSeleccionado, setResultadoSeleccionado] = React.useState(-1);
  const [filesModalOpen, setFilesModalOpen] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [rowIdDelete, setRowIdDelete] = React.useState(null);
  const [enableMetas, setEnableMetas] = React.useState(false);
  const [columnOnEditing, setColumnOnEditing] = React.useState('');
  const navigate = useNavigate();

  function attachFiles(id) {
    setResultadoSeleccionado(id);
    setFilesModalOpen(true);
  }

  const saveColumn = (rowInfo) => {
    return (
      <section style={{ display: "flex" }}>
        <Tooltip title={`Adjuntos (${rowInfo.data.adjuntos})`}>
          <IconButton
            aria-label="delete"
            color={rowInfo.data.adjuntos > 0 ? "primary" : "default"}
            onClick={() => attachFiles(rowInfo.data.resultadoId)}
            size="small"
          >
            <AttachFileIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Eliminar">
          <IconButton
            aria-label="delete"
            color="warning"
            onClick={() => { setOpenConfirm(true); setRowIdDelete(rowInfo.data.resultadoId) }}
            size="small"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </section>
    );
  };

  const handleEliminarResultado = async () => {
    console.log('eliminandoooooooooooooooooo')
    await eliminaResultado(rowIdDelete);
    console.log('eliminado')
    await updateResultados();
    setOpenConfirm(false);
    setRowIdDelete(null);
  }

  const handleEditingStart = (e) => { setColumnOnEditing(e.column.dataField); };

  const onSaved = (e) => {
    const itemChanged = e.changes[0].data;
    console.log('itemChanged columnOnEditing ', columnOnEditing);
    if (itemChanged && itemChanged?.resultadoId) {
      if (columnOnEditing === "meta") {
        saveMeta(itemChanged.resultadoId, itemChanged.meta);
      } else if (columnOnEditing === "valorNumerico") {
        saveResultado(itemChanged.resultadoId, itemChanged.valorNumerico);
      }
    }
  }

  const saveResultado = (id, valor) => {
    if (isNaN(valor)) return toast.error("Por favor ingresa un valor numérico");

    putResultado({ id, valorNumerico: parseFloat(valor) }).then(response => {
      if (response.status === 200) {
        toast.success("Resultado Modificado correctamente");
      }
    });
  };

  const saveMeta = (id, valor) => {
    if (isNaN(valor)) return toast.error("Por favor ingresa un valor numérico");

    putResultado({ id, meta: parseFloat(valor) }).then(response => {
      if (response.status === 200) {
        toast.success("Meta Modificada correctamente");
      }
    });
  };

  return (
    <div style={{ margin: "0 40px 40px 40px" }}>
      <DataGrid
        keyExpr="resultadoId"
        dataSource={resultados || []}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnResizingMode="nextColumn"
        onEditingStart={handleEditingStart}
        onSaved={onSaved}
      >
        <Editing
          mode="cell"
          selectTextOnEditStart={true}
          allowUpdating={true}
        />
        <KeyboardNavigation
          editOnKeyPress={true}
          enterKeyDirection={"column"}
        />
        <Grouping
          autoExpandAll={allGroupsExpanded}
        />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey={storageKey}
        />
        <Sorting mode="multiple" />
        <ColumnChooser
          enabled={true}
          mode="dragAndDrop"
        />
        <GroupPanel visible={true} />

        <Column dataField="resultadoId" caption="ID" dataType="number" visible={false} allowEditing={false} />
        <Column
          dataField="periodo"
          caption="Periodo"
          dataType="string"
          allowSorting={true}
          allowEditing={false}
        />
        <Column
          dataField="nombreOKR"
          caption="Objetivo Clave"
          dataType="string"
          allowSorting={true}
          allowEditing={false}
        />
        <Column
          dataField="nombreKR"
          caption="Resultado Clave"
          dataType="string"
          allowSorting={true}
          allowEditing={false}
        />
        <Column
          dataField="fechaInicio"
          caption="Fecha Inicio"
          dataType="datetime"
          cellRender={(rowInfo) =>
            moment(rowInfo.data.fechaInicio).utc().format("D MMMM YYYY, h:mm:ss a")
          }
          defaultSortOrder={'asc'}
          defaultSortIndex={1}
          sortOrder={'asc'}
          sortIndex={1}
          allowSorting={true}
          allowEditing={false}
        />
        <Column
          dataField="fechaFin"
          caption="Fecha Fin"
          dataType="datetime"
          cellRender={(rowInfo) =>
            moment(rowInfo.data.fechaFin).utc().format("D MMMM YYYY, h:mm:ss a")
          }
          allowSorting={true}
          allowEditing={false}
        />
        <Column
          caption="Meta"
          dataField="meta"
          allowFiltering={false}
          allowSorting={false}
          allowEditing={enableMetas}
          width={100}
        />
        <Column
          caption="Resultado"
          dataField="valorNumerico"
          allowFiltering={false}
          allowSorting={false}
          allowEditing={true}
          width={100}
        />
        <Column
          caption="Opciones"
          allowFiltering={false}
          allowSorting={false}
          cellRender={saveColumn}
          width={120}
          allowEditing={false}
        />

        <Toolbar>

          <Item name="groupPanel" />
          <Item location="after">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={enableMetas} onChange={e => setEnableMetas(e.target.checked)} />}
                label="Habilitar metas"
              />
            </FormGroup>
          </Item>
          <Item name="columnChooserButton" />
          <Item name='searchPanel' />

        </Toolbar>

        <FilterRow visible={true} />
        <FilterPanel visible={false} />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <Paging defaultPageSize={50} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[50, 100, 150, 200]}
          showInfo={true}
        />
      </DataGrid>

      <ArchivosMain
        open={filesModalOpen}
        setOpen={setFilesModalOpen}
        tipoRaciId={1}
        rowId={resultadoSeleccionado}
        updateResultados={updateResultados}
      />

      <Confirm
        open={openConfirm}
        title={"Eliminar"}
        text={"¿Confirma que desea eliminar este elemento?"}
        onConfirm={handleEliminarResultado}
        handleCloseProp={() => setOpenConfirm(false)}
      />
    </div>
  )
}
