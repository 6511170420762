import React, { useEffect } from "react";
import { Handle, Position } from "react-flow-renderer";
import TemporaryDrawer from "../general-components/temporary-drawer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

let customNodeStyles = {
  background: "#fff",
  color: "#000",
  padding: 2,
  border: `solid 1px black`,
  borderRadius: 5,
  width: 180,
  height: 90,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
};

const CustomActivitiesNode = ({ data, selected }) => {
  const navigate = useNavigate();

  function navigateToActivity() {
    navigate(`/home/procesos/${data.id}`);
  }

  return (
    <div
      style={{
        ...customNodeStyles,
        borderStyle: selected ? "dashed" : "solid",
        overflow: "auto",
        resize: selected ? "none" : "both",
      }}
      onDoubleClick={navigateToActivity}
    >
      <Handle
        type="source"
        position={Position.Left}
        style={{ borderRadius: 50 }}
        id="inputSource"
      />
      <div style={{ width: "90%", height: "100%" }}>
        <Typography
          variant="body2"
          gutterBottom
          component="div"
          style={{ fontWeight: "bold" }}
        >
          {data.text}
        </Typography>
        <Divider />
        <div style={{ fontSize: 10, color: "GrayText" }}>
          <div style={{ textAlign: "center" }}>
            <b>Proceso:</b> {data.codigo} - {data.padre}
          </div>
        </div>
        <Typography
          variant="body2"
          gutterBottom
          component="div"
          dangerouslySetInnerHTML={{
            __html: data.info,
          }}
        ></Typography>
      </div>
      <Handle
        type="target"
        position={Position.Right}
        style={{ borderRadius: 50 }}
        id="inputTarget"
      />
    </div>
  );
};

export { CustomActivitiesNode };
