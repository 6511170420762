import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SimpleTreeView } from "@mui/x-tree-view";
import StyledTreeItem from "./treeViewItem";
import FolderIcon from "@mui/icons-material/Folder";
import SettingsIcon from "@mui/icons-material/Settings";
import { NodoContext } from "../Context/nodoContext";

export default function CustomTreeView({
  searchString = "",
  selected,
  setSelected,
  handleSelect,
  color = "#000",
  bgcolor = "#aebd36",
  itemColor = "white",
}) {
  const [nodosFiltrados, setNodosFiltrados] = useState([]);
  const { nodos, reloadTree, estructuraArbolNodos } =
    React.useContext(NodoContext);

  React.useEffect(() => {
    if (estructuraArbolNodos.length > 0) return;
    reloadTree();
  }, []);

  React.useEffect(() => {
    const searchStringLowerCase =
      eliminarDiacriticos(searchString).toLowerCase();
    if (searchString.length > 0) {
      setNodosFiltrados(
        nodos.filter((nodo) =>
          eliminarDiacriticos(nodo.nombre)
            .toLowerCase()
            .includes(searchStringLowerCase)
        )
      );
    }
  }, [searchString]);

  function eliminarDiacriticos(texto) {
    return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  return (
    <SimpleTreeView
      draggable
      aria-label="controlled"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      selectedItems={selected}
      onSelectedItemsChange={handleSelect}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ flexGrow: 1, maxWidth: 400, overflowY: "auto", marginTop: 2 }}
    >
      {searchString.length === 0
        ? estructuraArbolNodos.map((nodo) => {
            return (
              <StyledTreeItem
                nodeId={`${nodo.id}`}
                labelText={`${nodo.codigo} - ${nodo.nombre}`}
                labelIcon={nodo.tipoNodoId === 2 ? FolderIcon : SettingsIcon}
                items={nodo.items}
                level={2}
                key={nodo.id}
                padre={null}
                selected={selected}
                color={color}
                bgColor={bgcolor}
                itemColor={itemColor}
              ></StyledTreeItem>
            );
          })
        : nodosFiltrados.map((nodo) => {
            return (
              <StyledTreeItem
                nodeId={`${nodo.id}`}
                labelText={`${nodo.codigo}-${nodo.nombre}`}
                labelIcon={nodo.tipoNodoId === 2 ? FolderIcon : SettingsIcon}
                //items={nodo.items}
                level={1}
                key={nodo.id}
                padre={nodo.id}
                color={color}
                bgColor={bgcolor}
                itemColor={itemColor}
                selected={-1}
              ></StyledTreeItem>
            );
          })}
    </SimpleTreeView>
  );
}
