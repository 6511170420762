import { HOST } from "../Utils/config";
import { getRequest, postRequest } from "./requests";

async function getUsuarios() {
  return await getRequest(`${HOST}/api/v1/usuarios`);
}

async function getGerencias() {
  return await getRequest(`${HOST}/api/v1/usuarios/gerencias`);
}

async function getMiUsuario() {
  return await getRequest(`${HOST}/api/v1/usuarios/yo`);
}

async function changePassword(body) {
  return await postRequest(`${HOST}/api/v1/usuarios/changepassword`, body);
}

async function getUsuariosGerentes() {
  return await getRequest(`${HOST}/api/v1/usuarios/gerentes`);
}

export{ getUsuarios, getGerencias, getMiUsuario, changePassword, getUsuariosGerentes }