import { HOST } from "../Utils/config";
import { getRequest } from "./requests";

async function getModosIntegracion() {
  return await getRequest(`${HOST}/api/v1/modosintegracion`);
}

async function getModosIntegracionGlobal() {
  return await getRequest(`${HOST}/api/v1/modointegracionGlobal`);
}

export { getModosIntegracion, getModosIntegracionGlobal };
