import { Typography } from "@mui/material";
import React from "react";
import { VerticalProcessFlow } from "./verticalProcessFlow";

function ProcessDiagram() {
  return (
    <div>
      <Typography variant="h4" noWrap component="div">
        Mapa de Procesos
      </Typography>
      <VerticalProcessFlow />
    </div>
  );
}

export { ProcessDiagram };
