import { HOST } from '../Utils/config'
import { getRequest, postRequest, deleteRequest } from './requests'

async function getObjetivoDesempenoPorNivel(nivelId) {
  return await getRequest(
    `${HOST}/api/v1/objetivosDesempenoNivel/${nivelId}`
  )
}

async function postObjetivoDesempenoNivel(data) {
  return await postRequest(`${HOST}/api/v1/objetivosDesempenoNivel`, data)
}

async function deleteObjetivoDesempenoNivel(id) {
  return await deleteRequest(`${HOST}/api/v1/objetivosDesempenoNivel/${id}`)
}

export {
  getObjetivoDesempenoPorNivel,
  postObjetivoDesempenoNivel,
  deleteObjetivoDesempenoNivel
}
