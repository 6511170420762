import React, { useEffect, useState, useRef } from "react";
import ProcessTitle from "../components/processTitle";
import ProcessCode from "../components/processCode";
import { ProcessTabs } from "../components/processTabs";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Confirm from "../../../general-components/confirm";
import { useNavigate } from "react-router-dom";
import { NodoContext } from "../../../Context/nodoContext";
import { deleteNodo } from "../../../Api/nodos.api";
import MenuTresPuntitos from "../components/menuTresPuntitos";
import ModalPermisos from "../../../general-components/modalPermisos";
import { ModalCopiaActividad } from "../../../general-components/modalCopiaActividad";
import mainStyle from "../style.module.css";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip, Breadcrumbs, Typography, Link, Badge } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ModalReiniciarActividad } from "../../../general-components/modalReiniciarActividad";
import { PeriodoContext } from "../../../Context/periodoContext";
import Modal from "../../../general-components/modal"
import Bitacora from "../../Activities/bitacora";
import PerfectScrollbar from "react-perfect-scrollbar";


function ProcessDisplay() {
  const { nodoId } = useParams();
  const ref = useRef();
  const { nodoActual, reloadNodoActual, reloadTree } =
    React.useContext(NodoContext);
  const { getSelected, getUnicoPeriodoSeleccionado } = React.useContext(PeriodoContext);
  const navigate = useNavigate();
  const [openPermisoModal, setOpenPermisoModal] = useState(false);
  const [openModalCopia, setOpenModalCopia] = useState(false);
  const [oepnReiniciarModal, setOpenReiniciarModal] = useState(false);
  const [openBitacoraModal, setOpenBitacoraModal] = useState(false);

  useEffect(() => {
    if (nodoId) {
      reloadNodoActual(nodoId);
    }
  }, [nodoId]);

  const [openConfirm, setOpenConfirm] = useState(false);

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenConfirm = () => {
    if (!nodoActual.permisos.D) {
      toast.warn("No tienes permiso para poder realizar esta acción");
      return;
    }
    setOpenConfirm(true);
  };

  const mostrarPermisos = () => {
    if (!nodoActual.permisos.U) {
      toast.warn("No tienes permiso para poder realizar esta acción");
      return;
    }
    setOpenPermisoModal(true);
  };

  const del = async () => {
    try {
      const resp = await deleteNodo(nodoActual.id);
      if (resp.status === 200) {
        toast.success("Proceso eliminado correctamente");
        await reloadTree();
        navigate(`/home`);
      } else {
        toast.error("Error al eliminar el proceso");
      }
    } catch (error) {
    } finally {
      handleCloseConfirm();
    }
  };

  const handleClosePermiso = () => {
    setOpenPermisoModal(false);
  };

  const recargarArbolNodos = async () => {
    await reloadTree();
  };

  const handleCloseBitacora = () => {
    setOpenBitacoraModal(false)
  }
  const mostrarBitacora = () => {
    setOpenBitacoraModal(true);
    console.log("hola")
  }

  if (nodoActual) {
    return (
      <div>
        <div className={mainStyle["main-style"]}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: '0.5rem'
            }}
          >
            {/* <div></div> */}
            <div style={{ display: "flex", flexDirection: 'column', flexWrap: "wrap" }}>
              <div style={{ display: "flex"}}>
                <Breadcrumbs
                  maxItems={5}
                  separator={<NavigateNextIcon fontSize="small" />}
                  sx={{ display: "flex" }}
                >
                  {nodoActual.padres.map((nodoRuta, index) => {
                    if (index === nodoActual.padres.length - 1) {
                      return (
                        <div></div>
                      )
                    }
                    return (
                      <Link
                        key={nodoRuta.id}
                        color="inherit"
                        underline="hover"
                        // href={`/home/procesos/${nodoRuta.id}`}
                        onClick={() => navigate(`/home/procesos/${nodoRuta.id}`)}
                      >
                        <Typography
                          variant="caption"
                        >
                          {nodoRuta.codigo} - {nodoRuta.nombre}
                        </Typography>
                      </Link>
                    )
                  })}
                </Breadcrumbs>
            </div>
              <div style={{ display: 'flex' }}>
                <ProcessCode />
                <ProcessTitle />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {(nodoActual.permisos.U || nodoActual.permisos.A) ? (
                <Tooltip title={!getUnicoPeriodoSeleccionado()?.recalculoHabilitado ? "Solicitar reinicio" : "Reiniciar periodo"}>
                  <IconButton
                    aria-label="Restart"
                    onClick={() => {
                      if (getSelected().length > 1) {
                        return toast.warning(
                          "Solo es permitido reiniciar un periodo a la vez"
                        );
                      }
                      // if (!getUnicoPeriodoSeleccionado()?.recalculoHabilitado) {
                      //   return toast.warning(
                      //     "El periodo seleccionado no tiene habilitado el recálculo de indicadores"
                      //   );
                      // }
                      setOpenReiniciarModal(true);
                    }}
                  >
                    <RestartAltIcon color={(!getUnicoPeriodoSeleccionado()?.recalculoHabilitado) ? "warning" : "secondary"} />
                  </IconButton>
                </Tooltip>
              ) : null}
              {nodoActual.permisos.U ? (
                <MenuTresPuntitos
                  abrirModalCopia={() => setOpenModalCopia(true)}
                  mostrarPermisos={mostrarPermisos}
                  mostrarBitacora={mostrarBitacora}
                  eliminar={handleOpenConfirm}
                  isAdmin={nodoActual.permisos.A}
                />
              ) : null}
            </div>
          </div>
          {/* <ProcessDescription innerRef={ref} /> */}
          <ProcessTabs descriptionref={ref} />
          <Confirm
            open={openConfirm}
            title={"Eliminar"}
            text={"¿Confirma que desea eliminar este elemento?"}
            onConfirm={del}
            handleCloseProp={handleCloseConfirm}
          />
        </div>

        <ModalPermisos
          handleClose={handleClosePermiso}
          open={openPermisoModal}
          nodoId={nodoActual.id}
          recargarArbolNodos={recargarArbolNodos}
        />
        <ModalReiniciarActividad
          handleClose={() => setOpenReiniciarModal(false)}
          open={oepnReiniciarModal}
          nodoId={nodoActual.id}
          periodoCerrado={!getUnicoPeriodoSeleccionado()?.recalculoHabilitado}
        />

        <ModalCopiaActividad
          open={openModalCopia}
          setOpen={setOpenModalCopia}
        />
        <Modal open={openBitacoraModal}
          handleClose={handleCloseBitacora}
          title={"Bitácora"}>
          <PerfectScrollbar>
            <div style={{ maxHeight: 500 }}>
              <Bitacora />
            </div>

          </PerfectScrollbar>

        </Modal>
      </div>
    );
  } else {
    return (
      <div>
        <h1> No se encontró el proceso solicitado</h1>
      </div>
    );
  }
}

export { ProcessDisplay };
