export const ANALYSIS_RULES = {
  1: {
    name: 'Regla 1. Grandes diferencias con respecto al promedio',
    description:
      'Uno o más puntos están más allá de la línea de los límites de control',
    causes: [
      '1. Un nuevo operador realizando el trabajo',
      '2. Configuración incorrecta',
      '3. Error de medida',
      '4. Se omitió un paso en el proceso',
      '5. Fallo eléctrico'
    ]
  },
  2: {
    name: 'Regla 2. Grandes diferencias con respecto al promedio',
    description:
      'Al menos dos de tres puntos consecutivos están dentro o más allá de la Zona A',
    causes: [
      '1. Un nuevo operador realizando el trabajo',
      '2. Configuración incorrecta',
      '3. Error de medida',
      '4. Se omitió un paso en el proceso',
      '5. Fallo eléctrico'
    ]
  },
  3: {
    name: 'Regla 3. Pequeñas diferencias con respecto al promedio',
    description:
      'Al menos cuatro de cinco puntos consecutivos están dentro o más allá de la Zona B',
    causes: [
      '1. Materia prima cambiada',
      '2. Cambio en las instrucciones de trabajo',
      '3. Cambio de quipo de medición/calibración',
      '4. El operario ganó más experiencia al realizar el trabajo',
      '5. Cambios en el programa de mantenimiento'
    ]
  },
  4: {
    name: 'Regla 4. Pequeñas diferencias con respecto al promedio',
    description:
      'Hay siete puntos consecutivos en un lado del promedio (Zona C o más allá)',
    causes: [
      '1. Materia prima cambiada',
      '2. Cambio en las instrucciones de trabajo',
      '3. Cambio de quipo de medición/calibración',
      '4. El operario ganó más experiencia al realizar el trabajo',
      '5. Cambios en el programa de mantenimiento'
    ]
  },
  5: {
    name: 'Regla 5. Tendencias',
    description:
      'Siete puntos consecutivos con tendencia a la alza o a la baja',
    causes: ['1. Desgaste de la herramienta', '2. Efectos de la temperatura']
  },
  6: {
    name: 'Regla 6. Mezclas',
    description: 'Ocho puntos consecuitovs fuera de la Zona C',
    causes: ['1. Se está llevando a cabo un procesos en simultáneo']
  },
  7: {
    name: 'Regla 7. Estratificaciones',
    description: 'Quince puntos consecutivos dentro de la Zona C',
    causes: ['1. Se está llevando a cabo un procesos en simultáneo']
  },
  8: {
    name: 'Regla 8. Sobre control',
    description: 'Catorce puntos consecutivos alternando arriba y abajo',
    causes: [
      '1. Manipulación de los datos',
      '2. Cambios en las materias primas'
    ]
  }
}
