import React from "react";
import DataGrid, {
    Column,
    Grouping,
    FilterRow,
    HeaderFilter,
    FilterPanel,
    Pager,
    Paging,
    ColumnChooser,
    GroupPanel,
    SearchPanel,
    Toolbar,
    Item,
    Sorting,
    StateStoring,
} from "devextreme-react/data-grid";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import 'devextreme/dist/css/dx.light.css';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    getConfiguracionMatrizPuntos,
    createConfiguracionMatrizPuntos,
    updateConfiguracionMatrizPuntos,
    deleteConfiguracionMatrizPuntos
} from "../../../Api/configuracion.api";
import { PeriodoContext } from "../../../Context/periodoContext";
import { hasSameItems } from "../../../Utils/utilities";
import { Button as GridButton } from 'devextreme-react/button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CrearMatrizPuntos from "./crear";
import { toast } from "react-toastify";
import DeleteDialog from "../../../Custom/DeleteDialog";

const MatrizPuntos = () => {

    const { getSelected, periodos: allPeriodos } = React.useContext(PeriodoContext);

    const [loading, setLoading] = React.useState(false);
    const [deleteDialog, setDeleteDialog] = React.useState({ open: false });
    const [periodos, setPeriodos] = React.useState([]);
    const [configuracion, setConfiguracion] = React.useState({ open: false });
    const [configuraciones, setConfiguraciones] = React.useState([]);

    const onCreationClose = () => {
        setConfiguracion({ open: false });
    }

    // Obtener periodos actuales
    React.useEffect(() => {
        const temp_periodos = getSelected();
        const newPeriodos = !hasSameItems(temp_periodos, periodos);
        if (newPeriodos) {
            setPeriodos(temp_periodos);
        }
    }, [getSelected()]);

    // Actualizar conforme los periodos
    React.useEffect(() => {
        if (periodos && periodos.length > 0) {
            setLoading(true);
            getConfiguracionMatrizPuntos(periodos)
                .then((response) => {
                    const { body } = response;
                    setConfiguraciones(body || []);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [periodos]);

    return (
        <div>
            <section
                style={{
                    padding: "10px 0",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px 3%",
                }}
            >
                <DataGrid
                    dataSource={configuraciones}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnResizingMode="nextColumn"
                >
                    <Toolbar>
                        <Item location="after">
                            <GridButton
                                text="Agregar"
                                onClick={() => {
                                    setConfiguracion({ open: true });
                                }}
                                icon="add"
                            />
                        </Item>
                        {/* <Item name="groupPanel" /> */}
                        {/* <Item name="columnChooserButton" /> */}
                        <Item name="searchPanel" />
                    </Toolbar>
                    <Column dataField="id" caption="id" dataType="number" visible={false} />
                    <Column
                        dataField="periodoNombre"
                        caption="Período"
                        groupIndex={0}
                    />
                    <Column
                        dataField="rango_inicio"
                        caption="Rango Inicio"
                    />
                    <Column
                        dataField="rango_fin"
                        caption="Rango Fin"
                    />
                    <Column
                        dataField="puntos"
                        caption="Puntos"
                    />
                    <Column
                        width={'auto'}
                        dataField="id"
                        caption=""
                        allowFiltering={false}
                        allowSorting={false}
                        allowSearch={false}
                        allowGrouping={false}
                        allowReordering={false}
                        allowResizing={false}
                        cellRender={(rowInfo) => {
                            const { id, rango_inicio, rango_fin, puntos, periodoId } = rowInfo.data
                            return (
                                <span>
                                    <IconButton aria-label="edit" onClick={() => {
                                        setConfiguracion({ open: true, id, rango_inicio, rango_fin, puntos, periodoId });
                                    }}>
                                        <EditIcon sx={{ color: "#ffd105" }} />
                                    </IconButton>
                                    <IconButton aria-label="delete" onClick={() => {
                                        setDeleteDialog({
                                            open: true,
                                            handleDelete: () => {
                                                setLoading(true);
                                                deleteConfiguracionMatrizPuntos(id, periodos)
                                                    .then((response) => {
                                                        if (response.status === 200) {
                                                            const { body } = response;
                                                            setConfiguraciones(body?.body || []);
                                                            toast.success("Configuración eliminada exitosamente!");
                                                            setDeleteDialog({ open: false });
                                                        } else {
                                                            toast.error(response.message);
                                                        }
                                                    })
                                                    .catch((error) => {
                                                        toast.error('Error al momento de eliminar la configuración');
                                                    })
                                                    .finally(() => {
                                                        setLoading(false);
                                                    });
                                            }
                                        })
                                    }}>
                                        <DeleteIcon sx={{ color: "red" }} />
                                    </IconButton>
                                </span>
                            )
                        }
                        }
                    />
                    <Grouping autoExpandAll={true} />
                    <StateStoring
                        enabled={true}
                        type="localStorage"
                        storageKey={'MatrizPuntosConfigGRID'}
                    />
                    {/* <ColumnChooser
                        enabled={true}
                        mode="dragAndDrop"
                    /> */}
                    <GroupPanel visible={true} />
                    <FilterRow visible={true} />
                    <FilterPanel visible={false} />
                    <HeaderFilter visible={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                    <Paging defaultPageSize={50} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[50, 100, 150, 200]}
                        showInfo={true}
                    />
                </DataGrid>
            </section>
            {/* Creación/Edición configuración */}
            <Dialog
                fullWidth
                maxWidth={"md"}
                open={configuracion.open}
                onClose={onCreationClose}
            >
                <DialogTitle>
                    {configuracion.id ? "Editar" : "Crear"} configuración
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onCreationClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <CrearMatrizPuntos
                        configuracion={configuracion}
                        setConfiguracion={setConfiguracion}
                        periodos={allPeriodos.filter((periodo) => periodos.includes(periodo.id))}
                    />
                </DialogContent>
                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ padding: "1em 1em 1em 0em" }}>
                        <Button variant="text" onClick={onCreationClose}>
                            Cancelar
                        </Button>
                        <Button variant="contained" onClick={() => {
                            const { rango_inicio, rango_fin, puntos, periodoId, id } = configuracion;
                            const required = [rango_inicio, rango_fin, puntos, periodoId];
                            if (!required.includes(null) && !required.includes(undefined) && !required.includes('')) {
                                if (id) {
                                    // Editar
                                    setLoading(true);
                                    updateConfiguracionMatrizPuntos(id, configuracion, periodos)
                                        .then((response) => {
                                            if (response.status === 200) {
                                                const { body } = response;
                                                setConfiguraciones(body?.body || []);
                                                onCreationClose();
                                                toast.success("Configuración actualizada exitosamente!");
                                            } else {
                                                toast.error(response.message);
                                            }
                                        })
                                        .catch((error) => {
                                            toast.error('Error al momento de actualizar la configuración');
                                        })
                                        .finally(() => {
                                            setLoading(false);
                                        });
                                } else {
                                    // Crear
                                    setLoading(true);
                                    createConfiguracionMatrizPuntos(configuracion, periodos)
                                        .then((response) => {
                                            if (response.status === 201) {
                                                const { body } = response;
                                                setConfiguraciones(body?.body || []);
                                                onCreationClose();
                                                toast.success("Configuración creada exitosamente!");
                                            } else {
                                                toast.error(response.message);
                                            }
                                        })
                                        .catch((error) => {
                                            toast.error('Error al momento de crear la configuración');
                                        })
                                        .finally(() => {
                                            setLoading(false);
                                        });
                                }
                            } else {
                                toast.error("Por favor, llene todos los campos");
                            }
                        }} >
                            Guardar
                        </Button>
                    </div>
                </div>
            </Dialog>
            {/* Diálogo de eliminación */}
            <DeleteDialog 
                {...deleteDialog}
                handleClose={() => setDeleteDialog({ open: false })}
            />
            {/* Pantalla de carga */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default MatrizPuntos;