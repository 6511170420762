const nodeType = {
  gerencia: 1,
  proceso: 2,
  actividad: 3,
};

const sipocNodeType = {
  supplier: 1,
  customer: 2,
};

export { nodeType, sipocNodeType };
